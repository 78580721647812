import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import { Icon } from '@chakra-ui/react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { closeOverlayLoader, openOverlayLoader } from 'src/containers/OverlayLoader/operations';
import {
  docLibraryUploadFailure,
  docLibraryUploadSuccess,
  hideDocLibraryNotification,
  getDocumentLibrarySuccess,
  getDocumentLibraryFailure,
  getDocumentLibraryRequest,
  deleteDocumentFromLibrarySuccess,
  deleteDocumentFromLibraryFailure,
  getDocumentLibraryByIdSuccess,
  getDocumentLibraryByIdFailure,
  docLibraryUpdateFailure,
  docLibraryUpdateSuccess,
  clearDocumentLibrarySelected,
  closeDocLibraryUpload
} from './operations';
import {
  DOCUMENT_LIBRARY_UPLOAD_REQUEST,
  DOCUMENT_LIBRARY_REQUEST,
  DELETE_DOCUMENT_FROM_LIBRARY_REQUEST,
  GET_DOCUMENT_LIBRARY_REQUEST,
  DOCUMENT_LIBRARY_UPDATE_REQUEST,
  DOWNLOAD_DOCUMENT_LIBRARY_REQUEST
} from './types';
import {
  docLibraryUploadAction,
  getDocumentsLibraryAction,
  deleteDocumentFromLibraryAction,
  getDocumentsLibraryByIdAction,
  updateDocumentsLibraryAction,
  downloadDocumentLibraryAction
} from './actions';

const getToken = state => state.loginReducer.token;

function* workerDocumentLibraryUpload({ formData }) {
  try {
    const token = yield select(getToken);
    const response = yield call(docLibraryUploadAction, { token, formData });
    if (response.data.success) {
      yield put(docLibraryUploadSuccess({ url: response.data.url }));
      yield put(getDocumentLibraryRequest());
      yield put(closeDocLibraryUpload());
      toast('Saved successfully', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
      yield delay(3000);
      yield put(hideDocLibraryNotification());
    }
  } catch (error) {
    console.log('error', error);
    yield put(docLibraryUploadFailure({ error }));
    toast.error('Error saving doc');
  }
}
function* workerGetDocumentLibrary() {
  try {
    const token = yield select(getToken);
    const response = yield call(getDocumentsLibraryAction, {
      token
    });
    const { documentLibrary, success } = response.data;
    if (success) {
      const parsedDocumentLibrary = documentLibrary.map(val => ({
        ...val,
        created: new Date(val.created)
      }));
      yield put(getDocumentLibrarySuccess({ documentLibrary: parsedDocumentLibrary }));
    }
  } catch (error) {
    yield put(getDocumentLibraryFailure({ error }));
  }
}

function* workerDeleteDocumentFromLibrary({ documentId }) {
  try {
    const token = yield select(getToken);
    const response = yield call(deleteDocumentFromLibraryAction, { token, documentId });

    if (response.data.success) {
      yield put(deleteDocumentFromLibrarySuccess());
      toast.error('Archived successfully', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
      yield put(getDocumentLibraryRequest());
    }
  } catch (error) {
    yield put(deleteDocumentFromLibraryFailure({ error }));
  }
}

function* workerGetDocumentLibraryById({ documentId }) {
  try {
    const token = yield select(getToken);
    const response = yield call(getDocumentsLibraryByIdAction, {
      token,
      documentId
    });
    const { document, success } = response.data;
    if (success) {
      yield put(getDocumentLibraryByIdSuccess({ document }));
    }
  } catch (error) {
    yield put(getDocumentLibraryByIdFailure({ error }));
  }
}

function* workerUpdateDocumentToLibrary({ formData, documentId }) {
  try {
    const token = yield select(getToken);
    const response = yield call(updateDocumentsLibraryAction, {
      token,
      formData,
      documentId
    });

    if (response.data.success) {
      yield put(closeDocLibraryUpload()); // close drawer
      yield put(docLibraryUpdateSuccess({ document: response.data.document }));
      yield put(getDocumentLibraryRequest());
      toast('Edited successfully', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
    }
  } catch (error) {
    yield put(docLibraryUpdateFailure({ error }));
  }
}

function* workerDownloadDocumentLibrary({ documentId, filename, fileExtension }) {
  try {
    yield put(openOverlayLoader());
    const token = yield select(getToken);
    const response = yield call(downloadDocumentLibraryAction, { token, documentId });
    if (response) {
      const responseUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      toast('Document Downloaded', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
      link.href = responseUrl;
      link.setAttribute('download', `${filename}.${fileExtension}`); // append file extension to filename
      document.body.appendChild(link);
      link.click();
      yield put(closeOverlayLoader());
    }
  } catch (error) {
    console.log('download library document error', error);
    toast.error('Error downloading document');
  }
}

export function* watcherUploadDocumentToLibrary() {
  yield takeLatest(DOCUMENT_LIBRARY_UPLOAD_REQUEST, workerDocumentLibraryUpload);
}

export function* watcherGetDocumentLibrary() {
  yield takeLatest(DOCUMENT_LIBRARY_REQUEST, workerGetDocumentLibrary);
}

export function* watcherDeleteDocumentFromLibrary() {
  yield takeLatest(DELETE_DOCUMENT_FROM_LIBRARY_REQUEST, workerDeleteDocumentFromLibrary);
}

export function* watcherGetDocumentLibraryById() {
  yield takeLatest(GET_DOCUMENT_LIBRARY_REQUEST, workerGetDocumentLibraryById);
}

export function* watcherUpdateDocumentToLibrary() {
  yield takeLatest(DOCUMENT_LIBRARY_UPDATE_REQUEST, workerUpdateDocumentToLibrary);
}

export function* watcherDownloadDocumentLibrary() {
  yield takeLatest(DOWNLOAD_DOCUMENT_LIBRARY_REQUEST, workerDownloadDocumentLibrary);
}
