import { Filter } from '../Filter';

const dateRangeFilterList = [
  { label: 'Today', value: 'today' },
  { label: 'This Week', value: 'this-week' },
  { label: 'This Year', value: 'this-year' },
  { label: 'Last Week', value: 'last-week' },
  { label: 'Last Month', value: 'last-month' },
  { label: 'Last Quarter', value: 'last-quarter' },
  { label: 'Last Year', value: 'last-year' },
  { label: 'Year to Date', value: 'year-to-date' },
  { label: 'Month to Date', value: 'month-to-date' }
];

export const DateRangeFilter = ({ isDisabled }) => {
  return (
    <Filter
      title="Received"
      width="100%"
      filterList={dateRangeFilterList}
      dataKey="leadDate"
      filterType="dateRange"
      disableMultiple={true}
      isDisabled={isDisabled}
    />
  );
};
