export const GET_LAYOUTSREDUCER_REQUEST = 'GET_LAYOUTSREDUCER_REQUEST';
export const GET_LAYOUTSREDUCER_SUCCESS = 'GET_LAYOUTSREDUCER_SUCCESS';
export const GET_LAYOUTSREDUCER_FAILURE = 'GET_LAYOUTSREDUCER_FAILURE';

export const ADD_LAYOUT_REQUEST = 'ADD_LAYOUT_REQUEST';
export const ADD_LAYOUT_SUCCESS = 'ADD_LAYOUT_SUCCESS';
export const ADD_LAYOUT_FAILURE = 'ADD_LAYOUT_FAILURE';

export const UPDATE_LAYOUT_REQUEST = 'UPDATE_LAYOUT_REQUEST';
export const UPDATE_LAYOUT_SUCCESS = 'UPDATE_LAYOUT_SUCCESS';
export const UPDATE_LAYOUT_FAILURE = 'UPDATE_LAYOUT_FAILURE';

export const DELETE_LAYOUT_REQUEST = 'DELETE_LAYOUT_REQUEST';
export const DELETE_LAYOUT_SUCCESS = 'DELETE_LAYOUT_SUCCESS';
export const DELETE_LAYOUT_FAILURE = 'DELETE_LAYOUT_FAILURE';

export const UPLOAD_LAYOUT_REQUEST = 'UPLOAD_LAYOUT_REQUEST';
export const UPLOAD_LAYOUT_SUCCESS = 'UPLOAD_LAYOUT_SUCCESS';
export const UPLOAD_LAYOUT_FAILURE = 'UPLOAD_LAYOUT_FAILURE';

export const SET_LAYOUT_FOR_EDIT = 'SET_LAYOUT_FOR_EDIT';
export const CLEAR_LAYOUT_FOR_EDIT = 'CLEAR_LAYOUT_FOR_EDIT';

export const SET_ROOM_SELECTED = 'SET_ROOM_SELECTED';
export const CLEAR_SELECTED_ROOM = 'CLEAR_SELECTED_ROOM';

export const GET_LAYOUT_BY_ID_REQUEST = 'GET_LAYOUT_BY_ID_REQUEST';
export const GET_LAYOUT_BY_ID_SUCCESS = 'GET_LAYOUT_BY_ID_SUCCESS';
export const GET_LAYOUT_BY_ID_FAILURE = 'GET_LAYOUT_BY_ID_FAILURE';

export const GET_LAYOUT_OPTIONS = {
  REQUEST: 'GET_LAYOUT_OPTIONS_REQUEST',
  SUCCESS: 'GET_LAYOUT_OPTIONS_SUCCESS',
  FAILURE: 'GET_LAYOUT_OPTIONS_FAILURE'
};

export const DUPLICATE_LAYOUT_REQUEST = 'DUPLICATE_LAYOUT_REQUEST';
export const DUPLICATE_LAYOUT_SUCCESS = 'DUPLICATE_LAYOUT_SUCCESS';
export const DUPLICATE_LAYOUT_FAILURE = 'DUPLICATE_LAYOUT_FAILURE';

export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
