import {
  DrawerOverlay,
  DrawerContent,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Text,
  Popover,
  Center
} from '@chakra-ui/react';
import Close from 'src/styles/Icons/Close';
import Colors from 'src/styles/Colors';
import { FC } from 'react';
import { FormDrawerProps } from 'src/Types/GlobalTypes';

import {
  FormDrawerStyles,
  FormDrawerHeaderStyles,
  TitleStyles,
  FormDrawerFooterStyles
} from './styles';
import { Button } from '../../Primitives';
import { PopoverTrigger } from '../../Popover/PopoverTrigger';

export const FormDrawer: FC<FormDrawerProps> = ({
  children,
  isOpen,
  closeDrawer,
  isFetching,
  title = '',
  showFooter = false,
  footerOptions,
  wide = false
}) => {
  // TODO #1126 - Review why loader is commented out
  // const [showLoader, setShowLoader] = useState(false);
  // const { overlayLoaderOpen } = useSelector((state: RootStateOrAny) => state.overlayLoaderReducer);

  const { leftButton = {}, rightButton = {} } = footerOptions || {};

  return (
    <Drawer
      {...FormDrawerStyles}
      isOpen={isOpen}
      onClose={closeDrawer}
      returnFocusOnClose={false}
      autoFocus={false}
      variant={(wide && 'darkOverlayWide') || 'darkOverlay'}
    >
      <DrawerOverlay>
        <DrawerContent>
          {title && (
            <DrawerHeader {...FormDrawerHeaderStyles} display="flex" justifyContent="space-between">
              <Text {...TitleStyles}>{title}</Text>
              <Center onClick={closeDrawer} cursor="pointer">
                <Close size="13" color={Colors.gray18} />
              </Center>
            </DrawerHeader>
          )}
          <DrawerBody paddingX="40px">{children}</DrawerBody>

          {showFooter && (
            <DrawerFooter {...FormDrawerFooterStyles}>
              <Button
                variant="grayOutline"
                width="125px"
                size="md"
                onClick={leftButton.onClick}
                mr="25px"
              >
                {leftButton.label || 'CANCEL'}
              </Button>

              <Popover trigger="hover" placement="top-start">
                <PopoverTrigger>
                  <span>
                    <Button
                      width="125px"
                      size="md"
                      onClick={rightButton.onClick}
                      isLoading={rightButton.isLoading}
                      isDisabled={rightButton.isDisabled}
                      leftIcon={rightButton.leftIcon}
                      rightIcon={rightButton.rightIcon}
                    >
                      {rightButton.label || 'SAVE'}
                    </Button>
                  </span>
                </PopoverTrigger>
              </Popover>
            </DrawerFooter>
          )}
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};
