import moment from 'moment';
import { Grid, GridItem, Flex, Text, Circle } from '@chakra-ui/react';
import { EventCalendar, EventType, Activities } from 'src/v2/UIComponents/Primitives/Icons';
import { Skeleton } from 'src/v2/UIComponents/Primitives';
import { Card } from 'src/v2/UIComponents/Primitives/Card';
import { EventActivityObject } from 'venuex-types';
import { getDateDisplay } from 'src/utils/getDateDisplay';
import { DetailsCard } from './components/DetailsCard';
import { DocumentsCard } from './components/DocumentsCard';
import { ChecklistCard } from './components/ChecklistCard';
import { FloorPlanCard } from './components/FloorPlanCard';

import { useEvents } from '../../../Hooks/CollectionHooks/Events/useEvents';
import { LegacyPaymentsCard } from './components/PaymentsCard/LegacyPayments';

// const StyledGrid = styled(Grid)`
//   @media (max-height: 800px) {
//     margin-bottom: 25px;
//   }
// `;

export const Overview = () => {
  const { activeEvent: event, fetchingEvent } = useEvents();
  const consultant = event?.consultantData?.label;
  const consultantInitials = event?.consultantData?.initials;
  const { activities } = event;

  const filteredActivities = activities?.filter((i): i is EventActivityObject => {
    return typeof i !== 'undefined';
  });

  const lastActivityDate =
    filteredActivities?.length &&
    filteredActivities
      ?.filter(a => a.created)
      .sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())[0].created;

  // const overDueInvoices = invoices?.filter(invoice => {
  //   const today = new Date().getTime();
  //   const dueDate = new Date(invoice?.dueDate).getTime();
  //   const overDue = today > dueDate && invoice?.status !== 'paid';
  //   return overDue;
  // });

  const miniCardsTitle = [
    {
      title: 'Event Date',
      value: moment(event?.start).format('MM/DD/YYYY'),
      icon: <EventCalendar />
    },
    { title: 'Event Type', value: event?.eventTypeLabel, icon: <EventType /> },
    {
      title: 'Consultant',
      value: consultant,
      icon: (
        <Circle size="40px" bg="#E8E8E8" color="#A0A4A8">
          {consultantInitials}
        </Circle>
      )
    },
    {
      title: 'Last Activity Date',
      value:
        (lastActivityDate && getDateDisplay(new Date(lastActivityDate)).shortDate) ||
        'No Activities',
      icon: <Activities />
    }

    // (advancedPayments && {
    //   title: 'Payment Status',
    //   value: (overDueInvoices?.length && 'Overdue') || 'Current',
    //   icon: <EventPayment />
    // }) || {
    //   title: 'Payment Status Replacement Here',
    //   value: (overDueInvoices?.length && 'Overdue') || 'Current',
    //   icon: <EventPayment />
    // }
  ];

  return (
    <Flex height="full" flexDir="column" minH="1">
      <Grid mb="33px" templateColumns="repeat(4, 1fr)" gap={6} minHeight="1px">
        {miniCardsTitle.map((el, key) => (
          <GridItem key={key}>
            <Card display="flex" paddingX="26px" paddingY="20px" height="88px">
              <Flex>
                <Flex mr="26px" alignItems="center" color="var(--colors-primary)">
                  {el.icon}
                </Flex>
                <Flex flexDir="column">
                  <Text
                    fontFamily="Lato"
                    fontWeight={700}
                    fontSize="14px"
                    lineHeight="20px"
                    mb="4px"
                  >
                    {el.title}
                  </Text>
                  {fetchingEvent ? (
                    <Flex>
                      <Skeleton height="16px" width="160px" />
                    </Flex>
                  ) : (
                    <Text fontFamily="Lato" fontWeight={400} fontSize="18px" lineHeight="24px">
                      {el.value}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Card>
          </GridItem>
        ))}
      </Grid>
      <Grid
        h="full"
        width="100%"
        templateRows="1fr 1fr"
        templateColumns="repeat(3, 1fr)"
        gap={4}
        minHeight="1px"
      >
        <GridItem rowSpan={2} minH="1px">
          <DetailsCard fetchingEvent={fetchingEvent} />
        </GridItem>
        <GridItem minH="1px">
          <FloorPlanCard event={event} fetchingEvent={fetchingEvent} />
        </GridItem>
        <GridItem minH="1px">
          <DocumentsCard />
        </GridItem>
        <GridItem minH="1px">
          {/* {(advancedPayments && <PaymentsCard event={event} fetchingEvent={fetchingEvent} />) ||
            'Payments replacement card here'} */}
          <LegacyPaymentsCard />
        </GridItem>
        <GridItem minH="1px">
          <ChecklistCard fetchingEvent={fetchingEvent} />
        </GridItem>
      </Grid>
    </Flex>
  );
};
