import { useSelector } from 'react-redux';
import { useUser } from 'src/v2/Hooks/CollectionHooks/User/useUser';
import { useActions } from 'src/hooks/useActions';
import { hideSidebarOperation } from 'src/containers/AppContainer/operations';

/**
 * Hooks to access the FireStore rooms collection for this venue
 * @returns {Object}
 *
 * **ACTIONS**
 * - **getRooms** (Function): Fires getRoomsRequest() with token.
 * **STATES**
 * - **fetchingFireStore** (Boolean): Whether it is fetching the venue object from firestore
 * - **fetchingRTDB** (Boolean): Whether it is fetching the venue object from Real Time Database
 * - **staffUsers** (Array of Objects): Array of staff objects
 */
export const useSideBar = () => {
  const { token } = useUser();
  const { hideSidebar: sidebarHidden } = useSelector(state => state.appContainerReducer);

  // TEMPORARY SO WE DON'T BREAK STAGING

  const actions = useActions({
    hideSidebar: hideSidebarOperation
  });

  return {
    sidebarHidden,
    ...actions
  };
};
