/* eslint-disable import/prefer-default-export */
import {
  OPEN_DOCUMENT_LIBRARY_UPLOAD,
  CLOSE_DOCUMENT_LIBRARY_UPLOAD,
  DOCUMENT_LIBRARY_UPLOAD_REQUEST,
  DOCUMENT_LIBRARY_UPLOAD_SUCCESS,
  DOCUMENT_LIBRARY_UPLOAD_FAILURE,
  DOCUMENT_LIBRARY_HIDE_NOTIFICATIONS,
  DOCUMENT_LIBRARY_FAILURE,
  DOCUMENT_LIBRARY_SUCCESS,
  DOCUMENT_LIBRARY_REQUEST,
  GET_DOCUMENT_LIBRARY_REQUEST,
  GET_DOCUMENT_LIBRARY_SUCCESS,
  GET_DOCUMENT_LIBRARY_FAILURE,
  CLEAR_DOCUMENT_LIBRARY_SELECTED,
  DOCUMENT_LIBRARY_UPDATE_REQUEST,
  DOCUMENT_LIBRARY_UPDATE_SUCCESS
} from './types';

const initialState = {
  docLibraryUploadOpen: false,
  docLibraryUploadFetchingStatus: false,
  showDocAlert: false,
  docAlertMessage: '',
  docAlertError: '',
  fetchingDocumentLibrary: false,
  documentLibraryFiles: [],
  documentLibraryFetchError: null,
  selectedDocumentLibrary: null,
  updating: false
};

export const documentLibraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_DOCUMENT_LIBRARY_UPLOAD:
      return { ...state, docLibraryUploadOpen: true };
    case CLOSE_DOCUMENT_LIBRARY_UPLOAD:
      return { ...state, docLibraryUploadOpen: false };
    case DOCUMENT_LIBRARY_UPLOAD_SUCCESS:
      return {
        ...state,
        docLibraryUploadOpen: false,
        docLibraryUploadFetchingStatus: false,
        showDocAlert: true,
        docAlertMessage: action.successMessage
      };
    case DOCUMENT_LIBRARY_UPLOAD_REQUEST:
      return {
        ...state,
        docLibraryUploadFetchingStatus: true
      };
    case DOCUMENT_LIBRARY_UPLOAD_FAILURE:
      return {
        ...state,
        docLibraryUploadFetchingStatus: false,
        showDocAlert: true,
        docAlertError: true
      };
    case DOCUMENT_LIBRARY_HIDE_NOTIFICATIONS:
      return {
        ...state,
        showDocAlert: false,
        docAlertMessage: '',
        docAlertError: false
      };
    case DOCUMENT_LIBRARY_REQUEST:
      return {
        ...state,
        fetchingDocumentLibrary: true
      };
    case DOCUMENT_LIBRARY_SUCCESS:
      return {
        ...state,
        documentLibraryFiles: action.documentLibrary,
        fetchingDocumentLibrary: false
      };
    case DOCUMENT_LIBRARY_FAILURE:
      return {
        ...state,
        fetchingDocumentLibrary: false,
        documentLibraryFetchError:
          (action.error && action.error.message) || 'Error downloading documents'
      };
    // case GET_DOCUMENT_LIBRARY_REQUEST:
    //   return {};
    case GET_DOCUMENT_LIBRARY_SUCCESS:
      return {
        ...state,
        selectedDocumentLibrary: action.document
      };
    // case GET_DOCUMENT_LIBRARY_FAILURE:
    //   return {};
    case CLEAR_DOCUMENT_LIBRARY_SELECTED:
      return {
        ...state,
        selectedDocumentLibrary: null
      };
    case DOCUMENT_LIBRARY_UPDATE_REQUEST:
      return {
        ...state,
        updating: true
      };
    case DOCUMENT_LIBRARY_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false
      };
    default:
      return state;
  }
};
