import { takeLatest, call, put } from 'redux-saga/effects';
import { parseTable, saveMultipleGuests } from './actions';

import {
  PARSE_TABLE_REQUEST,
  PARSE_TABLE_SUCCESS,
  PARSE_TABLE_FAILURE,
  SAVE_GUESTS_REQUEST,
  SAVE_GUESTS_FAILURE,
  SAVE_GUESTS_SUCCESS
} from './types';
import { GET_GUESTS_REQUEST } from '../GuestContainer/types';
import { closeFileModal } from '../UploadButton/operations';

export function* workerParseTable({ token, parsedData, parsedColumns }) {
  try {
    const response = yield call(parseTable, { token, parsedData, parsedColumns });

    const { columns, data, failed } = response.data;

    yield put({ type: PARSE_TABLE_SUCCESS, columns, data, failed });
  } catch (error) {
    const errorObj = {
      error:
        (error.response && (error.response.data.message || error.response.data.toString())) ||
        error.toString()
    };

    yield put({ type: PARSE_TABLE_FAILURE, ...errorObj });
  }
}

export function* workerSaveGuests({ token, parsedData, parsedColumns, venueId, eventId }) {
  try {
    const response = yield call(saveMultipleGuests, {
      token,
      parsedData,
      parsedColumns,
      venueId,
      eventId
    });

    const { success } = response.data;
    yield put({ type: SAVE_GUESTS_SUCCESS, success });
    yield put({
      type: GET_GUESTS_REQUEST,
      token,
      eventId
    });
    yield put(closeFileModal());
  } catch (error) {
    yield put({ type: SAVE_GUESTS_FAILURE, error: error.response.data });
  }
}

export function* watcherParseTable() {
  yield takeLatest(PARSE_TABLE_REQUEST, workerParseTable);
}

export function* watcherSaveGuests() {
  yield takeLatest(SAVE_GUESTS_REQUEST, workerSaveGuests);
}
