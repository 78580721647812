import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AvatarEditor from 'react-avatar-editor';
import Img from 'react-image';
import ImageResizerStyles from './styles';
import Modal from '../Modal';
import ImageResizerFooter from '../AddEvent/AddEventFooter';
import Loader from '../../styles/Icons/Loader';
import { dataURItoBlob } from '../../utils/Helpers';

const ImageResizer = props => {
  const editor = useRef(null);
  // const [image, setImage] = useState('');
  const [scale, setScale] = useState(1);
  const onClickSave = async () => {
    try {
      if (props.profilePic) {
        const image = dataURItoBlob(props.image);
        props.saveImage(image, image);
      } else if (editor) {
        // This returns a HTMLCanvasElement, it can be made into a data URL or a blob
        // drawn on another canvas, or added to the DOM.
        const originalImage = editor.current.getImage().toDataURL();

        // If you want the image resized to the canvas size (also a HTMLCanvasElement)
        const imageDataURL = editor.current.getImageScaledToCanvas().toDataURL();

        const file = dataURItoBlob(imageDataURL);
        const image = dataURItoBlob(props.image);
        props.saveImage(image, file);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleToggleModal = () => {
    props.onRequestClose();
  };

  const handleScale = e => {
    const scale = parseFloat(e.target.value);
    setScale(scale);
  };

  return (
    <Modal isOpen={props.isOpen} toggle={handleToggleModal} noPadding height="50rem" width="500px">
      <ImageResizerStyles>
        <ImageResizerStyles.Header>{props.title ? props.title : 'Crop'}</ImageResizerStyles.Header>
        <ImageResizerStyles.Editor>
          {props.profilePic ? (
            <Img src={props.image} loader={<Loader />} />
          ) : (
            <AvatarEditor
              ref={editor}
              image={props.image}
              width={250}
              height={250}
              border={50}
              scale={scale}
            />
          )}
        </ImageResizerStyles.Editor>
        {!props.profilePic && (
          <div>
            <ImageResizerStyles.SliderText>Slide To Adjust Zoom</ImageResizerStyles.SliderText>
            <ImageResizerStyles.Input>
              <input
                name="scale"
                type="range"
                onChange={handleScale}
                min="0.1"
                max="2"
                step="0.01"
                defaultValue="1"
              />
            </ImageResizerStyles.Input>
          </div>
        )}
        <ImageResizerFooter
          handleSubmit={onClickSave}
          closeModal={handleToggleModal}
          fetching={props.fetching}
          cancel={'Cancel'}
          save={props.profilePic ? 'Upload' : 'Set Thumbnail'}
        />
      </ImageResizerStyles>
    </Modal>
  );
};

ImageResizer.propTypes = {
  isOpen: PropTypes.bool,
  image: PropTypes.string,
  setImage: PropTypes.func,
  onRequestClose: PropTypes.func
};

export default ImageResizer;
