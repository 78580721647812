const ACTIVITY_NAME = {
  REQUIRED: 'Activity Name is required',
  LABEL: 'Activity Name',
  PLACEHOLDER: 'e.g. Spoke to customer about availability'
};

const ACTIVITY_DESCRIPTION = {
  LABEL: 'Activity Description',
  PLACEHOLDER: 'e.g. Customer expressed interest in a weekend wedding'
};
const ACTIVITY_TYPE = {
  LABEL: 'Activity Type',
  PLACEHOLDER: 'Select an activity type',
  REQUIRED: 'Activity Type is required'
};

export const ACTIVITY_FORM_CONSTANTS = {
  ACTIVITY_NAME,
  ACTIVITY_DESCRIPTION,
  ACTIVITY_TYPE
};
