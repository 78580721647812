import { Box, Button as ChakraButton, Icon, ButtonGroup } from '@chakra-ui/react';
import { BiSend } from 'react-icons/bi';
import { useConfirm } from 'src/v2/Hooks/HelperHooks';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { getDateDisplay } from 'src/utils/getDateDisplay';
import { useEvents, useVenue } from 'src/v2/Hooks/CollectionHooks';
import { Button } from 'src/v2/UIComponents/Primitives';
import { ListCard } from 'src/v2/UIComponents/Primitives/Card/FullCard/FullCard';

export const LegacyPaymentsCard = () => {
  const {
    activeEvent,
    activeEventId,
    fetchingEvent,
    sendVenueToHostEmailRequest,
    setPaymentStatus
  } = useEvents();
  const { fireStoreVenue: venue } = useVenue();
  const schedule = activeEvent.paymentStatus?.schedule;
  const status = activeEvent.paymentStatus?.status;

  const PaymentStatusSwitchButton = () => {
    const handleValueChange = newStatus => {
      setPaymentStatus(newStatus, activeEventId);
    };

    return (
      <ButtonGroup size="xs" isAttached>
        <ChakraButton
          variant={status === 'current' ? 'solid' : 'outline'}
          colorScheme="green"
          onClick={() => handleValueChange('current')}
          borderTopLeftRadius="md"
          borderBottomLeftRadius="md"
          borderRightWidth={0}
          _notLast={{ borderRightWidth: '1px' }}
        >
          Current
        </ChakraButton>
        <ChakraButton
          variant={status === 'overdue' ? 'solid' : 'outline'}
          colorScheme="red"
          onClick={() => handleValueChange('overdue')}
          borderTopRightRadius="md"
          borderBottomRightRadius="md"
          _notFirst={{ borderLeftWidth: '1px' }}
        >
          Overdue
        </ChakraButton>
      </ButtonGroup>
    );
  };
  const depositArray = schedule?.map((deposit, i) => {
    if (typeof deposit === 'string') {
      return {
        value: getDateDisplay(new Date(deposit)).shortDate,
        title: `Deposit ${i + 1}`
      };
    }
    if (typeof deposit === 'object') {
      return {
        value: getDateDisplay(new Date(deposit.date)).shortDate,
        title: `Deposit ${i + 1}`
      };
    }
    return {
      value: 'N/A',
      title: `Deposit ${i + 1}`
    };
  });

  const list = [
    { title: 'Payment Status', value: <PaymentStatusSwitchButton /> },
    ...(depositArray || [{ value: 'N/A', title: 'N/A' }])
  ];

  const sendReminderEmail = () => {
    const defaultSubject =
      status === 'current'
        ? venue.emailMessages?.upcomingPayment?.subject || 'Upcoming Payment Due'
        : venue.emailMessages?.overduePayments?.subject || 'Outstanding Payment Due';
    const defaultMessage =
      status === 'current'
        ? venue?.emailMessages?.upcomingPayment?.message ||
          'We hope your event planning is going well! Just a reminder that you have an upcoming payment due.'
        : venue?.emailMessages?.overduePayments?.message ||
          'Just a reminder you have a payment due. Please contact the venue for more information or to make a payment.';
    sendVenueToHostEmailRequest({
      eventId: activeEventId,
      subject: defaultSubject,
      message: defaultMessage
    });
  };

  const { openConfirm } = useConfirm({
    acceptAction: sendReminderEmail,
    confirmHookId: 'paymentReminderEmail'
  });

  const sendReminderEmailButton = () =>
    openConfirm({
      title: 'Payment Reminder Email',
      description: 'Send a payment reminder email to the host.',
      confirmId: 'paymentReminderEmail',
      confirmButtonTitle: 'Send'
    });

  const Footer = () => {
    return (
      <Box w="100%" display="flex" justifyContent={'flex-end'}>
        <Button
          onClick={sendReminderEmailButton}
          w="100%"
          variant="outline"
          rightIcon={<Icon as={BiSend} />}
          isLoading={fetchingEvent}
        >
          Send Reminder
        </Button>
      </Box>
    );
  };

  return (
    <ListCard
      titleIcon={<Icon as={FaRegMoneyBillAlt} w={6} h={6} color="var(--colors-primary)" />}
      title={`Deposit Schedule`}
      list={list}
      fetching={fetchingEvent}
      headerBtnIsDisabled={fetchingEvent}
      cardFooter={Footer}
      headerBtnIcon={undefined}
      headerBtnAction={undefined}
      headerBtnLabel={undefined}
    />
  );
};
