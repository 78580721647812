import { OPEN_CHANGE_GROUP_NAME, CLOSE_CHANGE_GROUP_NAME } from './types';

export const openChangeGroupName = ({ groupId, groupName }) => ({
  type: OPEN_CHANGE_GROUP_NAME,
  groupId,
  groupName
});

export const closeChangeGroupName = () => ({
  type: CLOSE_CHANGE_GROUP_NAME
});
