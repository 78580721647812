import {
  CreateProductCategoryRequest,
  CreateProductRequest,
  CreateRateTypeRequest,
  ReadVenueRequest
} from 'src/containers/VenuesContainer/OperationTypes';
import {
  VENUES_LIST_REQUEST,
  VENUES_LOADMORE_REQUEST,
  VENUE_READ_REQUEST,
  SET_TUTORIAL,
  UPDATE_VENUE_REQUEST,
  UPDATE_VENUE_FAILED,
  UPDATE_VENUE_SUCCESS,
  EDIT_VENUE_REQUEST,
  EDIT_VENUE_SUCCESS,
  EDIT_VENUE_FAILED,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  CREATE_PRODUCT_CATEGORY_REQUEST,
  CREATE_PRODUCT_CATEGORY_SUCCESS,
  CREATE_PRODUCT_CATEGORY_FAILURE,
  CREATE_RATE_TYPE_REQUEST,
  CREATE_RATE_TYPE_SUCCESS,
  CREATE_RATE_TYPE_FAILURE,
  CREATE_TAX_RATE_REQUEST,
  CREATE_TAX_RATE_SUCCESS,
  CREATE_TAX_RATE_FAILURE
} from './types';

export const doListVenues = ({ token, limit = null }) => ({
  type: VENUES_LIST_REQUEST,
  token,
  limit
});

export const doLoadMoreVenues = ({ token = null, limit = null, startAfter = null }) => ({
  type: VENUES_LOADMORE_REQUEST,
  token,
  limit,
  startAfter
});

export const doReadVenue: ReadVenueRequest = ({ token, id }) => ({
  type: VENUE_READ_REQUEST,
  token,
  id
});

export const doSetTutorials = currentTutorialIndex => ({
  type: SET_TUTORIAL,
  currentTutorialIndex
});

export const doSkipTutorials = ({ data }) => ({
  type: UPDATE_VENUE_REQUEST,
  data
});

export const doSkipTutorialsFailed = skipTutorialError => ({
  type: UPDATE_VENUE_FAILED,
  skipTutorialError
});

export const doSkipTutorialsSuccess = skipTutorialSuccess => ({
  type: UPDATE_VENUE_SUCCESS,
  skipTutorialSuccess
});

export const editVenueRequest = ({ data }) => ({
  type: EDIT_VENUE_REQUEST,
  data
});

export const editVenueSuccess = () => ({
  type: EDIT_VENUE_SUCCESS
});

export const editVenueFailed = error => ({
  type: EDIT_VENUE_FAILED,
  error
});

export const createProductRequest: CreateProductRequest = product => ({
  type: CREATE_PRODUCT_REQUEST,
  product
});

export const createProductSuccess = data => ({
  type: CREATE_PRODUCT_SUCCESS,
  data
});

export const createProductFailure = error => ({
  type: CREATE_PRODUCT_FAILURE,
  error
});

export const createProductCategoryRequest: CreateProductCategoryRequest = category => ({
  type: CREATE_PRODUCT_CATEGORY_REQUEST,
  category
});

export const createProductCategorySuccess = data => ({
  type: CREATE_PRODUCT_CATEGORY_SUCCESS,
  data
});

export const createProductCategoryFailure = error => ({
  type: CREATE_PRODUCT_CATEGORY_FAILURE,
  error
});

export const createRateTypeRequest: CreateRateTypeRequest = rateType => ({
  type: CREATE_RATE_TYPE_REQUEST,
  rateType
});

export const createRateTypeSuccess = data => ({
  type: CREATE_RATE_TYPE_SUCCESS,
  data
});

export const createRateTypeFailure = error => ({
  type: CREATE_RATE_TYPE_FAILURE,
  error
});

export const createTaxRateRequest = taxRate => ({
  type: CREATE_TAX_RATE_REQUEST,
  taxRate
});

export const createTaxRateSuccess = data => ({
  type: CREATE_TAX_RATE_SUCCESS,
  data
});

export const createTaxRateFailure = error => ({
  type: CREATE_TAX_RATE_FAILURE,
  error
});
