import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  doUploadImageToCloud,
  clearImageUpload,
  doEditProfileAvatar
} from 'src/containers/DashboardContainer/operations';
import Dropdown from '../Dropdown';
import ImageResizer from '../ImageResizer';
import IconLoader from '../../styles/Icons/Loader';
import IconCamera from '../../styles/Icons/Camera';
import IconKey from '../../styles/Icons/Key';
import IconLogout from '../../styles/Icons/LogOut';
import IconGear from '../../styles/Icons/Gear';
import ProfileDropdownStyles from './styles';
import { displayImage } from '../../utils/displayImage';
import Modal from '../Modal';
import ConfirmModalFooter from '../ConfirmModal/ConfirmModalFooter';
import Colors from '../../styles/Colors';
import Button from '../Button';
import ButtonRound from '../../styles/ButtonRound';
import { openChangePassword } from '../../containers/ChangePasswordModal/operations';

const ProfileDropdown = props => {
  const {
    user,
    iconType,
    logout,
    left,
    openChangePasswordModal,
    uploadImageToCloud,
    editProfileAvatar
  } = props;
  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('');
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);
  const [editAvatarModalOpen, setEditAvatarModalOpen] = useState(false);
  const [cameraIconVisible, setCameraIconVisible] = useState(props.requestStatus);

  const buildFileSelector = callback => {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.addEventListener('change', e => {
      callback(e.target.files[0]);
    });
    return fileSelector;
  };

  const previewFile = newFile => {
    setFile(newFile);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setFile(reader.result);
      setFilename(newFile.name);
      setAvatarModalOpen(true);
    });
    if (newFile) {
      reader.readAsDataURL(newFile);
    }
  };

  useEffect(() => {
    if (props.requestStatus === true) {
      setCameraIconVisible(props.requestStatus);
    }
  }, [props.requestStatus]);

  const fileSelector = buildFileSelector(previewFile);

  const handleFileUpload = () => {
    fileSelector.click();
  };

  const openEditAvatarModal = () => {
    setEditAvatarModalOpen(true);
  };

  const closeEditAvatarModal = () => {
    setEditAvatarModalOpen(false);
  };

  const closeAvatarModal = () => {
    setAvatarModalOpen(false);
  };

  const uploadProfileImage = (image, fileToUpload) => {
    uploadImageToCloud({
      fileToUpload: [fileToUpload],
      filename,
      uploadType: 'avatar'
    });
    setAvatarModalOpen(false);
  };

  const uploadIconVisible = () => {
    setCameraIconVisible(true);
  };

  const uploadIconHidden = () => {
    setCameraIconVisible(false);
  };

  const renderAvatar = () => {
    let avatar;
    if (iconType === 'settings') {
      avatar = <IconGear />;
    } else {
      avatar = displayImage(user.photo, user.firstname, user.lastname);
    }
    return avatar;
  };

  const removeAvatar = () => {
    editProfileAvatar({ userId: user.userId });
  };

  return (
    <ProfileDropdownStyles className="noselect">
      {avatarModalOpen && (
        <ImageResizer
          isOpen={avatarModalOpen}
          onRequestClose={closeAvatarModal}
          image={file}
          filename={filename}
          saveImage={uploadProfileImage}
        />
      )}
      <Modal
        isOpen={editAvatarModalOpen}
        onRequestClose={closeEditAvatarModal}
        height={400}
        width={500}
      >
        <ProfileDropdownStyles.Header>Edit Profile Picture</ProfileDropdownStyles.Header>
        <ProfileDropdownStyles.ModalAvatar
          onMouseEnter={() => {
            uploadIconVisible();
          }}
          onMouseLeave={() => {
            uploadIconHidden();
          }}
          hover={props.requestStatus}
        >
          {displayImage(user.photo, user.firstname, user.lastname)}
          {cameraIconVisible && (
            <ButtonRound
              bgColor={props.requestStatus ? 'transparent' : '#b0b0b0'}
              action={handleFileUpload}
              icon={props.requestStatus ? <IconLoader color={Colors.white} /> : <IconCamera />}
              disableShadow={true}
            />
          )}
        </ProfileDropdownStyles.ModalAvatar>
        <ConfirmModalFooter flex={0.2}>
          {user.photo && (
            <Button label={'Remove Picture'} color={Colors.gray7D} onClick={removeAvatar} />
          )}
          <Button label={'Go Back'} color="var(--colors-primary)" onClick={closeEditAvatarModal} />
        </ConfirmModalFooter>
      </Modal>
      <Dropdown
        title={`Hi, ${user.firstname} ${user.lastname}`}
        top={'calc(100% + 5px)'}
        right={'0px'}
        left={left || 'auto'}
        links={[
          {
            label: 'Edit Profile Image',
            onClick: () => {
              openEditAvatarModal();
            },
            icon: <IconCamera />
          },
          {
            label: 'Change Password',
            icon: <IconKey />,
            onClick: () => openChangePasswordModal()
          },
          {
            label: 'Log Out',
            onClick: () => logout(),
            icon: <IconLogout />
          }
        ]}
        icon={renderAvatar()}
        disableShadow={true}
        bgColor={!user.photo && iconType !== 'settings' ? `var(--colors-primary)` : 'Transparent'}
      />
    </ProfileDropdownStyles>
  );
};

const mapDispatchToProps = dispatch => ({
  openChangePasswordModal: () => dispatch(openChangePassword()),
  uploadImageToCloud: ({ fileToUpload, filename, uploadType }) =>
    dispatch(doUploadImageToCloud({ fileToUpload, filename, uploadType })),
  editProfileAvatar: ({ userId }) => dispatch(doEditProfileAvatar({ userId, photo: null }))
});

ProfileDropdown.propTypes = {
  user: PropTypes.object,
  requestStatus: PropTypes.bool,
  iconType: PropTypes.string,
  logout: PropTypes.func,
  editProfileAvatar: PropTypes.func,
  removeProfileAvatar: PropTypes.func,
  left: PropTypes.string,
  openChangePasswordModal: PropTypes.func
};

export default connect(null, mapDispatchToProps)(ProfileDropdown);
