import { ListCardProps } from 'src/Types/GlobalTypes';
import { Box } from '@chakra-ui/react';
import { TextPairing } from 'src/v2/Drawers/EventDetails/Overview/components/TextPairing';
import { BaseCard } from '../BaseCard';

const horizontalProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};

export const ListCard: React.FunctionComponent<ListCardProps> = ({
  titleIcon,
  headerBtnAction,
  list,
  fetching,
  footer: Footer,
  cardFooter,
  title,
  headerBtnIcon,
  headerBtnLabel,
  headerBtnIsDisabled = false,
  horizontal = false
}) => {
  const totalInList = list?.length;
  return (
    <BaseCard
      titleIcon={titleIcon}
      title={title}
      headerBtnAction={headerBtnAction}
      headerBtnLabel={headerBtnLabel}
      headerBtnIcon={headerBtnIcon}
      btnIsDisabled={headerBtnIsDisabled}
      cardFooter={cardFooter}
    >
      {list.map((item, i) => {
        const isLastItem = i === totalInList - 1;
        return (
          <Box mb={isLastItem ? 0 : '12px'} key={`list-${i}`} {...(horizontal && horizontalProps)}>
            <TextPairing
              fetchingData={fetching}
              title={item.title}
              subtitle={item.value || '---'}
              missingMessage={undefined}
              titleColor={undefined}
            />
          </Box>
        );
      })}
      {Footer && <Footer />}
    </BaseCard>
  );
};
