import { useState, useRef, useEffect, useCallback } from 'react';
import { Flex, TabList as ChakraTabList } from '@chakra-ui/react';
import Colors from 'src/styles/Colors';
import { CaretRight, CaretLeft } from '../../Primitives/Icons';
import { CustomTab } from '../CustomTab';

// This is custom effect that calls onResize when page load and on window resize
const useResizeEffect = (onResize, deps = []) => {
  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, onResize]);
};

export const TabList = ({ tabHeaders, tabIndex, rightActions, disableTabs }) => {
  const [container, setContainer] = useState(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollRight, setScrollRight] = useState(0);

  const containerRef = useRef(null);
  // containerRef.current = container;

  const handleScroll = (e, scrollOffset) => {
    e.preventDefault();
    containerRef.current.scrollLeft += scrollOffset;
    setScrollLeft(containerRef.current.scrollLeft);
    setScrollRight(scrollRight - scrollOffset);
  };

  const listenScroll = e => {
    const { scrollWidth, clientWidth, scrollLeft: newScrollLeft } = e.target;
    setScrollLeft(newScrollLeft);
    setScrollRight(scrollWidth - clientWidth - newScrollLeft);
  };

  const setScrollableOnResize = useCallback(() => {
    if (!containerRef.current) return;
    const { clientWidth, scrollWidth } = containerRef.current;
    const maxScroll = scrollWidth - clientWidth;
    setScrollRight(maxScroll);
    setIsScrollable(scrollWidth > clientWidth);
  }, [containerRef]);

  useResizeEffect(setScrollableOnResize, [containerRef]);

  useEffect(() => {
    if (containerRef) {
      const { clientWidth, scrollWidth } = containerRef.current;
      const maxScroll = scrollWidth - clientWidth;
      setScrollRight(maxScroll);
      setIsScrollable(scrollWidth > clientWidth);
    }
  }, []);

  return (
    <Flex
      overflow="hidden"
      minH="48px"
      borderBottom={`2px solid ${Colors.grayED}`}
      paddingBottom="10px"
      marginRight="5px"
    >
      {/* TODO: #544 TabList arrows are not displaying */}
      {isScrollable && scrollLeft > 0 && (
        <Flex
          display="inline-flex"
          backgroundColor="transparent"
          position="relative"
          alignItems="center"
          justifyContent="center"
          verticalAlign="middle"
          cursor="pointer"
          onClick={e => handleScroll(e, -30)}
          zIndex="2"
          width="25px"
          height="100%"
        >
          <CaretLeft w={6} h={6} color={Colors.gray18} />
        </Flex>
      )}
      <ChakraTabList
        flex={1}
        marginRight="6px"
        ref={containerRef}
        mb={0}
        css={{
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
        // borderBottom={`2px solid ${Colors.grayED}`}
        // minH="46px"
      >
        <Flex>
          {tabHeaders?.map((tab, key) => (
            <CustomTab
              key={key}
              title={tab.title}
              tabIndex={tabIndex}
              tabNumber={key}
              isDisabled={tab.isDisabled || disableTabs}
              tooltip={tab.tooltip}
            />
          ))}
        </Flex>
      </ChakraTabList>
      {isScrollable && scrollRight > 0 && (
        <Flex
          display="inline-flex"
          backgroundColor="transparent"
          position="relative"
          alignItems="center"
          justifyContent="center"
          verticalAlign="middle"
          cursor="pointer"
          onClick={e => handleScroll(e, 30)}
          zIndex="2"
          right={0}
          width="25px"
          height="100%"
        >
          <CaretRight w={6} h={6} />
        </Flex>
      )}
      {rightActions?.map(comp => {
        return comp.component;
      })}
    </Flex>
  );
};
