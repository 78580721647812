import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useActions } from 'src/hooks/useActions';
import {
  openDocLibraryUpload,
  closeDocLibraryUpload,
  docLibraryUploadRequest,
  getDocumentLibraryRequest,
  deleteDocumentFromLibraryRequest,
  getDocumentLibraryByIdRequest,
  clearDocumentLibrarySelected,
  docLibraryUpdateRequest,
  downloadDocumentLibraryRequest
} from 'src/containers/DocumentLibrary/operations';

import { DocumentLibaryReturn, RootStates } from 'src/Types';

export type LibaryDocumentOption = {
  value: string;
  label: string;
  defaultFor?: string[];
  filename: string;
  url: string;
  description: string;
  reference: string;
};

export type SelectedLibraryDocument = {
  id: string;
  documentName: string;
  libraryFileUpload: string | null;
  formDoc: boolean;
  description: string;
  defaultFor?: { label: string; value: string }[];
};

export type DefautDocumentLibraryFormValues = {
  documentName: string;
  libraryFileUpload: null | string;
  formDoc: boolean;
  description: string;
  defaultFor?: { label: string; value: string }[];
};

export const useDocumentLibrary = (): DocumentLibaryReturn => {
  const dispatch = useDispatch();
  const [mode, setMode] = useState('create');

  const {
    docLibraryUploadOpen,
    documentLibraryFiles,
    fetchingDocumentLibrary,
    docLibraryUploadFetchingStatus: uploadingDocumentToLibrary,
    selectedDocumentLibrary
  } = useSelector((state: RootStates) => state.documentLibraryReducer);

  const documentLibraryOptions: LibaryDocumentOption[] =
    documentLibraryFiles &&
    documentLibraryFiles
      .filter(doc => doc.defaultFor?.includes('none'))
      .map(doc => ({
        value: doc.documentId,
        label: doc.documentName,
        defaultFor: doc.defaultFor,
        filename: doc.documentName,
        url: doc.url,
        description: doc.description,
        reference: doc.documentId
      }));

  useEffect(() => {
    if (selectedDocumentLibrary) {
      setMode('edit');
    } else {
      setMode('create');
    }
  }, [selectedDocumentLibrary]);

  const documentSelected: SelectedLibraryDocument | undefined = selectedDocumentLibrary && {
    id: selectedDocumentLibrary?.documentId,
    documentName: selectedDocumentLibrary?.documentName,
    libraryFileUpload: null,
    formDoc: false,
    description: selectedDocumentLibrary?.description,
    defaultFor: selectedDocumentLibrary?.defaultFor?.map(data => ({ label: data, value: data }))
  };

  const defaultValues: DefautDocumentLibraryFormValues = {
    documentName: '',
    libraryFileUpload: null,
    formDoc: false,
    description: '',
    defaultFor: []
  };

  const updateDocumentLibrary = ({ data }) => {
    dispatch(
      docLibraryUpdateRequest({ formData: data, documentId: selectedDocumentLibrary?.documentId })
    );
  };

  const filterDocumentByEventType = eventType => {
    if (eventType) {
      const documentsWithDefault = documentLibraryFiles.filter(
        doc => doc.defaultFor && doc.defaultFor.length
      );

      return documentsWithDefault.filter(doc => doc.defaultFor?.includes(eventType.toLowerCase()));
    }

    return [];
  };

  /**
   *
   * @type {actions}
   */

  const actions = useActions({
    docLibraryUploadRequest: ({ formData }) => docLibraryUploadRequest({ formData }),
    deleteDocumentFromLibrary: deleteDocumentFromLibraryRequest,
    openDocLibraryUpload,
    getLibraryDocuments: getDocumentLibraryRequest,
    closeDocLibraryUpload,
    getDocumentLibraryByIdRequest,
    clearDocumentLibrarySelected,
    downloadDocumentLibraryRequest
  });

  return {
    docLibraryUploadOpen,
    fetchingDocumentLibrary,
    uploadingDocumentToLibrary,
    documents: documentLibraryFiles,
    documentLibraryOptions,
    documentSelected,
    defaultValues,
    selectedDocumentLibrary,
    mode,
    updateDocumentLibrary,
    filterDocumentByEventType,
    ...actions
  };
};
