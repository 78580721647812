import React, { FC } from 'react';
import { FormControl, HStack } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { StyledSwitch } from './styles';
import { FormLabel } from '../../Primitives';
import { Props } from './types';

export const SwitchInputWithLabel: FC<Props> = ({ label, name, isRequired, isDisabled }) => {
  const { control } = useFormContext();

  return (
    <FormControl>
      <HStack w="100%" justify="space-between">
        <FormLabel label={label} />
        <Controller
          name={name}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <StyledSwitch
              {...field}
              isChecked={field.value}
              isRequired={isRequired}
              isDisabled={isDisabled}
            />
          )}
        />
      </HStack>
    </FormControl>
  );
};
