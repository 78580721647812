import { takeLatest, call, put, select } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import { Icon } from '@chakra-ui/react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import {
  closeModalLoader,
  openModalLoader,
  openToast
} from 'src/containers/ModalLoader/operations';
import { createFieldAction, getFieldsAction, updateFieldAction } from './actions';
import {
  addCustomFieldFailure,
  addCustomFieldSuccess,
  closeCustomFieldDrawer,
  getCustomFieldsFailure,
  getCustomFieldsRequest,
  getCustomFieldsSuccess,
  updateCustomFieldFailure,
  updateCustomFieldSuccess
} from './operations';
import {
  CREATE_CUSTOM_FIELD_REQUEST,
  LIST_CUSTOM_FIELD_REQUEST,
  UPDATE_CUSTOM_FIELD_REQUEST
} from './types';

const getToken = state => state.loginReducer.token;

function* workerCreateCustomField({ newField }) {
  try {
    const token = yield select(getToken);
    // yield put(
    //   openModalLoader({
    //     showProgressBar: true,
    //     modalLoaderHeader: 'Creating Field',
    //     modalLoaderMessage: 'Adding Field to database'
    //   })
    // );
    const response = yield call(createFieldAction, { token, newField });
    if (response?.data?.success) {
      toast('Custom field added successfully', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
      yield put(closeCustomFieldDrawer());
      // yield put(closeModalLoader());
      yield put(addCustomFieldSuccess());
      // yield put(openToast('Field Created'));
      yield put(getCustomFieldsRequest());
    }
    if (response?.data?.error) {
      // yield put(closeModalLoader());
      throw new Error(response.data.error);
    }
  } catch (error) {
    yield put(addCustomFieldFailure(error));
  }
}

function* workerUpdateCustomField({ propertiesToUpdate }) {
  try {
    const token = yield select(getToken);
    // yield put(
    //   openModalLoader({
    //     showProgressBar: true,
    //     modalLoaderHeader: 'Updating Field',
    //     modalLoaderMessage: 'Updating Field to database'
    //   })
    // );
    const response = yield call(updateFieldAction, { token, propertiesToUpdate });

    if (response?.data?.success) {
      toast('Custom field edited successfully', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
      // yield put(openToast('Field Updated'));
      yield put(closeCustomFieldDrawer());
      yield put(getCustomFieldsRequest());
      // yield put(closeModalLoader());
      yield put(updateCustomFieldSuccess());
    }
    if (response?.data?.error) {
      throw new Error(response.data.error);
    }
  } catch (error) {
    yield put(updateCustomFieldFailure(error));
  }
}

function* workerGetCustomFields() {
  try {
    const token = yield select(getToken);
    const response = yield call(getFieldsAction, { token });
    if (response?.data?.success) {
      const {
        customFields,
        fieldCategoryOptions,
        fieldGroupOptions,
        fieldCategoriesArray,
        fieldGroupsArray,
        fieldsByCategory,
        fieldsByGroup,
        onCreateFields,
        fieldGroupObject,
        notRequiredFields,
        venueRequired
      } = response.data;

      yield put(
        getCustomFieldsSuccess({
          customFields,
          fieldCategoryOptions,
          fieldGroupOptions,
          fieldCategoriesArray,
          fieldGroupsArray,
          fieldsByCategory,
          fieldsByGroup,
          onCreateFields,
          fieldGroupObject,
          notRequiredFields,
          venueRequired
        })
      );
    }
    if (response?.data?.error) {
      throw new Error(response.data.error);
    }
  } catch (error) {
    yield put(getCustomFieldsFailure(error));
  }
}

export function* watcherCreateCustomField() {
  yield takeLatest(CREATE_CUSTOM_FIELD_REQUEST, workerCreateCustomField);
}
export function* watcherGetCustomFields() {
  yield takeLatest(LIST_CUSTOM_FIELD_REQUEST, workerGetCustomFields);
}

export function* watcherUpdateCustomField() {
  yield takeLatest(UPDATE_CUSTOM_FIELD_REQUEST, workerUpdateCustomField);
}
