import React from 'react';
import AddEventStyles from './styles';

export const AddEventBody = props => {
  return <AddEventStyles.Body {...props} />;
};

export const Row = props => {
  return <AddEventStyles.BodyRow {...props} />;
};

export const Key = props => {
  return <AddEventStyles.BodyKey {...props} />;
};

export const Error = props => {
  return <AddEventStyles.BodyError {...props} />;
};

export const InfoText = props => {
  return <AddEventStyles.InfoText {...props} />;
};

export const FormLabel = props => {
  return <AddEventStyles.FormLabel {...props} />;
};
