import React from 'react';
import PropTypes from 'prop-types';
import { TextAreaWithLabel } from 'src/v2/UIComponents/FormComponents';
import { NOTES_LABEL, NOTES_PLACEHOLDER } from 'src/v2/Drawers/EventFormDrawer/constants';

export const RootNotesInput = ({ register, errors }) => {
  const notesProps = {
    label: NOTES_LABEL,
    placeholder: NOTES_PLACEHOLDER,
    name: 'notes'
  };
  return (
    <TextAreaWithLabel
      {...notesProps}
      height={'150px'}
      resize="none"
      placeholder={NOTES_PLACEHOLDER}
      register={register}
      errors={errors}
    />
  );
};

export const NotesInput = React.memo(RootNotesInput);

NotesInput.propTypes = {
  consultants: PropTypes.object,
  users: PropTypes.array,
  errors: PropTypes.object,
  register: PropTypes.func
};
