const getEndOfDay = date => new Date(date.setHours(23, 59, 59, 999));
const getStartOfYear = date => new Date(date.getFullYear(), 0, 1);
const getEndOfYear = date => getEndOfDay(new Date(date.getFullYear(), 11, 31));

const dateValidation = (filterBy, date) => {
  if (filterBy === 'today') {
    return date.toDateString() === new Date().toDateString();
  }

  // get this week's dates
  if (filterBy === 'this-week') {
    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();
    // get the first day of the week
    const startDate = new Date(todayObj.setDate(todayDate - todayDay));
    // get the last day of the week
    const endDate = getEndOfDay(new Date(todayObj.setDate(todayDate + (6 - todayDay))));
    return date >= startDate && date <= endDate;
  }

  // get last week dates
  if (filterBy === 'last-week') {
    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();
    // get the first day of the week
    const startDate = new Date(todayObj.setDate(todayDate - todayDay - 7));

    // get the last day of the week
    const endDate = getEndOfDay(new Date(todayObj.setDate(todayDate - todayDay - 1)));

    return date >= startDate && date <= endDate;
  }

  // get last month dates
  if (filterBy === 'last-month') {
    const todayObj = new Date();
    // get the first day of the month
    const startDate = new Date(todayObj.getFullYear(), todayObj.getMonth() - 1, 1);
    // get the last day of the month
    const endDate = getEndOfDay(new Date(todayObj.getFullYear(), todayObj.getMonth(), 0));

    return date >= startDate && date <= endDate;
  }

  // get last year dates
  if (filterBy === 'last-year') {
    const todayObj = new Date();
    const lastYear = new Date(todayObj.getFullYear() - 1, 1, 1);
    // get the first day of the year
    const startDate = getStartOfYear(lastYear);

    // get the last day of the year
    const endDate = getEndOfYear(lastYear);
    return date >= startDate && date <= endDate;
  }

  if (filterBy === 'this-year') {
    const todayObj = new Date();
    // get the first day of the year
    const startDate = getStartOfYear(todayObj);

    // get the last day of the year
    const endDate = getEndOfYear(todayObj);
    return date >= startDate && date <= endDate;
  }

  // get last quarter dates
  if (filterBy === 'last-quarter') {
    const todayObj = new Date();
    const todayMonth = todayObj.getMonth();
    const todayYear = todayObj.getFullYear();
    // get the first day of the quarter
    const startDate = new Date(todayObj.setMonth(todayMonth - (todayMonth % 3) - 3));
    // get the last day of the quarter
    const endDate = new Date(todayObj.setFullYear(todayYear - (todayMonth % 3) - 3));
    return date >= startDate && date <= endDate;
  }

  // get year todate dates
  if (filterBy === 'year-to-date') {
    const todayObj = new Date();
    const todayYear = todayObj.getFullYear();
    // get the first day of the year
    const startDate = new Date(todayObj.setDate(1));
    // get the last day of the year
    const endDate = new Date(todayObj.setFullYear(todayYear));
    return date >= startDate && date <= endDate;
  }

  // get month todate dates
  if (filterBy === 'month-to-date') {
    const todayObj = new Date();
    const todayMonth = todayObj.getMonth();
    const todayYear = todayObj.getFullYear();
    // get the first day of the month
    const startDate = new Date(todayObj.setDate(1));
    // get the last day of the month
    const endDate = new Date(todayObj.setFullYear(todayYear, todayMonth + 1, 0));
    return date >= startDate && date <= endDate;
  }

  return false;
};

export const dateFilterHelper = (filterBy, dateValue) => {
  const date = new Date(dateValue);

  if (filterBy?.length === 1) {
    return dateValidation(filterBy[0], date);
  }

  const filterValidation = filterBy?.map(filter => {
    return dateValidation(filter, date);
  });

  return filterValidation?.every(val => val === true);
};
