import { takeLatest, put, call, select } from 'redux-saga/effects';
import { GET_GUESTS_REQUEST } from '../GuestContainer/types';
import {
  GET_GROUPS_REQUEST,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAILURE,
  ADD_GROUP_REQUEST,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILURE,
  BATCH_EDIT_GUESTS_FAILURE,
  BATCH_EDIT_GUESTS_REQUEST,
  BATCH_EDIT_GUESTS_SUCCESS,
  BATCH_DELETE_GUESTS_REQUEST,
  BATCH_DELETE_GUESTS_FAILURE,
  BATCH_DELETE_GUESTS_SUCCESS,
  GET_EXPORT_DATA_FAILURE,
  GET_EXPORT_DATA_REQUEST,
  GET_EXPORT_DATA_SUCCESS,
  EDIT_GROUP_FAILURE,
  EDIT_GROUP_REQUEST,
  EDIT_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  HOST_SEND_EMAIL_REQUEST,
  HOST_UPDATE_EVENT_REQUEST,
  HOST_SEND_BATCH_EMAIL_SUCCESS,
  HOST_SEND_BATCH_EMAIL_FAILURE,
  HOST_SEND_BATCH_EMAIL_REQUEST,
  GET_LAYOUT_REQUEST
} from './types';

import {
  batchDeleteGuest,
  addGroup,
  getGroups,
  getExportData,
  editGroup,
  deleteGroup,
  hostSendEmailAndUpdateEvent,
  hostUpdateEventAction,
  hostSendBatchEmail,
  getLayout
} from './actions';
import {
  closeAddGroupModal,
  closeDeleteMultipleGuestModal,
  closeEditGroupModal,
  closeDeleteGroupModal,
  hostUpdateEventSuccess,
  hostUpdateEventFailure,
  hostSendEmailSuccess,
  hostSendEmailFailed,
  closeSendBatchEmailModal,
  getLayoutError,
  getLayoutSuccess
} from './operations';
import { editGuest } from '../GuestContainer/actions';
import { getEventRequest } from '../EventsContainer/operations';
import { doGetEvent } from '../DashboardContainer/operations';
import { closeChangeGroupName } from '../ChangeGroupNameModal/operations';
import { closeConfirmModal } from '../ConfirmModal/operations';
import { doGetGuests } from '../GuestContainer/operations';

const getEventId = state => state.loginReducer.eventId;

function* workerGroups({ token, eventId }) {
  try {
    const response = yield call(getGroups, { token, eventId });

    const { groups } = response.data;

    yield put({
      type: GET_GROUPS_SUCCESS,
      groups
    });
  } catch (error) {
    yield put({ type: GET_GROUPS_FAILURE, error: error.response.data });
  }
}

function* workerAddGroup({ token, data }) {
  try {
    const response = yield call(addGroup, { token, data });

    const { group } = response.data;
    yield put({
      type: ADD_GROUP_SUCCESS,
      group
    });
    yield put(closeAddGroupModal());
  } catch (error) {
    yield put({ type: ADD_GROUP_FAILURE, error: error.response.data });
  }
}

function* workerEditGuestBatch({ token, guests, data }) {
  try {
    const response = yield call(editGuest, { token, guestId: guests, data });
    const eventId = yield select(getEventId);
    if (response.data.success) {
      yield put({ type: BATCH_EDIT_GUESTS_SUCCESS, success: true, guests, data });
      yield put(doGetGuests({ token, eventId, orderBy: 'name', order: 'asc' }));
    }
  } catch (error) {
    yield put({
      type: BATCH_EDIT_GUESTS_FAILURE,
      error: {
        message: 'Error editing guests. Please try again!'
      }
    });
  }
}

function* workerDeleteGuestBatch({ token, guests, eventId }) {
  try {
    const response = yield call(batchDeleteGuest, { token, guestId: guests, eventId });
    if (response.data.success) {
      yield put({ type: BATCH_DELETE_GUESTS_SUCCESS, success: true, guests });
      yield put(doGetGuests({ token, eventId, orderBy: 'name', order: 'asc' }));
    }
    yield put(closeDeleteMultipleGuestModal());
  } catch (error) {
    yield put({
      type: BATCH_DELETE_GUESTS_FAILURE,
      error: {
        message: 'Error deleting guests. Please try again!'
      }
    });
  }
}

function* workerExportData({ token, eventId, venueId }) {
  try {
    const response = yield call(getExportData, { token, eventId, venueId });

    const { data } = response.data;

    yield put({
      type: GET_EXPORT_DATA_SUCCESS,
      success: true,
      data
    });
  } catch (error) {
    yield put({ type: GET_EXPORT_DATA_FAILURE, error: error.response.data });
  }
}

function* workerEditGroup({ token, groupId, data, eventId }) {
  try {
    const response = yield call(editGroup, { token, groupId, data });

    const { group } = response.data;

    yield put({
      type: EDIT_GROUP_SUCCESS,
      group
    });
    yield put(closeEditGroupModal());
    yield put({
      type: GET_GUESTS_REQUEST,
      token,
      eventId
    });
    yield put({
      type: GET_GROUPS_REQUEST,
      token,
      eventId
    });
    yield put(closeChangeGroupName());
  } catch (error) {
    yield put({ type: EDIT_GROUP_FAILURE, error: error.response.data });
  }
}

function* workerDeleteGroup({ token, groupId, eventId }) {
  try {
    const response = yield call(deleteGroup, { token, groupId });

    const { user } = response;

    yield put({
      type: DELETE_GROUP_SUCCESS,
      user
    });
    yield put(closeDeleteGroupModal());
    yield put({
      type: GET_GUESTS_REQUEST,
      token,
      eventId
    });
    yield put({
      type: GET_GROUPS_REQUEST,
      token,
      eventId
    });
    yield put(closeConfirmModal());
  } catch (error) {
    yield put({ type: DELETE_GROUP_FAILURE, error: error.response.data });
  }
}

function* workerHostSendEmailAndUpdateGuest({
  id,
  sendEmail,
  appEmailSent,
  event,
  token,
  guestId,
  orderBy = 'name',
  order = 'asc'
}) {
  try {
    const response = yield call(hostSendEmailAndUpdateEvent, {
      id,
      sendEmail,
      appEmailSent,
      event,
      token,
      guestId
    });
    if (response.data && response.data.success) {
      const { rowIndex } = sendEmail.guestDetails;
      yield put({
        type: GET_GUESTS_REQUEST,
        token,
        eventId: id,
        orderBy,
        order
      });
      yield put(hostSendEmailSuccess({ success: response.data, rowIndex }));
    }
  } catch (error) {
    yield put(hostSendEmailFailed({ error }));
  }
}

function* workerHostUpdateEvent({ id, event, token }) {
  try {
    const response = yield call(hostUpdateEventAction, { id, event, token });
    if (response.data && response.data.success) {
      yield put(getEventRequest({ token, eventId: id }));
      yield put(doGetEvent({ token, eventId: id }));
      yield put(hostUpdateEventSuccess({ success: response.data }));
    }
  } catch (error) {
    yield put(hostUpdateEventFailure({ error }));
  }
}

function* workerSendBatchEmail({ token, eventId, guestIds, subject, message }) {
  try {
    const response = yield call(hostSendBatchEmail, { token, eventId, guestIds, subject, message });

    if (response.data && response.data.success) {
      yield put({
        type: HOST_SEND_BATCH_EMAIL_SUCCESS
      });
      yield put(closeSendBatchEmailModal());
    }
  } catch (error) {
    yield put({ type: HOST_SEND_BATCH_EMAIL_FAILURE, error: error.response.data });
  }
}

function* workerGetLayout({ token, layoutId }) {
  try {
    const response = yield call(getLayout, { token, layoutId });
    const { layout } = response.data;
    yield put(getLayoutSuccess({ layout }));
  } catch (error) {
    yield put(getLayoutError({ error: error.response.data }));
  }
}

export function* watcherGroups() {
  yield takeLatest(GET_GROUPS_REQUEST, workerGroups);
}

export function* watcherAddGroup() {
  yield takeLatest(ADD_GROUP_REQUEST, workerAddGroup);
}

export function* watcherEditGuestBatch() {
  yield takeLatest(BATCH_EDIT_GUESTS_REQUEST, workerEditGuestBatch);
}

export function* watcherDeleteGuestBatch() {
  yield takeLatest(BATCH_DELETE_GUESTS_REQUEST, workerDeleteGuestBatch);
}

export function* watcherExportData() {
  yield takeLatest(GET_EXPORT_DATA_REQUEST, workerExportData);
}

export function* watcherEditGroup() {
  yield takeLatest(EDIT_GROUP_REQUEST, workerEditGroup);
}

export function* watcherDeleteGroup() {
  yield takeLatest(DELETE_GROUP_REQUEST, workerDeleteGroup);
}

export function* watcherHostSendEmailAndUpdateEvent() {
  yield takeLatest(HOST_SEND_EMAIL_REQUEST, workerHostSendEmailAndUpdateGuest);
}

export function* watcherHostUpdateEvent() {
  yield takeLatest(HOST_UPDATE_EVENT_REQUEST, workerHostUpdateEvent);
}

export function* watcherSendBatchEmail() {
  yield takeLatest(HOST_SEND_BATCH_EMAIL_REQUEST, workerSendBatchEmail);
}

export function* watchGetLayout() {
  yield takeLatest(GET_LAYOUT_REQUEST, workerGetLayout);
}
