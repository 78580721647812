import { takeLatest, select, call, put } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import { Spinner } from '@chakra-ui/react';
import { closeModalLoader } from 'src/containers/ModalLoader/operations';
import PROPOSAL_TYPES from './types';
import { generateProposalOperations } from './operations';
import { generateContractAction, generateProposalAction } from './actions';

const { GENERATE_PROPOSAL, GENERATE_CONTRACT } = PROPOSAL_TYPES;

const getToken = state => state.loginReducer.token;

function* workerRequestGenerateProposal({ eventId, proposalId }) {
  try {
    const token = yield select(getToken);
    toast('Generating Proposal', {
      icon: <Spinner />
    });
    const writeResponse = yield call(generateProposalAction, {
      token,
      eventId,
      proposalId
    });
    if (writeResponse) {
      const filename = 'proposal';
      const url = window.URL.createObjectURL(new Blob([writeResponse.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${filename}.pdf`);
      document.body.appendChild(link);
      link.click();
      yield put(generateProposalOperations.success());
    }
  } catch (error) {
    // TODO: #540 workerRequestGenerateProposal dispaly toast with error on error
    console.log({ autoFillError: error });
    toast.error(error?.response?.data);
  }
}

function* workerRequestGenerateContract({ eventId, contractId }) {
  try {
    const token = yield select(getToken);
    const writeResponse = yield call(generateContractAction, {
      token,
      eventId,
      contractId
    });
    if (writeResponse) {
      yield put(closeModalLoader());
      yield put(generateProposalOperations.success());
    }
  } catch (error) {
    // TODO: #539 workerRequestGenerateContract: Display toast with error on error
    console.log({ generateContractError: error });
    toast.error(error?.response?.data);
  }
}

export function* watchGenerateProposal() {
  yield takeLatest(GENERATE_PROPOSAL.REQUEST, workerRequestGenerateProposal);
}

export function* watchGenerateContract() {
  yield takeLatest(GENERATE_CONTRACT.REQUEST, workerRequestGenerateContract);
}
