import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useVenue } from 'src/v2/Hooks/CollectionHooks';
import { useDefaultValues } from 'src/v2/Hooks/CollectionHooks/Events/useDefaultValues';
import { SelectInputWithLabel } from 'src/v2/UIComponents/FormComponents';
import { LAYOUT_PLACEHOLDER, LAYOUT_LABEL, LAYOUT_REQUIRED } from '../constants';

const RootLayoutInput = ({ bookEvent }) => {
  const { setValue, control } = useFormContext();
  const roomId = useWatch({ control, name: 'room' });
  const currentLayout = useWatch({ control, name: 'layout' });
  const { layouts } = useVenue();

  const roomLayouts = layouts?.filter(layout => layout.roomId === roomId);
  const roomLayoutOptions = roomLayouts?.map(layout => ({ value: layout.id, label: layout.name }));

  const defaultValues = useDefaultValues();

  const defaultLayout = defaultValues?.layout;

  useEffect(() => {
    const layoutOption =
      currentLayout && roomLayoutOptions?.filter(option => option.value === currentLayout);
    const layoutExists = layoutOption?.length > 0;
    if (roomLayoutOptions && !layoutExists && currentLayout) {
      setValue('layout', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomLayoutOptions, defaultLayout, currentLayout]);

  useEffect(() => {
    if (defaultLayout) {
      setValue('layout', defaultLayout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLayout]);

  const layoutProps = {
    isRequired: bookEvent,
    name: 'layout',
    label: LAYOUT_LABEL,
    placeholder: LAYOUT_PLACEHOLDER,
    options: roomLayoutOptions || [],
    isLoading: false,
    isDisabled: !roomId,
    rules: { required: bookEvent && LAYOUT_REQUIRED }
  };

  return <SelectInputWithLabel {...layoutProps} />;
};

export const LayoutInput = React.memo(RootLayoutInput);
