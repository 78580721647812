import React from 'react';
import { Wrapper, MessageWrapper, StyledImage, StyledTitle, StyledMessage } from './styles';
import icon from '../../assets/layoutImage/computer.png';

const ScreenError = () => {
  return (
    <Wrapper>
      <MessageWrapper>
        <StyledImage src={icon} />
        <StyledTitle>Larger screens are always better!</StyledTitle>
        <StyledMessage>
          For the best experience using your portal, please expand your browser or login on your
          desktop for full access to all features.
        </StyledMessage>
      </MessageWrapper>
    </Wrapper>
  );
};

export default ScreenError;
