import { useState } from 'react';
import { useEvents } from 'src/v2/Hooks/CollectionHooks';
import { useDefaultValues } from 'src/v2/Hooks/CollectionHooks/Events/useDefaultValues';
import { useDrawers } from 'src/v2/Hooks/HelperHooks';
import { Wizard } from 'src/v2/UIComponents/Drawers/Wizard';
import { LeadForm } from '../LeadsFormDrawer/LeadForm';
// import { AppointmentForm } from './AppointmentForm/AppointmentForm';
// import { StepOne } from './StepOne';

const AddEventPage = ({ defaultDate }) => (
  <LeadForm defaultDate={defaultDate} bookEvent={true} mode="create" tabIndex={1} />
);

// const AddAppointmentPage = () => <AppointmentForm />;

export const CalendarWizardDrawer = ({ defaultWizardDate }) => {
  const { calendarWizardOpen, closeCalendarWizard } = useDrawers();
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [calendarEventType, setCalendarEventType] = useState('event');
  const defaultValues = useDefaultValues();

  // reset for when appointment form is implemented
  // useEffect(() => {
  //   setCalendarEventType('');
  // }, [calendarWizardOpen]);

  // const { updateEvent, monthStart, monthEnd, createEvent } = useEvents();
  const { updateEvent, createEvent } = useEvents();
  const handleSave = data => {
    if (calendarEventType === 'appointment') {
      const appointments = [data];
      updateEvent({
        data: { appointments },
        eventId: data.eventId,
        isAppointment: true,
        closeAction: closeCalendarWizard
      });
    }
    if (calendarEventType === 'event') {
      createEvent(data);
    }
  };

  // useEffect(() => {
  //   if (calendarEventType === 'appointment') {
  //     setDefaultValues({ start: defaultWizardDate, organizer: userId });
  //   }
  //   if (calendarEventType === 'event') {
  //     setDefaultValues({
  //       start: defaultWizardDate,
  //       leadInfo: {
  //         status: 'eventBooked'
  //       }
  //     });
  //   }
  // }, [calendarEventType, defaultWizardDate]);

  // const StepOneWithProps = (
  //   <StepOne
  //     defaultDate={defaultWizardDate}
  //     setCalendarEventType={setCalendarEventType}
  //     calendarEventType={calendarEventType}
  //   />
  // );

  // const Page2 = () => {
  //   switch (calendarEventType) {
  //     case 'event':
  //       return <AddEventPage defaultDate={defaultWizardDate} />;
  //     case 'appointment':
  //       return <AddAppointmentPage />;
  //     default:
  //       return <div>default page</div>;
  //   }
  // };

  // const page2Title = calendarEventType === 'event' ? 'Add an event' : 'Add an appointment';
  // feat/calendar-appointments
  // const steps = [
  //   { component: StepOneWithProps, step: 0, title: 'Add To Calendar' },
  //   { component: Page2, step: 1, title: page2Title }
  // ];

  const steps = [
    {
      component: () => <AddEventPage defaultDate={defaultWizardDate} />,
      step: 0,
      title: 'Add an event'
    }
  ];

  // TODO: #553 Log Activity Form Drawer: Follow this pattern

  return (
    <Wizard
      disableNext={!calendarEventType}
      steps={steps}
      defaultValues={defaultValues}
      touchedDefaultValues={{
        consultant: defaultValues?.consultant,
        staff: defaultValues?.staff,
        start: defaultValues?.start,
        leadInfo: defaultValues?.leadInfo
      }}
      saveAction={handleSave}
      cancelAction={closeCalendarWizard}
      isOpen={calendarWizardOpen}
      formId="activityDrawer"
    />
  );
};
