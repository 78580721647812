import {
  GET_CUSTOM_OBJECTS_REQUEST,
  GET_CUSTOM_OBJECTS_SUCCESS,
  GET_CUSTOM_OBJECTS_FAILURE,
  ADD_CUSTOM_OBJECTS_REQUEST,
  ADD_CUSTOM_OBJECTS_SUCCESS,
  ADD_CUSTOM_OBJECTS_FAILURE,
  UPDATE_CUSTOM_OBJECTS_REQUEST,
  UPDATE_CUSTOM_OBJECTS_SUCCESS,
  UPDATE_CUSTOM_OBJECTS_FAILURE,
  DELETE_CUSTOM_OBJECTS_REQUEST,
  DELETE_CUSTOM_OBJECTS_SUCCESS,
  DELETE_CUSTOM_OBJECTS_FAILURE,
  RESET_ALERTS,
  UPDATE_CUSTOM_OBJECTS_CATEGORY_REQUEST,
  UPDATE_CUSTOM_OBJECTS_CATEGORY_SUCCESS
} from './types';

export const getCustomObjectsRequest = () => ({
  type: GET_CUSTOM_OBJECTS_REQUEST
});

export const getCustomObjectsSuccess = ({ objects, categories, types }) => ({
  type: GET_CUSTOM_OBJECTS_SUCCESS,
  objects,
  categories,
  types
});

export const getCustomObjectsFailure = ({ error }) => ({
  type: GET_CUSTOM_OBJECTS_FAILURE,
  error
});

export const addCustomObjectsRequest = ({ shape }) => ({
  type: ADD_CUSTOM_OBJECTS_REQUEST,
  shape
});

export const addCustomObjectsSuccess = ({ shape }) => ({
  type: ADD_CUSTOM_OBJECTS_SUCCESS,
  shape
});

export const addCustomObjectsFailure = ({ error }) => ({
  type: ADD_CUSTOM_OBJECTS_FAILURE,
  error
});

export const updateCustomObjectsRequest = ({ id, shape }) => ({
  type: UPDATE_CUSTOM_OBJECTS_REQUEST,
  id,
  shape
});

export const updateCustomObjectsSuccess = ({ shape }) => ({
  type: UPDATE_CUSTOM_OBJECTS_SUCCESS,
  shape
});

export const updateCustomObjectsFailure = ({ error }) => ({
  type: UPDATE_CUSTOM_OBJECTS_FAILURE,
  error
});

export const deleteCustomObjectsRequest = ({ id }) => ({
  type: DELETE_CUSTOM_OBJECTS_REQUEST,
  id
});

export const deleteCustomObjectsSuccess = () => ({
  type: DELETE_CUSTOM_OBJECTS_SUCCESS
});

export const deleteCustomObjectsFailure = ({ error }) => ({
  type: DELETE_CUSTOM_OBJECTS_FAILURE,
  error
});

export const resetAlerts = () => ({ type: RESET_ALERTS });

export const updateCategoryRequest = ({ data }) => ({
  type: UPDATE_CUSTOM_OBJECTS_CATEGORY_REQUEST,
  data
});

export const updateCategorySuccess = () => ({
  type: UPDATE_CUSTOM_OBJECTS_CATEGORY_SUCCESS
});
