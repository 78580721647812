export const PRODUCT_DRAWER = {
  OPEN: 'OPEN_PRODUCT_DRAWER',
  CLOSE: 'CLOSE_PRODUCT_DRAWER'
};

export const PRODUCT_CATEGORY_DRAWER = {
  OPEN: 'OPEN_PRODUCT_CATEGORY_DRAWER',
  CLOSE: 'CLOSE_PRODUCT_CATEGORY_DRAWER'
};

export const RATE_TYPE_DRAWER = {
  OPEN: 'OPEN_RATE_TYPE_DRAWER',
  CLOSE: 'CLOSE_RATE_TYPE_DRAWER'
};

export const TAX_RATE_DRAWER = {
  OPEN: 'OPEN_TAX_RATE_DRAWER',
  CLOSE: 'CLOSE_TAX_RATE_DRAWER'
};

export const EVENT_PRODUCTS_DRAWER = {
  OPEN: 'OPEN_EVENTS_PRODUCT_DRAWER',
  CLOSE: 'CLOSE_EVENTS_PRODUCT_DRAWER'
};

export const ACTIVITY_DRAWER = {
  OPEN: 'OPEN_ACTIVITY_DRAWER',
  CLOSE: 'CLOSE_ACTIVITY_DRAWER'
};

export const CALENDAR_WIZARD_DRAWER = {
  OPEN: 'OPEN_CALENDAR_WIZARD_DRAWER',
  CLOSE: 'CLOSE_CALENDAR_WIZARD_DRAWER'
};

export const APPOINTMENT_DRAWER = {
  OPEN: 'OPEN_APPOINTMENT_DRAWER',
  CLOSE: 'CLOSE_APPOINTMENT_DRAWER'
};

export const REPORT_FIELDS_DRAWER = {
  OPEN: 'OPEN_REPORT_FIELDS_DRAWER',
  CLOSE: 'CLOSE_REPORT_FIELDS_DRAWER'
};
export const EMAIL_THREADS_DRAWER = {
  OPEN: 'OPEN_EMAIL_THREADS_DRAWER',
  CLOSE: 'CLOSE_EMAIL_THREADS_DRAWER'
};

export const EMAIL_COMPOSER_MODAL = {
  OPEN: 'OPEN_EMAIL_COMPOSER_MODAL',
  CLOSE: 'CLOSE_EMAIL_COMPOSER_MODAL'
};

export const EVENT_MANAGEMENT_DRAWER = {
  OPEN: 'OPEN_EVENT_MANAGEMENT_DRAWER',
  CLOSE: 'CLOSE_EVENT_MANAGEMENT_DRAWER'
};

export const EVENT_DOCUMENT_DRAWER = {
  OPEN: 'OPEN_EVENT_DOCUMENT_DRAWER',
  CLOSE: 'CLOSE_EVENT_DOCUMENT_DRAWER'
};

export const DOCUMENT_DETAILS_DRAWER = {
  OPEN: 'OPEN_DOCUMENT_DETAILS_DRAWER',
  CLOSE: 'CLOSE_DOCUMENT_DETAILS_DRAWER'
};

export const CHANGE_EVENT_FORM_VERSION = 'CHANGE_EVENT_FORM_VERSION';

export const BOOK_LEAD_OPERATIONS = {
  REQUEST: 'BOOK_LEAD_REQUEST',
  FINISH: 'BOOK_LEAD_FINISH'
};

export const EVENT_TASK_DRAWER = {
  OPEN: 'OPEN_EVENT_TASK_DRAWER',
  CLOSE: 'CLOSE_EVENT_TASK_DRAWER'
};

export const QUICK_EDIT_LEAD_DETAILS_DRAWER = {
  OPEN: 'OPEN_QUICK_EDIT_LEAD_DETAILS_DRAWER',
  CLOSE: 'CLOSE_QUICK_EDIT_LEAD_DETAILS_DRAWER',
  SET_LEAD_TO_QUICK_EDIT: 'SET_LEAD_TO_QUICK_EDIT'
};
