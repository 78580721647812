import { Flex } from '@chakra-ui/react';
import { CaretUp, CaretDown, Info } from 'src/v2/UIComponents/Primitives/Icons';
import { Tooltip } from 'src/v2/UIComponents/Primitives';
import { ALIGNVALUES } from '../../constants';

export const ColumnSortHeader = props => {
  const {
    dataKey,
    sortBy,
    sortDirection,
    label,
    tooltip,
    rightIcon,
    disableSort,
    iconName,
    align = 'left'
  } = props;

  const defaultIcons = {
    info: <Info h={4} w={4} ml={1} />
  };

  const isColumnSortedDirection = dataKey === sortBy && sortDirection;
  const textColor = sortBy === dataKey ? 'gray18' : 'gray7D';

  const headerAlign = ALIGNVALUES[align];

  return (
    <Tooltip label={tooltip} aria-label={tooltip}>
      <Flex
        alignItems="center"
        cursor="pointer"
        color={textColor}
        letterSpacing="0.25px"
        flex={1}
        justifyContent={headerAlign}
      >
        {label}
        {rightIcon}
        {iconName && defaultIcons[iconName]}
        {!disableSort && isColumnSortedDirection === 'ASC' && <CaretDown w={6} h={6} />}
        {!disableSort && isColumnSortedDirection === 'DESC' && <CaretUp w={6} h={6} />}
      </Flex>
    </Tooltip>
  );
};
