import {
  CLOSE_CUSTOM_FIELDS_DRAWER,
  CREATE_CUSTOM_FIELD_FAILURE,
  CREATE_CUSTOM_FIELD_REQUEST,
  CREATE_CUSTOM_FIELD_SUCCESS,
  LIST_CUSTOM_FIELD_FAILURE,
  LIST_CUSTOM_FIELD_REQUEST,
  LIST_CUSTOM_FIELD_SUCCESS,
  OPEN_CUSTOM_FIELDS_DRAWER,
  UPDATE_CUSTOM_FIELD_FAILURE,
  UPDATE_CUSTOM_FIELD_REQUEST,
  UPDATE_CUSTOM_FIELD_SUCCESS
} from './types';

export const addCustomFieldRequest = ({ newField }) => ({
  type: CREATE_CUSTOM_FIELD_REQUEST,
  newField
});

export const addCustomFieldSuccess = () => ({
  type: CREATE_CUSTOM_FIELD_SUCCESS
});

export const addCustomFieldFailure = ({ error }) => ({
  type: CREATE_CUSTOM_FIELD_FAILURE,
  error
});

export const updateCustomFieldRequest = ({ propertiesToUpdate }) => ({
  type: UPDATE_CUSTOM_FIELD_REQUEST,
  propertiesToUpdate
});

export const updateCustomFieldSuccess = () => ({
  type: UPDATE_CUSTOM_FIELD_SUCCESS
});

export const updateCustomFieldFailure = error => ({
  type: UPDATE_CUSTOM_FIELD_FAILURE,
  error
});

export const getCustomFieldsRequest = () => ({
  type: LIST_CUSTOM_FIELD_REQUEST
});

export const getCustomFieldsSuccess = ({
  customFields,
  fieldCategoryOptions,
  fieldGroupOptions,
  fieldCategoriesArray,
  fieldGroupsArray,
  fieldsByCategory,
  fieldsByGroup,
  onCreateFields,
  fieldGroupObject,
  notRequiredFields,
  venueRequired
}) => ({
  type: LIST_CUSTOM_FIELD_SUCCESS,
  customFields,
  fieldCategoryOptions,
  fieldGroupOptions,
  fieldCategoriesArray,
  fieldGroupsArray,
  fieldsByCategory,
  fieldsByGroup,
  onCreateFields,
  fieldGroupObject,
  notRequiredFields,
  venueRequired
});

export const getCustomFieldsFailure = ({ error }) => ({
  type: LIST_CUSTOM_FIELD_FAILURE,
  error
});

export const openCustomFieldDrawer = ({ activeField }) => ({
  type: OPEN_CUSTOM_FIELDS_DRAWER,
  activeField
});

export const closeCustomFieldDrawer = () => ({
  type: CLOSE_CUSTOM_FIELDS_DRAWER
});
