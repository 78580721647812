import { useEvents } from 'src/v2/Hooks/CollectionHooks';
import { ListCard } from 'src/v2/UIComponents/Primitives/Card/FullCard/FullCard';
import { Document } from 'src/v2/UIComponents/Primitives/Icons';
import { useState } from 'react';
import { Button } from 'src/v2/UIComponents/Primitives';
import { getDateDisplay } from 'src/utils/getDateDisplay';

import { AiOutlineMail } from 'react-icons/ai';
import { DisplayEmailBody } from 'src/v2/UIComponents/DisplayEmail';

const sortnull = (arr, ascending) => {
  // default to ascending
  if (typeof ascending === 'undefined') ascending = true;

  const multiplier = ascending ? 1 : -1;

  const sorter = function(aO, bO) {
    const a = aO.value;
    const b = bO.value;
    if (a && b) return 0;
    if (!a) return 1;
    if (!b) return -1;
    return 0;
  };

  return arr.sort(sorter);
};

export const LeadDetailsCard = () => {
  // TODO: #541 Display email body from lead (if available)

  const { activeEvent: lead, fetchingEvent: fetchingLead } = useEvents();
  const { leadInfo, created } = lead || {};
  const { sourceLabel = '', emailBody = '', tourDate = '' } = leadInfo || { sourceLabel: '' };

  const [isOpen, setOpen] = useState(false);

  const list = [
    {
      title: 'Source',
      value: sourceLabel
    },
    {
      title: 'Created',
      value: getDateDisplay(created)?.shortDate
    },
    {
      title: 'Tour Date',
      value: (tourDate && getDateDisplay(tourDate)?.shortDateTime) || 'Not scheduled'
    },
    emailBody && {
      title: '',
      value: (
        <Button
          onClick={() => setOpen(true)}
          variant="primaryLink"
          leftIcon={<AiOutlineMail />}
          textAlign="left"
        >
          View Original Email
        </Button>
      )
    }
  ];

  return (
    <>
      {lead?.leadInfo?.emailBody && (
        <DisplayEmailBody emailBody={emailBody} isOpen={isOpen} doClose={() => setOpen(false)} />
      )}
      <ListCard
        titleIcon={<Document color="var(--colors-primary)" />}
        title="Lead Details"
        list={sortnull(
          list?.filter(i => i),
          true
        )}
        fetching={fetchingLead}
        headerBtnAction={undefined}
        footer={undefined}
        headerBtnIcon={undefined}
        headerBtnLabel={undefined}
      />
    </>
  );
};
