import { TextFieldWithLabel } from 'src/v2/UIComponents/FormComponents';
import { ACTIVITY_FORM_CONSTANTS } from '../constants';

const ActivityDescription = props => {
  const { ACTIVITY_DESCRIPTION } = ACTIVITY_FORM_CONSTANTS;
  const inputProps = {
    name: 'description',
    isRequired: true,
    label: ACTIVITY_DESCRIPTION.LABEL,
    placeholder: ACTIVITY_DESCRIPTION.PLACEHOLDER,
    rules: {
      required: ACTIVITY_DESCRIPTION.REQUIRED
    }
  };
  return <TextFieldWithLabel {...inputProps} {...props} />;
};

export default ActivityDescription;
