import { FC } from 'react';
import { LeadDetailsTabProps } from 'src/Types/GlobalTypes';
import {
  SalespersonInput,
  LeadStatus,
  TourDate,
  ExpectedCloseDate,
  LeadSource,
  ProspectPhoneInput,
  ProspectEmailInput,
  LeadDate
} from 'src/v2/Drawers/LeadsFormDrawer/LeadForm/Inputs';
import { ClientNameInput } from 'src/v2/Drawers/EventFormDrawer/Inputs';
import { HStack } from '@chakra-ui/react';
import { FormInputsWrapper } from 'src/v2/UIComponents/FormComponents';
import { ProspectStreetAddress } from 'src/v2/Drawers/LeadsFormDrawer/LeadForm/Inputs/PropsectAddress';
import { ContentTitle } from './ContentTitle';

export const LeadDetailsTab: FC<LeadDetailsTabProps> = ({ bookEvent }) => {
  return (
    <FormInputsWrapper handleSubmit={undefined}>
      <ContentTitle title="Client Contact" />
      <ClientNameInput />
      <HStack>
        <ProspectPhoneInput />
        <ProspectEmailInput />
      </HStack>
      <ProspectStreetAddress />
      <ContentTitle title="Lead Info" />
      <LeadSource bookEvent={bookEvent} />
      <SalespersonInput bookEvent={bookEvent} />
      <LeadStatus />
      <LeadDate bookEvent={bookEvent} />
      <ExpectedCloseDate />
      <TourDate bookEvent={bookEvent} />
    </FormInputsWrapper>
  );
};
