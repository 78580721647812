export const ALTERNATE_LAYOUTS_PLACEHOLDER = 'Select all layouts available for selection by client';
export const CLIENT_NAME_PLACEHOLDER = 'e.g. Jack Smith';
export const EVENT_CODE_PLACEHOLDER = `e.g. JACKJILL21`;
export const EVENT_CODE_DISABLED_PLACEHOLDER = 'Photosharing is disabled for this event';
export const EVENT_NAME_PLACEHOLDER = `e.g. Jack & Jill's Wedding`;
export const EVENT_TYPE_PLACEHOLDER = `Used for reporting and creating the checklist`;
export const ROOM_PLACEHOLDER = 'Select room';
export const LAYOUT_PLACEHOLDER = 'Select layout/floorplan';
export const GUEST_MINIMUM_PLACEHOLDER = 'Set the minimum number of guests';
export const PAYMENT_PLACEHOLDER = 'Payment Due Date';
export const USER_FIRSTNAME_PLACEHOLDER = 'Jack';
export const USER_LASTNAME_PLACEHOLDER = 'Smith';
export const USER_EMAIL_PLACEHOLDER = 'jacksmith@gmail.com';
export const NOTES_PLACEHOLDER = 'Notes about the event';
export const PHOTOSHARING_TOOLTIP = 'Disable the guest mobile app for this event';
export const LAYOUT_LOCK_TOOLTIP = `Layout Locked`;
export const DEFAULT_DOCUMENTS_PLACEHOLDER =
  'Documents that will automatically add to the document center';
