/* eslint-disable import/no-import-module-exports */
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { createRoot } from 'react-dom/client';

import * as serviceWorker from './serviceWorker';

import App from './App';
import './app.css';

const container = document.getElementById('root');
const root = createRoot(container);

const isDev = process.env.NODE_ENV === 'development';

if (!isDev) {
  LogRocket.init('nlohzh/venuex-prod');
  setupLogRocketReact(LogRocket);
}

if (isDev) {
  console.log('Development mode');
}

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) {
  module.hot.accept();
}
