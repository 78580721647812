import { useUser } from 'src/v2/Hooks/CollectionHooks';
import { Card } from 'src/v2/UIComponents/Primitives';
import UploadDocument from 'src/containers/UploadDocument';
import { Box } from '@chakra-ui/react';
import { DocumentActions } from 'src/v2/Drawers/EventDetails/Navbar/components/DocumentsActions';
import EventDocumentsTable from 'src/v2/Tables/EventDocumentsTable/Table';

export const Documents = () => {
  const { token } = useUser();

  return (
    <Box height="100%">
      <DocumentActions />
      <Card height="100%">
        <EventDocumentsTable />
      </Card>
    </Box>
  );
};
