import { Box, Flex } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { ParsedLead } from 'src/containers/LeadsContainer/reducers';
import Colors from 'src/styles/Colors';
import { useEvents, useLeads } from 'src/v2/Hooks/CollectionHooks';
import { useDrawers } from 'src/v2/Hooks/HelperHooks';
import { FormInputsWrapper } from 'src/v2/UIComponents/FormComponents';
import { Button, Text } from 'src/v2/UIComponents/Primitives';
import { UpdatedEventData } from 'venuex-types';
import { useEffect } from 'react';
import { LeadSource, LeadStatus, SalespersonInput } from '../LeadsFormDrawer/LeadForm/Inputs';

export type EventQuickEditBody = {};

export const LeadQuickEditDrawerHeader = ({
  lead,
  handleClick
}: {
  lead: ParsedLead;
  handleClick: () => void;
}) => {
  const { clientName } = lead;

  return (
    <Flex borderBottom={`2px solid ${Colors.border}`} height="100px">
      <Flex flexDir="column" width="100%" justifyContent="flex-end">
        <Text fontSize="20px" lineHeight="26px" letterSpacing="0.2px" fontWeight={700} mb="8px">
          {clientName}
        </Text>
        <Box>
          <Button onClick={handleClick} variant="primaryLink">
            Open Details
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export const LeadQuickEditDrawer = ({
  fullSize,
  showBooked = false
}: {
  fullSize: boolean;
  showBooked?: boolean;
}) => {
  const methods = useForm<UpdatedEventData>();
  const {
    handleSubmit,
    formState: { isDirty },
    reset
  } = methods;

  const { updateEvent, getEvent } = useEvents();
  const { openLeadsDetails } = useLeads();
  const { closeQuickEditLeadDrawer, quickEditLeadDrawerOpen, leadToQuickEdit: lead } = useDrawers();

  useEffect(() => {
    if (lead) reset(lead);
  }, [lead]);
  const onSubmit = (data: UpdatedEventData) => {
    const closeAction = () => reset(data);
    if (!lead?.id) {
      toast.error('Error updating event');
      return;
    }
    showBooked && updateEvent({ data, eventId: lead?.id, closeAction, getEventsWithLead: true });
    !showBooked && updateEvent({ data, eventId: lead?.id, closeAction });
  };

  const requestClose = () => {
    closeQuickEditLeadDrawer();
  };

  if (!lead) return <div />;

  const handleClick = () => {
    getEvent({ eventId: lead?.id });
    openLeadsDetails();
    closeQuickEditLeadDrawer();
  };

  return (
    <Box
      position={fullSize ? 'fixed' : 'absolute'}
      top={0}
      right={0}
      bottom={0}
      width="400px"
      bg={Colors.white}
      boxShadow="0 4px 8px 0 rgba(0, 0, 0, 0.2)"
      zIndex={10}
      transform={quickEditLeadDrawerOpen ? 'translateX(0)' : 'translateX(100%)'}
      transition="transform 0.3s ease-in-out"
      display={'flex'}
      flexDir="column"
      alignContent="flex-start"
      px={4}
      gap={4}
    >
      <FormInputsWrapper>
        <FormProvider {...methods}>
          <Box color="var(--colors-primary)" py={2}></Box>
          <LeadQuickEditDrawerHeader lead={lead} handleClick={handleClick} />
          <Box height="fit-content">
            <LeadStatus disableBook />
            <SalespersonInput bookEvent={false} />
            <LeadSource bookEvent={false} />
          </Box>
          <Box display="flex" gap={4} justifyContent={'flex-end'}>
            <Button onClick={handleSubmit(onSubmit)} isDisabled={!isDirty}>
              {isDirty ? 'Save' : 'Saved'}
            </Button>
            <Button onClick={requestClose} variant={isDirty ? 'cancel' : 'grayOutline'}>
              {isDirty ? 'Cancel' : 'Close '}
            </Button>
          </Box>
          <Box>
            <Text variant="contentSubTitle">
              To grant your client portal access, edit stage in the full lead details view.
            </Text>
          </Box>
        </FormProvider>
      </FormInputsWrapper>
    </Box>
  );
};
