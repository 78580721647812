import { Flex, Text, Icon, IconButton, Stack } from '@chakra-ui/react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdSearch } from 'react-icons/md';
import { useWindowSize } from 'src/hooks/useWindowSize';
import Colors from 'src/styles/Colors';

import { Filter } from './Filter';
import { ProfileButton } from './ProfileButton';
import { ActionsGroup, ToolbarAction } from './ActionsGroup';

export interface ToolbarFilter {
  title: string;
  filterList: string[];
  dataKey: string;
}

export interface ToolbarProps {
  title?: string;
  rightActions?: ToolbarAction[];
  leftActions?: ToolbarAction[];
  filters?: ToolbarFilter[];
  leftArrowClick?: () => void;
  rightArrowClick?: () => void;
  isCalendar?: boolean;
}
export const Toolbar = ({
  title,
  rightActions,
  leftActions,
  filters,
  leftArrowClick,
  rightArrowClick,
  isCalendar = false
}: ToolbarProps) => {
  const { isMobile } = useWindowSize();

  const user = {
    // firstname: props.firstname,
    // lastname: props.lastname,
    // photo: props.photo,
    // token: props.token
  };

  return (
    <>
      <Flex
        flex={1}
        py="15px"
        px="30px"
        borderBottom={(!isCalendar && `1px solid ${Colors.grayED}`) || 'none'}
        flexDirection="column"
      >
        <Flex justifyContent="space-between" align="center">
          <Flex flex={1}>
            <Stack spacing={3} direction="row" align="center">
              {isMobile && (
                <IconButton
                  variant="unstyled"
                  aria-label="Search"
                  icon={<Icon as={MdSearch} w={8} h={8} color="var(--colors-primary)" />}
                />
              )}
              {!isMobile && <ActionsGroup actions={leftActions} />}
            </Stack>
            <Stack ml={8} direction="row" align="center">
              {filters && (
                <>
                  {filters?.map((filter, key) => (
                    <Filter
                      filterType={undefined}
                      key={key}
                      title={filter.title}
                      filterList={filter.filterList}
                      dataKey={filter.dataKey}
                    />
                  ))}
                </>
              )}
            </Stack>
          </Flex>
          {!filters && (
            <Flex flex={1} alignItems="center" justifyContent="space-between" maxWidth="250px">
              {leftArrowClick && (
                <IconButton
                  variant="unstyled"
                  onClick={leftArrowClick}
                  aria-label="Previous"
                  icon={<Icon as={MdKeyboardArrowLeft} w={8} h={8} color="var(--colors-primary)" />}
                />
              )}
              <Text
                fontFamily="Lora"
                fontSize={{ base: '18px', md: '20px' }}
                letterSpacing={{ base: 'normal', md: '-0.63px' }}
                textTransform="capitalize"
                color="#181818"
                textAlign="center"
              >
                {title}
              </Text>
              {rightArrowClick && (
                <IconButton
                  variant="unstyled"
                  onClick={rightArrowClick}
                  aria-label="Next"
                  icon={
                    <Icon as={MdKeyboardArrowRight} w={8} h={8} color="var(--colors-primary)" />
                  }
                />
              )}
            </Flex>
          )}
          <Flex flex={1} justifyContent="flex-end">
            <Stack spacing={3} direction="row" align="center">
              {!isMobile && <ActionsGroup actions={rightActions} />}
              <ProfileButton />
            </Stack>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
