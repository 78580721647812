// leadsTableUtils.ts

import Colors from 'src/styles/Colors';

interface LeadStatusOption {
  value: string;
  displayName: string;
}

interface LeadTagInformation {
  title: string | undefined;
  color: string;
}

export const getLeadTagInformation = (
  status: string,
  options: LeadStatusOption[]
): LeadTagInformation => {
  let color = 'var(--colors-primary-light)';

  if (status === 'lost') {
    color = Colors.redE7;
  }

  if (status === 'new') {
    color = Colors.green2C;
  }

  if (status === 'proposalSent' || status === 'contractSent') {
    color = Colors.grayD8;
  }

  if (status === 'eventBooked') {
    color = 'var(--colors-primary)';
  }

  const leadStatus = options?.find(opt => opt.value === status);

  return {
    title: leadStatus?.displayName,
    color
  };
};

interface Lead {
  leadInfo?: {
    leadType?: string;
  };
}

export const filterLeads = (leads: Lead[] | undefined): Lead[] => {
  return (leads || []).filter(ld => {
    const { leadType } = ld?.leadInfo || {};
    return leadType === 'unknown' || (leadType !== 'duplicate' && leadType !== 'message');
  });
};

interface EventToCheck {
  leadInfo?: {
    leadType?: string;
    leadDate?: string | Date;
  };
  createdBy?: any;
}

export const eventFilter = (eventToCheck: EventToCheck): boolean => {
  const { leadInfo, createdBy } = eventToCheck;
  const leadDate = leadInfo?.leadDate;
  if (leadInfo?.leadType === 'message' || leadInfo?.leadType === 'duplicate') return false;
  if (!createdBy && leadInfo && Object.keys(leadInfo)?.length) return true;
  if (createdBy && leadDate) return true;
  return false;
};
