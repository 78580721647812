import { Grid, GridItem, Flex, Text } from '@chakra-ui/react';
import { Card, Skeleton } from '../Primitives';

export const CardsSectionInfo = ({ cardsInfo, isLoading = false, ...props }) => {
  const totalCards = cardsInfo.length;

  return (
    <Grid templateColumns={`repeat(${totalCards}, 1fr)`} gap={6} {...props}>
      {cardsInfo.map((el, key) => (
        <GridItem key={key}>
          <Card display="flex" paddingX="26px" paddingY="20px" height="90px">
            <Flex>
              <Flex mr="26px" alignItems="center" color="var(--colors-primary)">
                {el?.icon}
              </Flex>
              <Flex flexDir="column">
                <Text variant="headerTitleS" mb="4px">
                  {el?.title}
                </Text>
                {isLoading ? (
                  <Flex>
                    <Skeleton height="16px" width="160px" />
                  </Flex>
                ) : (
                  <Text variant="headerTitleM">{el?.value}</Text>
                )}
              </Flex>
            </Flex>
          </Card>
        </GridItem>
      ))}
    </Grid>
  );
};
