import axios from 'axios';
import config from '../../.env.json';

const { apiUri, firebaseTriggerUri } = config;

export const fetchGuests = ({ token, rsvp = null, table = null }) => {
  let url = `${apiUri}/guests`;
  if (rsvp) {
    url = `${url}&rsvp=${rsvp}`;
  }
  if (table) {
    url = `${url}&table=${table}`;
  }
  return axios({
    method: 'get',
    url,
    headers: { Authorization: token }
  });
};

export const getLayout = ({ token, layoutId }) => {
  const url = `${apiUri}/layouts/${layoutId}`;
  return axios({
    method: 'get',
    url,
    headers: { Authorization: token }
  });
};

export const changeUserTable = ({ token, userId, table }) => {
  const url = `${apiUri}/guests/${userId}`;
  return axios({
    method: 'put',
    url,
    headers: { Authorization: token },
    data: { table }
  });
};

export const updateLayoutName = ({ token, eventId, table }) => {
  const url = `${apiUri}/events/${eventId}`;
  return axios({
    method: 'put',
    url,
    headers: { Authorization: token },
    data: { table }
  });
};

export const resetAllTables = ({ token, eventId }) => {
  const url = `${apiUri}/guests/${eventId}/tables/reset`;
  return axios({
    method: 'put',
    url,
    headers: { Authorization: token },
    data: { resetAll: true }
  });
};

export const submitSeatingChartAction = ({ token, chartData }) => {
  const url = `${apiUri}/seatingChart/submit`;
  return axios({
    method: 'post',
    url,
    headers: { Authorization: token },
    data: chartData
  });
};

export const submitNewSeatingChartAction = ({ token, chartData }) => {
  const url = `${firebaseTriggerUri}/requestNewSeatingChart`;
  return axios({
    method: 'post',
    url,
    headers: { Authorization: token },
    data: chartData
  });
};

export const updateLayoutAction = ({ token, eventId, layoutId }) => {
  const url = `${apiUri}/events/${eventId}/layout`;
  return axios({
    method: 'put',
    url,
    headers: { Authorization: token },
    data: { layoutId }
  });
};
