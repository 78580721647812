import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Colors from '../Colors';
import HexToRGB from '../HexToRGB';

const ButtonRoundStyles = styled.button.attrs(props => ({
  title: props.title || '',
  type: props.type || 'button',
  disabled: props.disabled || false
}))`
  border: none;
  height: ${props =>
    props.isActive
      ? (props.size === 'small' && '50px') || '55px'
      : (props.size === 'small' && '45px') || '50px'}};
  width: ${props =>
    props.isActive
      ? (props.size === 'small' && '50px') || '55px'
      : (props.size === 'small' && '45px') || '50px'}};
  box-shadow: ${props =>
    props.disableShadow ? null : `0 2px 2px 0 rgba(${HexToRGB(Colors.gray7D, true)}, 0.2)`};
  background-color: ${props => props.bgColor || `var(--colors-primary);`};
  padding: ${props => (props.size && props.size === 'large' ? '60px' : '15px')};
  border-radius: 50%;
  position: relative;
  transition: all 250ms ease-in-out 0s;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')}

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    transition: all 250ms ease-in-out 0s;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    top: 0;
    left: 0;
  }

  .initials {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: ${props =>
      props.color ? props.color : (props.primaryInitials && 'var(--colors-primary)') || '#ffffff'};
    font-size: ${props => (props.size && props.size === 'large' ? '35px' : '15px')};
  }

  &:focus {
    outline: none;
  }

  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
`;

export const StyledReactTooltip = styled(ReactTooltip)`
  font-weight: 500;
`;

export default ButtonRoundStyles;
