import React from 'react';
import PropTypes from 'prop-types';
import PillStyles from './styles';

const Pill = ({ label, bgColor, color, action, animate }) => (
  <PillStyles bgColor={bgColor} color={color} onClick={action} animate={animate}>
    <span>{label}</span>
  </PillStyles>
);

Pill.propTypes = {
  label: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  action: PropTypes.func,
  animate: PropTypes.bool
};

export default Pill;
