import React from 'react';
import moment from 'moment';

export const ColumnDate = props => {
  const { cellData, dateFormat } = props;

  const formats = {
    short: 'L',
    large: 'LL'
  };

  const format = dateFormat ? formats[dateFormat] : 'L';

  const date = cellData?.value ? moment(cellData?.value).format(format) : 'N/A';

  return <div>{date}</div>;
};
