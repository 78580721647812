import { DateFieldWithLabel } from 'src/v2/UIComponents/FormComponents';
import { EVENT_TASK_FORM_CONSTANTS } from '../constants';

const { EVENT_TASK_DUE_DATE } = EVENT_TASK_FORM_CONSTANTS;

export const EventTaskDueDate = props => {
  const inputProps = {
    label: EVENT_TASK_DUE_DATE.LABEL,
    name: 'dueDate',
    placeholder: EVENT_TASK_DUE_DATE.PLACEHOLDER,
    allowPreviousDate: true
  };

  return <DateFieldWithLabel {...inputProps} {...props} />;
};
