import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Notifications from 'src/v2/Notifications';
import { Toaster } from 'react-hot-toast';
import App from '../../components/App';
import { ModalLoader } from '../../components/ModalLoader';

import {
  doVenueIdRemove,
  doEventIdRemove,
  doPolicyAcceptTrigger,
  doPolicyReject
} from '../LoginContainer/operations';
import { hideSidebarOperation } from './operations';

const AppContainer = ({ setHideSidebar, children, ...props }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  const hideSidebar = { props };
  const { modalLoaderState } = props;

  return (
    <App show={show} hideSidebar={hideSidebar} setHideSidebar={setHideSidebar} {...props}>
      <Notifications />
      {children}
      <ModalLoader isOpen={modalLoaderState.isOpen} modalLoaderState={modalLoaderState} />
      <Toaster toastOptions={{ style: { color: '#181818' } }} />
    </App>
  );
};

const mapStateToProps = state => ({
  admin: state.loginReducer.admin,
  venueId: state.loginReducer.venueId,
  eventId: state.loginReducer.eventId,
  policyRequired: state.loginReducer.policyRequired,
  venue: state.venuesReducer.venue,
  hideSidebar: state.appContainerReducer.hideSidebar,
  modalLoaderState: state.modalLoaderReducer
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doVenueIdRemove,
      doEventIdRemove,
      doPolicyAcceptTrigger,
      doPolicyReject,
      setHideSidebar: hideSidebarOperation
    },
    dispatch
  );

AppContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setHideSidebar: PropTypes.func,
  modalLoaderState: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
