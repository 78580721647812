import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import UserForgotPassword from '../../components/ForgotPassword';
import { doForgot } from './operations';

const ForgotPassword = props => {
  const { forgotPass, errors, fetching } = props;
  const [email, setEmail] = useState('');
  const [activeVenue, setVenueId] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [error, setError] = useState(null);
  const [submitState, setSubmitState] = useState(false);

  const { venue, success } = props;
  useEffect(() => {
    setVenueId(venue);
  }, [venue]);

  const onSubmit = event => {
    event.preventDefault();
    if (!email) {
      return setError('Email is required');
    }

    if (!validator.isEmail(email)) {
      return setError('Provide a valid email address');
    }

    setError(null);
    setSubmitState(true);
    const venueId = activeVenue && activeVenue;
    forgotPass({ email, venueId, eventId });
  };

  const handleChange = event => {
    const { name, value } = event.currentTarget;
    switch (name) {
      case 'email':
        setEmail(value);
        break;
      case 'venueId':
        setVenueId(value);
        break;
      case 'eventId':
        setEventId(value);
        break;
      default:
      // do nothing.
    }
  };

  return (
    <UserForgotPassword
      email={email}
      venueId={activeVenue}
      eventId={eventId}
      name={props.venueName || 'Logo Here'}
      logo={props.venueLogo}
      handleChange={handleChange}
      success={success}
      submitState={submitState}
      handleSubmit={onSubmit}
      loading={fetching}
      errors={error || errors}
    />
  );
};

const mapStateToProps = state => {
  return {
    errors: state.forgotReducer.error,
    fetching: state.forgotReducer.fetching,
    success: state.forgotReducer.success,
    venueName: state.venueValuesReducer.venue && state.venueValuesReducer.venue.name,
    venueLogo: state.venueValuesReducer.venue && state.venueValuesReducer.venue.logo,
    venue: state.venueValuesReducer.venue && state.venueValuesReducer.venue.venueId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    forgotPass: ({ email, venueId, eventId }) => dispatch(doForgot({ email, venueId, eventId }))
  };
};

ForgotPassword.propTypes = {
  fetching: PropTypes.bool,
  errors: PropTypes.string,
  forgotPass: PropTypes.func,
  venueName: PropTypes.string,
  venueLogo: PropTypes.string,
  venue: PropTypes.string,
  success: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassword));
