import { TextFieldWithLabel } from 'src/v2/UIComponents/FormComponents';
import { EVENT_TASK_FORM_CONSTANTS } from '../constants';

const { EVENT_TASK_DESCRIPTION } = EVENT_TASK_FORM_CONSTANTS;

export const EventTaskDescription = props => {
  const inputProps = {
    name: 'description',
    label: EVENT_TASK_DESCRIPTION.LABEL,
    placeholder: EVENT_TASK_DESCRIPTION.PLACEHOLDER
  };
  return <TextFieldWithLabel {...inputProps} {...props} />;
};
