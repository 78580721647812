import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export const addEventAction = ({ event, token, monthStart, monthEnd }) =>
  axios({
    method: 'post',
    url: `${apiUri}/events`,
    headers: { Authorization: token },
    data: { ...event, monthEnd, monthStart }
  });

export const createHostUsersAction = ({ users, eventId, token }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/users/createHosts/${eventId}`,
    headers: { Authorization: token },
    data: users
  });
};

export const updateHostUsersAction = ({ users, eventId, token }) =>
  axios({
    method: 'put',
    url: `${apiUri}/users/updateHosts/${eventId}`,
    headers: { Authorization: token },
    data: users
  });

export const addEventDocumentsAction = ({ defaultDocuments, eventId, token }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/documents/eventCreate/${eventId}`,
    headers: { Authorization: token },
    data: defaultDocuments
  });
};

export const updateEventAction = ({ id, event, token }) =>
  axios({
    method: 'put',
    url: `${apiUri}/events/${id}`,
    headers: { Authorization: token },
    data: event
  });

export const getUsersAction = ({ venueId, token, eventId, role }) =>
  axios({
    method: 'get',
    url: `${apiUri}/venues/${venueId}/users`,
    headers: { Authorization: token },
    params: { eventId, role }
  });

export const getLayoutsAction = ({ roomId, token }) =>
  axios({
    method: 'get',
    url: `${apiUri}/layouts?roomId=${roomId}`,
    headers: { Authorization: token }
  });

// eslint-disable-next-line import/prefer-default-export
export const updateUserAction = ({ user, token }) =>
  axios({
    method: 'put',
    url: `${apiUri}/users/${user.id}`,
    headers: { Authorization: token },
    data: user
  });

export const checkEventCode = ({ token, venueId, eventCode, eventId = '' }) =>
  axios({
    method: 'post',
    url: `${apiUri}/events/check`,
    headers: { Authorization: token },
    data: { venueId, eventCode, eventId }
  });

type HostEmailActionProps = {
  token: string;
  venueId: string;
  email: string;
  eventId?: string;
  key?: number;
};
export const checkHostEmail = ({
  token,
  venueId,
  email,
  eventId = '',
  key
}: HostEmailActionProps) =>
  axios({
    method: 'post',
    url: `${apiUri}/users/check`,
    headers: { Authorization: token },
    data: { venueId, email, eventId, key }
  });

export const sendEventEmail = ({
  recipient,
  cc,
  bcc,
  body,
  subject,
  attachments,
  token,
  eventId
}) =>
  axios({
    method: 'post',
    url: `${apiUri}/emails/send`,
    headers: { Authorization: token },
    data: { recipient, cc, bcc, body, subject, attachments, eventId }
  });

export const setLeadTypeAction = ({
  leadType,
  leadToUpdateId,
  originalLeadId,
  token
}: {
  leadType: 'message' | 'duplicate' | 'unrelated' | 'original';
  leadToUpdateId: string;
  originalLeadId: string;
  token: string;
}) =>
  axios({
    method: 'put',
    url: `${apiUri}/events/leadtypes/${leadToUpdateId}`,
    data: {
      leadType,
      originalLeadId
    },
    headers: { Authorization: token }
  });
