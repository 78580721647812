import { ComposerAttachments } from './ComposerAttachments';
import { ComposerBody } from './ComposerBody';
import { ComposerFooter } from './ComposerFooter';
import { ComposerHeader } from './ComposerHeader';
import { ComposerSubjectField } from './ComposerSubjectField';
import { ComposerToField } from './ComposerToField';

export const ComposerComponents = {
  Body: ComposerBody,
  Header: ComposerHeader,
  ToField: ComposerToField,
  SubjectField: ComposerSubjectField,
  Footer: ComposerFooter,
  Attachments: ComposerAttachments
};
