import { ComponentStyleConfig } from '@chakra-ui/react';
import Colors from 'src/styles/Colors';

const disabledStyle = {
  opacity: 0.4,
  _hover: { opacity: 0.4 }
};

const textStyle = {
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '28px',
  letterSpacing: '0.2px'
};

const roundedStyle = {
  borderRadius: '50%',
  height: '50px',
  width: '50px'
};

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: '8px',
    _disabled: disabledStyle,
    opacity: 1
  },
  sizes: {
    sm: {
      h: '35px',
      ...textStyle
    },
    md: {
      h: '48px',
      ...textStyle
    }
  },
  variants: {
    primary: {
      bg: 'var(--colors-primary)',
      color: 'white',
      _hover: {
        _disabled: {
          bg: 'var(--colors-primary)'
        }
      },
      ...textStyle
    },
    primaryOutline: {
      borderColor: 'var(--colors-primary)',
      bg: 'white',
      color: 'var(--colors-primary)',
      borderWidth: '1px',
      ...textStyle
    },
    grayOutline: {
      borderColor: Colors.grayED,
      borderWidth: '1px',
      textTransform: 'capitalize',
      bg: 'white',
      ...textStyle
    },
    filterDropdown: {
      borderColor: Colors.grayED,
      borderWidth: '1px',
      textTransform: 'capitalize',
      bg: 'white',
      ...textStyle
    },
    roundedPrimary: {
      bg: 'var(--colors-primary)',
      color: 'white',
      ...roundedStyle,
      ...textStyle
    },
    roundedPrimaryOutline: {
      ...roundedStyle,
      color: 'var(--colors-primary)',
      bg: 'white',
      borderWidth: '1px',
      borderColor: 'var(--colors-primary)',
      ...textStyle
    },
    roundedSecondaryOutline: {
      ...roundedStyle,
      color: 'var(--colors-primary)',
      bg: 'white',
      borderWidth: '1px',
      borderColor: Colors.grayED,
      ...textStyle
    },
    primaryLink: {
      padding: 0,
      height: 'auto',
      verticalAlign: 'baseline',
      color: 'var(--colors-primary)',
      _hover: { textDecoration: 'none' },
      ...textStyle
    },
    cancel: {
      bg: Colors.redC0,
      color: 'white',
      ...textStyle
    }
  },
  defaultProps: {
    variant: 'primary',
    size: 'sm'
  }
  // Button: {
  //   sizes: {
  //     btn1: {
  //       h: '48px',
  //       fontSize: '14px',
  //       lineHeight: '18px',
  //       letterSpacing: '0.2px',
  //       fontWeight: 700,
  //       px: '16px'
  //     },
  //     btn2: {
  //       fontSize: '15px',
  //       fontWeight: 'normal',
  //       lineHeight: '22.5px',
  //       h: 10,
  //       px: 4
  //     },
  //     btn3: {
  //       h: '35px',
  //       fontWeight: 700,
  //       fontSize: '14px',
  //       lineHeight: '20px',
  //       letterSpacing: '0.2px'
  //     }
  //   },
  //   variants: {
  //     primary: {
  //       bg: 'var(--colors-primary)',
  //       color: 'white',
  //       textTransform: 'capitalize',
  //       borderRadius: '8px',
  //       _active: { bg: 'var(--colors-primary-lighter)', transform: 'scale(0.95)' },
  //       _hover: {
  //         _disabled: {
  //           bg: 'var(--colors-primary)'
  //         }
  //       }
  //     },
  //     primaryOutline: {
  //       borderColor: 'var(--colors-primary)',
  //       bg: 'white',
  //       color: 'var(--colors-primary)',
  //       borderWidth: '1px',
  //       textTransform: 'capitalize',
  //       borderRadius: '8px',
  //       _active: { transform: 'scale(0.95)' }
  //     },
  //     secondary: {
  //       bg: 'white',
  //       borderColor: Colors.grayED,
  //       color: Colors.gray18,
  //       borderWidth: '1px',
  //       textTransform: 'capitalize'
  //     },
  //     cancel: {
  //       bg: Colors.redC0,
  //       borderColor: Colors.grayED,
  //       color: 'white',
  //       borderWidth: '1px',
  //       textTransform: 'capitalize'
  //     },
  //     dropdown: {
  //       borderColor: Colors.grayED,
  //       borderWidth: '1px',
  //       textTransform: 'capitalize',
  //       fontWeight: 'normal',
  //       bg: 'white'
  //     },
  //     primaryLink: {
  //       padding: 0,
  //       height: 'auto',
  //       verticalAlign: 'baseline',
  //       color: 'var(--colors-primary)',
  //       _hover: { textDecoration: 'none' }
  //     }
  //   }
  // }
};
