import { Icon } from '@chakra-ui/react';

export const Email = props => {
  const { w = 8, h = 8 } = props;

  return (
    <Icon w={w} h={h} viewBox="0 0 16 16" {...props}>
      <path
        d="M7.8848 9.30555C6 8.37915 2.488 6.66395 1.3632 6.11835C1.1664 6.02395 1.008 6.12315 1.008 6.34075V12.6799C1.008 12.8975 1.184 13.0751 1.4016 13.0751H14.5952C14.8128 13.0751 14.9904 12.8991 14.9904 12.6815V6.34875C14.9904 6.13115 14.832 6.03195 14.6368 6.12795C13.52 6.67515 10.048 8.38395 8.1872 9.30715C8.0752 9.36315 7.992 9.35835 7.8848 9.30555ZM2.8576 5.63515C4.5408 6.45915 6.224 7.28475 7.9088 8.10395C7.9728 8.13595 8.0736 8.14555 8.136 8.11515C10.3888 7.00955 12.6384 5.89595 14.8896 4.78715C14.9712 4.74715 15 4.70075 15 4.60635C14.9968 4.27835 15 3.69915 15.0016 3.31195C15.0016 3.09435 14.824 2.91675 14.6064 2.91675H1.3968C1.1792 2.91675 1 3.09275 1 3.31195C1 3.70075 1.0032 4.28155 1.008 4.62235C1.0096 4.67675 1.0688 4.75515 1.12 4.78075C1.6976 5.07035 2.2784 5.35035 2.8576 5.63515Z"
        fill="currentColor"
      />
    </Icon>
  );
};
