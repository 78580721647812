import styled from 'styled-components';
import Colors from '../Colors';

const PillStyles = styled.span`
  display: block;
  width: ${props => props.width || '68px'};
  height: 20px;
  border-radius: 10px;
  background-color: ${props => props.bgColor || Colors.grayB0};

  span {
    color: ${props => props.color || Colors.white};
    display: block;
    width: 100%;
    height: 20px;
    line-height: 20px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }
  ${props =>
    props.animate &&
    `
&:hover {
  opacity: .9;
  transform: scale(1.05);
  transition: all 300ms linear;

}`}
`;

export default PillStyles;
