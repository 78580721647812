/* eslint-disable import/prefer-default-export */
import { OPEN_CONFIRM_MODAL, CLOSE_CONFIRM_MODAL } from './types';

const initialState = {
  isOpen: false,
  actionType: null,
  eventId: null
};

export const confirmModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CONFIRM_MODAL:
      return { ...state, isOpen: true, actionType: action.actionType, eventId: action.eventId };
    case CLOSE_CONFIRM_MODAL:
      return { ...state, isOpen: false, actionType: null, eventId: null };
    default:
      return state;
  }
};
