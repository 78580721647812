import moment from 'moment';
import { takeLatest, put, call, select, delay } from 'redux-saga/effects';
import {
  FETCH_MEMORIES_REQUEST,
  DELETE_MEMORY_REQUEST,
  BATCH_DELETE_REQUEST,
  CREATE_MEMORIES_REQUEST,
  EDIT_MOBILE_PHOTO_REQUEST,
  GET_MODERATORS_REQUEST,
  ADD_MODERATOR_REQUEST,
  DELETE_MODERATOR_REQUEST,
  EDIT_MEMORY_REQUEST
} from './types';
import {
  fetchMemoriesFailure,
  fetchMemoriesSuccess,
  deleteMemoryFailure,
  deleteMemorySuccess,
  fetchMemoriesRequest,
  batchDeleteFailure,
  batchDeleteSuccess,
  createMemoriesSuccess,
  createMemoriesFailure,
  editMobileEventPhotoFailure,
  editMobileEventPhotoSuccess,
  getModeratorsFailure,
  getModeratorsSuccess,
  addModeratorSuccess,
  addModeratorFailure,
  getModeratorsRequest,
  deleteModeratorSuccess,
  deleteModeratorFailure,
  editMemorySuccess,
  editMemoryFailure
} from './operations';
import {
  getMemoriesApi,
  deleteMemoryApi,
  batchDeleteApi,
  createMemoryApi,
  editMobileEventPhotoApi,
  getEventModeratorsApi,
  addModeratorApi,
  deleteModeratorApi,
  editMemoryApi
} from './actions';
import { uploadFile } from '../../core/API/firebaseStorage';
import makeId from '../../utils/randomString';

function* workerFetchMemories(action) {
  try {
    const { token, memoryType } = action;
    const response = yield call(getMemoriesApi, { token, type: memoryType });
    yield put(fetchMemoriesSuccess(response && response.data && response.data.feeds));
  } catch (error) {
    yield put(fetchMemoriesFailure(error));
  }
}

function* workerDeleteMemory(action) {
  try {
    const { token, feedId } = action;
    const response = yield call(deleteMemoryApi, { token, feedId });
    yield put(deleteMemorySuccess(response && response.data && response.data.success));
    yield put(fetchMemoriesRequest({ token }));
  } catch (error) {
    yield put(deleteMemoryFailure(error));
  }
}

function* workerEditMemory(action) {
  try {
    const { token, feedId, caption } = action;
    const response = yield call(editMemoryApi, { token, feedId, caption });
    yield put(editMemorySuccess(response && response.data && response.data.success));
    yield put(fetchMemoriesRequest({ token }));
  } catch (error) {
    yield put(editMemoryFailure(error));
  }
}

function* workerBatchDelete(action) {
  try {
    const { token, feedIds } = action;
    const response = yield call(batchDeleteApi, { token, feedIds });
    yield put(batchDeleteSuccess(response && response.data && response.data.success));
    yield put(fetchMemoriesRequest({ token }));
  } catch (error) {
    yield put(batchDeleteFailure(error));
  }
}

function getRemotePath({ dashboardReducer, loginReducer }) {
  const { end: eventEndDate } = dashboardReducer.event;
  const { eventId } = loginReducer;
  const { venueId } = loginReducer;
  if (!eventEndDate || !moment(eventEndDate, 'YYYY-MM-DD').isValid() || !venueId) {
    return null;
  }
  const endDate = moment(eventEndDate, 'YYYY-MM-DD').format('YYYY_MM_DD');
  return `eventsMediaContent/${endDate}/${venueId}/${eventId}`;
}

function* workerCreateMemory(action) {
  try {
    const { token, formData, file } = action;
    const remotePath = yield select(getRemotePath);
    const randomName = makeId();
    const { success: uploaded, fullPath, bucket } = yield call(
      uploadFile,
      file,
      `${remotePath}/${randomName}`
    );
    if (uploaded && fullPath && bucket) {
      const response = yield call(createMemoryApi, { token, formData, fullPath, bucket });
      yield put(createMemoriesSuccess(response && response.data && response.data.feed));
      yield put(fetchMemoriesRequest({ token }));
    }
  } catch (error) {
    yield put(createMemoriesFailure(error));
  }
}

function* workerEditMobilePhoto(action) {
  try {
    const { token, eventId, eventMobilePhoto, dayOfMessage, eventCode } = action;
    const response = yield call(editMobileEventPhotoApi, {
      token,
      eventId,
      eventMobilePhoto,
      dayOfMessage,
      eventCode
    });
    yield put(editMobileEventPhotoSuccess(response && response.data && response.data.event));
  } catch (error) {
    yield put(editMobileEventPhotoFailure(error));
  }
}

function* workerGetModerators(action) {
  try {
    const { token } = action;
    const response = yield call(getEventModeratorsApi, { token });
    yield put(getModeratorsSuccess(response && response.data && response.data.moderators));
  } catch (error) {
    yield put(getModeratorsFailure(error));
  }
}

function* workerAddModerator(action) {
  try {
    const { token, email, firstname, lastname } = action;
    const response = yield call(addModeratorApi, { token, email, firstname, lastname });
    yield put(addModeratorSuccess(response && response.data && response.data.success));
    yield delay(2000);
    yield put(getModeratorsRequest({ token }));
  } catch (error) {
    yield put(addModeratorFailure(error));
  }
}

function* workerDeleteModerator(action) {
  try {
    const { token, id } = action;
    const response = yield call(deleteModeratorApi, { token, id });
    yield put(deleteModeratorSuccess(response && response.data && response.data.success));
    yield delay(2000);
    yield put(getModeratorsRequest({ token }));
  } catch (error) {
    yield put(deleteModeratorFailure(error));
  }
}

export function* watchFetchMemories() {
  yield takeLatest(FETCH_MEMORIES_REQUEST, workerFetchMemories);
}

export function* watchDeleteMemory() {
  yield takeLatest(DELETE_MEMORY_REQUEST, workerDeleteMemory);
}

export function* watchEditMemory() {
  yield takeLatest(EDIT_MEMORY_REQUEST, workerEditMemory);
}

export function* watchBatchDelete() {
  yield takeLatest(BATCH_DELETE_REQUEST, workerBatchDelete);
}

export function* watchCreateMemory() {
  yield takeLatest(CREATE_MEMORIES_REQUEST, workerCreateMemory);
}

export function* watchEditMobilePhoto() {
  yield takeLatest(EDIT_MOBILE_PHOTO_REQUEST, workerEditMobilePhoto);
}

export function* watchGetModerators() {
  yield takeLatest(GET_MODERATORS_REQUEST, workerGetModerators);
}

export function* watchAddModerators() {
  yield takeLatest(ADD_MODERATOR_REQUEST, workerAddModerator);
}

export function* watchDeleteModerator() {
  yield takeLatest(DELETE_MODERATOR_REQUEST, workerDeleteModerator);
}
