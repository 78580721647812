import { useState } from 'react';
import { Tabs as ChakraTabs } from '@chakra-ui/react';

import { TabList } from './TabList';
import { TabPanels } from './TabPanels';

export const Tabs = ({
  tabHeaders,
  tabPanels,
  noPadding = false,
  defaultRender = false,
  showProgressBar = false,
  customRender = false,
  onTabsChange,
  index,
  disableTabs,
  rightActions,
  bgColor
}) => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = index => {
    onTabsChange && onTabsChange(index);
    setTabIndex(index);
  };

  return (
    <ChakraTabs
      variant="unstyled"
      width="full"
      colorScheme="primary"
      index={index || tabIndex}
      onChange={handleTabsChange}
      minH="1px"
      height="100%"
      isLazy
      lazyBehavior="unmount"
      display={'flex'}
      flexDir="column"
      transition="all 2s linear"
    >
      <TabList
        tabHeaders={tabHeaders}
        tabIndex={tabIndex}
        rightActions={rightActions}
        disableTabs={disableTabs}
      />
      <TabPanels
        bgColor={bgColor}
        noPadding={noPadding}
        tabPanels={tabPanels}
        defaultRender={defaultRender}
        tabHeaders={tabHeaders}
        showProgressBar={showProgressBar}
        customRender={customRender}
      />
    </ChakraTabs>
  );
};
