import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  GET_CHECKLIST_ITEMS_REQUEST,
  IMPORT_CHECKLIST_ITEMS_REQUEST,
  UPDATE_CHECKLIST_ITEM_REQUEST,
  CREATE_CHECKLIST_ITEM_REQUEST,
  UPDATE_HOST_CHECKLIST_ITEM_REQUEST,
  DELETE_CHECKLIST_ITEM_REQUEST
} from './types';
import {
  getChecklistItemsSuccess,
  getChecklistItemsFailure,
  importChecklistItemsSuccess,
  importChecklistItemsFailure,
  updateChecklistItemFailure,
  updateChecklistItemSuccess,
  createChecklistItemSuccess,
  createChecklistItemFailure,
  updateHostChecklistItemSuccess,
  updateHostChecklistItemFailure,
  deleteChecklistItemSuccess,
  deleteChecklistItemFailure
} from './operations';
import {
  getChecklistItemsApi,
  importChecklistItemsAPI,
  updateEventChecklistItemApi,
  createChecklistItemApi,
  updateHostChecklistItemApi,
  deleteChecklistItemApi
} from './actions';
import { getEventRequest } from '../EventsContainer/operations';

const getEventId = state => state.loginReducer.eventId;
const getUserRole = state => state.loginReducer.role;

function* workerGetChecklistItems(action) {
  try {
    const { token } = action;
    const response = yield call(getChecklistItemsApi, { token });
    yield put(getChecklistItemsSuccess({ items: response.data && response.data.items }));
  } catch (error) {
    yield put(getChecklistItemsFailure(error.response && error.response.data));
  }
}
function* workerImportChecklistItems(action) {
  try {
    const { token, items, importType, eventId } = action;
    const response = yield call(importChecklistItemsAPI, {
      token,
      items,
      importType,
      eventId
    });
    yield put(importChecklistItemsSuccess({ success: response.data && response.data.success }));
    if (response.data && response.data.success) {
      const selectedEventId = yield select(getEventId);
      yield put(getEventRequest({ token, eventId: selectedEventId || eventId }));
    }
  } catch (error) {
    yield put(importChecklistItemsFailure(error.response && error.response.data));
  }
}
function* workerUpdateChecklistItem(action) {
  try {
    const { token, checklistItems } = action;
    const userRole = yield select(getUserRole);
    let eventId;
    if (userRole === 'host') eventId = yield select(getEventId);
    if (userRole === 'staff') eventId = action.eventId;
    console.log({ action, userRole, token, eventId, checklistItems });
    const response = yield call(updateEventChecklistItemApi, { token, eventId, checklistItems });
    yield put(updateChecklistItemSuccess({ success: response.data && response.data.success }));
    if (response.data && response.data.success) {
      yield put(getEventRequest({ token, eventId }));
    }
  } catch (error) {
    yield put(updateChecklistItemFailure(error.response && error.response.data));
  }
}
function* workerUpdateHostChecklistItem(action) {
  try {
    const userRole = yield select(getUserRole);
    const { token, itemId, checklistItem, eventId: id } = action;
    const eventId = yield select(getEventId);
    let response;
    if (userRole === 'host') {
      response = yield call(updateHostChecklistItemApi, {
        token,
        itemId,
        checklistItem,
        eventId
      });
    }
    if (userRole === 'staff') {
      response = yield call(updateHostChecklistItemApi, {
        token,
        itemId,
        checklistItem,
        eventId: id
      });
    }
    yield put(updateHostChecklistItemSuccess({ success: response.data && response.data.success }));
    if (response.data && response.data.success) {
      if (userRole === 'host') {
        yield put(getEventRequest({ token, eventId }));
      }
      if (userRole === 'staff') {
        yield put(getEventRequest({ token, eventId: id }));
      }
    }
  } catch (error) {
    yield put(updateHostChecklistItemFailure(error.response && error.response.data));
  }
}
function* workerCreateChecklistItem(action) {
  try {
    const { token, checklistItem } = action;
    const eventId = yield select(getEventId);
    const response = yield call(createChecklistItemApi, { token, checklistItem });
    yield put(createChecklistItemSuccess({ success: response.data && response.data.success }));
    if (response.data && response.data.success) {
      yield put(getEventRequest({ token, eventId }));
    }
  } catch (error) {
    yield put(createChecklistItemFailure(error.response && error.response.data));
  }
}
function* workerDeleteChecklistItem(action) {
  try {
    const { token, id } = action;
    const eventId = yield select(getEventId);
    const response = yield call(deleteChecklistItemApi, { token, eventId, id });
    yield put(deleteChecklistItemSuccess({ success: response.data && response.data.success }));
    if (response.data && response.data.success) {
      yield put(getEventRequest({ token, eventId }));
    }
  } catch (error) {
    yield put(deleteChecklistItemFailure(error.response && error.response.data));
  }
}

export function* watchGetChecklistItems() {
  yield takeLatest(GET_CHECKLIST_ITEMS_REQUEST, workerGetChecklistItems);
}
export function* watchImportChecklistItems() {
  yield takeLatest(IMPORT_CHECKLIST_ITEMS_REQUEST, workerImportChecklistItems);
}
export function* watchUpdateChecklistItem() {
  yield takeLatest(UPDATE_CHECKLIST_ITEM_REQUEST, workerUpdateChecklistItem);
}
export function* watchUpdateHostChecklistItem() {
  yield takeLatest(UPDATE_HOST_CHECKLIST_ITEM_REQUEST, workerUpdateHostChecklistItem);
}
export function* watchCreateChecklistItem() {
  yield takeLatest(CREATE_CHECKLIST_ITEM_REQUEST, workerCreateChecklistItem);
}
export function* watchDeleteChecklistItem() {
  yield takeLatest(DELETE_CHECKLIST_ITEM_REQUEST, workerDeleteChecklistItem);
}
