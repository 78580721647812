import {
  EVENT_REQUEST,
  NOTES_REQUEST,
  NOTE_CREATE_REQUEST,
  NOTE_DELETE_REQUEST,
  EVENT_PHOTO_EDIT_REQUEST,
  IDEAS_REQUEST,
  EVENT_IDEA_CREATE_REQUEST,
  EVENT_IDEA_EDIT_REQUEST,
  EVENT_IDEA_DELETE_REQUEST,
  VENUE_STAFF_REQUEST,
  EVENT_CONTACTS_UPDATE_REQUEST,
  NOTES_CLEAN,
  CLOUD_IMAGE_UPLOAD_REQUEST,
  CLEAR_IMAGE_UPLOAD,
  OPEN_ADD_CONTACT_MODAL,
  CLOSE_ADD_CONTACT_MODAL,
  RESET_ADDED_CONTACT,
  NOTE_EDIT_REQUEST,
  RESET_EDITED_NOTE,
  PROFILE_AVATAR_EDIT_REQUEST
} from './types';

export const doGetEvent = ({ token, eventId }) => ({ type: EVENT_REQUEST, token, eventId });
export const doUploadImageToCloud = ({ fileToUpload, filename, uploadType }) => ({
  type: CLOUD_IMAGE_UPLOAD_REQUEST,
  fileToUpload,
  filename,
  uploadType
});
export const doEditEventPhoto = ({ token, eventId, photo }) => ({
  type: EVENT_PHOTO_EDIT_REQUEST,
  token,
  eventId,
  photo
});
export const doGetNotes = ({ token, eventId }) => ({ type: NOTES_REQUEST, token, eventId });
export const doCreateNote = ({ token, text, eventId }) => ({
  type: NOTE_CREATE_REQUEST,
  token,
  text,
  eventId
});

export const doEditNote = ({ token, text, noteId, eventId }) => ({
  type: NOTE_EDIT_REQUEST,
  token,
  text,
  noteId,
  eventId
});

export const doDeleteNote = ({ token, noteId }) => ({ type: NOTE_DELETE_REQUEST, token, noteId });
export const doGetIdeas = ({ token }) => ({ type: IDEAS_REQUEST, token });
export const doCreateIdea = ({ token, url, thumbnail, caption, eventId }) => ({
  type: EVENT_IDEA_CREATE_REQUEST,
  token,
  url,
  thumbnail,
  caption,
  eventId
});
export const doEditEventIdea = ({ token, ideaId, caption }) => ({
  type: EVENT_IDEA_EDIT_REQUEST,
  token,
  ideaId,
  caption
});

export const doDeleteEventIdea = ({ token, ideaId }) => ({
  type: EVENT_IDEA_DELETE_REQUEST,
  token,
  ideaId
});

export const doEditProfileAvatar = ({ userId, photo }) => ({
  type: PROFILE_AVATAR_EDIT_REQUEST,
  userId,
  photo
});

export const doGetEventStaff = ({ token, venueId }) => ({
  type: VENUE_STAFF_REQUEST,
  token,
  venueId
});

export const doUpdateEventContacts = ({ token, eventId, contacts }) => ({
  type: EVENT_CONTACTS_UPDATE_REQUEST,
  token,
  eventId,
  contacts
});

export const clean = () => ({
  type: NOTES_CLEAN
});

export const clearImageUpload = () => ({
  type: CLEAR_IMAGE_UPLOAD
});

export const openAddContactModal = () => ({
  type: OPEN_ADD_CONTACT_MODAL
});

export const closeAddContactModal = () => ({
  type: CLOSE_ADD_CONTACT_MODAL
});

export const resetAddedContact = () => ({
  type: RESET_ADDED_CONTACT
});

export const resetEditedNote = () => ({
  type: RESET_EDITED_NOTE
});
