import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import styled from 'styled-components';
import { Card, CardHeader, CardBody } from 'src/v2/UIComponents/Primitives/Card';
import { Skeleton } from 'src/v2/UIComponents/Primitives';
import { CardEmptyState } from '../CardEmptyState';

export const CardWrapper = styled(Card)`
  height: 100%;
  min-height: 1px;
  @media (max-width: 1440px) {
    height: 100%;
  }
`;

const fakeRows = ['', '', ''];

export const BaseCard = ({
  children,
  titleIcon,
  headerBtnAction,
  headerBtnIcon,
  headerBtnLabel,
  title
}) => {
  return (
    <CardWrapper>
      <CardHeader
        iconTitle={titleIcon}
        title={title}
        btnLabel={headerBtnLabel}
        btnAction={headerBtnAction}
        btnIcon={headerBtnIcon}
      />
      <CardBody paddingX={0} paddingTop="20px" paddingBottom={0}>
        {children}
      </CardBody>
    </CardWrapper>
  );
};

export const TableCard = ({
  titleIcon,
  headerBtnAction,
  headers,
  rows,
  fetching,
  title,
  headerBtnIcon,
  headerBtnLabel,
  emptyStateProps
}) => {
  const showSkeleton = fetching;
  const showEmptyState = !fetching && rows?.length === 0;

  return (
    <BaseCard
      title={title}
      headerBtnLabel={headerBtnLabel}
      headerBtnAction={headerBtnAction}
      headerBtnIcon={headerBtnIcon}
      titleIcon={titleIcon}
    >
      {showEmptyState && (
        // <CardEmptyState title="No Documents" btnLabel="Add Document" marginTop="-20px" />
        <CardEmptyState
          title={emptyStateProps?.title}
          btnLabel={emptyStateProps?.btnLabel}
          btnAction={emptyStateProps?.btnAction}
          marginTop="-20px"
        />
      )}
      {!showEmptyState && (
        <Table variant="simple">
          <Thead>
            <Tr>
              {headers?.map(val => (
                <Th
                  key={val?.key}
                  fontFamily="Lato"
                  fontWeight={700}
                  fontSize="14px"
                  lineHeight="16px"
                  color="#A0A4A8"
                  letterSpacing={0}
                  paddingTop={0}
                  textTransform="capitalize"
                  marginBottom="5px"
                >
                  {val?.label}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {!showSkeleton &&
              rows?.map((row, key) => {
                return (
                  <Tr key={key}>
                    {headers?.map(head => {
                      return (
                        <Td
                          key={head.key}
                          fontFamily="Lato"
                          fontWeight={400}
                          fontSize="14px"
                          lineHeight="20px"
                          placeContent="center"
                        >
                          {row[head.key]}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            {showSkeleton &&
              fakeRows.map((_, key) => (
                <Tr key={key}>
                  <Td>
                    <Skeleton height="16px" />
                  </Td>
                  <Td>
                    <Skeleton height="16px" />
                  </Td>
                  <Td>
                    <Skeleton height="16px" />
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      )}
    </BaseCard>
  );
};
