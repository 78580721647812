import { ACTIVITY_DRAWER } from 'src/v2/Drawers/Redux/types';
import { SET_LEAD_TYPE_SUCCESS } from 'src/containers/AddEventModal/types';
import {
  BasicUserObject,
  EventActivityObject,
  EventNoteData,
  EventResponseObject,
  RawEventData,
  ResponseLeadInfo
} from 'venuex-types';
import {
  GET_LEADS_SUCCESS,
  GET_LEADS_FAILURE,
  GET_LEADS_REQUEST,
  GET_LEAD_REQUEST,
  GET_LEAD_SUCCESS,
  GET_LEAD_FAILURE,
  OPEN_LEADS_DETAILS,
  CLOSE_LEADS_DETAILS,
  OPEN_LEADS_FORM,
  CLOSE_LEADS_FORM,
  OPEN_LEADS_NOTES_FORM,
  CLOSE_LEADS_NOTES_FORM,
  ADD_LEAD_FAILURE,
  OPEN_CONTRACT_MODAL,
  CLOSE_CONTRACT_MODAL,
  CLOSE_PROPOSAL_MODAL,
  OPEN_PROPOSAL_MODAL,
  UPDATE_LEAD_REQUEST,
  UPDATE_LEAD_SUCCESS,
  UPDATE_LEAD_FAILURE
} from './types';
import { GET_EVENT_FAILURE } from '../EventsContainer/types';

export interface ParsedLead extends Omit<RawEventData, 'created'> {
  sourceLabel?: string;
  salespersonData?: BasicUserObject;
  salespersonName?: string;
  leadStatusNumber?: number;
  created: Date;
  leadInfo: ResponseLeadInfo;
  possibleDuplicates?: string[];
}

export type LeadsReducerTypes = {
  leads?: ParsedLead[];
  fetchingLeads: boolean;
  lead?: EventResponseObject;
  fetchingLead: boolean;
  isLeadsDetailsOpen: boolean;
  isLeadsNotesFormOpen: boolean;
  isProposalModalOpen: boolean;
  isContractModalOpen: boolean;
  updatingLead: boolean;
  leadsFormTabIndex: number;
  editNote: boolean;
  editActivity: boolean;
  activity?: EventActivityObject;
  note?: EventNoteData;
  defaultTabIndex: number;
  isLeadFormOpen: boolean;
  error: any;
};

const initialState: LeadsReducerTypes = {
  leads: undefined,
  fetchingLeads: false,
  lead: undefined,
  fetchingLead: false,
  isLeadsDetailsOpen: false,
  isLeadsNotesFormOpen: false,
  isLeadFormOpen: false,
  isProposalModalOpen: false,
  isContractModalOpen: false,
  updatingLead: false,
  leadsFormTabIndex: 0,
  editNote: false,
  editActivity: false,
  activity: undefined,
  note: undefined,
  defaultTabIndex: 0,
  error: undefined
};

export const leadsReducer = (state = initialState, action): LeadsReducerTypes => {
  switch (action.type) {
    case GET_LEADS_SUCCESS:
      return {
        ...state,
        leads: action.leads,
        fetchingLeads: false
      };
    case GET_LEADS_FAILURE:
      return {
        ...state,
        error: action.error,
        leads: undefined,
        fetchingLeads: false
      };
    case GET_LEADS_REQUEST:
      return {
        ...state,
        fetchingLeads: true
      };
    case GET_LEAD_REQUEST:
      return {
        ...state,
        fetchingLead: true
      };
    case GET_LEAD_SUCCESS:
      return {
        ...state,
        lead: action.lead,
        fetchingLead: false
      };
    case GET_LEAD_FAILURE:
      return {
        ...state,
        error: action.error,
        fetchingLead: false
      };
    case OPEN_LEADS_DETAILS:
      return {
        ...state,
        isLeadsDetailsOpen: true,
        defaultTabIndex: action.tabIndex || 0
      };
    case CLOSE_LEADS_DETAILS:
      return {
        ...state,
        isLeadsDetailsOpen: false,
        defaultTabIndex: 0,
        lead: undefined
      };
    case GET_EVENT_FAILURE:
      return {
        ...state,
        isLeadsDetailsOpen: false,
        lead: undefined
      };
    case OPEN_LEADS_FORM:
      return {
        ...state,
        isLeadFormOpen: true,
        leadsFormTabIndex: action.tabIndex
      };
    case CLOSE_LEADS_FORM:
      return {
        ...state,
        isLeadFormOpen: false
      };
    case OPEN_LEADS_NOTES_FORM:
      return {
        ...state,
        isLeadsNotesFormOpen: true,
        editNote: !!action.editNote,
        note: action.note
      };
    case CLOSE_LEADS_NOTES_FORM:
      return {
        ...state,
        isLeadsNotesFormOpen: false,
        editNote: false,
        note: undefined
      };

    case ACTIVITY_DRAWER.OPEN:
      return {
        ...state,
        editActivity: !!action.editActivity,
        activity: action.activity
      };
    case ACTIVITY_DRAWER.CLOSE:
      return {
        ...state,
        editActivity: false,
        activity: undefined
      };
    case ADD_LEAD_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case OPEN_CONTRACT_MODAL:
      return {
        ...state,
        isContractModalOpen: true
      };
    case CLOSE_CONTRACT_MODAL:
      return {
        ...state,
        isContractModalOpen: false
      };
    case OPEN_PROPOSAL_MODAL:
      return {
        ...state,
        isProposalModalOpen: true
      };
    case CLOSE_PROPOSAL_MODAL:
      return {
        ...state,
        isProposalModalOpen: false
      };
    case UPDATE_LEAD_REQUEST:
      return {
        ...state,
        updatingLead: true
      };
    case UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        updatingLead: false,
        lead: action.lead
      };
    case UPDATE_LEAD_FAILURE:
      return {
        ...state,
        updatingLead: false
      };
    case SET_LEAD_TYPE_SUCCESS: {
      console.log('leads reducer', action.updatedLead.id);
      return {
        ...state,
        leads: state.leads?.map(evt => {
          if (evt.id === action.updatedLead.id) {
            console.log('found match', evt.id);
            return {
              ...action.updatedLead
            };
          }
          return evt;
        })
      };
    }
    default:
      return state;
  }
};
