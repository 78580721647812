import { takeLatest, put, call } from 'redux-saga/effects';

import {
  VENDOR_LIST_REQUEST,
  VENDOR_LIST_SUCCESS,
  VENDOR_LIST_FAILURE,
  CONTACT_VENDOR_FAILURE,
  CONTACT_VENDOR_SUCCESS,
  CONTACT_VENDOR_REQUEST
} from './types';
import { listVendors, contactVendor } from './actions';

function* workerVendorsList({ token, venueId }) {
  try {
    const response = yield call(listVendors, { token, venueId });
    const { vendorDetails } = response.data;
    yield put({
      type: VENDOR_LIST_SUCCESS,
      listVendorData: vendorDetails
    });
  } catch (error) {
    yield put({
      type: VENDOR_LIST_FAILURE,
      listVendorErrors: error.response
    });
  }
}

function* workerContactVendor({ emailData, token }) {
  try {
    const response = yield call(contactVendor, { emailData, token });
    yield put({
      type: CONTACT_VENDOR_SUCCESS,
      contactVendorSuccess: response.data
    });
  } catch (error) {
    yield put({
      type: CONTACT_VENDOR_FAILURE,
      contactVendorErr: error.response
    });
  }
}

export function* watcherVendorList() {
  yield takeLatest(VENDOR_LIST_REQUEST, workerVendorsList);
}

export function* watcherContactVendor() {
  yield takeLatest(CONTACT_VENDOR_REQUEST, workerContactVendor);
}
