import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useVenue, useUser } from 'src/v2/Hooks/CollectionHooks';
import { useConfirm } from 'src/v2/Hooks/HelperHooks';

// hot fix, need to refactor
const PolicyModal = () => {
  const {
    fireStoreVenueState: { venue }
  } = useVenue();

  const { policyRequired, doPolicyAccept, doPolicyReject } = useUser();

  const handleReject = () => {
    doPolicyReject();
    console.log('reject action ');
  };

  const handleAccept = () => {
    doPolicyAccept();
  };

  const { openConfirm } = useConfirm({
    acceptAction: handleAccept,
    confirmHookId: 'policyConfirm',
    rejectAction: handleReject
  });

  const RenderTermsAndPrivacy = () => {
    const policyPrivacy = venue?.policyPrivacy?.url;
    const policyTerms = venue?.policyTerms?.url;

    const linkStyle = {
      textDecoration: 'underline',
      color: 'var(--colors-primary)'
    };

    return (
      <div>
        By using this app, you acknowledge that you have read and agreed to our{' '}
        <a href={policyTerms || ''} style={linkStyle} target="_blank" rel="noopener noreferrer">
          Terms and Conditions
        </a>{' '}
        as well as our{' '}
        <a href={policyPrivacy || ''} style={linkStyle} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        .
      </div>
    );
  };

  useEffect(() => {
    if (policyRequired) {
      openConfirm({
        title: 'Please accept terms and conditions',
        description: <RenderTermsAndPrivacy />,
        confirmId: 'policyConfirm',
        confirmType: 'policyModal'
      });
    }
  }, [policyRequired]);

  if (!venue) return null;

  return (
    // <Modal isOpen={policyRequired} onRequestClose={null} height={250} width={400}>
    //   <ConfirmModalMessage>
    //     <ModalStyles>
    //       <p>By continuing to use this app, you accept the policies set forth by {venue.name}:</p>
    //       <ModalStyles.Link>
    //         {Boolean(policyPrivacy) && (
    //           <a href={policyPrivacy.url} target="_blank" rel="noopener noreferrer">
    //             Privacy Policy
    //           </a>
    //         )}
    //         {Boolean(policyTerms) && (
    //           <a href={policyTerms.url} target="_blank" rel="noopener noreferrer">
    //             Terms & Conditions
    //           </a>
    //         )}
    //       </ModalStyles.Link>
    //     </ModalStyles>
    //   </ConfirmModalMessage>
    //   <ConfirmModalFooter>
    //     {/* <Button
    //       label={'CANCEL'}
    //       padding={'30px'}
    //       bgColor={Colors.white}
    //       color={Colors.redC0}
    //       action={() => handleReject()}
    //     />
    //     <Button
    //       label={'ACCEPT'}
    //       padding={'30px'}
    //       bgColor={Colors.white}
    //       color={Colors.green2C}
    //       action={() => handleAccept()}
    //     /> */}
    //   </ConfirmModalFooter>
    // </Modal>
    <div />
  );
};

PolicyModal.propTypes = {
  policyRequired: PropTypes.bool,
  venue: PropTypes.object,
  doPolicyAccept: PropTypes.func,
  doPolicyReject: PropTypes.func
};

export default PolicyModal;
