import React from 'react';

import Colors from '../Colors';

const Comment = props => {
  return (
    <svg width={props.size} height={props.size} {...props} viewBox="0 0 64 64">
      <g>
        <path
          d="M27,33.5V15.9c0-2.2,2.2-3.9,4.4-3.9h25.5c2.2,0,4.1,1.6,4.1,3.9v17.7c0,2.2-1.9,4.5-4.1,4.5H53v6l-8.3-6H31.4  C29.2,38,27,35.7,27,33.5z"
          fill="none"
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M23,20H8.1C5.9,20,4,21.6,4,23.9v17.7C4,43.7,5.9,46,8.1,46H12v6l8.3-6h13.3c2.2,0,4.4-2.3,4.4-4.5"
          fill="none"
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

Comment.defaultProps = {
  color: Colors.black,
  size: 24
};

export default Comment;
