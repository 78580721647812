import { Progress, Text, Flex, Box } from '@chakra-ui/react';
import { FC } from 'react';
import { ProgressBarProps } from 'src/Types/GlobalTypes';

export const TitleCompletedVariant = ({ completed, total }) => (
  <Flex>
    <Text fontSize="16px" lineHeight="20px">
      {completed} items
    </Text>
    <Text fontSize="16px" lineHeight="20px" fontWeight={900} color="var(--colors-primary)" mx={1}>
      completed
    </Text>
    <Text fontSize="16px" lineHeight="20px">
      out of {total}
    </Text>
  </Flex>
);

export const TitleOfVariant = ({
  completed,
  total,
  titleSuffix
}: {
  completed: number;
  total: number;
  titleSuffix?: string;
}) => (
  <Box>
    <Text as="span" fontSize="14px" lineHeight="20px" fontWeight={600}>
      {completed}
      <Text as="span" fontWeight={400} mx={1}>
        of
      </Text>
      {total} {titleSuffix || ''}
    </Text>
  </Box>
);

export const TitleLabelVariant = ({ title }) => (
  <Flex>
    <Text fontSize="16px" lineHeight="20px">
      {title}
    </Text>
  </Flex>
);

export const ProgressBar: FC<ProgressBarProps> = ({
  value,
  subLabel = '',
  titleComponent,
  goal,
  color
}) => {
  const percent = (value * 100) / goal;
  return (
    <Box mb="24px" paddingX="3px">
      <Flex marginBottom="18px" justifyContent="space-between">
        {!subLabel && (
          <Text fontWeight={400} fontSize="14px" lineHeight="20px">
            {Math.round(value)}%
          </Text>
        )}
        {titleComponent}
        {subLabel && (
          <Text fontWeight={400} fontSize="14px" lineHeight="20px">
            {subLabel}
          </Text>
        )}
      </Flex>
      <Flex flex={1} alignItems="center">
        <Progress
          value={(goal > 0 && percent) || 0}
          variant={color && 'customGreen'}
          width="full"
          borderRadius="5px"
        />
      </Flex>
    </Box>
  );
};
