import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export const createBudgetItemCategoryApi = ({ token, name }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/budgetitemcategories`,
    headers: { Authorization: token },
    data: { name }
  });
};

export const createBudgetItemApi = ({ token, data }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/budgetitems`,
    headers: { Authorization: token },
    data
  });
};

export const updateBudgetItemApi = ({ token, data, itemId }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/budgetitems/${itemId}`,
    headers: { Authorization: token },
    data
  });
};

export const deleteBudgetItemApi = ({ token, itemId }) => {
  return axios({
    method: 'delete',
    url: `${apiUri}/budgetitems/${itemId}`,
    headers: { Authorization: token }
  });
};

export const fetchBudgetItemCategoriesApi = ({ token }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/budgetitemcategories`,
    headers: { Authorization: token }
  });
};

export const editBudgetItemCategoryApi = ({ token, categoryId, data }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/budgetitemcategories/${categoryId}`,
    headers: { Authorization: token },
    data
  });
};

export const deleteBudgetItemCategoryApi = ({ token, categoryId, deleteType = 'categoryOnly' }) => {
  return axios({
    method: 'delete',
    url: `${apiUri}/budgetitemcategories/${categoryId}?deleteType=${deleteType}`,
    headers: { Authorization: token }
  });
};

export const batchDeleteBudgetItemsApi = ({ token, data }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/budgetitems/batchdelete`,
    headers: { Authorization: token },
    data
  });
};

export const fetchBudgetItemsApi = ({ token }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/budgetitems`,
    headers: { Authorization: token }
  });
};
