import { takeLatest, put, call } from 'redux-saga/effects';
import { OPEN_STAFF_DETAILS } from './types';
import { openStaffDetailsSuccess, openStaffDetailsFailure } from './operations';
import { getStaffDetails } from './actions';

function* workerGetStaffDetails({ token, venueId, staffId }) {
  try {
    const response = yield call(getStaffDetails, { token, venueId, staffId });
    yield put(openStaffDetailsSuccess({ user: response && response.data && response.data.user }));
  } catch (error) {
    yield put(openStaffDetailsFailure({ error: error.response && error.response.data }));
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* watchGetStaffDetails() {
  yield takeLatest(OPEN_STAFF_DETAILS, workerGetStaffDetails);
}
