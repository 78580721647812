export const GET_LEADS_REQUEST = 'GET_LEADS_REQUEST';
export const GET_LEADS_SUCCESS = 'GET_LEADS_SUCCESS';
export const GET_LEADS_FAILURE = 'GET_LEADS_FAILURE';
export const GET_LEAD_REQUEST = 'GET_LEAD_REQUEST';
export const GET_LEAD_SUCCESS = 'GET_LEAD_SUCCESS';
export const GET_LEAD_FAILURE = 'GET_LEAD_FAILURE';
export const OPEN_LEADS_DETAILS = 'OPEN_LEADS_DETAILS';
export const CLOSE_LEADS_DETAILS = 'CLOSE_LEADS_DETAILS';
export const OPEN_LEADS_FORM = 'OPEN_LEADS_FORM';
export const CLOSE_LEADS_FORM = 'CLOSE_LEADS_FORM';
export const OPEN_LEADS_NOTES_FORM = 'OPEN_LEADS_NOTES_FORM';
export const CLOSE_LEADS_NOTES_FORM = 'CLOSE_LEADS_NOTES_FORM';
export const ADD_LEAD_REQUEST = 'ADD_LEAD_REQUEST';
export const ADD_LEAD_SUCCESS = 'ADD_LEAD_SUCCESS';
export const ADD_LEAD_FAILURE = 'ADD_LEAD_FAILURE';
export const ADD_NOTE_REQUEST = 'ADD_NOTE_REQUEST';
export const ADD_NOTE_SUCCESS = 'ADD_NOTE_SUCCESS';
export const ADD_NOTE_FAILURE = 'ADD_NOTE_FAILURE';
export const DELETE_LEAD_REQUEST = 'DELETE_LEAD_REQUEST';
export const DELETE_LEAD_FAILURE = 'DELETE_LEAD_FAILURE';
export const DELETE_LEAD_SUCCESS = 'DELETE_LEAD_SUCCESS';
export const UPDATE_LEAD_REQUEST = 'UPDATE_LEAD_REQUEST';
export const UPDATE_LEAD_SUCCESS = 'UPDATE_LEAD_SUCCESS';
export const UPDATE_LEAD_FAILURE = 'UPDATE_LEAD_FAILURE';

export const OPEN_CONTRACT_MODAL = 'OPEN_CONTRACT_MODAL';
export const CLOSE_CONTRACT_MODAL = 'CLOSE_CONTRACT_MODAL';
export const OPEN_PROPOSAL_MODAL = 'OPEN_PROPOSAL_MODAL';
export const CLOSE_PROPOSAL_MODAL = 'CLOSE_PROPOSAL_MODAL';
