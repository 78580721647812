import {
  FETCH_EVENT_VENDORS_REQUEST,
  FETCH_EVENT_VENDORS_SUCCESS,
  FETCH_EVENT_VENDORS_FAILURE,
  ADD_EVENT_VENDOR_REQUEST,
  ADD_EVENT_VENDOR_SUCCESS,
  ADD_EVENT_VENDOR_FAILURE,
  UPDATE_EVENT_VENDOR_REQUEST,
  UPDATE_EVENT_VENDOR_SUCCESS,
  UPDATE_EVENT_VENDOR_FAILURE,
  DELETE_EVENT_VENDOR_REQUEST,
  DELETE_EVENT_VENDOR_SUCCESS,
  DELETE_EVENT_VENDOR_FAILURE,
  GET_EVENT_VENDORS_BY_EVENT_ID_REQUEST,
  GET_EVENT_VENDORS_BY_EVENT_ID_SUCCESS,
  GET_EVENT_VENDORS_BY_EVENT_ID_FAILURE,
  OPEN_VENDOR_MODAL
} from './types';

const initialState = {
  status: '',
  error: '',
  fetchingEventVendors: false,
  eventVendors: [],
  updatingEventVendorSuccess: null,
  updatingEventVendorError: null,
  deletingEventVendorSuccess: null,
  deletingEventVendorError: null,
  addingEventVendorSuccess: null,
  addingEventVendorError: null,
  isVendorModalOpen: false
};

export const eventVendorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EVENT_VENDOR_REQUEST:
      return {
        ...state,
        status: 'addingVendor',
        addingEventVendorSuccess: false
      };
    case ADD_EVENT_VENDOR_SUCCESS:
      return {
        ...state,
        status: 'addingVendorSuccess',
        addingEventVendorSuccess: true
      };
    case ADD_EVENT_VENDOR_FAILURE:
      return {
        ...state,
        status: 'addingVendorFailure',
        error: action.error
      };
    case FETCH_EVENT_VENDORS_REQUEST:
      return {
        ...state,
        status: 'fetchingEventVendors'
      };
    case FETCH_EVENT_VENDORS_SUCCESS:
      return {
        ...state,
        status: 'fetchEventVendorsSuccess',
        eventVendors: action.eventVendors
      };
    case FETCH_EVENT_VENDORS_FAILURE:
      return {
        ...state,
        status: 'fetchEventVendorsFailure',
        error: action.error
      };
    case UPDATE_EVENT_VENDOR_REQUEST:
      return {
        ...state,
        status: 'updatingVendor'
      };
    case UPDATE_EVENT_VENDOR_SUCCESS:
      return {
        ...state,
        status: 'updatingVendorSuccess',
        updatingEventVendorSuccess: action.updatingEventVendorSuccess
      };
    case UPDATE_EVENT_VENDOR_FAILURE:
      return {
        ...state,
        status: 'updatingVendorFailed',
        updateEventVendorError: action.updateEventVendorError
      };

    case DELETE_EVENT_VENDOR_REQUEST:
      return {
        ...state,
        status: 'deletingVendor'
      };

    case DELETE_EVENT_VENDOR_SUCCESS:
      return {
        ...state,
        status: 'deletingVendorSuccess'
      };
    case DELETE_EVENT_VENDOR_FAILURE:
      return {
        ...state,
        status: 'deletingVendorFailed',
        deletingEventVendorError: action.deletingEventVendorError
      };
    case GET_EVENT_VENDORS_BY_EVENT_ID_REQUEST:
      return {
        ...state,
        fetchingEventVendors: true
      };
    case GET_EVENT_VENDORS_BY_EVENT_ID_SUCCESS:
      return {
        ...state,
        eventVendors: action.eventVendors,
        fetchingEventVendors: false
      };
    case GET_EVENT_VENDORS_BY_EVENT_ID_FAILURE:
      return {
        ...state,
        eventVendors: [],
        error: action.error,
        fetchingEventVendors: true
      };
    case OPEN_VENDOR_MODAL:
      return {
        ...state,
        isVendorModalOpen: action.isOpen
      };
    default:
      return state;
  }
};
