import { Icon, Flex } from '@chakra-ui/react';
import { MdOutlineFileDownload } from 'react-icons/md';
import { FloorPlan } from 'src/v2/UIComponents/Primitives/Icons';
import { ListCard } from 'src/v2/UIComponents/Primitives/Card/FullCard/FullCard';
import { Tag } from 'src/v2/UIComponents/Primitives';
import { useEvents } from 'src/v2/Hooks/CollectionHooks';
import {
  DOWNLOADING_EVENT_PACKET_HEADER,
  DOWNLOADING_LAYOUT_PACKET_MESSAGE,
  STILL_WORKING
} from 'src/components/ModalLoader/types';
import { TextPairing } from '../TextPairing';

export const FloorPlanCard = ({ event, fetchingEvent }) => {
  const { roomData, layoutOption, layoutStatus, layoutData } = event;

  const legacyLayout = layoutData?.type !== 'template';

  const layoutStatusText = layoutStatus || legacyLayout ? 'Available' : false;

  // TODO: #566 Fix why roomOption.label is not working
  const list = [
    {
      title: 'Room',
      value: roomData?.name
    },
    {
      title: 'Layout',
      value: layoutOption?.label
    }
  ];

  const {
    openModalLoader,
    downloadEventPacket,
    updateModalLoaderMessage,
    closeModalLoader
  } = useEvents();

  const handleDownloadEventPacketClick = () => {
    downloadEventPacket({
      eventId: event.id,
      updateModalLoaderMessage,
      closeModalLoader
    });
    openModalLoader({
      showProgressBar: true,
      modalLoaderMessage: DOWNLOADING_LAYOUT_PACKET_MESSAGE(event.title),
      modalLoaderHeader: DOWNLOADING_EVENT_PACKET_HEADER
    });
    const update = () =>
      updateModalLoaderMessage({
        showProgressBar: true,
        modalLoaderMessage: STILL_WORKING
      });
    setTimeout(() => update(), 3500);
  };

  const footer = () => (
    <Flex mt="20px" justifyContent="space-between" alignItems="flex-end">
      <Flex flexDir="column">
        <TextPairing
          fetchingData={fetchingEvent}
          title="Status"
          subtitle={`${event?.guestsTableAssigned || 0} Guests assigned to tables`}
          missingMessage={undefined}
          titleColor={undefined}
        />
      </Flex>
      {layoutStatusText && <Tag title={layoutStatusText} />}
    </Flex>
  );
  return (
    <ListCard
      titleIcon={<FloorPlan />}
      title="Floor Plan"
      headerBtnIcon={
        <Icon
          as={MdOutlineFileDownload}
          onClick={handleDownloadEventPacketClick}
          w={6}
          h={6}
          color="var(--colors-primary)"
        />
      }
      list={list}
      fetching={fetchingEvent}
      footer={footer}
      headerBtnAction={undefined}
      headerBtnLabel={undefined}
    />
  );
};
