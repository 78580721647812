import { setTableNumber, setShapeNumber } from 'src/utils/Helpers';
import {
  ADD_TABLE_TO_LAYOUT,
  SELECT_TABLE,
  UNSELECT_TABLE,
  SAVE_LAYOUT_REQUEST,
  SAVE_LAYOUT_SUCCESS,
  SAVE_LAYOUT_FAILURE,
  ADD_TABLES_TO_LAYOUT,
  CLEAR_EDITOR,
  EDIT_TABLE_SETTINGS,
  DELETE_TABLE,
  SELECT_TOOL,
  UNSELECT_TOOL,
  SELECT_TEXT,
  ADD_TEXT_TO_LAYOUT,
  EDIT_TEXT_SETTINGS,
  UNSELECT_TEXT,
  ADD_SHAPE_TO_LAYOUT,
  SELECT_SHAPE,
  UNSELECT_SHAPE,
  EDIT_SHAPE_SETTINGS,
  ADD_SHAPES_TO_LAYOUT,
  ADD_TEXTS_TO_LAYOUT,
  DELETE_SHAPE,
  DELETE_TEXT,
  CHANGE_SAVE_INDICATOR,
  UPDATE_STATUS_SUCCESS
} from './types';
import {
  UPDATE_LAYOUT_REQUEST,
  UPDATE_LAYOUT_SUCCESS,
  UPDATE_LAYOUT_FAILURE
} from '../LayoutsContainer/types';

const initialState = {
  tables: [],
  texts: [],
  shapes: [],
  tableSelected: null,
  textSelected: null,
  shapeSelected: null,
  lastAddedTable: null,
  isFetching: false,
  error: null,
  toolSelected: null,
  saveIndicator: false
};

export const layoutsEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TABLE_TO_LAYOUT: {
      console.time('reducer_table_to_layout');
      const tableInfo = action.table;
      const isBrideGroomTable = tableInfo.brideGroomTable;
      let tableNumber = null;
      let tableIndex = null;
      console.timeEnd('reducer_table_to_layout');

      console.time('reducer_table_number');
      if (!isBrideGroomTable) {
        tableNumber = setTableNumber(state.tables, 1);
        tableIndex = tableNumber;
      } else {
        tableIndex = setTableNumber(state.tables, 5000);
      }
      console.timeEnd('reducer_table_number');

      const maxGuests = action.table.defaultGuests;
      const tableName = tableInfo.brideGroomTable ? tableInfo.name : '';

      const table = {
        ...action.table,
        number: tableNumber,
        name: tableName,
        index: tableIndex,
        maxGuests,
        objectType: 'table'
      };

      return {
        ...state,
        tables: [...state.tables, table],
        lastAddedTable: table,
        saveIndicator: true
      };
    }
    case ADD_TABLES_TO_LAYOUT:
      return {
        ...state,
        tables: action.tables
      };
    case DELETE_TABLE:
      return {
        ...state,
        tables: state.tables.filter(table => table.index !== action.table.index),
        saveIndicator: true
      };
    case EDIT_TABLE_SETTINGS:
      return {
        ...state,
        tables: state.tables.map(table => {
          if (table.index === action.table.index) {
            if (table.brideGroomTable) {
              return action.table;
            }
            // return this to assign the table number into an index deleted
            return {
              ...action.table,
              index: action.table.number
            };
          }
          return table;
        }),
        tableSelected: action.table,
        saveIndicator: true
      };
    case SELECT_TABLE:
      return {
        ...state,
        tableSelected: action.table,
        shapeSelected: null,
        textSelected: null
      };
    case UNSELECT_TABLE:
      return {
        ...state,
        tableSelected: null
      };
    case SAVE_LAYOUT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case SAVE_LAYOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null
      };
    case SAVE_LAYOUT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case UPDATE_LAYOUT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case UPDATE_LAYOUT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case UPDATE_LAYOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null
      };
    case CLEAR_EDITOR:
      return {
        ...state,
        tableSelected: null,
        shapeSelected: null,
        textSelected: null,
        tables: [],
        shapes: [],
        texts: [],
        saveIndicator: false,
        toolSelected: null,
        lastAddedTable: null
      };
    case SELECT_TOOL:
      return {
        ...state,
        toolSelected: action.tool
      };
    case UNSELECT_TOOL:
      return {
        ...state,
        toolSelected: null
      };
    case SELECT_TEXT:
      return {
        ...state,
        textSelected: action.text,
        tableSelected: null,
        shapeSelected: null
      };
    case ADD_TEXT_TO_LAYOUT: {
      return {
        ...state,
        texts: [...state.texts, { ...action.text }]
      };
    }
    case ADD_TEXTS_TO_LAYOUT:
      return {
        ...state,
        texts: action.texts || []
      };
    case EDIT_TEXT_SETTINGS:
      return {
        ...state,
        texts: state.texts.map(item => {
          if (item.index === action.text.index) {
            return action.text;
          }
          return item;
        }),
        textSelected: action.text,
        saveIndicator: true
      };
    case UNSELECT_TEXT:
      return {
        ...state,
        textSelected: null
      };
    case ADD_SHAPES_TO_LAYOUT:
      return {
        ...state,
        shapes: action.shapes || []
      };
    case ADD_SHAPE_TO_LAYOUT: {
      const shapeNumber = setShapeNumber(state.shapes);
      const index = state.shapes.length + 1;
      const shapeName = `${action.shape.name} ${index}`;

      return {
        ...state,
        shapes: [
          ...state.shapes,
          { ...action.shape, number: shapeNumber, name: shapeName, objectType: 'shape' }
        ]
      };
    }
    case SELECT_SHAPE:
      return {
        ...state,
        shapeSelected: action.shape,
        tableSelected: null,
        textSelected: null
      };
    case UNSELECT_SHAPE:
      return {
        ...state,
        shapeSelected: null
      };
    case EDIT_SHAPE_SETTINGS:
      return {
        ...state,
        shapes: state.shapes.map(item => {
          if (item.number === action.shape.number) {
            return action.shape;
          }
          return item;
        }),
        shapeSelected: action.shape,
        saveIndicator: true
      };
    case DELETE_SHAPE:
      return {
        ...state,
        shapes: state.shapes.filter(shape => shape.number !== action.shape.number),
        saveIndicator: true
      };
    case DELETE_TEXT:
      return {
        ...state,
        texts: state.texts.filter(text => text.index !== action.text.index),
        saveIndicator: true
      };
    case CHANGE_SAVE_INDICATOR:
      return {
        ...state,
        saveIndicator: action.value
      };
    default:
      return state;
  }
};
