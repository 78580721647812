import { FC } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { HStack } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormField, FormErrorMessage, FormLabel } from '../../Primitives';
import { StyledFormControl } from './styles';
import { DateFieldWithLabelProps } from './types';

export const DateFieldWithLabel: FC<DateFieldWithLabelProps> = ({
  isRequired,
  isDisabled,
  label,
  name,
  placeholder,
  rules,
  isHorizontalControl = false,
  showTimeSelect = true,
  allowPreviousDate,
  errorKey,
  minDateValue,
  ...rest
}) => {
  const methods = useFormContext();
  const {
    formState: { errors },
    control
  } = methods;

  let error;

  if ((errorKey && errorKey.index) || (errorKey && errorKey.index === 0)) {
    const { groupKey, inputName, index } = errorKey;
    error = errors?.[groupKey]?.[inputName]?.[index]?.date?.message;
  } else if (errorKey) {
    const { groupKey, inputName } = errorKey;
    error = errors?.[groupKey]?.[inputName]?.message || '';
  } else {
    error = errors?.[name]?.message || '';
  }

  const isInvalid = !!error;

  const dateFormat = (showTimeSelect && 'MMMM d, yyyy h:mm aa') || 'MMMM d, yyyy';

  const minDateVal = minDateValue?.length ? new Date(minDateValue) : undefined;

  const allowPrevious = allowPreviousDate ? undefined : new Date();

  const minDate = minDateVal || allowPrevious;

  return (
    <StyledFormControl
      isHorizontalControl={isHorizontalControl}
      isInvalid={isInvalid}
      isRequired={isRequired}
      isFullWidth
    >
      <HStack marginBottom="0px" justify="space-between" align="flex-end">
        <FormLabel label={label} />
        <FormErrorMessage>{error}</FormErrorMessage>
      </HStack>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, onBlur, value, ...restField } }) => {
          let pickerValue = value;
          if (pickerValue?.date) pickerValue = pickerValue.date;
          return (
            <DatePicker
              // enable={false}
              selected={pickerValue && moment(pickerValue).toDate()}
              minDate={minDate}
              onChange={e => {
                onChange(e);
                onBlur();
              }}
              showTimeSelect={showTimeSelect}
              dateFormat={dateFormat}
              placeholderText={placeholder}
              customInput={
                <FormField
                  {...restField}
                  {...rest}
                  {...methods}
                  fieldValue={new Date(pickerValue).toUTCString()}
                />
              }
            />
          );
        }}
      />
    </StyledFormControl>
  );
};
