import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmModal } from 'src/v2/UIComponents/Alert';
import ReactTooltip from 'react-tooltip/dist';
import AppStyles from './styles';
import SidebarContainer from '../../containers/SidebarContainer';
import Alert from '../Alert';

import InputInlineEdit from '../InputInlineEdit';
import PolicyModal from '../PolicyModal';
import { useWindowSize } from '../../hooks/useWindowSize';
import ScreenError from '../ScreenError';
import Constants from '../../utils/Constants';

const App = ({
  show,
  children,
  admin,
  venueId,
  eventId,
  doVenueIdRemove,
  doVenueIdSet,
  doEventIdRemove,
  doEventIdSet,
  policyRequired,
  venue = {},
  doPolicyAcceptTrigger,
  doPolicyReject,
  hideSidebar,
  setHideSidebar,
  grayBackground
}) => {
  const { width } = useWindowSize();

  const breakpoint = Constants.BREAKPOINTS.md;

  if (width <= breakpoint) {
    return <ScreenError width={width} />;
  }

  return (
    <AppStyles>
      {admin && (
        <AppStyles.AdminControls>
          <span>Admin Controls</span>
          {venueId && (
            <AppStyles.AdminControl>
              <InputInlineEdit
                value={eventId}
                label={'Event ID'}
                placeholder={'Event ID'}
                onDelete={() => doEventIdRemove()}
                onSubmit={id => doEventIdSet(id)}
              />
            </AppStyles.AdminControl>
          )}
          <AppStyles.AdminControl>
            <InputInlineEdit
              value={venueId}
              label={'Venue ID'}
              placeholder={'Venue ID'}
              onDelete={() => doVenueIdRemove()}
              onSubmit={id => doVenueIdSet(id)}
            />
          </AppStyles.AdminControl>
        </AppStyles.AdminControls>
      )}
      <SidebarContainer hideSidebar={hideSidebar} setHideSidebar={setHideSidebar} />
      <AppStyles.Main
        show={show}
        isAdmin={admin}
        grayBackground={grayBackground}
        hideSidebar={hideSidebar}
      >
        <AppStyles.MainInner>{children}</AppStyles.MainInner>
      </AppStyles.Main>
      <PolicyModal
        policyRequired={policyRequired}
        venue={venue}
        doPolicyAccept={doPolicyAcceptTrigger}
        doPolicyReject={doPolicyReject}
      />
      <Alert />
      <ConfirmModal />
      {hideSidebar && (
        <ReactTooltip id="sidebar-tooltips" effect="solid" place="right" aria-haspopup="true" />
      )}
    </AppStyles>
  );
};

App.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isAdmin: PropTypes.bool,
  admin: PropTypes.bool,
  venueId: PropTypes.string,
  eventId: PropTypes.string,
  doVenueIdRemove: PropTypes.func,
  doVenueIdSet: PropTypes.func,
  doEventIdRemove: PropTypes.func,
  doEventIdSet: PropTypes.func,
  policyRequired: PropTypes.bool,
  venue: PropTypes.object,
  doPolicyAcceptTrigger: PropTypes.func,
  doPolicyReject: PropTypes.func,
  hideSidebar: PropTypes.bool,
  setHideSidebar: PropTypes.func,
  grayBackground: PropTypes.bool
};

export default App;
