import React from 'react';
import PropTypes from 'prop-types';
import ChangePasswordStyles from './styles';
import ChangePasswordHeader from './ChangePasswordHeader';
import ChangePasswordFooter from './ChangePasswordFooter';
import ChangePasswordBody from './ChangePasswordBody';
import Button from '../../styles/Button';
import Colors from '../../styles/Colors';

const ChangePassword = ({
  closeModal,
  oldPassword,
  newPassword,
  confirmPassword,
  handleInputChange,
  handleSubmit,
  resetErrors,
  success
}) => {
  return (
    <ChangePasswordStyles>
      {success ? (
        <div>
          <ChangePasswordHeader closeModal={closeModal} title={'Password Changed'} />
          <ChangePasswordStyles.Success>
            Password changed successfully!
          </ChangePasswordStyles.Success>
          <ChangePasswordStyles.BtClose>
            <Button
              label={'Close'}
              padding={'40px'}
              bgColor={Colors.white}
              color={Colors.gray7D}
              action={closeModal}
            />
          </ChangePasswordStyles.BtClose>
        </div>
      ) : (
        <div>
          <ChangePasswordHeader closeModal={closeModal} title={'Change Password'} />
          <ChangePasswordBody
            oldPassword={oldPassword}
            newPassword={newPassword}
            confirmPassword={confirmPassword}
            handleInputChange={handleInputChange}
            resetErrors={resetErrors}
            success={success}
          />
          <ChangePasswordFooter
            closeModal={closeModal}
            handleSubmit={handleSubmit}
            disabled={!newPassword || !oldPassword || !confirmPassword}
          />
        </div>
      )}
    </ChangePasswordStyles>
  );
};

ChangePassword.propTypes = {
  closeModal: PropTypes.func,
  oldPassword: PropTypes.string,
  newPassword: PropTypes.string,
  confirmPassword: PropTypes.string,
  handleInputChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  resetErrors: PropTypes.string,
  success: PropTypes.string
};

export default ChangePassword;
