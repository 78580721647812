import { motion, AnimatePresence } from 'framer-motion';

export const SmoothContainerDiv = ({ isVisible = true, children, ...rest }) => (
  <AnimatePresence>
    {isVisible && (
      <motion.div
        initial={{ opacity: 0, height: (!isVisible && 0) || '100%' }}
        animate={{ opacity: 1, height: '100%' }}
        exit={{ opacity: 0, height: '0px' }}
        style={{ width: '100%', height: '100%', minHeight: '1px' }}
        transition={{ duration: 0.3 }}
        {...rest}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);

export const GrowingButton = ({ children, ...rest }) => (
  <motion.div
    whileHover={{ scale: 2, cursor: 'pointer' }}
    style={{ width: 'auto', height: '100%', minHeight: '1px' }}
    {...rest}
    whileTap={{ scale: 1, cursor: 'pointer' }}
  >
    {children}
  </motion.div>
);
