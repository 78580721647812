import { saveAs } from 'file-saver';

export const createExcelFile = (wbout, eventTitle, setCreatingFile, updateModalLoaderMessage) => {
  if (updateModalLoaderMessage) updateModalLoaderMessage();
  const s2ab = s => {
    const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
    const view = new Uint8Array(buf); // create uint8array as viewer
    // eslint-disable-next-line no-bitwise
    for (let i = 0; i < s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff; // convert to octet
    return buf;
  };

  saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${eventTitle}.xlsx`);
  if (setCreatingFile) setCreatingFile(false);
};
