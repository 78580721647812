export const validateBookingFields = lead => {
  // TODO: #547 Validate that all paymentSchedule dates are in the future
  const { users, room, layout, title, total, staff, eventType, start, minimumGuests } = lead;
  const fieldsToValidate = {
    users,
    room,
    layout,
    title,
    eventType,
    start,
    minimumGuests
  };

  const inValidFields = Object.keys(fieldsToValidate).filter(key => !fieldsToValidate[key]);
  return inValidFields;
};
