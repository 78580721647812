import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

import chroma from 'chroma-js';
import { Button, MenuButton, Progress, Accordion, Text } from 'src/v2/theme/variants';
import { doGetVenueValues } from './operations';
import GlobalStyles from '../../styles/GlobalStyles';
import { getQueryParams } from '../../utils/Helpers';
import Loader from '../../components/Loader';
import Colors from '../../styles/Colors';

const generatePrimaryColorRange = color => {
  const rangeColors = chroma.scale(['white', color]).colors(10);
  return rangeColors.reduce((a, v, i) => ({ ...a, [i === 0 ? 50 : i * 100]: v }), {});
};

const DynamicValuesContainer = props => {
  // user colors
  const { colors } = props && props.data;

  const primaryRanges = (colors?.primary && generatePrimaryColorRange(colors.primary)) || {};

  const theme = extendTheme({
    styles: {
      global: {
        'html, body': {
          color: Colors.black
        }
      }
    },
    fonts: { body: 'Lato' },
    colors: {
      ...Colors,
      primary: {
        ...primaryRanges
      },
      selectedOption: { 500: Colors.grayED }
    },
    shadows: {
      outline: 'none'
    },
    components: {
      Skeleton: {
        baseStyle: {
          animation: '2s linear infinite alternate animation-snm94m',
          opacity: 0.6
        }
      },
      Input: {
        variants: {
          outline: {
            field: {
              borderColor: 'gray.300',
              _placeholder: {
                fontSize: 'sm'
              }
            }
          }
        }
      },
      Menu: {
        variants: {
          checkmark: {
            parts: ['groupTitle', 'list'],
            groupTitle: {
              paddingLeft: '21px'
            }
          }
        }
      },
      Drawer: {
        variants: {
          eventDetails: {
            parts: ['dialog, dialogContainer'],
            dialog: {
              pointerEvents: 'auto',
              marginLeft: '100px',
              position: 'absolute !important'
            },
            dialogContainer: {
              pointerEvents: 'none',
              marginLeft: '100px',
              position: 'absolute !important',
              width: 'calc(100% - 100px)',
              zIndex: 2
            }
          },
          darkOverlay: {
            parts: ['dialogContainer'],
            dialogContainer: {
              backgroundColor: 'rgba(0, 0, 0, 0.55)'
            },
            dialog: {
              width: '600px !important',
              maxWidth: '600px'
            }
          },
          darkOverlayWide: {
            parts: ['dialogContainer'],
            dialogContainer: {
              backgroundColor: 'rgba(0, 0, 0, 0.55)'
            },
            dialog: {
              width: '600px !important',
              maxWidth: '600px'
            }
          },
          vnxSmall: {
            parts: ['dialogContainer'],
            dialogContainer: {
              backgroundColor: 'rgba(0, 0, 0, 0.55)'
            },
            dialog: {
              width: '380px !important',
              maxWidth: '380px'
            }
          },
          formDrawer: {
            parts: ['dialog'],
            dialog: {
              width: '100px'
            },
            dialogContainer: {
              width: '100px'
            }
          }
        }
      },
      Table: {
        baseStyle: {
          tbody: {
            tr: {
              '&:last-of-type': {
                td: { borderBottomWidth: 0 }
              }
            }
          }
        }
      },
      Text,
      Button,
      MenuButton,
      Progress,
      Accordion
    }
  });

  const setVenueEvent = params => {
    localStorage.removeItem('venueId');
    localStorage.removeItem('eventId');

    if (params.venueId) {
      localStorage.setItem('venueId', params.venueId);
    }

    if (params.eventId) {
      localStorage.setItem('eventId', params.eventId);
    }

    props.doGetVenueValues({ venueId: params.venueId });
  };

  useEffect(() => {
    setVenueEvent(getQueryParams(window.location.search));
  }, []);

  return props.fetching ? (
    <Loader />
  ) : (
    <React.Fragment>
      <GlobalStyles styles={props.data} />
      <ChakraProvider theme={theme}>{props.children}</ChakraProvider>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  fetching: state.venueValuesReducer.fetching,
  error: state.venueValuesReducer.error,
  data: state.venueValuesReducer.venue
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doGetVenueValues
    },
    dispatch
  );

DynamicValuesContainer.propTypes = {
  fetching: PropTypes.bool,
  data: PropTypes.object,
  children: PropTypes.object,
  doGetVenueValues: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicValuesContainer);
