export const VENUES_LIST_RESET = 'VENUES_LIST_RESET';
export const VENUES_LIST_REQUEST = 'VENUES_LIST_REQUEST';
export const VENUES_LIST_FAILURE = 'VENUES_LIST_FAILURE';
export const VENUES_LIST_SUCCESS = 'VENUES_LIST_SUCCESS';
export const VENUES_LOADMORE_REQUEST = 'VENUES_LOADMORE_REQUEST';
export const VENUES_LOADMORE_FAILURE = 'VENUES_LOADMORE_FAILURE';
export const VENUES_LOADMORE_SUCCESS = 'VENUES_LOADMORE_SUCCESS';
export const VENUES_CREATE = 'VENUES_CREATE';
export const VENUE_READ_RESET = 'VENUE_READ_RESET';
export const VENUE_READ_REQUEST = 'VENUE_READ_REQUEST';
export const VENUE_READ_FAILURE = 'VENUE_READ_FAILURE';
export const VENUE_READ_SUCCESS = 'VENUE_READ_SUCCESS';
export const VENUES_UPDATE = 'VENUES_UPDATE';
export const VENUES_DELETE = 'VENUES_DELETE';
export const SET_TUTORIAL = 'SET_TUTORIAL';
export const UPDATE_VENUE_REQUEST = 'UPDATE_VENUE_REQUEST';
export const UPDATE_VENUE_FAILED = 'UPDATE_VENUE_FAILED';
export const UPDATE_VENUE_SUCCESS = 'UPDATE_VENUE_SUCCESS';
export const GET_HOST_DETAILS_REQUEST = 'GET_HOST_DETAILS_REQUEST';
export const GET_HOST_DETAILS_FAILED = 'GET_HOST_DETAILS_FAILED';
export const GET_HOST_DETAILS_SUCCESS = 'GET_HOST_DETAILS_SUCCESS';

export const EDIT_VENUE_REQUEST = 'EDIT_VENUE_REQUEST';
export const EDIT_VENUE_SUCCESS = 'EDIT_VENUE_SUCCESS';
export const EDIT_VENUE_FAILED = 'EDIT_VENUE_FAILED';

export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';

export const CREATE_PRODUCT_CATEGORY_REQUEST = 'CREATE_PRODUCT_CATEGORY_REQUEST';
export const CREATE_PRODUCT_CATEGORY_SUCCESS = 'CREATE_PRODUCT_CATEGORY_SUCCESS';
export const CREATE_PRODUCT_CATEGORY_FAILURE = 'CREATE_PRODUCT_CATEGORY_FAILURE';

export const CREATE_RATE_TYPE_REQUEST = 'CREATE_RATE_TYPE_REQUEST';
export const CREATE_RATE_TYPE_SUCCESS = 'CREATE_RATE_TYPE_SUCCESS';
export const CREATE_RATE_TYPE_FAILURE = 'CREATE_RATE_TYPE_FAILURE';

export const CREATE_TAX_RATE_REQUEST = 'CREATE_TAX_RATE_REQUEST';
export const CREATE_TAX_RATE_SUCCESS = 'CREATE_TAX_RATE_SUCCESS';
export const CREATE_TAX_RATE_FAILURE = 'CREATE_TAX_RATE_FAILURE';
