import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

// NOTE roomId is optional here, will get all layouts or all from room
export const getLayoutsAction = ({ token, venueId }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/layouts/venue/${venueId}`,
    headers: { Authorization: token }
  });
};

export const addLayoutAction = ({ token, layout }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/layouts`,
    headers: { Authorization: token },
    data: layout
  });
};

export const updateLayoutAction = ({ token, layoutId, layout }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/layouts/${layoutId}`,
    headers: { Authorization: token },
    data: layout
  });
};

export function uploadLayoutAction({ token, formData }) {
  const data = new FormData();
  data.append('file', formData);
  return axios({
    method: 'post',
    url: `${apiUri}/layouts/upload`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: token
    }
  });
}

export const deleteLayoutAction = ({ token, layoutId }) => {
  return axios({
    method: 'delete',
    url: `${apiUri}/layouts/${layoutId}`,
    headers: { Authorization: token }
  });
};

export const getLayout = ({ token, layoutId }) => {
  const url = `${apiUri}/layouts/${layoutId}`;
  return axios({
    method: 'get',
    url,
    headers: { Authorization: token }
  });
};

export const duplicateLayoutApi = ({ token, layoutId }) => {
  const url = `${apiUri}/layouts/duplicate/${layoutId}`;
  return axios({
    method: 'get',
    url,
    headers: { Authorization: token }
  });
};
