import styled from 'styled-components';
import { BaseCardProps } from 'src/Types/GlobalTypes';
import { Card } from '../CardComponent';
import { CardHeader } from '../CardHeader';
import { CardBody } from '../CardBody';
import { CardFooter } from '../CardFooter';

export const CardWrapper = styled(Card).attrs({
  height: '100%',
  minHeight: '1px',
  overflowy: 'scroll',
  minWidth: '1px'
})`
  height: 100%;
  overflowy: scroll;
  min-height: 1px;
  min-width: 1px;
  @media (max-width: 1440px) {
    height: 100%;
  }
`;

export const BaseCard: React.FunctionComponent<BaseCardProps> = ({
  children,
  titleIcon,
  headerBtnAction,
  headerBtnIcon,
  headerBtnLabel,
  btnIsDisabled,
  title,
  cardFooter: ListCardFooter
}) => {
  return (
    <CardWrapper>
      <CardHeader
        iconTitle={titleIcon}
        title={title}
        btnLabel={headerBtnLabel}
        btnAction={headerBtnAction}
        btnIcon={headerBtnIcon}
        btnIsDisabled={btnIsDisabled}
        rightActions={undefined}
      />
      <CardBody>{children}</CardBody>
      {ListCardFooter && (
        <CardFooter>
          <ListCardFooter />
        </CardFooter>
      )}
    </CardWrapper>
  );
};
