import styled from 'styled-components';
import Colors from '../../styles/Colors';
import HexToRGB from '../../styles/HexToRGB';

const TableStyles = styled.div`
  background-color: ${props => props.bgColor || Colors.white};
  border-radius: 2px;
  box-shadow: 0 0 4px 0 rgba(${HexToRGB(Colors.black, true)}, 0.1);
  border: solid 1px ${Colors.grayFA};
  width: calc(100%);
  position: relative;
  overflow: auto;
  height: -webkit-fill-available;
}
`;

TableStyles.Header = styled.div`
  position: sticky;
  top: 0;
  background-color: #ffffff;
  > div {
    > div {
      color: ${props => props.color || Colors.gray88};
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
`;

TableStyles.HeaderRow = styled.div`
display: flex;
justify-content: ${props => (props.selected ? 'flex-start' : 'space-between')}
align-items: center;
width: calc(100% - 100px);
height: ${props => props.height || '65px'};
padding: 0 50px;
border-bottom: 1px solid ${props => props.borderColor || Colors.grayEE};

transition: all 250ms ease-in-out 0s;
  > div {
    &:nth-child(8) {
      svg {
        transition: all 250ms ease-in-out 0s;
        opacity: 0.3;

        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }
    }
  }
}`;

TableStyles.Body = styled.div``;

TableStyles.Footer = styled.div`
  padding: 10px;
  > button {
    width: 100%;
  }
`;

TableStyles.Row = styled.div`
   display: flex;
  justify-content: ${props => (props.selected ? 'flex-start' : 'space-between')}
  width: calc(100% - 100px);
  height: ${props => props.height || '65px'};
  padding: 0 50px;
  border-bottom: 1px solid ${props => props.borderColor || Colors.grayEE};
  transition: all 250ms ease-in-out 0s;
  &:hover {
    background-color: ${props => props.bgColorHover || Colors.grayFA};
    /*
    > div {
      &:nth-child(8) {
        svg {
          transition: all 250ms ease-in-out 0s;
          opacity: 0.3;
          &:hover {
            cursor: pointer;
            opacity: 1;
          }
        }
      }
    }
    */
  }
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: ${props => props.height || '70px'};
  }
`;

TableStyles.Col = styled.div`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.28px;
  color: #222222;
  width: calc(100% / ${props => props.size || 1});
  > svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

export default TableStyles;
