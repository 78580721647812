export const GET_CUSTOM_OBJECTS_REQUEST = 'GET_CUSTOM_OBJECTS_REQUEST';
export const GET_CUSTOM_OBJECTS_SUCCESS = 'GET_CUSTOM_OBJECTS_SUCCESS';
export const GET_CUSTOM_OBJECTS_FAILURE = 'GET_CUSTOM_OBJECTS_FAILURE';

export const ADD_CUSTOM_OBJECTS_REQUEST = 'ADD_CUSTOM_OBJECTS_REQUEST';
export const ADD_CUSTOM_OBJECTS_SUCCESS = 'ADD_CUSTOM_OBJECTS_SUCCESS';
export const ADD_CUSTOM_OBJECTS_FAILURE = 'ADD_CUSTOM_OBJECTS_FAILURE';

export const UPDATE_CUSTOM_OBJECTS_REQUEST = 'UPDATE_CUSTOM_OBJECTS_REQUEST';
export const UPDATE_CUSTOM_OBJECTS_SUCCESS = 'UPDATE_CUSTOM_OBJECTS_SUCCESS';
export const UPDATE_CUSTOM_OBJECTS_FAILURE = 'UPDATE_CUSTOM_OBJECTS_FAILURE';

export const DELETE_CUSTOM_OBJECTS_REQUEST = 'DELETE_CUSTOM_OBJECTS_REQUEST';
export const DELETE_CUSTOM_OBJECTS_SUCCESS = 'DELETE_CUSTOM_OBJECTS_SUCCESS';
export const DELETE_CUSTOM_OBJECTS_FAILURE = 'DELETE_CUSTOM_OBJECTS_FAILURE';

export const UPDATE_CUSTOM_OBJECTS_CATEGORY_REQUEST = 'UPDATE_CUSTOM_OBJECTS_CATEGORY_REQUEST';
export const UPDATE_CUSTOM_OBJECTS_CATEGORY_SUCCESS = 'UPDATE_CUSTOM_OBJECTS_CATEGORY_SUCCESS';

export const RESET_ALERTS = 'RESET_ALERTS';
