import React, { FC } from 'react';
import { FormLabel as ChakraLabel } from '@chakra-ui/react';
import Colors from 'src/styles/Colors';
import { Props } from './types';

const labelStyles = {
  fontSize: 'sm',
  marginBottom: '5px',
  minWidth: 0
};

export const FormLabel: FC<Props> = ({ label, className, labelIcon, children, ...props }) => {
  return (
    <ChakraLabel
      whiteSpace={'nowrap'}
      {...labelStyles}
      className={className}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      {...props}
    >
      {label}
      {children}
      {labelIcon}
    </ChakraLabel>
  );
};
