import { useSelector } from 'react-redux';
import {
  doSetCompletedFilter,
  doSetSortField,
  doSetSortOrder,
  updateChecklistItemRequest,
  createChecklistItemRequest,
  updateHostChecklistItemRequest,
  openChecklistModal,
  closeChecklistModal
} from 'src/containers/ChecklistContainer/operations';
import { useActions } from 'src/hooks/useActions';
import { getFilteredChecklistItems } from 'src/selectors';
import { RootStates, UseCheckListHooksReturn } from 'src/Types';

export const useChecklist = (): UseCheckListHooksReturn => {
  const { event } = useSelector((state: RootStates) => state.eventsReducer);
  const filteredChecklistItems = useSelector(state => getFilteredChecklistItems(state));
  const { checklistItems = {} } = event || {};

  const checklistReducer = useSelector((state: RootStates) => state.checklistReducer);

  const actions = useActions({
    updateChecklistItem: updateChecklistItemRequest,
    setCompleted: doSetCompletedFilter,
    setSortField: doSetSortField,
    setSortOrder: doSetSortOrder,
    createChecklistItemRequest,
    updateHostChecklistItemRequest,
    openChecklistModal,
    closeChecklistModal
  });

  return {
    ...checklistReducer,
    checklistItems,
    filteredChecklistItems,
    ...actions
  };
};
