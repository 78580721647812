import {
  FETCH_GUESTS_REQUEST,
  FETCH_GUESTS_SUCCESS,
  FETCH_GUESTS_FAILURE,
  CHANGE_USER_TABLE_REQUEST,
  CHANGE_USER_TABLE_SUCCESS,
  CHANGE_USER_TABLE_FAILURE,
  CHANGE_GROUP_TABLE_SUCCESS,
  GET_LAYOUT_REQUEST,
  GET_LAYOUT_SUCCESS,
  GET_LAYOUT_FAILURE,
  SET_SEARCH_TERM,
  CHANGE_LAYOUT_NAME_REQUEST,
  CHANGE_LAYOUT_NAME_SUCCESS,
  CHANGE_LAYOUT_NAME_FAILURE,
  RESET_ALL_TABLES_REQUEST,
  RESET_ALL_TABLES_SUCCESS,
  RESET_ALL_TABLES_FAILURE,
  FILTER_GUESTS_REQUEST,
  FILTER_GUESTS_SUCCESS,
  FILTER_GUESTS_FAILURE,
  SUBMIT_SEATING_CHART_REQUEST,
  SUBMIT_SEATING_CHART_FAILURE,
  SUBMIT_SEATING_CHART_SUCCESS,
  ALERT_RESET,
  NEW_SEATING_CHART_REQUEST,
  NEW_SEATING_CHART_FAILURE,
  NEW_SEATING_CHART_SUCCESS,
  UPDATE_LAYOUT_REQUEST,
  UPDATE_LAYOUT_SUCCESS,
  UPDATE_LAYOUT_FAILURE,
  SELECT_TABLE,
  CLEAR_SELECTED_TABLE
} from './types';

import { GET_EVENT_SUCCESS } from '../EventsContainer/types';

export const fetchGuestsRequest = ({ token }) => ({
  type: FETCH_GUESTS_REQUEST,
  token
});

export const fetchGuestsSuccess = ({ guests }) => ({
  type: FETCH_GUESTS_SUCCESS,
  guests
});

export const fetchGuestsFailure = ({ error }) => ({
  type: FETCH_GUESTS_FAILURE,
  error
});

export const filterGuestRequest = ({ token, venueId, eventId, rsvp, table }) => ({
  type: FILTER_GUESTS_REQUEST,
  token,
  venueId,
  eventId,
  rsvp,
  table
});

export const filterGuestsSuccess = ({ guests, filterSuccess }) => ({
  type: FILTER_GUESTS_SUCCESS,
  guests,
  filterSuccess
});

export const filterGuestsFailure = ({ error }) => ({
  type: FILTER_GUESTS_FAILURE,
  error
});

export const getLayoutRequest = ({ token, layoutId }) => ({
  type: GET_LAYOUT_REQUEST,
  token,
  layoutId
});

export const getLayoutSuccess = ({ layout }) => ({
  type: GET_LAYOUT_SUCCESS,
  layout
});

export const getLayoutError = ({ error }) => ({
  type: GET_LAYOUT_FAILURE,
  error
});

export const changeUserTableRequest = ({ token, userId, table, group }) => ({
  type: CHANGE_USER_TABLE_REQUEST,
  token,
  userId,
  table,
  group
});

export const changeUserTableSuccess = ({ user }) => ({
  type: CHANGE_USER_TABLE_SUCCESS,
  user
});

export const changeUserTableFailure = ({ error }) => ({
  type: CHANGE_USER_TABLE_FAILURE,
  error
});

export const updateLayoutNameRequest = ({ token, eventId, table }) => ({
  type: CHANGE_LAYOUT_NAME_REQUEST,
  eventId,
  token,
  table
});

export const updateLayoutNameSuccess = event => ({
  type: GET_EVENT_SUCCESS,
  event
});

export const updateLayoutNameFailure = ({ error }) => ({
  type: CHANGE_LAYOUT_NAME_FAILURE,
  error
});

export const changeGroupTableSuccess = ({ group, table }) => ({
  type: CHANGE_GROUP_TABLE_SUCCESS,
  group,
  table
});

export const resetAllTablesRequest = ({ token, eventId, venueId }) => ({
  type: RESET_ALL_TABLES_REQUEST,
  token,
  eventId,
  venueId
});

export const resetAllTablesSuccess = ({ success }) => ({
  type: RESET_ALL_TABLES_SUCCESS,
  success
});

export const resetAllTablesFailure = ({ error }) => ({
  type: RESET_ALL_TABLES_FAILURE,
  error
});

export const setSearchTerm = ({ searchTerm }) => ({
  type: SET_SEARCH_TERM,
  searchTerm
});

export const submitChartRequest = ({ token, chartData }) => ({
  type: SUBMIT_SEATING_CHART_REQUEST,
  chartData,
  token
});

export const submitChartFailure = ({ seatingChartError }) => ({
  type: SUBMIT_SEATING_CHART_FAILURE,
  seatingChartError
});

export const submitChartSuccess = ({ seatingChartData }) => ({
  type: SUBMIT_SEATING_CHART_SUCCESS,
  seatingChartData
});

export const submitNewChartRequest = ({ token, chartData }) => ({
  type: NEW_SEATING_CHART_REQUEST,
  chartData,
  token
});

export const submitNewChartFailure = ({ newSeatingChartError }) => ({
  type: NEW_SEATING_CHART_FAILURE,
  newSeatingChartError
});

export const submitNewChartSuccess = ({ newSeatingChartData }) => ({
  type: NEW_SEATING_CHART_SUCCESS,
  newSeatingChartData
});

export const resetAlert = ({ alert }) => ({
  type: ALERT_RESET,
  alert
});

export const updateLayoutRequest = ({ token, eventId, layoutId }) => ({
  type: UPDATE_LAYOUT_REQUEST,
  eventId,
  token,
  layoutId
});

export const updateLayoutSuccess = ({ token, layoutId }) => ({
  type: GET_LAYOUT_REQUEST,
  layoutId,
  token
});

export const updateLayoutFailure = () => ({
  type: UPDATE_LAYOUT_FAILURE
});

export const selectTable = ({ table }) => ({
  type: SELECT_TABLE,
  table
});

export const clearSelectedTable = () => ({
  type: CLEAR_SELECTED_TABLE
});
