import {
  OPEN_EDIT_GUEST_MODAL,
  CLOSE_EDIT_GUEST_MODAL,
  OPEN_DELETE_GUEST_MODAL,
  CLOSE_DELETE_GUEST_MODAL,
  OPEN_ADD_GROUP_MODAL,
  CLOSE_ADD_GROUP_MODAL,
  GET_GROUPS_REQUEST,
  ADD_GROUP_REQUEST,
  OPEN_ADD_GUEST_MODAL,
  CLOSE_ADD_GUEST_MODAL,
  RESET_EDITED_GUEST,
  RESET_ADDDED_GROUP,
  BATCH_EDIT_GUESTS_REQUEST,
  OPEN_DELETE_MULTIPLE_GUESTS_MODAL,
  CLOSE_DELETE_MULTIPLE_GUESTS_MODAL,
  BATCH_DELETE_GUESTS_REQUEST,
  RESET_DELETED_GUEST,
  GET_EXPORT_DATA_REQUEST,
  RESET_EXPORT_DATA,
  OPEN_EDIT_GROUP_MODAL,
  CLOSE_EDIT_GROUP_MODAL,
  OPEN_DELETE_GROUP_MODAL,
  CLOSE_DELETE_GROUP_MODAL,
  SET_SELECTED_GROUP,
  EDIT_GROUP_REQUEST,
  DELETE_GROUP_REQUEST,
  RESET_EDITED_GROUP,
  RESET_DELETED_GROUP,
  SET_SEARCH_TERM,
  SET_SORT_FIELD,
  SET_SORT_VALUE,
  SET_RSVP_FILTER,
  SET_MEALOPTIONS_FILTER,
  SET_TABLE_FILTER,
  RESET_EDITED_GUESTS,
  RESET_DELETED_GUESTS,
  SET_GROUP_FILTER,
  HOST_UPDATE_EVENT_REQUEST,
  HOST_UPDATE_EVENT_FAILURE,
  HOST_UPDATE_EVENT_SUCCESS,
  HOST_SEND_EMAIL_REQUEST,
  HOST_SEND_EMAIL_SUCCESS,
  HOST_SEND_EMAIL_FAILURE,
  HOST_SEND_BATCH_EMAIL_REQUEST,
  HOST_SEND_BATCH_EMAIL_FAILURE,
  HOST_SEND_BATCH_EMAIL_SUCCESS,
  OPEN_SEND_BATCH_EMAIL_MODAL,
  CLOSE_SEND_BATCH_EMAIL_MODAL,
  GET_LAYOUT_REQUEST,
  GET_LAYOUT_SUCCESS,
  GET_LAYOUT_FAILURE
} from './types';

export const openEditGuestModal = () => ({
  type: OPEN_EDIT_GUEST_MODAL
});

export const closeEditGuestModal = () => ({
  type: CLOSE_EDIT_GUEST_MODAL
});

export const openDeleteGuestModal = () => ({
  type: OPEN_DELETE_GUEST_MODAL
});

export const closeDeleteGuestModal = () => ({
  type: CLOSE_DELETE_GUEST_MODAL
});

export const openAddGroupModal = () => ({
  type: OPEN_ADD_GROUP_MODAL
});

export const closeAddGroupModal = () => ({
  type: CLOSE_ADD_GROUP_MODAL
});

export const doGetGroups = ({ token, eventId }) => ({
  type: GET_GROUPS_REQUEST,
  token,
  eventId
});

export const doAddGroup = ({ token, data }) => ({
  type: ADD_GROUP_REQUEST,
  token,
  data
});

export const openAddGuestModal = () => ({
  type: OPEN_ADD_GUEST_MODAL
});

export const closeAddGuestModal = () => ({
  type: CLOSE_ADD_GUEST_MODAL
});

export const resetEditedGuest = () => ({
  type: RESET_EDITED_GUEST
});

export const resetAddedGroup = () => ({
  type: RESET_ADDDED_GROUP
});

export const batchEdit = ({ token, guests, data }) => ({
  type: BATCH_EDIT_GUESTS_REQUEST,
  token,
  guests,
  data
});

export const openDeleteMultipleGuestModal = () => ({
  type: OPEN_DELETE_MULTIPLE_GUESTS_MODAL
});

export const closeDeleteMultipleGuestModal = () => ({
  type: CLOSE_DELETE_MULTIPLE_GUESTS_MODAL
});

export const batchDelete = ({ token, guests, eventId }) => ({
  type: BATCH_DELETE_GUESTS_REQUEST,
  token,
  guests,
  eventId
});

export const resetDeletedGuest = () => ({
  type: RESET_DELETED_GUEST
});

export const doGetExportData = ({ token, eventId, venueId }) => ({
  type: GET_EXPORT_DATA_REQUEST,
  token,
  eventId,
  venueId
});

export const resetExportData = () => ({
  type: RESET_EXPORT_DATA
});

export const openEditGroupModal = () => ({
  type: OPEN_EDIT_GROUP_MODAL
});

export const closeEditGroupModal = () => ({
  type: CLOSE_EDIT_GROUP_MODAL
});

export const openDeleteGroupModal = () => ({
  type: OPEN_DELETE_GROUP_MODAL
});

export const closeDeleteGroupModal = () => ({
  type: CLOSE_DELETE_GROUP_MODAL
});

export const setSelectedGroup = group => ({
  type: SET_SELECTED_GROUP,
  group
});

export const doEditGroup = ({ token, groupId, data, eventId }) => ({
  type: EDIT_GROUP_REQUEST,
  token,
  groupId,
  data,
  eventId
});

export const doDeleteGroup = ({ token, groupId, eventId }) => ({
  type: DELETE_GROUP_REQUEST,
  token,
  groupId,
  eventId
});

export const resetEditedGroup = () => ({
  type: RESET_EDITED_GROUP
});

export const resetDeletedGroup = () => ({
  type: RESET_DELETED_GROUP
});

export const setSearchTerm = ({ searchTerm }) => ({
  type: SET_SEARCH_TERM,
  searchTerm
});

export const setSortField = ({ sortField }) => ({
  type: SET_SORT_FIELD,
  sortField
});

export const setSortValue = ({ sortValue }) => ({
  type: SET_SORT_VALUE,
  sortValue
});

export const setRsvpFilter = ({ rsvpFilter }) => ({
  type: SET_RSVP_FILTER,
  rsvpFilter
});

export const setMealOptionsFilter = ({ filterMeals }) => ({
  type: SET_MEALOPTIONS_FILTER,
  filterMeals
});

export const setTableFilter = ({ tableFilter }) => ({
  type: SET_TABLE_FILTER,
  tableFilter
});

export const setGroupFilter = ({ groupFilter }) => ({
  type: SET_GROUP_FILTER,
  groupFilter
});

export const resetEditedGuests = () => ({
  type: RESET_EDITED_GUESTS
});

export const resetDeletedGuests = () => ({
  type: RESET_DELETED_GUESTS
});

export const updateEventAndHostSendEmailToGuests = ({
  id,
  sendEmail,
  event,
  token,
  appEmailSent,
  guestId
}) => ({
  type: HOST_SEND_EMAIL_REQUEST,
  id,
  sendEmail,
  event,
  token,
  appEmailSent,
  guestId
});

export const hostSendEmailSuccess = ({ success }) => ({
  type: HOST_SEND_EMAIL_SUCCESS,
  hostSendEmailSuccess: success
});

export const hostSendEmailFailed = ({ error }) => ({
  type: HOST_SEND_EMAIL_FAILURE,
  hostSendEmailFailed: error
});

export const hostUpdateEvent = ({ id, event, token }) => ({
  type: HOST_UPDATE_EVENT_REQUEST,
  id,
  event,
  token
});

export const hostUpdateEventSuccess = ({ success }) => ({
  type: HOST_UPDATE_EVENT_SUCCESS,
  hostUpdateSuccess: success
});

export const hostUpdateEventFailure = ({ error }) => ({
  type: HOST_UPDATE_EVENT_FAILURE,
  hostUpdateEventFailed: error
});

export const sendBatchEmail = ({ eventId, guestIds, token, subject, message }) => ({
  type: HOST_SEND_BATCH_EMAIL_REQUEST,
  eventId,
  guestIds,
  token,
  subject,
  message
});

export const sendBatchEmailSuccess = () => ({
  type: HOST_SEND_BATCH_EMAIL_SUCCESS
});

export const sendBatchEmailFailed = () => ({
  type: HOST_SEND_BATCH_EMAIL_FAILURE
});

export const openSendBatchEmailModal = () => ({
  type: OPEN_SEND_BATCH_EMAIL_MODAL
});

export const closeSendBatchEmailModal = () => ({
  type: CLOSE_SEND_BATCH_EMAIL_MODAL
});

export const getLayoutRequest = ({ token, layoutId }) => ({
  type: GET_LAYOUT_REQUEST,
  token,
  layoutId
});

export const getLayoutSuccess = ({ layout }) => ({
  type: GET_LAYOUT_SUCCESS,
  layout
});

export const getLayoutError = ({ error }) => ({
  type: GET_LAYOUT_FAILURE,
  error
});
