import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { Button } from 'src/v2/UIComponents/Primitives';
import Colors from 'src/styles/Colors';

export const FieldBoxHeader = ({ title, subtitle, buttonAction }) => {
  const handlerEdit = e => {
    e.stopPropagation();
    buttonAction();
  };

  return (
    <HStack fontSize="md" fontWeight="semibold" justifyContent="space-between" width="100%">
      <Box color="primary.600" width="100%">
        <HStack justifyContent="space-between">
          <Box
            fontSize="20px"
            fontFamily="Lato"
            fontWeight={700}
            lineHeight="26px"
            color={Colors.black}
            // textTransform="capitalize"
          >
            {title}
          </Box>
          {subtitle && (
            <Box
              fontSize="14px"
              letterSpacing="wide"
              color={Colors.gray7D}
              fontWeight={'500'}
              fontStyle={'italic'}
            >
              {subtitle}
            </Box>
          )}
        </HStack>
      </Box>
      {buttonAction && (
        <Button
          variant="link"
          color="var(--colors-primary)"
          fontFamily="Lato"
          fontStyle="normal"
          fontSize="14px"
          lineHeight="18px"
          fontWeight={700}
          onClick={handlerEdit}
          disableAnimation
          _hover={{ textDecoration: 'none' }}
        >
          Edit
        </Button>
      )}
    </HStack>
  );
};
