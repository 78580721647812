import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

// eslint-disable-next-line import/prefer-default-export
export function getStaffDetails({ token, venueId, staffId }) {
  return axios({
    method: 'get',
    url: `${apiUri}/venues/${venueId}/users/${staffId}`,
    headers: { Authorization: token }
  });
}
