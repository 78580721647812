import { Grid, GridItem } from '@chakra-ui/react';
import { Button } from 'src/v2/UIComponents/Primitives';
import { AddPlus } from 'src/v2/UIComponents/Primitives/Icons';
import { useLeads, useVenue } from 'src/v2/Hooks/CollectionHooks';
import { DateRangeFilter, Filter } from 'src/v2/UIComponents/Toolbar';
import { useDrawers } from 'src/v2/Hooks/HelperHooks';
import { useTableContext } from 'src/v2/context/Tables';

export const FilterBar = ({ onAddLeadClick, disableButtons }) => {
  const { eventTypeOptions, staffUsers } = useVenue();
  const { leadStatusOptions, leadSources } = useLeads();
  const { openEventManagementDrawer } = useDrawers();
  const { removeFilters, filters } = useTableContext();

  const staffOptions = staffUsers?.map(staff => ({
    value: staff.id,
    label: `${staff.firstname} ${staff.lastname}`
  }));

  const staffList = staffOptions && [...staffOptions, { value: 'isEmpty', label: 'Unassigned' }];

  const eventTypeList =
    (eventTypeOptions && [...eventTypeOptions, { value: 'isEmpty', label: 'No Event Type' }]) || [];

  const statusList = leadStatusOptions
    ?.sort((a, b) => a.order - b.order)
    .map(item => ({
      value: item.value,
      label: item.displayName
    }));

  const leadSourceList = leadSources?.map(source => ({
    value: source.value,
    label: source.label
  }));

  return (
    <Grid templateColumns="repeat(7, 1fr)" gap={6} mb="20px">
      <GridItem>
        <Filter
          title="Salesperson"
          dataKey="salesperson"
          filterList={staffList}
          width="100%"
          isDisabled={disableButtons}
          filterType={undefined}
        />
      </GridItem>
      <GridItem>
        <Filter
          title="Event Type"
          dataKey="eventType"
          filterList={eventTypeList}
          width="100%"
          isDisabled={disableButtons}
          filterType={undefined}
        />
      </GridItem>
      <GridItem>
        <Filter
          title="Lead Status"
          dataKey="status"
          filterList={statusList}
          width="100%"
          isDisabled={disableButtons}
          filterType={undefined}
        />
      </GridItem>
      <GridItem>
        <Filter
          title="Lead Source"
          dataKey="source"
          filterList={leadSourceList}
          width="100%"
          isDisabled={disableButtons}
          filterType={undefined}
        />
      </GridItem>
      <GridItem>
        <DateRangeFilter isDisabled={disableButtons} />
      </GridItem>
      {/* // TODO: #575 Leads Table: Make Export Leads Button Functional */}
      {/* <GridItem>
        <Button
          variant="filterDropdown"
          rightIcon={<Icon as={HiExternalLink} w={4} h={4} />}
          width="100%"
          paddingX="20px"
          isDisabled={disableButtons}
        >
          Export Leads
        </Button>
      </GridItem> */}
      <GridItem>
        <Button
          variant="outline"
          width="100%"
          paddingX="20px"
          onClick={() => removeFilters()}
          isDisabled={disableButtons || !filters?.length}
        >
          Clear Filters
        </Button>
      </GridItem>
      <GridItem>
        <Button
          variant="primary"
          leftIcon={<AddPlus color="white" w={4} h={4} />}
          width="100%"
          paddingX="20px"
          onClick={() => openEventManagementDrawer('lead', 'create')}
          isDisabled={disableButtons}
        >
          Add Lead
        </Button>
      </GridItem>
    </Grid>
  );
};
