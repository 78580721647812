import { useEvents, useLeads } from 'src/v2/Hooks/CollectionHooks';
import { useConfirm } from 'src/v2/Hooks/HelperHooks';
import { NotesForm } from 'src/v2/Drawers/Leads/NotesDrawer/NotesForm';
import { Wizard } from 'src/v2/UIComponents/Drawers/Wizard';

export const NotesDrawer = () => {
  const {
    isLeadsNotesFormOpen,
    closeLeadsNotesForm,
    addNoteRequest,
    editNote,
    note: noteToEdit
  } = useLeads();

  const { updateEvent, activeEvent: lead } = useEvents();

  const defaultValues = {
    note: noteToEdit?.note
  };

  const closeDrawer = () => {
    closeLeadsNotesForm();
  };

  const handleSave = data => {
    if (editNote && noteToEdit) {
      const { id: noteId } = noteToEdit;
      updateEvent({
        data: {
          advancedNotes: {
            ...data,
            id: noteId
          }
        },
        eventId: lead?.id,
        isLead: true,
        closeAction: closeDrawer
      });
    } else {
      const note = {
        ...data
      };
      addNoteRequest({ note, id: lead?.id });
    }
  };

  const { openConfirm } = useConfirm({ acceptAction: closeDrawer, confirmHookId: 'notesForm' });

  const requestClose = () => {
    openConfirm({
      description: 'All changes will be lost',
      confirmId: 'notesForm',
      title: ''
    });
  };

  const steps = [{ component: NotesForm, step: 0, title: 'Log Note About Event' }];

  return (
    <Wizard
      // disableNext={true}
      steps={steps}
      saveAction={handleSave}
      defaultValues={defaultValues}
      cancelAction={closeDrawer}
      isOpen={isLeadsNotesFormOpen}
      formId="notesDrawer"
    />
  );
};
