import axios from 'axios';
import config from 'src/.env.json';

const { apiUri } = config;

export const generateProposalAction = ({ proposalId, eventId, token }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/proposals/generateproposal/${eventId}/${eventId}`,
    headers: { Authorization: token },
    path: '/file',
    responseType: 'blob'
  });
};

export const generateContractAction = ({ contractId, eventId, token }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/contracts/generatecontract/${eventId}/${eventId}`,
    headers: { Authorization: token },
    path: '/file',
    responseType: 'blob'
  });
};
