import React from 'react';
import PropTypes from 'prop-types';
import InputRadioStyles from './styles';

const InputRadio = ({
  children,
  checked,
  bgColor,
  borderColor,
  action,
  loading,
  disabled = false,
  className
}) => (
  <InputRadioStyles
    checked={checked}
    bgColor={bgColor}
    borderColor={borderColor}
    disabled={disabled}
    loading={loading}
    onClick={action}
    className={className}
  >
    {children && children}
  </InputRadioStyles>
);

InputRadio.propTypes = {
  children: PropTypes.object,
  checked: PropTypes.bool,
  action: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  borderColor: PropTypes.string,
  bgColor: PropTypes.string,
  className: PropTypes.string
};

export default InputRadio;
