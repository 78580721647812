import React from 'react';
import {
  PROSPECT_EMAIL_LABEL,
  PROSPECT_EMAIL_PLACEHOLDER
} from 'src/v2/Drawers/LeadsFormDrawer/constants';
import { TextFieldWithLabel } from 'src/v2/UIComponents/FormComponents';

const RootProspectEmailInput = () => {
  const prospectEmailProps = {
    name: 'leadInfo.email',
    label: PROSPECT_EMAIL_LABEL,
    placeholder: PROSPECT_EMAIL_PLACEHOLDER
  };
  return <TextFieldWithLabel {...prospectEmailProps} />;
};

export const ProspectEmailInput = React.memo(RootProspectEmailInput);
