import { Icon } from '@chakra-ui/react';

export const Document = props => {
  const { width = 6, height = 6 } = props;

  return (
    <Icon
      width={width}
      height={height}
      viewBox="0 0 23 28"
      color="var(--colors-primary)"
      {...props}
    >
      <path
        d="M20.803 3.848H20.038V22.706C20.038 23.957 19.018 24.98 17.764 24.98H3.80205V25.679C3.80205 26.48 4.45305 27.128 5.25105 27.128H20.8001C21.601 27.128 22.2491 26.48 22.2491 25.679V5.294C22.2491 4.496 21.604 3.845 20.803 3.848ZM17.7611 24.152C18.562 24.152 19.2101 23.504 19.2101 22.703V2.324C19.2101 1.523 18.5591 0.875 17.7611 0.875H7.40205V1.355C7.40805 1.406 7.41105 1.457 7.41105 1.508V5.48C7.41105 6.605 6.49605 7.523 5.36805 7.523H1.39605C1.35405 7.523 1.31205 7.52 1.27005 7.517H0.766052V22.703C0.766052 23.504 1.41705 24.152 2.21505 24.152H17.7611ZM5.21205 10.643H15.3521C15.7301 10.643 16.0361 10.949 16.0361 11.327C16.0361 11.705 15.7301 12.011 15.3521 12.011H5.21205C4.83405 12.011 4.52805 11.705 4.52805 11.327C4.52805 10.949 4.83405 10.643 5.21205 10.643ZM5.21205 14.357H15.3521C15.7301 14.357 16.0361 14.663 16.0361 15.041C16.0361 15.419 15.7301 15.725 15.3521 15.725H5.21205C4.83405 15.725 4.52805 15.419 4.52805 15.041C4.52805 14.663 4.83405 14.357 5.21205 14.357ZM4.52505 18.539C4.52505 18.161 4.83105 17.855 5.20905 17.855H10.2791C10.6571 17.855 10.9631 18.161 10.9631 18.539C10.9631 18.917 10.6541 19.223 10.2761 19.223H5.20605C4.83405 19.223 4.52505 18.917 4.52505 18.539ZM1.39605 6.701H5.37705C6.04005 6.695 6.57705 6.158 6.58305 5.495V1.508C6.58305 1.142 6.28005 0.896 5.96805 0.896C5.81805 0.896 5.66505 0.95 5.53905 1.076L0.961052 5.657C0.577052 6.041 0.850052 6.701 1.39605 6.701Z"
        fill="currentColor"
      />
    </Icon>
  );
};
