import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export function checkCSV({ formData, eventId, venueId }) {
  const data = new FormData();
  data.append('file', formData);

  // data.set('file', formData);
  return axios({
    method: 'post',
    url: `${apiUri}/venues/${venueId}/events/${eventId}/users/import/check`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}
