import PROPOSAL_TYPES from './types';

const { GENERATE_PROPOSAL, GENERATE_CONTRACT } = PROPOSAL_TYPES;

export const generateProposalOperations = {
  request: ({ eventId, proposalId }) => ({
    type: GENERATE_PROPOSAL.REQUEST,
    eventId,
    proposalId
  }),
  success: data => ({
    type: GENERATE_PROPOSAL.SUCCESS,
    data
  }),
  failure: () => ({
    type: GENERATE_PROPOSAL.FAILURE
  })
};

export const generateContractOperations = {
  request: ({ eventId, proposalId }) => ({
    type: GENERATE_CONTRACT.REQUEST,
    eventId,
    proposalId
  }),
  success: data => ({
    type: GENERATE_CONTRACT.SUCCESS,
    data
  }),
  failure: () => ({
    type: GENERATE_CONTRACT.FAILURE
  })
};
