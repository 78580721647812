import styled from 'styled-components';
import { Flex } from '@chakra-ui/react';
import Colors from '../../styles/Colors';
import HexToRGB from '../../styles/HexToRGB';

const AppStyles = styled(Flex).attrs(props => ({
  flex: '1',
  flexDirection: 'row'
}))``;

AppStyles.AdminControls = styled.div`
  display: block;
  height: 50px;
  line-height: 50px;
  width: calc(100% - 20px);
  padding: 0 10px;
  background: ${Colors.beigeC0};
`;

AppStyles.AdminControl = styled.div`
  float: right;
  height: 50px;
  line-height: 50px;
  margin-left: 10px;

  > div {
    margin-top: 5px;
  }

  div,
  span {
    height: 40px;
    line-height: 40px;
  }

  span {
    padding: 0 10px;
    background-color: rgba(${HexToRGB(Colors.white, true)}, 0.5);
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }

  input {
    height: 40px;
    line-height: 40px;
    border: none;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }

  button {
    height: 40px;
    line-height: 40px;
  }
`;

AppStyles.Sidebar = styled.aside`
  min-width: ${props => (props.hideSidebar ? '100px' : '240px')};
  height: ${props => (props.isAdmin ? 'calc(100% - 50px)' : '100%')};
  position: fixed;
  top: ${props => (props.isAdmin ? '50px' : '0')};
  bottom: 0;
  left: 0;
  transition: all 300ms;
`;

// AppStyles.Main = styled.main`
//   width: -webkit-fill-available;
//   margin-left: ${props => (props.hideSidebar ? '100px' : '240px')};
//   position: static;
//   padding: 10px;
//   transition: all 250ms ease-in-out 0s;
//   background-color: ${props => (props.grayBackground ? Colors.grayFA : Colors.white)};
//   opacity: ${props => (props.show ? '1.0' : '0.0')};
//   min-height: calc(100vh - 20px);
// `;

AppStyles.Main = styled.main`
  flex: 1;
  width: 100%;
  padding: 10px;
  background-color: ${Colors.grayFA};
`;

AppStyles.MainInner = styled.div`
  height: calc(100vh - 20px);
  background-color: ${Colors.grayFA};
  min-height: inherit;
  width: calc(100% - 2px);
  border: 1px solid ${Colors.grayED};
  overflow: hidden;
`;

export default AppStyles;
