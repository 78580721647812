import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoginStyles from './styles';
import Input from '../../styles/Input';
import Button from '../../styles/Button';

const Login = ({
  welcomeMessage,
  logo,
  handleSubmit,
  handleChange,
  loading = false,
  email = '',
  password = '',
  venueId = '',
  errors = {}
}) => (
  <LoginStyles>
    <LoginStyles.Card>
      <LoginStyles.CardHeader logo={logo}>
        {/* {(logo && <img src={logo} alt="logo" />) || <h1>{name}</h1>} */}
      </LoginStyles.CardHeader>

      <LoginStyles.WelcomeMessage>{welcomeMessage}</LoginStyles.WelcomeMessage>
      <form onSubmit={handleSubmit}>
        <Input
          disabled={loading}
          type={'email'}
          onChange={handleChange}
          name={'email'}
          placeholder={'Email'}
          value={email}
          error={errors && errors.email}
        />
        <Input
          disabled={loading}
          type={'password'}
          onChange={handleChange}
          name={'password'}
          placeholder={'Password'}
          value={password}
          error={errors && errors.password}
        />
        <Input
          disabled={loading}
          type={localStorage.venueId ? 'hidden' : 'text'}
          onChange={handleChange}
          name={'venueId'}
          placeholder={'Venue ID'}
          value={venueId}
        />

        <LoginStyles.Error show={Object.keys(errors).length > 0}>
          {errors && Object.keys(errors).map((e, k) => <span key={k.toString()}>{errors[e]}</span>)}
        </LoginStyles.Error>

        <Button disabled={loading} type={'submit'} label="Login" loading={loading} />

        <Link to="/forgotpassword">Forgot Password?</Link>
      </form>
    </LoginStyles.Card>
  </LoginStyles>
);

Login.propTypes = {
  name: PropTypes.string,
  welcomeMessage: PropTypes.string,
  logo: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  email: PropTypes.string,
  password: PropTypes.string,
  venueId: PropTypes.string,
  errors: PropTypes.object
};

export default Login;
