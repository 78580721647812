/* eslint-disable react/display-name */
// LIBRARIES
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CalendarWizardDrawer } from 'src/v2/Drawers/CalendarWizardDrawer';
import { TableProvider } from 'src/v2/context/Tables';
import { ComposerModal } from 'src/v2/UIComponents/EmailComponents/Composer/ComposerModal';

// APP CONTAINER
import AppContainer from 'src/v2/UIComponents/AppContainer';

// STYLES
import './less/styles.scss';

// UI COMPONENTS
import { EventDetails } from 'src/v2/Drawers';
import { EventManagementDrawer } from 'src/v2/Drawers/EventManagementDrawer/EventManagementDrawer';
import { LeadsDetails as LeadsDetailsDrawer } from 'src/v2/Drawers/LeadsDetails';

// HOOKS
import {
  useEvents,
  useVenue,
  useCustomFields,
  useRooms,
  useDocumentLibrary,
  useLeads
} from '../../Hooks/CollectionHooks';
import { useLoader } from '../../Hooks/HelperHooks';

import { CalendarComponent } from './CalendarComponent';
import { FilterBar } from './CalendarToolbar/FilterBar';

moment.locale('en-US', {
  week: {
    dow: 1 // Monday is the first day of the week.
  }
});

const Calendar = () => {
  // STATES
  const [expanded, setExpanded] = useState(false);
  const [defaultWizardDate, setDefaultWizardDate] = useState(null);

  // REDUCERS

  const { eventsState, monthStart, monthEnd } = useEvents();
  const { tableLeads, getLeadsRequest } = useLeads();
  const { showGlassLoader } = useLoader();
  const { getCustomFieldsRequest } = useCustomFields();

  // DISPATCHES

  const { events = [], appointments } = eventsState;

  // const openEventModal = ({ eventId }) => dispatch(openEventModalRequest({ eventId, token }));
  const { getVenue, getStaff } = useVenue();
  const { getRooms } = useRooms();
  const { getLibraryDocuments } = useDocumentLibrary();

  // STATE

  const [calendarList, setCalendarList] = useState([]);

  // USE EFFECTS

  useEffect(() => {
    showGlassLoader();
    getVenue();
    getCustomFieldsRequest();
    getRooms();
    getStaff();
    getLeadsRequest();
    getLibraryDocuments();
  }, []);
  useEffect(() => {
    const fullList = [];
    if (events) fullList.push(...events.filter(evt => evt.type !== 'lead'));
    if (appointments) {
      fullList.push(...appointments);
    }

    const leadsWithDate = tableLeads?.filter(lead => lead.start || lead.preferredDate);
    const leadsList = leadsWithDate?.map(lead => {
      const leadObject = lead;
      if (!lead.title) {
        leadObject.title = lead.clientName;
      }
      if (lead.preferredDate && !lead.start) {
        leadObject.start = new Date(lead.preferredDate).toISOString();
        leadObject.end = new Date(lead.preferredDate + 1000000).toISOString();
      }
      return leadObject;
    });
    const thisMonthsLeads = leadsList?.filter(lead => {
      const isFuture = new Date(lead.start).getTime() >= new Date(monthStart);
      const isPast = new Date(lead.start).getTime() <= new Date(monthEnd);
      if (isFuture && isPast) return true;
      return false;
    });
    if (thisMonthsLeads) fullList.push(...thisMonthsLeads);

    const updatedList = fullList.map((evt, i) => {
      const staffArray = evt.staff || [];
      const attendeesSet = new Set(staffArray);
      evt.organizer && attendeesSet.add(evt.organizer);
      evt.consultant && attendeesSet.add(evt.consultant);
      evt.salesperson && attendeesSet.add(evt.salesperson);
      const attendees = [...attendeesSet];
      evt.attendees = attendees;

      if (evt.appointment) {
        const appt = {
          ...evt
        };
        appt.eventType = appt.appointmentTypeId || 'appointment';
        return appt;
      }
      if (evt.type === 'lead') {
        const lead = {
          ...evt
        };
        lead.eventType = 'lead';
        return lead;
      }
      return { ...evt, organizer: evt.consultant };
    });

    setCalendarList(updatedList);
  }, [events, appointments, tableLeads, monthEnd, monthStart]);

  // FUNCTIONS

  return (
    <>
      <AppContainer isCalendar withPadding={false}>
        <ComposerModal />
        {/* <CardContent /> */}
        <TableProvider>
          <FilterBar expanded={expanded} />
          <CalendarComponent
            data={calendarList}
            expanded={expanded}
            setExpanded={setExpanded}
            setDefaultWizardDate={setDefaultWizardDate}
          />
        </TableProvider>
        <EventDetails />

        <CalendarWizardDrawer defaultWizardDate={defaultWizardDate} />
      </AppContainer>
      <LeadsDetailsDrawer />
    </>
  );
};

Calendar.propTypes = {
  openEventModalRequest: PropTypes.func.isRequired,
  openCreateEventModal: PropTypes.func.isRequired,
  events: PropTypes.array,
  staff: PropTypes.array,
  users: PropTypes.array,
  searchEventRequest: PropTypes.func
};

export default Calendar;
