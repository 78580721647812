import styled from 'styled-components';

const ConfirmModalStyles = styled.div``;

ConfirmModalStyles.Message = styled.div`
  display: flex;
  justify-content: center;
  flex: 0.6;
  align-items: center;
  padding: 0 60px;
  font-family: Lora;
  font-weight: normal;
  font-size: ${props =>
    (props && props.type === 'submitSeatingChart' && 'font-size: 18px') || '24px'};
  letter-spacing: -0.6px;
  text-align: center;
  color: #181818;
`;

ConfirmModalStyles.Footer = styled.div`
  display: flex;
  flex: ${props => props.flex || '0.4'}
  height: 173px;
  justify-content: center;
  font-weight: bold;
  align-items: center;

  & > * {
    margin-right: 1%;
    margin-left: 1%;
  }
`;

export default ConfirmModalStyles;
