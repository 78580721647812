import React from 'react';
import { useSelector } from 'react-redux';
import { closeToast, openToast } from 'src/containers/ModalLoader/operations';
import { useActions } from 'src/hooks/useActions';

export const useToast = () => {
  const { toastOpen, toastMessage, toastBody } = useSelector(state => state.modalLoaderReducer);
  const actions = useActions({ closeToast, openToast });
  return {
    toastMessage,
    toastBody,
    toastOpen,
    ...actions
  };
};
