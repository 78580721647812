import React from 'react';
import PropTypes from 'prop-types';
import StatusStyles from './styles';

const Status = ({ bgColor, size }) => <StatusStyles bgColor={bgColor} size={size} />;

Status.propTypes = {
  bgColor: PropTypes.string,
  size: PropTypes.string
};

export default Status;
