import { Center, HStack, Icon } from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { BsTrash } from 'react-icons/bs';
import { DateFieldWithLabel } from 'src/v2/UIComponents/FormComponents';
import { Button, IconButton } from 'src/v2/UIComponents/Primitives';

export const LegacyPayments = () => {
  // get control from react-hook-form
  const {
    watch,
    control,
    setValue,
    formState: { errors }
  } = useFormContext();

  const {
    append: appendLegacyPayment,
    remove: removeLegacyPayment,
    fields: legacyPaymentFields
  } = useFieldArray({
    control,

    name: 'paymentStatus.schedule'
  });

  const removePayment = i => {
    removeLegacyPayment(i);
    const paymentStatus = watch('paymentStatus');
    setValue('paymentStatus', paymentStatus, { shouldDirty: true, shouldTouch: true });
  };

  const renderPaymentFields = () => {
    const schedule = watch('paymentStatus.schedule');

    return legacyPaymentFields.map((field, index) => {
      const latestSchedule = schedule && index > 0 && schedule[index - 1]?.date;
      const errorKey = {
        groupKey: `paymentStatus`,
        inputName: `schedule`,
        index
      };
      const legacyPaymentInputProps = {
        name: `paymentStatus.schedule[${index}].date`,
        label: `Deposit #${index + 1}`,
        placeholder: 'Deposit Date',
        minDateValue: latestSchedule,
        showTimeSelect: false,
        isRequired: true,
        errorKey,
        rules: {
          required: 'All payments must have a date'
        }
      };

      return (
        <HStack key={field.id}>
          <DateFieldWithLabel {...legacyPaymentInputProps} />
          <Center>
            <IconButton
              aria-label="test"
              colorScheme="red"
              variant="ghost"
              icon={<Icon as={BsTrash} w={4} h={4} />}
              tooltipLabel="Delete Payment"
              onClick={() => removePayment(index)}
            />
          </Center>
        </HStack>
      );
    });
  };

  return (
    <>
      {renderPaymentFields()}
      <Button variant="outline" onClick={() => appendLegacyPayment({ date: '' })}>
        Add Deposit to Schedule
      </Button>
    </>
  );
};
