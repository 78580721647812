export const OPEN_EVENT_MODAL = 'OPEN_MODAL';
export const CLOSE_EVENT_MODAL = 'CLOSE_EVENT_MODAL';

export const VIEW_EVENT_REQUEST = 'VIEW_EVENT_REQUEST';
export const VIEW_EVENT_SUCCESS = 'VIEW_EVENT_SUCCESS';
export const VIEW_EVENT_FAILURE = 'VIEW_EVENT_FAILURE';

export const GET_ROOM_DETAILS_REQUEST = 'GET_ROOM_DETAILS_REQUEST';
export const GET_ROOM_DETAILS_FAILURE = 'GET_ROOM_DETAILS_FAILURE';
export const GET_ROOM_DETAILS_SUCCESS = 'GET_ROOM_DETAILS_SUCCESS';

export const GET_LAYOUT_DETAILS_REQUEST = 'GET_LAYOUT_DETAILS_REQUEST';
export const GET_LAYOUT_DETAILS_FAILURE = 'GET_LAYOUT_DETAILS_FAILURE';
export const GET_LAYOUT_DETAILS_SUCCESS = 'GET_LAYOUT_DETAILS_SUCCESS';

export const GET_HOSTS_REQUEST = 'GET_HOSTS_REQUEST';
export const GET_HOSTS_SUCCESS = 'GET_HOSTS_SUCCESS';
export const GET_HOSTS_FAILURE = 'GET_HOSTS_FAILURE';

export const SET_PAYMENTSTATUS_REQUEST = 'SET_PAYMENTSTATUS_REQUEST';
export const SET_PAYMENTSTATUS_SUCCESS = 'SET_PAYMENTSTATUS_SUCCESS';
export const SET_PAYMENTSTATUS_FAILURE = 'SET_PAYMENTSTATUS_FAILURE';

export const SEND_VENUETOHOSTEMAIL_REQUEST = 'SEND_VENUETOHOSTEMAIL_REQUEST';
export const SEND_VENUETOHOSTEMAIL_SUCCESS = 'SEND_VENUETOHOSTEMAIL_SUCCESS';
export const SEND_VENUETOHOSTEMAIL_FAILURE = 'SEND_VENUETOHOSTEMAIL_FAILURE';

export const GENERATE_SEATING_CHART_PDF_REQUEST = 'GENERATE_SEATING_CHART_PDF_REQUEST';
export const GENERATE_SEATING_CHART_PDF_FAILURE = 'GENERATE_SEATING_CHART_PDF_FAILURE';

export const REQUEST_EVENT_PACKET = 'REQUEST_EVENT_PACKET';
export const REQUEST_EVENT_PACKET_FAILURE = 'REQUEST_EVENT_PACKET_FAILURE ';

export const OPEN_EVENT_MODAL_REQUEST = 'OPEN_EVENT_MODAL_REQUEST';
export const OPEN_EVENT_MODAL_SUCCESS = 'OPEN_EVENT_MODAL_SUCCESS';
export const OPEN_EVENT_MODAL_FAILURE = 'OPEN_EVENT_MODAL_FAILURE';

export const SET_ACTIVE_ITEM = 'SET_ACTIVE_ITEM';

export const GET_EVENT_DETAIL_REQUEST = 'GET_EVENT_DETAIL_REQUEST';
export const GET_EVENT_DETAIL_SUCCESS = 'GET_EVENT_DETAIL_SUCCESS';
export const GET_EVENT_DETAIL_FAILURE = 'GET_EVENT_DETAIL_FAILURE';

export const OPEN_EVENT_DETAILS_DRAWER = 'OPEN_EVENT_DETAILS_DRAWER';
export const CLOSE_EVENT_DETAILS_DRAWER = 'CLOSE_EVENT_DETAILS_DRAWER';

export const OPEN_EVENT_FORM_DRAWER = 'OPEN_EVENT_FORM_DRAWER';
export const CLOSE_EVENT_FORM_DRAWER = 'CLOSE_EVENT_FORM_DRAWER';
