import {
  GET_STAFF_REQUEST,
  GET_STAFF_SUCCESS,
  GET_STAFF_FAILURE,
  UPDATE_STAFF_REQUEST,
  UPDATE_STAFF_SUCCESS,
  UPDATE_STAFF_FAILURE,
  DELETE_STAFF_REQUEST,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_FAILURE,
  GET_STAFF_EVENTS_REQUEST,
  GET_STAFF_EVENTS_SUCCESS,
  GET_STAFF_EVENTS_FAILURE,
  OPEN_ADD_STAFF_MODAL,
  CLOSE_ADD_STAFF_MODAL,
  ADD_STAFF_REQUEST,
  ADD_STAFF_SUCCESS,
  ADD_STAFF_FAILURE
} from './types';

const initialState = {
  staff: null,
  updatedStaff: null,
  deleteSuccess: false,
  updating: false,
  deleting: false,
  fetching: false,
  fetchingEvents: false,
  addingStaff: false,
  addStaffSuccess: false,
  addStaffError: null,
  error: null,
  events: null,
  isOpen: false
};

const getStaffReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STAFF_REQUEST:
      return { ...state, staff: null, fetching: true, error: null };
    case GET_STAFF_SUCCESS:
      return {
        ...state,
        staff: action.staff,
        fetching: false,
        error: null,
        updatedStaff: null,
        deleteSuccess: false,
        addStaffSuccess: false
      };
    case GET_STAFF_FAILURE:
      return {
        ...state,
        staff: null,
        error: action.error.message,
        fetching: false,
        updatedStaff: null,
        deleteSuccess: false,
        addStaffSuccess: false
      };
    case UPDATE_STAFF_REQUEST:
      return { ...state, updatedStaff: null, error: null, updating: true, fetching: true };
    case UPDATE_STAFF_SUCCESS:
      return {
        ...state,
        updatedStaff: action.staff,
        updating: false,
        error: null,
        fetching: false
      };
    case UPDATE_STAFF_FAILURE:
      return {
        ...state,
        updatedStaff: null,
        updating: false,
        fetching: false,
        error: action.error
      };
    case DELETE_STAFF_REQUEST:
      return { ...state, deleteSuccess: false, error: null, deleting: true, updatedStaff: null };
    case DELETE_STAFF_SUCCESS:
      return { ...state, deleteSuccess: true, error: null, deleting: false, updatedStaff: null };
    case DELETE_STAFF_FAILURE:
      return {
        ...state,
        deleteSuccess: false,
        error: action.error.error,
        deleting: false,
        updatedStaff: null
      };
    case GET_STAFF_EVENTS_REQUEST:
      return { ...state, fetchingEvents: true, error: null, events: null, updatedStaff: null };
    case GET_STAFF_EVENTS_SUCCESS:
      return {
        ...state,
        fetchingEvents: false,
        error: null,
        events: action.events,
        updatedStaff: null
      };
    case GET_STAFF_EVENTS_FAILURE:
      return {
        ...state,
        fetchingEvents: false,
        error: action.error.error.message,
        events: null,
        updatedStaff: null
      };
    case OPEN_ADD_STAFF_MODAL:
      return {
        ...state,
        isOpen: true
      };
    case CLOSE_ADD_STAFF_MODAL:
      return {
        ...state,
        isOpen: false
      };
    case ADD_STAFF_REQUEST:
      return {
        ...state,
        addingStaff: true,
        addStaffSuccess: false,
        addStaffError: null
      };
    case ADD_STAFF_SUCCESS:
      return {
        ...state,
        addingStaff: false,
        addStaffSuccess: action.success,
        addStaffError: null
      };
    case ADD_STAFF_FAILURE:
      return {
        ...state,
        addingStaff: false,
        addStaffSuccess: false,
        addStaffError: action.error
      };
    default:
      return state;
  }
};

export default getStaffReducer;
