import React from 'react';
import Colors from 'src/styles/Colors';
import { FormErrorMessage as ChakraErrorMessage } from '@chakra-ui/react';
import { Tooltip } from '../Tooltip';

export const FormErrorMessage = ({ children, bottom = false }) => {
  const errorStyles = {
    backgroundColor: Colors.redC0,
    height: '1em',
    fontSize: '12px',
    color: Colors.white,
    borderTopRadius: 'md',
    py: '8px',
    px: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'default',
    alignSelf: bottom && 'end',
    minWidth: 0
  };
  return (
    <Tooltip label={children}>
      <ChakraErrorMessage {...errorStyles} role="alert">
        {children}
      </ChakraErrorMessage>
    </Tooltip>
  );
};
