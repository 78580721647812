import { takeLatest, call, put } from 'redux-saga/effects';

import { registerUserFailure, registerUserSuccess } from './operations';
import { REGISTER_USER_REQUEST } from './types';
import { registerUser } from './actions';
import { closeCreateEventModal, getUsersRequest } from '../AddEventModal/operations';

function* workerRegisterUser({ user, token, venueId }) {
  try {
    const response = yield call(registerUser, { user, token });
    yield put(registerUserSuccess(response.data));
    yield put(getUsersRequest({ token, venueId }));
  } catch (error) {
    yield put(registerUserFailure(error.response.data));
    yield put(getUsersRequest({ token, venueId }));
    yield put(closeCreateEventModal());
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* watchRegisterUser() {
  yield takeLatest(REGISTER_USER_REQUEST, workerRegisterUser);
}
