import { useEvents } from 'src/v2/Hooks/CollectionHooks';
import { useDrawers } from 'src/v2/Hooks/HelperHooks';
import { Wizard } from 'src/v2/UIComponents/Drawers/Wizard';
import { EventTaskForm } from './EventTaskForm';

export const EventTaskDrawer = () => {
  const {
    eventTaskDrawerOpen,
    closeEventTaskDrawer,
    eventTask: eventTaskToEdit,
    editEventTask
  } = useDrawers();
  const { updateEvent, activeEventId } = useEvents();

  const defaultValues = {
    name: eventTaskToEdit?.name,
    assignedTo: eventTaskToEdit?.assignedTo,
    description: eventTaskToEdit?.description,
    dueDate: eventTaskToEdit?.dueDate,
    completed: eventTaskToEdit?.completed
  };

  const closeDrawer = () => {
    closeEventTaskDrawer();
  };

  const handleSave = data => {
    if (editEventTask && eventTaskToEdit) {
      const { id: eventTaskId } = eventTaskToEdit;
      updateEvent({
        data: {
          eventTask: {
            ...data,
            id: eventTaskId
          }
        },
        eventId: activeEventId,
        closeAction: closeDrawer
      });
    } else {
      updateEvent({
        data: {
          eventTask: {
            ...data
          }
        },
        eventId: activeEventId,
        closeAction: closeDrawer
      });
    }
  };

  const steps = [
    { component: EventTaskForm, step: 0, title: editEventTask ? 'Edit Task' : 'Create Task' }
  ];

  return (
    <Wizard
      steps={steps}
      saveAction={handleSave}
      cancelAction={closeEventTaskDrawer}
      isOpen={eventTaskDrawerOpen}
      formId="eventTaskDrawer"
      defaultValues={defaultValues}
    />
  );
};
