import { REGISTER_USER_REQUEST, REGISTER_USER_SUCCESS, REGISTER_USER_FAILURE } from './types';

const initialState = {
  user: null,
  fetching: false,
  error: null
};

// eslint-disable-next-line import/prefer-default-export
export const registerUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null,
        user: null
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        user: action.user
      };
    case REGISTER_USER_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        user: null
      };
    default:
      return state;
  }
};
