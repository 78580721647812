import { TextFieldWithLabel } from 'src/v2/UIComponents/FormComponents';
import { ACTIVITY_FORM_CONSTANTS } from '../constants';

const ActivityName = props => {
  const { ACTIVITY_NAME } = ACTIVITY_FORM_CONSTANTS;
  const inputProps = {
    name: 'title',
    isRequired: true,
    label: ACTIVITY_NAME.LABEL,
    placeholder: ACTIVITY_NAME.PLACEHOLDER,
    rules: {
      required: ACTIVITY_NAME.REQUIRED
    }
  };
  return <TextFieldWithLabel {...inputProps} {...props} />;
};

export default ActivityName;
