import { useSelector } from 'react-redux';
import { checkHostEmail } from 'src/containers/AddEventModal/actions';
import { getUsersRequest } from 'src/containers/AddEventModal/operations';
import {
  doPolicyAcceptTrigger,
  doPolicyAccept,
  doPolicyReject,
  doPolicyRequire,
  doLogout,
  emailThreadOperations
} from 'src/containers/LoginContainer/operations';
import {
  doUploadImageToCloud,
  clearImageUpload,
  doEditProfileAvatar
} from 'src/containers/DashboardContainer/operations';
import { useActions } from 'src/hooks/useActions';
import { RootStates } from 'src/Types';

export const useUser = () => {
  const {
    admin,
    email,
    eventId,
    fetching: fetchingUser,
    firstname,
    lastname,
    photo,
    policyAcceptRequested,
    policyRejected,
    policyRequired,
    role,
    token,
    uid: userId,
    venueId,
    viewPermissions,
    claims,
    venuexAdmin
  } = useSelector((state: RootStates) => state.loginReducer);
  const { uploadStatus } = useSelector((state: RootStates) => state.dashboardReducer);

  const actions = useActions({
    checkHostEmail: ({ email: checkEmail }) =>
      token && venueId && checkHostEmail({ token, venueId, email: checkEmail }),
    doPolicyAcceptTrigger,
    doPolicyAccept: () => doPolicyAccept({ token }),
    doPolicyReject,
    doPolicyRequire,
    doLogout: () => doLogout({ token }),
    getUsersRequest: () => token && venueId && getUsersRequest({ token, venueId }),
    listEmailThreads: emailThreadOperations.request,
    doEditProfileAvatar,
    doUploadImageToCloud,
    clearImageUpload
  });

  return {
    admin,
    userId,
    token,
    email,
    eventId,
    firstname,
    lastname,
    photo,
    role,
    venueId,
    viewPermissions,
    policyRequired,
    policyRejected,
    policyAcceptRequested,
    fetchingUser,
    claims,
    permissions: claims && claims.permissions,
    uploadStatus,
    venuexAdmin,
    ...actions
  };
};
