import { takeLatest, put, call } from 'redux-saga/effects';

import {
  FORGOT_FAILURE,
  FORGOT_REQUEST,
  FORGOT_SUCCESS,
  REMIND_REQUEST,
  REMIND_FAILURE,
  REMIND_SUCCESS
} from './types';
import { forgot, remind } from './actions';

function* workerForgot({ email, venueId, eventId }) {
  try {
    const response = yield call(forgot, { email, venueId, eventId });

    const { success } = response.data;

    yield put({ type: FORGOT_SUCCESS, success });
  } catch (error) {
    yield put({ type: FORGOT_FAILURE, ...error.response.data });
  }
}

function* workerRemind({ email, venueId, eventId }) {
  try {
    const response = yield call(remind, { email, venueId, eventId });

    const { success } = response.data;

    yield put({ type: REMIND_SUCCESS, success });
  } catch (error) {
    yield put({ type: REMIND_FAILURE, ...error.response.data });
  }
}

export function* watcherForgot() {
  yield takeLatest(FORGOT_REQUEST, workerForgot);
}

export function* watcherRemind() {
  yield takeLatest(REMIND_REQUEST, workerRemind);
}
