import React from 'react';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import Alert from 'src/components/Alert';
import { Icon } from '@chakra-ui/react';
import { IoIosCheckmarkCircle } from 'react-icons/io';

import { getLayoutsAction as getLayoutOptionsAction } from 'src/containers/AddEventModal/actions';

import {
  GET_LAYOUTSREDUCER_REQUEST,
  ADD_LAYOUT_REQUEST,
  UPDATE_LAYOUT_REQUEST,
  DELETE_LAYOUT_REQUEST,
  UPLOAD_LAYOUT_REQUEST,
  GET_LAYOUT_BY_ID_REQUEST,
  DUPLICATE_LAYOUT_REQUEST,
  GET_LAYOUT_OPTIONS
} from './types';
import {
  getLayoutsAction,
  addLayoutAction,
  updateLayoutAction,
  deleteLayoutAction,
  uploadLayoutAction,
  getLayout,
  duplicateLayoutApi
} from './actions';
import {
  addLayoutFailure,
  addLayoutSuccess,
  deleteLayoutFailure,
  deleteLayoutSuccess,
  getLayoutsFailure,
  getLayoutsRequest,
  getLayoutsSuccess,
  updateLayoutFailure,
  updateLayoutSuccess,
  uploadLayoutSuccess,
  uploadLayoutFailure,
  getLayoutByIdSuccess,
  getLayoutByIdError,
  duplicateLayoutError,
  duplicateLayoutSuccess,
  getLayoutOptionsOperations
} from './operations';

const getToken = state => state.loginReducer.token;
const getVenueId = state => state.loginReducer.venueId;

function* workerGetLayouts({ venueId }) {
  try {
    const token = yield select(getToken);
    const response = yield call(getLayoutsAction, { token, venueId });
    if (response.data.success) {
      yield put(getLayoutsSuccess({ layouts: response.data.layouts }));
    }
  } catch (error) {
    yield put(getLayoutsFailure({ error }));
  }
}

function* workerGetLayoutOptions({ roomId }) {
  try {
    const token = yield select(getToken);

    const response = yield call(getLayoutOptionsAction, { token, roomId });

    if (response.data.success) {
      yield put(getLayoutOptionsOperations.success({ layouts: response.data.layouts }));
    }
  } catch (error) {
    yield put(getLayoutOptionsOperations.failure({ error }));
  }
}

function* workerAddLayout({ layout }) {
  try {
    const token = yield select(getToken);
    const venueId = yield select(getVenueId);
    const response = yield call(addLayoutAction, { token, layout });
    if (response.data.success) {
      yield put(addLayoutSuccess({ layout: response.data.layout }));
      yield put(getLayoutsRequest({ venueId }));
    }
  } catch (error) {
    yield put(addLayoutFailure({ error }));
  }
}

function* workerUpdateLayout({ layoutId, layout }) {
  try {
    const token = yield select(getToken);
    const venueId = yield select(getVenueId);
    const response = yield call(updateLayoutAction, { token, layoutId, layout });
    if (response.data.success) {
      yield put(updateLayoutSuccess({ layout: response.data.layout }));
      yield put(getLayoutsRequest({ venueId }));
    }
  } catch (error) {
    toast.error(error.response.data.error, { icon: '' });
    // yield put(updateLayoutFailure({ error }));
  }
}

function* workerUploadLayout({ formData }) {
  try {
    const token = yield select(getToken);
    const response = yield call(uploadLayoutAction, { token, formData });
    if (response.data.success) {
      yield put(uploadLayoutSuccess({ url: response.data.url }));
    }
  } catch (error) {
    yield put(uploadLayoutFailure({ error }));
  }
}

function* workerDeleteLayout({ layoutId }) {
  try {
    const token = yield select(getToken);
    const venueId = yield select(getVenueId);
    const response = yield call(deleteLayoutAction, { token, layoutId });
    if (response.data.success) {
      yield put(deleteLayoutSuccess());
      yield put(getLayoutsRequest({ venueId }));
    }
  } catch (error) {
    yield put(deleteLayoutFailure({ error }));
  }
}

function* workerGetLayoutById({ layoutId }) {
  try {
    const token = yield select(getToken);
    const response = yield call(getLayout, { token, layoutId });
    const { layout } = response.data;
    yield put(getLayoutByIdSuccess({ layout }));
  } catch (error) {
    yield put(getLayoutByIdError({ error: error.response.data }));
  }
}

function* workerDuplicateLayout({ layoutId }) {
  try {
    const token = yield select(getToken);
    const venueId = yield select(getVenueId);
    const response = yield call(duplicateLayoutApi, { token, layoutId });
    const { layout } = response.data;
    yield put(getLayoutsRequest({ venueId }));
    yield put(duplicateLayoutSuccess({ layout }));
  } catch (error) {
    yield put(duplicateLayoutError({ error: error.response.data }));
  }
}

export function* watchGetLayouts() {
  yield takeLatest(GET_LAYOUTSREDUCER_REQUEST, workerGetLayouts);
}

export function* watchAddLayout() {
  yield takeLatest(ADD_LAYOUT_REQUEST, workerAddLayout);
}

export function* watchUpdateLayout() {
  yield takeLatest(UPDATE_LAYOUT_REQUEST, workerUpdateLayout);
}

export function* watchUploadLayout() {
  yield takeLatest(UPLOAD_LAYOUT_REQUEST, workerUploadLayout);
}

export function* watchDeleteLayout() {
  yield takeLatest(DELETE_LAYOUT_REQUEST, workerDeleteLayout);
}

export function* watchGetLayoutById() {
  yield takeLatest(GET_LAYOUT_BY_ID_REQUEST, workerGetLayoutById);
}

export function* watchGetLayoutOptions() {
  yield takeLatest(GET_LAYOUT_OPTIONS.REQUEST, workerGetLayoutOptions);
}
export function* watchDuplicateLayout() {
  yield takeLatest(DUPLICATE_LAYOUT_REQUEST, workerDuplicateLayout);
}
