import { FieldErrors, FieldValues } from 'react-hook-form';

type FieldError = {
  message?: string;
  ref?: {
    name: string;
  };
  type?: string;
};

export const findErrorByName = (errors: FieldErrors<FieldValues>, name: string): string | null => {
  // Function to parse the parts of the field name
  const parseFieldNameParts = (fieldName: string): string[] => {
    const parts: string[] = [];
    const regex = /([^[.\]]+|\[\d+\])/g;
    let match: RegExpExecArray | null;
    while ((match = regex.exec(fieldName)) !== null) {
      parts.push(match[0].replace(/^\[|\]$/g, '')); // Remove brackets from array indices
    }
    return parts;
  };

  const nameParts = parseFieldNameParts(name);

  let currentError: FieldError | FieldErrors<FieldValues> | undefined = errors;
  for (const part of nameParts) {
    if (currentError && typeof currentError === 'object') {
      // TypeScript safe check for array and object types
      if (!isNaN(parseInt(part, 10)) && Array.isArray(currentError)) {
        currentError = (currentError as FieldErrors<FieldValues>[])[parseInt(part, 10)];
      } else {
        currentError = (currentError as FieldErrors<FieldValues>)[part];
      }
    } else {
      return null;
    }
  }

  if (currentError && typeof currentError === 'object' && 'message' in currentError) {
    return (currentError as FieldError).message || null;
  }

  return null;
};
