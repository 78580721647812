export const GET_DOCUMENTS_REQUEST = 'GET_DOCUMENTS_REQUEST';
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';
export const GET_DOCUMENTS_FAILURE = 'GET_DOCUMENTS_FAILURE';

export const GET_DOCUMENT_REQUEST = 'GET_DOCUMENT_REQUEST';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_FAILURE = 'GET_DOCUMENT_FAILURE';

export const ADD_DOCUMENT_REQUEST = 'ADD_DOCUMENT_REQUEST';
export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS';
export const ADD_DOCUMENT_FAILURE = 'ADD_DOCUMENT_FAILURE';

export const UPDATE_DOCUMENT_REQUEST = 'UPDATE_DOCUMENT_REQUEST';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_FAILURE = 'UPDATE_DOCUMENT_FAILURE';

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAILURE = 'DELETE_DOCUMENT_FAILURE';

export const COMMENT_DOCUMENT_REQUEST = 'COMMENT_DOCUMENT_REQUEST';
export const COMMENT_DOCUMENT_SUCCESS = 'COMMENT_DOCUMENT_SUCCESS';
export const COMMENT_DOCUMENT_FAILURE = 'COMMENT_DOCUMENT_FAILURE';

export const DOCUMENT_UPLOAD_REQUEST = 'DOCUMENT_UPLOAD_REQUEST';
export const DOCUMENT_UPLOAD_SUCCESS = 'DOCUMENT_UPLOAD_SUCCESS';
export const DOCUMENT_UPLOAD_FAILURE = 'DOCUMENT_UPLOAD_FAILURE';

export const DOCUMENTS_HIDE_NOTIFICATIONS = 'DOCUMENTS_HIDE_NOTIFICATIONS';

export const OPEN_DOCUMENT_UPLOAD = 'OPEN_DOCUMENT_UPLOAD';
export const CLOSE_DOCUMENT_UPLOAD = 'CLOSE_DOCUMENT_UPLOAD';

export const AUTOFILL_DOCUMENT_REQUEST = 'AUTOFILL_DOCUMENT_REQUEST';
export const AUTOFILL_DOCUMENT_SUCCESS = 'AUTOFILL_DOCUMENT_SUCCESS';
export const AUTOFILL_DOCUMENT_FAILURE = 'AUTOFILL_DOCUMENT_FAILURE';

export const OPEN_UPLOAD_DOCUMENT_VERSION_DRAWER = 'OPEN_UPLOAD_DOCUMENT_VERSION_DRAWER';
export const CLOSE_UPLOAD_DOCUMENT_VERSION_DRAWER = 'CLOSE_UPLOAD_DOCUMENT_VERSION_DRAWER';

export const SELECT_DOCUMENT = 'SELECT_DOCUMENT';
export const CLEAR_DOCUMENT = 'CLEAR_DOCUMENT';

export const DOWNLOAD_DOCUMENT = {
  REQUEST: 'DOWNLOAD_DOCUMENT_REQUEST',
  SUCCESS: 'DOWNLOAD_DOCUMENT_SUCCESS',
  FAILURE: 'DOWNLOAD_DOCUMENT_FAILURE'
};
