import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export const getEventsAction = ({
  token,
  limit = null,
  startDate = null,
  endDate = null,
  startAfter = null,
  monthStart = null,
  monthEnd = null
}) => {
  const url = `${apiUri}/events`;
  return axios({
    method: 'get',
    url,
    headers: { Authorization: token },
    params: { limit, startDate, startAfter, endDate, monthStart, monthEnd }
  });
};

export const getEventAnalyticsAction = ({
  token,
  limit = null,
  startDate = null,
  endDate = null,
  startAfter = null,
  monthStart = null,
  monthEnd = null
}) => {
  const url = `${apiUri}/events/analytics`;
  return axios({
    method: 'get',
    url,
    headers: { Authorization: token },
    params: { limit, startDate, startAfter, endDate, monthStart, monthEnd }
  });
};

export const getEventAction = ({ token, eventId }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/events/${eventId}`,
    headers: { Authorization: token }
  });
};

export const deleteEventAction = ({ token, id }) => {
  return axios({
    method: 'delete',
    url: `${apiUri}/events/${id}`,
    headers: { Authorization: token }
  });
};

export const searchEvent = ({ token, option }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/events/search?search=${option}`,
    headers: { Authorization: token }
  });
};

export const updateEventAction = ({ id, event, token }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/events/${id}`,
    headers: { Authorization: token },
    data: event
  });
};

export const updateEventChecklistItem = ({ token, eventId, role, checklistItem }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/events/${eventId}/checklistitems`,
    headers: { Authorization: token },
    data: { eventId, role, checklistItem }
  });
};

export function getUserTasks({ token }) {
  return axios({
    method: 'get',
    url: `${apiUri}/users/userTasks`,
    headers: { Authorization: token }
  });
}
