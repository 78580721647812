import { Tab } from '@chakra-ui/react';
import { Tooltip } from '../../Primitives';

export const CustomTab = ({ title, tabIndex, tabNumber, isDisabled, tooltip, ...props }) => (
  <Tooltip label={tooltip} shouldWrapChildren>
    <Tab
      paddingX="25px"
      _selected={{
        bg: 'var(--colors-primary)',
        color: 'white',
        borderRadius: '5px',
        fontWeight: 700
      }}
      _disabled={{
        cursor: 'not-allowed',
        opacity: 0.4
      }}
      flexShrink={0}
      fontSize="14px"
      lineHeight="20px"
      isDisabled={isDisabled}
    >
      {title}
    </Tab>
  </Tooltip>
);
