export const OPEN_DOCUMENT_LIBRARY_UPLOAD = 'OPEN_DOCUMENT_LIBRARY_UPLOAD';
export const CLOSE_DOCUMENT_LIBRARY_UPLOAD = 'CLOSE_DOCUMENT_LIBRARY_UPLOAD';
export const DOCUMENT_LIBRARY_UPLOAD_REQUEST = 'DOCUMENT_LIBRARY_UPLOAD_REQUEST';
export const DOCUMENT_LIBRARY_UPLOAD_SUCCESS = 'DOCUMENT_LIBRARY_UPLOAD_SUCCESS';
export const DOCUMENT_LIBRARY_UPLOAD_FAILURE = 'DOCUMENT_LIBRARY_UPLOAD_FAIURE';
export const DOCUMENT_LIBRARY_HIDE_NOTIFICATIONS = 'DOCUMENT_LIBRARY_HIDE_NOTIFICATIONS';
export const DOCUMENT_LIBRARY_REQUEST = 'DOCUMENT_LIBRARY_REQUEST';
export const DOCUMENT_LIBRARY_SUCCESS = 'DOCUMENT_LIBRARY_SUCCESS';
export const DOCUMENT_LIBRARY_FAILURE = ' DOCUMENT_LIBRARY_FAILURE';
export const DELETE_DOCUMENT_FROM_LIBRARY_REQUEST = 'DELETE_DOCUMENT_FROM_LIBRARY_REQUEST';
export const DELETE_DOCUMENT_FROM_LIBRARY_SUCCESS = 'DELETE_DOCUMENT_FROM_LIBRARY_SUCCESS';
export const DELETE_DOCUMENT_FROM_LIBRARY_FAILURE = 'DELETE_DOCUMENT_FROM_LIBRARY_FAILURE';
export const GET_DOCUMENT_LIBRARY_REQUEST = 'GET_DOCUMENT_LIBRARY_REQUEST';
export const GET_DOCUMENT_LIBRARY_SUCCESS = 'GET_DOCUMENT_LIBRARY_SUCCESS';
export const GET_DOCUMENT_LIBRARY_FAILURE = 'GET_DOCUMENT_LIBRARY_FAILURE';
export const CLEAR_DOCUMENT_LIBRARY_SELECTED = 'CLEAR_DOCUMENT_LIBRARY_SELECTED';
export const DOCUMENT_LIBRARY_UPDATE_REQUEST = 'DOCUMENT_LIBRARY_UPDATE_REQUEST';
export const DOCUMENT_LIBRARY_UPDATE_SUCCESS = 'DOCUMENT_LIBRARY_UPDATE_SUCCESS';
export const DOCUMENT_LIBRARY_UPDATE_FAILURE = 'DOCUMENT_LIBRARY_UPDATE_FAILURE';
export const DOWNLOAD_DOCUMENT_LIBRARY_REQUEST = 'DOWNLOAD_DOCUMENT_LIBRARY_REQUEST';
// messages
export const DOCUMENT_LIBRARY_UPLOAD_SUCCESS_MESSAGE = 'File added to document library';
