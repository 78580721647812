import { ComponentStyleConfig } from '@chakra-ui/react';
import Colors from 'src/styles/Colors';

export const Progress: ComponentStyleConfig = {
  baseStyle: {
    filledTrack: {
      bgColor: 'var(--colors-primary)'
    },
    track: {
      bgColor: Colors.grayED
    }
  },
  variants: {
    customGreen: {
      filledTrack: {
        bgColor: '#03B575'
      }
    }
  }
};
