import { Box } from '@chakra-ui/react';
import React, { FC } from 'react';
import Colors from 'src/styles/Colors';
import { EventTableDocument } from 'venuex-types';
import { useEvents } from 'src/v2/Hooks/CollectionHooks';
import { SelectInputWithLabel } from 'src/v2/UIComponents/FormComponents';
import { SelectInput } from 'src/v2/UIComponents/Primitives/SelectInput/SelectInput';
import { ComposerAttachmentTypes } from '../types';

export const ComposerAttachments: FC<ComposerAttachmentTypes> = () => {
  const { activeEvent } = useEvents();
  const { documents: unfilteredDocuments } = activeEvent;

  const documents = unfilteredDocuments?.filter((i): i is EventTableDocument => {
    return typeof i !== 'undefined';
  });

  const documentsOptions = documents?.map(document => ({
    ...document,
    label: `${document.label} (${document.filename})`,
    value: document.documentId
  }));

  return (
    <Box
      borderBottom={`1px solid ${Colors.grayED7}`}
      height="50px"
      display="flex"
      justifyContent={'space-between'}
      alignItems="center"
      padding="0px 12px"
      fontSize="14px"
      minHeight={'50px'}
    >
      Attachments:
      <Box flex="1">
        <SelectInput
          options={documentsOptions}
          placeholder="Attach an event document"
          handleOnChange={d => console.log('attach', d)}
          inline
        />
      </Box>
    </Box>
  );
};
