import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export function parseTable({ token, parsedData, parsedColumns }) {
  return axios({
    method: 'post',
    url: `${apiUri}/guests/parse`,
    headers: { Authorization: token },
    data: { parsedData, parsedColumns }
  });
}

export function saveMultipleGuests({ token, parsedData, parsedColumns, venueId, eventId }) {
  return axios({
    method: 'post',
    url: `${apiUri}/guests/save`,
    headers: { Authorization: token },
    data: { parsedData, parsedColumns, venueId, eventId }
  });
}
