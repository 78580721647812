import React from 'react';
import { NoteInput } from 'src/v2/Drawers/Leads/NotesDrawer/NotesForm/Inputs/NoteInput';
import { NoteTypeInput } from '../Inputs/NoteTypeInput';

export const Form = ({}) => (
  <>
    {/* <NoteTypeInput /> */}
    <NoteInput />
  </>
);
