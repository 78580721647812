/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  MenuDivider,
  MenuItem,
  Box
} from '@chakra-ui/react';
import { useTableContext } from 'src/v2/context/Tables';
import { CaretDown } from 'src/v2/UIComponents/Primitives/Icons';
import { Button } from 'src/v2/UIComponents/Primitives';
import _isObject from 'lodash/isObject';
import Colors from 'src/styles/Colors';
import { FilterGroups } from './FilterGroups';
import { getOptionListWithCount } from './helpers';

export type RootFilterProps = {
  dataKey: string;
  filterList: any;
  title: string;
  width?: string;
  isDisabled?: boolean;
  filterType?: any;
  disableMultiple?: boolean;
  customStyles?: { [key: string]: string };
};

const RootFilter = ({
  dataKey,
  filterList,
  title,
  width = '',
  isDisabled = false,
  filterType,
  disableMultiple = false,
  customStyles
}: RootFilterProps) => {
  const [itemsChecked, setItemsChecked] = useState<string[] | string>([]);
  const { filterData, clearFilter, filters, tableData, allData } = useTableContext();

  const handleClear = () => {
    clearFilter(dataKey);
    setItemsChecked([]);
  };

  useEffect(() => {
    const activeFilterObject = filters?.find(fltr => fltr.filterKey === dataKey);
    const activeFilters = activeFilterObject?.filterBy;
    activeFilters && setItemsChecked(activeFilters);
    if (itemsChecked?.length > 0 && !activeFilters) {
      setItemsChecked([]);
    }
  }, [filters, itemsChecked, dataKey]);

  const handleCheckValue = (filterBy: string, disabledMultiple: boolean) => {
    const activeFilterObject = filters?.find(fltr => fltr.filterKey === dataKey);
    const activeFilters = activeFilterObject?.filterBy;

    // Initialize itemsCheckedCopy as an array
    let itemsCheckedCopy: string[] = [];
    if (Array.isArray(activeFilters)) {
      itemsCheckedCopy = [...activeFilters];
    } else if (activeFilters) {
      itemsCheckedCopy = [activeFilters];
    }

    if (disabledMultiple) {
      const isAlreadyChecked = itemsCheckedCopy.includes(filterBy);
      if (!isAlreadyChecked) {
        itemsCheckedCopy = [filterBy];
      } else {
        itemsCheckedCopy = [];
      }
      setItemsChecked(itemsCheckedCopy);
      if (filterType && !filterType.group)
        filterData({ filterBy: itemsCheckedCopy, filterKey: dataKey, filterType });
    } else {
      const isAlreadyChecked = itemsCheckedCopy.includes(filterBy);

      if (!isAlreadyChecked) {
        itemsCheckedCopy.push(filterBy);
      } else {
        itemsCheckedCopy = itemsCheckedCopy.filter(val => val !== filterBy);
      }

      setItemsChecked(itemsCheckedCopy);
      filterData({ filterBy: itemsCheckedCopy, filterKey: dataKey, filterType });
    }
  };

  const totalFiltersActive = itemsChecked.length ? `(${itemsChecked.length}) ` : '';

  if (filterList?.groups) {
    return (
      <FilterGroups
        width={width}
        isDisabled={isDisabled}
        totalFiltersActive={totalFiltersActive}
        title={title}
        groups={filterList.groups}
        itemsChecked={itemsChecked}
        handleCheckValue={handleCheckValue}
        tableData={tableData}
        allData={allData}
        dataKey={dataKey}
        handleClear={handleClear}
        filters={filters}
      />
    );
  }
  const optionListWithCount = getOptionListWithCount({
    filterList,
    tableData,
    dataKey,
    allData,
    filters,
    filterType
  });

  const filterName = () => {
    if (disableMultiple) {
      const filterKeyActiveArray = filters?.filter(fltr => fltr.filterKey === dataKey);
      if (filterKeyActiveArray?.length) {
        const activeFilterValue = filterKeyActiveArray[0]?.filterBy[0];
        const activeFilterObject = filterList?.filter(
          fltr => fltr?.value === activeFilterValue
        )?.[0];
        return activeFilterObject?.label;
      }
    }
    return '';
  };
  return (
    <Menu closeOnSelect={false} isLazy>
      <MenuButton
        as={Button}
        variant="filterDropdown"
        rightIcon={<CaretDown />}
        width={width}
        isDisabled={isDisabled}
        {...customStyles}
      >
        {!disableMultiple && totalFiltersActive}
        {title}
        {filterName() && disableMultiple && ` - ${filterName()}`}
      </MenuButton>
      <MenuList zIndex="12">
        <MenuOptionGroup type={'checkbox'} value={itemsChecked}>
          {optionListWithCount?.map(val => {
            const isValObject = _isObject(val);
            const { totalMatches } = val;
            const Label = () => (
              <Box display="flex" gap="4px" alignItems={'center'}>
                <div>{isValObject ? val.label : val}</div> <div>{`(${totalMatches})`}</div>
              </Box>
            );
            return (
              <MenuItemOption
                // icon={disableMultiple && <Icon as={GrRadialSelected} display="flex" />}
                color={(!totalMatches && Colors.gray7D) || ''}
                key={val.label}
                value={isValObject ? val.value : val}
                textTransform="capitalize"
                fontFamily="Lato"
                fontSize="15px"
                lineHeight="22.5px"
                onClick={e => handleCheckValue(isValObject ? val.value : val, disableMultiple)}
                _active={{ background: 'grayED' }}
                _focus={{ background: 'grayED' }}
                _checked={{ background: disableMultiple && 'var(--colors-primary-lightest)' }}
                display="flex"
                alignItems={'center'}
                isDisabled={totalMatches < 1}
              >
                <Label />
              </MenuItemOption>
            );
          })}
          <MenuDivider />
          <MenuItem
            pl="36px"
            fontFamily="Lato"
            fontSize="15px"
            lineHeight="22.5px"
            onClick={handleClear}
            _active={{ background: 'grayED' }}
            _focus={{ background: 'grayED' }}
          >
            Clear
          </MenuItem>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

export const Filter = React.memo(RootFilter);
