import styled from 'styled-components';
import Colors from '../Colors';

const InputStyles = styled.input.attrs(props => ({
  name: props.name || '',
  value: props.value || '',
  type: props.type || 'text',
  required: props.required || false,
  disabled: props.disabled || false,
  placeholder: props.placeholder || '',
  autocomplete: props.autocomplete || 'on'
}))`
  display: flex;
  flex-grow: ${props => (props.disableGrow ? '' : 1)};
  border-width: 0px;
  border-bottom-width: ${props => (props.noBorder ? '' : '1px')};
  border-style: solid;
  ${props =>
    props.error
      ? `border-color: ${Colors.redC0};`
      : `border-color: ${props.borderColors || Colors.grayD8};`}
  padding: 0 10px;
  height: ${props => props.height || '42px'};
  width: ${props => props.width || ''};
  line-height: ${props => props.lineHeight || '42px'};
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  font-family: Lato;
  font-size: ${props => props.fontSize || '15px'};
  cursor: ${props => props.disabled && 'not-allowed'};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.1px;

  &::placeholder {
    color: ${props => (props.error ? Colors.redC0 : Colors.grayD8)};
  }

  &:hover {
    border-bottom: ${props => !props.disabled && 'solid 1px var(--colors-primary)'};
  }
`;

export default InputStyles;
