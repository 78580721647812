import React from 'react';
import config from '../.env.json';

const { bucketUri } = config;

export const displayImage = (photo, firstname, lastname) => {
  let display;
  if (!photo) {
    const firstnameInitial = (firstname && firstname.substring(0, 1)) || '';
    const lastnameInitial = (lastname && lastname.substring(0, 1)) || '';

    display = (
      <div className="initials" style={{ textTransform: 'uppercase', fontWeight: '600' }}>
        <span>
          {firstnameInitial}
          {lastnameInitial}
        </span>
      </div>
    );
  } else {
    display = <img alt={firstname} src={`${bucketUri}${photo}`} />;
  }

  return display;
};
