const checkPolicies = (required, privacy, terms, venue) => {
  // if its already marked required, no need to trigger any change
  if (required) return false;

  if (!venue) return false;

  // see if venue even has any policy values
  if (!venue.policyPrivacy && !venue.policyTerms) return false;

  // see if user is missing one that venue has
  if (venue.policyPrivacy && !privacy) return true;
  if (venue.policyTerms && !terms) return true;

  // check for newer timestamps for policy
  if (
    venue.policyPrivacy &&
    venue.policyPrivacy.updated &&
    privacy &&
    venue.policyPrivacy.updated._seconds > privacy
  )
    return true;

  if (
    venue.policyTerms &&
    venue.policyTerms.updated &&
    privacy &&
    venue.policyTerms.updated._seconds > terms
  )
    return true;

  return false;
};

export default checkPolicies;
