import React from 'react';
import PropTypes from 'prop-types';
import AddChecklistModalStyles from './styles';
import Close from '../../styles/Icons/Close';
import Colors from '../../styles/Colors';

const AddChecklistModalHeader = ({ title, setShowAddChecklist }) => {
  return (
    <AddChecklistModalStyles.Header>
      <AddChecklistModalStyles.Close onClick={() => setShowAddChecklist(false)}>
        <Close color={Colors.gray7D} size={14} />
      </AddChecklistModalStyles.Close>
      {title}
    </AddChecklistModalStyles.Header>
  );
};

AddChecklistModalHeader.propTypes = {
  title: PropTypes.string,
  setShowAddChecklist: PropTypes.func
};

AddChecklistModalHeader.defaultProps = {
  title: '',
  setShowAddChecklist: () => {}
};

export default AddChecklistModalHeader;
