// action types
import { EMAIL_THREADS_DRAWER } from 'src/v2/Drawers/Redux/types';
import {
  SET_PAYMENTSTATUS_FAILURE,
  SET_PAYMENTSTATUS_REQUEST,
  SET_PAYMENTSTATUS_SUCCESS
} from 'src/containers/EventDetailsModal/types';
import { ADD_EVENT_SUCCESS, SEND_EVENT_EMAIL, UPDATE_EVENT_SUCCESS } from '../AddEventModal/types';
import { GET_EMAIL_THREADS } from '../LoginContainer/types';
import {
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,
  GET_EVENT_REQUEST,
  GET_EVENT_FAILURE,
  GET_EVENT_SUCCESS,
  GET_MORE_EVENTS_REQUEST,
  GET_MORE_EVENTS_FAILURE,
  GET_MORE_EVENTS_SUCCESS,
  SET_PAYMENT_STATUS,
  CLEAR_ACTIVE_EVENT,
  UPDATE_EVENT_CHECKLIST_ITEM_SUCCESS,
  OPEN_CREATE_EVENT_FORM,
  CLOSE_CREATE_EVENT_FORM,
  GET_EVENT_ANALYTICS,
  GET_USER_TASKS_REQUEST,
  GET_USER_TASKS_SUCCESS,
  GET_USER_TASKS_FAILURE
} from './types';
import { CLOSE_LEADS_DETAILS } from '../LeadsContainer/types';
import {
  COMMENT_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_SUCCESS,
  GET_DOCUMENTS_SUCCESS,
  UPDATE_DOCUMENT_SUCCESS
} from '../DocumentsContainer/types';

// reducer with initial state
const initialState = {
  fetching: false,
  fetchingMore: false,
  error: null,
  deleteEventError: null,
  events: null,
  deleteEventSuccess: false,
  fetchingEvent: false,
  startAfter: null,
  stats: null,
  monthStart: null,
  monthEnd: null,
  isCreateEventFormOpen: false,
  appointments: [],
  filterBarExpanded: false,
  fetchingEmailThreads: false,
  threadOptions: [],
  event: {},
  detailedEventList: [],
  fetchingAnalytics: false,
  userTasks: [],
  fetchingUserTasks: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EVENT_SUCCESS:
      return {
        ...state,
        events: action.events
      };

    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        event: {
          ...action.event,
          advancedNotes: action.event.advancedNotes
        }
      };

    case GET_EVENTS_REQUEST:
      return {
        ...state,
        fetching: true,
        events: null,
        error: null,
        monthStart: action.monthStart,
        monthEnd: action.monthEnd
      };
    case GET_EVENTS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        deleteEventError: null,
        deleteEventSuccess: false
      };

    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        events: action.events,
        deleteEventError: null,
        deleteEventSuccess: false,
        stats: action.stats,
        startAfter: action.events[action.events.length - 1] || { ...state.startAfter },
        appointments: action.appointments
      };
    case GET_EVENT_ANALYTICS.REQUEST:
      return {
        ...state,
        fetchingAnalytics: true,
        events: null,
        error: null
      };
    case GET_EVENT_ANALYTICS.FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        deleteEventError: null,
        deleteEventSuccess: false
      };

    case GET_EVENT_ANALYTICS.SUCCESS:
      return {
        ...state,
        fetchingAnalytics: false,
        error: null,
        events: action.events,
        deleteEventError: null,
        deleteEventSuccess: false,
        stats: action.stats,
        startAfter: action.events[action.events.length - 1] || { ...state.startAfter },
        appointments: action.appointments,
        detailedEventList: action.detailedEventList
      };
    case GET_MORE_EVENTS_REQUEST:
      return {
        ...state,
        fetchingMore: true,
        error: null
      };
    case GET_MORE_EVENTS_FAILURE:
      return {
        ...state,
        fetchingMore: false,
        error: action.error
      };
    case GET_MORE_EVENTS_SUCCESS:
      return {
        ...state,
        fetchingMore: false,
        error: null,
        events: [...state.events, ...action.events],
        startAfter: action.events[action.events.length - 1] || { ...state.startAfter }
      };
    case GET_EVENT_REQUEST:
      return {
        ...state,
        fetchingEvent: true,
        error: {}
      };
    case GET_EVENT_FAILURE:
      return {
        ...state,
        fetchingEvent: false,
        error: action.error,
        event: {}
      };
    case CLEAR_ACTIVE_EVENT:
      return {
        ...state,
        event: {}
      };
    case GET_EVENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetchingEvent: false,
        error: null,
        event: { ...state.event, ...action.event }
      };
    case DELETE_EVENT_REQUEST:
      return {
        ...state,
        fetching: true,
        deleteEventError: null,
        deleteEventSuccess: false
      };
    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        deleteEventError: null,
        deleteEventSuccess: true
      };
    case DELETE_EVENT_FAILURE:
      return {
        ...state,
        deleteEventError: action.error,
        fetching: false,
        deleteEventSuccess: false
      };
    // NOTE: to avoid an unnecessary api call, and to get around the multiple instances
    // of 'events' in different containers in state, the EventDetailsModal container needs
    // to call this to update a property of one of the events in the array in this state
    // object, so that if you reopen the dialog, the new data gets copied to that state
    case SET_PAYMENT_STATUS: {
      const clone = [...state.events];
      const found = clone.find(e => e.id === action.eventId);
      // account for whatever reason its not there
      if (found) {
        found.paymentStatus.status = action.status;
        return {
          ...state,
          events: clone
        };
      }
      return { ...state };
    }
    case UPDATE_EVENT_CHECKLIST_ITEM_SUCCESS:
      return {
        ...state
      };
    case OPEN_CREATE_EVENT_FORM:
      return {
        ...state,
        isCreateEventFormOpen: true
      };
    case CLOSE_CREATE_EVENT_FORM:
      return {
        ...state,
        isCreateEventFormOpen: false
      };
    case GET_EMAIL_THREADS.REQUEST:
      return {
        ...state,
        fetchingEmailThreads: true
      };
    case GET_EMAIL_THREADS.SUCCESS:
      return {
        ...state,
        fetchingEmailThreads: false,
        threadOptions: [...state.threadOptions, ...action.threadOptions]
      };
    case GET_EMAIL_THREADS.FAILURE:
      return {
        ...state,
        fetchingEmailThreads: false
      };
    case EMAIL_THREADS_DRAWER.CLOSE:
      return {
        ...state,
        threadOptions: []
      };
    case SEND_EVENT_EMAIL.SUCCESS: {
      return {
        ...state,
        event: { ...state.event, ...action.eventData }
      };
    }

    // get documents succcess
    case GET_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        event: {
          ...state.event,
          documents: [...action.documents]
        }
      };
    }

    case DELETE_DOCUMENT_SUCCESS: {
      return {
        ...state,
        event: {
          ...state.event,
          documents: [...action.documents]
        }
      };
    }

    case UPDATE_DOCUMENT_SUCCESS: {
      return {
        ...state,
        event: {
          ...state.event,
          documents: [...action.documents]
        }
      };
    }

    case COMMENT_DOCUMENT_SUCCESS: {
      return {
        ...state,
        event: {
          ...state.event,
          documents: [...action.documents]
        }
      };
    }

    case CLOSE_LEADS_DETAILS:
      return {
        ...state,
        isLeadsDetailsOpen: false,
        event: {}
      };
    case SET_PAYMENTSTATUS_REQUEST:
      return {
        ...state,
        settingPaymentStatus: true
      };
    case SET_PAYMENTSTATUS_SUCCESS:
      return {
        ...state,
        settingPaymentStatus: false,
        event: {
          ...state.event,
          paymentStatus: {
            ...state.event.paymentStatus,
            status: action.status
          }
        },
        updateEventError: null
      };
    case SET_PAYMENTSTATUS_FAILURE:
      return {
        ...state,
        settingPaymentStatus: false
      };
    case GET_USER_TASKS_REQUEST:
      return {
        ...state,
        fetchingUserTasks: true
      };
    case GET_USER_TASKS_SUCCESS:
      return {
        ...state,
        fetchingUserTasks: false,
        userTasks: action.userTasks
      };
    case GET_USER_TASKS_FAILURE:
      return {
        ...state,
        fetchingUserTasks: false,
        userTasks: []
      };

    default:
      return state;
  }
};

export default reducer;
