import { Box } from '@chakra-ui/react';
import _isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import { Checkmark } from 'react-checkmark';
import Toggle from 'react-toggle';
import {
  ColumnProps,
  defaultTableRowRenderer,
  TableCellRenderer,
  TableRowProps
} from 'react-virtualized';
import AddChecklistModal from 'src/components/AddChecklistModal';
import TableRow, { CheckListRow, HeaderRow } from 'src/components/ChecklistTable/Row';
import ChecklistTableStyles from 'src/components/ChecklistTable/styles';
import RowControls from 'src/components/VirtualizedGuestListTable/RowControls';
import Colors from 'src/styles/Colors';
import Loader from 'src/styles/Icons/Loader';
import InputRadio from 'src/styles/InputRadio';
import { generateOrderedGroups } from 'src/utils/generateChecklistGroups';
import { getDateDisplay } from 'src/utils/getDateDisplay';
import { useChecklist, useEvents, useUser } from 'src/v2/Hooks/CollectionHooks';
import { Table } from 'src/v2/Table';
import { Tooltip } from 'src/v2/UIComponents/Primitives';
import { EventChecklistItem } from 'venuex-types';

// const rowCache = new CellMeasurerCache({
//   defaultHeight: 50,
//   fixedWidth: true,
//   keyMapper: () => 1
// });

type CheckListItemUpdating = string;

export const ChecklistTable = () => {
  const [itemUpdating, setItemUpdating] = useState<CheckListItemUpdating>(
    '' as CheckListItemUpdating
  );
  const [itemToDelete, setItemToDelete] = useState({});
  const [itemToEdit, setItemToEdit] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // const [showAddChecklist, setShowAddChecklist] = useState(false);

  const [groupedChecklist, setGroupedChecklist] = useState<CheckListRow[]>([]);
  const { fetchingEvent: fetchingEventStatus, activeEvent, getEvent } = useEvents();
  const eventKeyLength = Object.keys(activeEvent).length;

  const { checklistItems } = activeEvent;

  const { role, token, eventId } = useUser();
  const {
    filteredChecklistItems,
    setSortField,
    setSortOrder,
    sortOrder,
    updateChecklistItem,
    completedFilter,
    setCompleted,
    status,
    createChecklistItemRequest: createChecklistItem,
    openChecklistModal,
    closeChecklistModal,
    isChecklistModalOpen,
    updatingChecklist
  } = useChecklist();

  const checkListGroups = useMemo(() => generateOrderedGroups(filteredChecklistItems || {}), [
    filteredChecklistItems
  ]);

  useEffect(() => {
    setIsLoading(fetchingEventStatus);
  }, [fetchingEventStatus]);

  useEffect(() => {
    if (role === 'host' && !eventKeyLength) {
      getEvent({ eventId });
    }
  }, [eventKeyLength]);

  const handleCheckList = (
    isExpanded: (index: number, expanded?: boolean | undefined) => boolean | undefined
  ) => {
    const newChecklist = Object.entries(checkListGroups).reduce(
      (acc: CheckListRow[], [key, values], index) => {
        const isHeader = (val: CheckListRow): val is HeaderRow =>
          Object.keys(val).filter((ky: string) => ky === 'header').length > 0;
        const headerRows = groupedChecklist.filter(isHeader);
        const row = {
          index,
          header: true,
          title: key,
          expanded: headerRows.find(item => item.header && item.title === key)
            ? headerRows.find(item => item.title === key)?.expanded
            : true,
          size: values.length
        } as HeaderRow;
        row.expanded = isExpanded(index, row.expanded) || false;
        const checklist = row.expanded ? values : [];
        return [...acc, row, ...checklist];
      },
      []
    );
    /* Transform checkListGroups object to an array of objects that have a
     header object followed by its subesquent values */
    // const newChecklist = [] as CheckListRow[];
    // Object.keys(checkListGroups).forEach((key, index) => {
    //   const row = {
    //     index,
    //     header: true,
    //     title: key,
    //     expanded: false,
    //     columns: checkListGroups[key],
    //     size: checkListGroups[key].length
    //   } as HeaderRow;
    //   row.expanded = isExpanded(index, row.expanded) || false;
    //   const checklist = row.expanded ? checkListGroups[key] : [];
    //   newChecklist.push(row, ...checklist);
    // });

    setGroupedChecklist(newChecklist);
  };

  useEffect(() => {
    handleCheckList((index, expanded) => expanded);
  }, [checkListGroups]);

  const toggleCollapsible = ({ rowData }) => {
    if (rowData.header) {
      handleCheckList((index, expanded) => {
        if (index === rowData.index) {
          return !expanded;
        }
        return expanded;
      });
    }
  };

  const rowRenderer = (rowProps: TableRowProps) => {
    if (rowProps.rowData.header) {
      return (
        <TableRow
          key={rowProps.index}
          rowProps={rowProps}
          toggleCollapsible={toggleCollapsible}
          groupedChecklist={groupedChecklist}
          itemUpdating={itemUpdating}
        />
      );
    }
    return defaultTableRowRenderer(rowProps);
  };

  const handleUpdateChecklistItem = (itemId: string) => {
    if (!_isEmpty(checklistItems)) {
      if (checklistItems?.[itemId].completed === false) setItemUpdating(itemId);
      setTimeout(() => {
        updateChecklistItem({
          token,
          checklistItems: { [itemId]: { completed: !checklistItems?.[`${itemId}`].completed } },
          eventId: activeEvent.id
        });
      }, 900);
    }
  };

  const handleToggleCompleted = () => {
    if (completedFilter === 'uncompleted') {
      setCompleted({ completedFilter: 'all' });
    }
    if (completedFilter === 'all') {
      setCompleted({ completedFilter: 'uncompleted' });
    }
  };

  const handleEditChecklistItem = item => {
    // setShowAddChecklist(true);
    openChecklistModal();
    setItemToEdit(item);
  };

  const handleDeleteChecklistItem = item => {
    // openConfirm();
    setItemToDelete(item);
  };

  const handleCreateItem = item => {
    createChecklistItem({ token, checklistItem: { ...item, itemType: 'host' } });
  };

  const handleCloseChecklistModal = flag => {
    closeChecklistModal();
  };

  const columns = [
    {
      dataKey: '',
      size: 'sm',
      cellDataGetter: ({ rowData }) => ({
        id: rowData.id,
        completed: rowData.completed,
        required: rowData.required
      }),
      flexGrow: 0,
      minWidth: 20,
      cellRenderer: ({ cellData }) => {
        const { id, completed, required } = cellData as EventChecklistItem & {
          id: string;
        };
        return (
          <>
            {updatingChecklist ? (
              <Loader size={22} />
            ) : (
              <InputRadio
                disabled={role === 'staff' && !required}
                action={() =>
                  (role === 'host' || (role === 'staff' && required)) &&
                  handleUpdateChecklistItem(id)
                }
                checked={completed}
                bgColor={Colors.green2C}
                borderColor={Colors.green2C}
              >
                {(completed && <Checkmark size={15} color={'#2cb070'} />) ||
                  (id === itemUpdating && <Checkmark size={15} color={'#2cb070'} />) ||
                  null}
              </InputRadio>
            )}
          </>
        );
      }
    },
    {
      label: 'Item',
      dataKey: 'name',
      cellDataGetter: ({ rowData }) => {
        return { name: rowData.name, completed: rowData.completed };
      },
      flexGrow: 3,
      cellRenderer: ({ cellData, dataKey, parent, rowIndex }) => {
        const { name, completed } = cellData;
        return (
          // <CellMeasurer
          //   cache={rowCache}
          //   columnIndex={0}
          //   key={dataKey}
          //   parent={parent}
          //   rowIndex={rowIndex}
          // >
          //   {({ registerChild }) => (
          //     // 'style' attribute required to position cell (within parent List)
          //     <Box
          //       {...registerChild}
          //       overflowWrap={'break-word'}
          //       whiteSpace={'break-spaces'}
          //       paddingY="16px"
          //       minW="fit-content"
          //     >
          //       {name && name.charAt(0).toUpperCase() + name.slice(1)}
          //     </Box>
          //   )}
          // </CellMeasurer>
          <Tooltip label={name && name.charAt(0).toUpperCase() + name.slice(1)}>
            <Box whiteSpace={'nowrap'} textOverflow={'ellipsis'}>
              {name && name.charAt(0).toUpperCase() + name.slice(1)}
            </Box>
          </Tooltip>
        );
      }
    } as {
      label: string;
      dataKey: string;
      cellDataGetter: ({ rowData }) => { name: string; completed: boolean };
      cellRenderer: TableCellRenderer;
    },
    {
      label: 'Due Date',
      dataKey: 'dueDate',
      componentType: 'date',
      dateFormat: 'short',
      flexShrink: 1,
      flexGrow: 1
    },
    {
      label: 'Completed By',
      dataKey: 'completedBy',
      flexGrow: 2
    },
    {
      label: ' Completed On',
      dataKey: 'completedDate',
      cellDataGetter: ({ rowData }) => ({
        value: (rowData.completedDate && getDateDisplay(rowData.completedDate).shortDateTime) || ''
      }),
      flexGrow: 2
    },
    {
      dataKey: '',
      headerRenderer: () => {
        return (
          <ChecklistTableStyles.Toggle>
            {/* <span style={{ marginRight: '12px' }}>Hide Completed</span>
            <Toggle
              checked={completedFilter === 'uncompleted'}
              name="completedFilter"
              icons={false}
              onChange={() => handleToggleCompleted()}
            /> */}
          </ChecklistTableStyles.Toggle>
        );
      },
      cellRenderer: ({ rowData }) =>
        (((rowData.overrideRequired || !rowData.required) && role === 'host') ||
          (role === 'staff' && rowData.required)) && (
          <RowControls
            disableDelete={role === 'staff'}
            disableEdit={rowData.overrideRequired && role !== 'staff'}
            handleEdit={() => handleEditChecklistItem(rowData)}
            handleDelete={() => handleDeleteChecklistItem(rowData)}
          />
        )
    }
  ] as ColumnProps[];

  const handleSort = ({ field }) => {
    setIsLoading(true);
    setSortField({ sortField: field });
    if (sortOrder === 'asc') {
      setSortOrder({ sortOrder: 'desc' });
    } else {
      setSortOrder({ sortOrder: 'asc' });
    }
    setTimeout(() => setIsLoading(false), 400);
  };

  return (
    <>
      <Table
        columns={columns}
        data={!isLoading && groupedChecklist}
        emptyStateProps={undefined}
        isLoading={isLoading}
        rowRenderer={rowRenderer}
        rowClickAction={toggleCollapsible}
        // rowHeight={rowCache.rowHeight}
        disableColumnWidth={true}
        onHeaderClick={({ dataKey }) => handleSort({ field: dataKey })}
      />
      <AddChecklistModal
        role={role}
        event={activeEvent}
        status={status}
        itemToEdit={itemToEdit}
        handleCreateItem={handleCreateItem}
        showAddChecklist={isChecklistModalOpen}
        setShowAddChecklist={handleCloseChecklistModal}
        // handleToggleCompleted={handleToggleCompleted}
        // handleUpdateHostChecklistItem={handleUpdateHostChecklistItem}
      />
    </>
  );
};
