import { takeLatest, call, put } from 'redux-saga/effects';
import { resetPasswordFailed, resetPasswordSuccess } from './operations';

import { resetPasswordAction } from './actions';
import { RESET_PASSWORD_USER_REQUEST } from './types';

function* workerResetUserPassword({ token, data }) {
  try {
    const response = yield call(resetPasswordAction, { token, data });
    yield put(resetPasswordSuccess({ response: response.data }));
  } catch (error) {
    yield put(resetPasswordFailed({ error: error.response.data }));
  }
}

export function* watchResetUserPassword() {
  yield takeLatest(RESET_PASSWORD_USER_REQUEST, workerResetUserPassword);
}
