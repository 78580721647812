import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  FETCH_VENDOR_CATEGORIES_REQUEST,
  FETCH_VENDORS_REQUEST,
  FETCH_VENDOR_CATEGORIES_FAILURE,
  FETCH_VENDOR_CATEGORIES_SUCCESS,
  FETCH_VENDORS_SUCCESS,
  FETCH_VENDORS_FAILURE
} from './types';
import { fetchVendorCategoriesApi, fetchVendorsApi } from './actions';

const getToken = state => state.loginReducer.token;

function* workerFetchVendorCategories() {
  try {
    const token = yield select(getToken);
    const response = yield call(fetchVendorCategoriesApi, { token });
    if (response.data.success) {
      yield put({
        type: FETCH_VENDOR_CATEGORIES_SUCCESS,
        vendorCategory: response.data.vendorCategory
      });
    }
  } catch (error) {
    yield put({ type: FETCH_VENDOR_CATEGORIES_FAILURE, error: error.response.data });
  }
}

function* workerFetchVendors({ category }) {
  try {
    const token = yield select(getToken);
    const response = yield call(fetchVendorsApi, { token, category });
    if (response.data.success) {
      yield put({ type: FETCH_VENDORS_SUCCESS, vendors: response.data.vendors });
    }
  } catch (error) {
    yield put({ type: FETCH_VENDORS_FAILURE, error: error.response.data });
  }
}

export function* watchFetchVendorCategories() {
  yield takeLatest(FETCH_VENDOR_CATEGORIES_REQUEST, workerFetchVendorCategories);
}
export function* watchFetchVendors() {
  yield takeLatest(FETCH_VENDORS_REQUEST, workerFetchVendors);
}
