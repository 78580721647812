import { ComponentStyleConfig } from '@chakra-ui/react';

const textStyle = {
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '28px',
  letterSpacing: '0.2px'
};

export const MenuButton: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: '8px'
  },
  sizes: {
    sm: {
      h: '35px',
      ...textStyle
    },
    md: {
      h: '48px',
      ...textStyle
    }
  },
  variants: {
    primary: {
      bg: 'var(--colors-primary)',
      color: 'white'
    },
    primaryOutline: {
      borderColor: 'var(--colors-primary)',
      bg: 'white',
      color: 'var(--colors-primary)',
      borderWidth: '1px'
    }
  },
  defaultProps: {
    variant: 'primary',
    size: 'sm'
  }
};
