import styled from 'styled-components';
import { Input } from '@chakra-ui/react';
import React from 'react';
import Colors from 'src/styles/Colors';

const RootStyledInput = styled(Input)``;

export const InputStyles = {
  borderColor: '#ccc',
  variant: 'outline',
  size: 'md',
  borderRadius: 'sm',
  _invalid: {
    borderColor: Colors.redC0
  },
  _focus: { borderWidth: '2px' }
};

export const StyledInput = React.memo(RootStyledInput);
