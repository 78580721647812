const GENERATE_PROPOSAL = {
  REQUEST: 'GENERATE_PROPOSAL_REQUEST',
  SUCCESS: 'GENERATE_PROPOSAL_SUCCESS',
  FAILURE: 'GENERATE_PROPOSAL_FAILURE'
};

const GENERATE_CONTRACT = {
  REQUEST: 'GENERATE_CONTRACT_REQUEST',
  SUCCESS: 'GENERATE_CONTRACT_SUCCESS',
  FAILURE: 'GENERATE_CONTRACT_FAILURE'
};

const PROPOSAL_TYPES = {
  GENERATE_PROPOSAL,
  GENERATE_CONTRACT
};

export default PROPOSAL_TYPES;
