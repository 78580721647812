import {
  FETCH_MEMORIES_REQUEST,
  FETCH_MEMORIES_SUCCESS,
  FETCH_MEMORIES_FAILURE,
  DELETE_MEMORY_REQUEST,
  DELETE_MEMORY_SUCCESS,
  DELETE_MEMORY_FAILURE,
  BATCH_DELETE_REQUEST,
  BATCH_DELETE_SUCCESS,
  BATCH_DELETE_FAILURE,
  SET_SEARCH_TERM,
  CREATE_MEMORIES_REQUEST,
  CREATE_MEMORIES_SUCCESS,
  CREATE_MEMORIES_FAILURE,
  EDIT_MOBILE_PHOTO_REQUEST,
  EDIT_MOBILE_PHOTO_SUCCESS,
  EDIT_MOBILE_PHOTO_FAILURE,
  GET_MODERATORS_REQUEST,
  GET_MODERATORS_SUCCESS,
  GET_MODERATORS_FAILURE,
  ADD_MODERATOR_REQUEST,
  ADD_MODERATOR_SUCCESS,
  ADD_MODERATOR_FAILURE,
  DELETE_MODERATOR_REQUEST,
  DELETE_MODERATOR_SUCCESS,
  DELETE_MODERATOR_FAILURE,
  EDIT_MEMORY_REQUEST,
  EDIT_MEMORY_SUCCESS,
  EDIT_MEMORY_FAILURE
} from './types';

export const fetchMemoriesRequest = ({ token, type }) => ({
  type: FETCH_MEMORIES_REQUEST,
  token,
  memoryType: type
});

export const fetchMemoriesSuccess = memories => ({
  type: FETCH_MEMORIES_SUCCESS,
  memories
});

export const fetchMemoriesFailure = error => ({
  type: FETCH_MEMORIES_FAILURE,
  error
});

export const createMemoriesRequest = ({ token, formData, file, filename }) => ({
  type: CREATE_MEMORIES_REQUEST,
  token,
  formData,
  file,
  filename
});

export const createMemoriesSuccess = memory => ({
  type: CREATE_MEMORIES_SUCCESS,
  memory
});

export const createMemoriesFailure = error => ({
  type: CREATE_MEMORIES_FAILURE,
  error
});

export const deleteMemoryRequest = ({ token, feedId }) => ({
  type: DELETE_MEMORY_REQUEST,
  token,
  feedId
});

export const deleteMemorySuccess = success => ({
  type: DELETE_MEMORY_SUCCESS,
  success
});

export const deleteMemoryFailure = error => ({
  type: DELETE_MEMORY_FAILURE,
  error
});

export const editMemoryRequest = ({ token, caption, feedId }) => ({
  type: EDIT_MEMORY_REQUEST,
  token,
  caption,
  feedId
});

export const editMemorySuccess = success => ({
  type: EDIT_MEMORY_SUCCESS,
  success
});

export const editMemoryFailure = error => ({
  type: EDIT_MEMORY_FAILURE,
  error
});

export const batchDeleteRequest = ({ token, feedIds }) => ({
  type: BATCH_DELETE_REQUEST,
  token,
  feedIds
});

export const batchDeleteSuccess = success => ({
  type: BATCH_DELETE_SUCCESS,
  success
});

export const batchDeleteFailure = error => ({
  type: BATCH_DELETE_FAILURE,
  error
});

export const setSearchTerm = ({ searchTerm }) => ({
  type: SET_SEARCH_TERM,
  searchTerm
});

export const editMobileEventPhotoRequest = ({
  token,
  eventId,
  eventMobilePhoto,
  dayOfMessage,
  eventCode
}) => ({
  type: EDIT_MOBILE_PHOTO_REQUEST,
  token,
  eventId,
  eventMobilePhoto,
  dayOfMessage,
  eventCode
});

export const editMobileEventPhotoSuccess = mobilePhoto => ({
  type: EDIT_MOBILE_PHOTO_SUCCESS,
  mobilePhoto
});

export const editMobileEventPhotoFailure = error => ({
  type: EDIT_MOBILE_PHOTO_FAILURE,
  error
});

export const getModeratorsRequest = ({ token }) => ({
  type: GET_MODERATORS_REQUEST,
  token
});

export const getModeratorsSuccess = moderators => ({
  type: GET_MODERATORS_SUCCESS,
  moderators
});

export const getModeratorsFailure = error => ({
  type: GET_MODERATORS_FAILURE,
  error
});

export const addModeratorRequest = ({ token, email, firstname, lastname }) => ({
  type: ADD_MODERATOR_REQUEST,
  token,
  email,
  firstname,
  lastname
});

export const addModeratorSuccess = success => ({
  type: ADD_MODERATOR_SUCCESS,
  success
});

export const addModeratorFailure = error => ({
  type: ADD_MODERATOR_FAILURE,
  error
});

export const deleteModeratorRequest = ({ token, id }) => ({
  type: DELETE_MODERATOR_REQUEST,
  token,
  id
});

export const deleteModeratorSuccess = success => ({
  type: DELETE_MODERATOR_SUCCESS,
  success
});

export const deleteModeratorFailure = error => ({
  type: DELETE_MODERATOR_FAILURE,
  error
});
