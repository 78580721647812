import PROPOSAL_TYPES from './types';

const { GENERATE_PROPOSAL, GENERATE_CONTRACT } = PROPOSAL_TYPES;

const initialState = {
  generatingProposal: false,
  generatingContract: false
};

export const proposalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_PROPOSAL.REQUEST:
      return {
        ...state,
        generatingProposal: true
      };
    case GENERATE_PROPOSAL.SUCCESS:
      return {
        ...state,
        generatingProposal: false
      };
    case GENERATE_PROPOSAL.FAILURE:
      return {
        ...state,
        generatingProposal: false
      };
    case GENERATE_CONTRACT.REQUEST:
      return {
        ...state,
        generatingContract: true
      };
    case GENERATE_CONTRACT.SUCCESS:
      return {
        ...state,
        generatingContract: false
      };
    case GENERATE_CONTRACT.FAILURE:
      return {
        ...state,
        generatingContract: false
      };
    default:
      return {
        ...state
      };
  }
};
