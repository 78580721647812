import styled from 'styled-components';
import Colors from '../../styles/Colors';

const ModalLoaderStyles = styled.div`
  top: 0px;
  left: 0px;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: rgba(24, 24, 24, 0.8);
  transition: all 0.5s ease;
`;

ModalLoaderStyles.Header = styled.div`
  font-size: 25px;
  font-family: Lato;
  color: ${Colors.black11};
  text-align: center;
  font-weight: 600;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
`;

ModalLoaderStyles.Message = styled.div`
  font-size: 16px;
  font-family: Lato;
  margin: 0 2em;
  color: ${Colors.gray88};
  text-align: center;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

ModalLoaderStyles.Footer = styled.div`
  font-size: 1em;
  font-family: Lato;
  color: ${Colors.gray88};
  text-align: center;
  margin: 0 2em;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ModalLoaderStyles;
