import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
  EVENT_FAILURE,
  EVENT_REQUEST,
  EVENT_SUCCESS,
  NOTES_FAILURE,
  NOTES_REQUEST,
  NOTES_SUCCESS,
  NOTE_CREATE_FAILURE,
  NOTE_CREATE_REQUEST,
  NOTE_CREATE_SUCCESS,
  NOTE_DELETE_FAILURE,
  NOTE_DELETE_REQUEST,
  NOTE_DELETE_SUCCESS,
  CLOUD_IMAGE_UPLOAD_REQUEST,
  CLOUD_IMAGE_UPLOAD_FAILURE,
  CLOUD_IMAGE_UPLOAD_SUCCESS,
  EVENT_PHOTO_EDIT_REQUEST,
  EVENT_PHOTO_EDIT_SUCCESS,
  EVENT_PHOTO_EDIT_FAILURE,
  EVENT_IDEA_DELETE_REQUEST,
  EVENT_IDEA_DELETE_FAILURE,
  EVENT_IDEA_DELETE_SUCCESS,
  IDEAS_REQUEST,
  IDEAS_SUCCESS,
  IDEAS_FAILURE,
  EVENT_IDEA_CREATE_REQUEST,
  EVENT_IDEA_CREATE_SUCCESS,
  EVENT_IDEA_CREATE_FAILURE,
  EVENT_IDEA_EDIT_REQUEST,
  EVENT_IDEA_EDIT_SUCCESS,
  EVENT_IDEA_EDIT_FAILURE,
  VENUE_STAFF_REQUEST,
  VENUE_STAFF_SUCCESS,
  VENUE_STAFF_FAILURE,
  EVENT_CONTACTS_UPDATE_REQUEST,
  EVENT_CONTACTS_UPDATE_SUCCESS,
  EVENT_CONTACTS_UPDATE_FAILURE,
  NOTE_EDIT_REQUEST,
  NOTE_EDIT_FAILURE,
  NOTE_EDIT_SUCCESS,
  PROFILE_AVATAR_EDIT_REQUEST,
  PROFILE_AVATAR_EDIT_FAILURE,
  PROFILE_AVATAR_EDIT_SUCCESS
} from './types';

import {
  getEventDetails,
  getEventNotes,
  createEventNote,
  deleteEventNote,
  editEventPhoto,
  getEventIdeas,
  createEventIdea,
  editEventIdea,
  deleteEventIdea,
  getEventStaff,
  updateEventContacts,
  uploadImageToCloud,
  editEventNote,
  editProfileAvatar
} from './actions';

import { closeAddContactModal } from './operations';

const getToken = state => state.loginReducer.token;
const getUserId = state => state.loginReducer.uid;

function* workerEvent({ token, eventId }) {
  try {
    const response = yield call(getEventDetails, { token, eventId });

    const { event } = response.data;
    yield put({
      type: EVENT_SUCCESS,
      event
    });
  } catch (error) {
    yield put({ type: EVENT_FAILURE, ...error.response.data });
  }
}

function* workerNotes({ token, eventId }) {
  try {
    const response = yield call(getEventNotes, { token, eventId });

    const { notes } = response.data;

    yield put({
      type: NOTES_SUCCESS,
      notes
    });
  } catch (error) {
    yield put({ type: NOTES_FAILURE, ...error.response.data });
  }
}

function* workerCreateNote({ token, text, eventId }) {
  try {
    const response = yield call(createEventNote, { token, text, eventId });

    const { note } = response.data;

    yield put({
      type: NOTE_CREATE_SUCCESS,
      note
    });
    yield put({
      type: NOTES_REQUEST,
      token,
      eventId
    });
  } catch (error) {
    yield put({ type: NOTE_CREATE_FAILURE, ...error.response.data });
  }
}

function* workerEditNote({ token, text, noteId, eventId }) {
  try {
    const response = yield call(editEventNote, { token, text, noteId });

    const { note } = response.data;

    yield put({
      type: NOTE_EDIT_SUCCESS,
      note
    });
    yield put({
      type: NOTES_REQUEST,
      token,
      eventId
    });
  } catch (error) {
    yield put({ type: NOTE_EDIT_FAILURE, ...error.response.data });
  }
}

function* workerDeleteNote({ token, noteId }) {
  try {
    const response = yield call(deleteEventNote, { token, noteId });

    yield put({
      type: NOTE_DELETE_SUCCESS,
      noteId
    });
  } catch (error) {
    yield put({ type: NOTE_DELETE_FAILURE, ...error.response.data });
  }
}

function* workerEditEventPhoto({ token, eventId, photo }) {
  try {
    const response = yield call(editEventPhoto, { token, eventId, photo });

    const {
      event,
      event: { contacts }
    } = response.data;

    yield put({
      type: EVENT_PHOTO_EDIT_SUCCESS,
      event,
      contacts: contacts || []
    });
  } catch (error) {
    yield put({ type: EVENT_PHOTO_EDIT_FAILURE, ...error.response.data });
  }
}

function* workerUploadImageToCloud({ fileToUpload, filename, uploadType }) {
  try {
    const token = yield select(getToken);
    let imageUpload;
    if (fileToUpload && fileToUpload.length > 1) {
      const responseThumbnail = yield call(uploadImageToCloud, {
        token,
        fileToUpload: fileToUpload[0],
        filename: `thumbnail_${filename}`,
        uploadType
      });
      const responseOriginal = yield call(uploadImageToCloud, {
        token,
        fileToUpload: fileToUpload[1],
        filename,
        uploadType
      });

      const { data: thumbnailData } = responseThumbnail && responseThumbnail.data;
      const { data: originalData } = responseOriginal && responseOriginal.data;

      imageUpload = {
        imageURLs: [thumbnailData && thumbnailData.key, originalData && originalData.key],
        uploadType
      };
    } else {
      const response = yield call(uploadImageToCloud, {
        token,
        fileToUpload: fileToUpload[0],
        filename: `thumbnail_${filename}`,
        uploadType
      });
      const { data } = response.data;

      imageUpload = {
        imageURLs: [data && data.key],
        uploadType
      };
    }

    yield put({
      type: CLOUD_IMAGE_UPLOAD_SUCCESS,
      imageUpload
    });
  } catch (error) {
    if (error.response) {
      yield put({ type: CLOUD_IMAGE_UPLOAD_FAILURE, ...error.response.data });
    }
  }
}

function* workerIdeas({ token }) {
  try {
    const response = yield call(getEventIdeas, { token });

    const { ideas } = response.data;

    yield put({
      type: IDEAS_SUCCESS,
      ideas
    });
  } catch (error) {
    yield put({ type: IDEAS_FAILURE, ...error.response.data });
  }
}

function* workerCreateIdea({ token, url, thumbnail, caption, eventId }) {
  try {
    const response = yield call(createEventIdea, { token, url, thumbnail, caption, eventId });

    const { idea } = response.data;

    yield put({
      type: EVENT_IDEA_CREATE_SUCCESS,
      idea
    });
  } catch (error) {
    yield put({ type: EVENT_IDEA_CREATE_FAILURE, ...error.response.data });
  }
}

function* workerEditEventIdea({ token, ideaId, caption }) {
  try {
    const response = yield call(editEventIdea, { token, ideaId, caption });

    const { idea } = response.data;

    yield put({
      type: EVENT_IDEA_EDIT_SUCCESS,
      idea
    });
  } catch (error) {
    yield put({ type: EVENT_IDEA_EDIT_FAILURE, ...error.response.data });
  }
}

function* workerDeleteEventIdea({ token, ideaId }) {
  try {
    const response = yield call(deleteEventIdea, { token, ideaId });

    yield put({
      type: EVENT_IDEA_DELETE_SUCCESS,
      ideaId
    });
  } catch (error) {
    yield put({ type: EVENT_IDEA_DELETE_FAILURE, ...error.response.data });
  }
}

function* workerVenueStaff({ token, venueId }) {
  try {
    const response = yield call(getEventStaff, { token, venueId });

    const { users: staff } = response.data;

    yield put({
      type: VENUE_STAFF_SUCCESS,
      staff
    });
  } catch (error) {
    yield put({ type: VENUE_STAFF_FAILURE, ...error.response.data });
  }
}

function* workerUpdateEventContacts({ token, eventId, contacts }) {
  try {
    const response = yield call(updateEventContacts, { token, eventId, contacts });

    const {
      event: { contacts: updatedContacts }
    } = response.data;

    yield put(closeAddContactModal());
    yield put({
      type: EVENT_CONTACTS_UPDATE_SUCCESS,
      contacts: updatedContacts
    });
  } catch (error) {
    yield put({ type: EVENT_CONTACTS_UPDATE_FAILURE, ...error.response.data });
  }
}

function* workerEditProfileAvatar({ userId, photo }) {
  try {
    const token = yield select(getToken);
    const response = yield call(editProfileAvatar, { token, userId, photo });

    const { user } = response.data;

    yield put({
      type: PROFILE_AVATAR_EDIT_SUCCESS,
      photo: user.photo
    });
  } catch (error) {
    yield put({ type: PROFILE_AVATAR_EDIT_FAILURE, ...error.response.data });
  }
}

export function* watcherEvent() {
  yield takeLatest(EVENT_REQUEST, workerEvent);
}

export function* watcherNotes() {
  yield takeLatest(NOTES_REQUEST, workerNotes);
}

export function* watcherCreateNote() {
  yield takeLatest(NOTE_CREATE_REQUEST, workerCreateNote);
}

export function* watcherEditNote() {
  yield takeLatest(NOTE_EDIT_REQUEST, workerEditNote);
}

export function* watcherDeleteNote() {
  yield takeLatest(NOTE_DELETE_REQUEST, workerDeleteNote);
}

export function* watcherUploadImageToCloud() {
  yield takeLatest(CLOUD_IMAGE_UPLOAD_REQUEST, workerUploadImageToCloud);
}

export function* watcherEditEventPhoto() {
  yield takeLatest(EVENT_PHOTO_EDIT_REQUEST, workerEditEventPhoto);
}

export function* watcherIdeas() {
  yield takeLatest(IDEAS_REQUEST, workerIdeas);
}

export function* watcherCreateIdea() {
  yield takeLatest(EVENT_IDEA_CREATE_REQUEST, workerCreateIdea);
}

export function* watcherEditEventIdea() {
  yield takeLatest(EVENT_IDEA_EDIT_REQUEST, workerEditEventIdea);
}

export function* watcherDeleteEventIdea() {
  yield takeLatest(EVENT_IDEA_DELETE_REQUEST, workerDeleteEventIdea);
}

export function* watcherVenueStaff() {
  yield takeLatest(VENUE_STAFF_REQUEST, workerVenueStaff);
}

export function* watcherUpdateEventContacts() {
  yield takeLatest(EVENT_CONTACTS_UPDATE_REQUEST, workerUpdateEventContacts);
}

export function* watcherEditProfileAvatar() {
  yield takeLatest(PROFILE_AVATAR_EDIT_REQUEST, workerEditProfileAvatar);
}
