import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export function getGuests({ token, eventId, orderBy, order, venueDownload }) {
  let url = `${apiUri}/guests?`;

  if (orderBy) {
    url = `${url}&orderBy=${orderBy}&order=${order}`;
  }

  if (eventId) {
    url = `${url}&eventId=${eventId}`;
  }

  if (venueDownload) {
    url = `${url}&venueDownload=${venueDownload}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: token }
  });
}

export function addGuest({ token, data }) {
  return axios({
    method: 'post',
    url: `${apiUri}/guests`,
    headers: { Authorization: token },
    data
  });
}

export function editGuest({ token, guestId, data }) {
  return axios({
    method: 'put',
    url: `${apiUri}/guests/${guestId}`,
    headers: { Authorization: token },
    data: {
      ...data,
      guestId
    }
  });
}

export function deleteGuest({ token, guestId }) {
  return axios({
    method: 'delete',
    url: `${apiUri}/guests/${guestId}`,
    headers: { Authorization: token }
  });
}

export function generateExcelFile({ token, eventId, venueId, layoutId }) {
  return axios({
    method: 'get',
    url: `${apiUri}/guests/generateExcelFile?&eventId=${eventId}&venueId=${venueId}&layoutId=${layoutId}`,
    headers: { Authorization: token }
  });
}

export function generateVendorListExcelFile({ token, eventId, venueId, layoutId }) {
  return axios({
    method: 'get',
    url: `${apiUri}/vendors/vendorListExcelFile?&eventId=${eventId}`,
    headers: { Authorization: token }
  });
}
