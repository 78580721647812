import {
  GET_TABLES_REQUEST,
  GET_TABLES_SUCCESS,
  GET_TABLES_FAILURE,
  ADD_TABLE_REQUEST,
  ADD_TABLE_SUCCESS,
  UPDATE_TABLE_SUCCESS,
  UPDATE_TABLE_REQUEST,
  DELETE_TABLE_REQUEST,
  DELETE_TABLE_SUCCESS,
  RESET_ALERTS,
  UPDATE_TABLE_CATEGORY_REQUEST,
  UPDATE_TABLE_CATEGORY_SUCCESS
} from './types';

const initialState = {
  isFetching: false,
  error: null,
  tables: [],
  categories: [],
  types: [],
  tableAddedSuccess: false,
  tableUpdatedSuccess: false,
  tableDeletedSuccess: false,
  isCategoryLoading: false
};

export const tablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TABLES_REQUEST:
      return {
        ...state,
        isFeching: true,
        error: null
      };
    case GET_TABLES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        tables: action.tables,
        categories: action.categories,
        types: action.types
      };
    case GET_TABLES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case ADD_TABLE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        tableAddedSuccess: false,
        tableUpdatedSuccess: false
      };
    case ADD_TABLE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        tableAddedSuccess: true
      };
    case UPDATE_TABLE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        tableAddedSuccess: false,
        tableUpdatedSuccess: false
      };
    case UPDATE_TABLE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        tableUpdatedSuccess: true
      };
    case DELETE_TABLE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        tableAddedSuccess: false,
        tableUpdatedSuccess: false,
        tableDeletedSuccess: false
      };
    case DELETE_TABLE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        tableDeletedSuccess: true
      };
    case RESET_ALERTS:
      return {
        ...state,
        tableUpdatedSuccess: false,
        tableAddedSuccess: false,
        tableDeletedSuccess: false
      };
    case UPDATE_TABLE_CATEGORY_REQUEST:
      return {
        ...state,
        isCategoryLoading: true
      };
    case UPDATE_TABLE_CATEGORY_SUCCESS:
      return {
        ...state,
        isCategoryLoading: false
      };
    default:
      return state;
  }
};
