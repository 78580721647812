import React from 'react';
import { useHistory } from 'react-router-dom';
import { Text, Icon, Image, useDisclosure, Flex, Spinner } from '@chakra-ui/react';
import { ButtonDropdown } from 'src/v2/UIComponents/Primitives';
import { useUser } from 'src/v2/Hooks/CollectionHooks';
import { HiLogout } from 'react-icons/hi';
import { MdCameraAlt, MdVpnKey } from 'react-icons/md';
import { Modal } from 'src/v2/UIComponents/Modal';
import ProfileDropdown from 'src/components/ProfileDropdown';
import Color from 'src/styles/Colors';

import config from 'src/.env.json';
import { GrowingButton } from '../../Animated/animated';

const { bucketUri } = config;

export const ProfileButton = () => {
  const history = useHistory();
  const {
    userId,
    firstname,
    lastname,
    photo,
    doLogout,
    token,
    uploadStatus,
    doEditProfileAvatar,
    doUploadImageToCloud
  } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const firstnameInitial = firstname.charAt(0);
  const lastnameInitial = lastname.charAt(0);
  const initials = `${firstnameInitial}${lastnameInitial}`;
  const fullName = `${firstname} ${lastname}`;
  const profileDropdownTitle = `Hi, ${fullName}`;

  const user = {
    userId,
    firstname,
    lastname,
    photo,
    token
  };

  const logout = () => {
    doLogout();
    history.push('/login');
  };

  const editProfileAvatar = (filename, fileToUpload) => {
    doUploadImageToCloud({
      token,
      fileToUpload: [fileToUpload],
      filename,
      uploadType: 'avatar'
    });
  };

  const removeProfileAvatar = () => {
    doEditProfileAvatar({ token, userId, photo: null });
  };

  const profileDropdownItems = [
    {
      label: 'Edit Profile Image',
      icon: <Icon as={MdCameraAlt} w={6} h={6} color="var(--colors-primary)" />,
      onClick: onOpen
    },
    {
      label: 'Change Password',
      icon: <Icon as={MdVpnKey} w={6} h={6} color="var(--colors-primary)" />,
      onClick: () => {}
    },
    {
      label: 'Log Out',
      icon: <Icon as={HiLogout} w={6} h={6} color="var(--colors-primary)" />,
      onClick: logout
    }
  ];

  const footerOptions = {
    leftButton: {
      label: 'Remove Picture',
      color: Color.gray7D
    },
    rightButton: {
      label: 'Go Back',
      onClick: onClose,
      color: 'var(--colors-primary)'
    }
  };

  return (
    <>
      <ProfileDropdown
        user={user}
        iconType="avatar"
        logout={logout}
        removeProfileAvatar={removeProfileAvatar}
        requestStatus={uploadStatus}
      />
      {/* <ButtonDropdown title={profileDropdownTitle} items={profileDropdownItems} shape="round">
        {photo ? (
          <Image alt={fullName} borderRadius="full" src={`${bucketUri}${photo}`} loading="eager" />
        ) : (
          <Text fontWeight="600" textTransform="uppercase" fontSize="15px" textAlign="center">
            {initials}
          </Text>
        )}
      </ButtonDropdown>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Edit Profile Picture"
        showFooter
        footerOptions={footerOptions}
      >
        <Flex justifyContent="center">
          <Image
            borderRadius="full"
            boxSize="150px"
            src={`${bucketUri}${photo}`}
            alt={fullName}
            loading="eager"
          />
        </Flex>
      </Modal> */}
    </>
  );
};
