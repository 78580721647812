import styled from 'styled-components';
import HexToRGB from '../../styles/HexToRGB';
import Screens from '../../styles/Screens';
import Colors from '../../styles/Colors';

const LoginStyles = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${props => (props.bgColor ? props.bgColor : `var(--colors-bgLoginSection)`)};
`;

LoginStyles.Card = styled.div`
  width: calc(100% - 48px);
  padding: 24px;
  border-radius: 2px;
  box-shadow: 0 0 20px 0 rgba(${HexToRGB(Colors.black, true)}, 0.2);
  background-color: ${props => (props.bgColor ? props.bgColor : `var(--colors-bgLoginCard)`)};

  input {
    width: calc(100% - 20px);
    margin-bottom: 20px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    background-color: ${props => (props.bgColor ? props.bgColor : `var(--colors-bgLoginSection)`)};
  }
  button {
    width: 100%;
  }

  a {
    display: block;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }

  @media (min-width: ${Screens.small}) {
    padding: 48px;
    width: 480px;

    button {
      width: auto;
      margin-right: 20px;
    }

    a {
      display: inline;
      height: auto;
      line-height: auto;
      text-align: left;
    }
  }
`;

LoginStyles.WelcomeMessage = styled.p`
  font-family: Lora;
  color: #4a4a4a;
`;

LoginStyles.CardHeader = styled.div`
  display: flex;
  background: ${props => (props.color ? props.color : `var(--colors-bgLoginCard)`)};
  background-image: url(${props =>
    props.logo || 'https://prod-venuex-images.s3.us-east-2.amazonaws.com/Placeholder.png'});
  background-position: center;
  background-size: var(--logoSize);
  background-repeat: no-repeat;
  height: 200px;
  align-items: middle;
  justify-content: center;
  margin-bottom: 20px;

  h1 {
    margin: 0;
    line-height: 200px;
  }
`;

LoginStyles.Error = styled.div`
  transition: all 250ms ease-in-out 0s;
  color: ${Colors.redC0};
  font-size: 11px;
  font-weight: bold;
  line-height: 22px;
  height: ${props => (props.show ? '22px' : '0px')};
  margin-bottom: 20px;
`;

export default LoginStyles;
