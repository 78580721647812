import { FormControl } from '@chakra-ui/react';
import styled, { css } from 'styled-components';
import { FormLabel } from '../../Primitives';

type Props = {
  isHorizontalControl?: boolean;
  isFullWidth?: boolean;
};

export const StyledFormControl = styled(FormControl)<Props & { isFullWidth?: boolean }>`
  ${({ isHorizontalControl }) =>
    isHorizontalControl &&
    css`
      && {
        display: grid;
        grid-template-columns: max-content 1fr;
        align-items: center;
        grid-column-gap: 25px;
      }
    `}

  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      && {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    `}
`;

export const StyledFormLabel = styled(FormLabel)<Props>`
  ${({ isHorizontalControl }) =>
    isHorizontalControl &&
    css`
      && {
        margin-bottom: 0;
      }
    `}
`;
