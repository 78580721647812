import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  GET_CUSTOM_OBJECTS_REQUEST,
  ADD_CUSTOM_OBJECTS_REQUEST,
  UPDATE_CUSTOM_OBJECTS_REQUEST,
  DELETE_CUSTOM_OBJECTS_REQUEST,
  UPDATE_CUSTOM_OBJECTS_CATEGORY_REQUEST
} from './types';
import {
  getCustomObjectsAction,
  addCustomObjectsAction,
  updateCustomObjectsAction,
  deleteCustomObjectsAction,
  updateCategoryAction
} from './actions';
import {
  getCustomObjectsSuccess,
  getCustomObjectsFailure,
  addCustomObjectsSuccess,
  addCustomObjectsFailure,
  updateCustomObjectsSuccess,
  updateCustomObjectsFailure,
  deleteCustomObjectsSuccess,
  deleteCustomObjectsFailure,
  getCustomObjectsRequest,
  updateCategorySuccess
} from './operations';

const getToken = state => state.loginReducer.token;

function* workerGetCustomObjects() {
  try {
    const token = yield select(getToken);
    const response = yield call(getCustomObjectsAction, { token });
    if (response.data.success) {
      const { objects, categories, types } = response.data;
      yield put(getCustomObjectsSuccess({ objects, categories, types }));
    }
  } catch (error) {
    yield put(getCustomObjectsFailure({ error }));
  }
}

function* workerAddCustomObject({ shape }) {
  try {
    const token = yield select(getToken);
    const response = yield call(addCustomObjectsAction, { shape, token });
    if (response.data.success) {
      yield put(addCustomObjectsSuccess({ table: response.data.table }));
    }
  } catch (error) {
    yield put(addCustomObjectsFailure({ error }));
  }
}

function* workerUpdateCustomObject({ id, shape }) {
  try {
    const token = yield select(getToken);
    const response = yield call(updateCustomObjectsAction, { id, shape, token });
    if (response.data.success) {
      yield put(updateCustomObjectsSuccess({ table: response.data.table }));
    }
  } catch (error) {
    yield put(updateCustomObjectsFailure({ error }));
  }
}

function* workerDeleteCustomObject({ id }) {
  try {
    const token = yield select(getToken);
    const response = yield call(deleteCustomObjectsAction, { id, token });
    if (response.data.success) {
      yield put(deleteCustomObjectsSuccess());
    }
  } catch (error) {
    yield put(deleteCustomObjectsFailure({ error }));
  }
}

function* workerUpdateCustomObjectCategory({ data }) {
  try {
    const token = yield select(getToken);
    const response = yield call(updateCategoryAction, { data, token });
    if (response.data.success) {
      yield put(getCustomObjectsRequest());
      yield put(updateCategorySuccess());
    }
  } catch (error) {
    // yield put(updateTableFailure({ error }));
  }
}

export function* watchGetCustomObjectsRequest() {
  yield takeLatest(GET_CUSTOM_OBJECTS_REQUEST, workerGetCustomObjects);
}

export function* watchAddCustomObjectRequest() {
  yield takeLatest(ADD_CUSTOM_OBJECTS_REQUEST, workerAddCustomObject);
}

export function* watchUpdateCustomObjectRequest() {
  yield takeLatest(UPDATE_CUSTOM_OBJECTS_REQUEST, workerUpdateCustomObject);
}

export function* watchDeleteCustomObjectRequest() {
  yield takeLatest(DELETE_CUSTOM_OBJECTS_REQUEST, workerDeleteCustomObject);
}

export function* watchUpdateCustomObjectCategory() {
  yield takeLatest(UPDATE_CUSTOM_OBJECTS_CATEGORY_REQUEST, workerUpdateCustomObjectCategory);
}
