import { Icon } from '@chakra-ui/react';

export const Calendar = props => {
  const { width = 6, height = 6 } = props;

  return (
    <Icon
      width={width}
      height={height}
      viewBox="0 0 27 27"
      color="var(--colors-primary)"
      {...props}
    >
      <path
        d="M21.0525 3.06751H22.8238C24.91 3.06751 26.6075 4.76377 26.6088 6.85127V22.825C26.6088 24.9125 24.9113 26.61 22.8238 26.61H4.76876C2.68126 26.61 0.983765 24.9125 0.983765 22.825V6.85252C0.983765 4.76502 2.68126 3.06751 4.76876 3.06751H6.53876V0.983765H8.55252V3.06751H19.0388V0.983765H21.0513V3.06751H21.0525ZM2.99751 10.6363V22.8238C2.99751 23.7988 3.79251 24.5938 4.76876 24.5938H22.825C23.8 24.5938 24.595 23.7988 24.595 22.8238V10.6363H2.99751ZM24.5938 6.85127C24.5938 5.87627 23.8 5.08126 22.8238 5.08126H21.0525V6.47002H19.0375V5.08251H8.55252V6.47002H6.54001V5.07876H4.76876C3.79251 5.07876 2.99751 5.87377 2.99751 6.85002V8.62127H24.5938V6.85127Z"
        fill="currentColor"
      />
    </Icon>
  );
};
