import styled from 'styled-components';

const AddChecklistModalStyles = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

AddChecklistModalStyles.Header = styled.div`
  display: flex;
  justify-content: center;
  min-height: 110px;
  z-index: 10;
  align-items: center;
  font-family: Lora;
  font-size: 24px;
  letter-spacing: -0.6px;
  text-transform: capitalize;
  text-align: center;
  color: #181818;
  background-color: var(--colors-modalHeader);
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

AddChecklistModalStyles.Close = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
`;

AddChecklistModalStyles.Body = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  flex-grow: 1;
  padding: 5px 0px;
`;

AddChecklistModalStyles.BodyRow = styled.div`
  display: flex;
  min-height: 50px;
  align-items: ${props => props.alignItems || 'center'};
  padding: 0px 50px;
  font-family: Lato;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.28px;
  margin-bottom: ${props => props.marginBottom || '20px'};
  color: #222222;
`;

AddChecklistModalStyles.Key = styled.div`
  display: flex;
  font-family: Lato;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.28px;
  margin-right: ${props => props.marginRight || '25px'};
  color: #7d7d7d;
`;

AddChecklistModalStyles.Values = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Lato;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.28px;
  color: #7d7d7d;
  width: 100%;
`;

AddChecklistModalStyles.Span = styled.span`
  display: flex;
  margin-bottom: 20px;
  align-items: ${props => props.alignItems || 'flex-start'};

  input {
    margin-left: 10px;
  }
`;

AddChecklistModalStyles.DueDate = styled.span`
  margin-left: 10px;
  color: #181818;
`;

AddChecklistModalStyles.Option = styled.span`
  display: flex;

  > div {
    margin: 0px 20px 0px 5px;
  }
`;

AddChecklistModalStyles.Footer = styled.div`
  display: flex;
  justify-content: center;
  border-top: solid 1px #d8d8d8;
  align-items: center;
  padding: 0px 15px;
  min-height: 75px;

  button {
    margin: 0px 10px;
  }
`;

AddChecklistModalStyles.FooterLeft = styled.div``;

AddChecklistModalStyles.FooterRight = styled.div``;

export default AddChecklistModalStyles;
