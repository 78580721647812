import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  GET_TABLES_REQUEST,
  ADD_TABLE_REQUEST,
  UPDATE_TABLE_REQUEST,
  DELETE_TABLE_REQUEST,
  UPDATE_TABLE_CATEGORY_REQUEST
} from './types';
import {
  getTablesAction,
  addTableAction,
  updateTableAction,
  deleteTableAction,
  updateCategoryAction
} from './actions';
import {
  getTablesSuccess,
  getTablesFailure,
  addTableFailure,
  addTableSuccess,
  updateTableSuccess,
  updateTableFailure,
  deleteTableSuccess,
  deleteTableFailure,
  getTablesRequest,
  updateCategorySuccess
} from './operations';

const getToken = state => state.loginReducer.token;

function* workerGetTables() {
  try {
    const token = yield select(getToken);
    const response = yield call(getTablesAction, { token });
    if (response.data.success) {
      yield put(
        getTablesSuccess({
          tables: response.data.tables,
          categories: response.data.categories,
          types: response.data.types
        })
      );
    }
  } catch (error) {
    yield put(getTablesFailure({ error }));
  }
}

function* workerAddTable({ table }) {
  try {
    const token = yield select(getToken);
    const response = yield call(addTableAction, { table, token });
    if (response.data.success) {
      yield put(addTableSuccess({ table: response.data.table }));
    }
  } catch (error) {
    yield put(addTableFailure({ error }));
  }
}

function* workerUpdateTable({ id, table }) {
  try {
    const token = yield select(getToken);
    const response = yield call(updateTableAction, { id, table, token });
    if (response.data.success) {
      yield put(updateTableSuccess({ table: response.data.table }));
    }
  } catch (error) {
    yield put(updateTableFailure({ error }));
  }
}

function* workerDeleteTable({ id }) {
  try {
    const token = yield select(getToken);
    const response = yield call(deleteTableAction, { id, token });
    if (response.data.success) {
      yield put(deleteTableSuccess());
    }
  } catch (error) {
    yield put(deleteTableFailure({ error }));
  }
}

function* workerUpdateTableCategory({ data }) {
  try {
    const token = yield select(getToken);
    const response = yield call(updateCategoryAction, { data, token });
    if (response.data.success) {
      yield put(getTablesRequest());
      yield put(updateCategorySuccess());
    }
  } catch (error) {
    // yield put(updateTableFailure({ error }));
  }
}

export function* watchGetTablesRequest() {
  yield takeLatest(GET_TABLES_REQUEST, workerGetTables);
}

export function* watchAddTableRequest() {
  yield takeLatest(ADD_TABLE_REQUEST, workerAddTable);
}

export function* watchUpdateTableRequest() {
  yield takeLatest(UPDATE_TABLE_REQUEST, workerUpdateTable);
}

export function* watchDeleteTableRequest() {
  yield takeLatest(DELETE_TABLE_REQUEST, workerDeleteTable);
}

export function* watchUpdateTablesCategory() {
  yield takeLatest(UPDATE_TABLE_CATEGORY_REQUEST, workerUpdateTableCategory);
}
