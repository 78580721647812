import styled from 'styled-components';

export default styled.input.attrs(
  ({ type = 'file', accept = '.csv,.xls,.xlsx', disabled = false, value = '', props }) => ({
    ...props,
    type,
    accept,
    disabled,
    value
  })
)`
  display: none;
  border: none;
`;
