import { useSelector } from 'react-redux';
import { useUser } from 'src/v2/Hooks/CollectionHooks/User/useUser';
import { useActions } from 'src/hooks/useActions';
import { getRoomsRequest } from 'src/containers/RoomsContainer/operations';

/**
 * Hooks to access the FireStore rooms collection for this venue
 * @returns {Object}
 *
 * **ACTIONS**
 * - **getRooms** (Function): Fires getRoomsRequest() with token.
 * **STATES**
 * - **fetchingFireStore** (Boolean): Whether it is fetching the venue object from firestore
 * - **fetchingRTDB** (Boolean): Whether it is fetching the venue object from Real Time Database
 * - **staffUsers** (Array of Objects): Array of staff objects
 */
export const useRooms = () => {
  const { token } = useUser();
  const roomsState = useSelector(state => state.roomsReducer);
  const { fetching } = roomsState;
  const roomOptions =
    roomsState &&
    roomsState.rooms &&
    roomsState.rooms.map(room => ({
      value: room.id,
      label: room.name
    }));

  // TEMPORARY SO WE DON'T BREAK STAGING

  const actions = useActions({
    getRooms: () => getRoomsRequest({ token })
  });

  return {
    roomOptions,
    roomsState,
    fetching,
    ...actions
  };
};
