import { HStack } from '@chakra-ui/react';

import {
  AlternateLayoutsInput,
  EventCodeInput,
  EventConsultantInput,
  EventTeamInput,
  EventTitleInput,
  GuestMinimumInput,
  LayoutInput,
  PhotoSharingInput
} from 'src/v2/Drawers/EventFormDrawer/Inputs';
import { EventType, EventDateInput, RoomInput, EventEnd } from 'src/v2/SharedComponents/inputs';
import { FormInputsWrapper } from 'src/v2/UIComponents/FormComponents';
import { useVenue, useUser } from 'src/v2/Hooks/CollectionHooks';
import { ContentTitle } from './ContentTitle';

export const EventDetailsTab = ({ bookEvent, mode }) => {
  const { featureFlags } = useVenue();
  const { guestMobileApp } = featureFlags;
  return (
    <FormInputsWrapper handleSubmit={undefined}>
      <ContentTitle title="Event Team" />
      <EventConsultantInput bookEvent={bookEvent} />
      <EventTeamInput bookEvent={bookEvent} />
      <ContentTitle title="Event Info" />
      <HStack>
        <RoomInput bookEvent={bookEvent} />
        <LayoutInput bookEvent={bookEvent} />
      </HStack>
      <EventType bookEvent={bookEvent} />
      <AlternateLayoutsInput />

      <EventTitleInput mode={mode} bookEvent={bookEvent} />

      <HStack>
        <EventDateInput bookEvent={bookEvent} />
        <EventEnd bookEvent={bookEvent} />
      </HStack>
      <GuestMinimumInput bookEvent={bookEvent} />
      {guestMobileApp && (
        <>
          <ContentTitle title="Guest App" />
          <PhotoSharingInput />
          <EventCodeInput />
        </>
      )}
    </FormInputsWrapper>
  );
};
