import styled, { keyframes, css } from 'styled-components';
import Colors from '../Colors';

const colorPulse = keyframes`
     0%   { opacity: 0; }
    100% { opacity: 1; }
`;

const pulse = keyframes`
    0%{background-position:100% 0%}
    100%{background-position:0% 100%}
`;

const animation = css`
  ${pulse} 2s linear infinite;
`;

const colorPulseAnimate = css`
  ${colorPulse} 400ms 1
`;

const ButtonStyles = styled.button.attrs(props => ({
  type: props.type || 'button',
  disabled: props.disabled || false
}))`
  display; flex;
  flex-grow: 1;
  border: none;
  height: ${props => (props.height ? props.height : '50px')};
  border-radius: 4px;
  border: ${props => (props.noBorder ? 'none' : 'solid 1px #ededed')};
  box-shadow: ${props => (props.disableShadow ? null : '0 2px 4px 0 rgba(125, 125, 125, 0.2)')};
  background-color: ${props => props.bgColor || `var(--colors-primary);`};
  color: ${props =>
    (props.color && props.color) || (props.primary && `var(--colors-primary)`) || Colors.white};
  padding-left: ${props =>
    ((props.hasIcon || props.loading) && !props.iconRight && !props.smallIcon && '45px') ||
    (props.smallIcon && props.hasIcon && !props.iconRight && '0px') ||
    props.padding ||
    '15px'};
  padding-right: ${props =>
    ((props.hasIcon || props.loading) && props.iconRight && !props.smallIcon && '45px') ||
    (props.smallIcon && props.hasIcon && props.iconRight && '0px') ||
    props.padding ||
    '15px'};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  cursor: ${props => props.disabled && 'not-allowed'};
  width: ${props => props.width || ''};
  animation: ${props => (props.animate ? animation : '')};
  ${props =>
    props.animate
      ? `background: linear-gradient(to right, #ffffff, var(--colors-primary-lighter) , var(--colors-primary-light), var(--colors-primary-lighter), #ffffff);
    background-size: 1000% 100%;`
      : ''};
  ${props =>
    props.smallIcon
      ? 'display: flex; align-items: center; font-size: 11.9px; justify-content: space-around;'
      : ''};
    
  svg {
    position: ${props => (props.smallIcon ? '' : 'absolute')};
    left: ${props => (props.iconRight && 'auto') || (props.width === '100%' ? '480px' : '15px')};
    right: ${props => (!props.iconRight && 'auto') || (props.width === '100%' ? '480px' : '15px')};
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  &:hover {
    cursor: ${props => props.disabled && 'not-allowed'};
    border: ${props =>
      !props.disabled && props.animate && '1px solid var(--colors-primary-lightest)'};
    transition: all 400ms ease-in-out;
    svg{
      ${props => props.animateSVG && `transform: scale(1.2);`}
    }
  }
  &:focus {
    outline: 'none';
  }
  &:active {

    svg, path{
      ${props => props.animateSVG && `opacity: 0.75`}
     
    }
  }
`;

export default ButtonStyles;
