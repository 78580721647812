import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export const getRoomsAction = ({ token }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/rooms`,
    headers: { Authorization: token }
  });
};
