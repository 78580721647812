import React from 'react';
import { useDocuments } from 'src/v2/Hooks/CollectionHooks';
import { Button, Stack } from '@chakra-ui/react';
import { AddPlus } from 'src/v2/UIComponents/Primitives/Icons';
import { useDrawers } from '../../../../../Hooks/HelperHooks/DrawerHooks/useDrawers';

export const DocumentActions = () => {
  const { openEventDocumentDrawer } = useDrawers();

  return (
    <Stack direction="row" justifyContent="end" marginBottom="16px">
      <Button
        variant="primary"
        leftIcon={<AddPlus color="white" w={4} h={4} />}
        paddingX="20px"
        onClick={() => openEventDocumentDrawer()}
      >
        Upload Document
      </Button>
    </Stack>
  );
};
