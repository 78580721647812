import React, { useEffect } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Box,
  Text
} from '@chakra-ui/react';
import { useConfirm } from 'src/v2/Hooks/HelperHooks';
import { useEvents, useUser } from 'src/v2/Hooks/CollectionHooks';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import Colors from 'src/styles/Colors';
import { Button } from '../Primitives';
import { SelectInputWithLabel, TextFieldWithLabel } from '../FormComponents';
import { ConfirmFields } from '../../../Types/GlobalTypes';

const FormModal: React.FunctionComponent<{
  fields?: ConfirmFields;
  methods: UseFormReturn;
}> = ({ fields = [], methods }) => {
  const renderInputs = () =>
    fields.map(field => {
      const { fieldType } = field;
      if (fieldType === 'textInput') {
        return <TextFieldWithLabel {...field} key={field.label} />;
      }
      if (fieldType === 'select' && typeof field.options !== 'undefined') {
        return <SelectInputWithLabel {...field} options={field.options} key={field.label} />;
      }
      return <div key={field.name} />;
    });
  return (
    <FormProvider {...methods}>
      <Box display="flex" flexDirection={'column'} paddingX={'20px'}>
        {renderInputs()}
      </Box>
    </FormProvider>
  );
};

export const ConfirmModal = () => {
  const cancelRef = React.useRef(null);

  const { activeEvent: event, updateEvent } = useEvents();

  const eventId = event?.id;

  const {
    confirmModalOpen,
    confirmModalTitle,
    confirmModalDescription,
    confirmAccept,
    confirmReject,
    confirmType,
    confirmNoSkip,
    confirmFields,
    confirmUpdate,
    confirmButtonTitle,
    confirmDefaultValues,
    confirmModalHeight
  } = useConfirm({});

  const { doPolicyReject } = useUser();

  const methods = useForm({ mode: 'all', defaultValues: confirmDefaultValues });
  const {
    handleSubmit,
    reset,
    formState: { isValid }
  } = methods;
  useEffect(() => {
    reset(confirmDefaultValues);
  }, [confirmDefaultValues, reset]);
  const handleSave = data => {
    if (confirmUpdate === 'lead') updateEvent({ data, eventId, isLead: true });
    confirmAccept();
  };

  const handleReject = () => {
    if (confirmType === 'policyModal') {
      doPolicyReject();
    } else confirmReject();
  };

  return (
    <AlertDialog
      isOpen={confirmModalOpen}
      leastDestructiveRef={cancelRef}
      onClose={handleReject}
      isCentered
      scrollBehavior="inside"
    >
      <AlertDialogOverlay>
        <AlertDialogContent overflow="hidden" height={confirmModalHeight}>
          {confirmModalTitle && (
            <AlertDialogHeader
              backgroundColor="var(--colors-modalHeader)"
              color={Colors.gray18}
              padding="18px 40px"
              marginBottom="25px"
            >
              {confirmType === 'modal' ? (
                <Text
                  fontFamily="Lora"
                  fontSize="21.75px"
                  color={Colors.gray18}
                  fontWeight="normal"
                >
                  {confirmModalTitle}
                </Text>
              ) : (
                confirmModalTitle
              )}
            </AlertDialogHeader>
          )}
          <AlertDialogCloseButton top="3" />
          <AlertDialogBody
            paddingX="40px"
            _hover={{
              '&::-webkit-scrollbar-thumb': {
                background: Colors.grayCC
              }
            }}
            css={{
              '&::-webkit-scrollbar': {
                width: '6px'
              },
              '&::-webkit-scrollbar-track': {
                width: '6px'
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'transparent',
                borderRadius: '6px'
              }
            }}
          >
            {confirmModalDescription}
          </AlertDialogBody>
          {confirmType === 'formModal' && <FormModal fields={confirmFields} methods={methods} />}

          <AlertDialogFooter>
            <Button variant="grayOutline" width="125px" size="md" onClick={handleReject} mr="25px">
              {(confirmNoSkip && 'GO BACK') || (confirmType === 'formModal' && 'SKIP') || 'CANCEL'}
            </Button>
            <Button
              variant={confirmType === 'cancel' ? 'cancel' : 'primary'}
              width="125px"
              size="md"
              isDisabled={confirmNoSkip && !isValid}
              onClick={(confirmType === 'formModal' && handleSubmit(handleSave)) || confirmAccept}
            >
              {confirmButtonTitle || (confirmType === 'formModal' && 'SAVE') || 'CONTINUE'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
