export const upcomingPayments = [
  {
    eventName: 'Jack & Jill’s Wedding',
    dueDate: '10/10/2022',
    amount: 10000,
    status: 'paid',
    description: '1st Deposit',
    paymentMethod: 'credit card'
  },
  {
    eventName: 'Jack & Jill’s Wedding',
    dueDate: '10/10/2022',
    amount: 10000,
    status: 'overdue',
    description: '1st Deposit',
    paymentMethod: 'debit card'
  },
  {
    eventName: 'Jack & Jill’s Wedding',
    dueDate: '10/10/2022',
    amount: 10000,
    status: 'scheduled',
    description: '1st Deposit',
    paymentMethod: 'direct deposit'
  },
  {
    eventName: 'Jack & Jill’s Wedding',
    dueDate: '10/10/2022',
    amount: 10000,
    status: 'refunded',
    description: '1st Deposit',
    paymentMethod: 'direct deposit'
  }
];

// Paid, Scheduled, Overdue (red), Refunded (red), Failed (red)
// closest duedate in the
export const invoices = [
  {
    invoice: '123456789',
    dueDate: '07/07/2022',
    amount: 10000,
    status: 'paid',
    paidOn: '07/07/2022',
    paymentMethod: 'credit card',
    description: '1st Deposit'
  },
  {
    invoice: '123456789',
    dueDate: '08/08/2022',
    amount: 10000,
    status: 'paid',
    paidOn: '08/08/2022',
    paymentMethod: 'credit card',
    description: '1st Deposit'
  },
  {
    invoice: '123456789',
    dueDate: '09/08/2022',
    amount: 15000,
    status: 'failed',
    paidOn: '09/08/2022',
    paymentMethod: 'debit card',
    description: '3st Deposit'
  },
  {
    invoice: '123456789',
    dueDate: '10/10/2022',
    amount: 10000,
    status: 'scheduled',
    paidOn: '10/10/2022',
    paymentMethod: 'debit card',
    description: '2st Deposit'
  },
  {
    invoice: '123456789',
    dueDate: '10/10/2022',
    amount: 20000,
    status: 'overdue',
    paidOn: '10/10/2022',
    paymentMethod: 'direct deposit',
    description: '2st Deposit'
  },
  {
    invoice: '123456789',
    dueDate: '11/11/2022',
    amount: 20000,
    status: 'overdue',
    paidOn: '11/11/2022',
    paymentMethod: 'direct deposit',
    description: '2st Deposit'
  },
  {
    invoice: '123456789',
    dueDate: '11/11/2022',
    amount: 10000,
    status: 'refunded',
    paidOn: '11/11/2022',
    paymentMethod: 'direct deposit',
    description: '2st Deposit'
  }
];
