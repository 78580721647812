export const FETCH_MEMORIES_REQUEST = 'FETCH_MEMORIES_REQUEST';
export const FETCH_MEMORIES_SUCCESS = 'FETCH_MEMORIES_SUCCESS';
export const FETCH_MEMORIES_FAILURE = 'FETCH_MEMORIES_FAILURE';

export const CREATE_MEMORIES_REQUEST = 'CREATE_MEMORIES_REQUEST';
export const CREATE_MEMORIES_SUCCESS = 'CREATE_MEMORIES_SUCCESS';
export const CREATE_MEMORIES_FAILURE = 'CREATE_MEMORIES_FAILURE';

export const DELETE_MEMORY_REQUEST = 'DELETE_MEMORY_REQUEST';
export const DELETE_MEMORY_SUCCESS = 'DELETE_MEMORY_SUCCESS';
export const DELETE_MEMORY_FAILURE = 'DELETE_MEMORY_FAILURE';

export const EDIT_MEMORY_REQUEST = 'EDIT_MEMORY_REQUEST';
export const EDIT_MEMORY_SUCCESS = 'EDIT_MEMORY_SUCCESS';
export const EDIT_MEMORY_FAILURE = 'EDIT_MEMORY_FAILURE';

export const BATCH_DELETE_REQUEST = 'BATCH_DELETE_REQUEST';
export const BATCH_DELETE_SUCCESS = 'BATCH_DELETE_SUCCESS';
export const BATCH_DELETE_FAILURE = 'BATCH_DELETE_FAILURE';

export const EDIT_MOBILE_PHOTO_REQUEST = 'EDIT_MOBILE_PHOTO_REQUEST';
export const EDIT_MOBILE_PHOTO_SUCCESS = 'EDIT_MOBILE_PHOTO_SUCCESS';
export const EDIT_MOBILE_PHOTO_FAILURE = 'EDIT_MOBILE_PHOTO_FAILURE';

export const GET_MODERATORS_REQUEST = 'GET_MODERATORS_REQUEST';
export const GET_MODERATORS_SUCCESS = 'GET_MODERATORS_SUCCESS';
export const GET_MODERATORS_FAILURE = 'GET_MODERATORS_FAILURE';

export const ADD_MODERATOR_REQUEST = 'ADD_MODERATOR_REQUEST';
export const ADD_MODERATOR_SUCCESS = 'ADD_MODERATOR_SUCCESS';
export const ADD_MODERATOR_FAILURE = 'ADD_MODERATOR_FAILURE';

export const DELETE_MODERATOR_REQUEST = 'DELETE_MODERATOR_REQUEST';
export const DELETE_MODERATOR_SUCCESS = 'DELETE_MODERATOR_SUCCESS';
export const DELETE_MODERATOR_FAILURE = 'DELETE_MODERATOR_FAILURE';

export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
