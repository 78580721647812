import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  calendarWizardOperations,
  emailComposerModalOperations,
  eventManagementDrawerOperations
} from 'src/v2/Drawers/Redux/operations';
import toast from 'react-hot-toast';
import { Icon } from '@chakra-ui/react';
import { IoIosCheckmarkCircle } from 'react-icons/io';

import { openOverlayLoader, closeOverlayLoader } from 'src/containers/OverlayLoader/operations';
import { openEventDetailsDrawer } from 'src/containers/EventDetailsModal/operations';
import { getEventAction } from 'src/containers/EventsContainer/actions';
import {
  addEventFailure,
  addEventSuccess,
  getUsersSuccess,
  getUsersFailure,
  updateEventFailure,
  updateEventSuccess,
  getLayoutsSuccess,
  getLayoutsFailure,
  updateUserSuccess,
  updateUserFailed,
  getUsersRequest,
  checkEventCodeFailure,
  checkEventCodeSuccess,
  checkHostEmailFailure,
  checkHostEmailSuccess,
  sendEventEmailOperations,
  setLeadTypeSuccess
} from './operations';
import {
  addEventAction,
  getUsersAction,
  updateEventAction,
  getLayoutsAction,
  updateUserAction,
  checkEventCode,
  checkHostEmail,
  sendEventEmail,
  setLeadTypeAction
} from './actions';
import {
  ADD_EVENT_REQUEST,
  GET_USERS_REQUEST,
  UPDATE_EVENT_REQUEST,
  GET_LAYOUTS_REQUEST,
  UPDATE_USER_REQUEST,
  CHECK_EVENTCODE_REQUEST,
  CHECK_HOSTEMAIL_REQUEST,
  SEND_EVENT_EMAIL,
  SET_LEAD_TYPE_REQUEST
} from './types';
import {
  getEventRequest,
  getEvents,
  getEventTasksRequest,
  getEventSuccess
} from '../EventsContainer/operations';
import { closeLeadsDetails, getLeadsRequest } from '../LeadsContainer/operations';

// const getRole = state => state.loginReducer.role;
const getToken = state => state.loginReducer.token;

function* workerAddEvent({ event: eventData, token, monthStart, monthEnd }) {
  try {
    // yield put(
    //   openModalLoader({
    //     showProgressBar: true,
    //     modalLoaderHeader: 'Creating Event',
    //     modalLoaderMessage: 'Creating Event to database'
    //   })
    // );

    yield put(openOverlayLoader());

    const response = yield call(addEventAction, { event: eventData, token, monthStart, monthEnd });
    const eventId = response && response?.data?.event?.id;
    const { events, event, success } = response.data;
    // const { users, defaultDocuments } = event;
    if (eventId) {
      yield put(addEventSuccess({ success, events, event }));
      toast('Saved successfully', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
      // const usersCreatedResponse = yield call(createHostUsersAction, { users, eventId, token });
      // const { success: usersCreatedSuccess } = usersCreatedResponse && usersCreatedResponse.data;

      // if (usersCreatedSuccess) yield put(createHostUsersSuccess());

      // const documentsAddedResponse = yield call(addEventDocumentsAction, {
      //   defaultDocuments,
      //   token,
      //   eventId
      // });

      // const { success: documentsAddedSuccess } = documentsAddedResponse.data;
      // if (documentsAddedSuccess) yield put(addEventDocumentsSuccess());
    }
    yield put(calendarWizardOperations.close());
    yield put(eventManagementDrawerOperations.close()); // This call close the drawer
  } catch (error) {
    yield put(addEventFailure(error.response.data.error, error.response.data.errors));
    toast.error('Error saving event');
  } finally {
    // yield put(closeModalLoader());
    yield put(closeOverlayLoader());
  }
}

function* workerUpdateEvent({
  id,
  event,
  token,
  isAppointment,
  monthEnd,
  monthStart,
  closeAction,
  isLead,
  navigate,
  getEventsWithLead
}) {
  // const role = yield select(getRole);
  try {
    yield put(openOverlayLoader());
    closeAction && closeAction();
    const response = yield call(updateEventAction, { id, event, token });
    yield put(
      updateEventSuccess({ success: response?.data?.event?.success, event: response?.data?.event })
    );
    yield put(getEventTasksRequest());
    yield put(getEvents({ token, monthEnd, monthStart }));
    if (response?.data?.event?.type === 'lead') {
      if (getEventsWithLead) yield put(getLeadsRequest({ getEvents: true }));
      if (!getEventsWithLead) yield put(getLeadsRequest());
    }
    // if (!isLead) {

    // }
    // if (isLead) {
    //   yield put(
    //     updateLeadSuccess({ success: response.data.event.success, lead: response.data.event })
    //   );
    // }
    if (response?.data?.bookedLead) {
      // getEvent({ eventId: id });
      // openEventDetailsDrawer();
      yield put(getEventRequest({ token, eventId: id }));
      yield put(closeLeadsDetails());
      navigate();
      yield put(openEventDetailsDrawer());
    }
    if (isAppointment) {
      toast('Appointment Created', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
      yield put(getEvents({ token, monthEnd, monthStart }));
    } else if (event?.eventTask && !event?.eventTask?.id) {
      toast('Task created', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
    } else if (event?.eventTask?.archive) {
      toast('Task Deleted', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
    } else {
      toast('Edited successfully', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
    }

    // if (role === 'host') {
    //   yield put(doGetEvent({ token, eventId: id }));
    // }
    // yield put(closeHostFormDrawer());
    // if (role === 'staff') {
    //   yield put(closeCreateEventModal());
    // }
    // yield put(closeCreateEventModal()); // This call close the drawer

    // const updateResponse = yield call(getEventAction, { token, eventId: id });

    // if (updateResponse) {
    //   yield put(getEventDetailSuccess(updateResponse?.data?.event));
    //   yield put(getEventSuccess(updateResponse?.data?.event));
    // }
  } catch (error) {
    const errorMessage = error.response.data.message || 'Error editing event';
    toast.error(errorMessage);
    yield put(
      updateEventFailure(
        error && error.response && (error.response.data.message || error.response.data.error),
        error && error.response && error.response.data.errors
      )
    );
  } finally {
    // yield put(closeModalLoader());
    // yield put(closeCreateEventModal());
    yield put(closeOverlayLoader());
  }
}

function* workerGetUsers({ venueId, token, eventId, role }) {
  try {
    const response = yield call(getUsersAction, { venueId, token, eventId, role });
    yield put(getUsersSuccess({ users: response.data.users }));
  } catch (error) {
    yield put(getUsersFailure(error));
  }
}

function* workerGetLayouts({ roomId, token }) {
  try {
    const response = yield call(getLayoutsAction, { roomId, token });
    yield put(getLayoutsSuccess({ layouts: response.data.layouts }));
  } catch (error) {
    yield put(getLayoutsFailure(error.response.data.message));
  }
}

function* workerUpdateUser({ user, token, venueId }) {
  try {
    const response = yield call(updateUserAction, { user, token });
    yield put(updateUserSuccess({ data: response.data.users }));
    yield put(getUsersRequest({ venueId, token }));
  } catch (error) {
    yield put(updateUserFailed(error.response.data.message));
  }
}

function* workerCheckEventCode({ token, venueId, eventCode, eventId = '' }) {
  try {
    const response = yield call(checkEventCode, { token, venueId, eventCode, eventId });
    const { success } = response && response.data;
    if (success) {
      yield put(checkEventCodeSuccess({ success }));
    }
  } catch (error) {
    yield put(
      checkEventCodeFailure({
        error: error.response && error.response.data && error.response.data.error
      })
    );
  }
}

function* workerCheckHostEmail({ token, venueId, email, eventId, key }) {
  try {
    const response = yield call(checkHostEmail, { token, venueId, email, eventId });
    const { success } = response && response.data;
    if (success) {
      yield put(checkHostEmailSuccess({ success, key }));
    }
  } catch (error) {
    yield put(
      checkHostEmailFailure({
        error: error.response && error.response.data && error.response.data.error,
        key
      })
    );
  }
}

function* workerSendEventEmail({ recipient, cc, bcc, body, subject, attachments, eventId }) {
  try {
    yield put(openOverlayLoader());
    const token = yield select(getToken);
    const response = yield call(sendEventEmail, {
      recipient,
      cc,
      bcc,
      body,
      subject,
      attachments,
      token,
      eventId
    });
    const { success, eventData } = response?.data || {};
    if (success) {
      // the backend returns the list of leads with the new lead
      yield put({
        type: SEND_EVENT_EMAIL.SUCCESS,
        eventData
      });
      toast('Email Sent', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
    }
    yield put(emailComposerModalOperations.close());
  } catch (error) {
    yield put(
      sendEventEmailOperations.failure(error.response.data.error, error.response.data.errors)
    );
  } finally {
    yield put(closeOverlayLoader());
  }
}

function* workerSetLeadType({ leadType, leadToUpdateId, originalLeadId }) {
  try {
    const token = yield select(getToken);
    const response = yield call(setLeadTypeAction, {
      leadType,
      leadToUpdateId,
      originalLeadId,
      token
    });
    if (response.data.success) {
      const { updatedLead } = response.data;
      yield put(setLeadTypeSuccess(updatedLead));
      const eventResponse = yield call(getEventAction, { token, eventId: originalLeadId });
      const { event } = eventResponse.data;
      yield put(getEventSuccess(event));
      //   getEvent({ eventId: cellData.id });
    }
  } catch (error) {
    toast.error(error.response.data.error);
  }
}

export function* watchSetLeadType() {
  yield takeLatest(SET_LEAD_TYPE_REQUEST, workerSetLeadType);
}

export function* watchAddEvent() {
  yield takeLatest(ADD_EVENT_REQUEST, workerAddEvent);
}

export function* watchUpdateEvent() {
  yield takeLatest(UPDATE_EVENT_REQUEST, workerUpdateEvent);
}

export function* watchGetUsers() {
  yield takeLatest(GET_USERS_REQUEST, workerGetUsers);
}

export function* watchGetLayouts() {
  yield takeLatest(GET_LAYOUTS_REQUEST, workerGetLayouts);
}

export function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER_REQUEST, workerUpdateUser);
}

export function* watchCheckEventCode() {
  yield takeLatest(CHECK_EVENTCODE_REQUEST, workerCheckEventCode);
}

export function* watchCheckHostEmail() {
  yield takeLatest(CHECK_HOSTEMAIL_REQUEST, workerCheckHostEmail);
}

export function* watchSendEventEmail() {
  yield takeLatest(SEND_EVENT_EMAIL.REQUEST, workerSendEventEmail);
}
