import {
  FORGOT_FAILURE,
  FORGOT_REQUEST,
  FORGOT_SUCCESS,
  REMIND_REQUEST,
  REMIND_FAILURE,
  REMIND_SUCCESS
} from './types';
import { OPEN_EVENT_MODAL } from '../EventDetailsModal/types';

const initialState = {
  fetching: false,
  error: null,
  success: null
};

export function forgotReducer(state = initialState, action) {
  switch (action.type) {
    case FORGOT_REQUEST:
      return { ...state, fetching: true, error: null, success: null };
    case FORGOT_FAILURE:
      return { ...state, fetching: false, error: action.error, success: null };
    case FORGOT_SUCCESS:
      return { ...state, fetching: false, error: null, success: action.success };
    case REMIND_REQUEST:
      return { ...state, fetching: true, error: null, success: null };
    case REMIND_FAILURE:
      return { ...state, fetching: false, error: action.error, success: null };
    case REMIND_SUCCESS:
      return { ...state, fetching: false, error: null, success: action.success };
    case OPEN_EVENT_MODAL:
      return { ...state, fetching: false, error: null, success: null };
    default:
      return state;
  }
}
