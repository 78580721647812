/* eslint-disable react/prop-types */
import { Flex, Icon } from '@chakra-ui/react';
import { ListCard } from 'src/v2/UIComponents/Primitives/Card/FullCard/FullCard';
import { GoChecklist } from 'react-icons/go';
import { useHistory } from 'react-router-dom';
import { getDateDisplay } from 'src/utils/getDateDisplay';
import { useEvents, useEventsDetails, useUser } from 'src/v2/Hooks/CollectionHooks';
import { TextPairing } from '../TextPairing';

export const ChecklistCard = ({ fetchingEvent }) => {
  const { activeEvent: event } = useEvents();
  const { checkListGroups, checklistStats } = event;
  const today = checkListGroups?.today;
  const overdue = checkListGroups?.overdue;
  const upcoming = checkListGroups?.upcoming;
  // const { today, overdue, upcoming } = checkListGroups;
  const { completedCount, totalItemCount } = checklistStats || {};

  const history = useHistory();

  const { role } = useUser();

  const upcomingValue =
    upcoming && upcoming?.length <= 0
      ? 'No items upcoming'
      : `${upcoming && upcoming?.[0]?.name} - Due: ${upcoming &&
          getDateDisplay(upcoming?.[0]?.dueDate).shortDate}`;

  const overdueValue =
    overdue && overdue?.length > 0 ? `${overdue?.length} items overdue` : 'No items overdue';

  // TODO: #569 Event Details Checklist Card: Update ChecklistCard with 'live' data
  const { setActiveItem } = useEventsDetails();
  const list = [
    {
      title: 'Due Today',
      value: (today?.length && today?.[0]?.name) || 'No items due today'
    },
    {
      title: 'Upcoming',
      value: upcomingValue
    },
    {
      title: 'Overdue',
      value: overdueValue
    }
  ];
  const navigate = () => history.push('/checklist');
  const viewAllAction =
    role === 'host' ? () => navigate() : () => setActiveItem({ activeItem: 'checklist' });

  const footer = () => (
    <Flex mt="12px" justifyContent="space-between" alignItems="flex-end">
      <Flex flexDir="column">
        <TextPairing
          fetchingData={fetchingEvent}
          title="Status"
          subtitle={`${completedCount?.toString() || '0'}/${totalItemCount} items complete`}
          missingMessage={undefined}
          titleColor={undefined}
        />
      </Flex>
    </Flex>
  );

  return (
    <ListCard
      titleIcon={<Icon as={GoChecklist} w={6} h={6} color="var(--colors-primary)" />}
      title="Checklist"
      headerBtnLabel="View All"
      list={list}
      fetching={fetchingEvent}
      footer={footer}
      headerBtnAction={viewAllAction}
    />
  );
};
