import React from 'react';
import LoaderStyles from './styles';
import IconLoader from '../../styles/Icons/Loader';

const Loader = () => (
  <LoaderStyles>
    <IconLoader />
  </LoaderStyles>
);

export default Loader;
