/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  refreshPage() {
    window.location.reload(false);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="errorContainer">
          <div className="browser"></div>

          <h1 className="headerMessage">Unfortunately, something has gone wrong.</h1>
          <p className="errorParagraph">
            We are unable to fulfill your request. Please refresh your browser. If the error
            continues, please contact us.
          </p>
          <button onClick={this.refreshPage} className="btn-green">
            <img className="icon" src="https://htmlacademy.ru/assets/icons/reload-6x-white.png" />{' '}
            Click to refresh
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = { children: PropTypes.element };

export default ErrorBoundary;
