import { Drawer, DrawerBody, DrawerHeader, DrawerContent, Flex } from '@chakra-ui/react';
import Colors from 'src/styles/Colors';
import { EventTasksTab } from 'src/v2/Drawers/LeadsDetails/EventTasks';
import { DetailsHeader } from '../DetailsHeader';
import { Navbar } from '../Navbar';
import { Overview } from '../Overview';
import { Documents } from '../Documents';
import { Questionnaire } from '../Questionnaire';
import { Payments } from '../Payments';
import { Checklist } from '../Checklist';
import { AppointmentsTab } from '../Appointments/AppointmentsTab';
import { EmailsTab } from '../Emails/EmailsTab';
import { ActivitiesTab } from '../../LeadsDetails/Activities';
import { Notes } from '../../LeadsDetails/Notes';
import { EventManagementDrawer } from '../../EventManagementDrawer/EventManagementDrawer';

export const DetailsDrawer = ({
  isOpen = false,
  onCloseDrawer,
  event,
  activeItem,
  fetchingEvent
}) => {
  const { startDate, title, id, eventType } = event;

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onCloseDrawer}
        size="full"
        closeOnEsc={true}
        trapFocus={false}
        variant="eventDetails"
      >
        <DrawerContent padding="10px">
          <Flex flexDirection="column" border="1px solid #ededed" height="full">
            <DrawerHeader
              paddingX="25px"
              paddingY="20px"
              backgroundColor="#F7F7F7"
              borderBottom="1px solid #ededed"
              minH="91px"
              flex="unset"
            >
              <DetailsHeader
                eventType={eventType}
                title={title}
                startDate={startDate}
                onBackClick={onCloseDrawer}
                fetchingEvent={fetchingEvent}
                eventId={id}
                event={event}
              />
            </DrawerHeader>
            <Navbar />
            <DrawerBody bg={Colors.grayFA} paddingX="43px" paddingY="18px" flex="1" minH="1">
              {activeItem === 'overview' && <Overview />}
              {/* {activeItem === 'event' && <Questionnaire event={event} />} */}
              {/* {activeItem === 'payments' && <Payments />} */}
              {activeItem === 'documents' && <Documents />}
              {activeItem === 'checklist' && <Checklist />}
              {activeItem === 'activities' && <ActivitiesTab />}
              {activeItem === 'notes' && <Notes />}
              {activeItem === 'tasks' && <EventTasksTab />}
              {/* feat/calendar-appointments */}
              {/* {activeItem === 'appointments' && <AppointmentsTab />} */}
              {/* feat/emails */}
              {/* {activeItem === 'emails' && <EmailsTab />} */}
              <EventManagementDrawer />
            </DrawerBody>
          </Flex>
        </DrawerContent>
      </Drawer>
    </>
  );
};
