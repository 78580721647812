/* eslint-disable react/prop-types */
import { Box } from '@chakra-ui/react';
import Colors from 'src/styles/Colors';

const primaryColor = 'var(--colors-primary)';
const lightPrimary = 'var(--colors-primary-light)';
const baseLabelStyles = {
  border: `1px solid ${primaryColor}`,
  borderLeft: `4px solid ${primaryColor}`,
  borderLeftRadius: '0px',
  padding: '3px 2px',
  display: 'flex',
  alignItems: 'center',
  margin: '1.75px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '14px',
  minWidth: '140px'
};
export const FilterLabel = ({ apptType }) => {
  const { appointmentBackgroundColor, appointmentColor, appointmentBorderColor, label } = apptType;
  return (
    <Box
      {...baseLabelStyles}
      backgroundColor={appointmentBackgroundColor || 'transparent'}
      color={appointmentColor || primaryColor}
      borderColor={appointmentBorderColor || primaryColor}
      flex="1"
    >
      {label}
    </Box>
  );
};

export const EventTypeLabel = ({ eventType }) => {
  const { label } = eventType;
  return (
    <Box {...baseLabelStyles} backgroundColor={primaryColor} color={Colors.white} flex="1">
      {label}
    </Box>
  );
};

export const LeadLabel = () => {
  return (
    <Box
      {...baseLabelStyles}
      backgroundColor={lightPrimary}
      borderColor={lightPrimary}
      borderWidth="0px"
      paddingLeft="7px"
      color={Colors.white}
      flex="1"
    >
      Lead
    </Box>
  );
};
