import { Grid, Flex, Button, Icon, Stack } from '@chakra-ui/react';
import { Text, Skeleton } from 'src/v2/UIComponents/Primitives';
import {
  MdKeyboardArrowLeft,
  MdModeEdit,
  MdDelete,
  MdClose,
  MdNotificationsNone
} from 'react-icons/md';
import { useEvents } from 'src/v2/Hooks/CollectionHooks';
import { useConfirm, useDrawers } from 'src/v2/Hooks/HelperHooks';
import { ActionsGroup } from 'src/v2/UIComponents/Toolbar/ActionsGroup';
import IconCelebration from 'src/styles/Icons/Celebration';
import IconRings from 'src/styles/Icons/Rings';
import { generateReportXLS } from 'src/v2/Pages/Reports/GenerateReportXLS';
import { HiExternalLink } from 'react-icons/hi';

const handleExport = event => {
  generateReportXLS({ columns: [event], reportName: 'R365 Potential Data' });
};

export const DetailsHeader = ({
  title,
  startDate,
  onBackClick,
  fetchingEvent,
  eventId,
  eventType,
  event
}) => {
  const { deleteEvent, openCreateEventForm } = useEvents();
  const { openEventManagementDrawer } = useDrawers();
  // const { openEventFormDrawer } = useEventsDetails();

  const handleDeleteEvent = () => deleteEvent({ id: eventId });
  const { openConfirm } = useConfirm({
    acceptAction: handleDeleteEvent,
    confirmHookId: 'deleteEvent'
  });

  const handleDelete = () => {
    openConfirm({
      title: `Delete ${title}?`,
      description: 'This will remove access for all hosts to their client portal',
      confirmId: 'deleteEvent',
      confirmType: 'cancel'
    });
  };

  const handleEdit = () => {
    openEventManagementDrawer('event', 'edit');
  };

  const rightActions = [
    // {
    //   icon: <Icon as={MdNotificationsNone} w={6} h={6} color="var(--colors-)" />,
    //   title: 'Overdue Payments',
    //   noItemsText: 'No overdue payments',
    //   type: 'dropdown',
    //   variant: 'roundedSecondaryOutline'
    // },
    {
      icon: <Icon as={MdModeEdit} w={6} h={6} />,
      onClick: handleEdit,
      type: 'iconButton'
    },
    {
      icon: <Icon as={MdDelete} w={6} h={6} />,
      onClick: handleDelete,
      type: 'iconButton'
    },
    {
      icon: <Icon as={MdClose} w={6} h={6} />,
      onClick: onBackClick,
      type: 'iconButton'
    }
  ];

  return (
    <Grid templateColumns="repeat(3, 1fr)">
      <Flex alignItems="center" w="100%">
        <Button
          leftIcon={<Icon as={MdKeyboardArrowLeft} w={6} h={6} color="var(--colors-primary)" />}
          variant="unstyled"
          display="flex"
          alignItems="center"
          fontFamily="Lato"
          fontSize="14px"
          fontWeight={400}
          letterSpacing="0.1px"
          lineHeight="20px"
          color="#52575C"
          onClick={onBackClick}
        >
          Back to Calendar
        </Button>
      </Flex>
      <Flex w="100%" justifyContent="center" flexDirection="column">
        {fetchingEvent && (
          <Flex justifyContent="center" mb="5px">
            <Skeleton height="16px" width="160px" />
          </Flex>
        )}
        {!fetchingEvent && (
          <Flex alignItems="center" justifyContent="center">
            {eventType !== 'other' ? (
              <IconCelebration style={{ marginRight: '12px' }} />
            ) : (
              <IconRings />
            )}
            {/* <Event color="var(--colors-primary)" mr="5px" w={6} h={6} /> */}
            <Text variant="contentTitle" textAlign="center">
              {title}
            </Text>
          </Flex>
        )}
        {!fetchingEvent && (
          <Text variant="contentSubTitle" textAlign="center">
            {startDate}
          </Text>
        )}
        {fetchingEvent && (
          <Flex justifyContent="center">
            <Skeleton height="16px" width="160px" />
          </Flex>
        )}
      </Flex>
      <Flex w="100%" justifyContent="flex-end" alignItems="center">
        <Stack spacing={2} direction="row" align="center">
          <ActionsGroup actions={rightActions} />
        </Stack>
      </Flex>
    </Grid>
  );
};
