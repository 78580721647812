import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/database';
import config from './.env.json';

const { apiKey, authDomain, databaseURL, projectId, storageBucket, messagingSenderId } = config;

const firebaseConfig = {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId
};

export default firebase.initializeApp(firebaseConfig);
