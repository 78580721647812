import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export const getChecklistItemsApi = ({ token }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/checklists`,
    headers: { Authorization: token }
  });
};

export const importChecklistItemsAPI = ({ token, items, importType, eventId }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/checklists/import`,
    headers: { Authorization: token },
    data: { items, importType, eventId }
  });
};

export const updateEventChecklistItemApi = ({ token, eventId, checklistItems }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/events/${eventId}`,
    headers: { Authorization: token },
    data: { checklistItems }
  });
};

export const createChecklistItemApi = ({ token, checklistItem }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/checklists`,
    headers: { Authorization: token },
    data: checklistItem
  });
};

export const updateHostChecklistItemApi = ({ token, itemId, checklistItem, eventId }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/checklists/${itemId}`,
    headers: { Authorization: token },
    data: { ...checklistItem, itemType: 'host', eventId }
  });
};

export const deleteChecklistItemApi = ({ token, eventId, id }) => {
  return axios({
    method: 'delete',
    url: `${apiUri}/events/${eventId}/checklistitems/${id}`,
    headers: { Authorization: token }
  });
};
