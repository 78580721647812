/* eslint-disable react/prop-types */
import {
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Box,
  MenuDivider,
  MenuItem
} from '@chakra-ui/react';
import { CaretDown } from 'src/v2/UIComponents/Primitives/Icons';
import { Button } from 'src/v2/UIComponents/Primitives';
import _isObject from 'lodash/isObject';
import Colors from 'src/styles/Colors';
import { getOptionListWithCount } from './helpers';

export const FilterGroups = ({
  width,
  isDisabled = false,
  totalFiltersActive,
  title,
  groups,
  tableData,
  dataKey,
  itemsChecked,
  handleCheckValue,
  handleClear,
  allData,
  filters
}) => {
  return (
    <Menu closeOnSelect={false} variant="checkmark">
      <MenuButton
        as={Button}
        variant="filterDropdown"
        rightIcon={<CaretDown />}
        width={width}
        isDisabled={isDisabled}
      >
        {totalFiltersActive}
        {title}
      </MenuButton>
      <MenuList zIndex="12" display="grid" gridTemplateColumns={'1fr 1fr'}>
        {groups?.map(group => {
          const optionListWithCount = getOptionListWithCount({
            filterList: group?.options,
            tableData,
            dataKey,
            allData,
            filters
          });
          const newOptions = optionListWithCount.filter(itm => itm.totalMatches > 0);
          optionListWithCount.forEach((itm, i) => {
            if (itm.totalMatches < 1) {
              newOptions.push(itm);
            }
          });
          return (
            <MenuOptionGroup
              type="checkbox"
              value={itemsChecked}
              title={group.title}
              key={group.title}
            >
              {newOptions.map((val, key) => {
                const isValObject = _isObject(val);
                const { totalMatches } = val;
                const Label = () => (
                  <Box display="flex" gap="4px" alignItems={'center'}>
                    <div>{isValObject ? val.label : val}</div> <div>{`(${totalMatches})`}</div>
                  </Box>
                );

                return (
                  <MenuItemOption
                    color={!totalMatches && Colors.gray7D}
                    key={key}
                    value={isValObject ? val.value : val}
                    textTransform="capitalize"
                    fontFamily="Lato"
                    fontSize="15px"
                    lineHeight="22.5px"
                    onClick={e => handleCheckValue(isValObject ? val.value : val)}
                    _active={{ background: 'grayED' }}
                    _focus={{ background: 'grayED' }}
                    display="flex"
                    marginRight="20px"
                    isDisabled={totalMatches < 1}
                  >
                    <Label />
                  </MenuItemOption>
                );
              })}
            </MenuOptionGroup>
          );
        })}
        <MenuDivider gridColumn="span 2" />
        <MenuItem
          pl="36px"
          fontFamily="Lato"
          fontSize="15px"
          lineHeight="22.5px"
          onClick={handleClear}
          _active={{ background: 'grayED' }}
          _focus={{ background: 'grayED' }}
          gridColumn="span 2"
        >
          Clear
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
