import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export const getTablesAction = ({ token }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/tables`,
    headers: { Authorization: token }
  });
};

export const addTableAction = ({ table, token }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/tables`,
    headers: { Authorization: token },
    data: table
  });
};

export const updateTableAction = ({ id, table, token }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/tables/${id}`,
    headers: { Authorization: token },
    data: table
  });
};

export const deleteTableAction = ({ id, token }) => {
  return axios({
    method: 'delete',
    url: `${apiUri}/tables/${id}`,
    headers: { Authorization: token }
  });
};

export const updateCategoryAction = ({ data, token }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/tables/category/1`,
    headers: { Authorization: token },
    data
  });
};
