import { ComponentStyleConfig } from '@chakra-ui/react';
import Colors from 'src/styles/Colors';

export const Text: ComponentStyleConfig = {
  variants: {
    // replace with headerTitleL
    contentTitle: {
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 600,
      color: Colors.black
    },
    contentSubTitle: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
      color: Colors.gray7D
    },
    label: {
      fontSize: '12px',
      fontWeight: '500',
      letterSpacing: '-0.23px',
      color: Colors.grayB0
    },
    cardLabel: {
      fontSize: '15px',
      letterSpacing: '-0.28px',
      fontWeight: '500',
      color: Colors.black
    },
    cardHeaderTitle: {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '26px'
    },
    headerTitleL: {
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 600,
      color: Colors.black
    },
    headerTitleM: {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 400,
      textTransform: 'capitalize',
      color: Colors.black
    },
    headerTitleS: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 700,
      color: Colors.black,
      textTransform: 'capitalize'
    }
  }
};
