import {
  GET_TABLES_REQUEST,
  GET_TABLES_SUCCESS,
  GET_TABLES_FAILURE,
  ADD_TABLE_REQUEST,
  ADD_TABLE_FAILURE,
  UPDATE_TABLE_REQUEST,
  UPDATE_TABLE_SUCCESS,
  UPDATE_TABLE_FAILURE,
  DELETE_TABLE_REQUEST,
  DELETE_TABLE_SUCCESS,
  DELETE_TABLE_FAILURE,
  ADD_TABLE_SUCCESS,
  RESET_ALERTS,
  UPDATE_TABLE_CATEGORY_REQUEST,
  UPDATE_TABLE_CATEGORY_SUCCESS
} from './types';

export const getTablesRequest = () => ({
  type: GET_TABLES_REQUEST
});

export const getTablesSuccess = ({ tables, categories, types }) => ({
  type: GET_TABLES_SUCCESS,
  tables,
  categories,
  types
});

export const getTablesFailure = ({ error }) => ({
  type: GET_TABLES_FAILURE,
  error
});

export const addTableRequest = ({ table }) => ({
  type: ADD_TABLE_REQUEST,
  table
});

export const addTableSuccess = ({ table }) => ({
  type: ADD_TABLE_SUCCESS,
  table
});

export const addTableFailure = ({ error }) => ({
  type: ADD_TABLE_FAILURE,
  error
});

export const updateTableRequest = ({ id, table }) => ({
  type: UPDATE_TABLE_REQUEST,
  id,
  table
});

export const updateTableSuccess = ({ table }) => ({
  type: UPDATE_TABLE_SUCCESS,
  table
});

export const updateTableFailure = ({ error }) => ({
  type: UPDATE_TABLE_FAILURE,
  error
});

export const deleteTableRequest = ({ id }) => ({
  type: DELETE_TABLE_REQUEST,
  id
});

export const deleteTableSuccess = () => ({
  type: DELETE_TABLE_SUCCESS
});

export const deleteTableFailure = ({ error }) => ({
  type: DELETE_TABLE_FAILURE,
  error
});

export const resetAlerts = () => ({ type: RESET_ALERTS });

export const updateCategoryRequest = ({ data }) => ({
  type: UPDATE_TABLE_CATEGORY_REQUEST,
  data
});

export const updateCategorySuccess = () => ({
  type: UPDATE_TABLE_CATEGORY_SUCCESS
});
