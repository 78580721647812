import React, { FC, ReactNode } from 'react';

import { EventChecklistItem } from 'venuex-types';
import CaretDown from '../../styles/Icons/CaretDown';
import CaretUp from '../../styles/Icons/CaretUp';

export type HeaderRow = {
  index: number;
  expanded: boolean;
  title: string;
  size: number;
  columns: object[];
  header?: boolean;
};

export type CheckListRow = HeaderRow | EventChecklistItem;

type RowData = {
  index: number;
  id: string;
  header: boolean;
  title: string;
  size: number;
  columns: object[];
};

export type RowProps = {
  style: object;
  className: string;
  key: string;
  rowData: RowData;
  columns: ReactNode[];
  index: number;
  isScrolling: boolean;
};

type RowInputProps = {
  rowProps: RowProps;
  groupedChecklist: CheckListRow[];
  toggleCollapsible: (input) => void;
  itemUpdating?: string;
};
const Row: FC<RowInputProps> = ({
  rowProps,
  groupedChecklist,
  toggleCollapsible,
  itemUpdating
}) => {
  const { style, className, key, rowData } = rowProps;
  const updating = rowData.id === itemUpdating;
  const newClassName = updating ? `${className} updating` : className;
  const isHeader = (val: CheckListRow): val is HeaderRow =>
    Object.keys(val).filter((ky: string) => ky === 'header').length > 0;
  const headerRows = groupedChecklist.filter(isHeader);
  if (rowData.header) {
    return (
      <div
        style={{
          ...style,
          backgroundColor: 'var(--colors-headerRow)',
          cursor: 'pointer',
          textTransform: 'uppercase',
          letterSpacing: '0.88px',
          color: '#7d7d7d',
          fontSize: '14px',
          fontWeight: 500,
          paddingLeft: '10px',
          overflow: 'visible !important',
          width: '100%'
        }}
        key={key}
        className={className}
        onClick={() => toggleCollapsible({ rowData })}
      >
        {headerRows.find(item => item.index === rowData.index)?.expanded ? (
          <CaretUp />
        ) : (
          <CaretDown />
        )}
        <div style={{ margin: '0px 5px' }}>{rowData.title}</div>
        <div
          style={{
            color: '#b0b0b0',
            fontFamily: 'Lato'
          }}
        >
          ({rowData.size})
        </div>
      </div>
    );
  }
  if (updating && rowData.id === itemUpdating)
    return (
      <div className={newClassName} key={key} style={style}>
        {rowProps.columns}
      </div>
    );
  return <div />;
  // return defaultTableRowRenderer(rowProps);
};

export default Row;
