import { REGISTER_USER_REQUEST, REGISTER_USER_SUCCESS, REGISTER_USER_FAILURE } from './types';

export const registerUserRequest = ({ user, token, venueId }) => ({
  type: REGISTER_USER_REQUEST,
  token,
  user,
  venueId
});

export const registerUserSuccess = ({ user }) => ({
  type: REGISTER_USER_SUCCESS,
  user
});

export const registerUserFailure = ({ error }) => ({
  type: REGISTER_USER_FAILURE,
  error
});
