/* eslint-disable import/prefer-default-export */
import {
  OPEN_ADD_EVENT_MODAL,
  CLOSE_ADD_EVENT_MODAL,
  ADD_EVENT_REQUEST,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
  GET_LAYOUTS_REQUEST,
  GET_LAYOUTS_SUCCESS,
  GET_LAYOUTS_FAILURE,
  CHECK_EVENTCODE_SUCCESS,
  CHECK_EVENTCODE_FAILURE,
  CHECK_HOSTEMAIL_SUCCESS,
  CHECK_HOSTEMAIL_FAILURE,
  CREATE_HOST_USERS_SUCCESS,
  CREATE_HOST_USERS_FAILURE,
  ADD_EVENT_DOCUMENTS_SUCCESS,
  ADD_EVENT_DOCUMENTS_FAILURE,
  SEND_EVENT_EMAIL,
  SET_LEAD_TYPE_REQUEST,
  SET_LEAD_TYPE_SUCCESS
} from './types';

const initialState = {
  fetching: false,
  updating: false,
  startDate: '',
  isOpen: false,
  event: {},
  fetchingRoom: false,
  updateEventSuccess: false,
  addEventError: null,
  updateEventError: null,
  error: null,
  users: null,
  rooms: null,
  layouts: null,
  eventCodeExists: false,
  hostEmailExists: { user1: false, user2: false, user3: false },
  addingEvent: false,
  addEventSuccess: false,
  creatingHosts: false,
  hostsCreated: false,
  addingEventDocuments: false,
  eventDocumentsCreated: false,
  fetchingLayouts: false,
  sendingEventEmail: false,
  duplicateIdUpdating: null
};

export const addEventModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_ADD_EVENT_MODAL:
      return {
        ...state,
        isOpen: true,
        startDate: action.startDate
      };
    case CLOSE_ADD_EVENT_MODAL:
      return {
        ...state,
        isOpen: false,
        eventCodeExists: false,
        hostEmailExists: { user1: false, user2: false, user3: false }
      };
    case ADD_EVENT_REQUEST:
      return {
        ...state,
        event: action.event,
        updateEventSuccess: false,
        fetching: true,
        addEventError: null,
        addingEvent: true,
        addEventSuccess: false,
        creatingHosts: true,
        hostsCreated: false,
        addingEventDocuments: true,
        eventDocumentsCreated: false
      };
    case ADD_EVENT_SUCCESS:
      return {
        ...state,
        updateEventSuccess: false,
        fetching: false,
        addEventError: null,
        addingEvent: false,
        addEventSuccess: true
      };
    case ADD_EVENT_FAILURE:
      return {
        ...state,
        addEventSuccess: false,
        updateEventSuccess: false,
        fetching: false,
        addEventError: action.error,
        addingEvent: false
      };
    case CREATE_HOST_USERS_SUCCESS:
      return {
        ...state,
        creatingHosts: false,
        hostsCreated: true
      };
    case CREATE_HOST_USERS_FAILURE:
      return {
        ...state,
        creatingHosts: false,
        hostsCreated: false
      };
    case ADD_EVENT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        addingEventDocuments: false,
        eventDocumentsCreated: true
      };
    case ADD_EVENT_DOCUMENTS_FAILURE:
      return {
        ...state,
        addingEventDocuments: false,
        eventDocumentsCreated: false
      };
    case UPDATE_EVENT_REQUEST:
      return {
        ...state,
        addEventSuccess: false,
        updateEventSuccess: false,
        updating: true,
        updateEventError: null,
        event: {}
      };
    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        updateEventSuccess: true,
        addEventSuccess: false,
        updating: false,
        event: {
          ...action.event,
          advancedNotes: action.event.advancedNotes
        },
        updateEventError: null
      };
    case UPDATE_EVENT_FAILURE:
      return {
        ...state,
        addEventSuccess: false,
        updateEventSuccess: false,
        updating: false,
        updateEventError: action.error,
        event: {}
      };
    case GET_USERS_REQUEST:
      return {
        ...state,
        success: false,
        addEventSuccess: false,
        addEventError: null,
        updateEventError: null,
        fetching: true,
        error: null
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        success: true,
        addEventSuccess: false,
        updateEventSuccess: false,
        fetching: false,
        error: null,
        users: action.users
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        success: false,
        fetching: false,
        addEventSuccess: false,
        updateEventSuccess: false,
        error: action.error,
        users: null
      };
    case GET_LAYOUTS_REQUEST:
      return {
        ...state,
        success: false,
        addEventSuccess: false,
        updateEventSuccess: false,
        fetching: true,
        error: null,
        layouts: null,
        fetchingLayouts: true
      };
    case GET_LAYOUTS_SUCCESS:
      return {
        ...state,
        success: true,
        addEventSuccess: false,
        updateEventSuccess: false,
        fetching: false,
        error: null,
        layouts: action.layouts,
        fetchingLayouts: false
      };
    case GET_LAYOUTS_FAILURE:
      return {
        ...state,
        success: false,
        addEventSuccess: false,
        updateEventSuccess: false,
        fetching: false,
        error: action.error,
        layouts: null,
        fetchingLayouts: false
      };
    case CHECK_EVENTCODE_SUCCESS:
      return {
        ...state,
        eventCodeExists: action.success
      };
    case CHECK_EVENTCODE_FAILURE:
      return {
        ...state,
        eventCodeExists: !action.error
      };
    case CHECK_HOSTEMAIL_SUCCESS: {
      const { key, success } = action;
      const { hostEmailExists } = state;
      hostEmailExists[key] = success;
      return {
        ...state,
        hostEmailExists
      };
    }
    case CHECK_HOSTEMAIL_FAILURE: {
      const { key, error } = action;
      const { hostEmailExists } = state;
      hostEmailExists[key] = !error;
      return {
        ...state,
        hostEmailExists
      };
    }

    case SEND_EVENT_EMAIL.REQUEST: {
      return {
        ...state,
        sendingEventEmail: true
      };
    }
    case SEND_EVENT_EMAIL.SUCCESS: {
      return {
        ...state,
        sendingEventEmail: false
      };
    }
    case SEND_EVENT_EMAIL.FAILURE: {
      return {
        ...state,
        sendingEventEmail: false
      };
    }
    case SET_LEAD_TYPE_REQUEST: {
      return {
        ...state,
        duplicateIdUpdating: action.leadToUpdateId
      };
    }
    case SET_LEAD_TYPE_SUCCESS: {
      return {
        ...state,
        duplicateIdUpdating: null
      };
    }
    default:
      return state;
  }
};
