import styled from 'styled-components';

export const StyledImage = styled.img`
  width: 125px;
  height: 125px;
  filter: opacity(100%);
  -webkit-filter: opacity(40%);
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  font-family: 'Lato', sans-serif;
  justify-content: center;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 390px;

  @media (min-width: 600px) {
    width: 460px;
  }
`;

export const StyledMessage = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  font-weight: 500;
  letter-spacing: -0.19px;
  color: #b0b0b0;
  font-size: 15px;
  line-height: 1rem;

  @media (max-width: 600px) {
    line-height: 1.2rem;
    width: 76%;
  }
`;

export const StyledTitle = styled.span`
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;
