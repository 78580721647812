import {
  EventTaskName,
  EventTaskAssignedTo,
  EventTaskDescription,
  EventTaskDueDate,
  EventTaskCompleted
} from 'src/v2/Drawers/EventTaskDrawer/EventTaskForm/Inputs';

import { FormInputsWrapper } from 'src/v2/UIComponents/FormComponents';

export const EventTaskForm = () => {
  return (
    <FormInputsWrapper>
      <EventTaskName />
      <EventTaskAssignedTo />
      <EventTaskDescription />
      <EventTaskDueDate />
      <EventTaskCompleted />
    </FormInputsWrapper>
  );
};
