import {
  VENDOR_LIST_FAILURE,
  VENDOR_LIST_REQUEST,
  VENDOR_LIST_SUCCESS,
  SET_VENDOR_ITEM,
  SET_FILTER_VENDORS,
  CONTACT_VENDOR_FAILURE,
  CONTACT_VENDOR_SUCCESS,
  CONTACT_VENDOR_REQUEST,
  RECOMMENDED_VENDORS_SET_SEARCH_TERM
} from './types';

const initialState = {
  fetching: false,
  error: null,
  vendors: null,
  vendorItem: null,
  filteredVendors: null,
  listVendorCollectionData: null,
  listVendorCollectionFailed: null,
  contactVendorSuccess: null,
  contactVendorFailed: null,
  contactingVendor: false,
  status: '',
  searchTerm: ''
};

const vendorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case VENDOR_LIST_REQUEST:
      return {
        ...state,
        fetching: true,
        status: 'fetchingVendors'
      };
    case VENDOR_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        vendors: action.listVendorData,
        status: 'fetchingVendorsSuccess'
      };
    case VENDOR_LIST_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.listVendorErrors,
        status: 'fetchingVendorsFailed'
      };

    case SET_VENDOR_ITEM:
      return {
        ...state,
        filteredVendors: null,
        vendorItem: action.vendorItem
      };

    case SET_FILTER_VENDORS:
      return {
        ...state,
        vendorItem: null,
        filteredVendors: action.filteredVendors
      };
    case CONTACT_VENDOR_REQUEST:
      return {
        ...state,
        contactingVendor: true,
        status: 'contactingVendor'
      };
    case CONTACT_VENDOR_SUCCESS:
      return {
        ...state,
        contactingVendor: false,
        contactVendorSuccess: action.contactVendorSuccess,
        status: 'contactVendorSuccess'
      };
    case CONTACT_VENDOR_FAILURE:
      return {
        ...state,
        contactingVendor: false,
        contactVendorFailed: action.contactVendorErr
      };
    case RECOMMENDED_VENDORS_SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm
      };
    default:
      return state;
  }
};

export default vendorsReducer;
