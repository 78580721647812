import { Select } from 'chakra-react-select';
import { FC } from 'react';
import Colors from 'src/styles/Colors';

interface OptionProps {
  value: string;
  label: string;
}

export type ValueProps = OptionProps | Array<OptionProps> | null;
interface InputProps {
  isInvalid?: boolean;
  options?: Array<OptionProps>;
  placeholder?: string;
  handleOnChange: (option: any | Array<string>) => void;
  value?: ValueProps;
  isMulti?: boolean;
  isLoading?: boolean;
  selectedOptionStyle?: 'check' | 'color';
  closeMenuOnSelect?: boolean;
  inline?: boolean;
}

export const SelectInput: FC<InputProps> = ({
  isInvalid,
  options,
  placeholder,
  handleOnChange,
  value,
  isMulti,
  isLoading,
  selectedOptionStyle,
  closeMenuOnSelect,
  inline
}) => {
  return (
    <Select
      isClearable
      isInvalid={isInvalid}
      // flex={1}
      options={options}
      placeholder={placeholder}
      onChange={handleOnChange}
      value={value}
      isMulti={isMulti}
      isLoading={isLoading}
      menuPortalTarget={document.body}
      selectedOptionStyle="color"
      selectedOptionColor={'selectedOption'}
      closeMenuOnSelect={closeMenuOnSelect}
      // menuIsOpen
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        option: provided => {
          return {
            ...provided
          };
        }
      }}
      chakraStyles={{
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected && Colors.gray18,
          '&:hover': {
            backgroundColor: Colors.grayF7
          }
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          background: 'white',
          p: 0,
          w: '40px',
          color: '#A0A4A8'
        }),
        indicatorSeparator: () => ({
          display: 'none'
        }),
        placeholder: props => ({
          ...props,
          fontSize: '14px'
        }),
        valueContainer: props => ({
          ...props,
          fontSize: '14px'
        }),
        control: provided => ({
          ...provided,
          border: (inline && 'none') || provided.border,
          borderRadius: '8px',
          _invalid: {
            borderColor: Colors.redC0
          },
          height: '48px',
          _focus: { borderWidth: '2px' }
        }),
        multiValue: provided => ({
          ...provided,
          bg: Colors.grayED
        })
      }}
    />
  );
};
