import React from 'react';
import PropTypes from 'prop-types';
import ButtonRoundStyles, { StyledReactTooltip } from './styles';

const ButtonRound = ({
  icon,
  label,
  type,
  action,
  loading,
  color,
  bgColor,
  disabled = false,
  disableShadow,
  size,
  isActive,
  toolTipText,
  onMouseEnter,
  onMouseLeave,
  primaryInitials,
  className
}) => {
  const tooltipId = Math.random()
    .toString(36)
    .substring(2);

  return (
    <>
      <ButtonRoundStyles
        className={className}
        title={label}
        type={type}
        disabled={disabled}
        loading={loading}
        onClick={action}
        color={color}
        bgColor={bgColor}
        size={size}
        disableShadow={disableShadow}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        isActive={isActive}
        primaryInitials={primaryInitials}
        data-tip={toolTipText}
        data-for={tooltipId}
        data-place="bottom"
        data-effect="solid"
      >
        {icon && icon}
      </ButtonRoundStyles>
      <StyledReactTooltip id={tooltipId} className />
    </>
  );
};

ButtonRound.propTypes = {
  icon: PropTypes.element || null,
  label: PropTypes.string,
  color: PropTypes.string,
  toolTipText: PropTypes.string,
  bgColor: PropTypes.string,
  primaryInitials: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  action: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  disableShadow: PropTypes.bool || false,
  onMouseEnter: PropTypes.func || null,
  onMouseLeave: PropTypes.func || null,
  className: PropTypes.string
};

export default ButtonRound;
