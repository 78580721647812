import { FormInputsWrapper } from 'src/v2/UIComponents/FormComponents';
import { LegacyPayments } from 'src/v2/Drawers/EventFormDrawer/Inputs/LegacyPayments';
import { ContentTitle } from './ContentTitle';

export const LegacyPaymentsTab = () => {
  return (
    <FormInputsWrapper>
      <ContentTitle title="Deposit Schedule" />
      <LegacyPayments />
    </FormInputsWrapper>
  );
};
