import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export function login(loginData) {
  const payload = {};

  payload.email = loginData.email;
  payload.password = loginData.password;

  if (loginData.venueId) {
    payload.venueId = loginData.venueId;
  }

  if (loginData.eventId) {
    payload.eventId = loginData.eventId;
  }

  return axios({
    method: 'post',
    url: `${apiUri}/auth/login`,
    data: { ...payload }
  });
}

export function logout({ token }) {
  return axios({
    method: 'get',
    url: `${apiUri}/auth/logout`,
    headers: { Authorization: token }
  });
}

export function refreshAuth({ token, refresh }) {
  return axios({
    method: 'post',
    url: `${apiUri}/auth/refresh`,
    headers: { Authorization: token },
    data: { refresh }
  });
}

export function policyAccept({ token }) {
  return axios({
    method: 'post',
    url: `${apiUri}/auth/policy`,
    headers: { Authorization: token }
  });
}

export function getEmailThreads({ token, offset, limit }) {
  return axios({
    method: 'get',
    url: `${apiUri}/emails/threads/list/${offset}/${limit}`,
    headers: { Authorization: token }
  });
}
