import {
  DocumentDetailDrawerOperationsProps,
  DrawerOperations,
  EventManagementDrawerOperationsProps,
  EventManagementVersionTypes
} from 'src/Types/GlobalTypes';
import { EventActivityObject, EventResponseObject, EventTask } from 'venuex-types';

import { ParsedLead } from 'src/containers/LeadsContainer/reducers';
import {
  ACTIVITY_DRAWER,
  APPOINTMENT_DRAWER,
  BOOK_LEAD_OPERATIONS,
  CALENDAR_WIZARD_DRAWER,
  CHANGE_EVENT_FORM_VERSION,
  DOCUMENT_DETAILS_DRAWER,
  EMAIL_COMPOSER_MODAL,
  EMAIL_THREADS_DRAWER,
  EVENT_DOCUMENT_DRAWER,
  EVENT_MANAGEMENT_DRAWER,
  EVENT_PRODUCTS_DRAWER,
  EVENT_TASK_DRAWER,
  PRODUCT_CATEGORY_DRAWER,
  PRODUCT_DRAWER,
  RATE_TYPE_DRAWER,
  REPORT_FIELDS_DRAWER,
  TAX_RATE_DRAWER,
  QUICK_EDIT_LEAD_DETAILS_DRAWER
} from './types';

export const quickEditLeadDrawerOperations = {
  open: (lead: ParsedLead) => ({
    type: QUICK_EDIT_LEAD_DETAILS_DRAWER.OPEN,
    lead
  }),
  setLeadToQuickEdit: (lead: ParsedLead) => ({
    type: QUICK_EDIT_LEAD_DETAILS_DRAWER.SET_LEAD_TO_QUICK_EDIT,
    lead
  }),
  close: () => ({
    type: QUICK_EDIT_LEAD_DETAILS_DRAWER.CLOSE
  })
};

export const productDrawerOperations: DrawerOperations = {
  open: () => ({
    type: PRODUCT_DRAWER.OPEN
  }),
  close: () => ({
    type: PRODUCT_DRAWER.CLOSE
  })
};

export const productCategoriesDrawerOperations: DrawerOperations = {
  open: () => ({
    type: PRODUCT_CATEGORY_DRAWER.OPEN
  }),
  close: () => ({
    type: PRODUCT_CATEGORY_DRAWER.CLOSE
  })
};

export const rateTypeDrawerOperations = {
  open: () => ({
    type: RATE_TYPE_DRAWER.OPEN
  }),
  close: () => ({
    type: RATE_TYPE_DRAWER.CLOSE
  })
};

export const taxRateDrawerOperations = {
  open: () => ({
    type: TAX_RATE_DRAWER.OPEN
  }),
  close: () => ({
    type: TAX_RATE_DRAWER.CLOSE
  })
};

export const eventProductDrawerOperations = {
  open: () => ({
    type: EVENT_PRODUCTS_DRAWER.OPEN
  }),
  close: () => ({
    type: EVENT_PRODUCTS_DRAWER.CLOSE
  })
};

export type ActivityOpenProps = {
  activity?: EventActivityObject;
  editActivity?: true;
};

export type ActivityOpenOperation = (input: ActivityOpenProps) => void;

export const activityDrawerOperations = {
  open: ({ activity, editActivity }: ActivityOpenProps = {}) => ({
    type: ACTIVITY_DRAWER.OPEN,
    activity,
    editActivity
  }),
  close: () => ({
    type: ACTIVITY_DRAWER.CLOSE
  })
};

export type OpenCalendarWizard = (start?: string) => void;
export const calendarWizardOperations = {
  open: startTime => ({
    type: CALENDAR_WIZARD_DRAWER.OPEN,
    startTime
  }),
  close: () => ({
    type: CALENDAR_WIZARD_DRAWER.CLOSE
  })
};

export const appointmentDrawerOperations = {
  open: () => {
    return {
      type: APPOINTMENT_DRAWER.OPEN
      // defaultValues: defaultValues || { eventId }
    };
  },
  close: () => ({
    type: APPOINTMENT_DRAWER.CLOSE
  })
};

export const reportFieldsDrawerOperations: DrawerOperations = {
  open: () => ({
    type: REPORT_FIELDS_DRAWER.OPEN
  }),
  close: () => ({
    type: REPORT_FIELDS_DRAWER.CLOSE
  })
};

export const emailThreadsDrawerOperations: DrawerOperations = {
  open: () => ({
    type: EMAIL_THREADS_DRAWER.OPEN
  }),
  close: () => ({
    type: EMAIL_THREADS_DRAWER.CLOSE
  })
};

export const emailComposerModalOperations: DrawerOperations = {
  open: () => ({
    type: EMAIL_COMPOSER_MODAL.OPEN
  }),
  close: () => ({
    type: EMAIL_COMPOSER_MODAL.CLOSE
  })
};
export const eventDocumentDrawerOperations: DrawerOperations = {
  open: () => ({
    type: EVENT_DOCUMENT_DRAWER.OPEN
  }),
  close: () => ({
    type: EVENT_DOCUMENT_DRAWER.CLOSE
  })
};

export const documentDetailDrawerOperations: DocumentDetailDrawerOperationsProps = {
  open: document => ({
    type: DOCUMENT_DETAILS_DRAWER.OPEN,
    document
  }),
  close: () => ({
    type: DOCUMENT_DETAILS_DRAWER.CLOSE
  })
};

export const eventManagementDrawerOperations: EventManagementDrawerOperationsProps = {
  open: (version, mode, tabIndex) => ({
    type: EVENT_MANAGEMENT_DRAWER.OPEN,
    version,
    mode,
    tabIndex
  }),
  close: () => ({
    type: EVENT_MANAGEMENT_DRAWER.CLOSE
  })
};

export const changeEventManagementVersion = (version: EventManagementVersionTypes) => ({
  type: CHANGE_EVENT_FORM_VERSION,
  version
});

export type BookLeadOperations = { request: () => void; finish: () => void };
export type BookLeadRequest = () => void;
export type BookedDateFinish = () => void;

export const bookLeadOperations = {
  request: () => ({
    type: BOOK_LEAD_OPERATIONS.REQUEST
  }),
  finish: () => ({
    type: BOOK_LEAD_OPERATIONS.FINISH
  })
};

export type EventTaskOpenProps = {
  eventTask?: EventTask;
  editEventTask?: true;
};

export type EventTaskOpenOperation = (input?: EventTaskOpenProps) => void;

export const eventTaskDrawerOperations = {
  open: (input?: EventTaskOpenProps) => ({
    type: EVENT_TASK_DRAWER.OPEN,
    ...input
  }),
  close: () => ({
    type: EVENT_TASK_DRAWER.CLOSE
  })
};
