/* eslint-disable prefer-object-spread */
import moment from 'moment';
// import _some from 'lodash/some';
import { LayoutId, NewBookedEventFormValues, RawEvent, RawPaymentStatus } from 'venuex-types';

export type FormPaymentStatus = {
  schedule: { date: string }[];
};

type FormSelectOption = {
  value: string;
  label: string;
};
export interface EventFormData extends Omit<Partial<RawEvent>, 'created' | 'paymentStatus'> {
  paymentStatus?: FormPaymentStatus;
  eventTeam?: FormSelectOption[];
  layoutOptions?: LayoutId[];
}

export const parseEventFormData = (data: EventFormData) => {
  // handle legacy payments

  const parsePayments = (payments: { date: string }[]) => payments?.map(payment => payment?.date);
  const paymentStatusObject = data.paymentStatus?.schedule
    ? ({
        status: 'current',
        hostAppNotification: false,
        schedule: parsePayments(data.paymentStatus.schedule)
      } as RawPaymentStatus)
    : undefined;

  // handle event team array

  const staff = data.eventTeam ? data.eventTeam.map(teamMember => teamMember.value) : undefined;

  // handle layout option

  const end =
    data?.start && !data?.end
      ? moment(data.start)
          .add(4, 'hours')
          .toString()
      : undefined;

  const minimumGuests =
    data.minimumGuests && typeof data?.minimumGuests === 'string'
      ? parseInt(data.minimumGuests, 10)
      : data.minimumGuests;

  const parsedData = {
    ...(staff && { staff }),
    ...(paymentStatusObject && { paymentStatus: paymentStatusObject }),
    ...(end && { end }),
    ...(minimumGuests && { minimumGuests })
  };

  const newData: Partial<RawEvent> = Object.assign({}, data, parsedData);
  return newData;
};

// const parseDocuments = (documentLibraryFiles, eventDocuments) =>
//   documentLibraryFiles
//     ?.filter(document => eventDocuments?.some(eventDoc => eventDoc === document.documentId))
//     ?.map(doc => ({
//       filename: doc.documentName,
//       url: doc.url,
//       label: doc.documentName,
//       description: doc.description,
//       reference: doc.documentId,
//       formDoc: doc.formDoc
//     }));

export const handleCreateEventData = (data, documentLibraryFiles) => {
  console.log('create', data?.layoutOptions);
  const eventObject: NewBookedEventFormValues = {
    leadInfo: data?.leadInfo,
    dayOfMessage: data?.dayOfMessage,
    clientName: data?.clientName,
    eventCode: data?.eventCode,
    consultant: data?.consultant, // is the login user event team, ,
    eventType: data?.eventType,
    // layoutLockDays: from the venue side,
    layoutLockDisabled: data?.layoutLockDisabled,
    layoutOptions: data?.layoutOptions?.map(layout => layout.value),
    photoSharing: data?.photoSharing,
    minimumGuests: parseInt(data.minimumGuests, 10), // parse to integer
    room: data?.room,
    layout: data?.layout,
    staff: data?.staff,
    start: data?.start,
    end: moment(data.start)
      .add(4, 'hours')
      .toString(),
    title: data?.title,
    // additionalDocuments: parseDocuments(documentLibraryFiles, data.additionalDocuments),
    notes: data?.notes,
    contacts: [data.consultant],
    usersToCreate: data?.users
  };

  return eventObject;
};

export const handleUpdateEventData = data => {
  const parsedEventObject = parseEventFormData(data);
  return parsedEventObject;
};

// deprecated
export const getDefaultValues = ({
  formValues,
  lockLayoutDays,
  userId,
  firstname,
  lastname,
  guestMobileApp,
  customFields,
  activeEvent
}) => {
  throw new Error('old default value');
  const defaultValues = {
    ...(activeEvent && activeEvent)

    // HM - Commented out in case needed for reference, read event now handles this well

    // eventType: formValues?.eventType,
    // title: formValues?.title,
    // clientName: formValues?.clientName,
    // consultant: formValues?.consultant || userId,
    // contactTracing: formValues?.contactTracing,
    // start: formValues?.start,
    // eventCode: formValues?.eventCode,
    // layoutLockDays: formValues?.layoutLockDays || lockLayoutDays,
    // room: formValues?.room,
    // minimumGuests: formValues?.minimumGuests,
    // layout: formValues?.layout,
    // selectedLayoutOptions: formValues?.alternateLayoutOptions,
    // layoutLockDisabled: formValues?.layoutLockDisabled,
    // notes: formValues?.notes,
    // photoSharing: formValues?.photoSharing || guestMobileApp,
    // eventTeam: formValues?.eventTeam || [
    //   {
    //     value: userId,
    //     label: `${firstname} ${lastname}`
    //   }
    // ],
    // payments: formValues?.payments?.map(date => ({ date })) || [''],
    // users: formValues?.users || [
    //   {
    //     firstname: '',
    //     lastname: '',
    //     email: ''
    //   }
    // ],
    // customFields
  };

  if (activeEvent?.paymentStatus?.schedule) {
    const schedule = activeEvent.paymentStatus.schedule.map(pmt => {
      if (pmt.date) return pmt;
      return { date: new Date(pmt) };
    });
    defaultValues.paymentStatus.schedule = schedule;
  }

  return defaultValues;
};
