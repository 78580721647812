import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

// Generic type for the actions
type ActionCreatorsMap = {
  [key: string]: (...args: any[]) => Action | ((dispatch: Dispatch) => void);
};

export function useActions<T extends ActionCreatorsMap>(
  actions: T
): {
  [K in keyof T]: (...args: Parameters<T[K]>) => ReturnType<T[K]>;
} {
  const dispatch = useDispatch();

  return useMemo(
    () => bindActionCreators(actions, dispatch) as any, // This cast is to make TypeScript happy. In reality, bindActionCreators should be close to our return type
    [dispatch]
  );
}
