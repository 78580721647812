const LOGIN_ERROR_EMAIL = 'Missing and/or invalid email.';

const LOGIN_ERROR_PASSWORD = 'Missing and/or invalid password.';

const BREAKPOINTS = {
  sm: 600,
  md: 980,
  lgs: 1213,
  lg: 1347, // 1200
  xl: 1500
};

exports.LOGIN_ERROR_EMAIL = LOGIN_ERROR_EMAIL;
exports.LOGIN_ERROR_PASSWORD = LOGIN_ERROR_PASSWORD;
exports.BREAKPOINTS = BREAKPOINTS;
