import { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { CgWebsite } from 'react-icons/cg';
import { GoChecklist } from 'react-icons/go';
import Colors from 'src/styles/Colors';
import { useEvents, useEventsDetails, useVenue } from 'src/v2/Hooks/CollectionHooks';
import { Document } from 'src/v2/UIComponents/Primitives/Icons';
import { MdOutlineSpeakerNotes, MdTask } from 'react-icons/md';
import { BsClipboardCheck } from 'react-icons/bs';
import { MenuItem } from './components/MenuItem';

type TabItem = {
  title: string;
  key: string;
  icon: any;
  focus?: boolean;
  featureFlag?: string;
};

const tabLinks = [
  // {
  //   title: 'Payments',
  //   key: 'payments',
  //   icon: CreditCard,
  //   order: 2,
  //   featureFlag: 'advancedPayments'
  // },
  {
    title: 'Documents',
    key: 'documents',
    icon: Document,
    focus: true,
    order: 2
  },
  {
    title: 'Checklist',
    key: 'checklist',
    icon: GoChecklist,
    order: 3
  },
  {
    title: 'Activities',
    key: 'activities',
    icon: BsClipboardCheck,
    order: 4
  },
  {
    title: 'Notes',
    key: 'notes',
    icon: MdOutlineSpeakerNotes,
    order: 5
  },
  {
    title: 'Tasks',
    key: 'tasks',
    icon: MdTask,
    order: 6
  }
  // {
  //   title: 'Questionnaire',
  //   key: 'event',
  //   icon: AiOutlineForm,
  //   focus: true,
  //   order: 4,
  //   featureFlag: 'customFields'
  // }

  // feat/calendar-appointments
  // {
  //   title: 'Appointments',
  //   key: 'appointments',
  //   icon: BiCalendar,
  //   order: 6
  // },
  // feat/emails
  // {
  //   title: 'Emails',
  //   key: 'emails',
  //   icon: BiEnvelope,
  //   order: 7
  // }
];

export const Navbar = () => {
  const [tabsList, setTabsList] = useState<TabItem[]>(tabLinks);
  const { activeItem, setActiveItem } = useEventsDetails();
  const { fetchingEvent } = useEvents();

  const { featureFlags } = useVenue();

  useEffect(() => {
    const availableTabs = tabsList.filter(t => {
      if (!t.featureFlag) return true;
      return featureFlags[t.featureFlag];
    });
    if (availableTabs.length !== tabsList.length) {
      setTabsList(availableTabs);
    }
  }, [featureFlags, tabsList]);

  const handleItemClick = (key, focus = false) => {
    setActiveItem({ activeItem: key });
  };

  return (
    <Flex bg={Colors.white} paddingX="10px" borderBottom="1px solid #ededed" minH="1px">
      <MenuItem
        icon={CgWebsite}
        isActive={activeItem === 'overview'}
        onClick={() => handleItemClick('overview')}
        isDisabled={fetchingEvent}
      >
        Overview
      </MenuItem>
      {tabsList?.map((tab, key) => (
        <>
          <MenuItem
            key={key}
            icon={tab.icon}
            isActive={activeItem === tab.key}
            onClick={() => handleItemClick(tab.key, tab.focus)}
            isDisabled={fetchingEvent}
          >
            {tab.title}
          </MenuItem>
        </>
      ))}
      {/* {actionButtons[focusedTab]} */}
    </Flex>
  );
};
