import React from 'react';
import PropTypes from 'prop-types';
import {
  GUEST_MINIMUM_PLACEHOLDER,
  GUEST_MINIMUM_REQUIRED,
  GUEST_MINIMUM_LABEL
} from 'src/v2/Drawers/EventFormDrawer/constants';
import { TextFieldWithLabel } from 'src/v2/UIComponents/FormComponents';

export const GuestMinimumInput = ({ optional = false, bookEvent }) => {
  const guestMinimumProps = {
    name: 'minimumGuests',
    requiredMessage: GUEST_MINIMUM_PLACEHOLDER,
    isRequired: bookEvent,
    label: GUEST_MINIMUM_LABEL,
    placeholder: GUEST_MINIMUM_PLACEHOLDER,
    rules: { required: bookEvent && GUEST_MINIMUM_REQUIRED }
  };
  return <TextFieldWithLabel {...guestMinimumProps} />;
};
