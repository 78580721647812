const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const getDateDisplay = date => {
  const jsDate = new Date(date);
  const shortDate = jsDate.toLocaleDateString();
  const time = jsDate.toLocaleTimeString().replace(/(.*)\D\d+/, '$1');
  const dayOfWeek = days[jsDate.getDay()];
  const month = months[jsDate.getMonth()];
  const year = jsDate.getFullYear();
  const monthDay = jsDate.getDate();
  const longDate = `${dayOfWeek}, ${month} ${monthDay} ${year}`;
  const longDateTime = `${dayOfWeek}, ${month} ${monthDay}, ${year} ${time}`;
  const shortDateTime = `${shortDate} ${time}`;
  return { shortDate, time, dayOfWeek, longDate, longDateTime, shortDateTime };
};
