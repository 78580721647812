import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../styles/Button';
import Colors from '../../styles/Colors';
import ChangePasswordStyles from './styles';

const ChangePasswordFooter = ({ closeModal, handleSubmit, disabled }) => {
  return (
    <ChangePasswordStyles.Footer>
      <Button
        label={'Cancel'}
        padding={'40px'}
        bgColor={Colors.white}
        color={Colors.gray7D}
        action={closeModal}
      />
      <Button label={'Update Password'} action={handleSubmit} disabled={disabled} />
    </ChangePasswordStyles.Footer>
  );
};

ChangePasswordFooter.propTypes = {
  closeModal: PropTypes.func,
  handleSubmit: PropTypes.func,
  disabled: PropTypes.bool
};

export default ChangePasswordFooter;
