import {
  ADD_TABLE_TO_LAYOUT,
  SELECT_TABLE,
  UNSELECT_TABLE,
  SAVE_LAYOUT_REQUEST,
  SAVE_LAYOUT_SUCCESS,
  SAVE_LAYOUT_FAILURE,
  ADD_TABLES_TO_LAYOUT,
  CLEAR_EDITOR,
  EDIT_TABLE_SETTINGS,
  DELETE_TABLE,
  SELECT_TOOL,
  UNSELECT_TOOL,
  SELECT_TEXT,
  ADD_TEXT_TO_LAYOUT,
  EDIT_TEXT_SETTINGS,
  UNSELECT_TEXT,
  ADD_SHAPE_TO_LAYOUT,
  SELECT_SHAPE,
  UNSELECT_SHAPE,
  EDIT_SHAPE_SETTINGS,
  ADD_SHAPES_TO_LAYOUT,
  ADD_TEXTS_TO_LAYOUT,
  DELETE_SHAPE,
  DELETE_TEXT,
  CHANGE_SAVE_INDICATOR,
  UPDATE_STATUS_REQUEST,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAILURE
} from './types';

export const addTableToLayout = ({ table }) => ({
  type: ADD_TABLE_TO_LAYOUT,
  table
});

export const addShapeToLayout = ({ shape }) => ({
  type: ADD_SHAPE_TO_LAYOUT,
  shape
});

// edit mode
export const addTablesToLayout = ({ tables }) => ({
  type: ADD_TABLES_TO_LAYOUT,
  tables
});

export const addShapesToLayout = ({ shapes }) => ({
  type: ADD_SHAPES_TO_LAYOUT,
  shapes
});

export const addTextToLayout = ({ text }) => ({
  type: ADD_TEXT_TO_LAYOUT,
  text
});

export const addTextsToLayout = ({ texts }) => ({
  type: ADD_TEXTS_TO_LAYOUT,
  texts
});

export const editTableSettings = ({ table }) => ({
  type: EDIT_TABLE_SETTINGS,
  table
});

export const editTextSettings = ({ text }) => ({
  type: EDIT_TEXT_SETTINGS,
  text
});

export const editShapeSettings = ({ shape }) => ({
  type: EDIT_SHAPE_SETTINGS,
  shape
});

export const selectTable = table => ({
  type: SELECT_TABLE,
  table
});

export const selectShape = shape => ({
  type: SELECT_SHAPE,
  shape
});

export const selectText = text => ({
  type: SELECT_TEXT,
  text
});

export const unselectText = () => ({
  type: UNSELECT_TEXT
});

export const unselectTable = () => ({
  type: UNSELECT_TABLE
});

export const unselectShape = () => ({
  type: UNSELECT_SHAPE
});

export const saveLayoutRequest = ({ layout }) => ({
  type: SAVE_LAYOUT_REQUEST,
  layout
});

export const saveLayoutSuccess = ({ layout }) => ({
  type: SAVE_LAYOUT_SUCCESS,
  layout
});

export const saveLayoutFailure = ({ error }) => ({
  type: SAVE_LAYOUT_FAILURE,
  error
});

export const clearEditor = () => ({
  type: CLEAR_EDITOR
});

export const deleteTable = ({ table }) => ({
  type: DELETE_TABLE,
  table
});

export const deleteShape = ({ shape }) => ({
  type: DELETE_SHAPE,
  shape
});

export const deleteText = ({ text }) => ({
  type: DELETE_TEXT,
  text
});

export const selectTool = tool => ({
  type: SELECT_TOOL,
  tool
});

export const unselectTool = () => ({
  type: UNSELECT_TOOL
});

export const changeSaveIndicator = value => ({
  type: CHANGE_SAVE_INDICATOR,
  value
});

export const updateStatusRequest = ({ layoutId, status }) => ({
  type: UPDATE_STATUS_REQUEST,
  layoutId,
  status
});

export const updateStatusSuccess = ({ layout }) => ({
  type: UPDATE_STATUS_SUCCESS,
  layout
});

export const updateStatusFailure = ({ error }) => ({
  type: UPDATE_STATUS_FAILURE,
  error
});
