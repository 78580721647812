import { takeLatest, put, call, select } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import { Icon } from '@chakra-ui/react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import {
  productCategoriesDrawerOperations,
  productDrawerOperations,
  rateTypeDrawerOperations,
  taxRateDrawerOperations
} from 'src/v2/Drawers/Redux/operations';

import {
  VENUES_LIST_REQUEST,
  VENUES_LIST_FAILURE,
  VENUES_LIST_SUCCESS,
  VENUES_LOADMORE_REQUEST,
  VENUES_LOADMORE_FAILURE,
  VENUES_LOADMORE_SUCCESS,
  VENUE_READ_REQUEST,
  VENUE_READ_FAILURE,
  VENUE_READ_SUCCESS,
  UPDATE_VENUE_REQUEST,
  UPDATE_VENUE_FAILED,
  UPDATE_VENUE_SUCCESS,
  GET_HOST_DETAILS_REQUEST,
  GET_HOST_DETAILS_FAILED,
  GET_HOST_DETAILS_SUCCESS,
  EDIT_VENUE_REQUEST,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_CATEGORY_REQUEST,
  CREATE_RATE_TYPE_REQUEST,
  CREATE_TAX_RATE_REQUEST
} from './types';

import { LOGOUT_REQUEST } from '../LoginContainer/types';

import {
  list,
  read,
  updateTutorial,
  getHostDetails,
  updateVenuePaymentsApi,
  createProductAction,
  createProductCategoryAction,
  createRateTypeAction,
  createTaxRateAction
} from './actions';
import {
  editVenueSuccess,
  editVenueFailed,
  createProductFailure,
  createRateTypeFailure
} from './operations';
import { closeOverlayLoader, openOverlayLoader } from '../OverlayLoader/operations';

const getToken = state => state.loginReducer.token;
const getVenueId = state => state.venueValuesReducer.venue.venueId;
const getUserId = state => state.loginReducer.uid;

// TODO: #721 Venue Sagas: Use correct toast styles

function* workerVenuesList({ token, limit }) {
  try {
    const response = yield call(list, { token, limit });

    const { success, query, venues } = response.data;

    yield put({
      type: VENUES_LIST_SUCCESS,
      success,
      query,
      venues
    });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: LOGOUT_REQUEST, token });
    } else {
      yield put({ type: VENUES_LIST_FAILURE, ...error.response });
    }
  }
}

function* workerGetHostDetails({ token, userId }) {
  try {
    const response = yield call(getHostDetails, { token, id: userId });
    const { user } = response.data;
    yield put({
      type: GET_HOST_DETAILS_SUCCESS,
      hostDetails: user
    });
  } catch (error) {
    yield put({ type: GET_HOST_DETAILS_FAILED, getHostError: error.response.data });
  }
}

function* workerVenuesLoadMore({ token, limit, startAfter }) {
  try {
    const response = yield call(list, { token, limit, startAfter });

    const { success, query, venues } = response.data;

    yield put({
      type: VENUES_LOADMORE_SUCCESS,
      success,
      query,
      venues
    });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: LOGOUT_REQUEST, token });
    } else {
      yield put({ type: VENUES_LOADMORE_FAILURE, ...error.response });
    }
  }
}

function* workerVenueRead({ token, id }) {
  try {
    const userId = yield select(getUserId);

    const response = yield call(read, { token, id });

    const { success, venue, layouts } = response.data;
    yield put({
      type: VENUE_READ_SUCCESS,
      success,
      venue,
      layouts
    });
    yield put({ type: GET_HOST_DETAILS_REQUEST, token, userId });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: LOGOUT_REQUEST, token });
    } else {
      yield put({ type: VENUE_READ_FAILURE, ...error.response.data });
    }
  }
}

function* workerUpdateTutorial({ data }) {
  try {
    const userId = yield select(getUserId);
    const token = yield select(getToken);

    const response = yield call(updateTutorial, { token, id: userId, data });

    if (response.data && response.data.success) {
      yield put({ type: UPDATE_VENUE_SUCCESS, data: response.data });
      // yield put({ type: VENUE_READ_REQUEST, token, id: venueId });
    }
  } catch (error) {
    yield put({ type: UPDATE_VENUE_FAILED, error: error.response });
  }
}

function* workerEditVenue({ data }) {
  try {
    const token = yield select(getToken);
    const venueId = yield select(getVenueId);
    const response = yield call(updateVenuePaymentsApi, { token, venueId, data });

    if (response.data.success) {
      yield put(editVenueSuccess());
    }
  } catch (error) {
    yield put(editVenueFailed(error));
  }
}

function* workerCreateProduct({ product }) {
  try {
    yield put(openOverlayLoader());
    const token = yield select(getToken);
    console.log({ token });
    const response = yield call(createProductAction, { product, token });
    const { success, venue } = response?.data || {};
    if (success) {
      // the backend returns the list of leads with the new lead
      yield put({
        type: VENUE_READ_SUCCESS,
        success,
        venue
      });
      toast('Saved successfully', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
    }
    yield put(productDrawerOperations.close());
  } catch (error) {
    yield put(createProductFailure(error.response.data.error, error.response.data.errors));
  } finally {
    yield put(closeOverlayLoader());
  }
}

function* workerCreateProductCategory({ category }) {
  try {
    yield put(openOverlayLoader());
    const token = yield select(getToken);
    const response = yield call(createProductCategoryAction, { category, token });
    const { success, venue } = response?.data || {};
    if (success) {
      // the backend returns the list of leads with the new lead
      yield put({
        type: VENUE_READ_SUCCESS,
        success,
        venue
      });
      toast('Saved successfully', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
    }
    yield put(productCategoriesDrawerOperations.close());
  } catch (error) {
    yield put(createProductFailure(error.response.data.error, error.response.data.errors));
  } finally {
    yield put(closeOverlayLoader());
  }
}

function* workerCreateRateType({ rateType }) {
  try {
    yield put(openOverlayLoader());
    const token = yield select(getToken);
    const response = yield call(createRateTypeAction, { rateType, token });
    const { success, venue } = response?.data || {};
    if (success) {
      // the backend returns the list of leads with the new lead
      yield put({
        type: VENUE_READ_SUCCESS,
        success,
        venue
      });
      toast('Saved successfully', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
    }
    yield put(rateTypeDrawerOperations.close());
  } catch (error) {
    yield put(createRateTypeFailure(error.response.data.error, error.response.data.errors));
  } finally {
    yield put(closeOverlayLoader());
  }
}

function* workerCreateTaxRate({ taxRate }) {
  try {
    yield put(openOverlayLoader());
    const token = yield select(getToken);
    const response = yield call(createTaxRateAction, { taxRate, token });
    const { success, venue } = response?.data || {};
    if (success) {
      // the backend returns the list of leads with the new lead
      yield put({
        type: VENUE_READ_SUCCESS,
        success,
        venue
      });
      toast('Saved successfully', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
    }
    yield put(taxRateDrawerOperations.close());
  } catch (error) {
    yield put(createRateTypeFailure(error.response.data.error, error.response.data.errors));
  } finally {
    yield put(closeOverlayLoader());
  }
}

// EXPORTS
export function* watcherVenuesList() {
  yield takeLatest(VENUES_LIST_REQUEST, workerVenuesList);
}

export function* watcherVenuesLoadMore() {
  yield takeLatest(VENUES_LOADMORE_REQUEST, workerVenuesLoadMore);
}

export function* watcherVenueRead() {
  yield takeLatest(VENUE_READ_REQUEST, workerVenueRead);
}

export function* watcherUpdateTutorial() {
  yield takeLatest(UPDATE_VENUE_REQUEST, workerUpdateTutorial);
}

export function* watcherGetHostDetails() {
  yield takeLatest(GET_HOST_DETAILS_REQUEST, workerGetHostDetails);
}

export function* watcherEditVenue() {
  yield takeLatest(EDIT_VENUE_REQUEST, workerEditVenue);
}

export function* watcherCreateProduct() {
  yield takeLatest(CREATE_PRODUCT_REQUEST, workerCreateProduct);
}

export function* watcherCreateProductCategory() {
  yield takeLatest(CREATE_PRODUCT_CATEGORY_REQUEST, workerCreateProductCategory);
}

export function* watcherCreateRateType() {
  yield takeLatest(CREATE_RATE_TYPE_REQUEST, workerCreateRateType);
}

export function* watcherCreateTaxRate() {
  yield takeLatest(CREATE_TAX_RATE_REQUEST, workerCreateTaxRate);
}
