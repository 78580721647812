import { takeLatest, put, call } from 'redux-saga/effects';
import {
  VERIFY_FAILURE,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS
} from './types';

import { reset, resetPassword } from './actions';

function* workerReset({ search }) {
  try {
    const response = yield call(reset, { search });
    const { success } = response.data;
    yield put({ type: VERIFY_SUCCESS, success });
  } catch (error) {
    yield put({ type: VERIFY_FAILURE, ...error.response.data });
  }
}

function* workerResetPassword({ token, uid, venueId, eventId, password, confirm }) {
  try {
    const response = yield call(resetPassword, { token, uid, venueId, eventId, password, confirm });
    const { success } = response.data;
    yield put({ type: RESET_PASSWORD_SUCCESS, success });
  } catch (error) {
    yield put({ type: RESET_PASSWORD_FAILURE, ...error.response.data });
  }
}

export function* watcherReset() {
  yield takeLatest(VERIFY_REQUEST, workerReset);
}

export function* watcherResetPassword() {
  yield takeLatest(RESET_PASSWORD_REQUEST, workerResetPassword);
}
