import { ADD_EVENT_DOCUMENTS_REQUEST } from '../AddEventModal/types';
import {
  GET_LEADS_FAILURE,
  GET_LEADS_REQUEST,
  GET_LEADS_SUCCESS,
  GET_LEAD_REQUEST,
  GET_LEAD_SUCCESS,
  GET_LEAD_FAILURE,
  OPEN_LEADS_DETAILS,
  CLOSE_LEADS_DETAILS,
  OPEN_LEADS_FORM,
  CLOSE_LEADS_FORM,
  OPEN_LEADS_NOTES_FORM,
  CLOSE_LEADS_NOTES_FORM,
  ADD_LEAD_REQUEST,
  ADD_LEAD_SUCCESS,
  ADD_LEAD_FAILURE,
  ADD_NOTE_REQUEST,
  ADD_NOTE_SUCCESS,
  ADD_NOTE_FAILURE,
  DELETE_LEAD_REQUEST,
  DELETE_LEAD_SUCCESS,
  DELETE_LEAD_FAILURE,
  UPDATE_LEAD_REQUEST,
  UPDATE_LEAD_SUCCESS,
  UPDATE_LEAD_FAILURE,
  OPEN_CONTRACT_MODAL,
  CLOSE_CONTRACT_MODAL,
  OPEN_PROPOSAL_MODAL,
  CLOSE_PROPOSAL_MODAL
} from './types';

export const getLeadsRequest = ({ getEvents = false } = {}) => ({
  type: GET_LEADS_REQUEST,
  getEvents
});

export const getLeadsSuccess = leads => ({
  type: GET_LEADS_SUCCESS,
  leads
});

export const getLeadsFailure = error => ({
  type: GET_LEADS_FAILURE,
  error
});

export const getLeadRequest = ({ id }) => ({
  type: GET_LEAD_REQUEST,
  id
});

export const getLeadSuccess = lead => ({
  type: GET_LEAD_SUCCESS,
  lead
});

export const getLeadFailure = error => ({
  type: GET_LEAD_FAILURE,
  error
});

export const openLeadsDetails = ({ tabIndex = 0 } = {}) => ({
  type: OPEN_LEADS_DETAILS,
  tabIndex
});

export const closeLeadsDetails = () => ({
  type: CLOSE_LEADS_DETAILS
});

export const openLeadsForm = ({ tabIndex }) => ({
  type: OPEN_LEADS_FORM,
  tabIndex
});

export const closeLeadsForm = () => ({
  type: CLOSE_LEADS_FORM
});

export const openLeadNotesForm = ({ editNote, note }) => ({
  type: OPEN_LEADS_NOTES_FORM,
  editNote,
  note
});

export const closeLeadsNotesForm = () => ({
  type: CLOSE_LEADS_NOTES_FORM
});

export const addLeadRequest = ({ lead }) => ({
  type: ADD_LEAD_REQUEST,
  lead
});

export const addLeadSuccess = ({ success, lead }) => ({
  type: ADD_LEAD_SUCCESS,
  lead
});

export const addLeadFailure = error => ({
  type: ADD_LEAD_FAILURE,
  error
});

export const addNoteRequest = ({ note, id }) => ({
  type: ADD_NOTE_REQUEST,
  note,
  id
});

export const addNoteSuccess = ({ note }) => ({
  type: ADD_NOTE_SUCCESS,
  note
});

export const addNoteFailure = error => ({
  type: ADD_NOTE_FAILURE,
  error
});

export const deleteLead = ({ id }) => ({
  type: DELETE_LEAD_REQUEST,
  id
});

export const deleteLeadSuccess = success => ({
  type: DELETE_LEAD_SUCCESS,
  success
});

export const deleteLeadFailure = error => ({
  type: DELETE_LEAD_FAILURE,
  error
});

export const updateLeadRequest = ({ id, event }) => ({
  type: UPDATE_LEAD_REQUEST,
  id,
  event
});

export const updateLeadSuccess = ({ lead }) => {
  return {
    type: UPDATE_LEAD_SUCCESS,
    lead
  };
};

export const updateLeadFailure = (error, errors) => ({
  type: UPDATE_LEAD_FAILURE,
  error,
  errors
});

export const openContractModal = () => ({
  type: OPEN_CONTRACT_MODAL
});

export const closeContractModal = () => ({
  type: CLOSE_CONTRACT_MODAL
});

export const openProposalModal = () => ({
  type: OPEN_PROPOSAL_MODAL
});

export const closeProposalModal = () => ({
  type: CLOSE_PROPOSAL_MODAL
});
