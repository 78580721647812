import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export const addEventVendorApi = ({ token, eventId, vendor }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/eventvendors/${eventId}`,
    headers: { Authorization: token },
    data: vendor
  });
};

export const updateEventVendorApi = ({ token, eventId, vendorId, vendor }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/eventvendors/${eventId}/${vendorId}`,
    headers: { Authorization: token },
    data: vendor
  });
};

export const deleteEventVendorApi = ({ token, eventId, vendorId }) => {
  return axios({
    method: 'delete',
    url: `${apiUri}/eventvendors/${eventId}/${vendorId}`,
    headers: { Authorization: token }
  });
};

export const getEventVendorsByEventId = ({ token, eventId }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/eventvendors/${eventId}`,
    headers: { Authorization: token }
  });
};
