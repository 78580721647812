import { Menu, MenuButton, MenuList, MenuItem, Text, Divider } from '@chakra-ui/react';
import { FC, ReactElement, ReactNode } from 'react';
import Colors from 'src/styles/Colors';
import { Button } from 'src/v2/UIComponents/Primitives';

export interface ButtonDropdownItem {
  icon: ReactElement;
  onClick: () => void;
  label: string;
}

export interface ButtonDropdownProps {
  children: ReactNode;
  variant?: string;
  items?: ButtonDropdownItem[];
  title?: string;
  noItemsText?: string;
  rightIcon?: ReactNode;
  width?: number;
  as?: any;
  isDisabled?: boolean;
  icon?: ReactNode;
}

export const ButtonDropdown = ({
  children,
  variant = 'primaryOutline',
  items,
  title,
  noItemsText = '',
  rightIcon,
  width,
  as = Button,
  icon,
  isDisabled,
  ...props
}: ButtonDropdownProps) => (
  <Menu>
    <MenuButton
      as={as}
      variant={variant}
      icon={icon}
      rightIcon={rightIcon}
      width={width}
      _disabled={{
        opacity: 0.4,
        cursor: 'not-allowed'
      }}
      isDisabled={isDisabled}
      {...props}
    >
      {children}
    </MenuButton>
    <MenuList zIndex="999">
      {title && (
        <>
          <Text
            textTransform="capitalize"
            fontFamily="lora"
            fontSize="16px"
            lineHeight="34px"
            color="#181818"
            textAlign="center"
          >
            {title}
          </Text>
          <Divider marginBottom="5px" />
        </>
      )}
      {noItemsText && (
        <Text
          padding="0.4rem 0.8rem"
          textTransform="capitalize"
          fontFamily="Lato"
          fontSize="14px"
          color="#181818"
          fontWeight="bold"
        >
          {noItemsText}
        </Text>
      )}
      {items &&
        items.map((item, key) => (
          <MenuItem
            icon={item.icon}
            key={key}
            onClick={item.onClick}
            _active={{ background: 'grayED' }}
            _focus={{ background: 'grayED' }}
            // _hover={{ bg: Colors.grayFA }}
          >
            <Text
              fontSize="15px"
              // color="var(--colors-primary)"
              textTransform="capitalize"
              fontFamily="Lato"
            >
              {item.label}
            </Text>
          </MenuItem>
        ))}
    </MenuList>
  </Menu>
);
