import { FC, ReactElement, ReactNode } from 'react';
import {
  Button,
  ButtonDropdown,
  ButtonDropdownItem,
  IconButton
} from 'src/v2/UIComponents/Primitives';

// import { IconButton } from '@chakra-ui/react';

export interface ToolbarActionButton {
  type?: 'button' | 'iconButton' | 'iconButtonSecondary';
  onClick: () => void;
  icon: ReactElement;
  label?: string;
  tooltipLabel?: string;
  ariaLabel?: string;
}

export interface CustomToolbarAction {
  label?: string;
  type: 'dropdown' | 'custom';
  shape?: 'round';
  component?: ReactElement;
  icon?: ReactElement;
  items?: ButtonDropdownItem[];
  variant?: string;
  // type?: 'custom' | 'dropdown' | 'button' | 'reset' | 'iconButton' | 'iconButtonSecondary';
  // label?: string;
  // icon?: ReactElement;
  // tooltipLabel?: string;

  // onClick?: () => void;
}

export type ToolbarAction = ToolbarActionButton | CustomToolbarAction;
export interface ActionsGroupProps {
  actions?: ToolbarAction[];
}

export const ActionsGroup: FC<ActionsGroupProps> = ({ actions }) => {
  return (
    <>
      {actions?.map((action, key) => {
        // const { shape = 'round' } = action;
        if (action.type === 'dropdown') {
          // <ButtonDropdown key={key} shape={shape} as={IconButton} icon={action.icon} {...action}>
          return (
            <ButtonDropdown key={key} as={IconButton} items={action.items} {...action}>
              {action.label}
            </ButtonDropdown>
          );
        }

        if (action.type === 'custom') {
          return action.component;
        }

        if (action.type === 'iconButton') {
          return (
            <IconButton
              key={action.tooltipLabel}
              tooltipLabel={action?.tooltipLabel}
              aria-label={action?.ariaLabel || action.tooltipLabel || ''}
              variant="roundedPrimary"
              {...{ ...action, type: undefined }}
            />
          );
        }
        if (action.type === 'iconButtonSecondary') {
          return (
            <IconButton
              key={action.tooltipLabel}
              tooltipLabel={action?.tooltipLabel}
              aria-label={action?.ariaLabel || action.tooltipLabel || ''}
              variant="roundedPrimaryOutline"
              {...{ ...action, type: undefined }}
            />
          );
        }

        return (
          <Button key={key} {...{ ...action, type: undefined }}>
            {action.icon}
            {action.label}
          </Button>
        );
      })}
    </>
  );
};
