import React from 'react';
import PropTypes from 'prop-types';
import TableStyles from './styles';
import Button from '../Button';
import IconLoader from '../../styles/Icons/Loader';

const Table = ({
  loading = true,
  columns = [],
  data = [],
  actions = [],
  footer,
  hasHeader = true,
  hasBody = true,
  hasFooter = true,
  noPadding = false,
  centerAlign = false
}) => {
  columns = columns.filter(column => {
    return column !== 'firstNameMerge' && column !== 'lastNameMerge' && column;
  });
  return (
    <TableStyles>
      {hasHeader && (
        <TableStyles.Header>
          {columns.length > 0 && (
            <TableStyles.HeaderRow noPadding={noPadding}>
              {columns.map((el, key) => (
                <TableStyles.Col
                  key={key.toString()}
                  size={actions && actions.length > 0 ? columns.length + 1 : columns.length}
                  centerAlign={centerAlign}
                >
                  {(el === 'cellphone' && 'Phone Number') || el}
                </TableStyles.Col>
              ))}
              {actions && actions.length > 0 && (
                <TableStyles.Col size={columns.length + 1}>&nbsp;</TableStyles.Col>
              )}
            </TableStyles.HeaderRow>
          )}
        </TableStyles.Header>
      )}
      {hasBody && (
        <TableStyles.Body>
          {!loading &&
            data &&
            data.length > 0 &&
            data.map((el, key) => (
              <TableStyles.Row key={key.toString()} noPadding={noPadding}>
                {columns.map((e, k) => (
                  <TableStyles.Col
                    key={k.toString()}
                    size={actions && actions.length > 0 ? columns.length + 1 : columns.length}
                    centerAlign={centerAlign}
                  >
                    {el[k]}
                  </TableStyles.Col>
                ))}
                {actions && actions.length > 0 && (
                  <TableStyles.Col size={columns.length + 1}>
                    {actions.map((action, k) => (
                      <span
                        data={JSON.stringify(el)}
                        className="btn btn-primary"
                        key={k.toString()}
                        onClick={action.onClick}
                      >
                        {action.label}
                      </span>
                    ))}
                  </TableStyles.Col>
                )}
              </TableStyles.Row>
            ))}

          {loading && (
            <TableStyles.Row>
              <TableStyles.Col>
                <IconLoader />
              </TableStyles.Col>
            </TableStyles.Row>
          )}
        </TableStyles.Body>
      )}
      {!loading && hasFooter && (
        <TableStyles.Footer>
          <Button label={footer.label} />
        </TableStyles.Footer>
      )}
    </TableStyles>
  );
};

Table.propTypes = {
  loading: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
  actions: PropTypes.array,
  footer: PropTypes.object,
  hasHeader: PropTypes.bool,
  hasBody: PropTypes.bool,
  hasFooter: PropTypes.bool
};

export default Table;
