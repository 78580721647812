import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip/dist';
import Alert from 'src/components/Alert';
import PolicyModal from 'src/components/PolicyModal';
import { useWindowSize } from 'src/hooks/useWindowSize';
import Constants from 'src/utils/Constants';
// import { Sidebar } from 'src/v2/UIComponents/Sidebar';
import Sidebar from 'src/components/Sidebar';
import { StyledAppWrapper, StyledMainWrapper } from './styles';

const App = ({ children, isCalendar }) => {
  const { width } = useWindowSize();

  const mobileBreakpoint = Constants.BREAKPOINTS.sm;

  const isMobile = width <= mobileBreakpoint;

  return (
    <StyledAppWrapper>
      {!isMobile && <Sidebar />}
      <StyledMainWrapper isCalendar={isCalendar}>{children}</StyledMainWrapper>
      <PolicyModal />
      <Alert />
      {!isMobile && (
        <ReactTooltip id="sidebar-tooltips" effect="solid" place="right" aria-haspopup="true" />
      )}
    </StyledAppWrapper>
  );
};

App.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isAdmin: PropTypes.bool,
  admin: PropTypes.bool,
  venueId: PropTypes.string,
  eventId: PropTypes.string,
  doVenueIdRemove: PropTypes.func,
  doVenueIdSet: PropTypes.func,
  doEventIdRemove: PropTypes.func,
  doEventIdSet: PropTypes.func,
  policyRequired: PropTypes.bool,
  venue: PropTypes.object,
  doPolicyAcceptTrigger: PropTypes.func,
  doPolicyReject: PropTypes.func,
  hideSidebar: PropTypes.bool,
  setHideSidebar: PropTypes.func,
  grayBackground: PropTypes.bool
};

export default App;
