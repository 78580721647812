import React from 'react';
import PropTypes from 'prop-types';
import ChangePasswordStyles from './styles';
import CloseButton from '../CloseButton';

const ChangePasswordHeader = ({ title, closeModal }) => {
  return (
    <ChangePasswordStyles.Header>
      <ChangePasswordStyles.CloseButton onClick={() => closeModal()}>
        <CloseButton />
      </ChangePasswordStyles.CloseButton>
      <span>{title}</span>
    </ChangePasswordStyles.Header>
  );
};

ChangePasswordHeader.propTypes = {
  title: PropTypes.string,
  closeModal: PropTypes.func
};

export default ChangePasswordHeader;
