import {
  OPEN_STAFF_DETAILS,
  CLOSE_STAFF_DETAILS,
  OPEN_STAFF_DETAILS_SUCCESS,
  OPEN_STAFF_DETAILS_FAILURE
} from './types';

const initialState = {
  isOpen: false,
  user: null,
  error: null
};

const staffDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_STAFF_DETAILS:
      return { ...state, isOpen: true, user: null };
    case CLOSE_STAFF_DETAILS:
      return { ...state, isOpen: false, user: null };
    case OPEN_STAFF_DETAILS_SUCCESS:
      return { ...state, user: action.user, error: null };
    case OPEN_STAFF_DETAILS_FAILURE:
      return { ...state, user: null, error: action.error };
    default:
      return state;
  }
};

export default staffDetailsReducer;
