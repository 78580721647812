import { takeLatest, call, put, select } from 'redux-saga/effects';
import { closeConfirmModal } from 'src/containers/ConfirmModal/operations';
import {
  FETCH_EVENT_VENDORS_REQUEST,
  FETCH_EVENT_VENDORS_SUCCESS,
  FETCH_EVENT_VENDORS_FAILURE,
  ADD_EVENT_VENDOR_REQUEST,
  ADD_EVENT_VENDOR_SUCCESS,
  ADD_EVENT_VENDOR_FAILURE,
  UPDATE_EVENT_VENDOR_REQUEST,
  UPDATE_EVENT_VENDOR_SUCCESS,
  UPDATE_EVENT_VENDOR_FAILURE,
  DELETE_EVENT_VENDOR_REQUEST,
  DELETE_EVENT_VENDOR_SUCCESS,
  DELETE_EVENT_VENDOR_FAILURE,
  GET_EVENT_VENDORS_BY_EVENT_ID_REQUEST
} from './types';
import {
  addEventVendorApi,
  updateEventVendorApi,
  deleteEventVendorApi,
  getEventVendorsByEventId
} from './actions';

import { getEventVendorsByEventIdSuccess, getEventVendorsByEventIdFailure } from './operations';

const getToken = state => state.loginReducer.token;
const getEventId = state => state.loginReducer.eventId;

function* workerFetchEventVendors() {
  try {
    const token = yield select(getToken);
    const eventId = yield select(getEventId);
    const response = yield call(getEventVendorsByEventId, { token, eventId });
    if (response.data.success) {
      yield put({ type: FETCH_EVENT_VENDORS_SUCCESS, eventVendors: response.data.vendors });
    }
  } catch (error) {
    yield put({ type: FETCH_EVENT_VENDORS_FAILURE, error: error.response.data });
  }
}

function* workerAddEventVendor(action) {
  try {
    const { vendor } = action;
    const token = yield select(getToken);
    const eventId = yield select(getEventId);
    const response = yield call(addEventVendorApi, { token, eventId, vendor });
    if (response.data.success) {
      yield put({ type: ADD_EVENT_VENDOR_SUCCESS, success: true });
      yield put({ type: FETCH_EVENT_VENDORS_REQUEST });
    }
  } catch (error) {
    yield put({ type: ADD_EVENT_VENDOR_FAILURE, error: error.response.data });
  }
}

function* workerUpdateEventVendor(action) {
  try {
    const { vendor, vendorId } = action;
    const token = yield select(getToken);
    const eventId = yield select(getEventId);
    const response = yield call(updateEventVendorApi, { token, eventId, vendorId, vendor });
    if (response.data.success) {
      yield put({
        type: UPDATE_EVENT_VENDOR_SUCCESS,
        success: true,
        updatingEventVendorSuccess: true
      });
      yield put({ type: FETCH_EVENT_VENDORS_REQUEST });
    }
  } catch (error) {
    yield put({ type: UPDATE_EVENT_VENDOR_FAILURE, error: error.response.data });
  }
}

function* workerDeleteEventVendor(action) {
  try {
    const { vendorId } = action;
    const token = yield select(getToken);
    const eventId = yield select(getEventId);
    const response = yield call(deleteEventVendorApi, { token, eventId, vendorId });
    if (response.data.success) {
      yield put({ type: DELETE_EVENT_VENDOR_SUCCESS, success: true });
      yield put({ type: FETCH_EVENT_VENDORS_REQUEST });
      yield put(closeConfirmModal());
    }
  } catch (error) {
    yield put({ type: DELETE_EVENT_VENDOR_FAILURE, error: error.response.data });
  }
}

function* workerEventVendors({ token, eventId }) {
  try {
    const response = yield call(getEventVendorsByEventId, { token, eventId });
    yield put(getEventVendorsByEventIdSuccess({ eventVendors: response.data.vendors }));
  } catch (error) {
    yield put(getEventVendorsByEventIdFailure(error));
  }
}

export function* watchFetchEventVendors() {
  yield takeLatest(FETCH_EVENT_VENDORS_REQUEST, workerFetchEventVendors);
}
export function* watchAddEventVendor() {
  yield takeLatest(ADD_EVENT_VENDOR_REQUEST, workerAddEventVendor);
}

export function* watchUpdateEventVendor() {
  yield takeLatest(UPDATE_EVENT_VENDOR_REQUEST, workerUpdateEventVendor);
}

export function* watchDeleteEventVendor() {
  yield takeLatest(DELETE_EVENT_VENDOR_REQUEST, workerDeleteEventVendor);
}

export function* watchGetEventVendors() {
  yield takeLatest(GET_EVENT_VENDORS_BY_EVENT_ID_REQUEST, workerEventVendors);
}
