import React from 'react';
import StyledModal from './modalStyles';
import ModalLoaderStyles from './styles';
import { LoadingBar } from './loadingBar/loadingBar';

const { Header, Message, Footer } = ModalLoaderStyles;

export const ModalLoader = props => {
  const { modalLoaderState } = props;
  const { modalLoaderHeader, modalLoaderMessage, showProgressBar } = modalLoaderState;
  return (
    <StyledModal {...props} zIndex={999999}>
      <Header>{modalLoaderHeader}</Header>
      {modalLoaderMessage && <Message>{modalLoaderMessage}</Message>}
      {showProgressBar && (
        <Footer>
          <LoadingBar />
        </Footer>
      )}
    </StyledModal>
  );
};

export default ModalLoader;
