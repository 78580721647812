import styled from 'styled-components';
import { Flex, Box } from '@chakra-ui/react';
import Colors from 'src/styles/Colors';

export const StyledAppWrapper = styled(Flex).attrs(props => ({
  flex: '1',
  flexDirection: 'row'
}))``;

export const StyledMainWrapper = styled(Flex).attrs(props => ({
  // flex: 1,
  width: '100%',
  overflow: 'hidden',
  padding: '10px',
  // paddingBottom: props.isCalendar && '20px',
  backgroundColor: Colors.grayFA
}))``;
