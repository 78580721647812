import { Download, Archive, Email } from 'src/v2/UIComponents/Primitives/Icons';
import styled from 'styled-components';
import { Icon, Flex } from '@chakra-ui/react';
import { HiExternalLink } from 'react-icons/hi';
import { FC } from 'react';
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineFileAdd,
  AiOutlineFileDone
} from 'react-icons/ai';
import { Tooltip } from '../../Primitives';
import { ControlInputProps } from './ControlsTypes';

const ControlWrapper = styled(Flex)`
  display: flex;
  align-items: center;
  svg {
    margin: 7px;
    opacity: 0;
    cursor: pointer;
  }
`;

export const Controls: FC<ControlInputProps> = ({
  addProps,
  editProps,
  deleteProps,
  archiveProps,
  downloadProps,
  isFormDoc,
  customAction,
  hideDeleteAction,
  hideAddNewAction,
  openDetailsProps,
  sendMessageProps,
  packetClickUrl,
  handleDownloadClick
}) => {
  const handleClick = () => {
    handleDownloadClick && handleDownloadClick();
  };
  return (
    <ControlWrapper>
      {customAction}

      {editProps && (
        <Tooltip label="Edit" aria-label="Edit" placement="left">
          <Flex>
            <Icon as={AiOutlineEdit} w={6} h={6} cursor="pointer" />
          </Flex>
        </Tooltip>
      )}
      <>
        {packetClickUrl && (
          <Tooltip label="View Signature Packet" aria-label="Download" placement="left">
            <Flex>
              <a href={packetClickUrl} target="_blank" rel="noopener noreferrer" download>
                <Icon as={AiOutlineFileDone} w={7} h={7} cursor="pointer" />
              </a>
            </Flex>
          </Tooltip>
        )}
        {isFormDoc && downloadProps && (
          <Tooltip
            label={downloadProps?.tooltip || 'Download'}
            aria-label="Download"
            placement="left"
          >
            <Flex>
              <Download w={6} h={6} onClick={handleDownloadClick} cursor="pointer" />
            </Flex>
          </Tooltip>
        )}
      </>
      {archiveProps && (
        <Tooltip label="Archive" aria-label="Archive" placement="left">
          <Flex>
            <Archive w={6} h={6} onClick={archiveProps.onClick} cursor="pointer" />
          </Flex>
        </Tooltip>
      )}
      {addProps && !hideAddNewAction && (
        <Tooltip label="Add New" aria-label="Add New" placement="left">
          <Flex>
            <Icon as={AiOutlineFileAdd} w={6} h={6} cursor="pointer" {...addProps} />
          </Flex>
        </Tooltip>
      )}
      {deleteProps && !hideDeleteAction && (
        <Tooltip label={deleteProps?.tooltip || 'Delete'} aria-label="Delete" placement="left">
          <Flex alignItems="center">
            <Icon
              as={AiOutlineDelete}
              w={6}
              h={6}
              cursor="pointer"
              onClick={deleteProps?.onClick}
            />
          </Flex>
        </Tooltip>
      )}
      {openDetailsProps && (
        <Tooltip
          label={openDetailsProps?.tooltip || 'Details'}
          aria-label="Details"
          placement="left"
        >
          <Flex alignItems="center">
            <Icon
              as={HiExternalLink}
              w={6}
              h={6}
              onClick={openDetailsProps.onClick}
              cursor="pointer"
            />
          </Flex>
        </Tooltip>
      )}
      {sendMessageProps && (
        <Tooltip label={sendMessageProps?.tooltip || 'Send'} aria-label="Send" placement="left">
          <Flex>
            <Email w={6} h={6} cursor="pointer" />
          </Flex>
        </Tooltip>
      )}
      <Tooltip label={'Download File'} aria-label="Send" placement="left">
        <Flex>
          {handleDownloadClick && <Download w={6} h={6} onClick={handleClick} cursor="pointer" />}
        </Flex>
      </Tooltip>
    </ControlWrapper>
  );
};
