import styled, { css } from 'styled-components';
import Colors from '../../styles/Colors';

export const TableWrapper = styled.div`
  background-color: #fff;
  font-size: 15px;
  height: 100%;
  flex: 1 1 auto;

  .ReactVirtualized__Table__headerRow {
    font-weight: 600;
  }
  .ReactVirtualized__Table__headerColumn:first-of-type,
  .ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 53px;
    margin-right: 39px;
  }
  .ReactVirtualized__Table__rowColumn > div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  #nameheader {
    box-sizing: border-box;
  }
  #tableheader {
    text-align: center;
  }
  #rsvpheader {
    text-align: center;
  }
  #contactHeader {
    text-align: center;
  }
  #thankyouHeader {
    text-align: center;
  }
  .ReactVirtualized__Table__Grid {
    &:focus {
      outline: none;
    }
  }
  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }
  .ReactVirtualized__Table__row {
    overflow: visible !important;
  }
  .rsvpColumn {
    overflow: visible !important;
    text-align: center;
  }
  .table-header {
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.25px;
    color: ${Colors.black22};
    /* color: #888888; */
    cursor: pointer;

    display: flex;

    &:focus {
      outline: none;
    }
  }

  /* .ReactVirtualized__Table__headerRow {
    border-top: solid 1px ${Colors.grayED};
  } */

  .table-row {
    border-bottom: solid 1px ${Colors.grayED};
    overflow: visible !important;
    &:focus {
      outline: none;
    }
    &:hover:not(.ReactVirtualized__Table__headerRow:nth-child(n)) {
      background-color: #fafafa;
        svg {
          opacity: 0.3;
          &:hover {
            opacity: 1;
          }
        }
    }
  }
  .text-bold {
    font-weight: bold;
  }
`;

export const ThankYouNote = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: ${Colors.grayD8};
`;

ThankYouNote.Yes = styled.span`
  color: ${props => props.thankYouNote === 'yes' && Colors.green2C};
  cursor: pointer;
`;

ThankYouNote.Separator = styled.span`
  margin: 0px 3px;
`;

ThankYouNote.No = styled.span`
  color: ${props => props.thankYouNote === 'no' && Colors.redC0};
  cursor: pointer;
`;

export const Contact = styled.div`
  display: flex;
  justify-content: center;
  svg {
    margin-right: 15px;
    &:nth-child(3) {
      margin-right: 0px;
    }
  }
`;

export const Controls = styled.span`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  svg {
    margin: 15px;
    opacity: 0;
    cursor: pointer;
  }
`;

export const TableNumber = styled.div`
  text-align: center;
`;

export const PartyCellStyles = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: Lato;
  > span {
    &:nth-child(1) {
      margin-right: 5px;
      font-family: Lato;
      font-weight: 500;
    }
    &:nth-child(2) {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.23px;
      color: #222222;
    }
  }
`;

export const Dot = styled.div`
  height: 20px;
  width: 20px;
  background-color: ${props => props.backgroundColor};
  margin-right: 22px;
  border-radius: 50%;
  display: inline-block;
`;

export const RSVPOptionStyles = styled.div`
  display: flex;
  align-items: center;
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.28px;
  color: #222222;
  width: fit-content;
`;

PartyCellStyles.Select = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-family: Lato;
  font-size: 14px;
  > div {
    &:nth-child(1) {
      margin-right: 5px;
    }
    &:nth-child(2) {
      span {
        margin-right: 5px;
        margin-bottom: 5px;
        font-weight: 500;
        margin-left: -3px;
      }
    }
  }
`;

export const RsvpHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NoRows = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const NoRowsTitle = styled.span`
  font-family: Lato;
  font-size: 36px;
  font-weight: 500;
  line-height: 50px;
  color: #888;
  letter-spacing: -0.45px;
`;

export const NoRowsSubtitle = styled.span`
  font-family: Lato;
  font-size: 24px;
  font-weight: 500;
  line-height: 50px;
  color: #888;
  letter-spacing: -0.45px;
`;

export const SendButton = styled.div`
  display: block;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  height: 20px;
  line-height: 20px;
  letter-spacing: -0.28px;
  color: #b0b0b0;
  cursor: pointer;
`;

export const MenuOptionStyles = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: #222222;
  width: fit-content;
  letter-spacing: -0.28px;
`;

export const MenuOptionIconStyles = styled.div`
  margin-right: 15px;
`;
