/* eslint-disable import/prefer-default-export */
import {
  OPEN_EVENT_MODAL,
  CLOSE_EVENT_MODAL,
  GET_ROOM_DETAILS_REQUEST,
  GET_ROOM_DETAILS_FAILURE,
  GET_ROOM_DETAILS_SUCCESS,
  GET_LAYOUT_DETAILS_REQUEST,
  GET_LAYOUT_DETAILS_FAILURE,
  GET_LAYOUT_DETAILS_SUCCESS,
  GET_HOSTS_REQUEST,
  GET_HOSTS_SUCCESS,
  GET_HOSTS_FAILURE,
  SET_PAYMENTSTATUS_REQUEST,
  SET_PAYMENTSTATUS_SUCCESS,
  SET_PAYMENTSTATUS_FAILURE,
  SEND_VENUETOHOSTEMAIL_REQUEST,
  SEND_VENUETOHOSTEMAIL_SUCCESS,
  SEND_VENUETOHOSTEMAIL_FAILURE,
  OPEN_EVENT_MODAL_SUCCESS,
  OPEN_EVENT_MODAL_REQUEST,
  OPEN_EVENT_MODAL_FAILURE,
  SET_ACTIVE_ITEM,
  GET_EVENT_DETAIL_SUCCESS,
  GET_EVENT_DETAIL_REQUEST,
  GET_EVENT_DETAIL_FAILURE,
  OPEN_EVENT_DETAILS_DRAWER,
  CLOSE_EVENT_DETAILS_DRAWER,
  OPEN_EVENT_FORM_DRAWER,
  CLOSE_EVENT_FORM_DRAWER
} from './types';

const initialState = {
  fetching: false,
  isOpen: false,
  activeItem: 'overview',
  event: {},
  fetchingRoom: false,
  roomDetails: null,
  getRoomErrors: null,
  getHostsErrors: null,
  fetchingLayout: false,
  layoutDetails: null,
  layoutErrors: null,
  hosts: [],
  emailSending: false,
  emailSent: false,
  fetchingEvent: false,
  openEventModalError: null,
  isEventDetailsDrawerOpen: false,
  isEventFormDrawerOpen: false
};

export const eventDetailsModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_EVENT_MODAL_REQUEST:
      return { ...state, isOpen: true };
    case OPEN_EVENT_DETAILS_DRAWER:
      return {
        ...state,
        isEventDetailsDrawerOpen: true,
        activeItem: action.activeItem || 'overview'
      };
    case CLOSE_EVENT_DETAILS_DRAWER:
      return {
        ...state,
        isEventDetailsDrawerOpen: false,
        activeItem: 'overview',
        event: {}
      };
    case OPEN_EVENT_FORM_DRAWER:
      return {
        ...state,
        isEventFormDrawerOpen: true
      };
    case CLOSE_EVENT_FORM_DRAWER:
      return {
        ...state,
        isEventFormDrawerOpen: false
      };
    case OPEN_EVENT_MODAL_SUCCESS:
      return {
        ...state,
        isOpen: true,
        fetchingEvent: false,
        event: { ...state.event, ...action.event }
      };
    case OPEN_EVENT_MODAL_FAILURE:
      return {
        ...state,
        isOpen: false,
        fetchingEvent: false,
        openEventModalError: action.error
      };
    case OPEN_EVENT_MODAL:
      return { ...state, isOpen: true, event: { ...state.event, ...action.event } };
    case CLOSE_EVENT_MODAL:
      return {
        ...state,
        isOpen: false,
        event: {},
        addEventSuccess: false,
        updateEventSuccess: false,
        updating: true,
        updateEventError: null,
        emailSending: false,
        emailSent: false,
        activeItem: 'overview'
      };

    case GET_ROOM_DETAILS_REQUEST:
      return { ...state, fetchingRoom: true };
    case GET_ROOM_DETAILS_FAILURE:
      return { ...state, getRoomErrors: action.getRoomErrors };
    case GET_ROOM_DETAILS_SUCCESS:
      return { ...state, roomDetails: action.room };
    case GET_LAYOUT_DETAILS_REQUEST:
      return { ...state, fetchingLayout: true };
    case GET_LAYOUT_DETAILS_FAILURE:
      return { ...state, layoutErrors: action.layoutErrors };
    case GET_LAYOUT_DETAILS_SUCCESS:
      return { ...state, layoutDetails: action.layouts };
    case GET_HOSTS_REQUEST:
      return { ...state, hosts: [] };
    case GET_HOSTS_SUCCESS:
      return { ...state, hosts: action.users };
    case GET_HOSTS_FAILURE:
      return { ...state, hosts: [], getHostsErrors: action.error };
    case SET_PAYMENTSTATUS_REQUEST:
      return {
        ...state,
        addEventSuccess: false,
        updateEventSuccess: false,
        updating: true,
        updateEventError: null
      };
    case SET_PAYMENTSTATUS_SUCCESS:
      return {
        ...state,
        updateEventSuccess: true,
        addEventSuccess: false,
        updating: false,
        event: {
          ...state.event,
          paymentStatus: {
            ...state.event.paymentStatus,
            status: action.status
          }
        },
        updateEventError: null
      };
    case SET_PAYMENTSTATUS_FAILURE:
      return {
        ...state,
        addEventSuccess: false,
        updateEventSuccess: false,
        updating: false,
        updateEventError: action.error
      };
    case SEND_VENUETOHOSTEMAIL_REQUEST:
      return {
        ...state,
        emailSending: true
      };
    case SEND_VENUETOHOSTEMAIL_SUCCESS:
      return {
        ...state,
        emailSending: false,
        emailSent: true
      };
    case SEND_VENUETOHOSTEMAIL_FAILURE:
      return {
        ...state,
        emailSending: false,
        emailSent: false
      };
    case SET_ACTIVE_ITEM:
      return {
        ...state,
        activeItem: action.activeItem
      };
    case GET_EVENT_DETAIL_REQUEST:
      return {
        ...state,
        fetchingEvent: false
      };
    case GET_EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        event: action.event,
        fetchingEvent: false
      };
    case GET_EVENT_DETAIL_FAILURE:
      return {
        ...state,
        fetchingEvent: false,
        openEventModalError: action.error
      };
    default:
      return state;
  }
};
