/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import moment from 'moment';
import { Box, Popover, PopoverTrigger } from '@chakra-ui/react';
import { useVenue } from 'src/v2/Hooks/CollectionHooks';
import CalendarEventStyles from './styles';
import 'src/app.css';

import { EventPopOver } from './popover';
import { AppointmentPopover } from './AppointmentPopover';

const primaryColor = 'var(--colors-primary)';
const primaryLight = 'var(--colors-primary-light)';

const baseEventStyles = {
  border: `1px solid ${primaryColor}`,
  borderLeft: `4px solid ${primaryColor}`,
  borderLeftRadius: '0px',
  padding: '3px 2px',
  display: 'flex',
  alignItems: 'center',
  lineHeight: '1em',
  margin: '1.75px',
  height: '22px',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

const shadeColor = (color, percent) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100, 10);
  G = parseInt((G * (100 + percent)) / 100, 10);
  B = parseInt((B * (100 + percent)) / 100, 10);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

  return `#${RR}${GG}${BB}`;
};
const BaseEvent = ({ title, time, isOverdue, keyId }) => {
  return (
    <Box {...baseEventStyles} backgroundColor={(isOverdue && primaryLight) || primaryColor}>
      <Box overflow={'hidden'} textOverflow="ellipsis">
        {time} {title}
      </Box>
    </Box>
  );
};

const AppointmentEvent = ({
  title,
  time,
  isOverdue,
  keyId,
  appointment,
  appointmentColor,
  appointmentBackgroundColor,
  appointmentBorderColor
}) => {
  return (
    <>
      <Popover trigger="hover" placement="right">
        <PopoverTrigger>
          <Box
            {...baseEventStyles}
            backgroundColor={appointmentBackgroundColor || 'transparent'}
            color={appointmentColor || primaryColor}
            borderColor={appointmentBorderColor || primaryColor}
          >
            <Box overflow={'hidden'} textOverflow="ellipsis">
              {time} {title}
            </Box>
          </Box>
        </PopoverTrigger>
        <AppointmentPopover appointment={appointment} />
      </Popover>
    </>
  );
};

const LeadEvent = ({ title, time, appointment, fontColor, backgroundColor }) => {
  return (
    <Box
      {...baseEventStyles}
      backgroundColor={primaryLight}
      borderWidth="0px"
      paddingLeft="7px"
      borderLeftColor={primaryLight}
      color={fontColor}
    >
      <Box overflow={'hidden'} textOverflow="ellipsis">
        {time} {title}
      </Box>
    </Box>
  );
};

const CalendarEvent = props => {
  const { appointmentTypes, venueColors } = useVenue();

  const { event } = props;
  const { appointment: isAppointment, type } = event;

  const isLead = type === 'lead';

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  let endTime = event.end;
  const startTime = event.start;

  if (moment(endTime).isAfter(startTime, 'day')) {
    endTime = new Date(moment(endTime).hour(11));
  }

  const eventTime = moment(event.start).format('h:mma');

  const isOverdue = !!(
    event.paymentStatus &&
    event.paymentStatus.status &&
    event.paymentStatus.status === 'overdue'
  );

  const keyId = event.id;
  if (isLead) {
    return (
      <LeadEvent
        backgroundColor={primaryLight}
        title={event?.title}
        time={'Lead:'}
        key={keyId}
        appointment={event}
      />
    );
  }
  if (isAppointment) {
    const eventApptType = appointmentTypes?.find(
      apptType => event.appointmentTypeId === apptType.id
    );
    const { appointmentColor, appointmentBackgroundColor, appointmentBorderColor } =
      eventApptType || {};
    return (
      <AppointmentEvent
        appointmentBackgroundColor={appointmentBackgroundColor}
        appointmentColor={appointmentColor}
        appointmentBorderColor={appointmentBorderColor}
        title={event?.title}
        time={eventTime}
        overdue={isOverdue}
        key={keyId}
        appointment={event}
      />
    );
  }
  if (!isAppointment || !isLead)
    return <BaseEvent title={event?.title} time={eventTime} overdue={isOverdue} key={keyId} />;

  return (
    <>
      <Popover trigger="hover" placement="right" openDelay={500}>
        <PopoverTrigger>
          <CalendarEventStyles overdue={isOverdue} key={keyId}>
            {eventTime} {event.title}
          </CalendarEventStyles>
        </PopoverTrigger>
        <EventPopOver event={event} />
      </Popover>
    </>
  );
};

export default CalendarEvent;
