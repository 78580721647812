import moment from 'moment';
import { FormControl, HStack } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import _isArray from 'lodash/isArray';
import _isDate from 'lodash/isDate';
import { Select } from 'chakra-react-select';
import Colors from 'src/styles/Colors';
import { FC } from 'react';
import { SelectInputWithLabelProps } from 'src/Types/GlobalTypes';
import { FormLabel, FormErrorMessage } from '../../Primitives';

export const SelectInputWithLabel: FC<SelectInputWithLabelProps> = ({
  isLoading,
  isMulti,
  isRequired,
  label,
  customLabel,
  name,
  options,
  placeholder,
  rules,
  horizontal,
  errorKey,
  labelIcon,
  isDisabled,
  ...rest
}) => {
  const {
    formState: { errors },
    control,
    setValue,
    clearErrors
  } = useFormContext();

  let error;

  if (errorKey) {
    const { groupKey, inputName } = errorKey;
    error = errors?.[groupKey]?.[inputName]?.message;
  } else {
    error = errors && errors[name] && errors?.[name]?.message;
  }

  const isInvalid = !!error;

  const handleOnChange = (option, onChange) => {
    onChange(option);

    if (_isArray(option) && !option.length) {
      setValue(name, null, { shouldTouch: true });
      clearErrors(name); // Clear the error when the value is set to null
      return;
    }

    if (isMulti) {
      const valueOption = option?.map(opt => opt.value) || null;
      setValue(name, valueOption, { shouldTouch: true });
      clearErrors(name); // Clear the error when the value is set for multi-select
    } else {
      setValue(name, option && option.value, { shouldTouch: true });
      clearErrors(name); // Clear the error when the value is set for single-select
    }
  };

  const setValueSelected = value => {
    const isStringADate = value && moment(value).isValid();

    if (_isDate(value) || isStringADate) {
      if (value && options && new Date(value).toString() !== 'Invalid Date') {
        return options.find(
          option =>
            new Date(option.value.toString()).toUTCString() === new Date(value).toUTCString()
        );
      }
    }

    if (value && options && !isMulti) {
      return options.find(option => option.value === value);
    }

    if (value && options && isMulti) {
      const intersection = options.filter(item1 => value.some(item2 => item1.value === item2));
      return intersection;
    }

    return value;
  };

  const horizontalStyles = {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    alignItems: 'center',
    gridColumnGap: '25px'
  };

  return (
    <FormControl
      isInvalid={isInvalid}
      isRequired={isRequired}
      style={(horizontal && horizontalStyles) || {}}
    >
      <HStack marginBottom="0px" justify="space-between" align="flex-end">
        {customLabel || <FormLabel label={label || ''} labelIcon={labelIcon} />}
        <FormErrorMessage>{error}</FormErrorMessage>
      </HStack>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          return (
            <Select
              isDisabled={isDisabled}
              selectedOptionStyle="color"
              selectedOptionColor={'selectedOption'}
              isClearable
              isInvalid={isInvalid}
              // flex={1}
              {...field}
              options={options}
              placeholder={placeholder}
              onChange={option => handleOnChange(option, field.onChange)}
              value={setValueSelected(field.value)}
              isMulti={isMulti}
              isLoading={isLoading}
              // menuPortalTarget={document.body}
              styles={{
                // menuPortal: provided => ({ ...provided, zIndex: 9999 })
                menuPortal: provided => ({ ...provided })
              }}
              chakraStyles={{
                dropdownIndicator: (provided, state) => ({
                  ...provided,
                  background: 'white',
                  p: 0,
                  w: '40px'
                }),
                indicatorSeparator: () => ({
                  display: 'none'
                }),
                control: provided => ({
                  ...provided,
                  borderRadius: 'sm',
                  _invalid: {
                    borderColor: Colors.redC0
                  },
                  _focus: { borderWidth: '2px' }
                }),
                multiValue: provided => ({
                  ...provided,
                  bg: Colors.grayED
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected && Colors.gray18,
                  '&:hover': {
                    backgroundColor: Colors.grayF7
                  }
                })
              }}
              {...rest}
            />
          );
        }}
      />
    </FormControl>
  );
};
