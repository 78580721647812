import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export const getRoomDetailsAction = ({ token, roomId }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/rooms/${roomId}`,
    headers: { Authorization: token }
  });
};

export const getLayoutsDetailsAction = ({ token, roomId }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/layouts`,
    headers: { Authorization: token },
    params: { roomId }
  });
};

export const getHostsAction = ({ venueId, token, eventId, role }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/venues/${venueId}/users`,
    headers: { Authorization: token },
    params: { eventId, role }
  });
};

export const setPaymentStatusAction = ({ status, token, eventId }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/events/${eventId}/payment`,
    headers: { Authorization: token },
    data: { status }
  });
};

export const sendVenueToHostEmailAction = ({ token, eventId, subject, message }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/events/${eventId}/message`,
    headers: { Authorization: token },
    data: { subject, message }
  });
};

export function generatePdfFile({ token, eventId, venueId, layoutId }) {
  return axios({
    method: 'get',
    url: `${apiUri}/guests/generatePdfFile?&eventId=${eventId}&venueId=${venueId}&layoutId=${layoutId}`,
    headers: { Authorization: token },
    path: '/file',
    responseType: 'blob'
  });
}

/**
 * Generate a PDF File from a layoutType === template.
 * @param {Object} request - Required The request object sent to the server.
 * @param {string} request.token - The user token making the request.
 * @param {string} request.eventId - The eventId for the event we are requesting a pdf for.
 * @param {string} request.venueId - VenueId for the client we are requesting a pdf for
 * @returns {Blob} Blob of the pdf to be downloaded.
 */
export function generateTemplatePDF({ token, eventId, venueId }) {
  return axios({
    method: 'get',
    url: `${apiUri}/seatingChart/generatePdf?&eventId=${eventId}&venueId=${venueId}`,
    headers: { Authorization: token },
    path: '/file',
    responseType: 'blob'
  });
}
