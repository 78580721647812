import React from 'react';
import { Box } from '@chakra-ui/react';

export const CardBody = ({ children, ...props }) => (
  <Box
    paddingX="24px"
    flex="1 1 auto"
    paddingY="12px"
    height="100%"
    minH="1px"
    overflowY="auto"
    sx={{
      '::-webkit-scrollbar': {
        width: '12px'
      },
      '&:hover::-webkit-scrollbar': {
        width: '12px'
      },
      '&:hover::-webkit-scrollbar-track': {
        backgroundColor: 'rgba(221, 221, 221, 0.3)',
        borderRight: '3px solid #fafafa',
        borderLeft: '3px solid #fafafa',
        borderRadius: '6px'
      },
      // '&:hover ::-webkit-scrollbar-button': {
      //   display: 'none'
      // },
      '&:hover::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(221, 221, 221, 0.8)',
        borderRight: '3px solid #fafafa',
        borderLeft: '3px solid #fafafa',
        borderRadius: '5.5px'
      }
    }}
    {...props}
  >
    {children}
  </Box>
);
