export const OPEN_ADD_EVENT_MODAL = 'OPEN_ADD_EVENT_MODAL';
export const CLOSE_ADD_EVENT_MODAL = 'CLOSE_ADD_EVENT_MODAL';

export const ADD_EVENT_REQUEST = 'ADD_EVENT_REQUEST';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const ADD_EVENT_FAILURE = 'ADD_EVENT_FAILURE';

export const UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAILURE = 'UPDATE_EVENT_FAILURE';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const GET_LAYOUTS_REQUEST = 'GET_LAYOUTS_REQUEST';
export const GET_LAYOUTS_SUCCESS = 'GET_LAYOUTS_SUCCESS';
export const GET_LAYOUTS_FAILURE = 'GET_LAYOUTS_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const CHECK_EVENTCODE_REQUEST = 'CHECK_EVENTCODE_REQUEST';
export const CHECK_EVENTCODE_SUCCESS = 'CHECK_EVENTCODE_SUCCESS';
export const CHECK_EVENTCODE_FAILURE = 'CHECK_EVENTCODE_FAILURE';

export const CHECK_HOSTEMAIL_REQUEST = 'CHECK_HOSTEMAIL_REQUEST';
export const CHECK_HOSTEMAIL_SUCCESS = 'CHECK_HOSTEMAIL_SUCCESS';
export const CHECK_HOSTEMAIL_FAILURE = 'CHECK_HOSTEMAIL_FAILURE';

export const CREATE_HOST_USERS_REQUEST = 'CREATE_HOST_USERS_REQUEST';
export const CREATE_HOST_USERS_SUCCESS = 'CREATE_HOST_USERS_SUCCESS';
export const CREATE_HOST_USERS_FAILURE = 'CREATE_HOST_USERS_FAILURE';

export const ADD_EVENT_DOCUMENTS_REQUEST = 'ADD_EVENT_DOCUMENTS_REQUEST';
export const ADD_EVENT_DOCUMENTS_SUCCESS = 'ADD_EVENT_DOCUMENTS_SUCCESS';
export const ADD_EVENT_DOCUMENTS_FAILURE = 'ADD_EVENT_DOCUMENTS_FAILURE';

export const SEND_EVENT_EMAIL = {
  REQUEST: 'SEND_EVENT_EMAIL',
  SUCCESS: 'SEND_EVENT_EMAIL_SUCCESS',
  FAILURE: 'SEND_EVENT_EMAIL_FAILURE'
};

export const ADD_THREAD_TO_EVENT = {
  REQUEST: 'ADD_THREAD_TO_EVENT_REQUEST',
  SUCCESS: 'ADD_THREAD_TO_EVENT_SUCCESS',
  FAILURE: 'ADD_THREAD_TO_EVENT_FAILURE'
};
export const SET_LEAD_TYPE_REQUEST = 'SET_LEAD_TYPE_REQUEST';
export const SET_LEAD_TYPE_SUCCESS = 'SET_LEAD_TYPE_SUCCESS';
export const SET_LEAD_TYPE_FAILURE = 'SET_LEAD_TYPE_FAILURE';
