import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormField } from '../../Primitives';
import { InlineTextFieldTypes } from './types';

export const InlineTextField: FC<InlineTextFieldTypes> = ({ name, placeholder, styles }) => {
  const { register } = useFormContext();
  const inputStyles = {
    border: 'none',
    fontSize: '14px',
    ...styles
  };
  return (
    <FormField
      fieldValue={undefined}
      placeholder={placeholder}
      type="text"
      {...register(name)}
      style={inputStyles}
    />
  );
};
