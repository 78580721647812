import { Icon, Spinner } from '@chakra-ui/react';
import toast from 'react-hot-toast';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  CommentDocumentAction,
  DeleteDocumentAction,
  DownloadDocumentAction,
  GetDocumentsAction,
  UpdateVersionAction,
  UploadDocumentAction
} from 'src/containers/DocumentsContainer/OperationTypes';

import { closeOverlayLoader, openOverlayLoader } from 'src/containers/OverlayLoader/operations';
import { RootStates } from 'src/Types';
import { getDateDisplay } from 'src/utils/getDateDisplay';
import { eventDocumentDrawerOperations } from 'src/v2/Drawers/Redux/operations';

import {
  autoFillDocumentAction,
  commentDocumentAction,
  deleteDocumentAction,
  documentUploadAction,
  downloadDocumentAction,
  getDocumentAction,
  getDocumentsAction,
  updateDocumentAction
} from './actions';
import {
  commentDocumentFailure,
  commentDocumentSuccess,
  deleteDocumentFailure,
  deleteDocumentSuccess,
  documentUploadFailure,
  documentUploadSuccess,
  getDocumentFailure,
  getDocumentsFailure,
  getDocumentsRequest,
  getDocumentsSuccess,
  getDocumentSuccess,
  updateDocumentFailure,
  updateDocumentSuccess
} from './operations';
import {
  AUTOFILL_DOCUMENT_REQUEST,
  COMMENT_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_REQUEST,
  DOCUMENT_UPLOAD_REQUEST,
  DOWNLOAD_DOCUMENT,
  GET_DOCUMENT_REQUEST,
  GET_DOCUMENTS_REQUEST,
  UPDATE_DOCUMENT_REQUEST
} from './types';

const getToken = (state: RootStates) => state.loginReducer.token;

type AnyAction = { type: string; [key: string]: any };

function* workerGetDocuments({ eventId }: GetDocumentsAction) {
  try {
    const token = yield select(getToken);
    const response = yield call(getDocumentsAction, { token, eventId });
    if (response.data.success) {
      yield put(getDocumentsSuccess({ documents: response.data.documents }));
    }
  } catch (error) {
    yield put(getDocumentsFailure({ error }));
  }
}

export type ReadDocumentWorkerProps = { eventId: string; documentId: string; type: string };
function* workerGetDocument({ eventId, documentId }: ReadDocumentWorkerProps) {
  try {
    const token = yield select(getToken);
    const response = yield call(getDocumentAction, { token, eventId, documentId });
    if (response.data.success) {
      yield put(getDocumentSuccess({ document: response.data.document }));
    }
  } catch (error) {
    yield put(getDocumentFailure({ error }));
  }
}
// deprecated replaced with uploaddocument
// function* workerAddDocument({ document, eventId }) {
//   try {
//     const token = yield select(getToken);
//     const response = yield call(addDocumentAction, { token, document, eventId });
//     if (response.data.success) {
//       yield put(addDocumentSuccess());
//       yield put(getDocumentsRequest({ eventId }));
//     }
//   } catch (error) {
//     yield put(addDocumentFailure({ error }));
//   }
// }

function* workerUpdateDocument({ eventId, documentId, document }: UpdateVersionAction) {
  try {
    yield put(openOverlayLoader());
    const token = yield select(getToken);
    const response = yield call(updateDocumentAction, { token, eventId, documentId, document });
    if (response.data.success) {
      yield put(updateDocumentSuccess({ documents: response.data.documents }));
      yield put(getDocumentsRequest({ eventId }));
      toast('Updated successfully', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
      yield put(closeOverlayLoader());
    }
  } catch (error) {
    yield put(updateDocumentFailure({ error }));
    yield put(closeOverlayLoader());
    toast.error('Error saving version');
  }
}

function* workerDocumentUpload({ formData, eventId }: UploadDocumentAction) {
  try {
    yield put(openOverlayLoader());
    const token = yield select(getToken);
    const response = yield call(documentUploadAction, { token, formData, eventId });
    if (response.data.success) {
      // yield put(documentUploadSuccess({ url: response.data.url }));
      yield put(documentUploadSuccess());
      yield put(getDocumentsSuccess({ documents: response.data.documents }));
      yield put(closeOverlayLoader());
      yield put(eventDocumentDrawerOperations.close());

      toast('Document Added to Event', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
    }
  } catch (error) {
    yield put(documentUploadFailure({ error }));
    yield put(closeOverlayLoader());
    toast.error('Error Uploading Document');
  }
}
function* workerDeleteDocument({ eventId, documentId }: DeleteDocumentAction) {
  try {
    yield put(openOverlayLoader());
    const token = yield select(getToken);
    const response = yield call(deleteDocumentAction, { token, eventId, documentId });
    if (response.data.success) {
      yield put(closeOverlayLoader());
      yield put(deleteDocumentSuccess({ documents: response.data.eventDocuments }));
      // yield put(getDocumentsRequest({ eventId }));
      toast('Document Deleted', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
    }
  } catch (error) {
    yield put(closeOverlayLoader());
    yield put(deleteDocumentFailure({ error }));
  }
}

function* workerCommentDocument({ eventId, documentId, content }: CommentDocumentAction) {
  const loadingToastId = toast.loading('Adding Comment', {
    icon: <Spinner />
  });
  try {
    const token = yield select(getToken);
    const response = yield call(commentDocumentAction, { token, eventId, documentId, content });
    if (response) toast.dismiss(loadingToastId);
    if (response.data.success) {
      const { updatedDocument } = response.data;
      toast.success('Comment Added ');
      yield put(commentDocumentSuccess({ updatedDocument, documents: response.data.documents }));
      // review if necessary
      // yield put(getDocumentRequest({ eventId, documentId }));
      // yield put(getDocumentsRequest({ eventId }));
    }
  } catch (error) {
    toast.dismiss(loadingToastId);
    toast.error('Failed to update document');
    yield put(commentDocumentFailure({ error }));
  }
}

function* workerRequestDownloadDocument({
  eventId,
  docId,
  filename,
  url: requestUrl
}: DownloadDocumentAction) {
  try {
    yield put(openOverlayLoader());
    const token = yield select(getToken);
    const writeResponse = yield call(downloadDocumentAction, {
      token,
      eventId,
      docId,
      url: requestUrl
    });
    if (writeResponse) {
      const reponseUrl = window.URL.createObjectURL(new Blob([writeResponse.data]));
      const link = document.createElement('a');
      toast('Document Downloaded', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
      link.href = reponseUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      yield put(closeOverlayLoader());
    }
  } catch (error) {
    toast.error('Error Downloading Document');
    yield put(closeOverlayLoader());
  }
}

function* workerRequestAutoFillDocument({ eventId, venueId, docId, documentName }: AnyAction) {
  try {
    yield put(openOverlayLoader());
    const token = yield select(getToken);
    const writeResponse = yield call(autoFillDocumentAction, {
      token,
      eventId,
      venueId,
      docId
    });
    if (writeResponse) {
      const { shortDate } = getDateDisplay(new Date());
      const filename = `${documentName} - ${shortDate.replace('/', '_')} ` || 'default';
      const url = window.URL.createObjectURL(new Blob([writeResponse.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${filename}.pdf`);
      document.body.appendChild(link);
      link.click();
      yield put(closeOverlayLoader());
    }
  } catch (err) {
    const errorMessage = (err instanceof Error && 'string') || '';
    toast.error(errorMessage);
  }
}

export function* watchAutoFillDocument() {
  yield takeLatest(AUTOFILL_DOCUMENT_REQUEST, workerRequestAutoFillDocument);
}

export function* watchGetDocuments() {
  yield takeLatest(GET_DOCUMENTS_REQUEST, workerGetDocuments);
}

export function* watchGetDocument() {
  yield takeLatest(GET_DOCUMENT_REQUEST, workerGetDocument);
}

// export function* watchAddDocument() {
//   yield takeLatest(ADD_DOCUMENT_REQUEST, workerAddDocument);
// }

export function* watchUpdateDocument() {
  yield takeLatest(UPDATE_DOCUMENT_REQUEST, workerUpdateDocument);
}

export function* watchDeleteDocument() {
  yield takeLatest(DELETE_DOCUMENT_REQUEST, workerDeleteDocument);
}

export function* watchCommentDocument() {
  yield takeLatest(COMMENT_DOCUMENT_REQUEST, workerCommentDocument);
}

export function* watchDocumentUpload() {
  yield takeLatest(DOCUMENT_UPLOAD_REQUEST, workerDocumentUpload);
}

export function* watchDocumentDownload() {
  yield takeLatest(DOWNLOAD_DOCUMENT.REQUEST, workerRequestDownloadDocument);
}
