import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import DropdownStyles from './styles';
import ButtonRound from '../../styles/ButtonRound';
import Button from '../../styles/Button';
import IconCheckMark from '../../styles/Icons/Checkmark';
import InputRadio from '../../styles/InputRadio';
import Colors from '../../styles/Colors';
import Ellipsis from '../Ellipsis';
import Status from '../Status';
import Input from '../../styles/Input';
import CloseButton from '../CloseButton';
import Pill from '../../styles/Pill';
import CaretRight from '../../styles/Icons/CaretRight';

const Dropdown = ({
  title = '',
  action = () => {},
  links = [],
  items = [],
  itemsTwoLine = [],
  actionableItem = {},
  options = [],
  selectors = [],
  top = 'auto',
  bottom = 'auto',
  right = '0px',
  left = 'auto',
  width = '',
  onClick,
  value,
  btnLabel,
  padding,
  maxHeight = '',
  toolTipText = '',
  height = '',
  color = '',
  disableShadow = false,
  bgColor = '',
  justifyContent = '',
  btnType = 'roundBtn',
  label = '',
  icon = '',
  selectorColor = '',
  filterSuccess,
  resetInput,
  type = '',
  noItemsText = '',
  checkedValidator = () => {},
  optionSelect = () => {},
  handleSelectChange = () => {},
  handleSubmit = () => {},
  getStatusColor = () => {},
  children
}) => {
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState();
  const node = useRef();

  const handleInputChange = e => {
    setInputValue(e.currentTarget.value);
  };

  useEffect(() => {
    setInputValue();
  }, [resetInput]);

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleSubmit(inputValue);
      setTimeout(() => {
        setShow(false);
      }, 500);
    }
  };

  const clearInput = e => {
    e.preventDefault();
    handleSubmit();
    setInputValue('');
    setTimeout(() => {
      setShow(false);
    }, 500);
  };

  const handleToggleShow = e => {
    e.stopPropagation();
    setShow(!show);
    // eslint-disable-next-line no-unused-expressions
    onClick && onClick();
  };

  const handleClick = e => {
    if (!node.current.contains(e.target)) {
      // outside click
      setShow(false);
    }
    // inside click
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <DropdownStyles justifyContent={justifyContent} ref={node}>
      {btnType === 'roundBtn' && (
        <ButtonRound
          bgColor={bgColor}
          action={handleToggleShow}
          icon={icon}
          disableShadow={disableShadow}
          toolTipText={toolTipText}
        />
      )}
      {btnType === 'btn' && (
        <Button
          bgColor={bgColor}
          action={handleToggleShow}
          label={label}
          color={color}
          icon={icon}
          iconRight
        />
      )}
      {btnType === 'ellipsis' && <Ellipsis action={handleToggleShow} />}
      {btnType === 'select' && (
        <DropdownStyles.SelectBtn onClick={handleToggleShow} justifyContent={justifyContent}>
          {label} {icon}
        </DropdownStyles.SelectBtn>
      )}
      {btnType === 'pill' && <Pill label={'Pill'} bgColor={'red'} />}
      <DropdownStyles.Content
        show={show}
        top={top}
        bottom={bottom}
        right={right}
        left={left}
        width={width}
        className="show-scrollbar"
      >
        {title && title.length > 0 && (
          <DropdownStyles.ContentHeader>{title}</DropdownStyles.ContentHeader>
        )}
        {noItemsText && noItemsText.length > 0 && (
          <DropdownStyles.ContentItemTwoLineMain style={{ padding: '20px 0 10px 0' }}>
            {noItemsText}
          </DropdownStyles.ContentItemTwoLineMain>
        )}
        {links && links.length > 0 && (
          <DropdownStyles.ContentLinksWrapper>
            <DropdownStyles.ContentLinks height={height}>
              {links.map((link, k) => (
                <DropdownStyles.ContentLink key={k.toString()} icon={link.icon} color={color}>
                  <span
                    onClick={e => {
                      handleToggleShow(e);
                      // eslint-disable-next-line no-unused-expressions
                      link.onClick && link.onClick(e);
                    }}
                  >
                    {link.icon && link.icon}
                    {link.label}
                  </span>
                </DropdownStyles.ContentLink>
              ))}
            </DropdownStyles.ContentLinks>
          </DropdownStyles.ContentLinksWrapper>
        )}
        <DropdownStyles.ActionableItem onClick={actionableItem.action}>
          {actionableItem.icon}
          {actionableItem.label}
        </DropdownStyles.ActionableItem>
        {items && items.length > 0 && (
          <>
            <DropdownStyles.ContentLinks height={height} maxHeight={maxHeight}>
              {items.map((item, k) => (
                <DropdownStyles.ContentItem key={k.toString()} icon={item.icon} color={color}>
                  <span
                    onClick={e => {
                      handleToggleShow(e);
                      // eslint-disable-next-line no-unused-expressions
                      item.onClick && item.onClick(e);
                    }}
                  >
                    {item.label}
                    {item.icon && item.icon}
                  </span>
                </DropdownStyles.ContentItem>
              ))}
            </DropdownStyles.ContentLinks>
          </>
        )}
        {itemsTwoLine && itemsTwoLine.length > 0 && (
          <DropdownStyles.ContentLinksWrapper>
            <DropdownStyles.ContentLinks height={height} maxHeight={maxHeight}>
              {itemsTwoLine.map((item, k) => (
                <DropdownStyles.ContentItemTwoLine
                  key={k.toString()}
                  onClick={e => {
                    handleToggleShow(e);
                    // eslint-disable-next-line no-unused-expressions
                    item.onClick && item.onClick(e);
                  }}
                >
                  <DropdownStyles.ContentItemTwoLineWrapper>
                    <DropdownStyles.ContentItemTwoLineMain>
                      {item.label}
                    </DropdownStyles.ContentItemTwoLineMain>
                    <DropdownStyles.ContentItemTwoLineSub>
                      {item.subtitle}
                    </DropdownStyles.ContentItemTwoLineSub>
                  </DropdownStyles.ContentItemTwoLineWrapper>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CaretRight color={Colors.gray7D} size={18} />
                  </div>
                </DropdownStyles.ContentItemTwoLine>
              ))}
            </DropdownStyles.ContentLinks>
          </DropdownStyles.ContentLinksWrapper>
        )}
        {options && options.length > 0 && (
          <React.Fragment>
            <DropdownStyles.ContentLinks height={height} maxHeight={maxHeight}>
              {options.map((option, index) => (
                <DropdownStyles.Option
                  key={index.toString()}
                  icon={option.icon}
                  onClick={() => optionSelect(option.value)}
                >
                  <InputRadio checked={checkedValidator(option.value)} borderColor={Colors.grayB0}>
                    {checkedValidator(option.value) ? <IconCheckMark color={Colors.white} /> : null}
                  </InputRadio>
                  <span>
                    {option.icon && option.icon}
                    {option.label}
                  </span>
                </DropdownStyles.Option>
              ))}
            </DropdownStyles.ContentLinks>
            <DropdownStyles.Button>
              <Button
                label={btnLabel || 'Save'}
                bgColor={Colors.white}
                color={`var(--colors-primary)`}
                action={action}
                padding={padding}
              />
            </DropdownStyles.Button>
          </React.Fragment>
        )}
        {selectors && selectors.length > 0 && (
          <React.Fragment>
            <DropdownStyles.ContentLinks maxHeight={maxHeight}>
              {selectors.map((select, index) => (
                <DropdownStyles.Option
                  key={index.toString()}
                  onClick={() => {
                    handleSelectChange(select.value);
                    setTimeout(() => {
                      setShow(false);
                    }, 500);
                  }}
                >
                  <InputRadio borderColor={getStatusColor(select.label.toLowerCase())}>
                    {value && (
                      <Status
                        bgColor={
                          (value.toLowerCase() === select.label.toLowerCase() &&
                            getStatusColor(value)) ||
                          (value === parseInt(select.label, 10) && selectorColor)
                        }
                        size={'16px'}
                      />
                    )}
                  </InputRadio>
                  <div style={{ marginLeft: 15 }}>{select.icon}</div>
                  <span>{select.label}</span>
                </DropdownStyles.Option>
              ))}
            </DropdownStyles.ContentLinks>
          </React.Fragment>
        )}
        {type === 'input' && (
          <React.Fragment>
            <DropdownStyles.ContentLinks height={height}>
              <DropdownStyles.Input>
                <Input
                  width={'50px'}
                  placeholder={'Table #'}
                  onChange={evnt => handleInputChange(evnt)}
                  value={inputValue || ''}
                  disabled={filterSuccess}
                  onKeyDown={evnt => handleKeyDown(evnt)}
                />
                <DropdownStyles.InputClear>
                  {inputValue && <CloseButton size={'12px'} onClick={e => clearInput(e)} />}
                </DropdownStyles.InputClear>
              </DropdownStyles.Input>
              <DropdownStyles.InputButton>
                {!filterSuccess && (
                  <Button
                    label={'Save'}
                    bgColor={Colors.white}
                    color={Colors.gray7D}
                    disabled={!inputValue}
                    action={() => {
                      handleSubmit(inputValue);
                      setTimeout(() => {
                        setShow(false);
                      }, 500);
                    }}
                  />
                )}
              </DropdownStyles.InputButton>
            </DropdownStyles.ContentLinks>
          </React.Fragment>
        )}
        {type === 'div' && children}
      </DropdownStyles.Content>
    </DropdownStyles>
  );
};

Dropdown.propTypes = {
  title: PropTypes.string,
  links: PropTypes.array,
  items: PropTypes.array,
  itemsTwoLine: PropTypes.array,
  options: PropTypes.array,
  selectors: PropTypes.array,
  top: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
  toolTipText: PropTypes.string,
  right: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func,
  checkedValidator: PropTypes.func,
  optionSelect: PropTypes.func,
  handleSelectChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  getStatusColor: PropTypes.func,
  action: PropTypes.func,
  resetFilter: PropTypes.func,
  height: PropTypes.string,
  maxHeight: PropTypes.string,
  btnLabel: PropTypes.string,
  padding: PropTypes.string,
  color: PropTypes.string,
  disableShadow: PropTypes.bool,
  filterSuccess: PropTypes.bool,
  resetInput: PropTypes.bool,
  bgColor: PropTypes.string,
  justifyContent: PropTypes.string,
  btnType: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element,
  type: PropTypes.string,
  value: PropTypes.string,
  selectorColor: PropTypes.string,
  actionableItem: PropTypes.object
};

export default Dropdown;
