import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export const getCustomObjectsAction = ({ token }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/customObjects`,
    headers: { Authorization: token }
  });
};

export const addCustomObjectsAction = ({ shape, token }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/customObjects`,
    headers: { Authorization: token },
    data: shape
  });
};

export const updateCustomObjectsAction = ({ id, shape, token }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/customObjects/${id}`,
    headers: { Authorization: token },
    data: shape
  });
};

export const deleteCustomObjectsAction = ({ id, token }) => {
  return axios({
    method: 'delete',
    url: `${apiUri}/customObjects/${id}`,
    headers: { Authorization: token }
  });
};

export const updateCategoryAction = ({ data, token }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/customObjects/category/1`,
    headers: { Authorization: token },
    data
  });
};
