// import { Skeleton as ChakraSkeleton } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import styled, { keyframes } from 'styled-components';

const loadingEvent = keyframes`
   to {
    background-position-x: -200%;
    opacity: 0.2;
  }
`;

const StyledSkeleton = styled(Box)`
  background: linear-gradient(
    110deg,
    var(--colors-primary-lightest) 25%,
    var(--colors-primary-lighter) 50%,
    var(--colors-primary-lightest) 75%
  ) !important;
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s ${loadingEvent} alternate-reverse infinite;
  height: 20px;
  width: ${props => props.width ?? '100%'};
`;

export const Skeleton = ({ ...props }) => {
  return <StyledSkeleton {...props} />;
};
