import React from 'react';
import PropTypes from 'prop-types';
import Colors from '../Colors';
import './styles/loader.scss';

const Loader = props => (
  <svg
    width={props.size}
    height={props.size}
    {...props}
    viewBox="0 0 38 38"
    stroke={props.color || 'var(--colors-primary)' || Colors.gray7D}
    id="loading-spinner"
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)" strokeWidth="2">
        <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18" />
      </g>
    </g>
  </svg>
);

Loader.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  theme: PropTypes.object
};

Loader.defaultProps = {
  size: 38
};

export default Loader;
