export const OPEN_MODAL_LOADER = 'OPEN_MODAL_LOADER';
export const CLOSE_MODAL_LOADER = 'CLOSE_MODAL_LOADER';
export const UPDATE_MODAL_LOADER_MESSAGE = 'UPDATE_MODAL_LOADER_MESSAGE';

export const SHOW_GLASS_LOADER = 'OPEN_GLASS_LOADER';
export const HIDE_GLASS_LOADER = 'CLOSE_GLASS_LOADER';
export const SET_GLASS_LOADER_MESSAGE = 'SET_GLASS_LOADER_MESSAGE';

export const OPEN_CONFIRM = 'OPEN_CONFIRM';
export const CLOSE_CONFIRM = 'CLOSE_CONFIRM';
export const CONFIRM_ACCEPT = 'CONFIRM_ACCEPT';
export const CONFIRM_REJECT = 'CONFIRM_REJECT';

export const OPEN_TOAST = 'OPEN_TOAST';
export const CLOSE_TOAST = 'CLOSE_TOAST';
