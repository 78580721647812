import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react';
import { Header } from './Header';

export const EventTabBox = ({
  children,
  title,
  subtitle,
  buttonAction,
  secondaryAction,
  icons,
  keepExpanded = false
}) => (
  <Accordion
    allowToggle={!keepExpanded}
    borderRadius="8px"
    defaultIndex={keepExpanded ? [0] : []}
    border="1px solid #ededed"
  >
    <AccordionItem borderBottomWidth={0} borderTopWidth={0}>
      <AccordionButton
        paddingY="20px"
        paddingX="18px"
        w="100%"
        alignItems="center"
        _hover={{ background: '' }}
        borderRadius="8px"
      >
        <AccordionIcon
          w={8}
          h={8}
          color="var(--colors-primary)"
          mr="22px"
          display={(keepExpanded && 'none') || ''}
        />
        <Header
          title={title}
          subtitle={subtitle}
          buttonAction={buttonAction}
          secondaryAction={secondaryAction}
          icons={icons}
        />
      </AccordionButton>
      <AccordionPanel borderTop="1px solid #ededed" padding="0px">
        {children}
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
);
