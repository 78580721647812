import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validator from 'validator';
import { Redirect, withRouter } from 'react-router-dom';
import { doSetErrors, doLogin } from './operations';
import Login from '../../components/Login';
import Constants from '../../utils/Constants';

const LoginContainer = props => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [venueId, setVenueId] = useState(localStorage.venueId || '');
  useEffect(() => {
    props.doSetErrors({});
  }, []);

  const handleChange = event => {
    const { name, value: rawValue } = event.currentTarget;
    const value = rawValue.trim();
    switch (name) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'venueId':
        setVenueId(value);
        break;
      default:
      // nothing
    }
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (!email || !validator.isEmail(email)) {
      return props.doSetErrors({ email: Constants.LOGIN_ERROR_EMAIL });
    }

    if (!password) {
      return props.doSetErrors({ password: Constants.LOGIN_ERROR_PASSWORD });
    }

    props.doSetErrors({});

    return props.doLogin({ email, password, venueId });
  };

  return props.token ? (
    <Redirect to={'/dashboard'} />
  ) : (
    <Login
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      name={props.venueName || 'Logo Here'}
      logo={props.venueLogo}
      welcomeMessage={props.venueWelcomeMessage}
      loading={props.fetching}
      email={email}
      password={password}
      venueId={venueId}
      errors={props.error || {}}
    />
  );
};

const mapStateToProps = state => ({
  fetching: state.loginReducer.fetching,
  error: state.loginReducer.error,
  token: state.loginReducer.token,
  refresh: state.loginReducer.refresh,
  expires: state.loginReducer.expires,
  venueName: state.venueValuesReducer.venue && state.venueValuesReducer.venue.name,
  venueLogo: state.venueValuesReducer.venue && state.venueValuesReducer.venue.logo,
  venueWelcomeMessage:
    state.venueValuesReducer.venue && state.venueValuesReducer.venue.welcomeMessage
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doSetErrors,
      doLogin
    },
    dispatch
  );

LoginContainer.propTypes = {
  doSetErrors: PropTypes.func,
  doLogin: PropTypes.func,
  fetching: PropTypes.bool,
  token: PropTypes.string,
  error: PropTypes.object,
  venueName: PropTypes.string,
  venueLogo: PropTypes.string,
  venueWelcomeMessage: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginContainer));
