import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { doGetEvent } from '../DashboardContainer/operations';

const SidebarContainer = props => {
  const { event, hideSidebar, setHideSidebar } = props;

  return (
    <Sidebar
      location={props.history.location}
      permissions={props.claims.permissions}
      role={props.role}
      admin={props.admin}
      logo={props.logo}
      smallLogo={props.smallLogo}
      name={props.name}
      event={event}
      viewPermissions={props.viewPermissions}
      venueFeatureFlags={props.venueValues}
      hideSidebar={hideSidebar}
      setHideSidebar={setHideSidebar}
    />
  );
};

SidebarContainer.propTypes = {
  history: PropTypes.object,
  claims: PropTypes.object,
  viewPermissions: PropTypes.object,
  event: PropTypes.object,
  role: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string,
  token: PropTypes.string,
  eventId: PropTypes.string,
  getEvent: PropTypes.func,
  venueFeatureFlags: PropTypes.object,
  venueValues: PropTypes.object,
  admin: PropTypes.bool
};

const mapStateToProps = state => ({
  claims: state.loginReducer.claims,
  venueFeatureFlags: state.venuesReducer.venue && state.venuesReducer.venue.featureFlags,
  role: state.loginReducer.role,
  eventId: state.loginReducer.eventId,
  token: state.loginReducer.token,
  admin: state.loginReducer.admin,
  viewPermissions: state.loginReducer.viewPermissions,
  event: state.dashboardReducer.event,
  logo:
    (state.venueValuesReducer &&
      state.venueValuesReducer.venue &&
      state.venueValuesReducer.venue.sidebarLogo) ||
    (state.venuesReducer && state.venuesReducer.venue && state.venuesReducer.venue.logo),
  smallLogo:
    (state.venueValuesReducer &&
      state.venueValuesReducer.venue &&
      state.venueValuesReducer.venue.smallLogo) ||
    (state.venueValuesReducer &&
      state.venueValuesReducer.venue &&
      state.venueValuesReducer.venue.sidebarLogo) ||
    (state.venuesReducer && state.venuesReducer.venue && state.venuesReducer.venue.logo),
  name:
    (state.venuesReducer && state.venuesReducer.venue && state.venuesReducer.venue.name) ||
    (state.venues && state.venues.venue && state.venues.venue.name),
  venueValues: state.venueValuesReducer.venue.featureFlags
});

const mapDispatchToProps = dispatch => ({
  getEvent: ({ token, eventId }) => dispatch(doGetEvent({ token, eventId }))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SidebarContainer));
