import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import AddChecklistModalStyles from './styles';
import Input from '../../styles/Input';
import InputRadio from '../../styles/InputRadio';
import Colors from '../../styles/Colors';
import Checkmark from '../../styles/Icons/Checkmark';
import { getTimeDiff } from '../../utils/getTimeDiff';
import { capitalizeFirstLetter } from '../../utils/Helpers';

const AddChecklistModalBody = ({ startDate, setItem, item, itemToEdit, role }) => {
  const [name, setName] = useState(capitalizeFirstLetter(itemToEdit.name) || '');
  const [required, setRequired] = useState();
  const [beforeEvent, setBeforeEvent] = useState(false);
  const [afterEvent, setAfterEvent] = useState(false);
  const [noOfMonths, setNoOfMonths] = useState();
  const [noOfDays, setNoOfDays] = useState();
  const [noOfWeeks, setNoOfWeeks] = useState();
  const [selectedDate, setSelectedDate] = useState(itemToEdit.dueDate || startDate);
  const [dateType, setDateType] = useState('');

  useEffect(() => {
    if (role === 'host') {
      setItem({
        ...item,
        name,
        dueDate: moment(new Date(selectedDate))
          .utc()
          .toISOString()
      });
    }
    if (role === 'staff') {
      setItem({
        ...item,
        name,
        dueDate: moment(new Date(selectedDate)).toISOString(),
        overrideRequired: !required
      });
    }
  }, [selectedDate, name, required]);

  useEffect(() => {
    if (Object.keys(itemToEdit).length) {
      setDateType('specific');
    }
  }, [itemToEdit]);

  useEffect(() => {
    if (Object.keys(itemToEdit).length) {
      if (Object.prototype.hasOwnProperty.call(itemToEdit, 'overrideRequired')) {
        setRequired(!itemToEdit.overrideRequired);
      } else {
        setRequired(itemToEdit.required);
      }
    }
  }, [itemToEdit]);

  useEffect(() => {
    if (afterEvent && dateType === 'calculated') {
      setSelectedDate(
        moment(startDate).add({ days: noOfDays, weeks: noOfWeeks, months: noOfMonths })
      );
    }
    if (beforeEvent && dateType === 'calculated') {
      setSelectedDate(
        moment(startDate).subtract({ days: noOfDays, weeks: noOfWeeks, months: noOfMonths })
      );
    }
  }, [noOfDays, noOfWeeks, noOfMonths, beforeEvent, afterEvent]);

  useEffect(() => {
    if (dateType === 'specific') {
      if (moment(selectedDate).isBefore(startDate)) {
        setBeforeEvent(true);
        setAfterEvent(false);
      }
      if (moment(selectedDate).isAfter(startDate)) {
        setAfterEvent(true);
        setBeforeEvent(false);
      }
      const { months, days } = getTimeDiff(startDate, selectedDate);
      const weeks = Math.floor(days / 7);
      const daysRemaining = days % 7;
      setNoOfMonths(months);
      setNoOfWeeks(weeks);
      setNoOfDays(daysRemaining);
    }
  }, [dateType, selectedDate]);

  return (
    <AddChecklistModalStyles.Body>
      <AddChecklistModalStyles.BodyRow>
        <AddChecklistModalStyles.Key>Name:</AddChecklistModalStyles.Key>
        <Input onChange={e => setName(e.target.value)} value={name} disabled={role === 'staff'} />
      </AddChecklistModalStyles.BodyRow>
      {role === 'staff' && (
        <AddChecklistModalStyles.BodyRow alignItems="flex-start" marginBottom="2px">
          <AddChecklistModalStyles.Key marginRight="5px">Required:</AddChecklistModalStyles.Key>
          <InputRadio action={() => setRequired(!required)} checked={required}>
            {required ? <Checkmark color={Colors.white} /> : null}
          </InputRadio>
        </AddChecklistModalStyles.BodyRow>
      )}
      <AddChecklistModalStyles.BodyRow alignItems="flex-start">
        <AddChecklistModalStyles.Key>When:</AddChecklistModalStyles.Key>
        <AddChecklistModalStyles.Values>
          <AddChecklistModalStyles.Span>
            <AddChecklistModalStyles.Option>
              Before Event:
              <InputRadio
                action={() => {
                  setAfterEvent(false);
                  setBeforeEvent(true);
                  setDateType('calculated');
                }}
                checked={beforeEvent}
              >
                {beforeEvent ? <Checkmark color={Colors.white} /> : null}
              </InputRadio>
            </AddChecklistModalStyles.Option>
            <AddChecklistModalStyles.Option>
              After Event:
              <InputRadio
                action={() => {
                  setAfterEvent(true);
                  setBeforeEvent(false);
                  setDateType('calculated');
                }}
                checked={afterEvent}
              >
                {afterEvent ? <Checkmark color={Colors.white} /> : null}
              </InputRadio>
            </AddChecklistModalStyles.Option>
          </AddChecklistModalStyles.Span>
          <AddChecklistModalStyles.Span alignItems="center">
            Months:
            <Input
              type="number"
              height="35px"
              width="40px"
              min="0"
              onChange={e => {
                setNoOfMonths(e.target.value);
                setDateType('calculated');
              }}
              value={noOfMonths}
              disabled={!beforeEvent && !afterEvent}
            />
            Weeks:
            <Input
              type="number"
              height="35px"
              width="40px"
              min="0"
              onChange={e => {
                setNoOfWeeks(e.target.value);
                setDateType('calculated');
              }}
              value={noOfWeeks}
              disabled={!beforeEvent && !afterEvent}
            />
            Days:
            <Input
              type="number"
              height="35px"
              width="40px"
              min="0"
              onChange={e => {
                setNoOfDays(e.target.value);
                setDateType('calculated');
              }}
              value={noOfDays}
              disabled={!beforeEvent && !afterEvent}
            />
          </AddChecklistModalStyles.Span>
          <AddChecklistModalStyles.Span>
            <DatePicker
              inline
              selected={
                new Date(selectedDate) ||
                (dateType === 'specific' && new Date(selectedDate)) ||
                (dateType === 'calculated' && beforeEvent && new Date(selectedDate)) ||
                (dateType === 'calculated' && afterEvent && new Date(selectedDate))
              }
              onChange={date => {
                setSelectedDate(date);
                setDateType('specific');
              }}
            />
          </AddChecklistModalStyles.Span>
          <AddChecklistModalStyles.Span alignItems="center">
            Projected Due Date:
            <AddChecklistModalStyles.DueDate>
              {dateType && moment(selectedDate).format('LL')}
            </AddChecklistModalStyles.DueDate>
          </AddChecklistModalStyles.Span>
        </AddChecklistModalStyles.Values>
      </AddChecklistModalStyles.BodyRow>
    </AddChecklistModalStyles.Body>
  );
};

AddChecklistModalBody.propTypes = {
  startDate: PropTypes.string,
  role: PropTypes.string,
  item: PropTypes.object,
  itemToEdit: PropTypes.object,
  setItem: PropTypes.func
};

AddChecklistModalBody.defaultProps = {
  startDate: '',
  setItem: () => {},
  role: '',
  item: {},
  itemToEdit: {}
};

export default AddChecklistModalBody;
