export const OPEN_EDIT_GUEST_MODAL = 'OPEN_EDIT_GUEST_MODAL';
export const CLOSE_EDIT_GUEST_MODAL = 'CLOSE_EDIT_GUEST_MODAL';
export const OPEN_DELETE_GUEST_MODAL = 'OPEN_DELETE_GUEST_MODAL';
export const CLOSE_DELETE_GUEST_MODAL = 'CLOSE_DELETE_GUEST_MODAL';
export const OPEN_ADD_GROUP_MODAL = 'OPEN_ADD_GROUP_MODAL';
export const CLOSE_ADD_GROUP_MODAL = 'CLOSE_ADD_GROUP_MODAL';
export const GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_REQUEST = 'GET_GROUPS_REQUEST';
export const ADD_GROUP_FAILURE = 'ADD_GROUP_FAILURE';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const ADD_GROUP_REQUEST = 'ADD_GROUP_REQUEST';
export const OPEN_ADD_GUEST_MODAL = 'OPEN_ADD_GUEST_MODAL';
export const CLOSE_ADD_GUEST_MODAL = 'CLOSE_ADD_GUEST_MODAL';
export const RESET_EDITED_GUEST = 'RESET_EDITED_GUEST';
export const RESET_ADDDED_GROUP = 'RESET_ADDDED_GROUP';
export const BATCH_EDIT_GUESTS_REQUEST = 'BATCH_EDIT_GUESTS_REQUEST';
export const BATCH_EDIT_GUESTS_FAILURE = 'BATCH_EDIT_GUESTS_FAILURE';
export const BATCH_EDIT_GUESTS_SUCCESS = 'BATCH_EDIT_GUESTS_SUCCESS';
export const OPEN_DELETE_MULTIPLE_GUESTS_MODAL = 'OPEN_DELETE_MULTIPLE_GUESTS_MODAL';
export const CLOSE_DELETE_MULTIPLE_GUESTS_MODAL = 'CLOSE_DELETE_MULTIPLE_GUESTS_MODAL';
export const BATCH_DELETE_GUESTS_REQUEST = 'BATCH_DELETE_GUESTS_REQUEST';
export const BATCH_DELETE_GUESTS_FAILURE = 'BATCH_DELETE_GUESTS_FAILURE';
export const BATCH_DELETE_GUESTS_SUCCESS = 'BATCH_DELETE_GUESTS_SUCCESS';
export const RESET_DELETED_GUEST = 'RESET_DELETED_GUEST';
export const GET_EXPORT_DATA_FAILURE = 'GET_EXPORT_DATA_FAILURE';
export const GET_EXPORT_DATA_SUCCESS = 'GET_EXPORT_DATA_SUCCESS';
export const GET_EXPORT_DATA_REQUEST = 'GET_EXPORT_DATA_REQUEST';
export const RESET_EXPORT_DATA = 'RESET_EXPORT_DATA';
export const OPEN_EDIT_GROUP_MODAL = 'OPEN_EDIT_GROUP_MODAL';
export const CLOSE_EDIT_GROUP_MODAL = 'CLOSE_EDIT_GROUP_MODAL';
export const OPEN_DELETE_GROUP_MODAL = 'OPEN_DELETE_GROUP_MODAL';
export const CLOSE_DELETE_GROUP_MODAL = 'CLOSE_DELETE_GROUP_MODAL';
export const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP';
export const EDIT_GROUP_FAILURE = 'EDIT_GROUP_FAILURE';
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export const EDIT_GROUP_REQUEST = 'EDIT_GROUP_REQUEST';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const RESET_EDITED_GROUP = 'RESET_EDITED_GROUP';
export const RESET_DELETED_GROUP = 'RESET_DELETED_GROUP';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SET_SORT_FIELD = 'SET_SORT_FIELD';
export const SET_SORT_VALUE = 'SET_SORT_VALUE';
export const SET_RSVP_FILTER = 'SET_RSVP_FILTER';
export const SET_MEALOPTIONS_FILTER = 'SET_MEALOPTIONS_FILTER';
export const SET_TABLE_FILTER = 'SET_TABLE_FILTER';
export const SET_GROUP_FILTER = 'SET_GROUP_FILTER';
export const RESET_EDITED_GUESTS = 'RESET_EDITED_GUESTS';
export const RESET_DELETED_GUESTS = 'RESET_DELETED_GUESTS';
export const HOST_UPDATE_EVENT_REQUEST = 'HOST_UPDATE_EVENT_REQUEST';
export const HOST_UPDATE_EVENT_SUCCESS = 'HOST_UPDATE_EVENT_SUCCESS';
export const HOST_UPDATE_EVENT_FAILURE = 'HOST_UPDATE_EVENT_FAILURE';
export const HOST_SEND_EMAIL_REQUEST = 'HOST_SEND_EMAIL_REQUEST';
export const HOST_SEND_EMAIL_SUCCESS = 'HOST_SEND_EMAIL_SUCCESS';
export const HOST_SEND_EMAIL_FAILURE = 'HOST_SEND_EMAIL_FAILURE';
export const OPEN_SEND_BATCH_EMAIL_MODAL = 'OPEN_SEND_BATCH_EMAIL_MODAL';
export const CLOSE_SEND_BATCH_EMAIL_MODAL = 'CLOSE_SEND_BATCH_EMAIL_MODAL';
export const HOST_SEND_BATCH_EMAIL_REQUEST = 'HOST_SEND_BATCH_EMAIL_REQUEST';
export const HOST_SEND_BATCH_EMAIL_SUCCESS = 'HOST_SEND_BATCH_EMAIL_SUCCESS';
export const HOST_SEND_BATCH_EMAIL_FAILURE = 'HOST_SEND_BATCH_EMAIL_FAILURE';
export const GET_LAYOUT_REQUEST = 'GET_LAYOUT_REQUEST';
export const GET_LAYOUT_SUCCESS = 'GET_LAYOUT_SUCCESS';
export const GET_LAYOUT_FAILURE = 'GET_LAYOUT_FAILURE';
