import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export function list({ token, limit = null, startAfter = null }) {
  let url = `${apiUri}/venuess/aas`;

  if (limit || startAfter) {
    let query = '';

    query = limit ? `${query}&limit=${limit}` : query;
    query = startAfter ? `${query}&startAfter=${startAfter}` : query;
    query = query.substr(1);
    url = `${url}?${query}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: token }
  });
}

export function read({ token, id }) {
  return axios({
    method: 'get',
    url: `${apiUri}/venues/${id}`,
    headers: { Authorization: token }
  });
}

export function updateTutorial({ token, id, data }) {
  return axios({
    method: 'put',
    url: `${apiUri}/users/${id}`,
    headers: { Authorization: token },
    data
  });
}

export function getHostDetails({ token, id }) {
  return axios({
    method: 'get',
    url: `${apiUri}/users/${id}`,
    headers: { Authorization: token }
  });
}

export function updateVenuePaymentsApi({ venueId, token, data }) {
  return axios({
    method: 'put',
    url: `${apiUri}/venues/${venueId}/setuppayment`,
    headers: { Authorization: token },
    data
  });
}

export const createProductAction = ({ product, token }) =>
  axios({
    method: 'post',
    url: `${apiUri}/products/create`,
    headers: { Authorization: token },
    data: product
  });

export const createProductCategoryAction = ({ category, token }) =>
  axios({
    method: 'post',
    url: `${apiUri}/venues/productcategories/create`,
    headers: { Authorization: token },
    data: category
  });

export const createRateTypeAction = ({ rateType, token }) =>
  axios({
    method: 'post',
    url: `${apiUri}/venues/ratetypes/create`,
    headers: { Authorization: token },
    data: rateType
  });

export const createTaxRateAction = ({ taxRate, token }) =>
  axios({
    method: 'post',
    url: `${apiUri}/venues/taxrates/create`,
    headers: { Authorization: token },
    data: taxRate
  });
