import { Box, Center, HStack, Icon } from '@chakra-ui/react';
import { Text, Button, IconButton } from 'src/v2/UIComponents/Primitives';
import Colors from 'src/styles/Colors';
import { GrEdit } from 'react-icons/gr';
import { BsTrash } from 'react-icons/bs';

export const Header = ({ title, subtitle, buttonAction, secondaryAction, icons }) => {
  const handlerEdit = e => {
    e.stopPropagation();
    buttonAction();
  };

  return (
    <HStack
      fontSize="md"
      fontWeight="semibold"
      justifyContent="space-between"
      width="100%"
      role="group"
    >
      <Box color="primary.600" width="100%">
        <HStack justifyContent="space-between">
          <Text
            variant="contentTitle"
            color={Colors.black}
            fontSize="16px"
            width="100%"
            textAlign={'start'}
            flex={2} // Set a higher flex value for the title
          >
            {title}
          </Text>
          {subtitle && (
            <Box
              textAlign={'end'}
              fontSize="14px"
              letterSpacing="wide"
              color={Colors.gray7D}
              fontWeight={'500'}
              fontStyle={'italic'}
              flex={1} // Set a lower flex value for the subtitle
              ml={2} // Add some margin between the title and the subtitle
            >
              {subtitle}
            </Box>
          )}
        </HStack>
      </Box>
      {buttonAction && (
        <Center
          visibility="hidden"
          _groupHover={{
            visibility: 'visible'
          }}
        >
          {(icons && (
            <IconButton
              aria-label="test"
              variant="ghost"
              icon={<Icon as={GrEdit} w={4} h={4} />}
              tooltipLabel="Edit"
              onClick={handlerEdit}
            />
          )) || (
            <Button
              variant="link"
              color="var(--colors-primary)"
              fontFamily="Lato"
              fontStyle="normal"
              fontSize="14px"
              lineHeight="18px"
              fontWeight={700}
              onClick={handlerEdit}
              disableAnimation
              _hover={{ textDecoration: 'none' }}
            >
              Edit
            </Button>
          )}
        </Center>
      )}
      {secondaryAction && (
        <Center
          visibility="hidden"
          _groupHover={{
            visibility: 'visible'
          }}
        >
          {(icons && (
            <IconButton
              aria-label="test"
              colorScheme="red"
              variant="ghost"
              icon={<Icon as={BsTrash} w={4} h={4} />}
              tooltipLabel="Delete"
              onClick={secondaryAction}
            />
          )) || (
            <Button
              variant="link"
              color={Colors.redE7}
              fontFamily="Lato"
              fontStyle="normal"
              fontSize="14px"
              lineHeight="18px"
              fontWeight={700}
              onClick={secondaryAction}
              disableAnimation
              _hover={{ textDecoration: 'none' }}
            >
              Delete
            </Button>
          )}
        </Center>
      )}
    </HStack>
  );
};
