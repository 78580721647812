import { Flex, Text, Icon, Spinner } from '@chakra-ui/react';
import toast from 'react-hot-toast';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { IconButton, Skeleton } from 'src/v2/UIComponents/Primitives';
import { InfoSectionText } from 'src/v2/UIComponents';
import { Close, Edit, EventType } from 'src/v2/UIComponents/Primitives/Icons';
import {
  MdAccountBox,
  MdEmail,
  MdDateRange,
  MdOutlineMyLocation,
  MdDelete,
  MdDeleteOutline
} from 'react-icons/md';
import { BsFillPersonFill, BsFillTelephoneFill, BsFunnelFill } from 'react-icons/bs';
import { FiCopy } from 'react-icons/fi';
import { IoLocationSharp } from 'react-icons/io5';
import { ImAddressBook } from 'react-icons/im';
import { RiFolderInfoLine } from 'react-icons/ri';
import { useEvents, useLeads } from 'src/v2/Hooks/CollectionHooks';
import moment from 'moment';
import Colors from 'src/styles/Colors';
import { getInitials } from 'src/utils/Helpers';
import { useConfirm, useDrawers } from 'src/v2/Hooks/HelperHooks';
import { getDateDisplay } from 'src/utils/getDateDisplay';
import Delete from 'src/styles/Icons/Delete';

const infoTitleStyle = {
  fontSize: '13px',
  lineHeight: '16px',
  letterSpacing: '0.1px',
  fontWeight: 700
};

const infoValueStyle = {
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.1px',
  fontWeight: 400
};

// TODO: #560 Move copyText function to separate file

const copyText = async text => {
  await navigator.clipboard.writeText(text);
  toast('Text copied to clipboard', {
    icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
  });
};

// TODO: #559 Move InfoSectionComponent to separate file
const InfoSectionComponent = ({ title, value, iconName, icon, copy = false, isLoading }) => {
  return (
    <Flex width="100%" mb="25px" role="group">
      <Flex flexDir="column" width="100%">
        <Text {...infoTitleStyle} mb="12px">
          {title}
        </Text>
        <Flex alignItems="center" width="100%" justifyContent="space-between">
          <Flex alignItems="center">
            {(iconName && <Icon as={iconName} w={6} h={6} color="var(--colors-primary)" />) || icon}
            {!isLoading ? (
              <Text {...infoValueStyle} ml="20px">
                {value || '---'}
              </Text>
            ) : (
              <Skeleton width="160px" ml="20px" />
            )}
          </Flex>
          {/* {copy && value && (
            <Icon
              as={FiCopy}
              w={6}
              h={6}
              display="none"
              onClick={() => copyText(value)}
              color="var(--colors-primary)"
              cursor="pointer"
              _groupHover={{
                display: 'block'
              }}
            />
          )} */}
        </Flex>
      </Flex>
    </Flex>
  );
};

export const DisplayInfo = ({ isLoading }) => {
  const { closeLeadsDetails, leadStatusOptions, deleteLead } = useLeads();

  const { activeEvent: lead } = useEvents();

  const { openEventManagementDrawer } = useDrawers();
  const clientName = lead?.clientName?.trim();
  const clientNameArray = clientName?.split(' ');
  const clientInitials =
    (clientName && getInitials(clientNameArray?.[0], clientNameArray?.[1])) || '';

  const status = leadStatusOptions?.find(opt => opt.value === lead?.leadInfo?.status);

  const handleDelete = () => {
    openConfirm({
      title: `Delete Lead`,
      description: 'Are you sure you want to delete this lead? All saved details will be lost.',
      confirmId,
      confirmType: 'cancel',
      confirmButtonTitle: 'DELETE'
    });
  };

  const confirmId = 'deleteLead';
  const handleDeleteLead = () => deleteLead({ id: lead?.id });
  const { openConfirm } = useConfirm({ acceptAction: handleDeleteLead, confirmHookId: confirmId });

  return (
    <>
      <Flex
        width="378px"
        bg="white"
        flexDir="column"
        padding="30px"
        borderLeft={`1px solid ${Colors.grayED}`}
      >
        <Flex justifyContent="flex-end" mb="5px">
          <IconButton
            icon={<Icon as={MdDeleteOutline} w={'18px'} h={'18px'} />}
            mr="10px"
            bg={Colors.grayF7}
            color={Colors.black}
            onClick={() => handleDelete()}
            aria-label={''}
          />
          <IconButton
            icon={<Edit />}
            mr="10px"
            bg={Colors.grayF7}
            color={Colors.black}
            onClick={() => openEventManagementDrawer('lead', 'edit')}
            aria-label={''}
          />
          <IconButton
            icon={<Close />}
            bg={Colors.grayF7}
            color={Colors.black}
            onClick={() => closeLeadsDetails()}
            aria-label={''}
          />
        </Flex>
        <Flex flexDir="column" alignItems="center" width="100%" mb="24px">
          <Flex
            bg="var(--colors-primary)"
            color="white"
            width="90px"
            height="90px"
            borderRadius="30px"
            alignItems="center"
            justifyContent="center"
            fontWeight={400}
            fontSize="28px"
            lineHeight="34px"
            mb="20px"
          >
            {(isLoading && <Spinner />) || clientInitials}
          </Flex>
          <Text fontSize="20px" lineHeight="26px" letterSpacing="0.2px" fontWeight={700} mb="25px">
            {lead?.clientName}
          </Text>
        </Flex>
        <Flex overflow="auto" width="100%">
          {/* // TODO: #641 Leads DisplayInfo: refactor InfoSections to separate component */}
          <Flex flexDir="column" overflowY="scroll" width="100%">
            <InfoSectionComponent
              title="Client Name"
              value={lead?.clientName}
              iconName={MdAccountBox}
              isLoading={isLoading}
              icon={undefined}
              copy={undefined}
            />
            <InfoSectionComponent
              title="Email"
              value={lead?.leadInfo?.email}
              iconName={MdEmail}
              copy
              isLoading={isLoading}
              icon={undefined}
            />
            <InfoSectionComponent
              title="Phone"
              value={lead?.leadInfo?.prospectPhone}
              iconName={BsFillTelephoneFill}
              copy
              isLoading={isLoading}
              icon={undefined}
            />
            <InfoSectionText
              title="Address"
              value={lead?.leadInfo?.streetAddress}
              iconName={IoLocationSharp}
              isLoading={isLoading}
              icon={undefined}
            />
            {/* <InfoSectionComponent
              title="Company"
              value={lead?.leadInfo?.company}
              iconName={TiBusinessCard}
              copy
            /> */}
            <InfoSectionComponent
              title="Salesperson"
              value={lead?.salespersonName}
              iconName={BsFillPersonFill}
              isLoading={isLoading}
              icon={undefined}
              copy={undefined}
            />
            <InfoSectionText
              title="Lead Source"
              value={lead?.leadInfo?.sourceLabel}
              iconName={BsFunnelFill}
              isLoading={isLoading}
              icon={undefined}
            />
            <InfoSectionText
              title="Lead Date"
              value={lead?.created && moment(lead.created).format('L')}
              iconName={MdDateRange}
              isLoading={isLoading}
              icon={undefined}
            />
            <InfoSectionText
              title="Status"
              value={status?.displayName}
              iconName={RiFolderInfoLine}
              isLoading={isLoading}
              icon={undefined}
            />
            <InfoSectionText
              title="Tour Date"
              value={
                lead?.leadInfo?.tourDate &&
                getDateDisplay(new Date(lead.leadInfo.tourDate)).shortDateTime
              }
              iconName={MdDateRange}
              isLoading={isLoading}
              icon={undefined}
            />
            <InfoSectionText
              title="Guest Count"
              value={lead?.minimumGuests}
              iconName={ImAddressBook}
              isLoading={isLoading}
              icon={undefined}
            />
            <InfoSectionText
              title="Expected Close Date"
              value={
                lead?.leadInfo?.expectedCloseDate &&
                moment(lead.leadInfo.expectedCloseDate).format('L')
              }
              iconName={MdDateRange}
              isLoading={isLoading}
              icon={undefined}
            />
            <InfoSectionText
              title="Event Type"
              value={lead?.eventTypeLabel}
              isLoading={isLoading}
              icon={
                <EventType
                  width={6}
                  height={4}
                  color="var(--colors-primary)"
                  // TODO: #719 Leads DisplayInfo: Use correct icon components
                  style={{ marginRight: '20px' }}
                />
              }
              iconName={undefined}
            />
            <InfoSectionText
              title="Room"
              value={lead?.roomData?.name}
              iconName={MdOutlineMyLocation}
              isLoading={isLoading}
              icon={undefined}
            />
            {/* <InfoSectionText title="Notes" value={''} iconName={CgNotes} /> */}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
