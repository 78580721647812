import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import favicon from '../../assets/favicon/favicon.png';

const TitleComponent = ({ venueData }) => {
  const defaultTitle = 'VenueX';
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{(venueData && venueData.name) || defaultTitle}</title>
      <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
    </Helmet>
  );
};

TitleComponent.propTypes = {
  venueData: PropTypes.object || {}
};

export default TitleComponent;
