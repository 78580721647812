import { takeLatest, select, call, put } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import { Icon, Spinner } from '@chakra-ui/react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { openOverlayLoader, closeOverlayLoader } from 'src/containers/OverlayLoader/operations';
import { updateEventAction } from 'src/containers/AddEventModal/actions';
import { eventManagementDrawerOperations } from 'src/v2/Drawers/Redux/operations';
import {
  GET_LEADS_REQUEST,
  GET_LEAD_REQUEST,
  ADD_LEAD_REQUEST,
  ADD_NOTE_REQUEST,
  UPDATE_LEAD_REQUEST,
  DELETE_LEAD_REQUEST
} from './types';
import { getLeadsAction, getLeadAction, addLeadAction, addNoteAction } from './actions';
import {
  getLeadsSuccess,
  getLeadsFailure,
  getLeadSuccess,
  addLeadFailure,
  closeLeadsNotesForm,
  addNoteSuccess,
  deleteLeadSuccess,
  deleteLeadFailure,
  updateLeadSuccess,
  updateLeadFailure,
  getLeadsRequest,
  closeLeadsDetails
} from './operations';

import { deleteEventAction } from '../EventsContainer/actions';
import { getEventSuccess } from '../EventsContainer/operations';

const getToken = state => state.loginReducer.token;

export function* workerGetLeads({ getEvents }) {
  try {
    const token = yield select(getToken);
    const response = yield call(getLeadsAction, { token, getEvents });
    const { leads } = response.data;
    yield put(getLeadsSuccess(leads));
  } catch (error) {
    yield put(getLeadsFailure({ error: error.response && error.response.data.message }));
  }
}

function* workerGetLead({ id }) {
  try {
    const token = yield select(getToken);
    const response = yield call(getLeadAction, { token, id });
    const { lead } = response.data;
    yield put(getLeadSuccess({ ...lead, id }));
  } catch (error) {
    yield put(getLeadsFailure({ error: error.response && error.response.data.message }));
  }
}

function* workerAddLead({ lead }) {
  try {
    yield put(openOverlayLoader());
    const token = yield select(getToken);
    const response = yield call(addLeadAction, { lead, token });
    const { success, leads } = response?.data || {};
    if (success) {
      // the backend returns the list of leads with the new lead
      yield put(getLeadsSuccess(leads));
      toast('Saved successfully', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
    }
    yield put(eventManagementDrawerOperations.close());
  } catch (error) {
    yield put(addLeadFailure(error.response.data.error, error.response.data.errors));
  } finally {
    yield put(closeOverlayLoader());
  }
}

function* workerAddNote({ note, id }) {
  try {
    yield put(openOverlayLoader());
    const token = yield select(getToken);
    const response = yield call(addNoteAction, { note, token, id });
    const { success, event: lead } = response?.data || {};
    if (success) {
      yield put(addNoteSuccess(lead));
      yield put(getEventSuccess(lead));
      toast('Saved successfully', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
    }
    yield put(closeLeadsNotesForm());
  } catch (error) {
    yield put(addLeadFailure(error.response.data.error, error.response.data.errors));
  } finally {
    yield put(closeOverlayLoader());
  }
}

function* workerDeleteLead({ id }) {
  try {
    const token = yield select(getToken);
    const response = yield call(deleteEventAction, { token, id });
    const { data, leads } = response;
    if (data?.success) {
      yield put(closeLeadsDetails());
    }
    yield put(deleteLeadSuccess(data?.success));
    // update table
    yield put(getLeadsRequest());

    toast('Deleted successfully', {
      icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
    });
  } catch (error) {
    yield put(deleteLeadFailure(error.response.data.message, error.success));
  }
}

function* workerUpdateLead({ id, event }) {
  const loadingToastId = toast.loading('Updating Lead', {
    icon: <Spinner />
  });
  try {
    const token = yield select(getToken);
    // TODO: #549 Keep the 'Updating Lead' toast visible until update is complete

    const response = yield call(updateEventAction, { id, event, token });
    const { success, event: lead, leads } = response?.data || { success: false };

    if (success) {
      toast.dismiss(loadingToastId);
      toast('Lead Updated', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
      yield put(
        updateLeadSuccess({
          lead: {
            ...lead,
            id
          },
          leads
        })
      );
      yield put(getLeadsRequest());
      // TODO: #551 workerUpdateLead: close leadForm drawer after successful edit
    }
  } catch (error) {
    // TODO: #550 Handle error when updating a lead
    yield put(updateLeadFailure(error.response.data.error, error.response.data.errors));
    const errorMessage = error?.message || 'Failed to update lead';
    toast.error(errorMessage);
    toast.dismiss(loadingToastId);
  }
}

// EXPORTS
export function* watcherGetLeads() {
  yield takeLatest(GET_LEADS_REQUEST, workerGetLeads);
}
export function* watcherGetLead() {
  yield takeLatest(GET_LEAD_REQUEST, workerGetLead);
}

export function* watchAddLead() {
  yield takeLatest(ADD_LEAD_REQUEST, workerAddLead);
}

export function* watchAddNote() {
  yield takeLatest(ADD_NOTE_REQUEST, workerAddNote);
}

export function* watchDeleteLead() {
  yield takeLatest(DELETE_LEAD_REQUEST, workerDeleteLead);
}

export function* watchUpdateLead() {
  yield takeLatest(UPDATE_LEAD_REQUEST, workerUpdateLead);
}
