import { Box, Icon } from '@chakra-ui/react';
import { FC } from 'react';
import { BiSend } from 'react-icons/bi';
import Colors from 'src/styles/Colors';
import { Button } from 'src/v2/UIComponents/Primitives';
import { ComposerFooterTypes } from '../types';

export const ComposerFooter: FC<ComposerFooterTypes> = ({ sendEmail }) => {
  return (
    <Box
      borderBottom={`1px solid ${Colors.grayED7}`}
      height="80px"
      display="flex"
      flexDir="column"
      justifyContent={'center'}
      alignContent="center"
      padding="0px 12px"
      fontSize="14px"
      minHeight={'60px'}
      alignItems="flex-end"
    >
      <Box>
        <Button variant="primary" rightIcon={<Icon as={BiSend} />} onClick={() => sendEmail()}>
          Send
        </Button>
      </Box>
    </Box>
  );
};
