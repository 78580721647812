/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import Colors from '../Colors';

const InputRadioStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  background-color: ${props =>
    props.checked ? props.bgColor || `var(--colors-primary)` : Colors.white};
  width: 18px;
  height: 18px;
  overflow: hidden;
  border: 1px solid
    ${props =>
      props.checked
        ? props.borderColor || `var(--colors-primary)`
        : !props.checked
        ? `var(--colors-primary)`
        : Colors.grayB0};
  border-radius: 20px;
  position: relative;
  transition: all 250ms ease-in-out 0s;

  svg {
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }

  &:hover {
    border: 1px solid ${props => props.borderColorHover || `var(--colors-primary)` || Colors.grayB0};
  }
`;

InputRadioStyles.Fill = styled.div`
  display: block;
  background-color: ${props => props.bgColor || Colors.white};
  width: ${props => props.size || '20px'};
  height: ${props => props.size || '20px'};
`;

export default InputRadioStyles;
