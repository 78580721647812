import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export function forgot({ email, venueId, eventId }) {
  return axios({
    method: 'post',
    url: `${apiUri}/auth/forgot`,
    data: { email, venueId, eventId }
  });
}

export function remind({ email, venueId, eventId }) {
  return axios({
    method: 'post',
    url: `${apiUri}/auth/remind`,
    data: { email, venueId, eventId }
  });
}
