import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { DashboardReducer } from 'src/Types';
// action types
import {
  EVENT_FAILURE,
  EVENT_SUCCESS,
  EVENT_REQUEST,
  NOTES_FAILURE,
  NOTES_SUCCESS,
  NOTES_REQUEST,
  NOTE_CREATE_FAILURE,
  NOTE_CREATE_SUCCESS,
  NOTE_CREATE_REQUEST,
  NOTE_DELETE_FAILURE,
  NOTE_DELETE_SUCCESS,
  NOTE_DELETE_REQUEST,
  CLOUD_IMAGE_UPLOAD_REQUEST,
  CLOUD_IMAGE_UPLOAD_FAILURE,
  CLOUD_IMAGE_UPLOAD_SUCCESS,
  EVENT_PHOTO_EDIT_REQUEST,
  EVENT_PHOTO_EDIT_SUCCESS,
  EVENT_PHOTO_EDIT_FAILURE,
  IDEAS_REQUEST,
  IDEAS_SUCCESS,
  IDEAS_FAILURE,
  EVENT_IDEA_CREATE_REQUEST,
  EVENT_IDEA_CREATE_SUCCESS,
  EVENT_IDEA_CREATE_FAILURE,
  EVENT_IDEA_EDIT_REQUEST,
  EVENT_IDEA_EDIT_SUCCESS,
  EVENT_IDEA_EDIT_FAILURE,
  EVENT_IDEA_DELETE_REQUEST,
  EVENT_IDEA_DELETE_FAILURE,
  EVENT_IDEA_DELETE_SUCCESS,
  VENUE_STAFF_REQUEST,
  VENUE_STAFF_SUCCESS,
  VENUE_STAFF_FAILURE,
  EVENT_CONTACTS_UPDATE_REQUEST,
  EVENT_CONTACTS_UPDATE_SUCCESS,
  EVENT_CONTACTS_UPDATE_FAILURE,
  NOTES_CLEAN,
  CLEAR_IMAGE_UPLOAD,
  OPEN_ADD_CONTACT_MODAL,
  CLOSE_ADD_CONTACT_MODAL,
  RESET_ADDED_CONTACT,
  NOTE_EDIT_FAILURE,
  NOTE_EDIT_REQUEST,
  NOTE_EDIT_SUCCESS,
  RESET_EDITED_NOTE,
  PROFILE_AVATAR_EDIT_REQUEST,
  PROFILE_AVATAR_EDIT_FAILURE,
  PROFILE_AVATAR_EDIT_SUCCESS
} from './types';
import { EDIT_MOBILE_PHOTO_SUCCESS } from '../MemoriesContainer/types';

// reducer with initial state
const initialState = {
  fetching: false,
  uploadStatus: false,
  notes: [],
  addNoteLoading: false,
  addNoteError: null,
  noteSuccess: null,
  event: {
    title: null,
    eventType: null,
    photo: '',
    room: null,
    layout: null,
    guestsTotal: 0,
    guestsYes: 0,
    guestsNo: 0,
    guestsMaybe: 0,
    guestsTableAssigned: 0,
    start: null,
    end: null,
    duration: null,
    venueId: null,
    minimumGuests: null,
    consultant: null,
    staff: [],
    createdBy: null,
    created: null,
    updated: null
  },
  imageUpload: {
    imageURLs: [],
    uploadType: null
  },
  ideas: [],
  contacts: [],
  staff: [],
  error: null,
  addContactModalOpen: false,
  addContactLoading: false,
  addContactError: null,
  addContactSuccess: false,
  editNoteLoading: false,
  editNoteError: null,
  editNoteSuccess: false,
  editProfileAvatar: false
};

const persistConfig = {
  key: 'dashboard',
  storage,
  blacklist: ['token', 'refresh', 'expires', 'role', 'claims', 'admin', 'fetching']
};

const reducer = (state: DashboardReducer = initialState, action) => {
  switch (action.type) {
    case EVENT_REQUEST:
      return {
        ...state,
        fetching: true,
        imageUpload: {
          imageURLs: [],
          uploadType: null
        },
        error: null
      };
    case EVENT_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case EVENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        event: action.event,
        contacts: action.event.contacts ? action.event.contacts : []
      };
    case NOTES_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null
      };
    case NOTES_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case NOTES_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        notes: action.notes
      };
    case NOTE_CREATE_REQUEST:
      return {
        ...state,
        addNoteLoading: true,
        addNoteError: null
      };
    case NOTE_CREATE_FAILURE:
      return {
        ...state,
        addNoteLoading: false,
        addNoteError: action.error
      };
    case NOTE_CREATE_SUCCESS:
      return {
        ...state,
        addNoteLoading: false,
        noteSuccess: action.note
      };
    case NOTE_EDIT_REQUEST:
      return {
        ...state,
        editNoteLoading: true,
        editNoteError: null,
        editNoteSuccess: false
      };
    case NOTE_EDIT_FAILURE:
      return {
        ...state,
        editNoteLoading: false,
        editNoteError: action.error
      };
    case NOTE_EDIT_SUCCESS:
      return {
        ...state,
        editNoteLoading: false,
        editNoteSuccess: true
      };
    case NOTE_DELETE_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null
      };
    case NOTE_DELETE_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case NOTE_DELETE_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        notes: state.notes.filter(note => note.id !== action.noteId)
      };
    case EVENT_PHOTO_EDIT_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null
      };
    case EVENT_PHOTO_EDIT_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case EVENT_PHOTO_EDIT_SUCCESS:
      return {
        ...state,
        fetching: false,
        imageUpload: null,
        error: null,
        event: { ...state.event, ...action.event }
      };
    case CLOUD_IMAGE_UPLOAD_REQUEST:
      return {
        ...state,
        fetching: true,
        uploadStatus: true,
        error: null
      };
    case CLOUD_IMAGE_UPLOAD_FAILURE:
      return {
        ...state,
        fetching: false,
        uploadStatus: false,
        error: action.error
      };
    case CLOUD_IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        fetching: false,
        uploadStatus: false,
        error: null,
        imageUpload: action.imageUpload
      };
    case IDEAS_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null
      };
    case IDEAS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case IDEAS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        ideas: action.ideas
      };
    case EVENT_IDEA_CREATE_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null
      };
    case EVENT_IDEA_CREATE_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case EVENT_IDEA_CREATE_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        imageUpload: null,
        ideas: [...state.ideas, action.idea]
      };
    case EVENT_IDEA_EDIT_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null
      };
    case EVENT_IDEA_EDIT_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case EVENT_IDEA_EDIT_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        ideas: state.ideas.map(idea => {
          if (idea.id === action.idea.id) {
            return { ...idea, ...action.idea };
          }
          return { ...idea };
        })
      };
    case EVENT_IDEA_DELETE_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null
      };
    case EVENT_IDEA_DELETE_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case EVENT_IDEA_DELETE_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        ideas: state.ideas.filter(idea => idea.id !== action.ideaId)
      };
    case PROFILE_AVATAR_EDIT_REQUEST:
      return {
        ...state,
        fetching: true,
        uploadStatus: true,
        error: null
      };
    case PROFILE_AVATAR_EDIT_FAILURE:
      return {
        ...state,
        fetching: false,
        uploadStatus: false,
        editProfileAvatar: false,
        error: action.error
      };
    case PROFILE_AVATAR_EDIT_SUCCESS:
      return {
        ...state,
        fetching: false,
        uploadStatus: false,
        editProfileAvatar: true
      };
    case VENUE_STAFF_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null
      };
    case VENUE_STAFF_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case VENUE_STAFF_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        staff: action.staff
      };
    case EVENT_CONTACTS_UPDATE_REQUEST:
      return {
        ...state,
        addContactLoading: true,
        addContactError: null,
        addContactSuccess: false
      };
    case EVENT_CONTACTS_UPDATE_FAILURE:
      return {
        ...state,
        addContactLoading: false,
        addContactError: action.error
      };
    case EVENT_CONTACTS_UPDATE_SUCCESS:
      return {
        ...state,
        addContactLoading: false,
        error: null,
        contacts: action.contacts,
        addContactSuccess: true
      };
    case NOTES_CLEAN:
      return {
        ...state,
        noteSuccess: null,
        addNoteError: null
      };
    case CLEAR_IMAGE_UPLOAD:
      return {
        ...state,
        imageUpload: {
          imageURLs: [],
          uploadType: null
        }
      };
    case OPEN_ADD_CONTACT_MODAL:
      return {
        ...state,
        addContactModalOpen: true
      };
    case CLOSE_ADD_CONTACT_MODAL:
      return {
        ...state,
        addContactModalOpen: false
      };
    case RESET_ADDED_CONTACT:
      return {
        ...state,
        addContactSuccess: false,
        addContactError: null
      };
    case RESET_EDITED_NOTE:
      return {
        ...state,
        editNoteSuccess: false,
        editNoteError: null
      };
    case EDIT_MOBILE_PHOTO_SUCCESS:
      return {
        ...state,
        imageUpload: {
          imageURLs: [],
          uploadType: null
        }
      };
    default:
      return state;
  }
};

const dashboardReducer = persistReducer(persistConfig, reducer);

export default dashboardReducer;
