import {
  CommentDocumentOperation,
  DeleteDocumentOperation,
  DownloadDocumentOperation,
  GetDocumentsRequest,
  UpdateVersionOperation,
  UploadDocumentRequest
} from 'src/containers/DocumentsContainer/OperationTypes';
import {
  AutoFillDocumentOperation,
  DrawerCloseOperation,
  DrawerOpenOperation,
  SelectDocumentOperation
} from 'src/Types/GlobalTypes';

import {
  ADD_DOCUMENT_FAILURE,
  ADD_DOCUMENT_SUCCESS,
  AUTOFILL_DOCUMENT_REQUEST,
  CLEAR_DOCUMENT,
  CLOSE_DOCUMENT_UPLOAD,
  CLOSE_UPLOAD_DOCUMENT_VERSION_DRAWER,
  COMMENT_DOCUMENT_FAILURE,
  COMMENT_DOCUMENT_REQUEST,
  COMMENT_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAILURE,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SUCCESS,
  DOCUMENT_UPLOAD_FAILURE,
  DOCUMENT_UPLOAD_REQUEST,
  DOCUMENT_UPLOAD_SUCCESS,
  DOCUMENTS_HIDE_NOTIFICATIONS,
  DOWNLOAD_DOCUMENT,
  GET_DOCUMENT_FAILURE,
  GET_DOCUMENT_REQUEST,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENTS_FAILURE,
  GET_DOCUMENTS_REQUEST,
  GET_DOCUMENTS_SUCCESS,
  OPEN_DOCUMENT_UPLOAD,
  OPEN_UPLOAD_DOCUMENT_VERSION_DRAWER,
  SELECT_DOCUMENT,
  UPDATE_DOCUMENT_FAILURE,
  UPDATE_DOCUMENT_REQUEST,
  UPDATE_DOCUMENT_SUCCESS
} from './types';

export const getDocumentsRequest: GetDocumentsRequest = ({ eventId }) => ({
  type: GET_DOCUMENTS_REQUEST,
  eventId
});

export const getDocumentsFailure = ({ error }) => ({
  type: GET_DOCUMENTS_FAILURE,
  error
});

export const getDocumentsSuccess = ({ documents }) => ({
  type: GET_DOCUMENTS_SUCCESS,
  documents
});

export const getDocumentRequest = ({ eventId, documentId }) => ({
  type: GET_DOCUMENT_REQUEST,
  eventId,
  documentId
});

export const getDocumentFailure = ({ error }) => ({
  type: GET_DOCUMENT_FAILURE,
  error
});

export const getDocumentSuccess = ({ document }) => ({
  type: GET_DOCUMENT_SUCCESS,
  document
});

export const addDocumentFailure = ({ error }) => ({
  type: ADD_DOCUMENT_FAILURE,
  error
});

export const addDocumentSuccess = () => ({
  type: ADD_DOCUMENT_SUCCESS
});

export const updateDocumentRequest: UpdateVersionOperation = ({
  eventId,
  documentId,
  document
}) => ({
  type: UPDATE_DOCUMENT_REQUEST,
  eventId,
  documentId,
  document
});

export const updateDocumentFailure = ({ error }) => ({
  type: UPDATE_DOCUMENT_FAILURE,
  error
});

export const updateDocumentSuccess = ({ documents }) => ({
  type: UPDATE_DOCUMENT_SUCCESS,
  documents
});

export const deleteDocumentRequest: DeleteDocumentOperation = ({ eventId, documentId }) => ({
  type: DELETE_DOCUMENT_REQUEST,
  eventId,
  documentId
});

export const deleteDocumentFailure = ({ error }) => ({
  type: DELETE_DOCUMENT_FAILURE,
  error
});

export const deleteDocumentSuccess = ({ documents }) => ({
  type: DELETE_DOCUMENT_SUCCESS,
  documents
});

export const commentDocumentRequest: CommentDocumentOperation = ({
  eventId,
  documentId,
  content
}) => ({
  type: COMMENT_DOCUMENT_REQUEST,
  eventId,
  documentId,
  content
});

export const commentDocumentFailure = ({ error }) => ({
  type: COMMENT_DOCUMENT_FAILURE,
  error
});

export const commentDocumentSuccess = ({ updatedDocument, documents }) => ({
  type: COMMENT_DOCUMENT_SUCCESS,
  updatedDocument,
  documents
});

export const documentUploadOpen = () => ({
  type: OPEN_DOCUMENT_UPLOAD
});

export const documentUploadClose = () => ({
  type: CLOSE_DOCUMENT_UPLOAD
});

export const documentUploadRequest: UploadDocumentRequest = ({ eventId, formData }) => ({
  type: DOCUMENT_UPLOAD_REQUEST,
  eventId,
  formData
});

export const documentUploadSuccess = () => ({
  type: DOCUMENT_UPLOAD_SUCCESS,
  successMessage: 'Document uploaded'
});

export const documentUploadFailure = error => ({
  type: DOCUMENT_UPLOAD_FAILURE,
  errorMessage: error
});

export const hideDocLibraryNotification = () => ({
  type: DOCUMENTS_HIDE_NOTIFICATIONS
});

export const autoFillDocumentRequest: AutoFillDocumentOperation = ({
  token,
  eventId,
  venueId,
  docId,
  documentName
}) => ({
  type: AUTOFILL_DOCUMENT_REQUEST,
  token,
  eventId,
  venueId,
  docId,
  documentName
});

export const downloadDocumentOperations: DownloadDocumentOperation = {
  request: ({ eventId, docId, filename, url }) => ({
    type: DOWNLOAD_DOCUMENT.REQUEST,
    eventId,
    docId,
    filename,
    url
  }),
  success: () => ({
    type: DOWNLOAD_DOCUMENT.SUCCESS
  }),
  failure: () => ({
    type: DOWNLOAD_DOCUMENT.FAILURE
  })
};

export const openUploadDocumentVersionDrawer: DrawerOpenOperation = () => ({
  type: OPEN_UPLOAD_DOCUMENT_VERSION_DRAWER
});

export const closeUploadDocumentVersionDrawer: DrawerCloseOperation = () => ({
  type: CLOSE_UPLOAD_DOCUMENT_VERSION_DRAWER
});

export const selectDocument: SelectDocumentOperation = ({ document }) => ({
  type: SELECT_DOCUMENT,
  document
});

export const clearDocument: () => void = () => ({
  type: CLEAR_DOCUMENT
});
