export const scrollBarProps = {
  '::-webkit-scrollbar': {
    width: '12px'
  },
  '&:hover::-webkit-scrollbar': {
    width: '12px'
  },
  '&:hover::-webkit-scrollbar-track': {
    backgroundColor: 'rgba(221, 221, 221, 0.3)',
    borderRight: '3px solid #fafafa',
    borderLeft: '3px solid #fafafa',
    borderRadius: '6px'
  },
  // '&:hover ::-webkit-scrollbar-button': {
  //   display: 'none'
  // },
  '&:hover::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(221, 221, 221, 0.8)',
    borderRight: '3px solid #fafafa',
    borderLeft: '3px solid #fafafa',
    borderRadius: '5.5px'
  }
};
export const scrollSiblingProps = {
  marginRight: '12px'
};

export const scrollParentProps = {
  paddingRight: '12px'
};
