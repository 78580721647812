/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import {
  useDocumentLibrary,
  useEvents,
  useEventsDetails,
  useLeads,
  useRooms,
  useUser,
  useVenue
} from 'src/v2/Hooks/CollectionHooks';
import { useDrawers, useLoader } from 'src/v2/Hooks/HelperHooks';
import moment from 'moment';
import { RawEvent } from 'venuex-types';
import { useTableContext } from 'src/v2/context/Tables';

import { CalendarToolbar } from './CalendarToolbar';
import CalendarEvent from './CalendarEvent';
import CalendarDayHeader from './CalendarDayHeader';

const localizer = momentLocalizer(moment);

const RootCalendarComponent = ({ data, setDefaultWizardDate, expanded, setExpanded }) => {
  const tableId = 'calendar';
  const { tableData, getData, resetTable, contextTableId } = useTableContext<RawEvent>();

  const eventsToRender = tableData?.map(event => {
    const { start, end } = event;
    const startDay = start && new Date(start)?.getDay();
    const endDay = end && new Date(end)?.getDay();

    if (startDay !== endDay) {
      const newEnd = start && new Date(start)?.setHours(23);
      return {
        ...event,
        end: newEnd
      };
    }

    return { ...event };
  });

  // STATES
  const [date, setDate] = useState(new Date());
  const [highlight, setHighlight] = useState(null);
  const [dayClicked, setDayClicked] = useState<Date | null>(null);
  const [showSkeletons, setShowSkeletons] = useState(true);

  useEffect(() => {
    if (!data && tableData && contextTableId !== tableId && !data?.length) resetTable(tableId);
    if (tableId !== contextTableId) getData(data, tableId);
    if (data && data.length) getData(data, tableId);
  }, [data, tableId]);

  // REDUCERS

  const { eventsState, getEvents, getEvent, getEventTasksRequest } = useEvents();
  const { openLeadsDetails } = useLeads();
  const { openEventDetailsDrawer } = useEventsDetails();
  const { token } = useUser();
  const { glassLoaderVisible, showGlassLoader, hideGlassLoader } = useLoader();

  // DISPATCHES

  const { fetching: fetchingEvents } = eventsState;

  // const openEventModal = ({ eventId }) => dispatch(openEventModalRequest({ eventId, token }));
  const { getVenue, getStaff, fetchingFireStore } = useVenue();
  const { getRooms } = useRooms();
  const { getLibraryDocuments } = useDocumentLibrary();
  const { openCalendarWizard } = useDrawers();

  // STATE

  // USE EFFECTS

  useEffect(() => {
    showGlassLoader();
    getVenue();
    getRooms();
    getStaff();
    getLibraryDocuments();
    getEventTasksRequest();
  }, []);

  useEffect(() => {
    const activeMonth = date;
    const firstDay = new Date(activeMonth.getFullYear(), activeMonth.getMonth(), 1, 0, 0, 0);
    const lastDay = new Date(activeMonth.getFullYear(), activeMonth.getMonth() + 1, 0, 23, 59, 59);
    if (!token) return;
    getEvents({ token, monthStart: firstDay, monthEnd: lastDay });

    // dispatch(getEvents({ token, monthStart: firstDay, monthEnd: lastDay }));
  }, [date]);

  useEffect(() => {
    if (!fetchingEvents && !fetchingFireStore) setShowSkeletons(false);
    if (!fetchingEvents && glassLoaderVisible) setTimeout(() => hideGlassLoader(), 1500);
    if (fetchingEvents || fetchingFireStore) setShowSkeletons(true);
  }, [fetchingEvents, fetchingFireStore]);

  // FUNCTIONS
  const highlightDayPropGetter = dateProp => {
    if (showSkeletons) return { className: 'calendarLoading' };
    if (!highlight && !dayClicked) return {};

    const clickHighLight = moment(dayClicked).format('MM/DD/YYYY');
    const dateHighlight = moment(highlight).format('MM/DD/YYYY');
    const dateCompare = moment(dateProp).format('MM/DD/YYYY');
    if (dateHighlight === dateCompare) return { className: 'calendar-highlight' };
    if (clickHighLight === dateCompare) return { className: 'calendar-click' };

    return {};
  };

  const selectSlot = (start: Date) => {
    setDefaultWizardDate(start);
    openCalendarWizard(start.toISOString());
    setDayClicked(start);
    setTimeout(() => setDayClicked(null), 1000);
  };

  const handleClickEventCalendar = event => {
    const { id } = event;
    if (event?.type === 'lead') {
      getEvent({ eventId: event?.id });

      return openLeadsDetails();
    }
    if (event?.appointment) {
      getEvent({ eventId: event?.eventId });
      openEventDetailsDrawer();
    } else {
      getEvent({ eventId: id });
      openEventDetailsDrawer();
    }
  };
  return (
    <BigCalendar
      popup
      events={(showSkeletons && []) || eventsToRender || []}
      date={date}
      localizer={localizer}
      onSelectEvent={handleClickEventCalendar}
      selectable
      dayPropGetter={highlightDayPropGetter}
      onSelectSlot={({ start }) => selectSlot(start)}
      formats={{ dateFormat: 'D' }}
      components={{
        toolbar: toolbarProps => {
          const {
            localizer: { messages },
            label
          } = toolbarProps;
          return (
            <CalendarToolbar
              date={date}
              label={label}
              messages={messages}
              setDate={setDate}
              setHighlight={setHighlight}
              expanded={expanded}
              setExpanded={setExpanded}
            />
          );
        },
        month: {
          dateHeader: day => <CalendarDayHeader day={day} />,
          event: props => <CalendarEvent {...props} />
        }
      }}
      views={['month']}
      tooltipAccessor={undefined}
      style={{ border: 0 }}
    />
  );
};

export const CalendarComponent = React.memo(RootCalendarComponent);
