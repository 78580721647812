import React from 'react';
import Colors from '../Colors';

const DocVersion = props => {
  return (
    <svg width={props.size} height={props.size} {...props} viewBox="0 0 64 64">
      <g fill={props.color}>
        <path d="M11,62H49a3,3,0,0,0,3-3V58h1a3,3,0,0,0,3-3V17s0,0,0-.06a1.09,1.09,0,0,0-.05-.26.36.36,0,0,0,0-.09,1.17,1.17,0,0,0-.19-.3l-14-14a1.32,1.32,0,0,0-.29-.19l-.1,0L41.07,2H15a3,3,0,0,0-3,3V6H11A3,3,0,0,0,8,9V59A3,3,0,0,0,11,62Zm39-3a1,1,0,0,1-1,1H11a1,1,0,0,1-1-1V54h2v1a3,3,0,0,0,3,3H50Zm4-4a1,1,0,0,1-1,1H15a1,1,0,0,1-1-1V50H54ZM52.59,16H43a1,1,0,0,1-1-1V5.41ZM14,5a1,1,0,0,1,1-1H40V15a3,3,0,0,0,3,3H54V48H14ZM11,8h1V52H10V9A1,1,0,0,1,11,8Z" />
      </g>
    </svg>
  );
};

DocVersion.defaultProps = {
  color: Colors.black,
  size: 24
};

export default DocVersion;
