import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { doListVenues, doLoadMoreVenues } from './operations';
import AppContainer from '../AppContainer';
import Venues from '../../components/Venues';
import { isAuthenticated } from '../../middlewares';

const VenuesContainer = props => {
  useEffect(() => {
    props.doListVenues({ token: props.token });
  }, []);

  return (
    <AppContainer>
      <Venues {...props} />
    </AppContainer>
  );
};

const mapStateToProps = state => ({
  fetching: state.venuesReducer.fetching,
  error: state.venuesReducer.error,
  data: state.venuesReducer.venues,
  token: state.loginReducer.token,
  startAfter: state.venuesReducer.startAfter
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doListVenues,
      doLoadMoreVenues
    },
    dispatch
  );

VenuesContainer.propTypes = {
  token: PropTypes.string,
  doListVenues: PropTypes.func,
  doLoadMoreVenues: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(isAuthenticated)(VenuesContainer));
