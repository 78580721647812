import React, { ReactNode, MouseEvent, FC } from 'react';
import { Button, Icon } from '@chakra-ui/react';
import { MenuLink } from './styles';

interface Props {
  children?: ReactNode;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  isActive?: boolean;
  icon?: any;
  isDisabled?: boolean;
}

export const MenuItem: FC<Props> = ({ icon, children, isActive = false, onClick, isDisabled }) => {
  return (
    <MenuLink
      isActive={isActive}
      leftIcon={icon && <Icon as={icon} mr="5px" w={6} h={6} />}
      onClick={onClick}
      isDisabled={isDisabled}
    >
      {children}
    </MenuLink>
  );
};
