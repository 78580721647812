import ReactTooltip from 'react-tooltip/dist';
import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const SidebarWrapper = styled.aside`
  flex: 0 0 ${props => (props.hideSidebar ? '100px' : '240px')};
  width: ${props => (props.hideSidebar ? '100px' : '240px')};
  height: ${props => (props.isAdmin ? 'calc(100% - 50px)' : '100%')};
  top: ${props => (props.isAdmin ? '50px' : '0')};
  bottom: 0;
  left: 0;
  transition: all 300ms;
`;

const SidebarStyles = styled.div`
  background-color: var(--colors-sideBar);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

SidebarStyles.Header = styled.div`
  display: flex;
  height: 120px;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 25px 10px;
  box-sizing: border-box;
`;

SidebarStyles.Img = styled.img`
  display: block;
  text-align: center;
  width: 100%;
  height: auto;
  object-fit: cover;
  cursor: pointer;
  transition: opacity 500ms linear 0.1s;
  background-color: ${props => (props.src ? '' : Colors.grayEE)};
`;

SidebarStyles.Body = styled.div`
  display: block;
  width: 100%;
  height: calc(100% - 138px - 75px);
`;

SidebarStyles.Nav = styled.ul`
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
`;

SidebarStyles.NavLink = styled.li`
  display: flex;
  width: 100%;
  height: ${props => props.height || '70px'};
  transition: all 600ms linear;
  background-color: ${props =>
    props.selected && !props.hideSidebar ? `var(--colors-sideBar-selected)` : 'transparent'};
  border-left: 4px solid ${props => (props.selected ? `var(--colors-primary)` : 'transparent')};
  padding-left: ${props => (!props.hideSidebar && '22px') || 0};
  ${props => props.hideSidebar && 'justify-content: space-around;'}
  a {
    display: grid;
    flex: 1 1 0px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    background-color: transparent;
    color: var(--colors-sideBarText);
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    height: ${props => props.height || '70px'};
    width: calc(100% - 54px - 20px);
    transition: all 600ms linear;
    position: relative;
    z-index: 1;
    width: auto;

    svg {
      padding: ${props => props.hideSidebar && '0px'};
      transition: all 400ms linear;
      fill: ${props => {
        if (props.selected) return `var(--colors-sideBarHiddenIcon-selected) !important`;
        if (props.hideSidebar) return `var(--colors-sideBarHiddenIcon)`;
        return null;
      }};
      flex: ${props => props.hideSidebar && `1 1 0px`};

      path,
      g {
        fill: ${props => {
          if (props.selected && props.hideSidebar)
            return `var(--colors-sideBarHiddenIcon-selected) !important`;
          if (props.hideSidebar) return `var(--colors-sideBarHiddenIcon)`;
          return null;
        }};
      }
    }
    &:hover {
      svg {
        transform: ${props => props.hideSidebar && 'scale(1.1)'}};
        fill: ${props => {
          if (props.hideSidebar) return `var(--colors-primary-light)`;
          return null;
        }};
      }
    }
    span {
      margin-left: 10.1px;
      font-family: Lato;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.1px;
      color: var(--colors-sideBarText);
      opacity: ${props => props.hideSidebar && '0'};
      transition: all 600ms linear;
    }

    &:hover {
      background-color: ${props => {
        if (props.hideSidebar && props.selected) return 'var(--colors-sideBar)';
        if (props.hideSidebar) return null;
        return 'var(--colors-sideBar-hover)';
      }};
      path,
      g,
      svg {
        fill: ${props => {
          if (props.hideSidebar) return `var(--colors-primary)`;
          return null;
        }};
      }
    }
  }
`;

SidebarStyles.CollapseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  padding: 8px;
  cursor: pointer;
`;

SidebarStyles.Footer = styled.div`
  line-height: 15px;
  font-size: 12px;
  color: ${Colors.white};
  opacity: 0.6;
  font-weight: bold;
  text-align: center;
  display: block;
  width: 100%;
  height: 15px;
  padding: 30px 0px;
`;

SidebarStyles.StyledTooltip = styled(ReactTooltip)`
  z-index: 999999 !important;
  overflow: visible;
`;

export default SidebarStyles;
