import styled from 'styled-components';

const StatusStyles = styled.div`
  display: flex;
  height: ${props => props.size || '14px'};
  width: ${props => props.size || '14px'};
  border-radius: 50%;
  background-color: ${props => props.bgColor};
  margin: 1px;
`;

export default StatusStyles;
