/* eslint-disable react/prop-types */
import {
  FormErrorMessage,
  FormControl,
  VStack,
  Box,
  Stack,
  Tooltip,
  HStack,
  Text
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import InputFile from 'src/components/InputFile';
import Colors from 'src/styles/Colors';
import Delete from 'src/styles/Icons/Delete';
import { Button } from 'src/v2/UIComponents/Primitives';

import { EventDocumentName } from './Inputs/DocumentName';
import { EventDocumentDescription } from './Inputs/DocumentDescrption';

export const UploadDocument = ({ version = false }) => {
  const {
    watch,
    setValue,
    formState: { errors }
  } = useFormContext();

  const fileName = watch('filename');

  const isFetching = false;

  const handleChange = e => {
    const fileToUpload = e.target.files[0];
    if (fileToUpload) {
      setValue('documentUpload', fileToUpload, { shouldDirty: true, shouldTouch: true });
      setValue('label', fileToUpload?.name?.replace(/\.[^/.]+$/, ''), {
        shouldDirty: true,
        shouldTouch: true
      });
      setValue('filename', fileToUpload?.name, {
        shouldDirty: true,
        shouldTouch: true
      });
    }
  };

  const handleClear = () => {
    setValue('documentUpload', '');
    setValue('filename', '');
    setValue('libraryDocument', '');
    setValue('label', '');
  };

  return (
    <Stack width="100%" alignItems="stretch" paddingTop="16px" justifyContent="space-between">
      <VStack spacing={8}>
        <HStack justifyContent="space-between" width="100%">
          <FormControl
            display="flex"
            justifyContent="space-between"
            align-items="center"
            isInvalid={!!errors.documentUpload || false}
            marginBottom="0"
          >
            <Stack>
              <Text fontWeight="medium">File To Upload</Text>
              <FormErrorMessage>
                {errors.documentUpload && !!errors.documentUpload.message}
              </FormErrorMessage>
            </Stack>

            <Box>
              <InputFile
                id="documentUpload"
                accept={null}
                disabled={isFetching}
                name="documentUpload"
                onChange={handleChange}
              />
              {(fileName && (
                <HStack>
                  <Tooltip label={fileName} aria-label={fileName}>
                    <Text isTruncated maxWidth="350px">
                      {fileName}
                    </Text>
                  </Tooltip>
                  <Box onClick={() => handleClear()} style={{ cursor: 'pointer' }}>
                    {' '}
                    <Delete size={14} color={Colors.redC0} />
                  </Box>
                </HStack>
              )) || (
                <Button
                  onClick={() => document?.getElementById('documentUpload')?.click()}
                  isLoading={isFetching}
                  width="160px"
                  variant="outline"
                >
                  Upload Document{' '}
                </Button>
              )}
            </Box>
          </FormControl>
        </HStack>

        <EventDocumentName version={version} />

        <EventDocumentDescription version={version} />

        {/* <FormControl isInvalid={errors.label} isRequired={true}>
          <FormLabel htmlFor="label" size="sm" m={0}></FormLabel>
          <FormErrorMessage>{errors.label && errors.label.message}</FormErrorMessage>
          <Input
            {...register('label', {
              required: 'Document Name is required'
            })}
            isRequired={true}
            id="label"
            size={inputStyles.inputSize}
            variant={inputStyles.variant}
            borderColor={inputStyles.borderColor}
          />
        </FormControl>
        <FormControl isInvalid={errors.description} isRequired>
          <FormLabel htmlFor="description" size="sm" m={0}>
            Document Description
          </FormLabel>
          <FormErrorMessage>{errors.description && errors.description.message}</FormErrorMessage>
          <Input
            {...register('description', { required: 'Description  is required' })}
            isRequired={true}
            id="description"
            size={inputStyles.inputSize}
            variant={inputStyles.variant}
            borderColor={inputStyles.borderColor}
          />
        </FormControl> */}
      </VStack>
    </Stack>
  );
};
