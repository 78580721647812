import {
  GET_DOCUMENTS_REQUEST,
  GET_DOCUMENTS_FAILURE,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENT_REQUEST,
  GET_DOCUMENT_FAILURE,
  GET_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_REQUEST,
  ADD_DOCUMENT_FAILURE,
  ADD_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_REQUEST,
  UPDATE_DOCUMENT_FAILURE,
  UPDATE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_FAILURE,
  DELETE_DOCUMENT_SUCCESS,
  COMMENT_DOCUMENT_REQUEST,
  COMMENT_DOCUMENT_FAILURE,
  COMMENT_DOCUMENT_SUCCESS,
  DOCUMENT_UPLOAD_REQUEST,
  DOCUMENT_UPLOAD_SUCCESS,
  DOCUMENT_UPLOAD_FAILURE,
  DOCUMENTS_HIDE_NOTIFICATIONS,
  OPEN_DOCUMENT_UPLOAD,
  CLOSE_DOCUMENT_UPLOAD,
  AUTOFILL_DOCUMENT_REQUEST,
  OPEN_UPLOAD_DOCUMENT_VERSION_DRAWER,
  CLOSE_UPLOAD_DOCUMENT_VERSION_DRAWER,
  SELECT_DOCUMENT,
  CLEAR_DOCUMENT,
  DOWNLOAD_DOCUMENT
} from './types';

const initialState = {
  isFetching: false,
  documents: [],
  document: null,
  error: null,
  uploadedUrl: null,
  fetchAction: null,
  documentUploadingStatus: false,
  docAlertError: false,
  documentUploadOpen: false,
  fillingDocument: false,
  isUploadDocumentVersionDrawerOpen: false,
  documentSelected: null
};

export const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCUMENTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        fetchAction: null
      };
    case GET_DOCUMENTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        fetchAction: null
      };
    case GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        documents: action.documents,
        fetchAction: null
      };
    case GET_DOCUMENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        document: null,
        fetchAction: null
      };
    case GET_DOCUMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        fetchAction: null
      };
    case GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        document: action.document,
        fetchAction: null
      };
    case ADD_DOCUMENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        fetchAction: null
      };
    case ADD_DOCUMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        uploadedUrl: null,
        fetchAction: null
      };
    case ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        uploadedUrl: null,
        fetchAction: null
      };
    case UPDATE_DOCUMENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        fetchAction: null
      };
    case UPDATE_DOCUMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        fetchAction: null
      };
    case UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        fetchAction: null,
        isUploadDocumentVersionDrawerOpen: false, // Close Dawer. TODO: check if this works here
        documentSelected: null
      };
    case DELETE_DOCUMENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        fetchAction: null
      };
    case DELETE_DOCUMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        fetchAction: null
      };
    case DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        fetchAction: null
      };
    case COMMENT_DOCUMENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        fetchAction: null
      };
    case COMMENT_DOCUMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        fetchAction: null
      };
    case COMMENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        fetchAction: null
      };
    case DOCUMENT_UPLOAD_REQUEST:
      return {
        ...state,
        documentUploadingStatus: true
      };
    case DOCUMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        documentUploadingStatus: false,
        documentUploadOpen: false,
        showDocAlert: true,
        docAlertMessage: action.successMessage
      };

    case DOCUMENT_UPLOAD_FAILURE:
      return {
        ...state,
        documentUploadingStatus: false,
        documentUploadOpen: false,
        showDocAlert: true,
        docAlertError: true
      };

    case DOCUMENTS_HIDE_NOTIFICATIONS:
      return {
        ...state,
        showDocAlert: false,
        docAlertError: false,
        docAlertMessage: ''
      };

    case OPEN_DOCUMENT_UPLOAD:
      return {
        ...state,
        documentUploadOpen: true
      };
    case CLOSE_DOCUMENT_UPLOAD:
      return {
        ...state,
        documentUploadOpen: false
      };
    case AUTOFILL_DOCUMENT_REQUEST:
      return {
        ...state,
        fillingDocument: true
      };
    case OPEN_UPLOAD_DOCUMENT_VERSION_DRAWER:
      return {
        ...state,
        isUploadDocumentVersionDrawerOpen: true
      };
    case CLOSE_UPLOAD_DOCUMENT_VERSION_DRAWER:
      return {
        ...state,
        isUploadDocumentVersionDrawerOpen: false
      };
    case SELECT_DOCUMENT:
      return {
        ...state,
        documentSelected: action.document
      };
    case CLEAR_DOCUMENT:
      return {
        ...state,
        documentSelected: null
      };

    case DOWNLOAD_DOCUMENT.REQUEST:
      return {
        ...state,
        downloadingDocument: true
      };

    case DOWNLOAD_DOCUMENT.SUCCESS:
      return {
        ...state,
        downloadingDocument: false
      };
    case DOWNLOAD_DOCUMENT.FAILURE:
      return {
        ...state,
        downloadingDocument: false
      };

    default:
      return state;
  }
};
