import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { css } from '@emotion/react';
import { Button } from '../Primitives';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export type EmptyStateProps = {
  isLoading?: boolean;
  title: string;
  subTitle?: string;
  showButton?: boolean;
  btnAction?: () => void;
  btnLabel?: string;
};

export const EmptyState: FC<EmptyStateProps> = ({
  isLoading = false,
  title,
  subTitle,
  showButton = false,
  btnAction,
  btnLabel
}) => {
  return (
    <Flex justifyContent="center" alignItems="center" width="full" height="full">
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent={(btnLabel && 'space-between') || 'space-evenly'}
        width="100%"
        height="160px"
      >
        {isLoading ? (
          <PropagateLoader
            css={override}
            // height={4}
            // width={10}
            color={'var(--colors-primary)'}
            loading={true}
            speedMultiplier={1.5}
          />
        ) : (
          <>
            <Text
              fontFamily="Lato"
              fontSize="36px"
              fontWeight={500}
              lineHeight="30px"
              color="#888"
              letterSpacing="-0.45px"
            >
              {title}
            </Text>
            <Text
              fontFamily="Lato"
              fontSize="24px"
              fontWeight={500}
              lineHeight="30px"
              color="#888"
              letterSpacing="-0.45px"
            >
              {subTitle}
            </Text>
            {showButton && <Button onClick={btnAction}>{btnLabel}</Button>}
          </>
        )}
      </Flex>
    </Flex>
  );
};
