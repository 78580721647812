import { FormInputsWrapper } from 'src/v2/UIComponents/FormComponents';
import { ACTIVITY_INPUTS } from '../Inputs';

export const ActivityForm = () => {
  return (
    <FormInputsWrapper>
      <ACTIVITY_INPUTS.ActivityType />
      <ACTIVITY_INPUTS.ActivityName />
      <ACTIVITY_INPUTS.ActivityDescription />
    </FormInputsWrapper>
  );
};
