import React from 'react';
import StyledButton from './styles';

function Button(props) {
  const { disabled, label, onClick } = props;

  return (
    <StyledButton {...props} onClick={disabled ? null : onClick}>
      {label || 'Confirm'}
    </StyledButton>
  );
}

export default Button;
