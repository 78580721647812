import { useSelector, RootStateOrAny } from 'react-redux';
import { useActions } from 'src/hooks/useActionsNew';
import {
  openCreateEventModal,
  closeCreateEventModal
} from 'src/containers/AddEventModal/operations';
import { closeHostFormDrawer, openHostFormDrawer } from 'src/v2/Reducers/HostForm/operations';
import {
  closeDocLibraryUpload,
  openDocLibraryUpload
} from 'src/containers/DocumentLibrary/operations';
import * as drawerOperations from 'src/v2/Drawers/Redux/operations';
import { RootStates } from 'src/Types';

const {
  activityDrawerOperations,
  eventProductDrawerOperations,
  productCategoriesDrawerOperations,
  productDrawerOperations,
  rateTypeDrawerOperations,
  taxRateDrawerOperations,
  calendarWizardOperations,
  appointmentDrawerOperations,
  reportFieldsDrawerOperations,
  emailThreadsDrawerOperations,
  emailComposerModalOperations,
  eventManagementDrawerOperations,
  eventDocumentDrawerOperations,
  bookLeadOperations,
  eventTaskDrawerOperations,
  quickEditLeadDrawerOperations,
  changeEventManagementVersion,
  documentDetailDrawerOperations
} = drawerOperations;

export const useDrawers = () => {
  const drawersState = useSelector((state: RootStates) => state.drawersReducer);

  const { hostFormDrawerOpen, activeGroupName, activeGroupFields } = useSelector(
    (state: RootStates) => state.hostFormReducer
  );
  const { overlayLoaderOpen } = useSelector((state: RootStateOrAny) => state.overlayLoaderReducer);
  const { docLibraryUploadOpen } = useSelector((state: RootStates) => state.documentLibraryReducer);
  const eventFormState = useSelector((state: RootStates) => state.addEventModalReducer);
  const { isOpen: eventFormIsOpen, startDate } = eventFormState;
  const actions = useActions({
    openProductDrawer: productDrawerOperations.open,
    closeProductDrawer: productDrawerOperations.close,
    openProductCategoryDrawer: productCategoriesDrawerOperations.open,
    closeProductCategoryDrawer: productCategoriesDrawerOperations.close,
    openRateTypeDrawer: rateTypeDrawerOperations.open,
    closeRateTypeDrawer: rateTypeDrawerOperations.close,
    openTaxRateDrawer: taxRateDrawerOperations.open,
    closeTaxRateDrawer: taxRateDrawerOperations.close,
    openEventProductDrawer: eventProductDrawerOperations.open,
    closeEventProductDrawer: eventProductDrawerOperations.close,
    openActivityDrawer: activityDrawerOperations.open,
    closeActivityDrawer: activityDrawerOperations.close,
    openCalendarWizard: calendarWizardOperations.open,
    closeCalendarWizard: calendarWizardOperations.close,
    openAppointmentDrawer: appointmentDrawerOperations.open,
    closeAppointmentDrawer: appointmentDrawerOperations.close,
    openReportFieldsDrawer: reportFieldsDrawerOperations.open,
    closeReportFieldsDrawer: reportFieldsDrawerOperations.close,
    openCreateEventModal,
    closeCreateEventModal,
    openHostFormDrawer,
    closeHostFormDrawer,
    closeDocLibraryUpload,
    openDocLibraryUpload,
    openEmailThreadsDrawer: emailThreadsDrawerOperations.open,
    closeEmailThreadsDrawer: emailThreadsDrawerOperations.close,
    openEmailComposer: emailComposerModalOperations.open,
    closeEmailComposer: emailComposerModalOperations.close,
    openEventManagementDrawer: eventManagementDrawerOperations.open,
    closeEventManagementDrawer: eventManagementDrawerOperations.close,
    openEventDocumentDrawer: eventDocumentDrawerOperations.open,
    closeEventDocumentDrawer: eventDocumentDrawerOperations.close,
    changeEventManagementVersion,
    openDocumentDetailsDrawer: documentDetailDrawerOperations.open,
    closeDocumentDetailsDrawer: documentDetailDrawerOperations.close,
    bookLeadRequest: bookLeadOperations.request,
    bookLeadFinish: bookLeadOperations.finish,
    openEventTaskDrawer: eventTaskDrawerOperations.open,
    closeEventTaskDrawer: eventTaskDrawerOperations.close,
    openQuickEditLeadDrawer: quickEditLeadDrawerOperations.open,
    closeQuickEditLeadDrawer: quickEditLeadDrawerOperations.close,
    setLeadToQuickEdit: quickEditLeadDrawerOperations.setLeadToQuickEdit
  });

  const stateValues = {
    activeGroupFields,
    activeGroupName,
    docLibraryUploadOpen,
    eventFormIsOpen,
    hostFormDrawerOpen,
    overlayLoaderOpen,
    startDate
  };

  return {
    ...drawersState,
    ...stateValues,
    ...actions
  };
};
