import { Button } from 'src/v2/UIComponents/Primitives';
import { Box, Text, Flex } from '@chakra-ui/react';
import { AddPlus } from 'src/v2/UIComponents/Primitives/Icons';
import { useEvents, useLeads } from 'src/v2/Hooks/CollectionHooks';
import { EmptyState } from 'src/v2/UIComponents/EmptyState';
import {
  scrollBarProps,
  scrollParentProps,
  scrollSiblingProps
} from 'src/v2/UIComponents/ScrollBar/scrollBarProps';
import moment from 'moment';
import { EventTabBox } from 'src/v2/UIComponents/Primitives/Cards/EventTabBox';

import { useConfirm } from 'src/v2/Hooks/HelperHooks';
import { useState } from 'react';
import { NotesDrawer } from '../../Leads/NotesDrawer';

export const Notes = () => {
  const { openLeadNotesForm } = useLeads();
  const { updateEvent, activeEvent: lead } = useEvents();
  const handleEditNote = note => openLeadNotesForm({ editNote: true, note });
  const [noteToDelete, setNoteToDelete] = useState('');

  const leadNotes = lead?.advancedNotes?.filter(nte => !nte.archive);

  const handleConfirmDelete = () => {
    if (noteToDelete) {
      updateEvent({
        data: {
          advancedNotes: {
            id: noteToDelete,
            archive: true
          }
        },
        eventId: lead?.id,
        isLead: true
      });
    }
    setNoteToDelete('');
  };

  const { openConfirm } = useConfirm({
    acceptAction: handleConfirmDelete,
    confirmHookId: 'deleteNote'
  });

  const requestDelete = noteId => {
    setNoteToDelete(noteId);
    openConfirm({
      title: 'Delete Note',
      description: 'Are you sure you want to delete this note?',
      confirmId: 'deleteNote',
      confirmType: 'cancel'
    });
  };

  return (
    <>
      <Box display="flex" height="100%" flexDirection={'column'} gap="18px" sx={scrollParentProps}>
        <Flex justifyContent="flex-end" sx={scrollSiblingProps}>
          <Button
            variant="primary"
            leftIcon={<AddPlus color="white" w={4} h={4} />}
            width="140px"
            onClick={() => openLeadNotesForm({ editNote: false, note: null })}
          >
            Add Note
          </Button>
        </Flex>
        <Flex
          flexDirection={'column'}
          minH="1px"
          gap={'24px'}
          overflowY="scroll"
          height="100%"
          sx={scrollBarProps}
        >
          {leadNotes?.map((note, key) => {
            const { createdByData } = note;
            // TODO: #552 Lead Details Notes Tab: Review why notes are not showing.
            const title = `${createdByData?.firstname} ${createdByData?.lastname} -  ${moment(
              note.created
            ).format('LLL')}`;

            return (
              <EventTabBox
                key={key}
                title={title}
                buttonAction={() => handleEditNote(note)}
                secondaryAction={() => requestDelete(note?.id)}
                icons
                subtitle={undefined}
              >
                <Box padding="22px">
                  <Text variant="contentTitle" fontSize="15px">
                    Note
                  </Text>
                  <Text variant="contentSubTitle">{note?.note}</Text>
                </Box>
              </EventTabBox>
            );
          }) || (
            <EmptyState
              title="No Notes Added Yet"
              subTitle="Add a note to track important details"
            />
          )}
        </Flex>
      </Box>
      <NotesDrawer />
    </>
  );
};
