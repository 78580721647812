import React from 'react';
import { Tag as ChakraTag, TagLabel } from '@chakra-ui/react';

export const Tag = ({ title, bg = '#03B575', wide = false }) => (
  <ChakraTag
    borderRadius="full"
    variant="solid"
    bg={bg}
    width={(wide && '150px') || '107px'}
    height="28px"
    justifyContent="center"
  >
    <TagLabel
      fontWeight={700}
      fontSize="12px"
      lineHeight="16px"
      textAlign="center"
      letterSpacing="0.1px"
      color="white"
      textTransform="capitalize"
    >
      {title}
    </TagLabel>
  </ChakraTag>
);
