import {
  GET_GUESTS_REQUEST,
  ADD_GUEST_REQUEST,
  RESET_ADDDED_GUEST,
  EDIT_GUEST_REQUEST,
  DELETE_GUEST_REQUEST,
  GENERATE_GUEST_FILE_REQUEST
} from './types';

export const doGetGuests = ({ token, eventId, orderBy, order, rsvp, table, venueDownload }) => ({
  type: GET_GUESTS_REQUEST,
  token,
  eventId,
  orderBy,
  order,
  rsvp,
  table,
  venueDownload
});

export const doAddGuest = ({ token, data }) => ({
  type: ADD_GUEST_REQUEST,
  token,
  data
});

export const resetAddedGuest = () => ({
  type: RESET_ADDDED_GUEST
});

export const doEditGuest = ({ token, guestId, data }) => ({
  type: EDIT_GUEST_REQUEST,
  token,
  guestId,
  data
});

export const doDeleteGuest = ({ token, guestId }) => ({
  type: DELETE_GUEST_REQUEST,
  token,
  guestId
});

export const doGenerateExcelFile = ({ token, eventId, venueId, layoutId }) => ({
  type: GENERATE_GUEST_FILE_REQUEST,
  token,
  eventId,
  venueId,
  layoutId
});
