import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export const resetPasswordAction = ({ token, data }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/auth/reset`,
    headers: { Authorization: token },
    data
  });
};
