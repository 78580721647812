import _isObject from 'lodash/isObject';
import { getFilteredData } from 'src/v2/context/Tables/getFilteredData';

export const getMatches = ({ filterObject, allData, dataKey, filters, filterType, tableData }) => {
  const searchFilter = filters?.filter(fltr => fltr.filterType === 'search')?.length;
  let { value: filterBy } = filterObject;
  if (!filterBy) {
    filterBy = filterObject;
  }
  const { filteredData } = getFilteredData({
    filterBy: [filterBy],
    filterKey: dataKey,
    filters,
    allData: (searchFilter && tableData) || allData,
    filterType,
    exclude: undefined, // add missing property
    filterId: undefined // add missing property
  });

  return filteredData?.length || 0;
};

export const getOptionListWithCount = ({
  filterList,
  tableData,
  dataKey,
  allData,
  filters,
  filterType
}) => {
  const unHandledList =
    filterList?.map(filterObject => {
      const isValObject = _isObject(filterObject);
      let newFilterObject = filterObject;
      if (!isValObject) {
        newFilterObject = {
          label: filterObject,
          value: filterObject
        };
      }
      const totalMatches = getMatches({
        filterObject: newFilterObject,
        allData,
        dataKey,
        filters,
        filterType,
        tableData
      });

      return {
        ...newFilterObject,
        totalMatches
      };
    }) || [];
  interface ListObject {
    totalMatches: number;
    value: string;
    label: string;
  }

  const filterListArray: ListObject[] = [];
  const filterListWithMatches = unHandledList?.filter(fltr => fltr.totalMatches > 0) || [];
  filterListArray.push(...filterListWithMatches);
  const filterListWithoutMatch =
    unHandledList.filter(fltr => fltr.totalMatches < 1 || !fltr.totalMatches) || [];
  filterListArray.push(...filterListWithoutMatch);
  return filterListArray;
};
