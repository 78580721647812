import { Drawer } from '@chakra-ui/react';
import styled from 'styled-components';
import Colors from 'src/styles/Colors';

export const FormDrawerContainer = styled(Drawer)``;

export const FormDrawerStyles = {
  size: 'full',
  variant: 'darkOverlay',
  zIndex: 2,
  closeOnEsc: true,
  motionPreset: 'slideInRight',
  className: 'medium-drawer'
};

export const FormDrawerHeaderStyles = {
  backgroundColor: 'var(--colors-modalHeader)',
  color: Colors.gray18,
  padding: '18px 39px',
  marginBottom: '25px'
};

export const FormDrawerFooterStyles = {
  py: '16px',
  justifyContent: 'flex-end',
  border: `1px solid ${Colors.grayED}`,
  px: '46px'
};

export const TitleStyles = {
  fontFamily: 'Lora',
  fontSize: '21.75px',
  color: Colors.gray18,
  fontWeight: 'normal'
};
