// action types
import {
  GET_HOST_TUTORIALS_DETAILS_FAILURE,
  GET_HOST_TUTORIALS_DETAILS_REQUEST,
  GET_HOST_TUTORIALS_DETAILS_SUCCESS,
  UPDATE_HOST_TUTORIALS_FAILURE,
  UPDATE_HOST_TUTORIALS_REQUEST,
  UPDATE_HOST_TUTORIALS_SUCCESS
} from './types';

// reducer with initial state
const initialState = {
  fetchingTutorials: false,
  fetchingTutorialsError: '',
  updatingHostTutorials: false,
  tutorialsStatus: {
    dashboard: true,
    checkList: true,
    guestList: true,
    seatingChart: true,
    documents: true,
    budgetPlanner: true,
    vendorList: true,
    memories: true,
    vendors: true
  }
};

export const tutorialsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOST_TUTORIALS_DETAILS_REQUEST:
      return {
        ...state,
        fetchingTutorials: true,
        error: null
      };
    case GET_HOST_TUTORIALS_DETAILS_SUCCESS:
      return {
        ...state,
        fetchingTutorials: false,
        error: null,
        tutorialsStatus: {
          dashboard: action.tutorialsStatus.dashboard,
          checkList: action.tutorialsStatus.checkList,
          guestList: action.tutorialsStatus.guestList,
          seatingChart: action.tutorialsStatus.seatingChart,
          documents: action.tutorialsStatus.documents,
          budgetPlanner: action.tutorialsStatus.budgetPlanner,
          vendorList: action.tutorialsStatus.vendorList,
          memories: action.tutorialsStatus.memories,
          vendors: action.tutorialsStatus.vendors
        }
      };
    case GET_HOST_TUTORIALS_DETAILS_FAILURE:
      return {
        ...state,
        fetchingTutorials: false,
        error: action.error
      };
    case UPDATE_HOST_TUTORIALS_REQUEST:
      return {
        ...state,
        updatingHostTutorials: true,
        error: null
      };
    case UPDATE_HOST_TUTORIALS_SUCCESS:
      return {
        ...state,
        updatingHostTutorials: false,
        error: null,
        tutorialStatus: action.payload
      };
    case UPDATE_HOST_TUTORIALS_FAILURE:
      return {
        ...state,
        updatingHostTutorials: false,
        error: action.error
      };
    default:
      return state;
  }
};
