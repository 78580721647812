import { TextFieldWithLabel } from 'src/v2/UIComponents/FormComponents';
import { EVENT_DOCUMENT_CONSTANTS } from '../constants';

export const EventDocumentDescription = ({ version }) => {
  const { EVENT_DOCUMENT_DESCRIPTION } = EVENT_DOCUMENT_CONSTANTS;

  const documentDescriptionProps = {
    name: 'description',
    isRequired: true,
    label: (version && 'Version Description Required') || EVENT_DOCUMENT_DESCRIPTION.LABEL,
    placeholder: EVENT_DOCUMENT_DESCRIPTION.PLACEHOLDER,
    rules: {
      required: EVENT_DOCUMENT_DESCRIPTION.REQUIRED
    }
  };
  return <TextFieldWithLabel {...documentDescriptionProps} />;
};
