import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export const getLeadsAction = ({ token, getEvents }) => {
  const url = `${apiUri}/events/leads`;

  return axios({
    method: 'get',
    url,
    headers: { Authorization: token },
    params: { getEvents }
  });
};

export const getLeadAction = ({ id, token }) =>
  axios({
    method: 'get',
    url: `${apiUri}/events/leads/${id}`,
    headers: { Authorization: token }
  });

export const addLeadAction = ({ lead, token }) =>
  axios({
    method: 'post',
    url: `${apiUri}/events/leads/create`,
    headers: { Authorization: token },
    data: { ...lead }
  });

export const addNoteAction = ({ note, token, id }) =>
  axios({
    method: 'put',
    url: `${apiUri}/events/${id}`,
    headers: { Authorization: token },
    data: {
      advancedNotes: {
        note: note.note
      }
    }
  });
