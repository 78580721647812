import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const generateReportXLS = ({ columns, reportName, setCreatingFile }) => {
  const wb = XLSX.utils.book_new();
  wb.props = {
    Title: `${reportName}`,
    CreatedDate: new Date()
  };
  wb.SheetNames.push(reportName);

  // set up variables to keep track of meal counts

  let data = columns;

  data = data.filter(a => {
    return !a.remove;
  });

  const ws = XLSX.utils.json_to_sheet(data);
  wb.Sheets[reportName] = ws;

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

  const s2ab = s => {
    const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
    const view = new Uint8Array(buf); // create uint8array as viewer
    // eslint-disable-next-line no-bitwise
    for (let i = 0; i < s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff; // convert to octet
    return buf;
  };
  saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${reportName}.xlsx`);
};
