export const GET_GUESTS_REQUEST = 'GET_GUESTS_REQUEST';
export const GET_GUESTS_SUCCESS = 'GET_GUESTS_SUCCESS';
export const GET_GUESTS_FAILURE = 'GET_GUESTS_FAILURE';

export const ADD_GUEST_REQUEST = 'ADD_GUEST_REQUEST';
export const ADD_GUEST_SUCCESS = 'ADD_GUEST_SUCCESS';
export const ADD_GUEST_FAILURE = 'ADD_GUEST_FAILURE';

export const RESET_ADDDED_GUEST = 'RESET_ADDDED_GUEST';

export const EDIT_GUEST_REQUEST = 'EDIT_GUEST_REQUEST';
export const EDIT_GUEST_SUCCESS = 'EDIT_GUEST_SUCCESS';
export const EDIT_GUEST_FAILURE = 'EDIT_GUEST_FAILURE';

export const DELETE_GUEST_REQUEST = 'DELETE_GUEST_REQUEST';
export const DELETE_GUEST_SUCCESS = 'DELETE_GUEST_SUCCESS';
export const DELETE_GUEST_FAILURE = 'DELETE_GUEST_FAILURE';

export const GENERATE_GUEST_FILE_REQUEST = 'GENERATE_GUEST_FILE_REQUEST';
export const GENERATE_GUEST_FILE_FAILURE = 'GENERATE_GUEST_FILE_FAILURE';
