import { COMMENT_DOCUMENT_SUCCESS } from 'src/containers/DocumentsContainer/types';
import { DrawersReducerTypes } from 'src/Types';
import {
  PRODUCT_DRAWER,
  PRODUCT_CATEGORY_DRAWER,
  RATE_TYPE_DRAWER,
  TAX_RATE_DRAWER,
  EVENT_PRODUCTS_DRAWER,
  ACTIVITY_DRAWER,
  CALENDAR_WIZARD_DRAWER,
  APPOINTMENT_DRAWER,
  REPORT_FIELDS_DRAWER,
  EMAIL_THREADS_DRAWER,
  EMAIL_COMPOSER_MODAL,
  EVENT_MANAGEMENT_DRAWER,
  EVENT_DOCUMENT_DRAWER,
  CHANGE_EVENT_FORM_VERSION,
  DOCUMENT_DETAILS_DRAWER,
  BOOK_LEAD_OPERATIONS,
  EVENT_TASK_DRAWER,
  QUICK_EDIT_LEAD_DETAILS_DRAWER
} from './types';

const initialState: DrawersReducerTypes = {
  productDrawerOpen: false,
  productCategoryDrawerOpen: false,
  rateTypeDrawerOpen: false,
  taxRateDrawerOpen: false,
  eventProductsDrawerOpen: false,
  activityDrawerOpen: false,
  calendarWizardOpen: false,
  appointmentDrawerOpen: false,
  appointmentDefaultValues: {},
  startTime: undefined,
  reportFieldsDrawerOpen: false,
  emailThreadsDrawerOpen: false,
  emailComposerModalOpen: false,
  eventManagementDrawerOpen: false,
  eventManagementVersion: 'lead',
  eventManagementMode: 'create',
  eventManagementTabIndex: 0,
  eventDocumentDrawerOpen: false,
  documentDetailsDrawerOpen: false,
  documentToView: undefined,
  documentComments: [],
  bookingLead: false,
  eventTaskDrawerOpen: false,
  editEventTask: false,
  eventTask: undefined,
  quickEditLeadDrawerOpen: false
};

export const drawersReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUICK_EDIT_LEAD_DETAILS_DRAWER.OPEN:
      return {
        ...state,
        quickEditLeadDrawerOpen: true,
        leadToQuickEdit: action.lead
      };

    case QUICK_EDIT_LEAD_DETAILS_DRAWER.CLOSE:
      return {
        ...state,
        quickEditLeadDrawerOpen: false,
        leadToQuickEdit: undefined
      };

    case QUICK_EDIT_LEAD_DETAILS_DRAWER.SET_LEAD_TO_EDIT:
      return {
        ...state,
        leadToQuickEdit: action.lead
      };

    case PRODUCT_DRAWER.OPEN:
      return {
        ...state,
        productDrawerOpen: true
      };

    case PRODUCT_DRAWER.CLOSE:
      return {
        ...state,
        productDrawerOpen: false
      };

    case PRODUCT_CATEGORY_DRAWER.OPEN:
      return {
        ...state,
        productCategoryDrawerOpen: true
      };

    case PRODUCT_CATEGORY_DRAWER.CLOSE:
      return {
        ...state,
        productCategoryDrawerOpen: false
      };
    case RATE_TYPE_DRAWER.OPEN:
      return {
        ...state,
        rateTypeDrawerOpen: true
      };

    case RATE_TYPE_DRAWER.CLOSE:
      return {
        ...state,
        rateTypeDrawerOpen: false
      };

    case TAX_RATE_DRAWER.OPEN:
      return {
        ...state,
        taxRateDrawerOpen: true
      };

    case TAX_RATE_DRAWER.CLOSE:
      return {
        ...state,
        taxRateDrawerOpen: false
      };

    case EVENT_PRODUCTS_DRAWER.OPEN:
      return {
        ...state,
        eventProductsDrawerOpen: true
      };
    case EVENT_PRODUCTS_DRAWER.CLOSE:
      return {
        ...state,
        eventProductsDrawerOpen: false
      };
    case ACTIVITY_DRAWER.OPEN:
      return {
        ...state,
        activityDrawerOpen: true
      };
    case ACTIVITY_DRAWER.CLOSE:
      return {
        ...state,
        activityDrawerOpen: false
      };
    case CALENDAR_WIZARD_DRAWER.OPEN:
      return {
        ...state,
        calendarWizardOpen: true,
        startTime: action.startTime,
        eventManagementVersion: 'event',
        eventManagementMode: 'create'
      };
    case CALENDAR_WIZARD_DRAWER.CLOSE:
      return {
        ...state,
        calendarWizardOpen: false,
        startTime: null,
        eventManagementVersion: 'lead',
        eventManagementMode: 'create'
      };
    case APPOINTMENT_DRAWER.OPEN:
      return {
        ...state,
        appointmentDrawerOpen: true,
        appointmentDefaultValues: action.defaultValues
      };
    case APPOINTMENT_DRAWER.CLOSE:
      return {
        ...state,
        appointmentDrawerOpen: false
      };
    case REPORT_FIELDS_DRAWER.OPEN:
      return {
        ...state,
        reportFieldsDrawerOpen: true
      };
    case REPORT_FIELDS_DRAWER.CLOSE:
      return {
        ...state,
        reportFieldsDrawerOpen: false
      };

    case EMAIL_THREADS_DRAWER.OPEN:
      return {
        ...state,
        emailThreadsDrawerOpen: true
      };
    case EMAIL_THREADS_DRAWER.CLOSE:
      return {
        ...state,
        emailThreadsDrawerOpen: false
      };
    case EMAIL_COMPOSER_MODAL.OPEN:
      return {
        ...state,
        emailComposerModalOpen: true
      };
    case EMAIL_COMPOSER_MODAL.CLOSE:
      return {
        ...state,
        emailComposerModalOpen: false
      };
    case EVENT_DOCUMENT_DRAWER.OPEN:
      return {
        ...state,
        eventDocumentDrawerOpen: true
      };
    case EVENT_DOCUMENT_DRAWER.CLOSE:
      return {
        ...state,
        eventDocumentDrawerOpen: false
      };
    case EVENT_MANAGEMENT_DRAWER.OPEN: {
      return {
        ...state,
        eventManagementDrawerOpen: true,
        eventManagementVersion: action.version,
        eventManagementMode: action.mode,
        eventManagementTabIndex: action.tabIndex
      };
    }
    case EVENT_MANAGEMENT_DRAWER.CLOSE: {
      return {
        ...state,
        eventManagementDrawerOpen: false,
        eventManagementVersion: 'lead',
        eventManagementMode: 'create',
        eventManagementTabIndex: 0
      };
    }
    case CHANGE_EVENT_FORM_VERSION: {
      return {
        ...state,
        eventManagementVersion: action.version
      };
    }

    case DOCUMENT_DETAILS_DRAWER.OPEN: {
      return {
        ...state,
        documentDetailsDrawerOpen: true,
        documentToView: action.document,
        documentComments: action.document.comments?.reverse() || []
      };
    }

    case DOCUMENT_DETAILS_DRAWER.CLOSE: {
      return {
        ...state,
        documentDetailsDrawerOpen: false,
        documentToView: {},
        documentComments: []
      };
    }

    case COMMENT_DOCUMENT_SUCCESS: {
      return {
        ...state,
        documentComments: action.updatedDocument.comments?.reverse() || []
      };
    }

    case BOOK_LEAD_OPERATIONS.REQUEST: {
      return {
        ...state,
        bookingLead: true
      };
    }

    case BOOK_LEAD_OPERATIONS.FINISH: {
      return {
        ...state,
        bookingLead: false
      };
    }
    case EVENT_TASK_DRAWER.OPEN:
      return {
        ...state,
        eventTask: action.eventTask,
        editEventTask: action.editEventTask,
        eventTaskDrawerOpen: true
      };
    case EVENT_TASK_DRAWER.CLOSE:
      return {
        ...state,
        eventTask: {},
        editEventTask: false,
        eventTaskDrawerOpen: false
      };

    default:
      return state;
  }
};
