import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { checkEventCode } from 'src/containers/AddEventModal/actions';
import { useUser, useEvents } from 'src/v2/Hooks/CollectionHooks';
import {
  EVENT_CODE_PLACEHOLDER,
  EVENT_CODE_DISABLED_PLACEHOLDER,
  EVENT_CODE_LABEL,
  EVENT_CODE_REQUIRED,
  EVENT_CODE_MIN_LENGTH,
  EVENT_CODE_MAX_LENGTH
} from 'src/v2/Drawers/EventFormDrawer/constants';
import { TextFieldWithLabel } from 'src/v2/UIComponents/FormComponents';
import { debounce } from 'lodash';
import { TextFieldProps } from 'src/v2/UIComponents/FormComponents/TextFieldWithLabel/types';

export const EventCodeInput = ({ mode }) => {
  const {
    formState: { dirtyFields, touchedFields },
    setValue,
    watch
  } = useFormContext();

  const { eventCode: dirtyCode } = dirtyFields;
  const { eventCode: touchedCode } = touchedFields;
  const clientNameInput = watch('clientName');
  const photoSharing = watch('photoSharing');
  const date = watch('start');
  const { token, venueId } = useUser();
  const { activeEventId: eventId } = useEvents();

  const debouncedSetValue = useRef(debounce(setValue, 250));

  useEffect(() => {
    if (
      mode === 'create' &&
      photoSharing &&
      clientNameInput &&
      clientNameInput.replace(/\s/g, '').length >= 4 &&
      date &&
      (!dirtyCode || !touchedCode)
    ) {
      const nameSnippet = clientNameInput
        .toLowerCase()
        .replace(/\s/g, '')
        .substring(0, 4)
        .toUpperCase();
      const dateSnippet = moment(date).format('MMDDYY');
      const newEventCode = nameSnippet + dateSnippet;
      debouncedSetValue.current('eventCode', newEventCode);
    }
  }, [clientNameInput, date, dirtyCode, mode, touchedCode, photoSharing]);

  const placeHolderText = () => {
    if (photoSharing) return EVENT_CODE_PLACEHOLDER;
    if (!photoSharing) return EVENT_CODE_DISABLED_PLACEHOLDER;
    return EVENT_CODE_PLACEHOLDER;
  };

  const doCheckEventCode = async ({ eventCode }) => {
    try {
      const result = await checkEventCode({ token, venueId, eventCode, eventId });
      if (result) {
        const {
          data: { message }
        } = result;

        console.log('message', message);
        return message;
      }
      return false;
    } catch (error) {
      return true;
    }
  };
  const eventCodeProps: TextFieldProps = {
    name: 'eventCode',
    isRequired: true,
    label: EVENT_CODE_LABEL,
    placeholder: placeHolderText(),
    isDisabled: !photoSharing,
    rules: {
      required: photoSharing && EVENT_CODE_REQUIRED,
      validate: {
        exists: async eventCode =>
          (await doCheckEventCode({ eventCode })) ||
          'This event code is already being used for another event'
      },
      minLength: { value: 4, message: EVENT_CODE_MIN_LENGTH },
      maxLength: { value: 15, message: EVENT_CODE_MAX_LENGTH }
    }
  };
  return <TextFieldWithLabel {...eventCodeProps} />;
};

EventCodeInput.propTypes = {
  consultants: PropTypes.object,
  users: PropTypes.array,
  errors: PropTypes.object,
  register: PropTypes.func,
  photoSharing: PropTypes.bool,
  doCheckEventCode: PropTypes.func,
  token: PropTypes.string,
  venueId: PropTypes.string,
  inputStyles: PropTypes.object,
  eventId: PropTypes.string,
  mode: PropTypes.string
};
