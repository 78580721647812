import { useEffect, useState } from 'react';
import { useDocuments, useUser, useDocumentLibrary } from 'src/v2/Hooks/CollectionHooks';
import { useDrawers } from 'src/v2/Hooks/HelperHooks';
import { Wizard } from 'src/v2/UIComponents/Drawers/Wizard';
import { AddLibraryDocument } from './AddLibraryDocument';
import { StepOne } from './StepOne';
import { UploadDocument } from './UploadDocument';
import { useEvents } from '../../Hooks/CollectionHooks/Events/useEvents';

export const EventDocumentDrawer = () => {
  const { role } = useUser();
  const { eventDocumentDrawerOpen, closeEventDocumentDrawer } = useDrawers();
  const { getLibraryDocuments } = useDocumentLibrary();
  const [documentUploadType, setDocumentUploadType] = useState('upload');
  const { userId } = useUser();
  const { activeEventId: eventId } = useEvents();
  const { documentUploadRequest } = useDocuments();

  const handlesave = data => {
    let uploadFormData = data;
    if (data.libraryDocument) {
      uploadFormData = data.libraryDocument;
      uploadFormData.type = 'libraryDocument';
      if (data.label) uploadFormData.label = data.label;
    }
    documentUploadRequest({ formData: uploadFormData, eventId });
  };

  useEffect(() => {
    role !== 'host' && getLibraryDocuments();
  }, []);

  const closeDrawer = () => {
    setDocumentUploadType('');
    closeEventDocumentDrawer();
  };

  const PageOne = () => (
    <StepOne
      setDocumentUploadType={setDocumentUploadType}
      documentUploadType={documentUploadType}
    />
  );
  const page2Title = () => {
    switch (documentUploadType) {
      case 'library':
        return 'Document Library Upload';
      case 'upload':
        return 'Upload a document from your computer';
      default:
        return 'Add Document';
    }
  };

  const Page2 = () => {
    switch (documentUploadType) {
      case 'library':
        return <AddLibraryDocument />;
      case 'upload':
        return <UploadDocument />;
      default:
        return <div>default page</div>;
    }
  };

  const staffSteps = [
    { component: PageOne, step: 0, title: 'Add Document To Event' },
    { component: Page2, step: 1, title: page2Title() }
  ];

  const hostSteps = [{ component: UploadDocument, step: 0, title: 'Add Document To Event' }];

  const steps = role === 'host' ? hostSteps : staffSteps;

  const defaultValues = {
    label: '',
    description: '',
    date: new Date().toISOString(),
    userId,
    deleted: false,
    version: [],
    comments: [],
    reactions: [],
    tags: []
  };

  return (
    <Wizard
      disableNext={!documentUploadType}
      defaultValues={defaultValues}
      steps={steps}
      saveAction={handlesave}
      cancelAction={closeDrawer}
      isOpen={eventDocumentDrawerOpen}
      formId="eventDocumentDrawer"
    />
  );
};
