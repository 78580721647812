import { takeLatest, put, call, all } from 'redux-saga/effects';
import {
  GET_STAFF_REQUEST,
  UPDATE_STAFF_REQUEST,
  DELETE_STAFF_REQUEST,
  GET_STAFF_EVENTS_REQUEST,
  ADD_STAFF_REQUEST
} from './types';
import {
  getStaffFailure,
  getStaffSuccess,
  updateStaffFailure,
  updateStaffSuccess,
  getStaffRequest,
  deleteStaffFailure,
  deleteStaffSuccess,
  getStaffEventsFailure,
  getStaffEventsSuccess,
  addStaffSuccess,
  addStaffFailure,
  closeAddStaffModal
} from './operations';
import { getStaff, updateStaff, deleteStaff, getStaffEvents, addStaff } from './actions';
import { closeStaffDetails } from '../StaffDetailsModal/operations';
import { closeConfirmModal } from '../ConfirmModal/operations';

function* workerGetStaff({ token, venueId }) {
  try {
    const response = yield call(getStaff, { token, venueId });
    yield put(getStaffSuccess({ staff: response.data.users }));
  } catch (error) {
    yield put(getStaffFailure({ error: error.response.data }));
  }
}

function* workerUpdateStaff({ token, staffId, viewPermissions, venueId }) {
  try {
    if (typeof staffId === 'string') {
      const response = yield call(updateStaff, { token, staffId, viewPermissions });
      yield put(updateStaffSuccess({ staff: response.data.user }));
    }
    if (Array.isArray(staffId)) {
      const response = yield all(
        staffId.map(id => call(updateStaff, { token, staffId: id, viewPermissions }))
      );
      yield put(updateStaffSuccess({ staff: response }));
    }
    yield put(closeStaffDetails());
    yield put(getStaffRequest({ token, venueId }));
  } catch (error) {
    yield put(updateStaffFailure({ error: error.response.data }));
  }
}

function* workerDeleteStaff({ token, staffId, consultantId, venueId }) {
  try {
    if (Array.isArray(staffId) && staffId.length > 0) {
      yield all(staffId.map(id => call(deleteStaff, { token, staffId: id, consultantId })));
      yield put(deleteStaffSuccess({ success: true }));
    } else {
      const response = yield call(deleteStaff, { token, staffId, consultantId });
      yield put(deleteStaffSuccess({ success: response.data.success }));
    }
    yield put(getStaffRequest({ token, venueId }));
    yield put(closeConfirmModal());
  } catch (error) {
    yield put(deleteStaffFailure({ error: error.response.data }));
  }
}

function* workerGetStaffEvents({ token, consultantId }) {
  try {
    const response = yield call(getStaffEvents, { token, consultantId });
    yield put(getStaffEventsSuccess({ events: response.data.events }));
  } catch (error) {
    yield put(getStaffEventsFailure({ error: error.response.data }));
  }
}

function* workerAddStaff({ token, staff, venueId }) {
  try {
    const response = yield call(addStaff, { token, staff, venueId });
    yield put(addStaffSuccess({ success: response.data.success }));
    yield put(closeAddStaffModal());
    yield put(getStaffRequest({ token, venueId }));
  } catch (error) {
    yield put(addStaffFailure({ error: error.response.data.error }));
  }
}

export function* watchGetStaff() {
  yield takeLatest(GET_STAFF_REQUEST, workerGetStaff);
}

export function* watchUpdateStaff() {
  yield takeLatest(UPDATE_STAFF_REQUEST, workerUpdateStaff);
}

export function* watchDeleteStaff() {
  yield takeLatest(DELETE_STAFF_REQUEST, workerDeleteStaff);
}

export function* watchGetStaffEvents() {
  yield takeLatest(GET_STAFF_EVENTS_REQUEST, workerGetStaffEvents);
}

export function* watchAddStaff() {
  yield takeLatest(ADD_STAFF_REQUEST, workerAddStaff);
}
