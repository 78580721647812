import React from 'react';
import PropTypes from 'prop-types';
import { useVenue } from 'src/v2/Hooks/CollectionHooks';
import { SelectInputWithLabel } from 'src/v2/UIComponents/FormComponents';

import { EVENT_TEAM_LABEL, EVENT_TEAM_PLACEHOLDER, EVENT_TEAM_REQUIRED } from '../constants';

export const EventTeamInput = ({ bookEvent }) => {
  const { staffUsers } = useVenue();
  const staff =
    staffUsers &&
    staffUsers
      .filter(user => user.role === 'staff')
      .map(user => {
        return { value: user.id, label: `${user.firstname} ${user.lastname}`, photo: user.photo };
      });

  const eventTeamProps = {
    isMulti: true,
    isRequired: bookEvent,
    label: EVENT_TEAM_LABEL,
    name: 'staff',
    options: staff,
    placeholder: EVENT_TEAM_PLACEHOLDER,
    rules: { required: bookEvent && EVENT_TEAM_REQUIRED }
  };
  return <SelectInputWithLabel {...eventTeamProps} />;
};

EventTeamInput.propTypes = {
  consultants: PropTypes.object,
  users: PropTypes.array,
  errors: PropTypes.object,
  register: PropTypes.func,
  control: PropTypes.object,
  staff: PropTypes.array,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  currentUserId: PropTypes.string
};
