/**
 *
 * @param {string} type Action type string
 * @param  {...any} argNames Arg names passed to the function
 * @returns {object} Action creator that contains the type and passed arguments as entries
 */
export function makeActionCreator(type, ...argNames) {
  return (...args) => {
    const action = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
}
