import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AlertStyles from './styles';

const Alert = ({ message, type, timeout, width, color, reset }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (!show && reset) reset();
    return () => {};
  }, [show]);

  useEffect(() => {
    const timer = setTimeout(() => setShow(false), timeout || 4000);
    return () => {
      clearTimeout(timer);
    };
  }, [message]);

  return message && show ? (
    <AlertStyles width={width} type={type} color={color}>
      {message}
    </AlertStyles>
  ) : null;
};

Alert.propTypes = {
  message: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  type: PropTypes.string,
  timeout: PropTypes.number,
  reset: PropTypes.object
};

export default Alert;
