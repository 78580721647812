export const GET_TABLES_REQUEST = 'GET_TABLES_REQUEST';
export const GET_TABLES_SUCCESS = 'GET_TABLES_SUCCESS';
export const GET_TABLES_FAILURE = 'GET_TABLES_FAILURE';

export const ADD_TABLE_REQUEST = 'ADD_TABLE_REQUEST';
export const ADD_TABLE_SUCCESS = 'ADD_TABLE_SUCCESS';
export const ADD_TABLE_FAILURE = 'ADD_TABLE_FAILURE';

export const UPDATE_TABLE_REQUEST = 'UPDATE_TABLE_REQUEST';
export const UPDATE_TABLE_SUCCESS = 'UPDATE_TABLE_SUCCESS';
export const UPDATE_TABLE_FAILURE = 'UPDATE_TABLE_FAILURE';

export const DELETE_TABLE_REQUEST = 'DELETE_TABLE_REQUEST';
export const DELETE_TABLE_SUCCESS = 'DELETE_TABLE_SUCCESS';
export const DELETE_TABLE_FAILURE = 'DELETE_TABLE_FAILURE';

export const UPDATE_TABLE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_TABLE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_TABLE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';

export const RESET_ALERTS = 'RESET_ALERTS';
