export const GET_CHECKLIST_ITEMS_REQUEST = 'GET_CHECKLIST_ITEMS_REQUEST';
export const GET_CHECKLIST_ITEMS_SUCCESS = 'GET_CHECKLIST_ITEMS_SUCCESS';
export const GET_CHECKLIST_ITEMS_FAILURE = 'GET_CHECKLIST_ITEMS_FAILURE';

export const IMPORT_CHECKLIST_ITEMS_REQUEST = 'IMPORT_CHECKLIST_ITEMS_REQUEST';
export const IMPORT_CHECKLIST_ITEMS_SUCCESS = 'IMPORT_CHECKLIST_ITEMS_SUCCESS';
export const IMPORT_CHECKLIST_ITEMS_FAILURE = 'IMPORT_CHECKLIST_ITEMS_FAILURE';

export const UPDATE_CHECKLIST_ITEM_REQUEST = 'UPDATE_CHECKLIST_ITEM_REQUEST';
export const UPDATE_CHECKLIST_ITEM_SUCCESS = 'UPDATE_CHECKLIST_ITEM_SUCCESS';
export const UPDATE_CHECKLIST_ITEM_FAILURE = 'UPDATE_CHECKLIST_ITEM_FAILURE';

export const CREATE_CHECKLIST_ITEM_REQUEST = 'CREATE_CHECKLIST_ITEM_REQUEST';
export const CREATE_CHECKLIST_ITEM_SUCCESS = 'CREATE_CHECKLIST_ITEM_SUCCESS';
export const CREATE_CHECKLIST_ITEM_FAILURE = 'CREATE_CHECKLIST_ITEM_FAILURE';

export const UPDATE_HOST_CHECKLIST_ITEM_REQUEST = 'UPDATE_HOST_CHECKLIST_ITEM_REQUEST';
export const UPDATE_HOST_CHECKLIST_ITEM_SUCCESS = 'UPDATE_HOST_CHECKLIST_ITEM_SUCCESS';
export const UPDATE_HOST_CHECKLIST_ITEM_FAILURE = 'UPDATE_HOST_CHECKLIST_ITEM_FAILURE';

export const DELETE_CHECKLIST_ITEM_REQUEST = 'DELETE_CHECKLIST_ITEM_REQUEST';
export const DELETE_CHECKLIST_ITEM_SUCCESS = 'DELETE_CHECKLIST_ITEM_SUCCESS';
export const DELETE_CHECKLIST_ITEM_FAILURE = 'DELETE_CHECKLIST_ITEM_FAILURE';

export const SET_SORT_FIELD = 'SET_SORT_FIELD';
export const SET_SORT_ORDER = 'SET_SORT_ORDER';
export const SET_COMPLETED_FILTER = 'SET_COMPLETED_FILTER';

export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';

export const OPEN_CHECKLIST_MODAL = 'OPEN_CHECKLIST_MODAL';
export const CLOSE_CHECKLIST_MODAL = 'CLOSE_CHECKLIST_MODAL';
