import {
  ADD_EVENT_VENDOR_REQUEST,
  FETCH_EVENT_VENDORS_REQUEST,
  UPDATE_EVENT_VENDOR_REQUEST,
  UPDATE_EVENT_VENDOR_SUCCESS,
  UPDATE_EVENT_VENDOR_FAILURE,
  DELETE_EVENT_VENDOR_REQUEST,
  DELETE_EVENT_VENDOR_SUCCESS,
  DELETE_EVENT_VENDOR_FAILURE,
  GET_EVENT_VENDORS_BY_EVENT_ID_REQUEST,
  GET_EVENT_VENDORS_BY_EVENT_ID_SUCCESS,
  GET_EVENT_VENDORS_BY_EVENT_ID_FAILURE,
  OPEN_VENDOR_MODAL
} from './types';

export const addEventVendorRequest = ({ vendor }) => ({
  type: ADD_EVENT_VENDOR_REQUEST,
  vendor
});

export const updateEventVendorRequest = ({ vendor, vendorId }) => ({
  type: UPDATE_EVENT_VENDOR_REQUEST,
  vendor,
  vendorId
});

export const updateEventVendorSuccess = ({ updatingEventVendorSuccess }) => ({
  type: UPDATE_EVENT_VENDOR_SUCCESS,
  updatingEventVendorSuccess
});

export const updateEventVendorFailed = ({ updatingEventVendorError }) => ({
  type: UPDATE_EVENT_VENDOR_FAILURE,
  updatingEventVendorError
});

export const deleteEventVendorRequest = ({ vendorId }) => ({
  type: DELETE_EVENT_VENDOR_REQUEST,
  vendorId
});

export const deleteEventVendorSuccess = ({ deletingEventVendorSuccess }) => ({
  type: DELETE_EVENT_VENDOR_SUCCESS,
  deletingEventVendorSuccess
});

export const deleteEventVendorFailure = ({ deletingEventVendorError }) => ({
  type: DELETE_EVENT_VENDOR_FAILURE,
  deletingEventVendorError
});

export const fetchEventVendorsRequest = () => ({
  type: FETCH_EVENT_VENDORS_REQUEST
});

export const getEventVendorsByEventIdRequest = ({ token, eventId }) => ({
  type: GET_EVENT_VENDORS_BY_EVENT_ID_REQUEST,
  token,
  eventId
});

export const getEventVendorsByEventIdSuccess = ({ eventVendors }) => {
  return {
    type: GET_EVENT_VENDORS_BY_EVENT_ID_SUCCESS,
    eventVendors
  };
};

export const getEventVendorsByEventIdFailure = error => ({
  type: GET_EVENT_VENDORS_BY_EVENT_ID_FAILURE,
  error
});

export const openVendorModal = isOpen => ({
  type: OPEN_VENDOR_MODAL,
  isOpen
});
