const EVENT_TASK_NAME = {
  REQUIRED: 'Event Task Name is required',
  LABEL: 'Event Task Name',
  PLACEHOLDER: 'Enter a name for the event task'
};

const EVENT_TASK_ASSIGNED_TO = {
  LABEL: 'Assigned To',
  PLACEHOLDER: 'Enter the name of the person assigned to this task'
};

const EVENT_TASK_DESCRIPTION = {
  LABEL: 'Description',
  PLACEHOLDER: 'Enter a description for the event task'
};

const EVENT_TASK_DUE_DATE = {
  LABEL: 'Due Date',
  PLACEHOLDER: 'Enter the due date for the event task'
};

const EVENT_TASK_COMPLETED = {
  LABEL: 'Completed',
  PLACEHOLDER: 'Check if the event task is completed'
};

export const EVENT_TASK_FORM_CONSTANTS = {
  EVENT_TASK_NAME,
  EVENT_TASK_ASSIGNED_TO,
  EVENT_TASK_DESCRIPTION,
  EVENT_TASK_DUE_DATE,
  EVENT_TASK_COMPLETED
};
