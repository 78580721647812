import { CLOSE_HOST_FORM_DRAWER, OPEN_HOST_FORM_DRAWER } from './types';

const initialState = {
  hostFormDrawerOpen: false,
  activeGroupFields: null,
  activeGroupName: null
};

export const hostFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_HOST_FORM_DRAWER:
      return {
        ...state,
        hostFormDrawerOpen: true,
        activeGroupFields: action.activeGroupFields,
        activeGroupName: action.activeGroupName
      };
    case CLOSE_HOST_FORM_DRAWER:
      return {
        ...state,
        hostFormDrawerOpen: false,
        activeGroupFields: null,
        activeGroupName: null
      };
    default:
      return state;
  }
};
