import { takeLatest, put, call } from 'redux-saga/effects';
import {
  GET_GUESTS_REQUEST,
  GET_GUESTS_SUCCESS,
  GET_GUESTS_FAILURE,
  ADD_GUEST_REQUEST,
  ADD_GUEST_SUCCESS,
  ADD_GUEST_FAILURE,
  EDIT_GUEST_REQUEST,
  EDIT_GUEST_SUCCESS,
  EDIT_GUEST_FAILURE,
  DELETE_GUEST_REQUEST,
  DELETE_GUEST_SUCCESS,
  DELETE_GUEST_FAILURE,
  GENERATE_GUEST_FILE_REQUEST,
  GENERATE_GUEST_FILE_FAILURE
} from './types';
import { getGuests, addGuest, editGuest, deleteGuest, generateExcelFile } from './actions';
import {
  closeAddGuestModal,
  closeEditGuestModal,
  closeDeleteGuestModal,
  closeDeleteMultipleGuestModal
} from '../GuestListContainer/operations';
import { createExcelFile } from '../../utils/generateGuestList';

function* workerGuests({ token, eventId, orderBy, order, venueDownload }) {
  try {
    const response = yield call(getGuests, { token, eventId, orderBy, order, venueDownload });

    const { guests } = response.data;

    yield put({
      type: GET_GUESTS_SUCCESS,
      guests
    });
  } catch (error) {
    yield put({ type: GET_GUESTS_FAILURE, error: error.response.data });
  }
}

function* workerAddGuest({ token, data }) {
  try {
    const response = yield call(addGuest, { token, data });

    const { guest } = response.data;
    yield put({
      type: ADD_GUEST_SUCCESS,
      guest
    });
    yield put(closeAddGuestModal());
  } catch (error) {
    yield put({ type: ADD_GUEST_FAILURE, error: error.response.data });
  }
}

function* workerEditGuest({ token, guestId, data }) {
  try {
    const response = yield call(editGuest, { token, guestId, data });

    const { guest } = response.data;

    yield put({
      type: EDIT_GUEST_SUCCESS,
      guest
    });
    yield put(closeEditGuestModal());
  } catch (error) {
    yield put({
      type: EDIT_GUEST_FAILURE,
      error: error.response ? error.response.data : error
    });
  }
}

function* workerDeleteGuest({ token, guestId }) {
  try {
    const response = yield call(deleteGuest, { token, guestId });

    const { success } = response.data;

    yield put({
      type: DELETE_GUEST_SUCCESS,
      success,
      id: guestId
    });
    yield put(closeDeleteGuestModal());
    yield put(closeDeleteMultipleGuestModal());
  } catch (error) {
    yield put({ type: DELETE_GUEST_FAILURE, error: error.response.data });
  }
}

function* workerGenerateExcelFile({ token, eventId, venueId, layoutId }) {
  try {
    const response = yield call(generateExcelFile, { token, eventId, venueId, layoutId });

    const { buffer, eventTitle } = response.data;
    createExcelFile(buffer, eventTitle);
  } catch (error) {
    yield put({ type: GENERATE_GUEST_FILE_FAILURE, error: error.response.data });
  }
}

export function* watcherGuests() {
  yield takeLatest(GET_GUESTS_REQUEST, workerGuests);
}

export function* watcherAddGuest() {
  yield takeLatest(ADD_GUEST_REQUEST, workerAddGuest);
}

export function* watcherEditGuest() {
  yield takeLatest(EDIT_GUEST_REQUEST, workerEditGuest);
}

export function* watcherDeleteGuest() {
  yield takeLatest(DELETE_GUEST_REQUEST, workerDeleteGuest);
}

export function* watcherGenerateExcelFile() {
  yield takeLatest(GENERATE_GUEST_FILE_REQUEST, workerGenerateExcelFile);
}
