export const OPEN_INVOICE_SUMMARY_DRAWER = 'OPEN_INVOICE_SUMMARY_DRAWER';
export const CLOSE_INVOICE_SUMMARY_DRAWER = 'CLOSE_INVOICE_SUMMARY_DRAWER';

export const OPEN_MANUAL_PAYMENT_MODAL = 'OPEN_MANUAL_PAYMENT_MODAL';
export const CLOSE_MANUAL_PAYMENT_MODAL = 'CLOSE_MANUAL_PAYMENT_MODAL';
export const OPEN_CREATE_INVOICE_MODAL = 'OPEN_CREATE_INVOICE_MODAL';
export const CLOSE_CREATE_INVOICE_MODAL = 'CLOSE_CREATE_INVOICE_MODAL';

export const OPEN_PAYMENT_SCHEDULE_MODAL = 'OPEN_PAYMENT_SCHEDULE_MODAL';
export const CLOSE_PAYMENT_SCHEDULE_MODAL = 'CLOSE_PAYMENT_SCHEDULE_MODAL';

export const OPEN_MAKE_PAYMENT_MODAL = 'OPEN_MAKE_PAYMENT_MODAL';
export const CLOSE_MAKE_PAYMENT_MODAL = 'CLOSE_MAKE_PAYMENT_MODAL';
