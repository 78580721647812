import {
  GET_CHECKLIST_ITEMS_REQUEST,
  GET_CHECKLIST_ITEMS_SUCCESS,
  GET_CHECKLIST_ITEMS_FAILURE,
  IMPORT_CHECKLIST_ITEMS_REQUEST,
  IMPORT_CHECKLIST_ITEMS_SUCCESS,
  IMPORT_CHECKLIST_ITEMS_FAILURE,
  SET_SEARCH_TERM,
  CREATE_CHECKLIST_ITEM_REQUEST,
  CREATE_CHECKLIST_ITEM_SUCCESS,
  CREATE_CHECKLIST_ITEM_FAILURE,
  UPDATE_HOST_CHECKLIST_ITEM_REQUEST,
  UPDATE_HOST_CHECKLIST_ITEM_SUCCESS,
  UPDATE_HOST_CHECKLIST_ITEM_FAILURE,
  DELETE_CHECKLIST_ITEM_REQUEST,
  DELETE_CHECKLIST_ITEM_SUCCESS,
  DELETE_CHECKLIST_ITEM_FAILURE,
  SET_COMPLETED_FILTER,
  SET_SORT_ORDER,
  SET_SORT_FIELD,
  UPDATE_CHECKLIST_ITEM_REQUEST,
  UPDATE_CHECKLIST_ITEM_SUCCESS,
  UPDATE_CHECKLIST_ITEM_FAILURE,
  OPEN_CHECKLIST_MODAL,
  CLOSE_CHECKLIST_MODAL
} from './types';

const initialState = {
  items: [],
  error: '',
  status: '',
  searchTerm: '',
  importType: '',
  sortOrder: 'asc',
  itemToUpdate: '',
  sortField: '',
  completedFilter: 'all',
  updatingChecklist: false,
  fetchingItems: false,
  isChecklistModalOpen: false
};

const checklistReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHECKLIST_ITEMS_REQUEST:
      return {
        ...state,
        status: 'fetchingItems',
        error: ''
      };
    case GET_CHECKLIST_ITEMS_SUCCESS:
      return {
        ...state,
        status: 'fetchingItemsSuccess',
        error: '',
        items: action.items
      };
    case GET_CHECKLIST_ITEMS_FAILURE:
      return {
        ...state,
        error: action.error,
        status: 'fetchingItemsFailure',
        items: []
      };
    case IMPORT_CHECKLIST_ITEMS_REQUEST:
      return {
        ...state,
        status: 'importingItems',
        importType: action.importType,
        fetchingItems: true,
        error: ''
      };
    case IMPORT_CHECKLIST_ITEMS_SUCCESS:
      return {
        ...state,
        status: 'importingItemsSuccess',
        fetchingItems: false,
        error: ''
      };
    case IMPORT_CHECKLIST_ITEMS_FAILURE:
      return {
        ...state,
        error: action.error,
        status: 'importingItemsFailure',
        fetchingItems: false
      };
    case CREATE_CHECKLIST_ITEM_REQUEST:
      return {
        ...state,
        error: '',
        status: 'creatingItem'
      };
    case CREATE_CHECKLIST_ITEM_SUCCESS:
      return {
        ...state,
        error: '',
        status: 'creatingItemSuccess'
      };
    case CREATE_CHECKLIST_ITEM_FAILURE:
      return {
        ...state,
        error: action.error,
        status: 'creatingItemFailure'
      };
    case UPDATE_HOST_CHECKLIST_ITEM_REQUEST:
      return {
        ...state,
        error: '',
        status: 'updatingHostItem'
      };
    case UPDATE_HOST_CHECKLIST_ITEM_SUCCESS:
      return {
        ...state,
        error: '',
        status: 'updatingHostItemSuccess'
      };
    case UPDATE_HOST_CHECKLIST_ITEM_FAILURE:
      return {
        ...state,
        error: action.error,
        status: 'updatingHostItemFailure'
      };
    case UPDATE_CHECKLIST_ITEM_REQUEST:
      return {
        ...state,
        error: '',
        status: 'updatingItem',
        itemToUpdate: Object.keys(action.checklistItems)[0],
        updatingChecklist: true
      };
    case UPDATE_CHECKLIST_ITEM_SUCCESS:
      return {
        ...state,
        error: '',
        status: 'updatingItemSuccess',
        itemToUpdate: '',
        updatingChecklist: false
      };
    case UPDATE_CHECKLIST_ITEM_FAILURE:
      return {
        ...state,
        error: action.error,
        status: 'updatingItemFailure',
        itemToUpdate: '',
        updatingChecklist: false
      };
    case DELETE_CHECKLIST_ITEM_REQUEST:
      return {
        ...state,
        error: '',
        status: 'deletingItem'
      };
    case DELETE_CHECKLIST_ITEM_SUCCESS:
      return {
        ...state,
        error: '',
        status: 'deletingItemSuccess'
      };
    case DELETE_CHECKLIST_ITEM_FAILURE:
      return {
        ...state,
        error: action.error,
        status: 'deletingItemFailure'
      };
    case SET_SORT_FIELD:
      return {
        ...state,
        sortField: action.sortField
      };
    case SET_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.sortOrder
      };
    case SET_COMPLETED_FILTER:
      return {
        ...state,
        completedFilter: action.completedFilter
      };
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm
      };
    case OPEN_CHECKLIST_MODAL:
      return {
        ...state,
        isChecklistModalOpen: true
      };
    case CLOSE_CHECKLIST_MODAL:
      return {
        ...state,
        isChecklistModalOpen: false
      };
    default:
      return state;
  }
};

export default checklistReducer;
