import React from 'react';
import PropTypes from 'prop-types';

import EllipsisStyles from './styles';

const Ellipsis = ({ action }) => {
  return (
    <EllipsisStyles onClick={action}>
      <EllipsisStyles.Dot />
      <EllipsisStyles.Dot />
      <EllipsisStyles.Dot />
    </EllipsisStyles>
  );
};

Ellipsis.propTypes = {
  action: PropTypes.func
};

export default Ellipsis;
