import { CardsSectionInfo } from 'src/v2/UIComponents';
import { useTableContext } from 'src/v2/context/Tables';
import { Icon } from '@chakra-ui/react';
import { BsFilterCircle, BsPersonCircle } from 'react-icons/bs';
import { useEvents, useLeads, useUser } from 'src/v2/Hooks/CollectionHooks';
import { FiPhoneOff } from 'react-icons/fi';
import { RiContactsBook2Line } from 'react-icons/ri';
import { RawEvent } from 'venuex-types';

export const MiniCardsSection = () => {
  const tableValues = useTableContext<RawEvent>();
  const { getNewOpportunities } = useLeads();
  const { fetchingLeads } = useLeads();
  const {
    // rowCount, filters,
    tableData,
    allData
  } = tableValues;

  const { userId } = useUser();

  const allOpenOpportunities = getNewOpportunities(allData);

  const userLeads = allData?.filter(lead => lead?.salesperson === userId);

  const miniCardsTitle = [
    {
      title: 'Filtered Leads',
      value: tableData?.length,
      icon: <Icon h={8} w={8} as={BsFilterCircle} />
    },
    {
      title: 'Total Leads',
      value: allData?.length,
      icon: <Icon h={8} w={8} as={RiContactsBook2Line} />
    },
    {
      title: 'Leads Not Contacted',
      value: allOpenOpportunities,
      icon: <Icon h={8} w={8} as={FiPhoneOff} />
    },
    {
      title: 'Your Leads',
      value: userLeads?.length,
      icon: <Icon h={8} w={8} as={BsPersonCircle} />
    }
  ];

  return <CardsSectionInfo cardsInfo={miniCardsTitle} mb="20px" isLoading={fetchingLeads} />;
};
