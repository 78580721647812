import styled from 'styled-components';
import Colors from '../../styles/Colors';

const AddEventStyles = styled.div``;

AddEventStyles.Header = styled.div`
  display: flex;
  justify-content: center;
  height: 110px;
  z-index: 10;
  align-items: center;
  font-family: Lora;
  font-size: 24px;
  letter-spacing: -0.6px;
  text-transform: capitalize;
  text-align: center;
  color: #181818;
  background-color: ${props => (props.bgColor ? props.bgColor : `var(--colors-modalHeader);`)};
  flex-direction: column;
  justify-content: center;
`;

AddEventStyles.HeaderTitle = styled.div``;

AddEventStyles.CloseButton = styled.button`
  padding: 15px;
  margin-left: auto;
  background-color: Transparent;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
`;

AddEventStyles.Body = styled.div`
  display: flex;
  flex-direction: column;
  height: ${props => props.height || '480px'};
  padding: ${props =>
    (props && props.padding) || (props.noText && '43px 35px 0 35px') || '69px 47px 0 48px'};
  overflow: auto;
  letter-spacing: -0.5px;
`;

AddEventStyles.BodyRow = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${props => (props.marginTop ? props.marginTop : '')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '')};
  margin-left: ${props => (props.marginLeft && props.marginLeft) || ''};
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : 'row')};
  align-items: ${props =>
    (props.disableAlign && ' ') ||
    (props.alignBottom && 'flex-end') ||
    (props.alignTop && 'flex-start') ||
    'center'};
  white-space: nowrap;
  font-size: 15px;
  font-weight: 600;
  justify-content: space-between;
  min-height: -webkit-fit-content;
`;

AddEventStyles.BodyKey = styled.div`
  display: flex;
  color: ${props => (props.color && props.color) || '#7d7d7d'};
  align-items: ${props => (props.disableAlign ? '' : 'center')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '8px')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '2px')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
  margin-bottom: 6px;
  flex-wrap: no-wrap;
  transition: color 1s;
`;

AddEventStyles.InfoText = styled.div`
  height: 18px;
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.19px;
  text-align: right;
  color: ${props => (props && props.color) || '#b0b0b0'};
  margin-top: -45px;
  margin-bottom: 32px;
  transition: color 1s;
`;

AddEventStyles.BodyError = styled.div`
  transition: all 250ms ease-in-out 0s;
  color: ${Colors.redC0};
  font-size: 11px;
  font-weight: bold;
  line-height: 22px;
`;

AddEventStyles.Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px;
  height: 80px;
  border-top: solid 1px #ccc;
  box-sizing: border-box;
`;

AddEventStyles.FooterItem = styled.div`
  display: flex;
  margin: 0 5px;
`;

export default AddEventStyles;
