import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import AddChecklistModalHeader from './header';
import AddChecklistModalBody from './body';
import AddChecklistModalFooter from './footer';
import AddChecklistModalStyles from './styles';

const AddChecklistModal = ({
  role,
  event = {},
  status,
  itemToEdit,
  handleCreateItem,
  showAddChecklist,
  setShowAddChecklist,
  handleUpdateHostChecklistItem
}) => {
  const { start: startDate } = event || {};
  const [item, setItem] = useState({});

  useEffect(() => {
    if (event && event.eventType) {
      const { eventType } = event;
      setItem({ ...item, eventType });
    }
  }, [event]);

  return (
    <Modal
      isOpen={showAddChecklist}
      onRequestClose={() => setShowAddChecklist(false)}
      noPadding
      zIndex={50}
      height={780}
      width={580}
    >
      <AddChecklistModalStyles>
        <AddChecklistModalHeader
          title={Object.keys(itemToEdit).length ? 'Edit Checklist Item' : 'Add Checklist Item'}
          setShowAddChecklist={setShowAddChecklist}
        />
        <AddChecklistModalBody
          role={role}
          startDate={startDate}
          setItem={setItem}
          item={item}
          itemToEdit={itemToEdit}
        />
        <AddChecklistModalFooter
          closeModal={() => setShowAddChecklist(false)}
          action={() => {
            if (Object.keys(itemToEdit).length) {
              handleUpdateHostChecklistItem({ ...item, id: itemToEdit.id });
            } else {
              handleCreateItem(item);
            }
          }}
          itemToEdit={Object.keys(itemToEdit).length}
          loading={status === 'creatingItem' || status === 'updatingHostItem'}
        />
      </AddChecklistModalStyles>
    </Modal>
  );
};

AddChecklistModal.propTypes = {
  showAddChecklist: PropTypes.bool,
  status: PropTypes.string,
  role: PropTypes.string,
  event: PropTypes.object,
  itemToEdit: PropTypes.object,
  setShowAddChecklist: PropTypes.func,
  handleUpdateHostChecklistItem: PropTypes.func,
  handleCreateItem: PropTypes.func
};

AddChecklistModal.defaultProps = {
  role: '',
  status: '',
  showAddChecklist: false,
  setShowAddChecklist: () => {},
  handleUpdateHostChecklistItem: () => {},
  handleCreateItem: () => {},
  event: {},
  itemToEdit: {}
};

export default AddChecklistModal;
