import {
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
  GET_MORE_EVENTS_REQUEST,
  GET_MORE_EVENTS_SUCCESS,
  GET_MORE_EVENTS_FAILURE,
  SET_PAYMENT_STATUS,
  SEARCH_EVENT_REQUEST,
  UPDATE_EVENT_NOTE_REQUEST,
  UPDATE_EVENT_NOTE_FAILURE,
  UPDATE_EVENT_NOTE_SUCCESS,
  CLEAR_ACTIVE_EVENT,
  UPDATE_EVENT_CHECKLIST_ITEM_REQUEST,
  UPDATE_EVENT_CHECKLIST_ITEM_SUCCESS,
  UPDATE_EVENT_CHECKLIST_ITEM_FAILURE,
  OPEN_CREATE_EVENT_FORM,
  CLOSE_CREATE_EVENT_FORM,
  GET_EVENT_ANALYTICS,
  GET_USER_TASKS_REQUEST,
  GET_USER_TASKS_SUCCESS,
  GET_USER_TASKS_FAILURE
} from './types';

type GetEventsProps = {
  token: string;
  limit?: number;
  startDate?: string;
  startAfter?: string;
  endDate?: string;
  monthStart?: string;
  monthEnd?: string;
};
export const getEvents = ({
  token,
  limit,
  startDate,
  endDate,
  startAfter,
  monthStart,
  monthEnd
}: GetEventsProps) => ({
  type: GET_EVENTS_REQUEST,
  token,
  limit,
  startDate,
  startAfter,
  endDate,
  monthStart,
  monthEnd
});

export const getEventAnalytics = ({
  token,
  limit,
  startDate,
  endDate,
  startAfter,
  monthStart,
  monthEnd
}) => ({
  type: GET_EVENT_ANALYTICS.REQUEST,
  token,
  limit,
  startDate,
  startAfter,
  endDate,
  monthStart,
  monthEnd
});

export const getEventAnalyticsSuccess = (events, stats, appointments, detailedEventList) => ({
  type: GET_EVENT_ANALYTICS.SUCCESS,
  events,
  stats,
  appointments,
  detailedEventList
});

export const getEventAnalyticsFailure = error => ({
  type: GET_EVENT_ANALYTICS.FAILURE,
  error
});

export const getEventsSuccess = (events, stats, appointments) => ({
  type: GET_EVENTS_SUCCESS,
  events,
  stats,
  appointments
});

export const getEventsFailure = error => ({
  type: GET_EVENTS_FAILURE,
  error
});

export const getEventRequest = ({ token, eventId }) => ({
  type: GET_EVENT_REQUEST,
  token,
  eventId
});

export const getEventSuccess = event => ({
  type: GET_EVENT_SUCCESS,
  event
});

export const getEventFailure = error => ({
  type: GET_EVENT_FAILURE,
  error
});

export const clearActiveEvent = () => ({
  type: CLEAR_ACTIVE_EVENT
});

export const getMoreEventsRequest = ({ token, limit, startDate, endDate, startAfter }) => ({
  type: GET_MORE_EVENTS_REQUEST,
  token,
  limit,
  startDate,
  endDate,
  startAfter
});

export const getMoreEventsSuccess = events => ({
  type: GET_MORE_EVENTS_SUCCESS,
  events
});

export const getEventsMoreFailure = error => ({
  type: GET_MORE_EVENTS_FAILURE,
  error
});

type DeleteEventRequest = {
  id: string;
  monthStart: string;
  monthEnd: string;
  consultantId?: string;
};
export const deleteEvent = ({
  id,
  consultantId = '',
  monthStart,
  monthEnd
}: DeleteEventRequest) => ({
  type: DELETE_EVENT_REQUEST,
  id,
  consultantId,
  monthStart,
  monthEnd
});

export const deleteEventSuccess = success => ({
  type: DELETE_EVENT_SUCCESS,
  success
});

export const deleteEventFailure = (error, success) => ({
  type: DELETE_EVENT_FAILURE,
  error,
  success
});

export const setPaymentStatusOnEventSuccess = ({ eventId, status }) => ({
  type: SET_PAYMENT_STATUS,
  eventId,
  status
});

export const searchEventRequest = ({ token, option }) => ({
  type: SEARCH_EVENT_REQUEST,
  token,
  option
});

export const updateEventNoteRequest = ({ id, event, token }) => ({
  type: UPDATE_EVENT_NOTE_REQUEST,
  id,
  event,
  token
});

export const updateEventNoteSuccess = ({ event }) => ({
  type: UPDATE_EVENT_NOTE_SUCCESS,
  event
});

export const updateEventNoteFailure = error => ({
  type: UPDATE_EVENT_NOTE_FAILURE,
  error
});

export const updateChecklistItemRequest = ({ token, checklistItem, eventId, role }) => ({
  type: UPDATE_EVENT_CHECKLIST_ITEM_REQUEST,
  token,
  role,
  checklistItem,
  eventId
});

export const updateEventChecklistItemSuccess = ({ success }) => ({
  type: UPDATE_EVENT_CHECKLIST_ITEM_SUCCESS,
  success
});

export const updateEventChecklistItemFailure = error => ({
  type: UPDATE_EVENT_CHECKLIST_ITEM_FAILURE,
  error
});

export const openCreateEventForm = () => ({
  type: OPEN_CREATE_EVENT_FORM
});

export const closeCreateEventForm = () => ({
  type: CLOSE_CREATE_EVENT_FORM
});

export const getEventTasksRequest = () => ({
  type: GET_USER_TASKS_REQUEST
});

export const getEventTasksSuccess = ({ userTasks }) => ({
  type: GET_USER_TASKS_SUCCESS,
  userTasks
});

export const getEventTasksFailure = () => ({
  type: GET_USER_TASKS_FAILURE
});
