import { Box } from '@chakra-ui/react';
import Colors from 'src/styles/Colors';
import { InlineTextField } from 'src/v2/UIComponents/FormComponents/InlineTextField/InlineTextField';

export const ComposerBody = () => {
  return (
    <Box
      borderBottom={`1px solid ${Colors.grayED7}`}
      height="100%"
      display="flex"
      flexDir="column"
      padding="12px 12px"
      fontSize="14px"
      minHeight={'1px'}
      paddingLeft="0px"
    >
      <InlineTextField placeholder={''} name={'body'} />
    </Box>
  );
};
