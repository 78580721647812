import { FC, forwardRef } from 'react';
import { IconButton as ChakraIconButton, IconButtonProps } from '@chakra-ui/react';
import { Tooltip } from '../Tooltip';
// import { GrowingButton } from 'src/v2/UIComponents/Animated/animated';

interface CustomProps extends IconButtonProps {
  tooltipLabel?: string;
  shape?: string;
}

export const IconButton: FC<CustomProps> = forwardRef<HTMLButtonElement, CustomProps>(
  (
    {
      children,
      onClick,
      variant = 'primary',
      icon,
      size,
      tooltipLabel,
      isLoading = false,
      ...props
    },
    ref
  ) => (
    <Tooltip label={tooltipLabel} shouldWrapChildren openDelay={500}>
      <ChakraIconButton
        ref={ref}
        icon={icon}
        size={size}
        variant={variant}
        onClick={onClick}
        isLoading={isLoading}
        {...props}
      >
        {children}
      </ChakraIconButton>
    </Tooltip>
  )
);

IconButton.displayName = 'IconButton';
