import { Grid, Box, Flex } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import Colors from 'src/styles/Colors';

import { SmoothContainerDiv } from '../../Animated/animated';
import { Toolbar, ToolbarProps } from '../../Toolbar';

export type AppBodyProps = {
  children: ReactNode;
  toolbarProps?: ToolbarProps;
  isCalendar?: boolean;
  isTable?: boolean;
  showBorder?: boolean;
  withPadding?: boolean;
};
export const AppBody = ({
  children,
  toolbarProps,
  isCalendar,
  isTable,
  showBorder,
  withPadding = true
}: AppBodyProps) => {
  const templateRows = (isCalendar && '1fr') || 'min-content 1fr';

  const defaultProps = {
    display: 'flex',

    bg: Colors.grayFA
  };

  const tableProps = {
    display: 'flex'
  };

  const paddingStyle = {
    paddingX: '30px',
    paddingTop: '25px',
    paddingBottom: '12px'
  };

  return (
    <SmoothContainerDiv>
      <Box
        h="100%"
        w="100%"
        {...((isTable && tableProps) || defaultProps)}
        flexDirection="column"
        border={showBorder ? `1px solid ${Colors.grayED}` : ''}
      >
        {toolbarProps && <Toolbar {...toolbarProps} />}
        <Flex
          bg={Colors.grayFA}
          h="100%"
          overflowY="hidden"
          direction="column"
          justify="flex-start"
          {...(withPadding && paddingStyle)}
        >
          {children}
        </Flex>
      </Box>
    </SmoothContainerDiv>
  );
};
