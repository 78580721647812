import {
  OPEN_CHANGE_PASSWORD,
  CLOSE_CHANGE_PASSWORD,
  RESET_PASSWORD_USER_FAILED,
  RESET_PASSWORD_USER_REQUEST,
  RESET_PASSWORD_USER_SUCCESS
} from './types';

const initialState = {
  isOpen: false,
  fetching: false,
  failed: false,
  errors: null,
  successMessage: null
};

const changePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CHANGE_PASSWORD:
      return {
        isOpen: true
      };
    case CLOSE_CHANGE_PASSWORD:
      return {
        ...state,
        isOpen: false
      };
    case RESET_PASSWORD_USER_REQUEST:
      return {
        ...state,
        fetching: true,
        failed: false,
        successMessage: null,
        errors: null
      };
    case RESET_PASSWORD_USER_FAILED:
      return {
        ...state,
        fetching: false,
        failed: true,
        successMessage: null,
        errors: action.resetPassError
      };
    case RESET_PASSWORD_USER_SUCCESS:
      return {
        ...state,
        fetching: false,
        failed: false,
        successMessage: action.resetPassSuccess,
        errors: action.error
      };
    default:
      return state;
  }
};

export default changePasswordReducer;
