import React from 'react';

const MoneyIcon = props => {
  const sideBarIconColor = props.sideBarIcon
    ? 'var(--colors-sideBarText)'
    : 'var(--colors-primary)';
  return (
    <svg width={props.size} height={props.size} {...props} viewBox="0 0 24 24">
      <g fill={props.color ? props.color : sideBarIconColor}>
        <path d="M1.65 7.15a.35.35 0 0 0-.35.35v12c0 .193.157.35.35.35h19a.35.35 0 0 0 .35-.35v-12a.35.35 0 0 0-.35-.35h-19zm0-1.3h19c.911 0 1.65.739 1.65 1.65v12a1.65 1.65 0 0 1-1.65 1.65h-19A1.65 1.65 0 0 1 0 19.5v-12c0-.911.739-1.65 1.65-1.65z" />
        <path d="M21.764 18.197c-.327-.06-.559-.315-.559-.638 0-.39.332-.754.666-.628.354.133.939-.321.939-.73V4.8c0-.399-.283-.717-.62-.717H3.41c-.337 0-.62.318-.62.717v1.33c0 .35-.262.641-.595.641-.333 0-.595-.29-.595-.641V4.799C1.6 3.7 2.408 2.8 3.41 2.8h18.78c1.002 0 1.81.9 1.81 1.999V16.2c0 1.138-1.166 2.192-2.236 1.996z" />
        <path d="M10.887 8.723a4.674 4.674 0 0 0-4.666 4.666 4.674 4.674 0 0 0 4.666 4.666 4.674 4.674 0 0 0 4.667-4.666 4.674 4.674 0 0 0-4.667-4.666zm0 1.037a3.622 3.622 0 0 1 3.63 3.63 3.621 3.621 0 0 1-3.63 3.628 3.621 3.621 0 0 1-3.629-3.629 3.622 3.622 0 0 1 3.63-3.629zm-.056.51a.519.519 0 0 0-.462.527v.065c-.285.064-.535.173-.729.348a1.394 1.394 0 0 0-.446 1.037c0 .642.463 1.036.819 1.272.355.236.696.356.696.356s.245.088.478.243c.234.155.357.33.357.405 0 .12-.028.198-.106.268-.077.07-.231.153-.55.153-.639 0-.819-.518-.819-.518a.52.52 0 1 0-.956.405s.38.826 1.256 1.077v.073a.519.519 0 1 0 1.037 0v-.056c.285-.067.534-.19.729-.365.306-.275.446-.667.446-1.037 0-.642-.463-1.036-.819-1.272a3.56 3.56 0 0 0-.696-.356s-.245-.088-.478-.243c-.234-.155-.357-.33-.357-.405 0-.12.028-.198.106-.267.077-.07.231-.146.55-.146.639 0 .819.51.819.51a.52.52 0 0 0 .956-.405s-.38-.82-1.256-1.07v-.072a.519.519 0 0 0-.575-.527zm-7.202 2.341a.778.778 0 1 0 0 1.556.778.778 0 0 0 0-1.556zm14.517 0a.778.778 0 1 0 0 1.556.778.778 0 0 0 0-1.556z" />
      </g>
    </svg>
  );
};

MoneyIcon.defaultProps = {
  size: 24
};

export default MoneyIcon;
