import { OPEN_CONFIRM_MODAL, CLOSE_CONFIRM_MODAL } from './types';

export const openConfirmModal = (actionType = null, eventId = null) => ({
  type: OPEN_CONFIRM_MODAL,
  actionType,
  eventId
});

export const closeConfirmModal = () => ({
  type: CLOSE_CONFIRM_MODAL
});
