import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  CLIENT_NAME_PLACEHOLDER,
  CLIENT_NAME_LABEL,
  CLIENT_NAME_REQUIRED
} from 'src/v2/Drawers/EventFormDrawer/constants';
import { TextFieldWithLabel } from 'src/v2/UIComponents/FormComponents';

const RootClientNameInput = props => {
  const clientNameProps = {
    name: 'clientName',
    isRequired: true,
    label: CLIENT_NAME_LABEL,
    placeholder: CLIENT_NAME_PLACEHOLDER,
    rules: {
      required: CLIENT_NAME_REQUIRED
    }
  };

  return <TextFieldWithLabel {...clientNameProps} {...props} />;
};

export const ClientNameInput = React.memo(RootClientNameInput);
