import { TabPanel } from '@chakra-ui/react';
import { memo } from 'react';
import { CustomTabPanelProps } from 'src/Types/GlobalTypes';

export const CustomTabPanel = memo<CustomTabPanelProps>(({ content, ...props }) => {
  return (
    <TabPanel height="100%" p={0} {...props}>
      {content}
    </TabPanel>
  );
});

CustomTabPanel.displayName = 'CustomTabPanel';
