import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import {
  CREATE_BUDGET_ITEM_CATEGORY,
  CREATE_BUDGET_ITEM,
  FETCH_BUDGET_ITEM_CATEGORIES,
  FETCH_BUDGET_ITEMS,
  UPDATE_BUDGET_ITEM,
  DELETE_BUDGET_ITEM,
  EDIT_BUDGET_ITEM_CATEGORY,
  DELETE_BUDGET_ITEM_CATEGORY,
  BATCH_DELETE_BUDGET_ITEMS
} from './types';
import {
  createBudgetItemCategoryFailure,
  createBudgetItemCategorySuccess,
  createBudgetItemSuccess,
  createBudgetItemFailure,
  fetchBudgetItemCategoriesSuccess,
  fetchBudgetItemCategoriesFailure,
  fetchBudgetItemsSuccess,
  fetchBudgetItemsRequest,
  fetchBudgetItemsFailure,
  updateBudgetItemSuccess,
  updateBudgetItemFailure,
  deleteBudgetItemSuccess,
  deleteBudgetItemFailure,
  editBudgetItemCategoryFailure,
  editBudgetItemCategorySuccess,
  deleteBudgetItemCategorySuccess,
  fetchBudgetItemCategoriesRequest,
  deleteBudgetItemCategoryFailure,
  batchDeleteBudgetItemsSuccess,
  batchDeleteBudgetItemsFailure
} from './operations';
import {
  createBudgetItemCategoryApi,
  createBudgetItemApi,
  fetchBudgetItemCategoriesApi,
  fetchBudgetItemsApi,
  updateBudgetItemApi,
  deleteBudgetItemApi,
  editBudgetItemCategoryApi,
  deleteBudgetItemCategoryApi,
  batchDeleteBudgetItemsApi
} from './actions';

const getToken = state => state.loginReducer.token;

// Workers
function* workerCreateBudgetItemCategory(action) {
  try {
    const token = yield select(getToken);
    const { name } = action;
    const response = yield call(createBudgetItemCategoryApi, { token, name });
    if (response.data && response.data.success) {
      yield put(
        createBudgetItemCategorySuccess(
          response.data && response.data.success,
          response.data && response.data.category
        )
      );
      yield put(fetchBudgetItemCategoriesRequest());
    }
  } catch (error) {
    yield put(createBudgetItemCategoryFailure(error.response.data));
  }
}

function* workerEditBudgetItemCategory(action) {
  try {
    const token = yield select(getToken);
    const { name, categoryId } = action;
    const response = yield call(editBudgetItemCategoryApi, { token, categoryId, data: { name } });
    if (response.data && response.data.success) {
      yield put(
        editBudgetItemCategorySuccess(
          response.data && response.data.success,
          response.data && response.data.category
        )
      );
      yield delay(300);
      yield put(fetchBudgetItemCategoriesRequest());
      yield put(fetchBudgetItemsRequest());
    }
  } catch (error) {
    yield put(editBudgetItemCategoryFailure(error.response.data));
  }
}

function* workerDeleteBudgetItemCategory(action) {
  try {
    const token = yield select(getToken);
    const { categoryId, deleteType } = action;
    const response = yield call(deleteBudgetItemCategoryApi, { token, categoryId, deleteType });
    if (response.data && response.data.success) {
      yield put(deleteBudgetItemCategorySuccess(response.data && response.data.success));
      yield delay(300);
      yield put(fetchBudgetItemCategoriesRequest());
      yield put(fetchBudgetItemsRequest());
    }
  } catch (error) {
    yield put(deleteBudgetItemCategoryFailure(error.response.data));
  }
}

function* workerCreateBudgetItem(action) {
  try {
    const token = yield select(getToken);
    const { name, totalAmount, dueDate, category, installments } = action;
    const response = yield call(createBudgetItemApi, {
      token,
      data: { name, totalAmount, dueDate, category, installments }
    });
    yield put(
      createBudgetItemSuccess(
        response.data && response.data.success,
        response.data && response.data.item
      )
    );
    if (response.data && response.data.success) {
      yield put(fetchBudgetItemsRequest());
    }
  } catch (error) {
    yield put(createBudgetItemFailure(error.response.data));
  }
}

function* workerUpdateBudgetItem(action) {
  try {
    const token = yield select(getToken);
    const { name, totalAmount, dueDate, category, installments, itemId } = action;
    const response = yield call(updateBudgetItemApi, {
      token,
      data: { name, totalAmount, dueDate, category, installments },
      itemId
    });
    if (response.data && response.data.success) {
      yield put(
        updateBudgetItemSuccess(
          response.data && response.data.success,
          response.data && response.data.item
        )
      );
      yield put(fetchBudgetItemsRequest());
    }
  } catch (error) {
    yield put(updateBudgetItemFailure(error.response.data));
  }
}

function* workerDeleteBudgetItem(action) {
  try {
    const token = yield select(getToken);
    const { itemId } = action;
    const response = yield call(deleteBudgetItemApi, { token, itemId });
    if (response.data && response.data.success) {
      yield put(deleteBudgetItemSuccess(response.data && response.data.success));
      yield put(fetchBudgetItemsRequest());
    }
  } catch (error) {
    yield put(deleteBudgetItemFailure(error.response.data));
  }
}

function* workerFetchBudgetItemCategories() {
  try {
    const token = yield select(getToken);
    const response = yield call(fetchBudgetItemCategoriesApi, { token });
    yield put(
      fetchBudgetItemCategoriesSuccess(
        response.data && response.data.success,
        response.data && response.data.items
      )
    );
  } catch (error) {
    yield put(fetchBudgetItemCategoriesFailure(error.response.data));
  }
}

function* workerFetchBudgetItems() {
  try {
    const token = yield select(getToken);
    const response = yield call(fetchBudgetItemsApi, { token });
    yield put(
      fetchBudgetItemsSuccess(
        response.data && response.data.success,
        response.data && response.data.items
      )
    );
  } catch (error) {
    yield put(fetchBudgetItemsFailure(error.response.data));
  }
}

function* workerBatchDeleteBudgetItems(action) {
  try {
    const token = yield select(getToken);
    const { items } = action;
    const response = yield call(batchDeleteBudgetItemsApi, { token, data: { items } });
    if (response.data && response.data.success) {
      yield put(batchDeleteBudgetItemsSuccess(response.data && response.data.success));
      yield put(fetchBudgetItemsRequest());
    }
  } catch (error) {
    yield put(batchDeleteBudgetItemsFailure(error.response.data));
  }
}

// Watchers
export function* watchCreateBudgetItemCategory() {
  yield takeLatest(CREATE_BUDGET_ITEM_CATEGORY, workerCreateBudgetItemCategory);
}
export function* watchEditBudgetItemCategory() {
  yield takeLatest(EDIT_BUDGET_ITEM_CATEGORY, workerEditBudgetItemCategory);
}
export function* watchDeleteBudgetItemCategory() {
  yield takeLatest(DELETE_BUDGET_ITEM_CATEGORY, workerDeleteBudgetItemCategory);
}
export function* watchCreateBudgetItem() {
  yield takeLatest(CREATE_BUDGET_ITEM, workerCreateBudgetItem);
}
export function* watchUpdateBudgetItem() {
  yield takeLatest(UPDATE_BUDGET_ITEM, workerUpdateBudgetItem);
}
export function* watchDeleteBudgetItem() {
  yield takeLatest(DELETE_BUDGET_ITEM, workerDeleteBudgetItem);
}
export function* watchBatchDeleteBudgetItems() {
  yield takeLatest(BATCH_DELETE_BUDGET_ITEMS, workerBatchDeleteBudgetItems);
}
export function* watchFetchBudgetItemCategories() {
  yield takeLatest(FETCH_BUDGET_ITEM_CATEGORIES, workerFetchBudgetItemCategories);
}
export function* watchFetchBudgetItems() {
  yield takeLatest(FETCH_BUDGET_ITEMS, workerFetchBudgetItems);
}
