import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export function getStyles({ venueId }) {
  return axios({
    method: 'get',
    url: `${apiUri}/venues/${venueId}/styles`
  });
}
