import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export const fetchVendorCategoriesApi = ({ token }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/vendorCategory`,
    headers: { Authorization: token }
  });
};

export const fetchVendorsApi = ({ token, category }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/vendors`,
    headers: { Authorization: token },
    params: { category }
  });
};
