import { Icon } from '@chakra-ui/react';
import { MdOutlineFileDownload } from 'react-icons/md';
import {
  useDocumentLibrary,
  useEventsDetails,
  useEvents,
  useUser,
  useDocuments
} from 'src/v2/Hooks/CollectionHooks';
import moment from 'moment';
import { Document } from 'src/v2/UIComponents/Primitives/Icons';
import { TableCard } from 'src/v2/UIComponents/Cards/TableCard';
import { useDrawers } from 'src/v2/Hooks/HelperHooks';
import { EventTableDocument } from 'venuex-types';
import { useHistory } from 'react-router-dom';
import { EventDocumentDrawer } from 'src/v2/Drawers/EventDocumentDrawer';

export const DocumentsCard = () => {
  const { role } = useUser();
  const { activeEvent, fetchingEvent } = useEvents();
  const { downloadDocument } = useDocuments();
  const { setActiveItem } = useEventsDetails();
  const { documents: unfilteredDocuments } = activeEvent;
  const { openEventDocumentDrawer } = useDrawers();

  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Updated', key: 'updated' },
    { label: 'Actions', key: 'actions' }
  ];

  const documents = unfilteredDocuments?.filter((i): i is EventTableDocument => {
    return typeof i !== 'undefined';
  });

  const rows = documents?.map(row => {
    const rowUpdatedDate = row?.updated && moment(row.updated).format('L');
    const dateValue = row?.created && moment(row.created).format('L');
    const updatedDate = rowUpdatedDate || dateValue;
    const isFormDoc = false;
    const { documentId } = row;

    const action = isFormDoc ? (
      <Icon
        as={MdOutlineFileDownload}
        w={6}
        h={6}
        color="var(--colors-primary)"
        cursor="pointer"
        onClick={() =>
          downloadDocument({
            eventId: activeEvent.id,
            docId: row.documentId,
            filename: row.filename,
            url: row.url,
            venueId: activeEvent.venueId
          })
        }
      />
    ) : (
      <a href={row?.url} target="_blank" rel="noopener noreferrer" download>
        <Icon as={MdOutlineFileDownload} w={6} h={6} color="var(--colors-primary)" />
      </a>
    );

    return {
      name: row?.label,
      updated: updatedDate,
      actions: action
    };
  });
  const history = useHistory();
  const navigate = () => history.push('/documents');
  const viewAllAction =
    role === 'host' ? () => navigate() : () => setActiveItem({ activeItem: 'documents' });

  return (
    <>
      <EventDocumentDrawer />
      <TableCard
        title="Documents"
        titleIcon={<Document />}
        headers={headers}
        rows={rows}
        headerBtnLabel="View All"
        headerBtnAction={viewAllAction}
        fetching={fetchingEvent}
        emptyStateProps={{
          title: 'No Documents',
          btnLabel: 'Add A Document',
          btnAction: openEventDocumentDrawer
        }}
        headerBtnIcon={undefined}
      />
    </>
  );
};
