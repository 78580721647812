import { TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { scrollBarProps } from '../../ScrollBar/scrollBarProps';
import { BaseCustomTab, CustomTab } from './CustomTab';
import { CustomTabPanel } from './CustomTabPanel';

export const TabFormWrapper = ({
  tabList,
  defaultIndex,
  index,
  setTabIndex,
  panelSpacing,
  isForm = true
}: {
  tabList: any;
  defaultIndex?: any;
  index?: any;
  setTabIndex?: any;
  panelSpacing?: any;
  isForm?: boolean;
}) => {
  return (
    <Tabs
      variant="enclosed-colored"
      height="100%"
      isFitted
      display="flex"
      flexDirection="column"
      defaultIndex={defaultIndex}
      isLazy={false}
      index={index}
      onChange={setTabIndex}
      overflowY="auto"
    >
      <TabList>
        {tabList?.map(
          tab =>
            (isForm && <CustomTab key={tab.title} title={tab.title} fields={tab.fields} />) || (
              <BaseCustomTab key={tab.title} title={tab.title} />
            )
        )}
      </TabList>
      <TabPanels marginTop={panelSpacing} height="100%" overflowY="scroll" p="0">
        {tabList?.map((tab, i) => {
          return <CustomTabPanel key={`${tab.title}-tab-panel`} content={tab.tab} />;
        })}
      </TabPanels>
    </Tabs>
  );
};
