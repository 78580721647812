import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

// action types
import {
  OPEN_EDIT_GUEST_MODAL,
  CLOSE_EDIT_GUEST_MODAL,
  OPEN_DELETE_GUEST_MODAL,
  CLOSE_DELETE_GUEST_MODAL,
  OPEN_ADD_GROUP_MODAL,
  CLOSE_ADD_GROUP_MODAL,
  GET_GROUPS_REQUEST,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAILURE,
  ADD_GROUP_REQUEST,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILURE,
  OPEN_ADD_GUEST_MODAL,
  CLOSE_ADD_GUEST_MODAL,
  RESET_EDITED_GUEST,
  RESET_ADDDED_GROUP,
  BATCH_EDIT_GUESTS_FAILURE,
  BATCH_EDIT_GUESTS_SUCCESS,
  BATCH_EDIT_GUESTS_REQUEST,
  OPEN_DELETE_MULTIPLE_GUESTS_MODAL,
  CLOSE_DELETE_MULTIPLE_GUESTS_MODAL,
  BATCH_DELETE_GUESTS_REQUEST,
  BATCH_DELETE_GUESTS_FAILURE,
  BATCH_DELETE_GUESTS_SUCCESS,
  RESET_DELETED_GUEST,
  GET_EXPORT_DATA_FAILURE,
  GET_EXPORT_DATA_REQUEST,
  GET_EXPORT_DATA_SUCCESS,
  RESET_EXPORT_DATA,
  OPEN_EDIT_GROUP_MODAL,
  CLOSE_EDIT_GROUP_MODAL,
  OPEN_DELETE_GROUP_MODAL,
  CLOSE_DELETE_GROUP_MODAL,
  SET_SELECTED_GROUP,
  EDIT_GROUP_FAILURE,
  EDIT_GROUP_REQUEST,
  EDIT_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  RESET_EDITED_GROUP,
  RESET_DELETED_GROUP,
  SET_SEARCH_TERM,
  SET_SORT_FIELD,
  SET_SORT_VALUE,
  SET_RSVP_FILTER,
  SET_MEALOPTIONS_FILTER,
  SET_TABLE_FILTER,
  RESET_EDITED_GUESTS,
  RESET_DELETED_GUESTS,
  SET_GROUP_FILTER,
  HOST_UPDATE_EVENT_REQUEST,
  HOST_UPDATE_EVENT_SUCCESS,
  HOST_UPDATE_EVENT_FAILURE,
  HOST_SEND_EMAIL_REQUEST,
  HOST_SEND_EMAIL_SUCCESS,
  HOST_SEND_EMAIL_FAILURE,
  OPEN_SEND_BATCH_EMAIL_MODAL,
  CLOSE_SEND_BATCH_EMAIL_MODAL,
  HOST_SEND_BATCH_EMAIL_SUCCESS,
  HOST_SEND_BATCH_EMAIL_FAILURE,
  HOST_SEND_BATCH_EMAIL_REQUEST,
  GET_LAYOUT_REQUEST,
  GET_LAYOUT_SUCCESS,
  GET_LAYOUT_FAILURE
} from './types';
import { FETCH_GUESTS_REQUEST } from '../SeatingChartContainer/types';

// reducer with initial state
const initialState = {
  guests: [],
  fetching: false,
  fetchingGuests: false,
  error: null,
  editModalOpen: false,
  deleteModalOpen: false,
  addGroupModalOpen: false,
  deleteGuestError: null,
  deleteGuestSuccess: null,
  addGroupLoading: false,
  addGroupError: null,
  addedGroup: null,
  groups: [],
  fetchingGroups: false,
  groupsError: null,
  addGuestModalOpen: false,
  addGuestLoading: false,
  addGuestError: null,
  addedGuest: null,
  batchEditLoading: false,
  batchEditSuccess: null,
  batchEditError: null,
  batchDeleteLoading: false,
  batchDeleteSuccess: false,
  batchDeleteError: null,
  deleteMultipleModalOpen: false,
  fetchingExportData: false,
  exportData: null,
  exportDataError: null,
  editGroupModalOpen: false,
  editGroupLoading: false,
  editedGroup: null,
  editGroupError: null,
  deleteGroupModalOpen: false,
  deleteGroupLoading: false,
  deleteGroupSuccess: null,
  deleteGroupError: null,
  selectedGroup: null,
  searchTerm: '',
  sortField: 'name',
  sortValue: 'asc',
  rsvpFilter: 'all',
  tableFilter: '',
  hostUpdatingEvent: false,
  hostUpdateEventSuccess: null,
  hostUpdateEventFailure: null,
  hostSendingEmail: false,
  sendButtonRowIndex: null,
  hostSendEmailSuccess: null,
  hostSendEmailFailure: null,
  filterMeals: 'all',

  isSendingBatch: false,
  isSentBatch: false,
  sendBatchEmailModalOpen: false
};

const persistConfig = {
  key: 'guestlist',
  storage,
  blacklist: ['token', 'refresh', 'expires', 'role', 'claims', 'admin']
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_EDIT_GUEST_MODAL:
      return {
        ...state,
        editModalOpen: true
      };
    case CLOSE_EDIT_GUEST_MODAL:
      return {
        ...state,
        editModalOpen: false
      };
    case OPEN_DELETE_GUEST_MODAL:
      return {
        ...state,
        deleteModalOpen: true
      };
    case CLOSE_DELETE_GUEST_MODAL:
      return {
        ...state,
        deleteModalOpen: false
      };
    case OPEN_ADD_GROUP_MODAL:
      return {
        ...state,
        addGroupModalOpen: true
      };
    case CLOSE_ADD_GROUP_MODAL:
      return {
        ...state,
        addGroupModalOpen: false
      };
    case GET_GROUPS_REQUEST:
      return {
        ...state,
        fetchingGroups: true,
        groupsError: null,
        deleteGuestSuccess: null,
        batchEditSuccess: null,
        batchDeleteSuccess: null,
        batchDeleteError: null,
        batchEditError: null,
        addGroupError: null
      };
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        fetchingGroups: false,
        groups: action.groups
      };
    case GET_GROUPS_FAILURE:
      return {
        ...state,
        fetchingGroups: false,
        groupsError: action.error
      };
    case ADD_GROUP_REQUEST:
      return {
        ...state,
        addGroupLoading: true,
        addGroupError: null
      };
    case ADD_GROUP_FAILURE:
      return {
        ...state,
        addGroupLoading: false,
        addedGroup: null,
        addGroupError: action.error
      };
    case ADD_GROUP_SUCCESS:
      return {
        ...state,
        addGroupLoading: false,
        addedGroup: action.group,
        groups: [...state.groups, action.group],
        addGroupError: null
      };
    case OPEN_ADD_GUEST_MODAL:
      return {
        ...state,
        addGuestModalOpen: true
      };
    case CLOSE_ADD_GUEST_MODAL:
      return {
        ...state,
        addGuestModalOpen: false
      };
    case RESET_EDITED_GUEST: // TODO move to guescontainer
      return {
        ...state,
        editedGuest: null
      };
    case RESET_ADDDED_GROUP:
      return {
        ...state,
        addedGroup: null
      };
    case BATCH_EDIT_GUESTS_REQUEST:
      return {
        ...state,
        batchEditLoading: true,
        batchEditError: null,
        batchEditSuccess: false
      };
    case BATCH_EDIT_GUESTS_SUCCESS:
      return {
        ...state,
        batchEditLoading: false,
        guests: state.guests.map(guest => {
          if (action.guests.indexOf(guest.id) !== -1) {
            return { ...guest, ...action.data };
          }
          return guest;
        }),
        batchEditSuccess: action.success
      };
    case BATCH_EDIT_GUESTS_FAILURE:
      return {
        ...state,
        batchEditLoading: false,
        batchEditError: action.error
      };
    case OPEN_DELETE_MULTIPLE_GUESTS_MODAL:
      return {
        ...state,
        deleteMultipleModalOpen: true
      };
    case CLOSE_DELETE_MULTIPLE_GUESTS_MODAL:
      return {
        ...state,
        deleteMultipleModalOpen: false
      };
    case BATCH_DELETE_GUESTS_REQUEST:
      return {
        ...state,
        batchDeleteLoading: true,
        batchDeleteError: null,
        batchDeleteSuccess: false
      };
    case BATCH_DELETE_GUESTS_FAILURE:
      return {
        ...state,
        batchDeleteLoading: false,
        batchDeleteError: action.error
      };
    case BATCH_DELETE_GUESTS_SUCCESS:
      return {
        ...state,
        batchDeleteLoading: false,
        batchDeleteSuccess: action.success,
        guests: state.guests.filter(guest => action.guests.indexOf(guest.id) === -1)
      };
    case RESET_DELETED_GUEST:
      return {
        ...state,
        deleteGuestSuccess: null
      };
    case GET_EXPORT_DATA_REQUEST:
      return {
        ...state,
        fetching: true,
        fetchingExportData: true,
        exportData: null,
        exportDataError: null
      };
    case GET_EXPORT_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetchingExportData: false,
        exportData: action.data
      };
    case GET_EXPORT_DATA_FAILURE:
      return {
        ...state,
        fetching: false,
        fetchingExportData: false,
        exportDataError: action.error
      };
    case RESET_EXPORT_DATA:
      return {
        ...state,
        exportData: null
      };
    case SET_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: action.group
      };
    case OPEN_EDIT_GROUP_MODAL:
      return {
        ...state,
        editGroupModalOpen: true
      };
    case CLOSE_EDIT_GROUP_MODAL:
      return {
        ...state,
        selectedGroup: {},
        editGroupModalOpen: false
      };
    case OPEN_DELETE_GROUP_MODAL:
      return {
        ...state,
        deleteGroupModalOpen: true
      };
    case CLOSE_DELETE_GROUP_MODAL:
      return {
        ...state,
        selectedGroup: {},
        deleteGroupModalOpen: false
      };
    case EDIT_GROUP_REQUEST:
      return {
        ...state,
        editGroupLoading: true,
        editedGroup: null,
        editGroupError: null
      };
    case EDIT_GROUP_FAILURE:
      return {
        ...state,
        editGroupLoading: false,
        editedGroup: null,
        editGroupError: action.error
      };
    case EDIT_GROUP_SUCCESS:
      return {
        ...state,
        editGroupLoading: false,
        editedGroup: action.group,
        editGroupError: null
      };
    case DELETE_GROUP_REQUEST:
      return {
        ...state,
        deleteGroupLoading: true,
        deleteGroupSuccess: null,
        deleteGroupError: null
      };
    case DELETE_GROUP_FAILURE:
      return {
        ...state,
        deleteGroupLoading: false,
        batchDeleteLoading: false,
        deleteGroupSuccess: null,
        deleteGroupError: action.error
      };
    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        deleteGroupLoading: false,
        batchDeleteLoading: false,
        deleteGroupSuccess: true,
        deleteGroupError: null
      };
    case RESET_EDITED_GROUP:
      return {
        ...state,
        editedGroup: null,
        editGroupError: null
      };
    case RESET_DELETED_GROUP:
      return {
        ...state,
        deleteGroupSuccess: null,
        deleteGroupError: null
      };
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm
      };
    case SET_SORT_FIELD:
      return {
        ...state,
        sortField: action.sortField
      };
    case SET_SORT_VALUE:
      return {
        ...state,
        sortValue: action.sortValue
      };
    case SET_RSVP_FILTER:
      return {
        ...state,
        rsvpFilter: action.rsvpFilter
      };
    case SET_MEALOPTIONS_FILTER:
      return {
        ...state,
        filterMeals: action.filterMeals
      };
    case SET_TABLE_FILTER:
      return {
        ...state,
        tableFilter: action.tableFilter
      };
    case SET_GROUP_FILTER:
      return {
        ...state,
        groupFilter: action.groupFilter
      };
    case RESET_EDITED_GUESTS:
      return {
        ...state,
        batchEditError: null,
        batchEditSuccess: false
      };
    case RESET_DELETED_GUESTS:
      return {
        ...state,
        batchDeleteError: null,
        batchDeleteSuccess: false
      };
    case FETCH_GUESTS_REQUEST:
      return {
        ...state,
        editedGroup: null,
        editGroupError: null
      };
    case HOST_UPDATE_EVENT_REQUEST:
      return {
        ...state,
        hostUpdatingEvent: true
      };
    case HOST_UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        hostUpdatingEvent: false,
        hostUpdateEventSuccess: action.hostUpdateSuccess
      };
    case HOST_UPDATE_EVENT_FAILURE:
      return {
        ...state,
        hostUpdateEventFailed: false,
        hostUpdateEventFailure: action.hostUpdateEventFailed
      };
    case HOST_SEND_EMAIL_REQUEST:
      return {
        ...state,
        hostSendingEmail: true,
        sendButtonRowIndex: action.sendEmail.guestDetails.rowIndex
      };
    case HOST_SEND_EMAIL_SUCCESS:
      return {
        ...state,
        hostSendingEmail: false,
        sendButtonRowIndex: null,
        hostSendEmailFailure: null,
        hostSendEmailSuccess: action.hostSendEmailSuccess
      };
    case HOST_SEND_EMAIL_FAILURE:
      return {
        ...state,
        hostSendingEmail: false,
        sendButtonRowIndex: null,
        hostSendEmailSuccess: null,
        hostSendEmailFailure: action.hostSendEmailFailed
      };
    case HOST_SEND_BATCH_EMAIL_REQUEST:
      return {
        ...state,
        isSendingBatch: true,
        isSentBatch: false
      };
    case HOST_SEND_BATCH_EMAIL_SUCCESS:
      return {
        ...state,
        isSendingBatch: false,
        isSentBatch: true
      };
    case HOST_SEND_BATCH_EMAIL_FAILURE:
      return {
        ...state,
        isSendingBatch: false,
        isSentBatch: false
      };
    case OPEN_SEND_BATCH_EMAIL_MODAL:
      return {
        ...state,
        isSendingBatch: false,
        isSentBatch: false,
        sendBatchEmailModalOpen: true
      };
    case CLOSE_SEND_BATCH_EMAIL_MODAL:
      return {
        ...state,
        sendBatchEmailModalOpen: false
      };
    case GET_LAYOUT_REQUEST:
      return {
        ...state,
        error: null,
        fetchingLayout: true,
        layout: null
      };
    case GET_LAYOUT_SUCCESS:
      return {
        ...state,
        layout: action.layout,
        fetchingLayout: false,
        error: null
      };
    case GET_LAYOUT_FAILURE:
      return {
        ...state,
        error: action.error,
        fetchingLayout: false,
        layout: null
      };
    default:
      return state;
  }
};

const guestListReducer = persistReducer(persistConfig, reducer);

export default guestListReducer;
