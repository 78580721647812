import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import LogRocket from 'logrocket';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';

import { addEventModalReducer } from './containers/AddEventModal/reducers';
import {
  watchAddEvent,
  watchCheckEventCode,
  watchCheckHostEmail,
  watchGetLayouts,
  watchGetUsers,
  watchSetLeadType,
  watchUpdateEvent,
  watchUpdateUser
} from './containers/AddEventModal/sagas';
import { appContainerReducer } from './containers/AppContainer/reducers';
import { budgetPlannerReducer } from './containers/BudgetPlannerContainer/reducer';
import {
  watchBatchDeleteBudgetItems,
  watchCreateBudgetItem,
  watchCreateBudgetItemCategory,
  watchDeleteBudgetItem,
  watchDeleteBudgetItemCategory,
  watchEditBudgetItemCategory,
  watchFetchBudgetItemCategories,
  watchFetchBudgetItems,
  watchUpdateBudgetItem
} from './containers/BudgetPlannerContainer/sagas';
import changeGroupNameReducer from './containers/ChangeGroupNameModal/reducers';
import changePasswordReducer from './containers/ChangePasswordModal/reducers';
import { watchResetUserPassword } from './containers/ChangePasswordModal/sagas';
import checklistReducer from './containers/ChecklistContainer/reducers';
import {
  watchCreateChecklistItem,
  watchDeleteChecklistItem,
  watchGetChecklistItems,
  watchImportChecklistItems,
  watchUpdateChecklistItem,
  watchUpdateHostChecklistItem
} from './containers/ChecklistContainer/sagas';
import { confirmModalReducer } from './containers/ConfirmModal/reducers';
import { customObjectsReducer } from './containers/CustomObjectsContainer/reducers';
import {
  watchAddCustomObjectRequest,
  watchDeleteCustomObjectRequest,
  watchGetCustomObjectsRequest,
  watchUpdateCustomObjectCategory,
  watchUpdateCustomObjectRequest
} from './containers/CustomObjectsContainer/sagas';
import dashboardReducer from './containers/DashboardContainer/reducers';
import {
  watcherCreateIdea,
  watcherCreateNote,
  watcherDeleteEventIdea,
  watcherDeleteNote,
  watcherEditEventIdea,
  watcherEditEventPhoto,
  watcherEditNote,
  watcherEditProfileAvatar,
  watcherEvent,
  watcherIdeas,
  watcherNotes,
  watcherUpdateEventContacts,
  watcherUploadImageToCloud,
  watcherVenueStaff
} from './containers/DashboardContainer/sagas';
import { documentLibraryReducer } from './containers/DocumentLibrary/reducers';
import {
  watcherDeleteDocumentFromLibrary,
  watcherDownloadDocumentLibrary,
  watcherGetDocumentLibrary,
  watcherGetDocumentLibraryById,
  watcherUpdateDocumentToLibrary,
  watcherUploadDocumentToLibrary
} from './containers/DocumentLibrary/sagas';
import { documentsReducer } from './containers/DocumentsContainer/reducers';
import {
  watchAutoFillDocument,
  watchCommentDocument,
  watchDeleteDocument,
  watchDocumentDownload,
  watchDocumentUpload,
  watchGetDocument,
  watchGetDocuments,
  watchUpdateDocument
} from './containers/DocumentsContainer/sagas';
import venueValuesReducer from './containers/DynamicValuesContainer/reducers';
import { watcherVenueValues } from './containers/DynamicValuesContainer/sagas';
import { eventDetailsModalReducer } from './containers/EventDetailsModal/reducers';
import {
  watcherGeneratePdfFile,
  watcherRequestEventPacket,
  watchGetHosts,
  watchGetLayoutsDetails,
  watchGetRoomDetails,
  watchSendVenueToHostEmail,
  watchSetPaymentStatus
} from './containers/EventDetailsModal/sagas';
import eventsReducer from './containers/EventsContainer/reducers';
import {
  watchDeleteEvent,
  watcherGetEvent,
  watcherGetEventAnalytics,
  watcherGetEvents,
  watcherGetMoreEvents,
  watcherGetUserTasks,
  watchSearchEvent,
  watchUpdateEventChecklistItem,
  watchUpdateEventNote
} from './containers/EventsContainer/sagas';
import { eventVendorsReducer } from './containers/EventVendorsContainer/reducers';
import {
  watchAddEventVendor,
  watchDeleteEventVendor,
  watchFetchEventVendors,
  watchGetEventVendors,
  watchUpdateEventVendor
} from './containers/EventVendorsContainer/sagas';
import { FileModalReducer } from './containers/FileModal/reducers';
import { watcherParseTable, watcherSaveGuests } from './containers/FileModal/sagas';
import { forgotReducer } from './containers/ForgotPassword/reducers';
import { watcherForgot, watcherRemind } from './containers/ForgotPassword/sagas';
import guestReducer from './containers/GuestContainer/reducers';
import {
  watcherAddGuest,
  watcherDeleteGuest,
  watcherEditGuest,
  watcherGenerateExcelFile,
  watcherGuests
} from './containers/GuestContainer/sagas';
import guestListReducer from './containers/GuestListContainer/reducers';
import {
  watcherAddGroup,
  watcherDeleteGroup,
  watcherDeleteGuestBatch,
  watcherEditGroup,
  watcherEditGuestBatch,
  watcherExportData,
  watcherGroups,
  watcherHostSendEmailAndUpdateEvent,
  watcherHostUpdateEvent,
  watcherSendBatchEmail
} from './containers/GuestListContainer/sagas';
import { layoutsReducer } from './containers/LayoutsContainer/reducers';
import {
  watchAddLayout as watchLayoutAddLayout,
  watchDeleteLayout as watchLayoutDeleteLayout,
  watchGetLayoutById,
  watchGetLayoutOptions,
  watchGetLayouts as watchLayoutGetLayouts,
  watchUpdateLayout as watchLayoutUpdateLayout,
  watchUploadLayout,
  watchDuplicateLayout
} from './containers/LayoutsContainer/sagas';
import { layoutsEditorReducer } from './containers/LayoutsEditorContainer/reducers';
import { watchSaveLayout, watchUpdateStatus } from './containers/LayoutsEditorContainer/sagas';
import { leadsReducer } from './containers/LeadsContainer/reducers';
import {
  watchAddLead,
  watchAddNote,
  watchDeleteLead,
  watcherGetLead,
  watcherGetLeads,
  watchUpdateLead
} from './containers/LeadsContainer/sagas';
import loginReducer from './containers/LoginContainer/reducers';
import {
  watcherLogin,
  watcherLogout,
  watcherPolicyAccept,
  watcherRefresh
} from './containers/LoginContainer/sagas';
import getStaffReducer from './containers/ManageStaffContainer/reducers';
import {
  watchAddStaff,
  watchDeleteStaff,
  watchGetStaff,
  watchGetStaffEvents,
  watchUpdateStaff
} from './containers/ManageStaffContainer/sagas';
import memoriesReducer from './containers/MemoriesContainer/reducers';
import {
  watchAddModerators,
  watchBatchDelete,
  watchCreateMemory,
  watchDeleteMemory,
  watchDeleteModerator,
  watchEditMemory,
  watchEditMobilePhoto,
  watchFetchMemories,
  watchGetModerators
} from './containers/MemoriesContainer/sagas';
import { modalLoaderReducer } from './containers/ModalLoader/reducers';
import { overlayLoaderReducer } from './containers/OverlayLoader/reducers';
import { paymentsReducer } from './containers/PaymentsContainer/reducers';
import { registerUserReducer } from './containers/RegisterContainer/reducers';
import { watchRegisterUser } from './containers/RegisterContainer/sagas';
import { resetPasswordReducer } from './containers/ResetPassword/reducers';
import { watcherReset, watcherResetPassword } from './containers/ResetPassword/sagas';
import { roomsReducer } from './containers/RoomsContainer/reducers';
import { watchGetRooms } from './containers/RoomsContainer/sagas';
import seatingChartReducer from './containers/SeatingChartContainer/reducers';
import {
  watchChangeLayoutName,
  watchChangeUserTable,
  watchFetchGuests,
  watchFilterGuests,
  watchGetLayout,
  watchResetAllTables,
  watchSubmitNewSeatingChart,
  watchSubmitSeatingChart,
  watchUpdateLayout
} from './containers/SeatingChartContainer/sagas';
import staffDetailsReducer from './containers/StaffDetailsModal/reducers';
import { watchGetStaffDetails } from './containers/StaffDetailsModal/sagas';
import { tablesReducer } from './containers/TablesContainer/reducers';
import {
  watchAddTableRequest,
  watchDeleteTableRequest,
  watchGetTablesRequest,
  watchUpdateTableRequest,
  watchUpdateTablesCategory
} from './containers/TablesContainer/sagas';
import { tutorialsReducer } from './containers/TutorialsContainer/reducers';
import {
  watcherGetHostTutorialDetails,
  watcherUpdateHostTutorials
} from './containers/TutorialsContainer/sagas';
import { uploadButtonReducer } from './containers/UploadButton/reducers';
import { watcherCheckCSV, watcherResetCSV } from './containers/UploadButton/sagas';
import { vendorsListReducer } from './containers/VendorListContainer/reducers';
import {
  watchFetchVendorCategories,
  watchFetchVendors
} from './containers/VendorListContainer/sagas';
import vendorsReducer from './containers/VendorsContainer/reducers';
import { watcherContactVendor, watcherVendorList } from './containers/VendorsContainer/sagas';
import venuesReducer from './containers/VenuesContainer/reducers';
import {
  watcherCreateProduct,
  watcherCreateProductCategory,
  watcherCreateRateType,
  watcherCreateTaxRate,
  watcherEditVenue,
  watcherGetHostDetails,
  watcherUpdateTutorial,
  watcherVenueRead,
  watcherVenuesList
} from './containers/VenuesContainer/sagas';
import { customFieldsReducer } from './v2/Collections/customFields/reducer';
import {
  watcherCreateCustomField,
  watcherGetCustomFields,
  watcherUpdateCustomField
} from './v2/Collections/customFields/sagas';
import { proposalsReducer } from './v2/Collections/proposals/reducer';
import { watchGenerateContract, watchGenerateProposal } from './v2/Collections/proposals/sagas';
import { drawersReducer } from './v2/Drawers/Redux/reducer';
import { hostFormReducer } from './v2/Reducers/HostForm/reducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// import { AddGuestModalReducer } from './containers/AddGuestModal/reducers';
// import { watcherResetGuest, watcherSaveGuest } from './containers/AddGuestModal/sagas';

// get around some naming collisions
const sagaMiddleware = createSagaMiddleware();

// eslint-disable-next-line no-underscore-dangle

interface ComposeWithDevTools {
  <R>(...functions: Function[]): R;
  ({ trace }: { trace: boolean }): typeof compose;
}

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as ComposeWithDevTools)
  ? // eslint-disable-next-line no-underscore-dangle
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as ComposeWithDevTools)({
      trace: true
    })
  : compose;

// const persistConfig = {
//   key: 'root',
//   storage,
//   blacklist: ['modalLoaderReducer']
// };

// const loginPersistConfig = {
//   key: 'auth',
//   storage,
//   blacklist: ['token', 'refresh', 'expires', 'role', 'claim', 'admin', 'fetching']
// };

const dashboardReducerConfig = {
  key: 'dashboard',
  storage,
  whitelist: ['dashboardReducer']
};

const rootReducer = combineReducers({
  addEventModalReducer,
  appContainerReducer,
  budgetPlannerReducer,
  changeGroupNameReducer,
  changePasswordReducer,
  checklistReducer,
  confirmModalReducer,
  customFieldsReducer,
  customObjectsReducer,
  dashboardReducer: persistReducer(dashboardReducerConfig, dashboardReducer),
  documentLibraryReducer,
  documentsReducer,
  drawersReducer,
  eventDetailsModalReducer,
  eventsReducer,
  eventVendorsReducer,
  FileModalReducer,
  forgotReducer,
  getStaffReducer,
  guestListReducer,
  guestReducer,
  hostFormReducer,
  layoutsEditorReducer,
  layoutsReducer,
  leadsReducer,
  loginReducer,
  memoriesReducer,
  modalLoaderReducer,
  overlayLoaderReducer,
  paymentsReducer,
  proposalsReducer,
  registerUserReducer,
  resetPasswordReducer,
  roomsReducer,
  seatingChartReducer,
  staffDetailsReducer,
  tablesReducer,
  tutorialsReducer,
  uploadButtonReducer,
  vendorsListReducer,
  vendorsReducer,
  venuesReducer,
  venueValuesReducer
});

const middlewares = [sagaMiddleware, LogRocket.reduxMiddleware()];

// redux store
const store = createStore(
  // persistReducer(persistConfig, rootReducer),
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
function* root() {
  yield all(
    [
      watchSetLeadType,
      watcherLogin,
      watcherLogout,
      watcherRefresh,
      watcherPolicyAccept,
      watcherForgot,
      watcherReset,
      watcherResetPassword,
      watcherVenuesList,
      watcherVendorList,
      watcherContactVendor,
      watcherVenueValues,
      watcherVenueRead,
      watcherUpdateTutorial,
      watcherGetHostDetails,
      watcherGetEvents,
      watcherGetEventAnalytics,
      watchDeleteEvent,
      watchAddEvent,
      watchGetUsers,
      watchGetRooms,
      watchUpdateEvent,
      watchGetLayouts,
      watchRegisterUser,
      watcherEvent,
      watcherNotes,
      watcherCreateNote,
      watcherEditNote,
      watcherDeleteNote,
      watcherEditEventPhoto,
      watcherIdeas,
      watcherCreateIdea,
      watcherEditEventIdea,
      watcherDeleteEventIdea,
      watchGetStaff,
      watchUpdateStaff,
      watchGetStaffDetails,
      watchDeleteStaff,
      watchGetStaffEvents,
      watcherVenueStaff,
      watcherUpdateEventContacts,
      watcherEditGuest,
      watcherDeleteGuest,
      watcherGuests,
      watcherGroups,
      watcherAddGroup,
      watcherAddGuest,
      watcherEditGuestBatch,
      watcherDeleteGuestBatch,
      watcherCheckCSV,
      watcherResetCSV,
      watcherParseTable,
      watcherSaveGuests,
      watcherExportData,
      watcherEditGroup,
      watcherDeleteGroup,
      watcherHostSendEmailAndUpdateEvent,
      watcherHostUpdateEvent,
      watchFetchGuests,
      watchFilterGuests,
      watchChangeUserTable,
      watcherGetEvent,
      watchGetLayout,
      watchChangeLayoutName,
      watchResetAllTables,
      watcherGetMoreEvents,
      watchAddStaff,
      watcherUploadImageToCloud,
      watchGetRoomDetails,
      watchGetLayoutsDetails,
      watchUpdateUser,
      watchSubmitSeatingChart,
      watchSubmitNewSeatingChart,
      watcherEditProfileAvatar,
      watchResetUserPassword,
      watchGetHosts,
      watchGetEventVendors,
      watchFetchMemories,
      watchDeleteMemory,
      watchEditMemory,
      watchBatchDelete,
      watchCreateMemory,
      watchEditMobilePhoto,
      watchGetModerators,
      watchAddModerators,
      watchDeleteModerator,
      watchCheckEventCode,
      watchCreateBudgetItemCategory,
      watchCreateBudgetItem,
      watchFetchBudgetItemCategories,
      watchFetchBudgetItems,
      watchUpdateBudgetItem,
      watchDeleteBudgetItem,
      watchEditBudgetItemCategory,
      watchDeleteBudgetItemCategory,
      watchBatchDeleteBudgetItems,
      watchGetChecklistItems,
      watchImportChecklistItems,
      watchUpdateChecklistItem,
      watchCreateChecklistItem,
      watchUpdateHostChecklistItem,
      watchDeleteChecklistItem,
      watchCheckHostEmail,
      watchFetchVendorCategories,
      watchFetchVendors,
      watchFetchEventVendors,
      watchAddEventVendor,
      watchUpdateEventVendor,
      watchDeleteEventVendor,
      watchSetPaymentStatus,
      watchSendVenueToHostEmail,
      watcherSendBatchEmail,
      watchUpdateLayout,
      watchLayoutGetLayouts,
      watchLayoutAddLayout,
      watchLayoutUpdateLayout,
      watchLayoutDeleteLayout,
      watchUploadLayout,
      watchGetLayoutById,
      watchGetLayoutOptions,
      watcherRemind,
      // watchAddDocument, deprecated
      watchDeleteDocument,
      watchUpdateDocument,
      watchGetDocuments,
      watchGetDocument,
      watchCommentDocument,
      watchDocumentDownload,
      watchDocumentUpload,
      watcherGetHostTutorialDetails,
      watcherUpdateHostTutorials,
      watchSearchEvent,
      watcherGenerateExcelFile,
      watcherGeneratePdfFile,
      watcherRequestEventPacket,
      watchGetTablesRequest,
      watchAddTableRequest,
      watchUpdateTableRequest,
      watchDeleteTableRequest,
      watchUpdateTablesCategory,
      watchGetCustomObjectsRequest,
      watchAddCustomObjectRequest,
      watchUpdateCustomObjectRequest,
      watchDeleteCustomObjectRequest,
      watchUpdateCustomObjectCategory,
      watchSaveLayout,
      watchUpdateStatus,
      watchUpdateEventNote,
      watchUpdateEventChecklistItem,
      watcherUploadDocumentToLibrary,
      watcherGetDocumentLibraryById,
      watcherGetDocumentLibrary,
      watcherDeleteDocumentFromLibrary,
      watchAutoFillDocument,
      watcherCreateCustomField,
      watcherGetCustomFields,
      watcherUpdateCustomField,
      watcherUpdateDocumentToLibrary,
      watcherDownloadDocumentLibrary,
      watcherEditVenue,
      watcherGetLeads,
      watcherGetLead,
      watchAddLead,
      watchAddNote,
      watchDeleteLead,
      watchUpdateLead,
      watcherCreateProduct,
      watcherCreateProductCategory,
      watcherCreateTaxRate,
      watcherCreateRateType,
      watchGenerateProposal,
      watchGenerateContract,
      watchDuplicateLayout,
      watcherGetUserTasks
    ].map(fork)
  );
}

sagaMiddleware.run(root);

export const persistor = persistStore(store);
export default store;
