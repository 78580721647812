import { DateFieldWithLabel } from 'src/v2/UIComponents/FormComponents';
import { TOUR_DATE_LABEL, REQUIRED } from '../../constants';

export const TourDate = ({ bookEvent }) => {
  const TourDateProps = {
    name: 'leadInfo.tourDate',
    label: TOUR_DATE_LABEL,
    placeholder: TOUR_DATE_LABEL,
    allowPreviousDate: true
  };

  return <DateFieldWithLabel {...TourDateProps} />;
};
