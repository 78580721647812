import { HStack, Box, Flex, Center, VStack } from '@chakra-ui/react';

import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Colors from 'src/styles/Colors';
import Checkmark from 'src/styles/Icons/Checkmark';
import 'src/v2/Notifications/toastStyles.css';
import { useToast } from '../Hooks/HelperHooks/Notifications/useToast';

const Notifications = () => {
  const { toastOpen, toastMessage, closeToast, toastBody } = useToast();
  const Message = ({ closeToast, title, body }) => {
    return (
      <div>
        <Box fontWeight="semibold">{title}</Box>
        <Box fontSize="sm">{body}</Box>
      </div>
    );
  };

  useEffect(() => {
    if (toastOpen && toastMessage) {
      toast.success(<Message title={toastMessage} closeToast={closeToast} body={toastBody} />, {
        toastId: 'reduxToast',
        onClose: closeToast
      });
    }
  }, [toastOpen, toastMessage]);

  useEffect(() => {}, []);
  return (
    <ToastContainer
      autoClose={2000}
      position="top-center"
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export default Notifications;
