import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export function getEventDetails({ token, eventId }) {
  return axios({
    method: 'get',
    url: `${apiUri}/events/${eventId}`,
    headers: { Authorization: token }
  });
}

export function editEventPhoto({ token, eventId, photo }) {
  return axios({
    method: 'put',
    url: `${apiUri}/events/${eventId}`,
    headers: { Authorization: token },
    data: { photo }
  });
}

export function uploadImageToCloud({ token, fileToUpload, filename, uploadType }) {
  const data = new FormData();
  data.append('images', fileToUpload && fileToUpload, filename);
  return axios({
    method: 'post',
    url: `${apiUri}/uploads/image-upload`,
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data'
    },
    data,
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      console.log(percentCompleted, 'percentage');
    }
  });
}

export function getEventNotes({ token, eventId }) {
  return axios({
    method: 'get',
    url: `${apiUri}/notes?eventId=${eventId}`,
    headers: { Authorization: token }
  });
}

export function createEventNote({ token, text, eventId }) {
  return axios({
    method: 'post',
    url: `${apiUri}/notes`,
    headers: { Authorization: token },
    data: { text, eventId }
  });
}

export function editEventNote({ token, text, noteId }) {
  return axios({
    method: 'put',
    url: `${apiUri}/notes/${noteId}`,
    headers: { Authorization: token },
    data: { text }
  });
}

export function deleteEventNote({ token, noteId }) {
  return axios({
    method: 'delete',
    url: `${apiUri}/notes/${noteId}`,
    headers: { Authorization: token }
  });
}

export function getEventIdeas({ token, eventId }) {
  return axios({
    method: 'get',
    url: `${apiUri}/ideas`,
    headers: { Authorization: token }
  });
}

export function createEventIdea({ token, url, thumbnail, caption, eventId }) {
  return axios({
    method: 'post',
    url: `${apiUri}/ideas`,
    headers: { Authorization: token },
    data: { url, thumbnail, caption, eventId }
  });
}

export function editEventIdea({ token, ideaId, caption }) {
  return axios({
    method: 'put',
    url: `${apiUri}/ideas/${ideaId}`,
    headers: { Authorization: token },
    data: { caption }
  });
}

export function deleteEventIdea({ token, ideaId }) {
  return axios({
    method: 'delete',
    url: `${apiUri}/ideas/${ideaId}`,
    headers: { Authorization: token }
  });
}

export function getEventStaff({ token, venueId }) {
  return axios({
    method: 'get',
    url: `${apiUri}/venues/${venueId}/users?role=staff`, // this is the url to pull all users from the venue, returns only the logged in host user, meaning there is a permissiosn issue
    headers: { Authorization: token }
  });
}

export function updateEventContacts({ token, eventId, contacts }) {
  return axios({
    method: 'put',
    url: `${apiUri}/events/${eventId}`,
    headers: { Authorization: token },
    data: { contacts }
  });
}

export function editProfileAvatar({ token, userId, photo }) {
  return axios({
    method: 'put',
    url: `${apiUri}/users/${userId}`,
    headers: { Authorization: token },
    data: { photo }
  });
}
