import {
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  VERIFY_FAILURE,
  VERIFY_REQUEST,
  VERIFY_SUCCESS
} from './types';

const initialState = {
  success: null,
  error: null,
  fetching: false,
  successVerify: null,
  errorVerify: null,
  fetchingVerify: false
};

export function resetPasswordReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        success: null,
        error: action.error,
        fetching: false
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        success: null,
        error: null,
        fetching: true
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        success: action.success,
        error: null,
        fetching: false
      };
    case VERIFY_FAILURE:
      return {
        ...state,
        success: null,
        error: null,
        fetching: false,
        successVerify: false,
        errorVerify: action.error,
        fetchingVerify: false
      };
    case VERIFY_REQUEST:
      return {
        ...state,
        success: null,
        error: null,
        fetching: false,
        successVerify: null,
        errorVerify: null,
        fetchingVerify: true
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        success: null,
        error: null,
        fetching: false,
        successVerify: action.success,
        errorVerify: null,
        fetchingVerify: false
      };
    default:
      return state;
  }
}
