import { SwitchInputWithLabel } from 'src/v2/UIComponents/FormComponents';
import { EVENT_TASK_FORM_CONSTANTS } from '../constants';

const { EVENT_TASK_COMPLETED } = EVENT_TASK_FORM_CONSTANTS;

export const EventTaskCompleted = () => {
  const inputProps = {
    label: EVENT_TASK_COMPLETED.LABEL,
    name: 'completed'
  };

  return <SwitchInputWithLabel {...inputProps} />;
};

export default EventTaskCompleted;
