import { Box } from '@chakra-ui/react';
import Colors from 'src/styles/Colors';
import { InlineTextField } from 'src/v2/UIComponents/FormComponents/InlineTextField/InlineTextField';

export const ComposerSubjectField = () => {
  return (
    <Box
      borderBottom={`1px solid ${Colors.grayED7}`}
      height="50px"
      display="flex"
      justifyContent={'center'}
      alignItems="center"
      padding="0px 12px"
      fontSize="14px"
      minHeight={'50px'}
    >
      Subject:
      <InlineTextField placeholder={'Subject'} name="subject" styles={{ fontWeight: 'bold' }} />
    </Box>
  );
};
