import styled from 'styled-components';
import Colors from '../../styles/Colors';

const VenuesStyles = styled.div``;

VenuesStyles.Header = styled.div`
  text-align: right;
  height: 50px;
  border-bottom: 1px solid ${Colors.grayED};
  padding: 15px;
  display: flex;
  flex-direction: row-reverse;
  button {
    margin-left: 10px;
  }
`;

VenuesStyles.Body = styled.div`
  padding: 15px;
`;

export default VenuesStyles;
