import styled from 'styled-components';
import Colors from '../../styles/Colors';
import HexToRGB from '../../styles/HexToRGB';

const ImageResizerStyles = styled.div`
  display: flex
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border: none
`;

ImageResizerStyles.Header = styled.div`
  display: flex;
  justify-content: center;
  height: 100px;
  min-height: 100px;
  align-items: center;
  font-family: Lora;
  font-size: 24px;
  letter-spacing: -0.6px;
  text-align: center;
  color: #181818;
  background-color: var(--colors-primary-light);
`;

ImageResizerStyles.Editor = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
  img {
    height: auto;
    max-width: 100%;
    max-height: 30rem;
  }
`;

ImageResizerStyles.SliderText = styled.div`
  font-family: 'Lora', serif;
  text-align: center;
`;

ImageResizerStyles.Input = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
`;

ImageResizerStyles.Buttons = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-evenly;
`;

export default ImageResizerStyles;
