import { useEffect, forwardRef, FC, useRef, useState } from 'react';
import { css } from '@emotion/react';
import cx from 'classnames';
import { Flex, Text } from '@chakra-ui/react';
import {
  Table as _RVTable,
  AutoSizer as _AutoSizer,
  Column as _Column,
  ColumnProps,
  AutoSizerProps,
  TableProps,
  TableRowRenderer
} from 'react-virtualized';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { isEqual } from 'lodash';
import { Tooltip, Button } from '../UIComponents/Primitives';
import { TableWrapper } from './styles';
import { ColumnSortHeader } from './Headers';
import { ColumnBadge, ColumnDate } from './Columns';
import { useTableContext } from '../context/Tables';
import { ALIGNVALUES } from './constants';

const Column = (_Column as unknown) as FC<ColumnProps>;
const AutoSizer = (_AutoSizer as unknown) as FC<AutoSizerProps>;
const RVTable = (_RVTable as unknown) as FC<TableProps>;

export interface ColumnConfig extends Partial<ColumnProps> {
  dataKey?: string;
  size?: 'sm' | 'md' | 'lg' | 'xlg';
  columnType?: 'fixed' | 'responsive';
  headerRenderer?: ColumnProps['headerRenderer'];
  cellDataGetter?: ColumnProps['cellDataGetter'];
  cellRenderer?: ColumnProps['cellRenderer'];
  disableColumnWidth?: boolean;
  tooltip?: string;
  iconName?: string;
  componentType?: 'default' | 'badge' | 'date';
  dateFormat?: string;
  headerComponent?: string;
  bold?: boolean;
  showValueToolTips?: boolean;
  align?: 'left' | 'center' | 'right';
  flexGrow?: number;
  // Add other custom properties here
}

export const Sizes = {
  responsive: {
    sm: 16,
    md: 8,
    lg: 4,
    xlg: 2
  },
  fixed: {
    sm: 100,
    md: 200,
    lg: 400,
    xlg: 800
  }
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const componentMap = {
  column: ({
    dataKey = '',
    size = 'xlg',
    columnType = 'fixed',
    headerRenderer,
    cellDataGetter,
    cellRenderer,
    width,
    disableColumnWidth,
    ...props
  }: ColumnConfig) => {
    const {
      tooltip = '',
      iconName,
      componentType,
      dateFormat,
      headerComponent,
      bold,
      showValueToolTips,
      align = 'left'
    } = props;

    const columnWidth =
      (columnType === 'responsive' && width && width / Sizes[columnType][size]) ||
      (size && Sizes.fixed[size]) ||
      Sizes.fixed.md;

    const renderCellTooltip = (value, headerAlign) => (
      <Flex justifyContent={headerAlign} fontSize="15px">
        <Tooltip label={value} placement="auto-start">
          {value}
        </Tooltip>
      </Flex>
    );

    const cellComponents = {
      default: ({ cellData }) => {
        const headerAlign = ALIGNVALUES[align];

        if (showValueToolTips) {
          return renderCellTooltip(cellData?.value, headerAlign);
        }

        return (
          <Flex flex={1} justifyContent={headerAlign} fontSize="15px">
            {cellData?.value}
          </Flex>
        );
      },
      badge: prps => <ColumnBadge {...prps} />,
      date: prps => <ColumnDate {...prps} dateFormat={dateFormat} />
    };

    const headerComponents = {
      default: prps => (
        <ColumnSortHeader {...prps} tooltip={tooltip} iconName={iconName} align={align} />
      )
    };

    const defaultCellDataGetter = ({ rowData }) =>
      rowData && {
        value: rowData[dataKey]
      };

    const defaultCellRenderer = prps => cellComponents[componentType || 'default'](prps);

    const defaultHeaderRenderer = prps => headerComponents[headerComponent || 'default'](prps);

    return (
      <Column
        headerRenderer={headerRenderer || defaultHeaderRenderer}
        dataKey={dataKey}
        width={disableColumnWidth ? 1 : columnWidth}
        flexGrow={props.flexGrow || 1}
        cellDataGetter={cellDataGetter || defaultCellDataGetter}
        cellRenderer={cellRenderer || defaultCellRenderer}
        className={cx({ 'text-bold': bold })}
        {...props}
      />
    );
  }
};

const EmptyStateComponent = ({
  title,
  subTitle,
  isLoading,
  btnAction,
  isFilterActive,
  btnLabel
}) => (
  <Flex justifyContent="center" alignItems="center" width="full" height="full">
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      height="160px"
    >
      {isLoading ? (
        <PropagateLoader
          css={override}
          color={'var(--colors-primary)'}
          loading={true}
          speedMultiplier={1.5}
        />
      ) : (
        <>
          <Text
            fontFamily="Lato"
            fontSize="36px"
            fontWeight={500}
            lineHeight="30px"
            color="#888"
            letterSpacing="-0.45px"
          >
            {title}
          </Text>
          <Text
            fontFamily="Lato"
            fontSize="24px"
            fontWeight={500}
            lineHeight="30px"
            color="#888"
            letterSpacing="-0.45px"
          >
            {subTitle}
          </Text>
          {!isFilterActive && btnLabel && <Button onClick={btnAction}>{btnLabel}</Button>}
        </>
      )}
    </Flex>
  </Flex>
);

interface TableInputProps extends Partial<TableProps> {
  rowClickAction?: (any) => void;
  data: Array<any>;
  columns: Array<any>;
  emptyStateProps: any;
  isLoading: boolean;
  disableHeight?: boolean;
  tableId: string;
  length?: any;
  rowRenderer?: TableRowRenderer;
  disableColumnWidth?: boolean;
  defaultSortBy?: string;
  defaultSortDirection?: 'ASC' | 'DESC';
}

type Ref = {};

export const Table = forwardRef<Ref, TableInputProps>(
  (
    {
      rowRenderer,
      rowClickAction,
      data,
      columns,
      emptyStateProps,
      isLoading,
      disableHeight,
      tableId,
      disableColumnWidth = false,
      defaultSortBy,
      defaultSortDirection,
      ...rest
    },
    ref
  ) => {
    const {
      tableData,
      rowCount,
      sortBy,
      sortDirection,
      sortData,
      getData,
      resetTable,
      filterData,
      clearFilter,
      filters,
      contextTableId
    } = useTableContext<any>();
    const sortedRef = useRef(false);

    useEffect(() => {
      if (
        tableData &&
        tableData.length &&
        !sortedRef.current &&
        defaultSortBy &&
        defaultSortDirection
      ) {
        // Ensure sorting is only triggered once until data or sort criteria changes
        sortData(defaultSortBy, defaultSortDirection === 'DESC' ? 'DESC' : 'ASC');
        sortedRef.current = true; // Mark as sorted
      }
    }, [data, tableId, defaultSortBy, defaultSortDirection, getData, sortData, contextTableId]);

    const [currentData, setCurrentData] = useState(data);

    useEffect(() => {
      if (!data && tableData && contextTableId !== tableId) {
        resetTable(tableId);
        sortedRef.current = false;
      }
      if (tableId !== contextTableId) {
        getData(data, tableId);
        setCurrentData(data);
      }
      if (data && data.length && !isEqual(data, currentData)) {
        getData(data, tableId, true);
        setCurrentData(data);
      }
    }, [data, tableId, currentData, contextTableId, tableData, getData, resetTable]);

    const sort = ({ sortBy: srtBy, sortDirection: srtDirection }) => {
      sortData(srtBy, srtDirection);
    };

    const setFilter = (filterBy, filterKey, filterType) => {
      filterData({ filterBy, filterKey, filterType });
    };

    const rowGetter = ({ index }) => tableData[index];

    const isFilterActive = filters?.length;
    const emptyStateTitle = isFilterActive
      ? 'No results that match your search filters were found.'
      : emptyStateProps?.title;
    const emptyStateSubTitle = isFilterActive ? '' : emptyStateProps?.subTitle;

    return (
      <TableWrapper>
        <AutoSizer disableHeight={disableHeight}>
          {({ width, height = 300 }) => {
            return (
              <RVTable
                id={tableId}
                onRowClick={rowClickAction}
                className="show-scrollbar"
                ref={ref}
                rowClassName={({ index }) => {
                  // console.log(tableData[index]);
                  const { completed } = tableData[index] || {};
                  const rowClass = 'table-row';
                  return completed ? `${rowClass} updating` : rowClass;
                }}
                headerClassName="table-header"
                rowHeight={80}
                rowCount={rowCount}
                // rowStyle={props => console.log('props', props)}
                rowGetter={rowGetter}
                width={width}
                height={height}
                headerHeight={64.2}
                overscanRowCount={10}
                sort={sort}
                sortBy={sortBy}
                sortDirection={sortDirection}
                rowStyle={{
                  cursor: (rowClickAction && 'pointer') || ''
                }}
                rowRenderer={rowRenderer}
                noRowsRenderer={() => (
                  <EmptyStateComponent
                    title={emptyStateTitle}
                    subTitle={emptyStateSubTitle}
                    isLoading={isLoading}
                    btnAction={emptyStateProps?.btnAction}
                    isFilterActive={isFilterActive}
                    btnLabel={emptyStateProps?.btnLabel}
                  />
                )}
                {...rest}
              >
                {columns?.map(({ type = 'column', ...props }) =>
                  componentMap[type]({
                    ...props,
                    setFilter,
                    clearFilter,
                    width,
                    disableColumnWidth
                  })
                )}
              </RVTable>
            );
          }}
        </AutoSizer>
      </TableWrapper>
    );
  }
);

Table.displayName = 'Table';
