import {
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuDivider,
  MenuItem,
  Text,
  Grid,
  GridItem,
  Icon,
  Box
} from '@chakra-ui/react';
import Colors from 'src/styles/Colors';
import { getDateDisplay } from 'src/utils/getDateDisplay';
import { IconButton } from 'src/v2/UIComponents/Primitives/';
import { EventTask } from 'venuex-types';

import { useLeads, useEventsDetails, useEvents } from 'src/v2/Hooks/CollectionHooks';

import { MdOutlineNotifications } from 'react-icons/md';
import { scrollBarProps } from 'src/v2/UIComponents/ScrollBar/scrollBarProps';
import { AiOutlineFileDone } from 'react-icons/ai';

const isDateInFuture = date => {
  const now = new Date();
  return new Date(date) > now;
};

const isDateInPast = date => {
  const now = new Date();
  const inputDate = new Date(date);
  return (
    inputDate.getFullYear() < now.getFullYear() ||
    (inputDate.getFullYear() === now.getFullYear() && inputDate.getMonth() < now.getMonth()) ||
    (inputDate.getFullYear() === now.getFullYear() &&
      inputDate.getMonth() === now.getMonth() &&
      inputDate.getDate() < now.getDate())
  );
};

const isDateToday = date => {
  const now = new Date();
  const inputDate = new Date(date);
  return (
    inputDate.getDate() === now.getDate() &&
    inputDate.getMonth() === now.getMonth() &&
    inputDate.getFullYear() === now.getFullYear()
  );
};

export const TasksDropdown = () => {
  const { openLeadsDetails } = useLeads();
  const { openEventDetailsDrawer } = useEventsDetails();
  const { getEvent, userTasks: unfilteredTasks, fetchingUserTasks } = useEvents();

  const tasks = unfilteredTasks?.filter(task => !task.archive && task.dueDate && !task.completed);

  const handleOpenDetails = (eventId, eventType) => {
    getEvent({ eventId });
    if (eventType === 'lead') {
      openLeadsDetails({ tabIndex: 4 });
    } else {
      openEventDetailsDrawer({ activeItem: 'tasks' });
    }
  };

  const overDueTasks = tasks
    .filter(task => isDateInPast(task.dueDate))
    .filter(task => !task.archive);

  const upcomingTasks = tasks
    .filter(task => isDateInFuture(task.dueDate))
    .filter(task => !task.archive);

  const todayTasks = tasks.filter(task => isDateToday(task.dueDate)).filter(task => !task.archive);

  const GridKey = ({ children }) => (
    <GridItem>
      <Text fontFamily="Lato" fontWeight={700} fontSize="14px" lineHeight="16px" color={'#A0A4A8'}>
        {children}
      </Text>
    </GridItem>
  );

  const GridValue = ({ children }) => (
    <GridItem>
      <Text fontFamily="Lato" fontWeight={700} fontSize="14px" lineHeight="16px">
        {children}
      </Text>
    </GridItem>
  );
  const TaskItem = ({ task, index, max }: { task: EventTask; index: number; max: number }) => {
    const bgColor = index % 2 === 0 ? Colors.grayED7 : Colors.white;
    const hoverColor = 'var(--colors-primary-lightest)';
    return (
      <>
        <MenuItem
          borderRadius={'5px'}
          marginY="2.5px"
          marginX="5px"
          display="flex"
          flexDirection="column"
          width={'-webkit-fill-available'}
          // bgColor={bgColor}
          _hover={{ bgColor: hoverColor }}
          onClick={() => handleOpenDetails(task.eventId, task.eventType)}
        >
          <Grid gridTemplateColumns="80px 1fr" width="100%" gap={2} marginX="5px">
            <GridKey>Task:</GridKey>
            <GridValue>{task.name}</GridValue>
            <GridKey>Related To:</GridKey>
            <GridValue>{task.eventTitle}</GridValue>
            <GridKey>Due:</GridKey>
            <GridValue>{getDateDisplay(task.dueDate).shortDate}</GridValue>
          </Grid>
        </MenuItem>
        {index < max - 1 && <MenuDivider />}
      </>
    );
  };

  const OverDueTasksList = () => (
    <MenuOptionGroup title="Overdue Tasks" type="radio">
      {overDueTasks
        ?.sort((a, b) => {
          if (a.dueDate === undefined) return 1;
          if (b.dueDate === undefined) return -1;
          return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
        })
        .map((task, i) => (
          <TaskItem
            task={task}
            key={`${task.id}-overdue${i}`}
            index={i}
            max={overDueTasks.length}
          />
        ))}
    </MenuOptionGroup>
  );

  const UpcomingTasksList = () => (
    <MenuOptionGroup title="Upcoming Tasks" type="radio">
      {upcomingTasks
        ?.sort((a, b) => {
          if (a.dueDate === undefined) return 1;
          if (b.dueDate === undefined) return -1;
          return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
        })
        ?.map((task, i) => (
          <TaskItem task={task} key={`${task.id}-upcoming`} index={i} max={upcomingTasks.length} />
        ))}
    </MenuOptionGroup>
  );

  const TodayTasksList = () => (
    <MenuOptionGroup title="Today's Tasks" type="radio">
      {todayTasks.map((task, i) => (
        <TaskItem task={task} key={task.id} index={i} max={upcomingTasks.length} />
      ))}
    </MenuOptionGroup>
  );

  const NotificationIcon = () => (
    <Box display="flex" position="relative">
      <Icon as={MdOutlineNotifications} w={6} h={6} />
      {overDueTasks.length > 0 && (
        <div
          style={{
            position: 'absolute',
            top: '-2px',
            right: '-2.5px',
            backgroundColor: 'red',
            borderRadius: '50%',
            width: '7px',
            height: '7px'
          }}
        />
      )}
    </Box>
  );

  const NoTasksState = () => (
    <Box
      p={4}
      textAlign="center"
      display="flex"
      justifyContent={'center'}
      alignItems="center"
      flexDirection="column"
      color={Colors.grayD8}
    >
      <AiOutlineFileDone size={32} />
      <Text mt={4} fontWeight="bold" fontSize="lg">
        No tasks to show
      </Text>
    </Box>
  );
  return (
    <Menu closeOnSelect={true}>
      <MenuButton
        as={IconButton}
        variant={'roundedSecondaryOutline'}
        icon={<NotificationIcon />}
        // icon={<Icon as={MdOutlineNotifications} w={6} h={6} />}
        isLoading={fetchingUserTasks}
      ></MenuButton>

      <MenuList width="fit-content" zIndex="999">
        {!tasks.length && <NoTasksState />}
        <Box maxH="400px" overflowY="scroll" sx={scrollBarProps}>
          {(overDueTasks.length && (
            <>
              <OverDueTasksList />
              {(todayTasks.length || upcomingTasks.length) && <MenuDivider />}
            </>
          )) || <div />}
          <>
            {(todayTasks.length && <TodayTasksList />) || <div />}
            {(upcomingTasks.length && <MenuDivider />) || <div />}
          </>

          {(upcomingTasks.length && <UpcomingTasksList />) || <div />}
        </Box>
      </MenuList>
    </Menu>
  );
};
