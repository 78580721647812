import styled from 'styled-components';
import Colors from 'src/styles/Colors';

const CalendarEventStyles = styled.div`
  margin: 1.75px;
  padding: 3px 2px;
  background-color: ${props =>
    props.overdue ? 'var(--colors-primary-lighter)' : 'var(--colors-primary)'};
  border-radius: 2px;
`;

export const PopoverStyles = styled.div`
  box-shadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)';
  transition: 0.3s;
  border-radius: 5px;
  padding: 20px 30px;
  height: 175px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

PopoverStyles.Header = styled.div`
  font-weight: 600;
  font-size: 1.2em;
`;
PopoverStyles.SubHeader = styled.span`
  color: ${Colors.gray7D};
  font-weight: 500;
  font-size: 0.9em;
`;

PopoverStyles.Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  font-size: 0.95em;
  height: 140px;
  justify-content: space-between;
`;

export default CalendarEventStyles;
