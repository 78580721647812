import React, { FC } from 'react';
import { FormControl, HStack, Textarea } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormLabel, FormErrorMessage } from '../../Primitives';
import { TextFieldAreaProps } from './types';

export const RootTextAreaWithLabel: FC<TextFieldAreaProps> = ({
  isRequired,
  label,
  placeholder,
  name,
  rules,
  fieldArray,
  errorKey,
  isDisabled,
  ...rest
}) => {
  const {
    formState: { errors },
    register,
    control
  } = useFormContext();
  // const fieldValue = useWatch({ name, control });
  const { index = '', groupName = '', fieldName = '' } = fieldArray || {};

  let errorMessage;
  let isInvalid = false;

  const fieldArrayError = fieldArray && errors?.[groupName]?.[index]?.[fieldName]?.message;
  const error = errors?.[name]?.message;

  if (errorKey) {
    const { groupKey, inputName } = errorKey;
    errorMessage = errors?.[groupKey]?.[inputName]?.message;
    isInvalid = !!errorMessage;
  } else {
    errorMessage = fieldArray ? fieldArrayError : error;
    isInvalid = fieldArray ? !!fieldArrayError : !!error;
  }

  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <HStack marginBottom="0px" justify="space-between" align="flex-end">
        <FormLabel label={label} />
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </HStack>
      <Textarea
        isDisabled={isDisabled}
        placeholder={placeholder}
        {...register(name, rules)}
        {...rest}
        variant="outline"
        _placeholder={{ fontSize: 'sm' }}
        borderRadius="2px"
        borderColor="#cccccc"
      />
    </FormControl>
  );
};

export const TextAreaWithLabel = React.memo(RootTextAreaWithLabel);
