import React from 'react';
import { OrderedList, ListItem } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useDocumentLibrary } from 'src/v2/Hooks/CollectionHooks';

export const DefaultDocumentsList = () => {
  const { filterDocumentByEventType } = useDocumentLibrary();
  const { watch } = useFormContext();
  const eventType = watch('eventType');
  const documentList = filterDocumentByEventType(eventType);

  return (
    <OrderedList>
      {documentList?.map((row, key) => (
        <ListItem key={key}>{row.documentName}</ListItem>
      ))}
    </OrderedList>
  );
};
