import {
  CreateChecklistItemRequest,
  setCompletedFilter,
  setSortField,
  SetSortOrder,
  UpdateChecklistItemRequest
} from 'src/Types';
import {
  GET_CHECKLIST_ITEMS_REQUEST,
  GET_CHECKLIST_ITEMS_SUCCESS,
  GET_CHECKLIST_ITEMS_FAILURE,
  IMPORT_CHECKLIST_ITEMS_REQUEST,
  IMPORT_CHECKLIST_ITEMS_SUCCESS,
  IMPORT_CHECKLIST_ITEMS_FAILURE,
  UPDATE_CHECKLIST_ITEM_REQUEST,
  UPDATE_CHECKLIST_ITEM_SUCCESS,
  UPDATE_CHECKLIST_ITEM_FAILURE,
  CREATE_CHECKLIST_ITEM_REQUEST,
  CREATE_CHECKLIST_ITEM_SUCCESS,
  CREATE_CHECKLIST_ITEM_FAILURE,
  UPDATE_HOST_CHECKLIST_ITEM_REQUEST,
  UPDATE_HOST_CHECKLIST_ITEM_SUCCESS,
  UPDATE_HOST_CHECKLIST_ITEM_FAILURE,
  DELETE_CHECKLIST_ITEM_REQUEST,
  DELETE_CHECKLIST_ITEM_SUCCESS,
  DELETE_CHECKLIST_ITEM_FAILURE,
  SET_SORT_FIELD,
  SET_SORT_ORDER,
  SET_COMPLETED_FILTER,
  SET_SEARCH_TERM,
  OPEN_CHECKLIST_MODAL,
  CLOSE_CHECKLIST_MODAL
} from './types';

export const getChecklistItemsRequest = ({ token }) => ({
  type: GET_CHECKLIST_ITEMS_REQUEST,
  token
});

export const getChecklistItemsSuccess = ({ items }) => ({
  type: GET_CHECKLIST_ITEMS_SUCCESS,
  items
});

export const getChecklistItemsFailure = error => ({
  type: GET_CHECKLIST_ITEMS_FAILURE,
  error
});

export const importChecklistItemsRequest = ({ token, items, importType, eventId }) => ({
  type: IMPORT_CHECKLIST_ITEMS_REQUEST,
  token,
  items,
  importType,
  eventId
});

export const importChecklistItemsSuccess = ({ success }) => ({
  type: IMPORT_CHECKLIST_ITEMS_SUCCESS,
  success
});

export const importChecklistItemsFailure = error => ({
  type: IMPORT_CHECKLIST_ITEMS_FAILURE,
  error
});

export const updateChecklistItemRequest: UpdateChecklistItemRequest = ({
  token,
  checklistItems,
  eventId
}) => ({
  type: UPDATE_CHECKLIST_ITEM_REQUEST,
  token,
  checklistItems,
  eventId
});

export const updateChecklistItemSuccess = ({ success }) => ({
  type: UPDATE_CHECKLIST_ITEM_SUCCESS,
  success
});

export const updateChecklistItemFailure = error => ({
  type: UPDATE_CHECKLIST_ITEM_FAILURE,
  error
});

export const createChecklistItemRequest: CreateChecklistItemRequest = ({
  token,
  checklistItem
}) => ({
  type: CREATE_CHECKLIST_ITEM_REQUEST,
  token,
  checklistItem
});

export const createChecklistItemSuccess = ({ success }) => ({
  type: CREATE_CHECKLIST_ITEM_SUCCESS,
  success
});

export const createChecklistItemFailure = error => ({
  type: CREATE_CHECKLIST_ITEM_FAILURE,
  error
});

export const updateHostChecklistItemRequest = ({ token, itemId, checklistItem, eventId }) => ({
  type: UPDATE_HOST_CHECKLIST_ITEM_REQUEST,
  token,
  itemId,
  eventId,
  checklistItem
});

export const updateHostChecklistItemSuccess = ({ success }) => ({
  type: UPDATE_HOST_CHECKLIST_ITEM_SUCCESS,
  success
});

export const updateHostChecklistItemFailure = error => ({
  type: UPDATE_HOST_CHECKLIST_ITEM_FAILURE,
  error
});

export const deleteChecklistItemRequest = ({ token, id }) => ({
  type: DELETE_CHECKLIST_ITEM_REQUEST,
  token,
  id
});

export const deleteChecklistItemSuccess = ({ success }) => ({
  type: DELETE_CHECKLIST_ITEM_SUCCESS,
  success
});

export const deleteChecklistItemFailure = error => ({
  type: DELETE_CHECKLIST_ITEM_FAILURE,
  error
});

export const doSetSortField: setSortField = ({ sortField }) => ({
  type: SET_SORT_FIELD,
  sortField
});

export const doSetSortOrder: SetSortOrder = ({ sortOrder }) => ({
  type: SET_SORT_ORDER,
  sortOrder
});

export const doSetCompletedFilter: setCompletedFilter = ({ completedFilter }) => ({
  type: SET_COMPLETED_FILTER,
  completedFilter
});

export const setSearchTerm = ({ searchTerm }) => ({
  type: SET_SEARCH_TERM,
  searchTerm
});

export const openChecklistModal = () => ({
  type: OPEN_CHECKLIST_MODAL
});

export const closeChecklistModal = () => ({
  type: CLOSE_CHECKLIST_MODAL
});
