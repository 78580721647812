import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../../components/Modal';
import { closeChangePassword, resetUserPassword } from './operations';
import ChangePassword from '../../components/ChangePassword';

const ChangePasswordModal = ({ isOpen, closeModal, resetPass, token, errors, successMessage }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    return () => {
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    };
  }, [isOpen]);

  const handleInputChange = event => {
    event.preventDefault();
    const { name, value } = event.currentTarget;
    switch (name) {
      case 'oldPassword':
        setOldPassword(value);
        break;
      case 'newPassword':
        setNewPassword(value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        break;
      default:
      // do nothing.
    }
  };

  const onSubmit = event => {
    event.preventDefault();
    if (!oldPassword) {
      return setError('Old password is required');
    }
    if (!newPassword) {
      return setError('New password is required');
    }
    if (!confirmPassword) {
      return setError('ConfirmPassword password is required');
    }
    setError(null);
    const payload = {
      token,
      data: {
        oldPassword,
        password: newPassword,
        confirm: confirmPassword
      }
    };
    resetPass(payload);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      height={530}
      width={580}
      boxSizing="border-box"
      noPadding
    >
      <ChangePassword
        closeModal={closeModal}
        oldPassword={oldPassword}
        newPassword={newPassword}
        confirmPassword={confirmPassword}
        handleInputChange={handleInputChange}
        handleSubmit={onSubmit}
        resetErrors={error || (errors && errors.error)}
        success={successMessage}
      />
    </Modal>
  );
};

const mapStateToProps = ({ changePasswordReducer, loginReducer }) => ({
  isOpen: changePasswordReducer.isOpen,
  errors: changePasswordReducer.errors,
  successMessage: changePasswordReducer.successMessage,
  token: loginReducer.token
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeChangePassword()),
  resetPass: ({ token, data }) => dispatch(resetUserPassword({ token, data }))
});

ChangePasswordModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  resetPass: PropTypes.func,
  token: PropTypes.string,
  errors: PropTypes.string,
  successMessage: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);
