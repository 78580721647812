import { useEvents } from 'src/v2/Hooks/CollectionHooks';
import { ListCard } from 'src/v2/UIComponents/Primitives/Card/FullCard/FullCard';

import { Calendar } from 'src/v2/UIComponents/Primitives/Icons';
import { EventResponseObject } from 'venuex-types';

export const EventDetailsCard = () => {
  const { activeEvent: lead, fetchingEvent: fetchingLead } = useEvents();
  const {
    eventTypeLabel,
    start,
    minimumGuests,
    roomData,
    layoutData,
    staffValues,
    clientName,
    alternateFloorplans,
    consultantData,
    title
  } = lead as EventResponseObject;

  const { label: consultantName = '' } = consultantData || { label: '' };

  const list = [
    {
      title: 'Event Type',
      value: eventTypeLabel,
      missingMessage: '---'
    },
    {
      title: 'Event Date & Time',
      value:
        start &&
        `${new Date(start).toLocaleDateString()} ${new Date(start)
          .toLocaleTimeString()
          .replace(/(.*)\D\d+/, '$1')}`,
      missingMessage: 'Missing Field'
    },
    {
      title: 'Guest Count',
      value: minimumGuests,
      missingMessage: 'Missing Field'
    },
    {
      title: 'Room',
      value: roomData?.name,
      missingMessage: 'Missing Field'
    },
    {
      title: 'Layout',
      value: layoutData?.name,
      missingMessage: 'Missing Field'
    },
    {
      title: 'Event Team',
      value: staffValues?.map((staff, i) => {
        if (i + 1 >= staffValues?.length) return `${staff.firstname} ${staff.lastname} `;
        return `${staff.firstname} ${staff.lastname}, `;
      }),
      missingMessage: 'Missing Field'
    },
    {
      title: 'Client Name',
      value: clientName,
      missingMessage: 'Missing Field'
    },
    {
      title: 'Event Consultant',
      value: consultantName,
      missingMessage: 'Missing Field'
    },
    {
      title: 'Event Name',
      value: title,
      missingMessage: 'Missing Field'
    },
    {
      title: 'Additional Layout Options',
      value: alternateFloorplans?.map(floorplan => floorplan.name),
      missingMessage: 'Missing Field'
    }
  ];

  return (
    <ListCard
      titleIcon={<Calendar color="var(--colors-primary)" />}
      title={`Event Details`}
      list={list}
      fetching={fetchingLead}
      headerBtnAction={undefined}
      footer={undefined}
      headerBtnIcon={undefined}
      headerBtnLabel={undefined}
    />
  );
};
