import { useSelector, useDispatch } from 'react-redux';
import {
  getLeadsRequest,
  openLeadsDetails,
  closeLeadsDetails,
  getLeadRequest,
  openLeadsForm,
  closeLeadsForm,
  openLeadNotesForm,
  closeLeadsNotesForm,
  addLeadRequest,
  addNoteRequest,
  deleteLead,
  updateLeadRequest,
  openContractModal,
  openProposalModal,
  closeContractModal,
  closeProposalModal
} from 'src/containers/LeadsContainer/operations';
import { useActions } from 'src/hooks/useActionsNew';
import { createSelector } from 'reselect';
import {
  generateContractOperations,
  generateProposalOperations
} from 'src/v2/Collections/proposals/operations';
import { RootStates } from 'src/Types';
import { useMemo } from 'react';

const selectLeads = createSelector(
  [(state: RootStates) => state.leadsReducer.leads, state => state.getStaffReducer.staff],
  (leads, staff) =>
    leads?.map(lead => {
      //   const salespersonName = (staffInfo && `${staffInfo?.firstname} ${staffInfo?.lastname}`) || '';
      return {
        ...lead,
        id: lead.id,
        clientName: lead.clientName,
        phone: lead?.leadInfo?.prospectPhone,
        email: lead.leadInfo?.email,
        // preferredDate: new Date(lead?.leadInfo?.preferredDate).getTime(),
        salesperson: lead.salesperson,
        salespersonName: lead?.salespersonName,
        status: lead.leadInfo.status,
        source: lead.leadInfo.source,
        created: new Date(lead.created).getTime(),
        leadStatusNumber: lead.leadStatusNumber
      };
    })
);

const selectLead = createSelector(
  (state: RootStates) => state.leadsReducer.lead,
  lead => ({
    ...lead,
    ...lead?.leadInfo,
    prospectPhone: lead?.leadInfo?.prospectPhone || '',
    prospectEmail: lead?.leadInfo?.email,
    address: lead?.leadInfo?.streetAddress,
    // guestCount: lead?.leadInfo?.guestCount,
    tourDate: lead?.leadInfo?.tourDate,
    expectedCloseDate: lead?.leadInfo?.expectedCloseDate,
    // preferredDate: lead?.leadInfo?.preferredDate,
    leadDate: lead?.leadInfo?.leadDate,
    status: lead?.leadInfo?.status,
    salesperson: lead?.salesperson,
    salespersonName: lead?.salespersonName,
    eventType: lead?.eventType,
    room: lead?.room,
    source: lead?.leadInfo?.source,
    sourceLabel: lead?.leadInfo?.sourceLabel
  })
);

export const useLeads = () => {
  const dispatch = useDispatch();
  const leadsState = useSelector((state: RootStates) => state.leadsReducer);
  const { leadStatusOptions, leadSources } = useSelector(
    (state: RootStates) => state.venuesReducer.venue
  );
  const { venueId } = useSelector((state: RootStates) => state.loginReducer);
  const tableLeads = useSelector(selectLeads);
  const filteredLeads = useMemo(() => {
    return (tableLeads || []).filter(lead => {
      const { leadType } = lead?.leadInfo || {};
      return leadType === 'unknown' || (leadType !== 'duplicate' && leadType !== 'message');
    });
  }, [tableLeads]);

  const defaultValues = useSelector(selectLead);

  // new, booked, venueToured, proposalSent, contractSent

  const leads = leadsState?.leads || [];

  const totalLeads = leads.length;
  const statusForOpenOportunities = leadStatusOptions
    ?.filter(status => status.order <= 5)
    .map(lead => lead.value);

  const openOpportunities = leads?.filter(lead =>
    statusForOpenOportunities?.includes(lead?.leadInfo?.status || '')
  ).length;

  const getNewOpportunities = filteredLeads => {
    return filteredLeads?.filter(lead => lead?.leadInfo?.status === 'new').length;
  };

  const getOpenOpportunities = filteredLeads => {
    return filteredLeads?.filter(lead =>
      statusForOpenOportunities?.includes(lead?.leadInfo?.status)
    ).length;
  };

  const leadsOnProposalSent = leads?.filter(lead => lead?.leadInfo?.status === 'proposalSent')
    .length;

  const getLeadsOnProposalSent = filteredLeads => {
    return filteredLeads?.filter(lead => lead?.leadInfo?.status === 'proposalSent').length;
  };

  const leadsOnContractSent = leads?.filter(lead => lead?.leadInfo?.status === 'contractSent')
    .length;

  const getLeadsOnContractSent = filteredLeads => {
    return filteredLeads?.filter(lead => lead?.leadInfo?.status === 'contractSent').length;
  };

  const leadsOnEventBooked = leads?.filter(lead => lead?.leadInfo?.status === 'eventBooked').length;
  const updateLead = ({ data, id }) => {
    dispatch(
      updateLeadRequest({
        id,
        event: data
      })
    );
  };

  const actions = useActions({
    getLeadsRequest,
    closeLeadsDetails,
    getLeadRequest,
    openLeadsDetails,
    openLeadsForm,
    closeLeadsForm,
    openLeadNotesForm,
    closeLeadsNotesForm,
    addLeadRequest,
    addNoteRequest,
    deleteLead,
    openContractModal,
    openProposalModal,
    closeContractModal,
    closeProposalModal,
    generateProposal: generateProposalOperations.request,
    generateContract: generateContractOperations.request
  });

  return {
    ...leadsState,
    filteredLeads,
    totalLeads,
    openOpportunities,
    leadsOnProposalSent,
    leadsOnContractSent,
    leadsOnEventBooked,
    leadStatusOptions,
    leadSources,
    tableLeads,
    defaultValues,
    updateLead,
    getOpenOpportunities,
    getLeadsOnProposalSent,
    getLeadsOnContractSent,
    getNewOpportunities,
    ...actions
  };
};
