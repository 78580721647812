import styled from 'styled-components';
import Colors from '../../styles/Colors';

const DocumentsListTableStyles = styled.div``;

DocumentsListTableStyles.Category = styled.div`
  color: ${Colors.gray88};
  letter-spacing: 0.25px;
  font-size: 12px;
  font-weight: 600;
`;

DocumentsListTableStyles.Rows = styled.div`
  color: ${props => (props.disabled ? Colors.grayCC : Colors.black22)};
  font-size: 15px;
`;

DocumentsListTableStyles.NoRows = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

DocumentsListTableStyles.NoRowsTitle = styled.span`
  font-family: Lato;
  font-size: 36px;
  font-weight: 500;
  line-height: 50px;
  color: #888;
  letter-spacing: -0.45px;
`;

DocumentsListTableStyles.NoRowsSubtitle = styled.span`
  font-family: Lato;
  font-size: 24px;
  font-weight: 500;
  line-height: 50px;
  color: #888;
  letter-spacing: -0.45px;
`;

DocumentsListTableStyles.MetaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  svg {
    opacity: 1 !important;
    color: ${Colors.gray7D} !important;
  }
`;

DocumentsListTableStyles.MetaRow = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InfoText = styled.div`
  height: 18px;
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.19px;
  text-align: right;
  color: ${props => (props && props.color) || '#b0b0b0'};
  margin-bottom: 32px;
  transition: color 1s;
`;

export const Controls = styled.span`
  display: flex;
  justify-content: flex-end;
  svg {
    margin: 8px;
    opacity: 0;
    cursor: pointer;
  }
`;

export default DocumentsListTableStyles;
