import styled from 'styled-components';
import { Button } from '@chakra-ui/react';

export const MenuLink = styled(Button).attrs(props => ({
  height: '58px',
  padding: '0 35px',
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Lato',
  variant: 'unstyled',
  fontSize: '16px',
  fontWeight: props.isActive ? 700 : 400,
  letterSpacing: '0.1px',
  lineHeight: '24px',
  textTransform: 'capitalize',
  color: props.isActive ? 'var(--colors-primary)' : '#52575C',
  borderRadius: 0,
  // borderBottom: props.isActive ? 'solid 3px var(--colors-primary)' : null,
  ...props
}))``;
