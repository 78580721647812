import { useEvents, useLeads } from 'src/v2/Hooks/CollectionHooks';
import { useDrawers } from 'src/v2/Hooks/HelperHooks';
import { Wizard } from 'src/v2/UIComponents/Drawers/Wizard';
import { ActivityForm } from './ActivityForm/Form';

export const ActivityFormDrawer = () => {
  const { activityDrawerOpen, closeActivityDrawer } = useDrawers();
  const { updateEvent, activeEvent: lead, activeEventId } = useEvents();
  const { activity: activityToEdit, editActivity } = useLeads();

  const defaultValues = {
    title: activityToEdit?.title,
    type: activityToEdit?.type,
    description: activityToEdit?.description
  };
  const closeDrawer = () => {
    closeActivityDrawer();
  };

  const handlesave = data => {
    if (editActivity && activityToEdit) {
      const { id: activityId } = activityToEdit;
      updateEvent({
        data: {
          activity: {
            ...data,
            id: activityId
          }
        },
        eventId: lead?.id,
        isLead: true,
        closeAction: closeDrawer
      });
    } else {
      const activity = {
        ...data
      };
      updateEvent({
        data: { activity },
        eventId: activeEventId,
        isLead: true,
        closeAction: closeDrawer
      });
    }
  };

  const steps = [{ component: ActivityForm, step: 0, title: 'Log Activity' }];

  // TODO: #553 Log Activity Form Drawer: Follow this pattern
  return (
    <Wizard
      steps={steps}
      saveAction={handlesave}
      cancelAction={closeActivityDrawer}
      isOpen={activityDrawerOpen}
      formId="activityDrawer"
      defaultValues={defaultValues}
    />
  );
};
