export const FETCH_GUESTS_REQUEST = 'FETCH_GUESTS_REQUEST';
export const FETCH_GUESTS_SUCCESS = 'FETCH_GUESTS_SUCCESS';
export const FETCH_GUESTS_FAILURE = 'FETCH_GUESTS_FAILURE';

export const FILTER_GUESTS_REQUEST = 'FILTER_GUESTS_REQUEST';
export const FILTER_GUESTS_SUCCESS = 'FILTER_GUESTS_SUCCESS';
export const FILTER_GUESTS_FAILURE = 'FILTER_GUESTS_FAILURE';

export const CHANGE_USER_TABLE_REQUEST = 'CHANGE_USER_TABLE_REQUEST';
export const CHANGE_USER_TABLE_SUCCESS = 'CHANGE_USER_TABLE_SUCCESS';
export const CHANGE_USER_TABLE_FAILURE = 'CHANGE_USER_TABLE_FAILURE';

export const CHANGE_LAYOUT_NAME_REQUEST = 'CHANGE_LAYOUT_NAME_REQUEST';
export const CHANGE_LAYOUT_NAME_SUCCESS = 'CHANGE_LAYOUT_NAME_SUCCESS';
export const CHANGE_LAYOUT_NAME_FAILURE = 'CHANGE_LAYOUT_NAME_FAILURE';

export const GET_LAYOUT_REQUEST = 'GET_LAYOUT_REQUEST';
export const GET_LAYOUT_SUCCESS = 'GET_LAYOUT_SUCCESS';
export const GET_LAYOUT_FAILURE = 'GET_LAYOUT_FAILURE';

export const RESET_ALL_TABLES_REQUEST = 'RESET_ALL_TABLES_REQUEST';
export const RESET_ALL_TABLES_SUCCESS = 'RESET_ALL_TABLES_SUCCESS';
export const RESET_ALL_TABLES_FAILURE = 'RESET_ALL_TABLES_FAILURE';

export const CHANGE_GROUP_TABLE_SUCCESS = 'CHANGE_GROUP_TABLE_SUCCESS';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';

export const SUBMIT_SEATING_CHART_REQUEST = 'SUBMIT_SEATING_CHART_REQUEST';
export const SUBMIT_SEATING_CHART_FAILURE = 'SUBMIT_SEATING_CHART_FAILURE';
export const SUBMIT_SEATING_CHART_SUCCESS = 'SUBMIT_SEATING_CHART_SUCCESS';

export const NEW_SEATING_CHART_REQUEST = 'NEW_SEATING_CHART_REQUEST';
export const NEW_SEATING_CHART_FAILURE = 'NEW_SEATING_CHART_FAILURE';
export const NEW_SEATING_CHART_SUCCESS = 'NEW_SEATING_CHART_SUCCESS';

export const ALERT_RESET = 'ALERT_RESET';

export const UPDATE_LAYOUT_REQUEST = 'UPDATE_LAYOUT_REQUEST';
export const UPDATE_LAYOUT_SUCCESS = 'UPDATE_LAYOUT_SUCCESS';
export const UPDATE_LAYOUT_FAILURE = 'UPDATE_LAYOUT_FAILURE';

export const SELECT_TABLE = 'SELECT_TABLE';
export const CLEAR_SELECTED_TABLE = 'CLEAR_SELECTED_TABLE';
