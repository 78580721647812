export const DOWNLOADING_EVENT_PACKET_HEADER = 'Downloading Event Packet';
export const DOWNLOADING_EVENT_PACKET_MESSAGE = title => (
  <div>
    <div>Generating Vendor List, Guest List and Seating Chart for {title && 'the event '}</div>
    <div style={{ fontWeight: 600 }}>{(title && title) || 'this event'}.</div>
  </div>
);
export const DOWNLOADING_LAYOUT_PACKET_MESSAGE = title => (
  <div>
    <div>Generating Seating Chart and Guest List for the event {title && 'the event '}</div>
    <div style={{ fontWeight: 600 }}>{(title && title) || 'this event'}.</div>
  </div>
);
export const STILL_WORKING = `Almost done, just a few more seconds.`;

export const EVENT_PACKET_ERROR_MESSAGE = `We weren't able to download the event packet, please try again.`;
export const EVENT_PACKET_ERROR_HEADER = `Download Error`;

export const CREATE_CONTRACT_ERROR_MESSAGE = `We weren't able to create the contract, please try again.`;
export const ERROR_HEADER = 'Error';
