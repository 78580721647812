import styled, { css } from 'styled-components';
import Colors from '../../styles/Colors';

export default styled.button`
  height: ${props => (props.height ? props.height : '50px')};
  padding: ${props => (props.padding ? props.padding : '0px 30px')};
  box-shadow: 0 2px 4px 0 rgba(125, 125, 125, 0.2);
  border: solid 1px #ededed;
  font-size: 12px;
  font-weight: 800;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: ${props => (props.color ? props.color : 'var(--colors-primary)')};
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  ${props =>
    props.animate &&
    css`
      &:hover {
        color: ${Colors.white} !important;
        background-color: var(--colors-primary);
        border-radius: 8px;
        transition: all 600ms ease-in-out;
      }
      &:active {
        background-color: var(--colors-primary-light);
        transition: none;
      }
    `}
  ${props =>
    props.kind === 'primary' &&
    css`
      background-color: var(--colors-primary);
      color: #fff;
      border: solid 1px;
    `}
  ${props =>
    props.kind === 'secondary' &&
    css`
      background-color: #555;
      color: #fff;
      border: solid 1px;
    `}
  ${props =>
    props.kind === 'success' &&
    css`
      background-color: #2cb070;
      color: #fff;
    `}
  ${props =>
    props.size === 'small' &&
    css`
      padding: 0px 15px;
      height: 40px;
    `}
  ${props =>
    props.kind === 'danger' &&
    css`
      color: #c02026;
    `}
  ${props =>
    props.kind === 'white' &&
    css`
      background-color: #ffffff;
      color: #fff;
      border: solid 1px #ededed;
    `}
`;
