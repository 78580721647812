import { Box, Flex, Icon, Skeleton } from '@chakra-ui/react';
import React from 'react';
import Colors from 'src/styles/Colors';
import { Card, Text } from '../../Primitives';

const primaryColor = 'var(--colors-primary)';
const defaultBorder = `1px solid ${Colors.grayED}`;
const hoverBorder = `2px solid ${primaryColor}`;
const selectedBorder = `1px solid ${primaryColor}`;

export const MiniCardButton = ({ icon, title, isLoading, subTitle, handleClick, selected }) => {
  return (
    <Box>
      <Card
        display="flex"
        paddingX="26px"
        paddingY="20px"
        height="90px"
        border={(selected && selectedBorder) || defaultBorder}
        _hover={{
          border: hoverBorder,
          cursor: 'pointer'
        }}
        _active={{
          backgroundColor: Colors.grayED
        }}
        onClick={handleClick}
      >
        <Flex>
          <Flex mr="26px" alignItems="center" color="var(--colors-primary)">
            <Icon as={icon} w={8} h={8} />
          </Flex>
          <Flex flexDir="column">
            <Text fontWeight={700} fontSize="14px" lineHeight="20px" mb="4px">
              {title}
            </Text>
            {isLoading ? (
              <Flex>
                <Skeleton height="16px" width="160px" />
              </Flex>
            ) : (
              <Text fontWeight={400} fontSize="18px" lineHeight="24px">
                {subTitle}
              </Text>
            )}
          </Flex>
        </Flex>
      </Card>
    </Box>
  );
};
