import styled from 'styled-components';

export default styled.button.attrs(({ disabled }) => ({
  type: 'button',
  disabled
}))`
  background-color: Transparent;
  border: none;
  height: 30px;
  width 30px;

  &:focus {
      outline: none;
  }
`;
