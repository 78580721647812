import moment from 'moment';
import { EventChecklistItem } from 'venuex-types';

interface ChecklistGroup {
  [header: string]: EventChecklistItem[];
}

export const generateOrderedGroups = (items: {
  [id: string]: EventChecklistItem;
}): ChecklistGroup => {
  const groups = {
    overdue: [] as EventChecklistItem[],
    today: [] as EventChecklistItem[],
    upcoming: [] as EventChecklistItem[],
    'no due date': [] as EventChecklistItem[],
    completed: [] as EventChecklistItem[]
  };
  if (Object.keys(items).length) {
    Object.entries(items).forEach(([id, item]) => {
      const itemDueDate = new Date(item.dueDate);
      const now = new Date();
      if (item.completed) {
        groups.completed.push({ ...item });
      } else if (now.toDateString() === itemDueDate.toDateString()) {
        groups.today.push({ ...item } as EventChecklistItem);
      } else if (moment().isAfter(item.dueDate)) {
        groups.overdue.push({ ...item } as EventChecklistItem);
      } else if (moment().isBefore(item.dueDate)) {
        groups.upcoming.push({ ...item } as EventChecklistItem);
      } else {
        groups['no due date'].push({ ...item } as EventChecklistItem);
      }
    });
  }
  return groups;
};
