import { takeLatest, put, call, select } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import { Icon } from '@chakra-ui/react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { getLeadsRequest } from 'src/containers/LeadsContainer/operations';
import {
  GET_EVENTS_REQUEST,
  GET_EVENTS_FAILURE,
  GET_EVENTS_SUCCESS,
  DELETE_EVENT_REQUEST,
  GET_EVENT_REQUEST,
  GET_MORE_EVENTS_REQUEST,
  SEARCH_EVENT_REQUEST,
  UPDATE_EVENT_NOTE_REQUEST,
  UPDATE_EVENT_CHECKLIST_ITEM_REQUEST,
  GET_EVENT_ANALYTICS,
  GET_USER_TASKS_REQUEST
} from './types';

import {
  getEventsAction,
  getEventAnalyticsAction,
  deleteEventAction,
  getEventAction,
  searchEvent,
  updateEventAction,
  updateEventChecklistItem,
  getUserTasks
} from './actions';

import {
  deleteEventSuccess,
  deleteEventFailure,
  getEvents,
  getEventFailure,
  getEventSuccess,
  getMoreEventsSuccess,
  getEventsMoreFailure,
  setPaymentStatusOnEventSuccess,
  updateEventNoteFailure,
  updateEventNoteSuccess,
  getEventRequest,
  updateEventChecklistItemFailure,
  getEventTasksSuccess,
  getEventTasksFailure
} from './operations';
import { closeConfirmModal } from '../ConfirmModal/operations';
import { closeEventDetailsDrawer, closeEventModal } from '../EventDetailsModal/operations';
import { getStaffEventsRequest } from '../ManageStaffContainer/operations';
import { closeChecklistModal } from '../ChecklistContainer/operations';

const getEventId = state => state.loginReducer.eventId;
const getUserRole = state => state.loginReducer.role;
const getToken = state => state.loginReducer.token;

export function* workerGetEvents({ limit, startDate, endDate, monthStart, monthEnd }) {
  try {
    const token = yield select(getToken);
    const response = yield call(getEventsAction, {
      token,
      limit,
      startDate,
      endDate,
      monthStart,
      monthEnd
    });
    const { events, stats, success, appointments } = response.data;
    yield put({
      type: GET_EVENTS_SUCCESS,
      events,
      appointments,
      success,
      stats
    });
  } catch (error) {
    yield put({ type: GET_EVENTS_FAILURE, ...error.data });
  }
}

export function* workerGetEventAnalytics({ limit, startDate, endDate, monthStart, monthEnd }) {
  try {
    const token = yield select(getToken);
    const response = yield call(getEventAnalyticsAction, {
      token,
      limit,
      startDate,
      endDate,
      monthStart,
      monthEnd
    });
    const { events, stats, success, appointments, detailedEventList } = response.data;
    yield put({
      type: GET_EVENT_ANALYTICS.SUCCESS,
      events,
      appointments,
      success,
      stats,
      detailedEventList
    });
  } catch (error) {
    yield put({ type: GET_EVENT_ANALYTICS.FAILURE, ...error.data });
  }
}

function* workerGetEvent({ eventId }) {
  try {
    const token = yield select(getToken);
    const response = yield call(getEventAction, { token, eventId });
    const { event } = response.data;
    yield put(getEventSuccess(event));
  } catch (error) {
    yield put(getEventFailure({ error: error.response && error.response.data.message }));
    toast.error('Failed to load event');
  }
}
function* workerDeleteEvent({ id, consultantId, monthStart, monthEnd }) {
  try {
    const token = yield select(getToken);
    const response = yield call(deleteEventAction, { token, id });
    yield put(deleteEventSuccess(response.data.success));
    // yield put(closeConfirmModal());
    yield put(getStaffEventsRequest({ token, consultantId }));
    // yield put(closeEventModal());
    yield put(getEvents({ token, monthStart, monthEnd }));
    yield put(getLeadsRequest({ token }));
    yield put(closeEventDetailsDrawer());
    toast('Deleted successfully', {
      icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
    });
  } catch (error) {
    toast.error('Failed to delete event');
    yield put(deleteEventFailure(error.response.data.message, error.success));
  }
}

function* workerGetMoreEvents({ limit, startDate, endDate, startAfter }) {
  try {
    const token = yield select(getToken);
    const response = yield call(getEventsAction, { token, limit, startDate, endDate, startAfter });
    const { events } = response.data;
    yield put(getMoreEventsSuccess(events));
  } catch (error) {
    yield put(getEventsMoreFailure(error.response.data));
  }
}

export function* setPaymentStatusOnEvent({ eventId, status }) {
  try {
    yield put(setPaymentStatusOnEventSuccess({ eventId, status }));
  } catch (error) {
    // this is not a tragic error, the actual data has already been updated successfully,
    // this is merely so that opening up an event after having chnaged it, the object in
    // the other slice of the store will also be updated without an api call to refresh data
  }
}

export function* workerSearchEvent({ option }) {
  try {
    const token = yield select(getToken);
    const response = yield call(searchEvent, { token, option });
    console.log(response);
  } catch (error) {
    console.log(error);
  }
}

function* workerUpdateEventNote({ id, event }) {
  try {
    const token = yield select(getToken);
    const response = yield call(updateEventAction, { id, event, token });
    if (response.status === 200) {
      yield put(updateEventNoteSuccess(response.data.event));
      yield put(getEventRequest({ token, eventId: id }));
    }
  } catch (error) {
    yield put(updateEventNoteFailure(error));
  }
}

function* getUserTasksWorker() {
  try {
    const token = yield select(getToken);
    const response = yield call(getUserTasks, { token });
    const { success, userTasks } = response.data;
    if (success) {
      yield put(getEventTasksSuccess({ userTasks }));
    } else {
      yield put(getEventTasksFailure());
    }
  } catch (error) {
    yield put(getEventTasksFailure());
  }
}

function* workerUpdateEventChecklistItem(action) {
  try {
    const { token, checklistItem, eventId, role } = action;
    const response = yield call(updateEventChecklistItem, { token, eventId, checklistItem, role });

    if (response.data.success) {
      // yield put(updateEventChecklistItemSuccess(response.data.success));
      yield put(getEventRequest({ token, eventId }));
      toast('Updated successfully', {
        icon: <Icon as={IoIosCheckmarkCircle} color="var(--colors-primary)" />
      });
      yield put(closeChecklistModal());
    }
  } catch (error) {
    yield put(updateEventChecklistItemFailure(error.response && error.response.data));
    toast.error('Error saving doc');
  }
}

// EXPORTS

export function* watcherGetUserTasks() {
  yield takeLatest(GET_USER_TASKS_REQUEST, getUserTasksWorker);
}

export function* watcherGetEvents() {
  yield takeLatest(GET_EVENTS_REQUEST, workerGetEvents);
}

export function* watcherGetEvent() {
  yield takeLatest(GET_EVENT_REQUEST, workerGetEvent);
}

export function* watcherGetMoreEvents() {
  yield takeLatest(GET_MORE_EVENTS_REQUEST, workerGetMoreEvents);
}

export function* watchDeleteEvent() {
  yield takeLatest(DELETE_EVENT_REQUEST, workerDeleteEvent);
}

export function* watchSearchEvent() {
  yield takeLatest(SEARCH_EVENT_REQUEST, workerSearchEvent);
}

export function* watchUpdateEventNote() {
  yield takeLatest(UPDATE_EVENT_NOTE_REQUEST, workerUpdateEventNote);
}

export function* watchUpdateEventChecklistItem() {
  yield takeLatest(UPDATE_EVENT_CHECKLIST_ITEM_REQUEST, workerUpdateEventChecklistItem);
}

export function* watcherGetEventAnalytics() {
  yield takeLatest(GET_EVENT_ANALYTICS.REQUEST, workerGetEventAnalytics);
}
