import { FC, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { LeadFormWrapperProps } from 'src/Types/GlobalTypes';
import toast from 'react-hot-toast';
import { useLeads, useUser } from 'src/v2/Hooks/CollectionHooks';
import { BsCalendarCheck } from 'react-icons/bs';
import { Form } from './Form';
import { useDrawers } from '../../../Hooks/HelperHooks/DrawerHooks/useDrawers';

export const LeadForm: FC<LeadFormWrapperProps> = ({ defaultDate, mode, tabIndex }) => {
  const methods = useFormContext();

  const {
    eventManagementTabIndex,
    eventManagementVersion,
    changeEventManagementVersion,
    eventManagementDrawerOpen
  } = useDrawers();
  const { status = '' } = useWatch({ name: 'leadInfo' }) || {};

  useEffect(() => {
    // handle lead being booked
    if (
      status === 'eventBooked' &&
      eventManagementVersion === 'lead' &&
      eventManagementDrawerOpen
    ) {
      toast('You are now booking this event', {
        duration: 2000,
        position: 'top-right',
        icon: <BsCalendarCheck />
      });
      changeEventManagementVersion('book-event');
    }
    if (status && status !== 'eventBooked' && eventManagementVersion === 'book-event') {
      changeEventManagementVersion('lead');
    }
  }, [status, eventManagementVersion]);

  const { userId } = useUser();

  const { lead } = useLeads();

  const bookEvent = eventManagementVersion === 'event' || eventManagementVersion === 'book-event';

  const formProps = {
    lead,
    tabIndex: eventManagementTabIndex || tabIndex,
    methods,
    userId
  };
  return <Form {...formProps} defaultDate={defaultDate} bookEvent={bookEvent} mode={mode} />;
};
