import styled from 'styled-components';

const OverlayStyles = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  display: grid;
  place-items: center;
  background-color: #222222;
  opacity: 0.5;
  z-index: 999999;
`;

export default OverlayStyles;
