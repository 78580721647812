import styled from 'styled-components';
import Colors from '../../styles/Colors';

const SearchStyles = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;
`;

SearchStyles.Input = styled.input.attrs(props => ({
  name: props.name || '',
  value: props.value || '',
  type: props.type || 'text',
  placeholder: props.placeholder || '',
  autocomplete: props.autocomplete || 'on'
}))`
  width: ${props => props.width || '210px'};
  height: ${props => props.height || '50px'};
  border-radius: 25px;
  font-family: Lato;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  border: solid 1px #ededed;
  padding: 0 20px;
  background-color: #ffffff;
  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;

    &::placeholder {
      color: ${Colors.grayD8};
    }
  }

  &::placeholder {
    color: #b0b0b0;
    font-weight: 600;
  }
`;

SearchStyles.Icon = styled.div`
  position: absolute;
  top: ${props => (props.height ? `calc(${props.height} - 22px)` : '19.5px')};
  right: 13px;
`;

export default SearchStyles;
