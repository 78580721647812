// action types
import {
  VENUES_LIST_RESET,
  VENUES_LIST_REQUEST,
  VENUES_LIST_FAILURE,
  VENUES_LIST_SUCCESS,
  VENUES_LOADMORE_REQUEST,
  VENUES_LOADMORE_FAILURE,
  VENUES_LOADMORE_SUCCESS,
  VENUE_READ_RESET,
  VENUE_READ_REQUEST,
  VENUE_READ_FAILURE,
  VENUE_READ_SUCCESS,
  SET_TUTORIAL,
  GET_HOST_DETAILS_REQUEST,
  GET_HOST_DETAILS_FAILED,
  GET_HOST_DETAILS_SUCCESS,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE
} from './types';

// reducer with initial state
const initialState = {
  fetching: false,
  error: null,
  venue: {},
  venues: [],
  startAfter: null,
  currentTutorialIndex: 0,
  userDetails: null,
  status: null,
  fetchingUserDetailsErrors: null,
  layouts: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case VENUES_LIST_RESET:
      return {
        ...state,
        fetching: false,
        error: null,
        venues: null,
        startAfter: null
      };
    case VENUES_LIST_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null,
        startAfter: null
      };
    case VENUES_LIST_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case VENUES_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        venues: action.venues
      };
    case VENUES_LOADMORE_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null
      };
    case VENUES_LOADMORE_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case VENUES_LOADMORE_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        venues: state.venues.concat(action.venues),
        startAfter: action.venues[action.venues.length - 1] || null
      };
    case VENUE_READ_RESET:
      return {
        ...state,
        fetching: false,
        venue: {},
        error: null
      };
    case VENUE_READ_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null
      };
    case VENUE_READ_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        venue: {}
      };
    // hack to get layouts, need to refactor get layouts
    case VENUE_READ_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        venue: action.venue,
        layouts: action.layouts
      };
    case SET_TUTORIAL:
      return {
        ...state,
        currentTutorialIndex: action.currentTutorialIndex
      };
    case GET_HOST_DETAILS_REQUEST:
      return {
        ...state,
        status: 'fetchingUserDetails'
      };
    case GET_HOST_DETAILS_SUCCESS:
      return {
        ...state,
        status: 'fetchingUserSuccess',
        userDetails: action.hostDetails
      };
    case GET_HOST_DETAILS_FAILED:
      return {
        ...state,
        status: 'fetchingUserFailed',
        fetchingUserDetailsErrors: action.getHostError
      };

    case CREATE_PRODUCT_REQUEST:
      return {
        ...state,
        creatingProduct: true
      };
    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        creatingProduct: false
      };
    case CREATE_PRODUCT_FAILURE:
      return {
        ...state,
        creatingProduct: false
      };
    default:
      return state;
  }
};

export default reducer;
