import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import { useVenue } from 'src/v2/Hooks/CollectionHooks';
import { SelectInputWithLabel } from 'src/v2/UIComponents/FormComponents';
import {
  ALTERNATE_LAYOUTS_PLACEHOLDER,
  ALTERNATE_LAYOUTS_LABEL
} from 'src/v2/Drawers/EventFormDrawer/constants';
import { useDefaultValues } from 'src/v2/Hooks/CollectionHooks/Events/useDefaultValues';

export const AlternateLayoutsInput = () => {
  const {
    // formState: { dirtyFields },
    setValue,
    control
  } = useFormContext();

  const defaultValues = useDefaultValues();

  const { layouts } = useVenue();

  const defaultOptions = defaultValues?.layoutOptions;
  const currentLayoutOptions = useWatch({ control, name: 'layoutOptions' });
  const roomId = useWatch({ control, name: 'room' });
  const roomLayouts = layouts?.filter(layout => layout.roomId === roomId);
  const roomLayoutOptions = roomLayouts?.map(layout => ({
    value: layout.id,
    label: layout.name
  }));

  useEffect(() => {
    if (defaultOptions) {
      setValue('layoutOptions', defaultOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOptions]);

  const layoutExists = (layoutOption, optionsArray) =>
    optionsArray.find(option => option.value === layoutOption);

  useEffect(() => {
    const validLayoutArray = currentLayoutOptions?.filter(option =>
      layoutExists(option, roomLayoutOptions)
    );

    const validLayouts = validLayoutArray && validLayoutArray.length > 0;
    if (!validLayouts && currentLayoutOptions?.length > 0) {
      setValue('layoutOptions', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomLayoutOptions, currentLayoutOptions, roomId]);

  // useEffect(() => {
  //   if (roomId) {
  //     roomId && getLayoutOptions(roomId);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [roomId]);

  const alternateLayoutProps = {
    isMulti: true,
    label: ALTERNATE_LAYOUTS_LABEL,
    name: 'layoutOptions',
    options: roomLayoutOptions || [],
    placeholder: ALTERNATE_LAYOUTS_PLACEHOLDER,
    isLoading: false
  };

  return <SelectInputWithLabel {...alternateLayoutProps} />;
};

AlternateLayoutsInput.propTypes = {
  consultants: PropTypes.object,
  users: PropTypes.array,
  errors: PropTypes.object,
  register: PropTypes.func,
  control: PropTypes.object,
  staff: PropTypes.array,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  currentUserId: PropTypes.string,
  rooms: PropTypes.array,
  fetching: PropTypes.bool,
  tableLayoutOptions: PropTypes.array,
  roomId: PropTypes.string,
  setValue: PropTypes.func,
  dirtyFields: PropTypes.object
};
