import {
  OPEN_ADD_EVENT_MODAL,
  CLOSE_ADD_EVENT_MODAL,
  ADD_EVENT_REQUEST,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_LAYOUTS_REQUEST,
  GET_LAYOUTS_SUCCESS,
  GET_LAYOUTS_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  CHECK_EVENTCODE_REQUEST,
  CHECK_EVENTCODE_SUCCESS,
  CHECK_EVENTCODE_FAILURE,
  CHECK_HOSTEMAIL_REQUEST,
  CHECK_HOSTEMAIL_SUCCESS,
  CHECK_HOSTEMAIL_FAILURE,
  CREATE_HOST_USERS_FAILURE,
  CREATE_HOST_USERS_REQUEST,
  CREATE_HOST_USERS_SUCCESS,
  ADD_EVENT_DOCUMENTS_REQUEST,
  ADD_EVENT_DOCUMENTS_SUCCESS,
  ADD_EVENT_DOCUMENTS_FAILURE,
  SEND_EVENT_EMAIL,
  SET_LEAD_TYPE_REQUEST,
  SET_LEAD_TYPE_SUCCESS
} from './types';

export const openCreateEventModal = startDate => ({
  type: OPEN_ADD_EVENT_MODAL,
  startDate
});

export const closeCreateEventModal = () => ({
  type: CLOSE_ADD_EVENT_MODAL
});

export const addEventRequest = ({ event, token, venueId, monthStart = '', monthEnd = '' }) => ({
  type: ADD_EVENT_REQUEST,
  event,
  token,
  venueId,
  monthStart,
  monthEnd
});

export const addEventSuccess = ({ success, events }) => ({
  type: ADD_EVENT_SUCCESS,
  success,
  events
});

export const addEventFailure = error => ({
  type: ADD_EVENT_FAILURE,
  error
});

export const updateEventRequest = ({
  id,
  event,
  newUsers = [],
  token,
  venueId,
  isAppointment,
  closeAction,
  isLead,
  monthStart,
  monthEnd,
  navigate,
  getEventsWithLead
}) => ({
  type: UPDATE_EVENT_REQUEST,
  id,
  event,
  token,
  newUsers,
  venueId,
  isAppointment,
  closeAction,
  isLead,
  monthStart,
  monthEnd,
  navigate,
  getEventsWithLead
});

export const updateEventSuccess = ({ success, event }) => ({
  type: UPDATE_EVENT_SUCCESS,
  success,
  event
});

export const updateEventFailure = (error, errors) => ({
  type: UPDATE_EVENT_FAILURE,
  error,
  errors
});

type GetUsersRequestProps = {
  venueId: string;
  token: string;
  eventId?: string;
  role?: 'host' | 'staff';
};
export const getUsersRequest = ({ venueId, token, eventId, role }: GetUsersRequestProps) => ({
  type: GET_USERS_REQUEST,
  venueId,
  token,
  eventId,
  role
});

export const getUsersSuccess = ({ users }) => {
  return {
    type: GET_USERS_SUCCESS,
    users
  };
};

export const getUsersFailure = error => ({
  type: GET_USERS_FAILURE,
  error
});

export const getLayoutsRequest = ({ roomId, token }) => ({
  type: GET_LAYOUTS_REQUEST,
  roomId,
  token
});

export const getLayoutsSuccess = ({ layouts }) => {
  return {
    type: GET_LAYOUTS_SUCCESS,
    layouts
  };
};

export const getLayoutsFailure = error => ({
  type: GET_LAYOUTS_FAILURE,
  error
});

export const updateUser = ({ user, token }) => ({
  type: UPDATE_USER_REQUEST,
  user,
  token
});

export const updateUserSuccess = ({ user }) => ({
  type: UPDATE_USER_SUCCESS,
  user
});

export const updateUserFailed = ({ user }) => ({
  type: UPDATE_USER_FAILURE,
  user
});

export const checkEventCodeRequest = ({ token, venueId, eventCode, eventId }) => ({
  type: CHECK_EVENTCODE_REQUEST,
  token,
  venueId,
  eventCode,
  eventId
});

export const checkEventCodeSuccess = ({ success }) => ({
  type: CHECK_EVENTCODE_SUCCESS,
  success
});

export const checkEventCodeFailure = ({ error }) => ({
  type: CHECK_EVENTCODE_FAILURE,
  error
});

export const checkHostEmailRequest = ({ token, venueId, email, eventId, key }) => ({
  type: CHECK_HOSTEMAIL_REQUEST,
  token,
  venueId,
  email,
  eventId,
  key
});

export const checkHostEmailSuccess = ({ success, key }) => ({
  type: CHECK_HOSTEMAIL_SUCCESS,
  success,
  key
});

export const checkHostEmailFailure = ({ error, key }) => ({
  type: CHECK_HOSTEMAIL_FAILURE,
  error,
  key
});

export const createHostUsersRequest = ({ users, eventId }) => ({
  type: CREATE_HOST_USERS_REQUEST,
  users,
  eventId
});

export const createHostUsersSuccess = () => ({
  type: CREATE_HOST_USERS_SUCCESS
});

export const createHostUsersFailure = ({ error }) => ({
  type: CREATE_HOST_USERS_FAILURE,
  error
});

export const addEventDocumentsRequest = ({ defaultDocuments }) => ({
  type: ADD_EVENT_DOCUMENTS_REQUEST,
  defaultDocuments
});

export const addEventDocumentsSuccess = () => ({
  type: ADD_EVENT_DOCUMENTS_SUCCESS
});

export const addEventDocumentsFailure = ({ error }) => ({
  type: ADD_EVENT_DOCUMENTS_FAILURE,
  error
});

export const sendEventEmailOperations = {
  request: ({ recipient, cc, bcc, body, subject, attachments, eventId }) => {
    return {
      type: SEND_EVENT_EMAIL.REQUEST,
      recipient,
      cc,
      bcc,
      body,
      subject,
      attachments,
      eventId
    };
  },
  success: ({ eventData }) => ({
    type: SEND_EVENT_EMAIL.SUCCESS,
    eventData
  }),
  failure: () => ({ type: SEND_EVENT_EMAIL.FAILURE })
};

export const setLeadTypeRequest = (
  leadType: 'message' | 'duplicate' | 'unrelated' | 'original',
  leadToUpdateId: string,
  originalLeadId: string
) => ({
  type: SET_LEAD_TYPE_REQUEST,
  leadType,
  originalLeadId,
  leadToUpdateId
});

export const setLeadTypeSuccess = updatedLead => ({
  type: SET_LEAD_TYPE_SUCCESS,
  updatedLead
});
