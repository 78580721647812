import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import algoliasearch from 'algoliasearch';
import AsyncSelect from 'react-select/async';
import debounce from 'debounce-promise';
import Colors from 'src/styles/Colors';
import IconSearch from 'src/styles/Icons/Search';
import { useUser } from 'src/v2/Hooks/CollectionHooks';
import config from '../../../../.env.json';

const Search = ({ handleSelectOption }) => {
  const { venueId } = useUser();
  const handleSearchEvents = async option => {
    const client = algoliasearch(config.app_id, config.admin_api_id);
    const index = client.initIndex('events');

    if (option) {
      const response = await index.search(option, { filters: `venueId:${venueId}` });
      const { hits: events } = response;
      return events.map(event => {
        const dateLong = moment(event.start).format('MM/DD/YYYY');
        // todo sort by event title.
        return {
          value: event.start,
          label: `${event.title.toUpperCase()} - ${dateLong}`
        };
      });
    }
    return null;
  };

  const noOptions = input => {
    if (input.inputValue.length > 0) {
      return 'No events found that match your search.';
    }

    return 'Search for an event';
  };

  const loadOptions = option => handleSearchEvents(option);
  const debouncedLoadOptions = debounce(loadOptions, 300);

  const DropdownIndicator = () => <IconSearch color={Colors.grayB0} size={'16px'} />;

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      loadOptions={debouncedLoadOptions}
      noOptionsMessage={noOptions}
      styles={{
        container: styles => ({ ...styles, width: '240px', marginLeft: '1.5rem' }),
        valueContainer: styles => ({ ...styles, paddingLeft: '0' }),
        control: styles => ({
          ...styles,
          marginInlineStart: '0px',
          marginLeft: '20px',
          height: '50px',
          boxShadow: '0 2px 4px 0 rgba(125, 125, 125, 0.2)',
          border: 'solid 1px #ededed',
          padding: '0 20px',
          borderRadius: '25px',
          '&:hover': { border: 'solid 1px #ededed', cursor: 'text' }
        }),
        input: styles => ({
          ...styles,
          borderTop: 'none',
          fontSize: '15px',
          fontFamily: 'Lato',
          fontWeight: 'normal',
          fontStyle: 'normal',
          letterSpacing: '-0.1px'
        }),
        indicatorSeparator: styles => ({ ...styles, display: 'none' }),
        dropdownIndicator: styles => ({ ...styles, display: 'none' }),
        menu: styles => ({
          ...styles,
          width: 'fit-content',
          minWidth: '400px',
          '&:hover ::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(221, 221, 221, 0.3)',
            borderRadius: '6px'
          },
          '&:hover ::-webkit-scrollbar-button': {
            display: 'none'
          },
          '&:hover ::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(221, 221, 221, 0.8)',
            borderRadius: '6px'
          }
        }),
        singleValue: styles => ({
          ...styles,
          fontSize: '15px',
          textTransform: 'capitalize',
          fontWeight: 'normal'
        }),
        0: styles => ({
          ...styles,
          padding: '0px 2px'
        }),
        option: (styles, state) => ({
          ...styles,
          padding: '10px 20px',
          cursor: 'pointer',
          color: (state.isDisabled && Colors.grayB0) || (state && state.isSelected && Colors.black),
          backgroundColor: state.isSelected ? `var(--colors-primary-light)` : '#FFFFFF',
          '&:hover': (state.isDisabled && { cursor: ' not-allowed' }) || {
            backgroundColor: `var(--colors-primary-light)`
          }
        }),
        placeholder: styles => ({
          ...styles,
          padding: '2px 0px',
          fontSize: '15px',
          letterSpacing: '-0.28px',
          color: '#b0b0b0',
          fontWeight: 600,
          userSelect: 'none'
        })
      }}
      placeholder="Search events"
      components={{
        DropdownIndicator
      }}
      maxMenuHeight={200}
      closeMenuOnSelect
      onChange={handleSelectOption}
    />
  );
};

Search.propTypes = {
  handleSelectOption: PropTypes.func,
  venueId: PropTypes.string
};

export default Search;
