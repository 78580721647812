export const ALTERNATE_LAYOUTS_LABEL = 'Alternate Floorplans';
export const ALTERNATE_LAYOUTS_PLACEHOLDER =
  'Select all floorplans available for selection by client';
export const CLIENT_NAME_LABEL = 'Client Name';
export const CLIENT_NAME_PLACEHOLDER = 'e.g. Jack & Jill';
export const CLIENT_NAME_REQUIRED = 'Required';
export const EVENT_CONSULTANT_LABEL = 'Event Consultant';
export const EVENT_CONSULTANT_PLACEHOLDER = 'Select event consultant';
export const EVENT_CONSULTANT_REQUIRED = 'Required';
export const ADDITIONAL_DOCUMENTS_LABEL = 'Additional Documents';
export const ADDITIONAL_DOCUMENTS_PLACEHOLDER =
  'These documents will be automatically added to the document center';
export const EVENT_CODE_DISABLED_PLACEHOLDER = 'Photosharing is disabled for this event';
export const EVENT_CODE_LABEL = 'Event Code';
export const EVENT_CODE_PLACEHOLDER = `e.g. JACKJILL21`;
export const EVENT_CODE_MIN_LENGTH = 'Must be four characters or more';
export const EVENT_CODE_MAX_LENGTH = 'Must be 15 characters or fewer';
export const EVENT_CODE_REQUIRED = 'Required if guest app is turned on';
export const EVENT_DATE_LABEL = 'Event Date & Time';
export const EVENT_DATE_PLACEHOLDER = 'Set event date & time';
export const EVENT_DATE_REQUIRED = 'Required';
export const EVENT_NAME_LABEL = 'Event Name';
export const EVENT_NAME_PLACEHOLDER = `e.g. Jack & Jill's Wedding`;
export const EVENT_TEAM_LABEL = 'Event Team';
export const EVENT_TEAM_PLACEHOLDER = 'Select event team';
export const EVENT_TEAM_REQUIRED = 'Required';
export const EVENT_TYPE_LABEL = 'Event Type';
export const EVENT_TYPE_PLACEHOLDER = `Select event type`;
export const EVENT_TYPE_REQUIRED = 'Required';
export const GUEST_MINIMUM_LABEL = 'Guest Count';
export const GUEST_MINIMUM_PLACEHOLDER = 'Set guest count';
export const GUEST_MINIMUM_REQUIRED = 'Required';
export const LAYOUT_LABEL = 'Floorplan';
export const LAYOUT_LOCK_TOOLTIP = ``;
export const LAYOUT_PLACEHOLDER = 'Select floorplan';
export const LAYOUT_REQUIRED = 'Required';
export const NOTES_LABEL = 'Internal Event Notes';
export const NOTES_PLACEHOLDER = 'Only event staff can see these notes';
export const PAYMENT_PLACEHOLDER = 'Add a payment date';
export const PHOTOSHARING_TOOLTIP = 'Disable the guest mobile app for this event';
export const ROOM_LABEL = 'Room';
export const ROOM_PLACEHOLDER = 'Select room';
export const ROOM_REQUIRED_ERROR = 'is required';
export const USER_EMAIL_PLACEHOLDER = 'jacksmith@gmail.com';
export const USER_FIRSTNAME_PLACEHOLDER = 'Jack';
export const USER_LASTNAME_PLACEHOLDER = 'Smith';
export const BOOKING_DATE = {
  LABEL: 'Booking Date',
  PLACEHOLDER: 'Booking Date',
  REQUIRED: 'Booking Date is Required'
};
