/* eslint-disable import/prefer-default-export */
import { HIDE_SIDEBAR } from './types';

const initialState = {
  hideSidebar: true
};

export const appContainerReducer = (state = initialState, action) => {
  switch (action.type) {
    case HIDE_SIDEBAR:
      return {
        ...state,
        hideSidebar: !state.hideSidebar
      };
    default:
      return state;
  }
};
