import { useEffect, useState } from 'react';
import { Tooltip } from 'src/v2/UIComponents/Primitives';
import {
  useDocuments,
  useEvents,
  useEventsDetails,
  useLeads,
  useUser
} from 'src/v2/Hooks/CollectionHooks';
import { useTableContext } from 'src/v2/context/Tables';
import { Table } from 'src/v2/Table';
import DocumentsListTableStyles from 'src/components/DocumentsListTable/styles';
import { Controls } from 'src/v2/UIComponents/Table';
import DocVersion from 'src/styles/Icons/DocVersion';
import Comment from 'src/styles/Icons/Comment';
import ReactTooltip from 'react-tooltip';
import { useConfirm, useDrawers } from 'src/v2/Hooks/HelperHooks';
import { EventDocumentDrawer } from 'src/v2/Drawers/EventDocumentDrawer';
import { UploadDocumentVersion } from 'src/v2/Drawers/UploadDocumentVersion';
import { DocumentDetailsDrawer } from 'src/v2/Drawers/DocumentDetailsDrawer';
import { EventTableDocument } from 'venuex-types';
import { getDateDisplay } from 'src/utils/getDateDisplay';

const EventDocumentsTable = ({ tableType = 'lead' }) => {
  // TODO #675 Event Details Documents Table: Replace with same table in leads details
  const { role, venueId, userId } = useUser();
  const { activeEvent: event, fetchingEvent: fetchingLead } = useEvents();
  const { isEventDetailsDrawerOpen } = useEventsDetails();
  const { isLeadsDetailsOpen } = useLeads();
  const [documents, setDocuments] = useState<Array<EventTableDocument | undefined>>([]);
  const [eventId, setEventId] = useState('');
  const {
    deleteDocument,
    autoFillDocument,
    openUploadDocumentVersionDrawer,
    selectDocument,
    downloadDocument
  } = useDocuments();

  const { clearContextData, tableData } = useTableContext();
  const { openEventDocumentDrawer, openDocumentDetailsDrawer } = useDrawers();
  const { documents: eventDocs } = event;

  // const eventDocs = unfilteredDocuments?.filter((i): i is EventTableDocument => {
  //   return typeof i !== 'undefined';
  // });

  const [fetchingDocuments, setFetchingDocuments] = useState(true);

  useEffect(() => {
    let timeoutId;
    if (!fetchingLead && fetchingDocuments) {
      timeoutId = setTimeout(() => {
        setFetchingDocuments(false);
      }, 350);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [fetchingLead, fetchingDocuments]);

  useEffect(() => {
    if (eventDocs) {
      setDocuments(eventDocs);
      setEventId(event?.id);
    }
  }, [eventDocs]);

  // useEffect(() => {
  //   if ((!isEventDetailsDrawerOpen && role !== 'host') || !isLeadsDetailsOpen) {
  //     clearContextData();
  //     setDocuments([]);
  //   }
  // }, [isEventDetailsDrawerOpen, isLeadsDetailsOpen, role]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [documents]);

  const [toDelete, setToDelete] = useState('');
  const handleViewDocument = data => {
    openDocumentDetailsDrawer(data);
  };
  const handleDeleteDocument = () => deleteDocument({ documentId: toDelete, eventId });
  const confirmId = 'deleteDocument';
  const { openConfirm } = useConfirm({
    acceptAction: handleDeleteDocument,
    confirmHookId: confirmId
  });
  const handleClickDelete = id => () => {
    setToDelete(id);
    openConfirm({
      title: `Delete Document`,
      confirmType: 'cancel',
      description: 'Are you sure you want to delete this document?',
      confirmId
    });
  };
  const handleAutoFill = ({ cellData }) => {
    const { documentId: docId, documentName } = cellData;
    autoFillDocument({ eventId, venueId, docId, documentName });
  };

  const handleDownload = ({ cellData }) => {
    const { documentId: docId, filename, label, url } = cellData;
    const fileType = filename.split('.').pop();
    const downloadName = `${label}.${fileType}`;
    downloadDocument({ eventId, docId, filename: downloadName, url, venueId });
  };

  const handleUpdateVersion = cellData => {
    openUploadDocumentVersionDrawer();
    selectDocument({ document: cellData });
  };

  const handleDownloadClick = async ({ cellData }) => {
    const { formDoc, url } = cellData;
    if (formDoc) {
      handleAutoFill({ cellData });
    } else {
      const fileExtension = url
        .split('.')
        .pop()
        .toLowerCase();
      if (fileExtension === 'pdf') {
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '_blank';
        anchor.download = url.split('/').pop();
        anchor.click();
      } else {
        handleDownload({ cellData });
      }
    }
  };

  const columns = [
    {
      dataKey: '',
      size: 'sm',
      cellDataGetter: ({ rowData }: { rowData: EventTableDocument }) => ({
        documentId: rowData.documentId,
        comments: rowData.comments,
        versions: rowData.versions,
        documentType: rowData.documentType
      }),
      cellRenderer: ({
        cellData,
        rowData
      }: {
        cellData: EventTableDocument;
        rowData: EventTableDocument;
      }) => {
        const versionCount = cellData?.versions?.length || 0;
        const commentCount = cellData?.comments?.length || 0;

        return (
          <DocumentsListTableStyles.Rows>
            <Tooltip label="Versions & Comments" placement="right">
              <DocumentsListTableStyles.MetaWrapper onClick={() => handleViewDocument(rowData)}>
                <DocumentsListTableStyles.MetaRow>
                  <DocVersion size={18} /> {versionCount + 1}
                </DocumentsListTableStyles.MetaRow>

                <DocumentsListTableStyles.MetaRow>
                  <Comment size={22} /> {commentCount}
                </DocumentsListTableStyles.MetaRow>
              </DocumentsListTableStyles.MetaWrapper>
            </Tooltip>
          </DocumentsListTableStyles.Rows>
        );
      }
    },

    {
      label: 'Document Name',
      dataKey: 'label',
      size: 'xlg',
      bold: true
    },
    {
      label: 'Description',
      size: 'xlg',
      dataKey: 'description',
      cellRenderer: ({ rowData }: { rowData: EventTableDocument }) => {
        return rowData.description;
      }
    },
    {
      label: 'Last Updated',
      cellDataGetter: ({ rowData }: { rowData: EventTableDocument }) => {
        const updatedDate = rowData.updated || rowData.created;
        return {
          value: (updatedDate && getDateDisplay(updatedDate).shortDateTime) || ''
        };
      },
      size: 'lg',
      componentType: 'date',
      dateFormat: 'short'
    },
    {
      label: 'Last Updated By',
      size: 'lg',
      dataKey: 'userName'
    },
    {
      dataKey: '',
      cellDataGetter: ({ rowData }) => ({ ...rowData }),
      cellRenderer: ({ cellData }: { cellData: EventTableDocument }) => {
        const { userId: createdBy } = cellData;
        // const isContract = documentType === 'Contract';

        const hideDeleteActionHost = userId !== createdBy && role !== 'staff';

        return (
          <Controls
            // packetClickUrl={isContract && anvilData?.detailsURL}
            isFormDoc={false}
            hideDeleteAction={hideDeleteActionHost}
            hideAddNewAction={hideDeleteActionHost}
            deleteProps={{ onClick: handleClickDelete(cellData.documentId) }}
            // downloadProps={{ onClick: () => handleAutoFill({ cellData }), url: cellData.url }}
            addProps={{ onClick: () => handleUpdateVersion(cellData) }}
            handleDownloadClick={() => handleDownloadClick({ cellData })}
            editProps={undefined}
            archiveProps={undefined}
            customAction={undefined}
            openDetailsProps={undefined}
            sendMessageProps={undefined}
          />
        );
      }
    }
  ];
  const emptyState = {
    title: 'No documents added yet.',
    subTitle: 'Add one now!',
    btnLabel: 'Add New Document',
    btnAction: openEventDocumentDrawer
  };

  return (
    <>
      <UploadDocumentVersion />
      <EventDocumentDrawer />
      <DocumentDetailsDrawer />
      <Table
        tableId="eventDocuments"
        columns={columns}
        data={!fetchingDocuments && documents}
        // hideBorders
        emptyStateProps={emptyState}
        isLoading={fetchingDocuments}
      />
    </>
  );
};

export default EventDocumentsTable;
