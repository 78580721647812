import React from 'react';
import PropTypes from 'prop-types';
import { Controls } from './styles';
import Edit from '../../styles/Icons/Edit';
import Delete from '../../styles/Icons/Delete';

const RowControls = ({ handleEdit, handleDelete, disableDelete, disableEdit }) => {
  return (
    <Controls>
      {!disableEdit && <Edit onClick={handleEdit} />}
      {!disableDelete && <Delete onClick={handleDelete} size={22} />}
    </Controls>
  );
};

RowControls.propTypes = {
  action: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  disableDelete: PropTypes.bool,
  disableEdit: PropTypes.bool
};

export default RowControls;
