import React from 'react';
import {
  NOTES_LABEL,
  NOTES_PLACEHOLDER
} from 'src/v2/Drawers/Leads/NotesDrawer/NotesForm/constants';
import { TextAreaWithLabel } from 'src/v2/UIComponents/FormComponents';

export const RootNoteInput = () => {
  const notesProps = {
    label: NOTES_LABEL,
    placeholder: NOTES_PLACEHOLDER,
    name: 'note'
  };
  return (
    <TextAreaWithLabel {...notesProps} resize="none" placeholder={NOTES_PLACEHOLDER} isRequired />
  );
};

export const NoteInput = React.memo(RootNoteInput);
