import React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Box, Text, HStack, Stack, Center, Icon, GridItem } from '@chakra-ui/react';
import { BsTrash } from 'react-icons/bs';
import { checkHostEmail } from 'src/containers/AddEventModal/actions';
import { useUser, useEvents } from 'src/v2/Hooks/CollectionHooks';
import { TextFieldWithLabel } from 'src/v2/UIComponents/FormComponents';
import { IconButton, Button } from 'src/v2/UIComponents/Primitives';
import { TextFieldProps } from 'src/v2/UIComponents/FormComponents/TextFieldWithLabel/types';
import { Row } from 'src/components/AddEvent/AddEventBody';

import {
  USER_FIRSTNAME_PLACEHOLDER,
  USER_EMAIL_PLACEHOLDER,
  USER_LASTNAME_PLACEHOLDER
} from './types';

export interface UsersInputProps {}

type NewUser = {
  firstname: string;
  lastname: string;
  email: string;
  role: 'host';
};
export interface UserField extends Omit<NewUser, 'billingContact'> {
  id?: string;
  docId?: string;
}

export const RootUsersInput = ({ bookEvent }) => {
  const methods = useFormContext<{ users: UserField[] }>();
  const {
    control,
    register,
    formState: { errors }
  } = methods;

  const { append: appendUser, fields: userFields, remove: removeUsers } = useFieldArray({
    control,
    name: 'users'
  });

  const watchUsers = useWatch({ control, name: 'users' });
  const { venueId, token } = useUser();
  const { activeEventId: eventId } = useEvents();

  const doCheckHostEmail = async ({ email, key }) => {
    try {
      const result = await checkHostEmail({ token, venueId, email, eventId, key });
      if (result) {
        const showError = result?.data?.userExists;
        if (showError) return 'This email address is already being used for another event';
        return showError;
      }
      return false;
    } catch (error) {
      return true;
    }
  };

  const formMethods = { register, errors };
  const partialError = 'Alld fields must be completed or empty';
  // const debouncedCheckEmail = debounce(doCheckHostEmail, 500);

  return (
    <>
      {userFields &&
        userFields.map((user, i) => {
          const userKey = `user${i}`;
          const firstNameBool = watchUsers && !!watchUsers?.[i]?.firstname?.length;
          const lastNameBool = watchUsers && !!watchUsers?.[i]?.lastname?.length;
          const emailBool = watchUsers && !!watchUsers?.[i]?.email?.length;
          const userFirstNameProps: TextFieldProps = {
            name: `users[${i}].firstname`,
            fieldArray: {
              index: i,
              groupName: 'users',
              fieldName: 'firstname'
            },
            rules: {
              required:
                (i === 0 && bookEvent && 'Required') ||
                ((lastNameBool || emailBool) && partialError),
              maxLength: 40
            },
            label: 'First Name',
            placeholder: USER_FIRSTNAME_PLACEHOLDER,
            isRequired: lastNameBool || emailBool || bookEvent
          };
          const userLastNameProps: TextFieldProps = {
            name: `users[${i}].lastname`,
            fieldArray: {
              index: i,
              groupName: 'users',
              fieldName: 'lastname'
            },
            rules: {
              required:
                (i === 0 && bookEvent && 'Required') ||
                ((firstNameBool || emailBool) && partialError),
              maxLength: 40
            },
            label: 'Last Name',
            placeholder: USER_LASTNAME_PLACEHOLDER,
            isRequired: firstNameBool || emailBool || bookEvent
          };
          const userEmailProps: TextFieldProps = {
            name: `users[${i}].email`,
            fieldArray: {
              index: i,
              groupName: 'users',
              fieldName: 'email'
            },
            rules: {
              required:
                (i === 0 && bookEvent && 'User Email is Required') ||
                ((firstNameBool || lastNameBool) && partialError),

              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address'
              },
              validate: {
                exists: async email => {
                  const result = await doCheckHostEmail({ email, key: i });
                  if (result) {
                    return result;
                  }
                  return undefined;
                }
              }
            },
            isRequired: bookEvent,
            label: 'Email Address',
            placeholder: USER_EMAIL_PLACEHOLDER
          };
          const firstName = (watchUsers && watchUsers[i] && watchUsers[i].firstname) || '';
          const lastName = (watchUsers && watchUsers[i] && watchUsers[i].lastname) || '';
          const dash = ((firstName?.length || lastName?.length) && '-') || '';
          return (
            <Box key={userKey}>
              <HStack justify="space-between" w="100%" align="center" mb="20px">
                <Text fontSize="15.5px" fontWeight="600" color="var(--colors-primary)">
                  User {i + 1} {` ${dash}  ${firstName} ${lastName}`}
                </Text>

                {i > 0 && (
                  <Center>
                    <IconButton
                      aria-label="test"
                      colorScheme="red"
                      variant="ghost"
                      icon={<Icon as={BsTrash} w={4} h={4} />}
                      tooltipLabel="Delete User"
                      onClick={() => removeUsers(i)}
                    />
                  </Center>
                )}
              </HStack>

              <input
                type="hidden"
                defaultValue={(user.docId && user.docId) || ''}
                {...register(`users.${i}.docId` as const)}
              />
              <Stack minWidth={0}>
                <HStack display="grid" gridTemplateColumns={'1fr 1fr'}>
                  <GridItem minW={0}>
                    <TextFieldWithLabel {...userFirstNameProps} {...formMethods} />
                  </GridItem>
                  <GridItem minW={0}>
                    <TextFieldWithLabel {...userLastNameProps} {...formMethods} />
                  </GridItem>
                </HStack>
                <TextFieldWithLabel {...userEmailProps} {...formMethods} />
              </Stack>
            </Box>
          );
        })}
      <Row style={{ justifyContent: 'flex-end' }}>
        <Button
          variant="outline"
          color="var(--colors-primary)"
          onClick={() => appendUser([{ firstname: '', lastname: '', email: '', role: 'host' }])}
        >
          Add User
        </Button>
      </Row>
    </>
  );
};

export const UsersInput = React.memo(RootUsersInput);
