import { useState, useEffect } from 'react';
import { BREAKPOINTS } from 'src/utils/Constants';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    isMobile: false,
    isTablet: false,
    isDesktop: false
  });

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.outerWidth <= BREAKPOINTS.sm;
      const isTablet = window.outerWidth > BREAKPOINTS.sm && window.outerWidth < BREAKPOINTS.md;
      const isDesktop = window.outerWidth >= BREAKPOINTS.lgs;

      setWindowSize({
        width: window.outerWidth,
        height: window.outerWidth,
        isMobile,
        isTablet,
        isDesktop
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};
