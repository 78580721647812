import axios from 'axios';
import config from 'src/.env.json';

const { apiUri } = config;

export const createFieldAction = ({ newField, token }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/customFields`,
    headers: { Authorization: token },
    data: { newField }
  });
};

export const getFieldsAction = ({ token, eventType, role }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/customFields/`,
    headers: { Authorization: token },
    params: { role, eventType }
  });
};

export const updateFieldAction = ({ propertiesToUpdate, token }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/customFields`,
    headers: { Authorization: token },
    data: { propertiesToUpdate }
  });
};
