import { EventFormValues, UpdatedUser } from 'venuex-types';
import { useUser } from 'src/v2/Hooks/CollectionHooks';
import { useDrawers } from '../../HelperHooks/DrawerHooks/useDrawers';
import { useEvents } from './useEvents';

export interface NewFormUser {
  firstname: string;
  lastname: string;
  email: string;
}
export interface NewEventFormValues extends Omit<EventFormValues, 'users'> {
  users?: (NewFormUser | UpdatedUser)[];
}

export const useDefaultValues = (): NewEventFormValues | undefined => {
  const { activeEvent } = useEvents();
  const { userId } = useUser();
  const { startTime, eventManagementMode, eventManagementVersion } = useDrawers();
  const newUser: NewFormUser = {
    firstname: '',
    lastname: '',
    email: ''
  };

  const formValues = activeEvent?.formValues || undefined;
  if (!formValues && eventManagementMode === 'create') {
    if (eventManagementVersion === 'lead') {
      return {
        salesperson: userId,
        staff: [userId],
        leadInfo: {
          status: 'new',
          leadDate: new Date().toISOString()
        },
        users: [newUser]
      };
    }
    return {
      consultant: userId,
      staff: [userId],
      users: [newUser],
      start: startTime,
      leadInfo: {
        status: 'eventBooked'
      }
    };
  }
  if (formValues && eventManagementMode === 'edit') {
    if (eventManagementVersion === 'book-event') {
      return {
        ...formValues,
        leadInfo: {
          ...formValues.leadInfo,
          status: 'eventBooked'
        }
      };
    }
    return formValues;
  }

  return undefined;
};
