/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import { VStack, Stack } from '@chakra-ui/react';
import { useDocumentLibrary } from 'src/v2/Hooks/CollectionHooks';
import { useFormContext } from 'react-hook-form';

import { SelectInputWithLabel } from '../../UIComponents/FormComponents/SelectInputWithLabel/index';
import { EventDocumentName } from './Inputs/DocumentName';

export const AddLibraryDocument = () => {
  const { documents: documentLibraryFiles } = useDocumentLibrary();
  const { watch, register, setValue } = useFormContext();

  const watchFile = watch('documentUpload');
  const watchLibraryDocument = watch('libraryDocument');

  useEffect(() => {
    register('documentUpload', {
      required: (!watchLibraryDocument && 'A File is required') || false
    });
  }, [register, watchLibraryDocument, watchFile]);

  useEffect(() => {
    setValue('label', watchLibraryDocument && watchLibraryDocument.label);
    setValue('description', watchLibraryDocument && watchLibraryDocument.description);
    setValue('filename', watchLibraryDocument && watchLibraryDocument.filename);
  }, [watchLibraryDocument, setValue]);

  const documentLibraryOptions =
    documentLibraryFiles &&
    documentLibraryFiles
      .filter(doc => doc.documentType !== 'Advanced - Default')
      .map(doc => {
        return {
          value: {
            value: doc.documentId,
            label: doc.documentName,
            filename: doc.documentName,
            url: doc.url,
            description: doc.description,
            reference: doc.documentId,
            formDoc: false
          },
          label: doc.documentName
        };
      });

  return (
    <Stack width="100%" alignItems="stretch" paddingTop="16px" justifyContent="space-between">
      <VStack spacing={8}>
        <SelectInputWithLabel
          name="libraryDocument"
          label="Add a document from the library"
          options={(documentLibraryOptions && documentLibraryOptions) || []}
          placeholder="Select a document from the library"
          isDisabled={watchFile}
        />

        <EventDocumentName />
      </VStack>
    </Stack>
  );
};
