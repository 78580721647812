import { Text, Flex } from '@chakra-ui/react';
import { Button } from 'src/v2/UIComponents/Primitives/Button';
import { AddPlus } from 'src/v2/UIComponents/Primitives/Icons';

export const CardEmptyState = ({ title, btnLabel, btnAction, ...props }) => (
  <Flex
    width="100%"
    height="100%"
    flexDir="column"
    alignItems="center"
    justifyContent="center"
    {...props}
  >
    <Text
      fontFamily="Lato"
      fontWeight={400}
      fontSize="18px"
      lineHeight="20px"
      color="#A0A4A8"
      mb="16px"
    >
      {title}
    </Text>
    {btnLabel && (
      <Button
        onClick={btnAction}
        variant="primaryOutline"
        size="sm"
        width="180px"
        leftIcon={<AddPlus w={5} h={5} color="var(--colors-primary)" />}
      >
        {btnLabel}
      </Button>
    )}
  </Flex>
);
