import styled from 'styled-components';

const ChecklistTableStyles = styled.div`
  .ReactVirtualized__Table__rowColumn {
    white-space: normal;
  }
  height: calc(100vh - 242px);
  border: solid 1px #ededed;
  width: 100%;
`;

ChecklistTableStyles.Name = styled.div`
  text-decoration: ${props => props.completed && 'line-through'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 45px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-weight: 500;
  min-height: 70px;
  overflow-wrap: break-word;
  color: red;
`;

ChecklistTableStyles.NoEditIcon = styled.span`
  display: flex;
  justify-content: flex-end;
  svg {
    margin: 15px;
    opacity: 0;
    cursor: pointer;
  }
`;

ChecklistTableStyles.Toggle = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-family: Lato;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.26px;
  color: #888888;

  svg {
    margin-left: 10px;
  }
`;

ChecklistTableStyles.NoRows = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

ChecklistTableStyles.NoRowsTitle = styled.span`
  font-family: Lato;
  font-size: 36px;
  font-weight: 500;
  line-height: 50px;
  color: #888;
  letter-spacing: -0.45px;
`;

ChecklistTableStyles.NoRowsSubtitle = styled.span`
  font-family: Lato;
  font-size: 24px;
  font-weight: 500;
  line-height: 50px;
  color: #888;
  letter-spacing: -0.45px;
`;

export default ChecklistTableStyles;
