const Colors = {
  grayAA: '#aaaaaa',
  grayFA: '#fafafa',
  grayEE: '#eeeeee',
  grayED: '#ededed',
  grayD8: '#d8d8d8',
  grayB0: '#b0b0b0',
  grayCC: '#cccccc',
  gray88: '#888888',
  gray7D: '#7d7d7d',
  gray18: '#181818',
  beigeC0: '#c0b69b',
  beigeC0A: '#c0b69b80',
  green2C: '#2cb070',
  green03: '#03B575',
  yellowF9: '#f9cc4f',
  redC0: '#c02026',
  redE7: '#E74F48',
  white: '#ffffff',
  black: '#000000',
  black22: '#222222',
  black11: '#111111',
  grayED7: '#EDF2F7',
  grayE8: '#E8E8E8',
  grayF7: '#F7F7F7',
  border: '#ededed',
  background: '#ffffff'
};

export default Colors;

// f7f7f7 (figma) --> use grayFA instead
// 52575c (figma) --> use gray18 instead
// c5c5c5 (figma) --> use grayCC instead
