import {
  CHECK_CSV_REQUEST,
  CANCEL_AFTER_CSV_PARSE,
  OPEN_FILE_MODAL,
  CLOSE_FILE_MODAL
} from './types';

export const onFileSelected = ({ formData, eventId, venueId }) => ({
  type: CHECK_CSV_REQUEST,
  formData,
  venueId,
  eventId
});
export const onCancelAfterParse = () => ({ type: CANCEL_AFTER_CSV_PARSE });
export const openFileModal = () => ({ type: OPEN_FILE_MODAL });
export const closeFileModal = () => ({ type: CLOSE_FILE_MODAL });
