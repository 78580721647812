import { FC } from 'react';
import { LEAD_SOURCE_LABEL } from 'src/v2/Drawers/LeadsFormDrawer/constants';
import { useVenue } from 'src/v2/Hooks/CollectionHooks';
import { SelectInputWithLabel } from 'src/v2/UIComponents/FormComponents';
import { LeadSourceInput, SelectInputWithLabelProps } from '../../../../../Types/GlobalTypes';

export const LeadSource: FC<LeadSourceInput> = ({ bookEvent }) => {
  const { leadSources, fetchingFireStore } = useVenue();
  const errorKey = { groupKey: 'leadInfo', inputName: 'source' };
  const LeadSourceProps: SelectInputWithLabelProps = {
    name: 'leadInfo.source',
    options: leadSources || [
      {
        value: 'none',
        label: 'No Options'
      }
    ],
    isDisabled: !leadSources,
    label: LEAD_SOURCE_LABEL,
    placeholder: LEAD_SOURCE_LABEL,
    isRequired: !bookEvent,
    errorKey,
    rules: {
      required: !bookEvent && 'Lead Source Is Required'
    }
  };

  return <SelectInputWithLabel isLoading={fetchingFireStore} {...LeadSourceProps} />;
};
