import React from 'react';
import { useDocuments } from 'src/v2/Hooks/CollectionHooks';
import { FormDrawer } from 'src/v2/UIComponents/Drawers';
import { useConfirm } from 'src/v2/Hooks/HelperHooks';
import { useForm, FormProvider } from 'react-hook-form';
import { DEFAULT_DRAWER_CANCEL, DISCARD_CHANGES } from 'src/v2/utils/constants';
import { Wizard } from 'src/v2/UIComponents/Drawers/Wizard';
import { Form } from './Form';
import { UploadDocument } from '../EventDocumentDrawer/UploadDocument';

export const UploadDocumentVersion = () => {
  const {
    isUploadDocumentVersionDrawerOpen,
    closeUploadDocumentVersionDrawer,
    handleUpdateVersionDocument,
    clearDocument
  } = useDocuments();

  const closeDrawer = () => {
    closeUploadDocumentVersionDrawer();
    clearDocument();
  };

  const { openConfirm } = useConfirm({
    acceptAction: closeDrawer,
    confirmHookId: 'uploadDocumentVersionDrawer'
  });

  const requestClose = () => {
    openConfirm({
      description: DEFAULT_DRAWER_CANCEL,
      title: DISCARD_CHANGES,
      confirmId: 'uploadDocumentVersionDrawer',
      confirmType: 'cancel'
    });
  };

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  const { handleSubmit, formState } = methods;
  const { isValid } = formState;

  const handleSave = formData => {
    handleUpdateVersionDocument({ formData });
  };

  const isRightButtonDisabled = !isValid;

  // return (
  //   <FormDrawer
  //     isOpen={isOpen}
  //     closeDrawer={requestClose}
  //     isFetching={false}
  //     title="Add New Document Version"
  //     showFooter
  //     footerOptions={{
  //       leftButton: {
  //         onClick: requestClose,
  //         isLoading: false
  //       },
  //       rightButton: {
  //         onClick: handleSubmit(onSubmit),
  //         isLoading: false,
  //         isDisabled: isRightButtonDisabled
  //       }
  //     }}
  //   >
  //     <FormProvider {...methods}>
  //       <Form {...methods} />
  //     </FormProvider>
  //   </FormDrawer>
  // );

  const steps = [
    { component: () => <UploadDocument version />, step: 0, title: 'Add New Version' }
  ];

  return (
    <Wizard
      defaultValues={{}}
      steps={steps}
      saveAction={handleSave}
      cancelAction={closeDrawer}
      isOpen={isUploadDocumentVersionDrawerOpen}
      formId="eventDocumentDrawer"
    />
  );
};
