// import { ComponentStyleConfig } from '@chakra-ui/react';

export const Accordion = {
  baseStyle: {
    container: {
      _last: {
        borderBottomWidth: 0
      },
      _first: {
        borderTopWidth: 0
      }
    }
  }
};
