import { useSelector, useDispatch } from 'react-redux';
import {
  closeEventModal,
  setActiveItem,
  openEventDetailsDrawer,
  closeEventDetailsDrawer,
  openEventFormDrawer,
  closeEventFormDrawer
} from 'src/containers/EventDetailsModal/operations';
import { useActions } from 'src/hooks/useActions';

export const useEventsDetails = () => {
  const dispatch = useDispatch();

  const eventModalState = useSelector(state => state.eventDetailsModalReducer);
  const {
    isOpen,
    event,
    activeItem,
    fetchingEvent,
    isEventDetailsDrawerOpen,
    isEventFormDrawerOpen
  } = eventModalState;

  // actions
  const actions = useActions({
    closeEventModal,
    setActiveItem,
    openEventDetailsDrawer,
    closeEventDetailsDrawer,
    openEventFormDrawer,
    closeEventFormDrawer
  });

  return {
    isOpen,
    event,
    activeItem,
    fetchingEvent,
    isEventDetailsDrawerOpen,
    isEventFormDrawerOpen,
    ...actions
  };
};
