import React from 'react';
import {
  PROSPECT_PHONE_LABEL,
  PROSPECT_PHONE_PLACEHOLDER,
  PROSPECT_PHONE_REQUIRED
} from 'src/v2/Drawers/LeadsFormDrawer/constants';
import { TextFieldWithLabel } from 'src/v2/UIComponents/FormComponents';

const RootProspectPhoneInput = props => {
  const prospectPhoneProps = {
    name: 'leadInfo.prospectPhone',

    label: PROSPECT_PHONE_LABEL,
    placeholder: PROSPECT_PHONE_PLACEHOLDER
  };
  return <TextFieldWithLabel {...prospectPhoneProps} {...props} />;
};

export const ProspectPhoneInput = React.memo(RootProspectPhoneInput);
