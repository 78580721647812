import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Colors from 'src/styles/Colors';
import { useEvents } from 'src/v2/Hooks/CollectionHooks';
import { ComposerComponents } from './Components';

export const Composer = () => {
  const methods = useForm();
  const { sendEventEmail, activeEventId } = useEvents();
  const { handleSubmit } = methods;
  const [minimized, setMinimized] = useState(false);
  const minimize = () => setMinimized(!minimized);
  const sendEmail = data => {
    const dataToSend = { ...data, eventId: activeEventId };
    sendEventEmail(dataToSend);
  };
  return (
    <FormProvider {...methods}>
      <motion.div
        animate={{
          height: (minimized && '50px') || '605px'
        }}
        transition={{ type: 'tween' }}
      >
        <Box
          height={'600px'}
          width="600px"
          backgroundColor={Colors.white}
          marginRight="100px"
          marginBottom="5px"
          boxShadow={'base'}
          display="flex"
          flexDirection={'column'}
          minHeight={'1px'}
          overflow="hidden"
          // border={`1px solid ${Colors.grayED}`}
        >
          <ComposerComponents.Header
            subject={'New Email'}
            minimize={minimize}
            minimized={minimized}
          />

          <ComposerComponents.ToField />
          <ComposerComponents.SubjectField />
          <ComposerComponents.Attachments />
          <ComposerComponents.Body />
          <ComposerComponents.Footer sendEmail={handleSubmit(sendEmail)} />
        </Box>
      </motion.div>
    </FormProvider>
  );
};
