import React from 'react';
import Colors from '../Colors';

const Gear = props => (
  <svg width={props.size} height={props.size} {...props} viewBox="0 0 24 24">
    <g fill={props.color ? props.color : 'var(--colors-primary)'}>
      <path d="M8.907 7.67a5.08 5.08 0 0 0-1.86 6.941 5.08 5.08 0 0 0 6.942 1.86 5.08 5.08 0 0 0 1.86-6.941 5.08 5.08 0 0 0-6.942-1.86zm4.012 6.948A2.933 2.933 0 0 1 8.9 13.541a2.933 2.933 0 0 1 1.077-4.018 2.933 2.933 0 0 1 4.019 1.076 2.933 2.933 0 0 1-1.077 4.02z" />
      <path d="M18.737 2.55l-2.608-1.52c-.981-.577-2.21-.3-2.873.64l-.498.688a9.738 9.738 0 0 0-2.504-.005l-.484-.678a2.179 2.179 0 0 0-2.866-.63l-2.617 1.51A2.179 2.179 0 0 0 3.4 5.352l.359.782A9.738 9.738 0 0 0 2.51 8.305l-.836.05a2.165 2.165 0 0 0-1.99 2.169l-.01 3.032c.008 1.138.855 2.07 1.987 2.157l.843.07a9.917 9.917 0 0 0 1.257 2.177l-.362.764a2.159 2.159 0 0 0 .875 2.8l2.63 1.507c.982.576 2.211.298 2.874-.64l.498-.689a9.738 9.738 0 0 0 2.504.006l.498.7a2.179 2.179 0 0 0 2.865.631l2.617-1.51a2.179 2.179 0 0 0 .887-2.798l-.345-.758a9.738 9.738 0 0 0 1.247-2.172l.846-.086a2.165 2.165 0 0 0 1.99-2.169l.01-3.032c-.008-1.137-.855-2.07-1.986-2.157l-.843-.07A9.917 9.917 0 0 0 19.31 6.11l.361-.764c.43-1.02.07-2.233-.934-2.795zm2.467 7.964l-.01 3.032c.013.023-.01.037-.033.05l-2.205.192-.236.662a6.975 6.975 0 0 1-1.508 2.63l-.472.52.948 2.016c.013.023-.01.036-.01.036l-2.64 1.525c-.023.013-.037-.01-.037-.01l-1.272-1.829-.686.15c-.994.203-2.035.217-3.032-.01l-.69-.127-1.27 1.814c-.022.013-.022.013-.059.003l-2.63-1.507-.027-.047.933-2.02-.464-.536a7.381 7.381 0 0 1-.9-1.24 7.381 7.381 0 0 1-.624-1.4l-.232-.67-2.19-.155c-.023.013-.037-.01-.027-.047l.01-3.032c-.013-.023.01-.036.033-.05l2.205-.192.236-.661a7.413 7.413 0 0 1 1.507-2.63l.473-.52-.948-2.016c-.014-.024.01-.037.01-.037l2.617-1.511c.023-.013.036.01.036.01l1.272 1.829.71-.163c.994-.203 2.035-.218 3.031.01l.691.126 1.27-1.813c.022-.014.022-.014.059-.004l2.63 1.508.027.046-.933 2.021.464.535a7.381 7.381 0 0 1 1.524 2.64l.232.67 2.217.202c-.014-.023-.014-.023 0 0z" />
    </g>
  </svg>
);

Gear.defaultProps = {
  size: 24,
  color: Colors.white
};

export default Gear;
