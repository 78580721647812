import { Flex } from '@chakra-ui/react';
import Colors from 'src/styles/Colors';
import styled from 'styled-components';

export const Card = ({ children, ...props }) => (
  <Flex
    flexDir="column"
    minWidth={0}
    overflow="hidden"
    bg={Colors.white}
    borderRadius="8px"
    border={`1px solid ${Colors.grayED}`}
    minH="1px"
    {...props}
  >
    {children}
  </Flex>
);

export const CardWrapper = styled(Card)`
  height: 100%;
  @media (max-width: 1440px) {
    height: 278px;
  }
`;
