import { useEffect, useState } from 'react';
import { useEvents, useLeads } from 'src/v2/Hooks/CollectionHooks';
import { LeadForm } from 'src/v2/Drawers/LeadsFormDrawer/LeadForm';
import { Wizard } from 'src/v2/UIComponents/Drawers/Wizard';
import { useHistory } from 'react-router-dom';
import { CreateEventPayload, EventFormValues, UpdatedEventData } from 'venuex-types';
import { useDrawers } from '../../Hooks/HelperHooks/DrawerHooks/useDrawers';
import { useDefaultValues } from '../../Hooks/CollectionHooks/Events/useDefaultValues';

export const EventManagementDrawer = ({ addLead = false }) => {
  const { updateEvent, activeEventId, createEvent } = useEvents();
  const history = useHistory();
  const defaultValues = useDefaultValues();

  const { addLeadRequest } = useLeads();
  const {
    eventManagementDrawerOpen,
    closeEventManagementDrawer,
    eventManagementVersion,
    eventManagementMode
  } = useDrawers();

  const [drawerTitle, setDrawerTitle] = useState('Add Lead');

  const [versionMode, setVersionMode] = useState('add-lead');

  const leadDrawerOpen =
    (addLead && eventManagementDrawerOpen && versionMode === 'add-lead') ||
    (!addLead && eventManagementDrawerOpen);

  useEffect(() => {
    if (eventManagementMode === 'edit') {
      if (eventManagementVersion === 'event') {
        setDrawerTitle('Manage Event');
        setVersionMode('edit-event');
      }
      if (eventManagementVersion === 'lead') {
        setDrawerTitle('Manage Lead');
        setVersionMode('edit-lead');
      }
      if (eventManagementVersion === 'book-event') {
        setDrawerTitle('Book Lead');
        setVersionMode('edit-event');
      }
    } else if (eventManagementMode === 'create') {
      if (eventManagementVersion === 'event') {
        setDrawerTitle('Add Event');
        setVersionMode('add-event');
      }
      if (eventManagementVersion === 'lead') {
        setDrawerTitle('Add Lead');
        setVersionMode('add-lead');
      }
    }
  }, [eventManagementVersion, eventManagementMode]);

  const goToCalendar = () => {
    history.push('/events');
  };

  const handlesave = formData => {
    // TODO: #551
    const data: CreateEventPayload | UpdatedEventData = formData;

    // if (formData?.paymentStatus?.schedule && data?.paymentStatus)
    // data.paymentStatus.schedule = formData.paymentStatus.schedule.map(pmt => pmt.date);

    switch (versionMode) {
      case 'edit-event':
        return updateEvent({
          data,
          eventId: activeEventId,
          closeAction: closeEventManagementDrawer,
          navigate: goToCalendar
        });

      case 'edit-lead':
        return updateEvent({
          data,
          eventId: activeEventId,
          closeAction: closeEventManagementDrawer
        });

      case 'add-lead':
        return addLeadRequest({
          lead: data
        });
      case 'add-event':
        return createEvent({
          data
        });
      default:
        break;
    }
  };

  const steps = [
    {
      component: LeadForm,
      step: 0,
      title: drawerTitle
    }
  ];
  // const dValues = {
  //   leadInfo: {
  //     prospectPhone: '',
  //     email: ''
  //   },
  //   ...defaultEventValues
  // };

  const leadDate = defaultValues?.leadInfo?.leadDate
    ? { leadDate: defaultValues?.leadInfo?.leadDate }
    : undefined;

  const leadStatus = defaultValues?.leadInfo?.status
    ? { status: defaultValues?.leadInfo?.status }
    : undefined;

  const leadInfo: { leadInfo: EventFormValues['leadInfo'] } | undefined =
    leadDate || leadStatus
      ? {
          leadInfo: {
            ...defaultValues?.leadInfo,
            ...(leadDate && leadDate),
            ...(leadStatus && leadStatus)
          }
        }
      : undefined;

  const defaultSalesperson = defaultValues?.salesperson
    ? { salesperson: defaultValues?.salesperson }
    : undefined;

  const touchedDefaultValues = {
    consultant: defaultValues?.consultant,
    staff: defaultValues?.staff,
    ...(leadInfo && leadInfo),
    ...(defaultSalesperson && defaultSalesperson)
  };

  return (
    <Wizard
      steps={steps}
      defaultValues={defaultValues}
      touchedDefaultValues={touchedDefaultValues}
      saveAction={handlesave}
      cancelAction={closeEventManagementDrawer}
      isOpen={leadDrawerOpen}
      formId="eventManagementDrawer"
      wide
      disableNext={false}
    />
  );
};
