import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import useInterval from './useInterval';
import { DateHandler } from '../utils';

import {
  doLogout,
  doRefresh,
  doPolicyRequire,
  doPolicyAccept
} from '../containers/LoginContainer/operations';
import checkPolicies from './checkPolicies';

const REFRESH_RANGE_TIME = 5;

const isAuthenticated = Component => {
  function IsAuthenticated(props) {
    const {
      expires,
      token,
      refresh,
      policyRequired,
      policyPrivacy,
      policyTerms,
      policyRejected,
      policyAcceptRequested,
      role
    } = props.loginReducer;
    const { venue } = props.venuesReducer;

    const diffTime = DateHandler.diff(expires, new Date().getTime());

    useInterval(() => {
      if (props.loginReducer.expires) {
        const dt = new Date().getTime();

        const localDifftime = DateHandler.diff(expires, dt);

        // IF IS ABOUT TO 5 MIN TO EXPIRES, REFRESH IT
        if (localDifftime <= REFRESH_RANGE_TIME) {
          props.refresh({ token, refresh });
        }
      }
    }, 3000);

    // if token expires or policy has been rejected, log out
    if ((expires && diffTime < 0) || policyRejected) {
      props.cleanCredentials({ token });
    }

    // see if we need to prompt for acceptance of venue policies
    if (role === 'host' && checkPolicies(policyRequired, policyPrivacy, policyTerms, venue))
      props.requirePolicy();

    // accept policies if requested
    if (policyAcceptRequested && token) props.acceptPolicy({ token });

    return token ? <Component {...props} /> : <Redirect to={'/login'} />;
  }

  const mapStatetoProps = state => ({ ...state });
  const mapDispatchToProps = dispatch => {
    return {
      cleanCredentials: ({ token }) => dispatch(doLogout({ token })),
      refresh: ({ token, refresh }) => dispatch(doRefresh({ token, refresh })),
      requirePolicy: () => dispatch(doPolicyRequire()),
      acceptPolicy: ({ token }) => dispatch(doPolicyAccept({ token }))
    };
  };

  IsAuthenticated.propTypes = {
    loginReducer: PropTypes.object,
    refresh: PropTypes.func,
    cleanCredentials: PropTypes.func,
    venuesReducer: PropTypes.object,
    requirePolicy: PropTypes.func,
    acceptPolicy: PropTypes.func
  };
  return connect(mapStatetoProps, mapDispatchToProps)(IsAuthenticated);
};

export default isAuthenticated;
