import { TextFieldWithLabel } from 'src/v2/UIComponents/FormComponents';
import { EVENT_DOCUMENT_CONSTANTS } from '../constants';

export const EventDocumentName = ({ version = false }) => {
  const { EVENT_DOCUMENT_NAME } = EVENT_DOCUMENT_CONSTANTS;

  const documentNameProps = {
    name: 'label',
    isRequired: true,
    label: (version && 'Version Name') || EVENT_DOCUMENT_NAME.LABEL,
    placeholder: EVENT_DOCUMENT_NAME.PLACEHOLDER,
    rules: {
      required: EVENT_DOCUMENT_NAME.REQUIRED
    }
  };
  return <TextFieldWithLabel {...documentNameProps} />;
};
