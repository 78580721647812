import React, { FC } from 'react';
import {
  SALESPERSON_LABEL,
  SALESPERSON_PLACEHOLDER,
  SALESPERSON_REQUIRED
} from 'src/v2/Drawers/LeadsFormDrawer/constants';
import { SelectInputWithLabel } from 'src/v2/UIComponents/FormComponents';
import { useVenue } from 'src/v2/Hooks/CollectionHooks';
import { SalespersonInputProps } from 'src/Types/GlobalTypes';

export const SalespersonInput: FC<SalespersonInputProps> = ({ bookEvent }) => {
  const { staffUsers } = useVenue();
  const staff =
    staffUsers &&
    staffUsers
      .filter(user => user.role === 'staff')
      .map(user => ({
        value: user.id,
        label: `${user.firstname} ${user.lastname}`,
        photo: user.photo
      }));

  const salespersonInputProps = {
    label: SALESPERSON_LABEL,
    name: 'salesperson',
    options: staff,
    placeholder: SALESPERSON_PLACEHOLDER,
    isRequired: false
  };
  return <SelectInputWithLabel {...salespersonInputProps} />;
};
