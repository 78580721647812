import React from 'react';
import PropTypes from 'prop-types';
import AddChecklistModalStyles from './styles';
import Button from '../../styles/Button';
import Colors from '../../styles/Colors';

const AddChecklistModalFooter = ({ closeModal, action, itemToEdit, loading }) => (
  <AddChecklistModalStyles.Footer>
    <Button
      label="Cancel"
      bgColor={Colors.white}
      color={Colors.gray7D}
      width="130px"
      action={closeModal}
    />
    <Button
      label={itemToEdit ? 'Edit Item' : 'Add Item'}
      width="130px"
      action={action}
      loading={loading}
    />
  </AddChecklistModalStyles.Footer>
);

AddChecklistModalFooter.propTypes = {
  itemToEdit: PropTypes.number,
  closeModal: PropTypes.func,
  action: PropTypes.func,
  loading: PropTypes.bool
};

AddChecklistModalFooter.defaultProps = {
  closeModal: () => {},
  action: () => {},
  loading: false,
  itemToEdit: 0
};

export default AddChecklistModalFooter;
