import { Flex, Text, Icon } from '@chakra-ui/react';
import { Skeleton } from 'src/v2/UIComponents/Primitives';

const infoValueStyle = {
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.1px',
  fontWeight: 400
};

const infoTitleStyle = {
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0.1px',
  fontWeight: 700
};

export const InfoSectionText = ({ title, value, iconName, icon, isLoading }) => (
  <Flex width="100%" mb="18px">
    <Flex flexDir="column">
      <Text {...infoTitleStyle} mb="12px">
        {title}
      </Text>
      <Flex alignItems="center">
        {(iconName && <Icon as={iconName} w={6} h={6} color="var(--colors-primary)" />) || icon}
        {!isLoading ? (
          <Text {...infoValueStyle} ml={iconName && '20px'}>
            {value || '---'}
          </Text>
        ) : (
          <Skeleton width="160px" ml="20px" />
        )}
      </Flex>
    </Flex>
  </Flex>
);
