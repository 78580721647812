export const ADD_TABLE_TO_LAYOUT = 'ADD_TABLE_TO_LAYOUT';
export const ADD_TABLES_TO_LAYOUT = 'ADD_TABLES_TO_LAYOUT';
export const ADD_TEXT_TO_LAYOUT = 'ADD_TEXT_TO_LAYOUT';
export const ADD_TEXTS_TO_LAYOUT = 'ADD_TEXTS_TO_LAYOUT';
export const ADD_SHAPE_TO_LAYOUT = 'ADD_SHAPE_TO_LAYOUT';
export const ADD_SHAPES_TO_LAYOUT = 'ADD_SHAPES_TO_LAYOUT';
export const SELECT_TABLE = 'SELECT_TABLE';
export const SELECT_TEXT = 'SELECT_TEXT';
export const UNSELECT_TABLE = 'UNSELECT_TABLE';
export const CLEAR_EDITOR = 'CLEAR_EDITOR';
export const EDIT_TABLE_SETTINGS = 'EDIT_TABLE_SETTINGS';
export const DELETE_TABLE = 'DELETE_TABLE';
export const DELETE_SHAPE = 'DELETE_SHAPE';
export const DELETE_TEXT = 'DELETE_TEXT';
export const SELECT_TOOL = 'SELECT_TOOL';
export const SELECT_SHAPE = 'SELECT_SHAPE';
export const UNSELECT_SHAPE = 'UNSELECT_SHAPE';
export const UNSELECT_TOOL = 'UNSELECT_TOOL';
export const EDIT_TEXT_SETTINGS = 'EDIT_TEXT_SETTINGS';
export const EDIT_SHAPE_SETTINGS = 'EDIT_SHAPE_SETTINGS';
export const UNSELECT_TEXT = 'UNSELECT_TEXT';

export const SAVE_LAYOUT_REQUEST = 'SAVE_LAYOUT_REQUEST';
export const SAVE_LAYOUT_SUCCESS = 'SAVE_LAYOUT_SUCCESS';
export const SAVE_LAYOUT_FAILURE = 'SAVE_LAYOUT_FAILURE';

export const CHANGE_SAVE_INDICATOR = 'CHANGE_SAVE_INDICATOR';
export const UPDATE_STATUS_REQUEST = 'UPDATE_STATUS_REQUEST';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_FAILURE = 'UPDATE_STATUS_FAILURE';
