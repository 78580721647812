import { Box, Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';

export const DisplayEmailBody = ({
  emailBody,
  isOpen = false,
  doClose
}: {
  emailBody: string;
  isOpen: boolean;
  doClose: () => void;
}) => {
  return (
    <Modal onClose={doClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent display="flex" maxWidth={'fit-content'}>
        <Box
          dangerouslySetInnerHTML={{ __html: emailBody }}
          overflow="auto"
          maxHeight="85vh"
          maxWidth="85vh"
          padding="20px"
        />
      </ModalContent>
    </Modal>
  );
};
