import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

// action types
import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  AUTH_RESET,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  REFRESH_SUCCESS,
  REFRESH_REQUEST,
  REFRESH_FAILURE,
  LOGIN_ERRORS_SET,
  VENUEID_REMOVE,
  EVENTID_REMOVE,
  POLICY_ACCEPT_SUCCESS,
  POLICY_REJECT,
  POLICY_ACCEPT_REQUEST,
  POLICY_REQUIRE,
  POLICY_ACCEPT_TRIGGER
} from './types';
import { PROFILE_AVATAR_EDIT_SUCCESS } from '../DashboardContainer/types';

// reducer with initial state
const initialState = {
  admin: null,
  claims: null,
  email: null,
  error: null,
  expires: null,
  fetching: false,
  policyAcceptRequested: false,
  policyPrivacy: null,
  policyRejected: false,
  policyRequired: false,
  policyTerms: null,
  refresh: null,
  role: null,
  token: null,
  viewPermissions: {},
  fetchingEmailThreads: false,
  threadOptions: []
};

const persistConfig = {
  key: 'auth',
  storage,
  blacklist: ['fetching']
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_RESET:
      return { ...initialState };
    case LOGIN_ERRORS_SET:
      return {
        ...state,
        error: action.error
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null,
        refresh: null,
        token: null,
        expires: null,
        role: null,
        uid: null,
        claims: null,
        viewPermissions: null,
        admin: null,
        firstname: null,
        lastname: null,
        photo: null
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        refresh: null,
        token: null,
        expires: null,
        claims: null,
        viewPermissions: null,
        firstname: null,
        lastname: null,
        photo: null,
        uid: null
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        refresh: action.refresh,
        token: action.token,
        expires: action.expires,
        role: action.role,
        uid: action.uid,
        claims: action.claims,
        admin: action.admin,
        firstname: action.firstname,
        lastname: action.lastname,
        photo: action.photo,
        venueId: action.venueId,
        eventId: action.eventId,
        email: action.userEmail,
        viewPermissions: action.viewPermissions,
        policyPrivacy: action.policyPrivacy,
        policyTerms: action.policyTerms,
        venuexAdmin: action.venuexAdmin
      };
    case REFRESH_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null
      };
    case REFRESH_FAILURE:
      return {
        ...state,
        fetching: false,
        error: null,
        refresh: action.refresh,
        token: action.token,
        expires: action.expires,
        role: action.role,
        uid: action.uid,
        claims: action.claims,
        admin: action.admin,
        firstname: action.firstname,
        lastname: action.lastname,
        viewPermissions: null,
        photo: action.photo,
        venueId: action.venueId,
        eventId: action.eventId,
        policyPrivacy: action.policyPrivacy,
        policyTerms: action.policyTerms
      };
    case REFRESH_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        refresh: action.refresh,
        token: action.token,
        expires: action.expires,
        role: action.role,
        uid: action.uid,
        claims: action.claims,
        admin: action.admin,
        venueId: action.venueId,
        eventId: action.eventId,
        viewPermissions: action.viewPermissions,
        policyPrivacy: action.policyPrivacy,
        policyTerms: action.policyTerms
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null,
        refresh: null,
        token: null,
        expires: null,
        role: null,
        uid: null,
        claims: null,
        admin: null,
        firstname: null,
        lastname: null,
        photo: null,
        viewPermissions: null,
        policyPrivacy: null,
        policyTerms: null
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        fetching: false,
        error: null,
        refresh: action.refresh,
        token: action.token,
        expires: action.expires,
        role: action.role,
        uid: action.uid,
        claims: action.claims,
        admin: action.admin,
        firstname: action.firstname,
        lastname: action.lastname,
        photo: action.photo,
        venueId: action.venueId,
        eventId: action.eventId,
        policyPrivacy: action.policyPrivacy,
        policyTerms: action.policyTerms
      };
    case PROFILE_AVATAR_EDIT_SUCCESS:
      return {
        ...state,
        photo: action.photo
      };
    case VENUEID_REMOVE:
      return {
        ...state,
        venueId: null,
        eventId: null
      };
    case EVENTID_REMOVE:
      return {
        ...state,
        eventId: null
      };
    case POLICY_ACCEPT_SUCCESS:
      return {
        ...state,
        fetching: false,
        policyPrivacy: action.timestamp,
        policyTerms: action.timestamp,
        policyRequired: false,
        policyRejected: false,
        policyAcceptRequested: false
      };
    case POLICY_REJECT:
      return {
        ...state,
        fetching: false,
        policyPrivacy: null,
        policyTerms: null,
        policyRejected: true
      };
    case POLICY_ACCEPT_TRIGGER:
      return {
        ...state,
        error: null,
        policyAcceptRequested: true
      };
    case POLICY_ACCEPT_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null,
        policyAcceptRequested: false
      };
    case POLICY_REQUIRE:
      return {
        ...state,
        policyRequired: true
      };
    default:
      return state;
  }
};

const loginReducer = persistReducer(persistConfig, reducer);

export default loginReducer;
