import React from 'react';
import { DateFieldWithLabel } from 'src/v2/UIComponents/FormComponents';
import { LEAD_DATE_LABEL } from '../../constants';

interface LeadDateProps {
  bookEvent?: boolean;
}

export const LeadDate: React.FC<LeadDateProps> = ({ bookEvent }) => {
  const leadDateProps = {
    name: 'leadInfo.leadDate',
    label: LEAD_DATE_LABEL,
    placeholder: LEAD_DATE_LABEL,
    showTimeSelect: false,
    allowPreviousDate: true
  };

  return <DateFieldWithLabel {...leadDateProps} />;
};

export default LeadDate;
