export const REQUIRED = 'Required';
export const CLIENT_FIRST_NAME_LABEL = 'First Name';
export const CLIENT_LAST_NAME_LABEL = 'Last Name';
export const CLIENT_ADDRESS_LABEL = 'Address';
export const CLIENT_COMPANY_LABEL = 'Company';
export const CLIENT_NAME_REQUIRED = 'Required';
export const PROSPECT_PHONE_LABEL = 'Phone';
export const PROSPECT_PHONE_PLACEHOLDER = '555-333-444';
export const PROSPECT_PHONE_REQUIRED = 'Required';
export const PROSPECT_EMAIL_LABEL = 'Email';
export const PROSPECT_EMAIL_PLACEHOLDER = 'example@example.com';
export const PROSPECT_EMAIL_REQUIRED = 'Required';
export const GUEST_LABEL = 'Guest Count';
export const GUEST_PLACEHOLDER = '123';
export const GUEST_REQUIRED = 'Is Required';
export const CUSTOM_INFO_LABEL = 'Custom Info';
export const CUSTOM_INFO_PLACEHOLDER = 'Custom Info';
export const SALESPERSON_LABEL = 'Salesperson';
export const SALESPERSON_PLACEHOLDER = 'Salesperson';
export const SALESPERSON_REQUIRED = 'Required';
export const LEAD_STATUS_LABEL = 'Lead Status';
export const LEAD_STATUS_PLACEHOLDER = 'Select One';
export const TOUR_DATE_LABEL = 'Tour Date';
export const LEAD_DATE_LABEL = 'Lead Received Date';
export const EXPECTED_CLOSE_DATE_LABEL = 'Expected Close Date';
export const LEAD_SOURCE_LABEL: string = 'Lead Source';

export const BILLING_CONTACT = {
  FIRST_NAME: {
    LABEL: 'First Name',
    PLACEHOLDER: 'First Name',
    REQUIRED: 'First Name is required'
  },
  LAST_NAME: {
    LABEL: 'Last Name',
    PLACEHOLDER: 'Last Name',
    REQUIRED: 'Last Name is required'
  },
  EMAIL: {
    LABEL: 'Email Address',
    PLACEHOLDER: 'Email',
    REQUIRED: 'Email Address is required'
  },
  PHONE: {
    LABEL: 'Phone',
    PLACEHOLDER: 'Phone',
    REQUIRED: 'Phoneis required'
  },
  STREET_ADDRESS: {
    LABEL: 'Street Address',
    PLACEHOLDER: 'Street Address',
    REQUIRED: 'Street Address is required'
  },
  CITY: {
    LABEL: 'City',
    PLACEHOLDER: 'City',
    REQUIRED: 'City is required'
  },
  STATE: {
    LABEL: 'State',
    PLACEHOLDER: 'State',
    REQUIRED: 'State is required'
  },
  ZIP_CODE: {
    LABEL: 'Zip Code',
    PLACEHOLDER: 'Zip Code',
    REQUIRED: 'Zip Code is required'
  }
};
