import styled from 'styled-components';
import Colors from '../../styles/Colors';

const ChangePasswordStyles = styled.div`
  display: flex;
  flex-direction: column;
`;

ChangePasswordStyles.Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 8rem;
  padding: 20px;
  background-color: ${props => (props.bgColor && props.bgColor) || `var(--colors-modalHeader);`};
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  span {
    font-family: Lora;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.63px;
    text-align: center;
    color: #181818;
  }
`;

ChangePasswordStyles.Success = styled.div`
  font-family: Lora;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.63px;
  text-align: center;
  color: #181818;
  margin-top: 5rem;
`;

ChangePasswordStyles.BtClose = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
`;

ChangePasswordStyles.CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: Transparent;
  border: none;
`;

ChangePasswordStyles.Title = styled.div`
  display: flex;
  font-family: Lora;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.6px;
  text-transform: capitalize;
  color: #181818;
`;

ChangePasswordStyles.Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem;
  height: 20rem;
  box-sizing: border-box;
`;

ChangePasswordStyles.BodyKey = styled.span`
  display: flex;
  flex: 1.5;
  letter-spacing: 0px;
`;

ChangePasswordStyles.BodyRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem;
`;

ChangePasswordStyles.Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px 15px 20px;
  border-top: solid 1px rgb(204, 204, 204, 0.5);
  justify-content: center;

  button {
    margin: 0px 5px;
  }
`;

ChangePasswordStyles.Error = styled.div`
  transition: all 250ms ease-in-out 0s;
  color: ${Colors.redC0};
  font-size: 11px;
  font-weight: bold;
  line-height: 22px;
  // height: ${props => (props.show ? '22px' : '0px')};
  margin-bottom: 20px;
`;

export default ChangePasswordStyles;
