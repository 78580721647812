export const FETCH_VENDOR_CATEGORIES_REQUEST = 'FETCH_VENDOR_CATEGORIES_REQUEST';
export const FETCH_VENDOR_CATEGORIES_SUCCESS = 'FETCH_VENDOR_CATEGORIES_SUCCESS';
export const FETCH_VENDOR_CATEGORIES_FAILURE = 'FETCH_VENDOR_CATEGORIES_FAILURE';

export const FETCH_VENDORS_REQUEST = 'FETCH_VENDORS_REQUEST';
export const FETCH_VENDORS_SUCCESS = 'FETCH_VENDORS_SUCCESS';
export const FETCH_VENDORS_FAILURE = 'FETCH_VENDORS_FAILURE';

export const SORT_ORDER = 'SORT_ORDER';
export const VENDOR_SET_SEARCH_TERM = 'VENDOR_SET_SEARCH_TERM';
