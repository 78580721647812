import React from 'react';
import PropTypes from 'prop-types';
import Colors from 'src/styles/Colors';
import Button from 'src/styles/Button';
import AddEventStyles from './styles';

const AddEventFooter = ({
  handleSubmit,
  closeModal,
  disabled,
  saveDisabled,
  fetching,
  save,
  cancel
}) => {
  return (
    <AddEventStyles.Footer>
      <AddEventStyles.FooterItem>
        <Button
          label={cancel || 'DISCARD'}
          padding={'0px'}
          bgColor={Colors.white}
          color={Colors.gray7D}
          action={() => closeModal()}
          width="140px"
        />
      </AddEventStyles.FooterItem>
      <AddEventStyles.FooterItem>
        <Button
          label={save || 'SAVE'}
          padding={'0px'}
          action={() => handleSubmit()}
          disabled={saveDisabled || disabled}
          loading={fetching}
          width="160px"
          type="submit"
          form="addEventForm"
        />
      </AddEventStyles.FooterItem>
    </AddEventStyles.Footer>
  );
};

AddEventFooter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fetching: PropTypes.bool,
  saveDisabled: PropTypes.bool,
  save: PropTypes.string,
  cancel: PropTypes.string
};

export default AddEventFooter;
