import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export function docLibraryUploadAction({ token, formData }) {
  const data = new FormData();
  const axiosConfig = {
    onUploadProgress: progressEvent => console.log(progressEvent.loaded)
  };
  Object.keys(formData).forEach(key => {
    if (key !== 'libraryFileUpload' && formData[key] && formData[key].length) {
      data.append(key, JSON.stringify(formData[key]));
    } else data.append(key, formData[key]);
  });

  return axios({
    method: 'post',
    url: `${apiUri}/documentLibrary/upload`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: token
    }
  });
}

export const getDocumentsLibraryAction = ({ token }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/documentLibrary`,
    headers: { Authorization: token }
  });
};

export const deleteDocumentFromLibraryAction = ({ token, documentId }) => {
  console.log(documentId);
  return axios({
    method: 'delete',
    url: `${apiUri}/documentLibrary/${documentId}`,
    headers: { Authorization: token }
  });
};

export const getDocumentsLibraryByIdAction = ({ token, documentId }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/documentLibrary/${documentId}`,
    headers: { Authorization: token }
  });
};

export const updateDocumentsLibraryAction = ({ token, formData, documentId }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/documentLibrary/${documentId}`,
    data: formData,
    headers: { Authorization: token }
  });
};

export const downloadDocumentLibraryAction = ({ token, documentId }) => {
  return axios({
    method: 'get',
    url: `${apiUri}/documentLibrary/libraryDownload?&document=${documentId}`,
    headers: { Authorization: token },
    path: '/file',
    responseType: 'blob'
  });
};
