import { useDispatch, useSelector } from 'react-redux';
import {
  createProductCategoryRequest,
  createProductRequest,
  createRateTypeRequest,
  createTaxRateRequest,
  doReadVenue,
  editVenueRequest
} from 'src/containers/VenuesContainer/operations';
import { useUser } from 'src/v2/Hooks/CollectionHooks/User/useUser';
import { getStaffRequest } from 'src/containers/ManageStaffContainer/operations';
import {
  doGetVenueValues,
  GetVenueValuesRequest
} from 'src/containers/DynamicValuesContainer/operations';
import { FirestoreVenueReducer, RootStates, RTDBVenueReducer } from 'src/Types';
import { RawRTDBVenueData, ResponseVenueObject } from 'venuex-types';
import {
  CreateProductCategoryRequest,
  CreateProductRequest,
  CreateRateTypeRequest,
  CreateTaxRateRequest,
  EditVenueRequest,
  ReadVenueRequestAction
} from 'src/containers/VenuesContainer/OperationTypes';
import { GetStaffRequestAction } from 'src/containers/ManageStaffContainer/OperationTypes';

export type FireStoreVenueTypes = FirestoreVenueReducer['venue'];
export type RTDBVenueTypes = RTDBVenueReducer['venue'];

export interface UseVenueReturn extends UseVenueActions {
  layouts: FireStoreVenueTypes['layouts'];
  dBVenue: RawRTDBVenueData;
  appointmentTypes: FireStoreVenueTypes['appointmentTypes'];
  fetchingFireStore: FirestoreVenueReducer['fetching'];
  fetchingRTDB: RTDBVenueReducer['fetching'];
  featureFlags: FirestoreVenueReducer['venue']['featureFlags'] &
    RTDBVenueReducer['venue']['featureFlags'];
  eventTypes: FireStoreVenueTypes['eventTypes'];
  guestMobileApp: FireStoreVenueTypes['guestMobileApp'];
  eventTypeOptions: FireStoreVenueTypes['eventTypes'];
  staffUsers: FireStoreVenueTypes['staffUsers'];
  lockLayoutDays: FireStoreVenueTypes['lockLayoutDays'];
  fireStoreVenueState: FirestoreVenueReducer;
  fireStoreVenue: ResponseVenueObject;
  defaultValues: object;
  smallLogo: RTDBVenueTypes['smallLogo'];
  logo: RTDBVenueTypes['logo'];
  venueId?: string;
  leadStatusOptions: FireStoreVenueTypes['leadStatusOptions'];
  productCategories: FireStoreVenueTypes['productCategories'];
  rateTypes: FireStoreVenueTypes['rateTypes'];
  taxRates: FireStoreVenueTypes['taxRates'];
  paymentScheduleOptions: FireStoreVenueTypes['paymentScheduleOptions'];
  paymentSchedules: FireStoreVenueTypes['paymentSchedules'];
  leadSources: FireStoreVenueTypes['leadSources'];
  activityTypes: FireStoreVenueTypes['activityTypes'];
  venueColors: RTDBVenueTypes['colors'];
  venueName: FireStoreVenueTypes['name'];
}
export type UseVenueHook = () => UseVenueReturn;

export type UseVenueActions = {
  doGetVenueValues: GetVenueValuesRequest;
  createProduct: CreateProductRequest;
  createProductCategory: CreateProductCategoryRequest;
  createRateType: CreateRateTypeRequest;
  createTaxRate: CreateTaxRateRequest;
  getVenue: () => ReadVenueRequestAction;
  getStaff: () => GetStaffRequestAction;
  editVenueRequest: EditVenueRequest;
};

export const useVenue: UseVenueHook = () => {
  const { token, venueId } = useUser();
  const fireStoreVenueState = useSelector((state: RootStates) => state.venuesReducer);
  const rTDBState = useSelector((state: RootStates) => state.venueValuesReducer);
  const dispatch = useDispatch();

  const { fetching: fetchingFireStore, venue: fireStoreVenue } = fireStoreVenueState;
  const { fetching: fetchingRTDB, venue: dBVenue } = rTDBState;

  const {
    eventTypes,
    guestMobileApp,
    lockLayoutDays,
    leadStatusOptions,
    // noteTypes,
    productCategories,
    rateTypes,
    taxRates,
    paymentScheduleOptions,
    paymentSchedules,
    leadSources,
    activityTypes,
    appointmentTypes,
    staffUsers,
    layouts,
    name: venueName
  } = fireStoreVenueState.venue;

  // const staffUsers = useSelector(state => state.getStaffReducer.staff);

  const { smallLogo, logo, colors: venueColors } = dBVenue;

  // TEMPORARY SO WE DON'T BREAK STAGING

  // TODO: #556 useVenue hook: convert eventTypes to firestore values
  // const eventTypeOptions =
  //   eventTypes &&
  //   eventTypes.map(type => ({
  //     value: type,
  //     label: `${type.charAt(0).toUpperCase()}${type.slice(1)}`
  //   }));

  // CHECK FOR FEATURE FLAG CONFLICTS
  const featureFlags = { ...fireStoreVenue.featureFlags, ...dBVenue.featureFlags };
  if (fireStoreVenue && fireStoreVenue.featureFlags && dBVenue && dBVenue.featureFlags)
    Object.keys(fireStoreVenue.featureFlags).forEach(key => {
      if (fireStoreVenue.featureFlags[key] === dBVenue.featureFlags[key]) {
        featureFlags[key] = fireStoreVenue.featureFlags[key];
      } else if (fireStoreVenue.featureFlags[key] !== dBVenue.featureFlags[key]) {
        if (fireStoreVenue.featureFlags[key] === true) {
          featureFlags[key] = fireStoreVenue.featureFlags[key];
        } else if (dBVenue.featureFlags[key] === true) {
          featureFlags[key] = dBVenue.featureFlags[key];
        }
      }
    });

  const actions: UseVenueActions = {
    doGetVenueValues: e => dispatch(doGetVenueValues(e)),
    createProduct: product => dispatch(createProductRequest({ product })),
    createProductCategory: category => dispatch(createProductCategoryRequest({ category })),
    createRateType: rateType => dispatch(createRateTypeRequest({ rateType })),
    createTaxRate: taxRate => dispatch(createTaxRateRequest({ taxRate })),
    getVenue: () => dispatch(doReadVenue({ token, id: venueId })),
    getStaff: () => dispatch(getStaffRequest({ token, venueId })),
    editVenueRequest: e => dispatch(editVenueRequest(e))
  };

  const defaultValues = {
    paymentsEnabled: fireStoreVenue?.paymentsEnabled,
    name: fireStoreVenue?.paymentAccountInfo?.company?.name,
    line1: fireStoreVenue?.paymentAccountInfo?.company?.address?.line1,
    phone: fireStoreVenue?.paymentAccountInfo?.company?.phone,
    city: fireStoreVenue?.paymentAccountInfo?.company?.address?.city,
    registration_number: fireStoreVenue?.paymentAccountInfo?.company?.registration_number
  };

  const returnObject: UseVenueReturn = {
    dBVenue,
    // noteTypes,
    layouts,
    appointmentTypes,
    fetchingFireStore,
    fetchingRTDB,
    featureFlags,
    eventTypes,
    guestMobileApp,
    eventTypeOptions: eventTypes,
    staffUsers,
    lockLayoutDays,
    fireStoreVenueState,
    fireStoreVenue,
    defaultValues,
    smallLogo,
    logo,
    venueId,
    leadStatusOptions,
    productCategories,
    rateTypes,
    taxRates,
    paymentScheduleOptions,
    paymentSchedules,
    leadSources,
    activityTypes,
    venueColors,
    venueName,
    ...actions
  };

  return returnObject;
};
