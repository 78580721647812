import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ForgotPasswordStyles from './styles';
import Input from '../../styles/Input';
import Button from '../../styles/Button';

const ForgotPassword = ({
  handleSubmit,
  handleChange,
  name,
  logo,
  success,
  submitState,
  loading = false,
  email = '',
  errors = {}
}) => (
  <ForgotPasswordStyles>
    <ForgotPasswordStyles.Card>
      {success && submitState ? (
        <ForgotPasswordStyles.Success>
          <ForgotPasswordStyles.SuccessHeader>
            Email successfully sent
          </ForgotPasswordStyles.SuccessHeader>
          <ForgotPasswordStyles.SuccessText>
            An email with a temporary password has been sent. Use that password to{' '}
            <Link to={'/login'}>log in</Link>.
          </ForgotPasswordStyles.SuccessText>
          <ForgotPasswordStyles.BackButton>
            <Link to={'/login'} className="backButton">
              <Button label={'Back to login'} />
            </Link>
          </ForgotPasswordStyles.BackButton>
        </ForgotPasswordStyles.Success>
      ) : (
        <div>
          <ForgotPasswordStyles.CardHeader logo={logo}>
            <h1>{!logo && name}</h1>
          </ForgotPasswordStyles.CardHeader>
          <form onSubmit={handleSubmit}>
            <Input
              disabled={loading}
              type={'email'}
              onChange={handleChange}
              name={'email'}
              placeholder={'Email'}
              value={email}
              error={errors && errors.email}
            />
            <ForgotPasswordStyles.Error show={errors && Object.keys(errors).length > 0}>
              {errors &&
                Object.keys(errors).map((e, k) => <span key={k.toString()}>{errors[e]}</span>)}
            </ForgotPasswordStyles.Error>
            <Button disabled={loading} type={'submit'} label={loading ? '...' : 'Submit'} />
            <Link to="/login">Back to login</Link>
          </form>
        </div>
      )}
    </ForgotPasswordStyles.Card>
  </ForgotPasswordStyles>
);

ForgotPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  email: PropTypes.string,
  password: PropTypes.string,
  venueId: PropTypes.string,
  eventId: PropTypes.string,
  name: PropTypes.string,
  logo: PropTypes.string,
  errors: PropTypes.object,
  success: PropTypes.string,
  submitState: PropTypes.bool
};

export default ForgotPassword;
