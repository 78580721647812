import {
  FETCH_GUESTS_REQUEST,
  FETCH_GUESTS_SUCCESS,
  FETCH_GUESTS_FAILURE,
  CHANGE_USER_TABLE_REQUEST,
  CHANGE_USER_TABLE_SUCCESS,
  CHANGE_USER_TABLE_FAILURE,
  CHANGE_GROUP_TABLE_SUCCESS,
  GET_LAYOUT_REQUEST,
  GET_LAYOUT_SUCCESS,
  GET_LAYOUT_FAILURE,
  SET_SEARCH_TERM,
  CHANGE_LAYOUT_NAME_REQUEST,
  CHANGE_LAYOUT_NAME_SUCCESS,
  CHANGE_LAYOUT_NAME_FAILURE,
  RESET_ALL_TABLES_REQUEST,
  RESET_ALL_TABLES_SUCCESS,
  RESET_ALL_TABLES_FAILURE,
  FILTER_GUESTS_REQUEST,
  FILTER_GUESTS_SUCCESS,
  FILTER_GUESTS_FAILURE,
  SUBMIT_SEATING_CHART_REQUEST,
  SUBMIT_SEATING_CHART_FAILURE,
  SUBMIT_SEATING_CHART_SUCCESS,
  NEW_SEATING_CHART_REQUEST,
  NEW_SEATING_CHART_FAILURE,
  NEW_SEATING_CHART_SUCCESS,
  ALERT_RESET,
  UPDATE_LAYOUT_REQUEST,
  UPDATE_LAYOUT_FAILURE,
  SELECT_TABLE,
  CLEAR_SELECTED_TABLE
} from './types';

const initialState = {
  guests: null,
  filteredGuests: null,
  fetchingGuests: false,
  fetchingLayout: false,
  filterSuccess: false,
  updatingTable: false,
  updatedGuest: null,
  resetTableSuccess: false,
  layout: null,
  error: null,
  newTable: null,
  group: null,
  searchTerm: '',
  submittingSeatingChart: false,
  submitSeatingChartFailed: false,
  submitSeatingChartFailedData: null,
  submitSeatingChartData: null,
  submitSeatingChartError: null,
  submittingNewSeatingChartRequest: false,
  newSeatingChartError: null,
  newSeatingChartFailed: false,
  newSeatingChartSuccessData: null,
  submitSeatingChartSuccess: false,
  seatingChartRequestSuccess: false,
  updateLayoutFailed: false,
  tableSelected: null
};

const seatingChartReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GUESTS_REQUEST:
      return {
        ...state,
        fetchingGuests: true,
        updatedGuest: null,
        group: null,
        filterSuccess: false,
        newTable: null
      };
    case FETCH_GUESTS_SUCCESS:
      return {
        ...state,
        fetchingGuests: false,
        guests: action.guests,
        filteredGuests: action.guests,
        filterSuccess: action.filterSuccess,
        error: null
      };
    case FETCH_GUESTS_FAILURE:
      return {
        ...state,
        fetchingGuests: false,
        filterSuccess: false,
        filteredGuests: null,
        error: action.error,
        guests: null
      };
    case FILTER_GUESTS_REQUEST:
      return {
        ...state,
        fetchingGuests: true,
        updatedGuest: null,
        group: null,
        filterSuccess: false,
        newTable: null
      };
    case FILTER_GUESTS_SUCCESS:
      return {
        ...state,
        fetchingGuests: false,
        filteredGuests: action.guests,
        filterSuccess: action.filterSuccess,
        error: null
      };
    case FILTER_GUESTS_FAILURE:
      return {
        ...state,
        fetchingGuests: false,
        filterSuccess: false,
        error: action.error,
        filteredGuests: null
      };
    case CHANGE_USER_TABLE_REQUEST:
      return {
        ...state,
        updatingTable: true,
        updatedGuest: null
      };
    case CHANGE_USER_TABLE_SUCCESS:
      return {
        ...state,
        updatingTable: false,
        updatedGuest: action.user,
        newTable: null,
        error: null
      };
    case CHANGE_USER_TABLE_FAILURE:
      return {
        ...state,
        updatingTable: false,
        updatedGuest: null,
        error: action.error
      };
    case CHANGE_GROUP_TABLE_SUCCESS:
      return {
        ...state,
        group: action.group,
        updatedGuest: null,
        newTable: action.table
      };
    case GET_LAYOUT_REQUEST:
      return {
        ...state,
        error: null,
        fetchingLayout: true,
        layout: null
      };
    case GET_LAYOUT_SUCCESS:
      return {
        ...state,
        layout: action.layout,
        fetchingLayout: false,
        error: null
      };
    case GET_LAYOUT_FAILURE:
      return {
        ...state,
        error: action.error,
        fetchingLayout: false,
        layout: null
      };
    case CHANGE_LAYOUT_NAME_REQUEST:
      return {
        ...state,
        fetchingLayout: true,
        error: null
      };
    case CHANGE_LAYOUT_NAME_SUCCESS:
      return {
        ...state,
        error: null,
        fetchingLayout: false
      };
    case CHANGE_LAYOUT_NAME_FAILURE:
      return {
        ...state,
        error: action.error,
        fetchingLayout: false
      };
    case RESET_ALL_TABLES_REQUEST:
      return {
        ...state,
        resetTableSuccess: false,
        error: null
      };
    case RESET_ALL_TABLES_SUCCESS:
      return {
        ...state,
        updatedGuest: null,
        newTable: null,
        resetTableSuccess: action.success,
        error: null
      };
    case RESET_ALL_TABLES_FAILURE:
      return {
        ...state,
        resetTableSuccess: false,
        error: action.error
      };
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm
      };
    case SUBMIT_SEATING_CHART_REQUEST:
      return {
        ...state,
        submittingSeatingChart: true,
        submitSeatingChartFailed: false
      };
    case SUBMIT_SEATING_CHART_FAILURE:
      return {
        ...state,
        submittingSeatingChart: false,
        submitSeatingChartFailed: true,
        submitSeatingChartFailedData: action.seatingChartError
      };
    case SUBMIT_SEATING_CHART_SUCCESS:
      return {
        ...state,
        submittingSeatingChart: false,
        submitSeatingChartFailed: false,
        submitSeatingChartData: action.seatingChartData,
        submitSeatingChartSuccess: true
      };
    case NEW_SEATING_CHART_REQUEST:
      return {
        ...state,
        submittingNewSeatingChartRequest: true,
        newSeatingChartFailed: false
      };
    case NEW_SEATING_CHART_FAILURE:
      return {
        ...state,
        submittingNewSeatingChartRequest: false,
        newSeatingChartFailed: true,
        newSeatingChartError: action.newSeatingChartError
      };
    case NEW_SEATING_CHART_SUCCESS:
      return {
        ...state,
        submittingNewSeatingChartRequest: false,
        newSeatingChartFailed: false,
        newSeatingChartSuccessData: action.newSeatingChartData,
        seatingChartRequestSuccess: true
      };
    case ALERT_RESET:
      return { ...state, [action.alert]: false };
    case UPDATE_LAYOUT_REQUEST:
      return {
        ...state,
        updateLayoutFailed: false
      };
    case UPDATE_LAYOUT_FAILURE:
      return {
        ...state,
        updateLayoutFailed: true
      };
    case SELECT_TABLE:
      return {
        ...state,
        tableSelected: action.table
      };
    case CLEAR_SELECTED_TABLE:
      return {
        ...state,
        tableSelected: null
      };
    default:
      return state;
  }
};

export default seatingChartReducer;
