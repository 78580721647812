export const LOGIN_ERRORS_SET = 'LOGIN_ERRORS_SET';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const AUTH_RESET = 'AUTH_RESET';
export const REFRESH_FAILURE = 'REFRESH_FAILURE';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const REFRESH_REQUEST = 'REFRESH_REQUEST';
export const VENUEID_REMOVE = 'VENUEID_REMOVE';
export const EVENTID_REMOVE = 'EVENTID_REMOVE';
export const POLICY_ACCEPT_TRIGGER = 'POLICY_ACCEPT_TRIGGER';
export const POLICY_ACCEPT_REQUEST = 'POLICY_ACCEPT_REQUEST';
export const POLICY_ACCEPT_SUCCESS = 'POLICY_ACCEPT_SUCCESS';
export const POLICY_REJECT = 'POLICY_REJECT';
export const POLICY_REQUIRE = 'POLICY_REQUIRE';

export const GET_EMAIL_THREADS = {
  REQUEST: 'GET_EMAIL_THREADS_REQUEST',
  SUCCESS: 'GET_EMAIL_THREADS_SUCCESS',
  FAILURE: 'GET_EMAIL_THREADS_FAILURE'
};
