import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export const saveLayoutAction = ({ token, layout }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/layouts`,
    headers: { Authorization: token },
    data: layout
  });
};

export const updateStatusAction = ({ token, layoutId, status }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/layouts/layout/${layoutId}`,
    headers: { Authorization: token },
    data: {
      status
    }
  });
};
