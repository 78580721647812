export const FETCH_EVENT_VENDORS_REQUEST = 'FETCH_EVENT_VENDORS_REQUEST';
export const FETCH_EVENT_VENDORS_SUCCESS = 'FETCH_EVENT_VENDORS_SUCCESS';
export const FETCH_EVENT_VENDORS_FAILURE = 'FETCH_EVENT_VENDORS_FAILURE';

export const ADD_EVENT_VENDOR_REQUEST = 'ADD_EVENT_VENDOR_REQUEST';
export const ADD_EVENT_VENDOR_SUCCESS = 'ADD_EVENT_VENDOR_SUCCESS';
export const ADD_EVENT_VENDOR_FAILURE = 'ADD_EVENT_VENDOR_FAILURE';

export const UPDATE_EVENT_VENDOR_REQUEST = 'UPDATE_EVENT_VENDOR_REQUEST';
export const UPDATE_EVENT_VENDOR_SUCCESS = 'UPDATE_EVENT_VENDOR_SUCCESS';
export const UPDATE_EVENT_VENDOR_FAILURE = 'UPDATE_EVENT_VENDOR_FAILURE';

export const DELETE_EVENT_VENDOR_REQUEST = 'DELETE_EVENT_VENDOR_REQUEST';
export const DELETE_EVENT_VENDOR_SUCCESS = 'DELETE_EVENT_VENDOR_SUCCESS';
export const DELETE_EVENT_VENDOR_FAILURE = 'DELETE_EVENT_VENDOR_FAILURE';

export const GET_EVENT_VENDORS_BY_EVENT_ID_REQUEST = 'GET_EVENT_VENDORS_BY_EVENT_ID_REQUEST';
export const GET_EVENT_VENDORS_BY_EVENT_ID_SUCCESS = 'GET_EVENT_VENDORS_BY_EVENT_ID_SUCCESS';
export const GET_EVENT_VENDORS_BY_EVENT_ID_FAILURE = 'GET_EVENT_VENDORS_BY_EVENT_ID_FAILURE';

export const OPEN_VENDOR_MODAL = 'OPEN_VENDOR_MODAL';
