import axios from 'axios';
import {
  DownloadDocumentRequestProps,
  DownloadDocumentServerRequestProps
} from 'src/containers/DocumentsContainer/OperationTypes';
import {
  ListDocumentsRequestProps,
  ReadDocumentRequestProps,
  UploadEventDocumentRequestProps
} from 'venuex-types';

import config from '../../.env.json';

const { apiUri } = config;

export const getDocumentsAction = ({ token, eventId }: ListDocumentsRequestProps) => {
  return axios({
    method: 'get',
    url: `${apiUri}/documents/${eventId}`,
    headers: { Authorization: token }
  });
};

export const getDocumentAction = ({ token, eventId, documentId }: ReadDocumentRequestProps) => {
  return axios({
    method: 'get',
    url: `${apiUri}/documents/${eventId}/${documentId}`,
    headers: { Authorization: token }
  });
};

export const documentUploadAction = ({
  token,
  eventId,
  formData
}: UploadEventDocumentRequestProps) => {
  const data = new FormData();
  Object.keys(formData).forEach(key => {
    const value = formData[key];
    if (key === 'libraryDocument' && formData[key]) {
      data.append(key, JSON.stringify(formData[key]));
    } else if (key !== 'libraryFileUpload' && formData[key] && formData[key].length && value) {
      data.append(key, JSON.stringify(formData[key]));
    } else if (formData[key]) {
      data.append(key, formData[key]);
    }
  });

  return axios({
    method: 'post',
    url: `${apiUri}/documents/uploadDocument/${eventId}`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data; boundary=XXX',
      Authorization: token
    }
  });
};

// deprecated replaced with uploaddocument
// export const addDocumentAction = ({ token, eventId, document }) => {
//   return axios({
//     method: 'post',
//     url: `${apiUri}/documents/${eventId}`,
//     headers: { Authorization: token },
//     data: document
//   });
// };

export const updateDocumentAction = ({ token, eventId, documentId, document }) => {
  const data = new FormData();

  Object.keys(document).forEach(key => data.append(key, document[key]));

  return axios({
    method: 'put',
    url: `${apiUri}/documents/${eventId}/${documentId}`,
    headers: { Authorization: token, 'Content-Type': 'multipart/form-data' },
    data
  });
};

export function uploadDocumentAction({ token, eventId, formData }) {
  const data = new FormData();
  data.append('file', formData);
  return axios({
    method: 'post',
    url: `${apiUri}/documents/${eventId}/upload`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: token
    }
  });
}

export const deleteDocumentAction = ({ token, eventId, documentId }) => {
  return axios({
    method: 'delete',
    url: `${apiUri}/documents/${eventId}/${documentId}`,
    headers: { Authorization: token }
  });
};

export const commentDocumentAction = ({ token, eventId, documentId, content }) => {
  return axios({
    method: 'put',
    url: `${apiUri}/documents/${eventId}/${documentId}/comment`,
    headers: { Authorization: token },
    data: { content }
  });
};

export const downloadDocumentAction = ({
  token,
  eventId,
  docId,
  url
}: DownloadDocumentServerRequestProps) => {
  return axios({
    method: 'get',
    url: `${apiUri}/documents/${eventId}/${docId}/download?`,
    headers: { Authorization: token },
    // path: '/file',
    responseType: 'blob',
    params: {
      versionUrl: url
    }
  });
};

export function autoFillDocumentAction({ token, eventId, venueId, docId }) {
  return axios({
    method: 'get',
    url: `${apiUri}/documentLibrary/documentWrite?&eventId=${eventId}&venueId=${venueId}&docId=${docId}`,
    headers: { Authorization: token },
    // path: '/file',
    responseType: 'blob'
  });
}

export const dasdf = () => {
  const data = '';

  const config = {
    method: 'get',
    path: '/file',
    responseType: 'blob',

    headers: {
      Authorization:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay1ndzd1aUB2ZW51ZXhzdGFnaW5nLmlhbS5nc2VydmljZWFjY291bnQuY29tIiwic3ViIjoiZmlyZWJhc2UtYWRtaW5zZGstZ3c3dWlAdmVudWV4c3RhZ2luZy5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsImF1ZCI6Imh0dHBzOi8vaWRlbnRpdHl0b29sa2l0Lmdvb2dsZWFwaXMuY29tL2dvb2dsZS5pZGVudGl0eS5pZGVudGl0eXRvb2xraXQudjEuSWRlbnRpdHlUb29sa2l0IiwiZmlyc3RuYW1lIjoiSGFzc2FuIiwibGFzdG5hbWUiOiJNYWhtb3VkIiwicGhvdG8iOiJ0aHVtYm5haWxfU1NfSGFzc2FuTWFobW91ZF8wODMuanBnIiwidWlkIjoiNmtFbVllSWt3bXZqZ3JWYzVnWVMiLCJlbWFpbCI6Imhhc3NhbkB0ZWFtdmVudWV4LmNvbSIsImlhdCI6MTY1MTc3MjA4NDMxOCwiZXhwIjoxNjUxNzc1Njg0MzE4LCJ2ZW51ZUlkIjoidmVudWV4c3RhZ2luZ19jb20iLCJldmVudElkIjpudWxsLCJyb2xlIjoic3RhZmYiLCJhZG1pbiI6ZmFsc2UsInZpZXdQZXJtaXNzaW9ucyI6eyJ2aWV3RXZlbnRzIjp0cnVlLCJjcmVhdGVFZGl0RXZlbnRzIjp0cnVlLCJkZWxldGVFdmVudHMiOnRydWUsInZpZXdCaWxsaW5nIjp0cnVlLCJtYW5hZ2VTdGFmZiI6dHJ1ZX0sImNsYWltcyI6eyJwZXJtaXNzaW9ucyI6eyJub3RlcyI6eyJyZWFkIjp0cnVlLCJjcmVhdGUiOmZhbHNlLCJ1cGRhdGUiOmZhbHNlLCJkZWxldGUiOmZhbHNlfSwibGF5b3V0cyI6eyJjcmVhdGUiOmZhbHNlLCJ1cGRhdGUiOmZhbHNlLCJkZWxldGUiOmZhbHNlLCJyZWFkIjp0cnVlfSwiZ3Vlc3RzIjp7InVwZGF0ZSI6ZmFsc2UsInJlYWQiOnRydWUsImNyZWF0ZSI6ZmFsc2UsImRlbGV0ZSI6ZmFsc2V9LCJyb29tcyI6eyJ1cGRhdGUiOmZhbHNlLCJkZWxldGUiOmZhbHNlLCJjcmVhdGUiOmZhbHNlLCJyZWFkIjp0cnVlfSwiZ3JvdXBzIjp7ImRlbGV0ZSI6ZmFsc2UsImNyZWF0ZSI6ZmFsc2UsInJlYWQiOnRydWUsInVwZGF0ZSI6ZmFsc2V9LCJ1c2VycyI6eyJkZWxldGUiOnRydWUsInJlYWQiOnRydWUsImNyZWF0ZSI6dHJ1ZSwidXBkYXRlIjp0cnVlfSwiYmlsbGluZyI6eyJyZWFkIjp0cnVlLCJ1cGRhdGUiOnRydWUsImNyZWF0ZSI6dHJ1ZSwiZGVsZXRlIjp0cnVlfSwiYWRtaW4iOnRydWUsInZlbnVlcyI6eyJ1cGRhdGUiOmZhbHNlLCJyZWFkIjp0cnVlLCJjcmVhdGUiOmZhbHNlLCJkZWxldGUiOmZhbHNlfSwiZXZlbnRzIjp7ImRlbGV0ZSI6dHJ1ZSwidXBkYXRlIjp0cnVlLCJyZWFkIjp0cnVlLCJjcmVhdGUiOnRydWV9fX0sInZlbnVleEFkbWluIjp0cnVlfQ.07Rpte-4Sc8IhzEmhXltg4hHkbJeFySF6ZuzTRLXYMY',
      'Content-Type': 'application/json'
    },
    data
  };

  axios(config)
    .then(writeResponse => {
      const filename = 'something';
      const url = window.URL.createObjectURL(new Blob([writeResponse.data]));
      console.log({ responseUrl: url });
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${filename}.pdf`);
      document.body.appendChild(link);
      link.click();
    })
    .catch(error => {
      console.log(error);
    });
};
