import { Text, Flex } from '@chakra-ui/react';
import { Skeleton } from 'src/v2/UIComponents/Primitives';
import Colors from 'src/styles/Colors';

export type TextPairingProps = {
  title: string;
  subtitle?: string;
  fetchingData?: boolean;
  missingMessage?: string;
  titleColor?: string;
};
export const TextPairing = ({
  title,
  subtitle,
  fetchingData = false,
  missingMessage,
  titleColor
}) => (
  <>
    <Text
      fontFamily="Lato"
      fontWeight={700}
      fontSize="14px"
      lineHeight="16px"
      color={(!subtitle && missingMessage && Colors.redE7) || titleColor || '#A0A4A8'}
      mb="6px"
    >
      {title}
    </Text>
    {fetchingData ? (
      <Flex>
        <Skeleton height="16px" width="160px" />
      </Flex>
    ) : (
      <Text fontFamily="Lato" fontWeight={400} fontSize="14px" lineHeight="20px">
        {(!subtitle && missingMessage) || subtitle || '---'}
      </Text>
    )}
  </>
);
