import React from 'react';
import PropTypes from 'prop-types';
import { FormErrorMessage, FormLabel, FormControl, Switch } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

export const ContactTracingInput = () => {
  const {
    formState: { errors },
    register
  } = useFormContext();
  return (
    <FormControl
      isInvalid={errors.contactTracing}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <FormLabel htmlFor="contactTracing" marginBottom={0}>
        Contact Tracing
      </FormLabel>
      <FormErrorMessage>{errors.contactTracing && errors.contactTracing.message}</FormErrorMessage>

      <Switch
        spacing="10px"
        name="contactTracing"
        id="contactTracing"
        {...register('contactTracing')}
      />
    </FormControl>
  );
};
