import {
  GET_CUSTOM_OBJECTS_REQUEST,
  GET_CUSTOM_OBJECTS_SUCCESS,
  GET_CUSTOM_OBJECTS_FAILURE,
  ADD_CUSTOM_OBJECTS_REQUEST,
  ADD_CUSTOM_OBJECTS_SUCCESS,
  UPDATE_CUSTOM_OBJECTS_REQUEST,
  UPDATE_CUSTOM_OBJECTS_SUCCESS,
  DELETE_CUSTOM_OBJECTS_REQUEST,
  DELETE_CUSTOM_OBJECTS_SUCCESS,
  RESET_ALERTS,
  UPDATE_CUSTOM_OBJECTS_CATEGORY_REQUEST,
  UPDATE_CUSTOM_OBJECTS_CATEGORY_SUCCESS
} from './types';

const initialState = {
  customObjects: [],
  categories: [],
  types: [],
  isFetching: false,
  error: null,
  shapeAddedSuccess: false,
  shapeUpdatedSuccess: false,
  shapeDeletedSuccess: false,
  isCategoryLoading: false
};

export const customObjectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOM_OBJECTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case GET_CUSTOM_OBJECTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        customObjects: action.objects,
        categories: action.categories,
        types: action.types,
        error: null
      };
    case GET_CUSTOM_OBJECTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case ADD_CUSTOM_OBJECTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        shapeAddedSuccess: false,
        shapeUpdatedSuccess: false
      };
    case ADD_CUSTOM_OBJECTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        shapeAddedSuccess: true
      };
    case UPDATE_CUSTOM_OBJECTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        shapeAddedSuccess: false,
        shapeUpdatedSuccess: false
      };
    case UPDATE_CUSTOM_OBJECTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        shapeUpdatedSuccess: true
      };
    case DELETE_CUSTOM_OBJECTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        shapeAddedSuccess: false,
        shapeUpdatedSuccess: false,
        shapeDeletedSuccess: false
      };
    case DELETE_CUSTOM_OBJECTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        shapeDeletedSuccess: true
      };
    case RESET_ALERTS:
      return {
        ...state,
        shapeUpdatedSuccess: false,
        shapeAddedSuccess: false,
        shapeDeletedSuccess: false
      };
    case UPDATE_CUSTOM_OBJECTS_CATEGORY_REQUEST:
      return {
        ...state,
        isCategoryLoading: true
      };
    case UPDATE_CUSTOM_OBJECTS_CATEGORY_SUCCESS:
      return {
        ...state,
        isCategoryLoading: false
      };
    default:
      return state;
  }
};
