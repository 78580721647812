import {
  FETCH_MEMORIES_REQUEST,
  FETCH_MEMORIES_SUCCESS,
  FETCH_MEMORIES_FAILURE,
  DELETE_MEMORY_REQUEST,
  DELETE_MEMORY_SUCCESS,
  DELETE_MEMORY_FAILURE,
  BATCH_DELETE_REQUEST,
  BATCH_DELETE_SUCCESS,
  BATCH_DELETE_FAILURE,
  SET_SEARCH_TERM,
  CREATE_MEMORIES_REQUEST,
  CREATE_MEMORIES_SUCCESS,
  CREATE_MEMORIES_FAILURE,
  EDIT_MOBILE_PHOTO_REQUEST,
  EDIT_MOBILE_PHOTO_SUCCESS,
  EDIT_MOBILE_PHOTO_FAILURE,
  GET_MODERATORS_REQUEST,
  GET_MODERATORS_SUCCESS,
  GET_MODERATORS_FAILURE,
  ADD_MODERATOR_REQUEST,
  ADD_MODERATOR_SUCCESS,
  ADD_MODERATOR_FAILURE,
  DELETE_MODERATOR_REQUEST,
  DELETE_MODERATOR_SUCCESS,
  DELETE_MODERATOR_FAILURE,
  EDIT_MEMORY_REQUEST,
  EDIT_MEMORY_SUCCESS,
  EDIT_MEMORY_FAILURE
} from './types';
import { EVENT_SUCCESS } from '../DashboardContainer/types';

const initialState = {
  fetching: false,
  deleting: false,
  editing: false,
  batchDeleting: false,
  deleteSuccess: false,
  editSuccess: false,
  batchDeleteSuccess: false,
  updatingEvent: false,
  addingModerator: false,
  addingModeratorSuccess: false,
  deletingModerator: false,
  deletingModeratorSuccess: false,
  editMobilePhotoSuccess: false,
  guestusers: [],
  creating: false,
  createSuccess: false,
  searchTerm: '',
  memories: [],
  error: null
};

const memoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MEMORIES_REQUEST:
      return {
        ...state,
        fetching: true,
        updatingEvent: false,
        editMobilePhotoSuccess: false,
        memories: [],
        error: null
      };
    case FETCH_MEMORIES_SUCCESS:
      return {
        ...state,
        fetching: false,
        batchDeleteSuccess: false,
        createSuccess: false,
        deleteSuccess: false,
        memories: action.memories,
        error: null
      };
    case FETCH_MEMORIES_FAILURE:
      return {
        ...state,
        fetching: false,
        memories: [],
        error: action.error
      };
    case DELETE_MEMORY_REQUEST:
      return {
        ...state,
        deleting: true,
        deleteSuccess: false,
        error: null
      };
    case DELETE_MEMORY_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteSuccess: action.success
      };
    case DELETE_MEMORY_FAILURE:
      return {
        ...state,
        deleting: false,
        deleteSuccess: false,
        error: action.error
      };
    case EDIT_MEMORY_REQUEST:
      return {
        ...state,
        editing: true,
        editSuccess: false,
        error: null
      };
    case EDIT_MEMORY_SUCCESS:
      return {
        ...state,
        editing: false,
        editSuccess: action.success
      };
    case EDIT_MEMORY_FAILURE:
      return {
        ...state,
        editing: false,
        editSuccess: false,
        error: action.error
      };
    case BATCH_DELETE_REQUEST:
      return {
        ...state,
        batchDeleting: true,
        batchDeleteSuccess: false
      };
    case BATCH_DELETE_SUCCESS:
      return {
        ...state,
        batchDeleting: false,
        batchDeleteSuccess: true
      };
    case BATCH_DELETE_FAILURE:
      return {
        ...state,
        batchDeleting: false,
        batchDeleteSuccess: false
      };
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm
      };
    case CREATE_MEMORIES_REQUEST:
      return {
        ...state,
        creating: true,
        createSuccess: false
      };
    case CREATE_MEMORIES_SUCCESS:
      return {
        ...state,
        creating: false,
        createSuccess: true
      };
    case CREATE_MEMORIES_FAILURE:
      return {
        ...state,
        creating: false,
        createSuccess: false,
        error: action.error
      };
    case EDIT_MOBILE_PHOTO_REQUEST:
      return {
        ...state,
        editMobilePhotoSuccess: false,
        updatingEvent: true
      };
    case EDIT_MOBILE_PHOTO_SUCCESS:
      return {
        ...state,
        editMobilePhotoSuccess: !!action.mobilePhoto,
        updatingEvent: false
      };
    case EDIT_MOBILE_PHOTO_FAILURE:
      return {
        ...state,
        editMobilePhotoSuccess: false,
        updatingEvent: false
      };
    case EVENT_SUCCESS:
      return {
        ...state,
        editMobilePhotoSuccess: false,
        updatingEvent: false
      };
    case GET_MODERATORS_REQUEST:
      return {
        ...state,
        addingModeratorSuccess: false,
        deletingModeratorSuccess: false,
        moderators: []
      };
    case GET_MODERATORS_SUCCESS:
      return {
        ...state,
        moderators: action.moderators
      };
    case GET_MODERATORS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case ADD_MODERATOR_REQUEST:
      return {
        ...state,
        addingModerator: true,
        addingModeratorSuccess: false
      };
    case ADD_MODERATOR_SUCCESS:
      return {
        ...state,
        addingModerator: false,
        addingModeratorSuccess: true
      };
    case ADD_MODERATOR_FAILURE:
      return {
        ...state,
        addingModerator: false,
        addingModeratorSuccess: false,
        error: action.error
      };
    case DELETE_MODERATOR_REQUEST:
      return {
        ...state,
        deletingModerator: true,
        deletingModeratorSuccess: false
      };
    case DELETE_MODERATOR_SUCCESS:
      return {
        ...state,
        deletingModerator: false,
        deletingModeratorSuccess: true
      };
    case DELETE_MODERATOR_FAILURE:
      return {
        ...state,
        deletingModerator: false,
        deletingModeratorSuccess: false,
        error: action.error
      };

    default:
      return state;
  }
};

export default memoriesReducer;
