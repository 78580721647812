import { useRooms } from 'src/v2/Hooks/CollectionHooks';
import { SelectInputWithLabel } from 'src/v2/UIComponents/FormComponents';
import { ROOM_PLACEHOLDER, ROOM_LABEL } from './constants';

export const RoomInput = ({ bookEvent }) => {
  const { roomOptions: rooms, fetching } = useRooms();

  const roomProps = {
    isRequired: bookEvent && true,
    name: 'room',
    label: ROOM_LABEL,
    options: rooms,
    isLoading: fetching,
    placeholder: ROOM_PLACEHOLDER,
    rules: { required: bookEvent && 'Required' }
  };

  return <SelectInputWithLabel {...roomProps} />;
};
