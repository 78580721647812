import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from '../../styles/Input';
import Button from '../../styles/Button';
import ModalButton from '../Button';
import InputInlineEditStyles from './styles';
import Colors from '../../styles/Colors';
import IconClose from '../../styles/Icons/Close';
import IconEdit from '../../styles/Icons/Edit';
import IconDelete from '../../styles/Icons/Delete';
import IconCheckmark from '../../styles/Icons/Checkmark';
import Modal from '../Modal';
import ConfirmModalMessage from '../ConfirmModal/ConfirmModalMessage';
import ConfirmModalFooter from '../ConfirmModal/ConfirmModalFooter';

const InputInlineEdit = ({
  label = '',
  showStatus,
  value = '',
  placeholder,
  onSubmit,
  onDelete
}) => {
  const [show, setShow] = useState(showStatus);
  const [newValue, setNewValue] = useState(value);
  const [deleteIdeaModal, setDeleteIdeaModal] = useState(false);

  const handleEdit = e => {
    e.preventDefault();
    setNewValue(e.target.value);
  };

  useEffect(() => {
    if (showStatus !== null) {
      setShow(showStatus);
    }
  });

  const handleToggleEdit = status => {
    setShow(status || !show);
    setNewValue(value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (newValue !== value) {
      onSubmit(newValue);
    }
    handleToggleEdit();
  };

  const closeDeleteIdeaModal = () => {
    setDeleteIdeaModal(false);
  };

  const deleteIdea = () => {
    setDeleteIdeaModal(false);
    onDelete();
  };

  return (
    <InputInlineEditStyles>
      <Modal
        isOpen={deleteIdeaModal}
        onRequestClose={closeDeleteIdeaModal}
        height={300}
        width={440}
      >
        <ConfirmModalMessage>Are you sure you want to delete this Idea?</ConfirmModalMessage>
        <ConfirmModalFooter>
          <ModalButton label={'Cancel'} color={Colors.redC0} onClick={closeDeleteIdeaModal} />
          <ModalButton label={'Delete Idea'} color={Colors.gray7D} onClick={deleteIdea} />
        </ConfirmModalFooter>
      </Modal>
      <InputInlineEditStyles.Form show={show}>
        <form onSubmit={handleSubmit}>
          <Input placeholder={placeholder} value={newValue} onChange={handleEdit} />
          <Button
            type={'button'}
            icon={<IconClose size={14} />}
            action={() => handleToggleEdit()}
            noBorder
            disableShadow
            bgColor="transparent"
          />
          <Button
            type={'submit'}
            icon={<IconCheckmark size={14} />}
            noBorder
            disableShadow
            bgColor="transparent"
          />
        </form>
      </InputInlineEditStyles.Form>
      <InputInlineEditStyles.Value show={!show}>
        <InputInlineEditStyles.ValueLabel>
          {label} {value || 'None'}
        </InputInlineEditStyles.ValueLabel>
        <Button
          type={'button'}
          icon={<IconEdit size={16} />}
          bgColor={Colors.white}
          action={() => handleToggleEdit()}
          noBorder
          disableShadow
        />
        <Button
          type={'button'}
          icon={<IconDelete size={16} />}
          bgColor={Colors.white}
          action={() => setDeleteIdeaModal(true)}
          noBorder
          disableShadow
        />
      </InputInlineEditStyles.Value>
    </InputInlineEditStyles>
  );
};

InputInlineEdit.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func,
  showStatus: PropTypes.bool,
  onDelete: PropTypes.func
};

export default InputInlineEdit;
