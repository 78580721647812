import { makeActionCreator } from '../../utils/makeActionCreator';

// Import Types
import {
  CREATE_BUDGET_ITEM,
  CREATE_BUDGET_ITEM_SUCCESS,
  CREATE_BUDGET_ITEM_FAILURE,
  CREATE_BUDGET_ITEM_CATEGORY,
  CREATE_BUDGET_ITEM_CATEGORY_SUCCESS,
  CREATE_BUDGET_ITEM_CATEGORY_FAILURE,
  FETCH_BUDGET_ITEM_CATEGORIES,
  FETCH_BUDGET_ITEM_CATEGORIES_SUCCESS,
  FETCH_BUDGET_ITEM_CATEGORIES_FAILURE,
  FETCH_BUDGET_ITEMS,
  FETCH_BUDGET_ITEMS_SUCCESS,
  FETCH_BUDGET_ITEMS_FAILURE,
  UPDATE_BUDGET_ITEM,
  UPDATE_BUDGET_ITEM_SUCCESS,
  UPDATE_BUDGET_ITEM_FAILURE,
  DELETE_BUDGET_ITEM,
  DELETE_BUDGET_ITEM_FAILURE,
  DELETE_BUDGET_ITEM_SUCCESS,
  EDIT_BUDGET_ITEM_CATEGORY,
  EDIT_BUDGET_ITEM_CATEGORY_SUCCESS,
  EDIT_BUDGET_ITEM_CATEGORY_FAILURE,
  DELETE_BUDGET_ITEM_CATEGORY,
  DELETE_BUDGET_ITEM_CATEGORY_SUCCESS,
  DELETE_BUDGET_ITEM_CATEGORY_FAILURE,
  BATCH_DELETE_BUDGET_ITEMS,
  BATCH_DELETE_BUDGET_ITEMS_SUCCESS,
  BATCH_DELETE_BUDGET_ITEMS_FAILURE,
  SET_STATUS_FILTER,
  SET_SEARCH_TERM
} from './types';

// Create budget item category operations
export const createBudgetItemCategoryRequest = makeActionCreator(
  CREATE_BUDGET_ITEM_CATEGORY,
  'name'
);
export const createBudgetItemCategorySuccess = makeActionCreator(
  CREATE_BUDGET_ITEM_CATEGORY_SUCCESS,
  'success',
  'category'
);
export const createBudgetItemCategoryFailure = makeActionCreator(
  CREATE_BUDGET_ITEM_CATEGORY_FAILURE,
  'error'
);

// Edit budget item category operations
export const editBudgetItemCategoryRequest = makeActionCreator(
  EDIT_BUDGET_ITEM_CATEGORY,
  'name',
  'categoryId'
);
export const editBudgetItemCategorySuccess = makeActionCreator(
  EDIT_BUDGET_ITEM_CATEGORY_SUCCESS,
  'success',
  'category'
);
export const editBudgetItemCategoryFailure = makeActionCreator(
  EDIT_BUDGET_ITEM_CATEGORY_FAILURE,
  'error'
);

// Delete budget item category operations
export const deleteBudgetItemCategoryRequest = makeActionCreator(
  DELETE_BUDGET_ITEM_CATEGORY,
  'categoryId',
  'deleteType'
);
export const deleteBudgetItemCategorySuccess = makeActionCreator(
  DELETE_BUDGET_ITEM_CATEGORY_SUCCESS,
  'success'
);
export const deleteBudgetItemCategoryFailure = makeActionCreator(
  DELETE_BUDGET_ITEM_CATEGORY_FAILURE,
  'error'
);

// Fetch budget item categories operations
export const fetchBudgetItemCategoriesRequest = makeActionCreator(FETCH_BUDGET_ITEM_CATEGORIES);
export const fetchBudgetItemCategoriesSuccess = makeActionCreator(
  FETCH_BUDGET_ITEM_CATEGORIES_SUCCESS,
  'success',
  'categories'
);
export const fetchBudgetItemCategoriesFailure = makeActionCreator(
  FETCH_BUDGET_ITEM_CATEGORIES_FAILURE,
  'error'
);

// Fetch budget items operations
export const fetchBudgetItemsRequest = makeActionCreator(FETCH_BUDGET_ITEMS);
export const fetchBudgetItemsSuccess = makeActionCreator(
  FETCH_BUDGET_ITEMS_SUCCESS,
  'success',
  'items'
);
export const fetchBudgetItemsFailure = makeActionCreator(FETCH_BUDGET_ITEMS_FAILURE, 'error');

// Create budget item operations
export const createBudgetItemRequest = makeActionCreator(
  CREATE_BUDGET_ITEM,
  'name',
  'totalAmount',
  'dueDate',
  'category',
  'installments'
);
export const createBudgetItemSuccess = makeActionCreator(
  CREATE_BUDGET_ITEM_SUCCESS,
  'success',
  'item'
);
export const createBudgetItemFailure = makeActionCreator(CREATE_BUDGET_ITEM_FAILURE, 'error');

// Update budget item operations
export const updateBudgetItemRequest = makeActionCreator(
  UPDATE_BUDGET_ITEM,
  'name',
  'totalAmount',
  'dueDate',
  'category',
  'installments',
  'itemId'
);
export const updateBudgetItemSuccess = makeActionCreator(
  UPDATE_BUDGET_ITEM_SUCCESS,
  'success',
  'item'
);
export const updateBudgetItemFailure = makeActionCreator(UPDATE_BUDGET_ITEM_FAILURE, 'error');

// Delete budget item operations
export const deleteBudgetItemRequest = makeActionCreator(DELETE_BUDGET_ITEM, 'itemId');
export const deleteBudgetItemSuccess = makeActionCreator(DELETE_BUDGET_ITEM_SUCCESS, 'success');
export const deleteBudgetItemFailure = makeActionCreator(DELETE_BUDGET_ITEM_FAILURE, 'error');

// Batch delete budget items operations
export const batchDeleteBudgetItemsRequest = makeActionCreator(BATCH_DELETE_BUDGET_ITEMS, 'items');
export const batchDeleteBudgetItemsSuccess = makeActionCreator(
  BATCH_DELETE_BUDGET_ITEMS_SUCCESS,
  'success'
);
export const batchDeleteBudgetItemsFailure = makeActionCreator(
  BATCH_DELETE_BUDGET_ITEMS_FAILURE,
  'error'
);

export const setStatusFilter = makeActionCreator(SET_STATUS_FILTER, 'statusFilter');
export const setSearchTerm = makeActionCreator(SET_SEARCH_TERM, 'searchTerm');
