import Fuse from 'fuse.js';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _isArray from 'lodash/isArray';
import { dateFilterHelper } from './helpers';
import { Filter, FilterBy, FilterDataParams, FilterKey } from './Types';

export const getFilteredData = <T>({
  filterBy,
  filterKey,
  exclude,
  filterId,
  filterType,
  allData: orignalData,
  filters
}: {
  filterBy: FilterBy;
  filterKey: FilterKey;
  exclude?: string;
  filterId?: string;
  filterType?: FilterDataParams['filterType'];
  allData?: any[];
  filters?: Filter[];
}) => {
  let allData = orignalData || [];
  if (filterType === 'search' && typeof filterBy === 'string') {
    const keys = Array.isArray(filterKey) ? filterKey : [filterKey];
    const fuse = new Fuse(allData, { keys, shouldSort: true, threshold: 0.3 });
    const result = fuse.search(filterBy);
    allData = (filterBy && result?.map(d => d.item)) || allData;
  }
  const newFilter = [{ filterKey: filterKey.toString(), filterBy, id: filterId, filterType }];
  let updatedFilters: Filter[] = [];
  // // check if filter is already in state
  const filterExist = filters?.find(filter => filter.filterKey === filterKey);

  if (filterExist) {
    // if filterBy is empty, remove the filter
    if (!filterBy.length) {
      updatedFilters = filters?.filter(val => val.filterKey !== filterKey) || [];
    } else {
      updatedFilters =
        filters?.map(val => {
          if (val.filterKey === filterKey) {
            return {
              ...val,
              filterBy,
              id: filterId,
              filterType
            };
          }

          return val;
        }) || [];
    }
  } else {
    updatedFilters = filters?.concat(newFilter) || [];
  }

  const filteredData: T[] = _filter(allData, row => {
    if (!updatedFilters?.length) {
      return true;
    }

    const filterValidation = updatedFilters?.map(filter => {
      const { filterKey: fltrKey, filterBy: fltrBy, filterType: fltrType } = filter;

      if (fltrType === 'search') {
        return true;
      }

      if (fltrBy === 'notEmpty' || fltrBy?.[0] === 'notEmpty') {
        return !!row[fltrKey];
      }
      if (fltrBy === 'isEmpty' || fltrBy?.[0] === 'isEmpty') {
        // Check explicitly for empty arrays
        return Array.isArray(row[fltrKey]) ? row[fltrKey].length === 0 : !row[fltrKey];
      }
      if (fltrType === 'boolean') {
        console.log('row[fltrKey]', row[fltrKey]);
        if (typeof row[fltrKey] === 'boolean') {
          if (fltrBy === 'true') {
            return row[fltrKey] === true;
          }
          if (fltrBy === 'false') {
            return row[fltrKey] === false;
          }
        }
        return false;
      }

      if (fltrType === 'dateRange') {
        if (_isArray(fltrBy)) {
          return dateFilterHelper(fltrBy, row[fltrKey]);
        }
        return dateFilterHelper([fltrBy], row[fltrKey]);
      }

      if (exclude) return !_includes(fltrBy, row[fltrKey]);
      if (_isArray(row[fltrKey])) {
        return row[fltrKey]?.some(i => _includes(fltrBy, i));
      }
      return _includes(fltrBy, row[fltrKey]);
    });

    return filterValidation.every(val => {
      return val === true;
    });
  });
  return { filteredData, updatedFilters };
};
