import { Box } from '@chakra-ui/react';
import { Card } from 'src/v2/UIComponents/Primitives';
import { ChecklistTable } from 'src/v2/SharedComponents/ChecklistTable';

export const Checklist = () => (
  <Box height="100%">
    <Card height="100%">
      <ChecklistTable />
    </Card>
  </Box>
);
