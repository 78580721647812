import styled from 'styled-components';
import { Switch } from '@chakra-ui/react';

export const StyledSwitch = styled(Switch)`
  span[data-checked] {
    background: var(--colors-primary);

    span {
      background: white;
    }
  }
`;
