import { Tab } from '@chakra-ui/react';
import { get } from 'lodash';
import Colors from 'src/styles/Colors';
import { useFormContext } from 'react-hook-form';
import { CustomTabProps } from 'src/Types/GlobalTypes';
import { FC } from 'react';

const checkForErrors = (fields, errors) => {
  const hasErrors = fields.filter(field => get(errors, field));
  return hasErrors?.length > 0;
};

export const CustomTab: FC<CustomTabProps> = ({ title, fields }) => {
  const {
    formState: { errors }
  } = useFormContext();

  const hasErrors = fields && checkForErrors(fields, errors);
  return (
    <Tab
      py="14px"
      _selected={{
        color: Colors.black,
        bg: Colors.white
        // borderBottomColor: Colors.white
      }}
      borderColor={Colors.grayED}
      bg={Colors.grayF7}
      fontSize="14px"
      fontWeight="bold"
      position="relative"
      color={(hasErrors && `${Colors.redC0}`) || ''}
      px="2"
    >
      {title}
    </Tab>
  );
};

export const BaseCustomTab = ({ title }) => {
  return (
    <Tab
      py="14px"
      _selected={{
        color: Colors.black,
        bg: Colors.white
        // borderBottomColor: Colors.white
      }}
      borderColor={Colors.grayED}
      bg={Colors.grayF7}
      fontSize="14px"
      fontWeight="bold"
      position="relative"
    >
      {title}
    </Tab>
  );
};
