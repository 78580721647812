import { FC, forwardRef } from 'react';
import { Button as ChakraButton } from '@chakra-ui/react';
import Colors from 'src/styles/Colors';
import { Tooltip } from 'src/v2/UIComponents/Primitives';
import { Props } from './types';

export const Button: FC<Props> = forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      onClick,
      variant = 'primary',
      disableAnimation = false,
      isLoading = false,
      tooltip,
      ...props
    },
    ref
  ) => (
    <Tooltip label={tooltip} shouldWrapChildren openDelay={500}>
      <ChakraButton
        ref={ref}
        variant={variant}
        onClick={onClick}
        isLoading={isLoading}
        {...props}
        _hover={
          (variant === 'filterDropdown' && { backgroundColor: Colors.grayF7 }) || { opacity: 0.9 }
        }
      >
        {children}
      </ChakraButton>
    </Tooltip>
  )
);

Button.displayName = 'Button';
