import { useEvents, useVenue } from 'src/v2/Hooks/CollectionHooks';
import { SelectInputWithLabel } from 'src/v2/UIComponents/FormComponents';
import { Center, Icon } from '@chakra-ui/react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { FormLabel, Tooltip } from 'src/v2/UIComponents/Primitives';
import { LEAD_STATUS_LABEL, LEAD_STATUS_PLACEHOLDER } from '../../constants';

const LabelIcon = () => (
  <Tooltip label="Contact VenueX to convert this event back to a lead" shouldWrapChildren>
    <Center>
      <Icon as={AiOutlineQuestionCircle} w={3.5} h={3.5} marginLeft="2px" />
    </Center>
  </Tooltip>
);

const CustomLabel = isEvent => <FormLabel label={LEAD_STATUS_LABEL} />;

export const LeadStatus = ({ disableBook }: { disableBook?: boolean }) => {
  const { leadStatusOptions } = useVenue();
  const { activeEvent } = useEvents();
  const { type } = activeEvent || '';
  const rawOptions = leadStatusOptions?.map(item => ({
    label: item.displayName,
    value: item.value
  }));

  const options = rawOptions.map(item =>
    item.value === 'eventBooked' && disableBook ? { ...item, isDisabled: true } : item
  );

  const isEvent = type === 'event';
  const leadStatusProps = {
    name: 'leadInfo.status',
    placeholder: LEAD_STATUS_PLACEHOLDER,
    isDisabled: isEvent,
    label: LEAD_STATUS_LABEL,
    labelIcon: isEvent && <LabelIcon />,
    options,
    customlabel: <CustomLabel />
  };

  return <SelectInputWithLabel {...leadStatusProps} />;
};
