import { FORGOT_REQUEST, REMIND_REQUEST } from './types';

export const doForgot = ({ email, venueId, eventId }) => ({
  type: FORGOT_REQUEST,
  email,
  venueId,
  eventId
});

export const doRemind = ({ email, venueId, eventId }) => ({
  type: REMIND_REQUEST,
  email,
  venueId,
  eventId
});
