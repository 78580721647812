import { Flex, Text, Box } from '@chakra-ui/react';
import { Button } from 'src/v2/UIComponents/Primitives/Button';

export const CardHeader = ({
  title,
  iconTitle,
  rightActions,
  btnLabel,
  btnAction,
  btnIcon,
  btnIsDisabled = false,
  ...rest
}) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      paddingX="24px"
      paddingTop="18px"
      paddingBottom="6px"
      bg="white"
      zIndex="1"
      position="sticky"
      top={0}
      {...rest}
    >
      <Flex alignItems="center">
        {iconTitle}
        <Text variant="cardHeaderTitle" ml={iconTitle ? '15px' : ''}>
          {title}
        </Text>
      </Flex>
      {btnLabel && (
        <Button
          disableAnimation
          variant="primaryLink"
          onClick={btnAction}
          isDisabled={btnIsDisabled}
        >
          {btnLabel}
        </Button>
      )}
      {btnIcon && <Box cursor="pointer">{btnIcon}</Box>}
    </Flex>
  );
};
