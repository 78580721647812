import { DateFieldWithLabel } from 'src/v2/UIComponents/FormComponents';

export const EventEnd = ({ bookEvent }: { bookEvent: boolean }) => {
  const eventDateProps = {
    isRequired: bookEvent,
    name: 'end',
    label: 'Event End',
    placeholder: 'Set Event end date & time',
    rules: { required: bookEvent && 'Required' }
  };

  return <DateFieldWithLabel {...eventDateProps} />;
};
