import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useDrawers } from 'src/v2/Hooks/HelperHooks';
import { AnimatePresenceDiv } from '../../Animated/AnimatePresenceDiv';
import { Composer } from './Composer';

export const ComposerModal = () => {
  const { emailComposerModalOpen } = useDrawers();

  return (
    <AnimatePresenceDiv>
      {(emailComposerModalOpen && (
        <div>
          <motion.div
            key="composer"
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              zIndex: 20
            }}
            exit={{
              transform: 'scaleY(0)'
            }}
            initial={{
              transform: 'scaleY(0)'
            }}
            animate={{
              transform: 'scaleY(1)'
            }}
          >
            <Box position="absolute" bottom={0} right={0} zIndex={20}>
              <Composer />
            </Box>
          </motion.div>
        </div>
      )) || <div />}
    </AnimatePresenceDiv>
  );
};
