import { Button } from 'src/v2/UIComponents/Primitives';
import { Box, Text, Flex, HStack, Icon } from '@chakra-ui/react';
import { AddPlus } from 'src/v2/UIComponents/Primitives/Icons';
import { useEvents } from 'src/v2/Hooks/CollectionHooks';
import { EmptyState } from 'src/v2/UIComponents/EmptyState';
import { EventTaskDrawer } from 'src/v2/Drawers/EventTaskDrawer';
import { EventTask } from 'venuex-types';

import {
  scrollBarProps,
  scrollParentProps,
  scrollSiblingProps
} from 'src/v2/UIComponents/ScrollBar/scrollBarProps';
import { useConfirm, useDrawers } from 'src/v2/Hooks/HelperHooks';
import { EventTabBox } from 'src/v2/UIComponents/Primitives/Cards/EventTabBox';
import { useState } from 'react';
import Colors from 'src/styles/Colors';
import { getDateDisplay } from 'src/utils/getDateDisplay';
import { IoIosCheckmarkCircle } from 'react-icons/io';

export const EventTasksTab = () => {
  const { openEventTaskDrawer } = useDrawers();
  const { updateEvent, activeEvent, fetchingEvent } = useEvents();
  const [eventTaskToDelete, setEventTaskToDelete] = useState('');
  const handleEditTask = (eventTask: EventTask) =>
    openEventTaskDrawer({ eventTask, editEventTask: true });

  const eventTasks = activeEvent?.eventTasks
    ?.filter(task => !task?.archive)
    ?.sort((a, b) => {
      if (a.dueDate === undefined) return 1;
      if (b.dueDate === undefined) return -1;
      return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
    });

  const handleConfirmDelete = () => {
    if (eventTaskToDelete) {
      updateEvent({
        data: {
          eventTask: {
            id: eventTaskToDelete,
            archive: true
          }
        },
        eventId: activeEvent?.id,
        isLead: true
      });
    }
    setEventTaskToDelete('');
  };

  const { openConfirm } = useConfirm({
    acceptAction: handleConfirmDelete,
    confirmHookId: 'deleteTask'
  });

  const requestDelete = noteId => {
    setEventTaskToDelete(noteId);
    openConfirm({
      title: 'Delete Task',
      description: 'Are you sure you want to delete this tasks?',
      confirmId: 'deleteTask',
      confirmType: 'cancel'
    });
  };

  return (
    <>
      <Box display="flex" height="100%" flexDirection={'column'} gap="18px" sx={scrollParentProps}>
        <Flex justifyContent="flex-end" sx={scrollSiblingProps}>
          <Button
            variant="primary"
            leftIcon={<AddPlus color="white" w={4} h={4} />}
            width="140px"
            onClick={() => openEventTaskDrawer({})}
          >
            Add Task
          </Button>
        </Flex>
        <Flex
          flexDirection={'column'}
          minH="1px"
          gap={'24px'}
          overflowY="scroll"
          height="100%"
          sx={scrollBarProps}
        >
          {eventTasks?.length ? (
            eventTasks?.map((eventTask, key) => {
              // const createdByName = eventTask?.createdByName;

              const taskStatus = eventTask?.completed ? (
                <Icon as={IoIosCheckmarkCircle} color={Colors.green03} />
              ) : (
                <Icon as={IoIosCheckmarkCircle} color={Colors.grayB0} />
              );

              const dueDate = eventTask?.dueDate
                ? getDateDisplay(new Date(eventTask?.dueDate)).shortDateTime
                : 'N/A';

              return (
                <EventTabBox
                  key={key}
                  title={
                    <Flex alignItems="center">
                      {taskStatus}
                      <Text ml={2}>
                        {eventTask?.assignedToName} - {eventTask?.name}
                      </Text>
                    </Flex>
                  }
                  subtitle={''}
                  buttonAction={() => handleEditTask(eventTask)}
                  secondaryAction={() => requestDelete(eventTask?.id)}
                  icons
                >
                  <Box padding="22px">
                    <HStack justifyContent="space-between">
                      <Box>
                        <Text variant="contentSubTitle">
                          {eventTask?.description || 'No Description'}
                        </Text>
                      </Box>
                      <Box>
                        <Text variant="contentSubTitle" textAlign="right">
                          <Text display="inline">Due:</Text>
                          <Text fontWeight="bold" ml={1} display="inline">
                            {dueDate}
                          </Text>
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </EventTabBox>
              );
            })
          ) : (
            <EmptyState
              isLoading={fetchingEvent}
              title={'No Task Added Yet'}
              subTitle="Add a task as a reminder to follow up with this lead"
              btnAction={undefined}
              btnLabel={undefined}
            />
          )}
        </Flex>
      </Box>
      <EventTaskDrawer />
    </>
  );
};
