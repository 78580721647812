// action types
import {
  CHECK_CSV_FAILURE,
  CHECK_CSV_SUCCESS,
  CHECK_CSV_REQUEST,
  CANCEL_AFTER_CSV_PARSE,
  RESET,
  OPEN_FILE_MODAL,
  CLOSE_FILE_MODAL
} from './types';

// reducer with initial state
const initialState = {
  fetching: false,
  parsedData: null,
  error: null,
  file: null,
  columns: null,
  fileModalOpen: false
};

export function uploadButtonReducer(state = initialState, action) {
  switch (action.type) {
    case CANCEL_AFTER_CSV_PARSE:
      return { ...initialState };
    case RESET:
      return { ...initialState };
    case CHECK_CSV_REQUEST:
      return { ...state, fetching: true, error: null, file: action.file };
    case CHECK_CSV_SUCCESS:
      return {
        ...state,
        fetching: false,
        file: null,
        parsedData: action.parsedData,
        columns: action.columns
      };
    case CHECK_CSV_FAILURE:
      return {
        ...state,
        fetching: false,
        parsedData: null,
        file: null,
        error: action.error,
        columns: null
      };
    case OPEN_FILE_MODAL:
      return {
        ...state,
        fileModalOpen: true
      };
    case CLOSE_FILE_MODAL:
      return {
        ...state,
        fileModalOpen: false
      };
    default:
      return state;
  }
}
