import styled from 'styled-components';
import Colors from 'src/styles/Colors';

const DayHeaderStyles = styled.div`
  font-family: Lato;
  font-size: 14px;
  letter-spacing: -0.22px;
  color: ${props =>
    (props.dayType === 'today' && Colors.white) ||
    (props.dayType === 'before' && Colors.grayB0) ||
    (props.dayType === 'after' && Colors.gray7D)};
  font-weight: 600;
  background-color: ${props => (props.dayType === 'today' && Colors.black) || ''};
  width: 25px;
  height: 25px;
  float: right;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -4px;
`;

export default DayHeaderStyles;
