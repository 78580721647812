import React from 'react';
import PropTypes from 'prop-types';

import { useVenue } from 'src/v2/Hooks/CollectionHooks';
import { SelectInputWithLabel } from 'src/v2/UIComponents/FormComponents';
import {
  EVENT_CONSULTANT_LABEL,
  EVENT_CONSULTANT_PLACEHOLDER,
  EVENT_CONSULTANT_REQUIRED
} from '../constants';

export const EventConsultantInput = ({ bookEvent }) => {
  const { staffUsers } = useVenue();

  const staff =
    staffUsers &&
    staffUsers
      .filter(user => user.role === 'staff')
      .map(user => {
        return { value: user.id, label: `${user.firstname} ${user.lastname}`, photo: user.photo };
      });

  const consultantProps = {
    isRequired: bookEvent,
    label: EVENT_CONSULTANT_LABEL,
    name: 'consultant',
    options: staff,
    placeholder: EVENT_CONSULTANT_PLACEHOLDER,
    rules: { required: bookEvent && EVENT_CONSULTANT_REQUIRED }
  };

  return <SelectInputWithLabel {...consultantProps} />;
};
