import { takeLatest, call, put, select } from 'redux-saga/effects';
import { SAVE_LAYOUT_REQUEST, UPDATE_STATUS_REQUEST } from './types';
import { saveLayoutAction, updateStatusAction } from './actions';
import {
  saveLayoutSuccess,
  saveLayoutFailure,
  updateStatusSuccess,
  updateStatusFailure
} from './operations';
import { getLayoutByIdRequest, getLayoutsRequest } from '../LayoutsContainer/operations';

const getToken = state => state.loginReducer.token;
const getVenueId = state => state.loginReducer.venueId;

function* workerSaveLayout({ layout }) {
  try {
    const token = yield select(getToken);
    const venueId = yield select(getVenueId);

    const newLayout = {
      ...layout,
      venueId
    };

    const response = yield call(saveLayoutAction, { token, layout: newLayout });
    if (response.data.success) {
      // TO-DO: CONSIDER MAKING ASYNC AFTER SAVE AND SET
      yield put(saveLayoutSuccess({ layout: response.data.layout }));
      yield put(getLayoutByIdRequest({ token, layoutId: response.data.layout.id }));
      yield put(getLayoutsRequest({ venueId }));
    }
  } catch (error) {
    yield put(saveLayoutFailure({ error }));
  }
}

function* workerUpdateStatus({ layoutId, status }) {
  try {
    const token = yield select(getToken);
    const venueId = yield select(getVenueId);
    const response = yield call(updateStatusAction, { token, layoutId, status });
    if (response.data.success) {
      yield put(updateStatusSuccess({ layout: response.data.layout }));
      yield put(getLayoutsRequest({ venueId }));
    }
  } catch (error) {
    yield put(updateStatusFailure({ error }));
  }
}

export function* watchSaveLayout() {
  yield takeLatest(SAVE_LAYOUT_REQUEST, workerSaveLayout);
}

export function* watchUpdateStatus() {
  yield takeLatest(UPDATE_STATUS_REQUEST, workerUpdateStatus);
}
