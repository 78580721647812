import React from 'react';
import { ListCard } from 'src/v2/UIComponents/Primitives/Card/FullCard/FullCard';
import { Icon } from '@chakra-ui/react';
import { useEvents } from 'src/v2/Hooks/CollectionHooks';
import { getDateDisplay } from 'src/utils/getDateDisplay';
import { FaRegMoneyBillAlt } from 'react-icons/fa';

export const LegacyPaymentsCard = () => {
  const { fetchingEvent, activeEvent } = useEvents();

  // HM - Typecasting as a quick fix for somerset. Need to deep dive and fix types
  const schedule = (activeEvent?.paymentStatus?.schedule as unknown) as string[];

  const list =
    (schedule &&
      schedule?.map((pmt, i) => {
        return {
          title: `Deposit #${i + 1}`,
          value: `Due: ${getDateDisplay(new Date(pmt)).shortDate}`
        };
      })) ||
    [];

  return (
    <ListCard
      titleIcon={<Icon as={FaRegMoneyBillAlt} w={6} h={6} color="var(--colors-primary)" />}
      title={`Deposit Schedule`}
      list={list}
      fetching={fetchingEvent}
      headerBtnIsDisabled={fetchingEvent}
      footer={undefined}
      headerBtnIcon={undefined}
      headerBtnAction={undefined}
      headerBtnLabel={undefined}
    />
  );
};
