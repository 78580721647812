import {
  PARSE_TABLE_REQUEST,
  PARSE_TABLE_SUCCESS,
  PARSE_TABLE_FAILURE,
  SAVE_GUESTS_REQUEST,
  SAVE_GUESTS_FAILURE,
  SAVE_GUESTS_SUCCESS,
  FILE_MODAL_CLEAN,
  ERROR_CLEAR
} from './types';

const initialState = {
  fetching: false,
  error: null,
  data: null,
  columns: null,
  success: null,
  parseFetching: false,
  fetchingSave: false,
  failed: 0,
  saveSuccess: false
};

export function FileModalReducer(state = initialState, action) {
  switch (action.type) {
    case FILE_MODAL_CLEAN:
      return { ...initialState };
    case PARSE_TABLE_REQUEST:
      return { ...initialState, parseFetching: true };
    case PARSE_TABLE_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.data,
        columns: action.columns,
        failed: action.failed,
        success: true,
        fetchingSave: false,
        parseFetching: false
      };
    case PARSE_TABLE_FAILURE:
      return {
        ...state,
        fetching: false,
        data: null,
        error: action.error,
        columns: null,
        success: null,
        fetchingSave: false,
        parseFetching: false
      };
    case SAVE_GUESTS_REQUEST:
      return { ...state, fetchingSave: true, success: null };
    case SAVE_GUESTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        data: action.data,
        columns: action.columns,
        fetchingSave: false,
        saveSuccess: action.success
      };
    case SAVE_GUESTS_FAILURE:
      return {
        ...state,
        fetching: false,
        data: null,
        error: action.error,
        columns: null,
        fetchingSave: false,
        success: null
      };
    case ERROR_CLEAR:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
}
