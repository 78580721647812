import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export function listVendors({ token, venueId }) {
  return axios({
    method: 'get',
    url: `${apiUri}/venues/${venueId}/vendors`,
    headers: { Authorization: token }
  });
}

export function contactVendor({ token, emailData }) {
  const { venueId, eventId } = emailData;
  return axios({
    method: 'post',
    url: `${apiUri}/vendors/contact`,
    headers: { Authorization: token },
    data: {
      venueId,
      eventId,
      emailData: emailData.emailData
    }
  });
}
