import { GET_ROOMS_REQUEST, GET_ROOMS_SUCCESS, GET_ROOMS_FAILURE } from './types';

export const getRoomsRequest = ({ token }) => ({
  type: GET_ROOMS_REQUEST,
  token
});

export const getRoomsSuccess = ({ rooms }) => {
  return {
    type: GET_ROOMS_SUCCESS,
    rooms
  };
};

export const getRoomsFailure = error => ({
  type: GET_ROOMS_FAILURE,
  error
});
