import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStates, UseConfirm, UseConfirmReturn } from 'src/Types/GlobalTypes';
import {
  openConfirm,
  closeConfirm,
  confirmAccept,
  confirmReject
} from 'src/containers/ModalLoader/operations';
import { useActions } from 'src/hooks/useActions';

export const useConfirm: UseConfirm = ({
  acceptAction,
  confirmHookId,
  rejectAction
}): UseConfirmReturn => {
  const dispatch = useDispatch();
  const modalLoaderState = useSelector((state: RootStates) => state.modalLoaderReducer);
  const {
    confirmModalOpen,
    confirmModalTitle,
    confirmModalDescription,
    confirmAccepted,
    confirmId,
    confirmType,
    confirmNoSkip,
    confirmFields,
    confirmUpdate,
    confirmButtonTitle,
    confirmDefaultValues,
    confirmModalHeight,
    confirmRejected
  } = modalLoaderState;

  useEffect(() => {
    if (
      confirmId &&
      confirmHookId &&
      confirmAccepted &&
      confirmModalOpen &&
      confirmId === confirmHookId &&
      acceptAction
    ) {
      acceptAction();
      dispatch(closeConfirm());
    }
  }, [acceptAction, confirmId, confirmAccepted, confirmId, confirmModalOpen, confirmHookId]);

  useEffect(() => {
    if (
      confirmId &&
      confirmHookId &&
      confirmRejected &&
      confirmModalOpen &&
      confirmId === confirmHookId &&
      rejectAction
    ) {
      rejectAction();
      dispatch(closeConfirm());
    }
  }, [rejectAction, confirmId, confirmRejected, confirmId, confirmModalOpen, confirmHookId]);

  const accept = data => {
    // acceptAction(data);
    dispatch(closeConfirm());
  };

  const actions = useActions({
    openConfirm,
    closeConfirm,
    confirmAccept,
    confirmReject
  });

  return {
    confirmModalOpen,
    confirmModalTitle,
    confirmModalDescription,
    modalLoaderState,
    confirmNoSkip,
    confirmType,
    confirmFields,
    confirmUpdate,
    confirmButtonTitle,
    confirmDefaultValues,
    confirmModalHeight,
    accept,
    ...actions
  };
};
