import moment from 'moment';

export const getTimeDiff = (start, end) => {
  const startTime = moment(start);
  const endTime = moment(end);
  let seconds;
  if (moment(startTime).isBefore(endTime)) {
    seconds = endTime.diff(startTime, 'seconds');
  }
  if (moment(startTime).isAfter(endTime)) {
    seconds = startTime.diff(endTime, 'seconds');
  }

  let years = 0;
  let months = 0;
  let days = 0;
  let hours = 0;
  let minutes = 0;

  if (seconds > 0) {
    years = Math.floor(seconds / 31556952);
    seconds -= years * 31556952;
    months = Math.floor(seconds / 2629746);
    seconds -= months * 2629746;
    days = Math.floor(seconds / 86400);
    seconds -= days * 86400;
    hours = Math.floor(seconds / 3600) % 24;
    seconds -= hours * 3600;
    minutes = Math.floor(seconds / 60) % 60;
    seconds -= minutes * 60;
  }

  return { years, months, days, hours, minutes, seconds };
};
