import {
  FETCH_VENDOR_CATEGORIES_REQUEST,
  FETCH_VENDOR_CATEGORIES_FAILURE,
  FETCH_VENDORS_REQUEST,
  FETCH_VENDORS_SUCCESS,
  FETCH_VENDORS_FAILURE,
  FETCH_VENDOR_CATEGORIES_SUCCESS,
  SORT_ORDER,
  VENDOR_SET_SEARCH_TERM
} from './types';

const initialState = {
  status: '',
  vendorCategories: [],
  vendors: [],
  error: '',
  order: 'asc',
  searchTerm: '',
  updateVendorError: null,
  deletingVendorError: null
};

export const vendorsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VENDOR_CATEGORIES_REQUEST:
      return {
        ...state,
        status: 'fetchingVendorCategories'
      };
    case FETCH_VENDOR_CATEGORIES_SUCCESS:
      return {
        ...state,
        vendorCategories: action.vendorCategory,
        status: 'fetchingVendorCategoriesSuccess'
      };
    case FETCH_VENDOR_CATEGORIES_FAILURE:
      return {
        ...state,
        vendorCategories: [],
        error: action.error,
        status: 'fetchingVendorCategoriesFailure'
      };
    case FETCH_VENDORS_REQUEST:
      return {
        ...state,
        status: 'fetchingVendors'
      };
    case FETCH_VENDORS_SUCCESS:
      return {
        ...state,
        vendors: action.vendors,
        status: 'fetchingVendorsSuccess'
      };
    case FETCH_VENDORS_FAILURE:
      return {
        ...state,
        vendors: [],
        error: action.error,
        status: 'fetchingVendorsFailure'
      };
    case SORT_ORDER:
      return {
        ...state,
        order: action.order
      };
    case VENDOR_SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm
      };
    default:
      return state;
  }
};
