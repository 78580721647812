import { TabPanels as ChakraTabPanels } from '@chakra-ui/react';
import Colors from 'src/styles/Colors';
// import { ProgressBar } from 'src/v2/UIComponents';
import { CustomFieldsTabPageTable } from 'src/v2/SharedComponents/CustomFieldsTabs/CustomFieldsTabPageTable';
import { TabPanel } from '../TabPanel';
import { FieldBox } from '../../Primitives/Cards';

export const TabPanels = ({
  tabPanels,
  defaultRender = false,
  customRender = false,
  tabHeaders,
  noPadding,
  bgColor
}) => (
  <ChakraTabPanels
    bg={Colors.grayFA}
    h="100%"
    overflow="auto"
    pt="0"
    mt="12px"
    p={!noPadding && '6px'}
    pl="0"
    pr="0"
  >
    {tabPanels?.map((panel, key) => {
      return (
        <TabPanel key={key} padding="0" bgColor={bgColor}>
          {!defaultRender && panel?.groupObjects}
          {defaultRender &&
            panel?.panelInfo?.map((info, infoKey) => {
              const cards = info?.panelContentProps?.cards;

              const title = info?.panelHeaderProps?.title;
              const rowClickAction = info?.panelContentProps?.rowClickAction;

              return (
                <FieldBox title={title} key={infoKey}>
                  <CustomFieldsTabPageTable
                    data={cards}
                    groupKey={infoKey}
                    rowClickAction={rowClickAction}
                  />
                </FieldBox>
              );
            })}
          {customRender && panel?.component}
        </TabPanel>
      );
    })}
  </ChakraTabPanels>
);
