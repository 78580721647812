import { TabFormWrapper } from 'src/v2/UIComponents/Drawers/TabForm/TabForm';
import React, { FC, useEffect } from 'react';
import { EventFormProps } from 'src/Types/GlobalTypes';
import { useFormContext } from 'react-hook-form';
import { LeadDetailsTab } from '../FormTabs/LeadDetailsTab';
import { EventDetailsTab } from '../FormTabs/EventDetailsTab';
import { ClientDetailsTab } from '../FormTabs/ClientDetailsTab';
// import { BillingDetailsTab } from '../FormTabs/BillingDetailsTab';
// import { EventProductsFormWithData } from '../FormTabs/EventProductsTab';
import { LegacyPaymentsTab } from '../FormTabs/LegacyPaymentsTab';
import { useDefaultValues } from '../../../../Hooks/CollectionHooks/Events/useDefaultValues';

const RootForm: FC<EventFormProps> = ({ tabIndex, userId, defaultDate, bookEvent, mode }) => {
  // const {
  //   featureFlags: { advancedPayments }
  // } = useVenue();
  const methods = useFormContext();

  const LeadDetailsTabWithProps = (
    <LeadDetailsTab bookEvent={bookEvent} defaultDate={defaultDate} mode={mode} />
  );
  const EventDetailsTabWithProps = <EventDetailsTab bookEvent={bookEvent} mode={mode} />;
  // const BillingDetailsTabWithProps = <BillingDetailsTab createEvent={createEvent} />;
  const ClientDetailsTabWithprops = <ClientDetailsTab bookEvent={bookEvent} />;
  // const EventProductsFormWithDataProps = <EventProductsFormWithData />;
  const LegacyPaymentsTabWithProps = <LegacyPaymentsTab />;

  const { reset } = methods;

  const defaultValues = useDefaultValues();

  const defaultValuesLength = defaultValues && Object.keys(defaultValues).length;

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValuesLength]);

  const bookEventRequiredFields = {
    leadTab: ['clientName'],
    eventDetailsTab: [
      'consultant',
      'staff',
      'room',
      'layout',
      'start',
      'minimumGuests',
      'eventCode'
    ]
  };

  const tabList = [
    { tab: LeadDetailsTabWithProps, title: 'Lead', fields: bookEventRequiredFields.leadTab },
    {
      tab: EventDetailsTabWithProps,
      title: 'Event',
      fields: bookEventRequiredFields.eventDetailsTab
    },
    // {
    //   tab: BillingDetailsTabWithProps,
    //   title: 'Billing',
    //   fields: ['paymentScheduleTemplate']
    // },
    { tab: ClientDetailsTabWithprops, title: 'Portal', fields: ['users'] },
    { tab: LegacyPaymentsTabWithProps, title: 'Deposit Schedule' }
    // {
    //   tab: EventProductsFormWithDataProps,
    //   title: 'Products',
    //   fields: ['minimumGuests', 'rateType']
    // }
  ].filter(tab => tab);

  return (
    <TabFormWrapper
      tabList={tabList}
      defaultIndex={tabIndex}
      index={undefined}
      setTabIndex={undefined}
      panelSpacing={undefined}
    />
  );
};

export const Form = React.memo(RootForm);
