import { OpenConfirmProps } from 'src/Types';
import {
  OPEN_MODAL_LOADER,
  CLOSE_MODAL_LOADER,
  UPDATE_MODAL_LOADER_MESSAGE,
  SHOW_GLASS_LOADER,
  HIDE_GLASS_LOADER,
  SET_GLASS_LOADER_MESSAGE,
  OPEN_CONFIRM,
  CLOSE_CONFIRM,
  CONFIRM_ACCEPT,
  CONFIRM_REJECT,
  OPEN_TOAST,
  CLOSE_TOAST
} from './types';

export const openModalLoader = ({
  showProgressBar,
  modalLoaderMessage,
  modalLoaderType,
  modalLoaderHeader
}) => ({
  type: OPEN_MODAL_LOADER,
  showProgressBar,
  modalLoaderMessage,
  modalLoaderType,
  modalLoaderHeader
});

export const closeModalLoader = () => ({
  type: CLOSE_MODAL_LOADER
});

export const updateModalLoaderMessage = ({
  modalLoaderMessage,
  showProgressBar,
  modalLoaderHeader
}) => ({
  type: UPDATE_MODAL_LOADER_MESSAGE,
  modalLoaderMessage,
  showProgressBar,
  modalLoaderHeader
});

export const showGlassLoader = message => ({
  type: SHOW_GLASS_LOADER,
  message
});

export const setGlassLoaderMessage = message => ({
  type: SET_GLASS_LOADER_MESSAGE,
  message
});

export const hideGlassLoader = () => ({
  type: HIDE_GLASS_LOADER
});

export const openConfirm = ({
  title,
  description,
  confirmId,
  confirmType,
  noSkip,
  fields,
  toUpdate,
  confirmButtonTitle,
  defaultValues,
  height
}: OpenConfirmProps) => ({
  type: OPEN_CONFIRM,
  title,
  height,
  description,
  confirmId,
  confirmType,
  noSkip,
  fields,
  toUpdate,
  confirmButtonTitle,
  defaultValues
});

export const closeConfirm = () => ({
  type: CLOSE_CONFIRM
});

export const confirmAccept = () => ({
  type: CONFIRM_ACCEPT
});
export const confirmReject = () => ({
  type: CONFIRM_REJECT
});

export const openToast = (message, body) => ({
  type: OPEN_TOAST,
  message,
  body
});

export const closeToast = () => ({
  type: CLOSE_TOAST
});
