import { Box, Icon } from '@chakra-ui/react';
import { FC } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BsArrowsAngleContract, BsArrowsAngleExpand } from 'react-icons/bs';
import Colors from 'src/styles/Colors';
import { useDrawers } from 'src/v2/Hooks/HelperHooks';
import { Text } from 'src/v2/UIComponents/Primitives';
import { ComposerHeaderTypes } from '../types';

export const ComposerHeader: FC<ComposerHeaderTypes> = ({ subject, minimize, minimized }) => {
  const { closeEmailComposer } = useDrawers();
  return (
    <Box
      borderBottom={`1px solid ${Colors.grayED7}`}
      backgroundColor="var(--colors-primary-lightest)"
      height="50px"
      display="flex"
      justifyContent={'space-between'}
      padding="0px 12px"
      minHeight={'50px'}
      alignContent="center"
      zIndex={21}
    >
      <Box
        _hover={{
          cursor: 'pointer',
          backgroundColor: Colors.gray7D
        }}
        display="flex"
        justifyContent={'center'}
        alignContent="center"
        alignItems="center"
      >
        <Text variant="contentTitle" fontSize="18px">
          {subject}
        </Text>
      </Box>
      <Box
        display="flex"
        gap="12px"
        justifyContent={'center'}
        alignContent="center"
        alignItems="center"
      >
        <Box
          _hover={{
            cursor: 'pointer',
            opacity: 0.6
          }}
          display="flex"
          justifyContent={'center'}
          alignContent="center"
          alignItems="center"
        >
          <Icon
            as={(minimized && BsArrowsAngleExpand) || BsArrowsAngleContract}
            onClick={minimize}
            w={'16px'}
            h={'16px'}
          />
        </Box>
        <Box
          _hover={{
            cursor: 'pointer',
            opacity: 0.6
          }}
          display="flex"
          justifyContent={'center'}
          alignContent="center"
          alignItems="center"
        >
          <Icon as={AiOutlineClose} onClick={closeEmailComposer} w={'17px'} h={'17px'} />
        </Box>
      </Box>
    </Box>
  );
};
