import React from 'react';

import { Portal, PopoverContent, PopoverArrow, HStack, Grid, Flex, Box } from '@chakra-ui/react';
import { Button } from 'src/v2/UIComponents/Primitives';
import moment from 'moment';
import Download from 'src/styles/Icons/Download';
import Edit from 'src/styles/Icons/Edit';
import {
  DOWNLOADING_EVENT_PACKET_HEADER,
  DOWNLOADING_EVENT_PACKET_MESSAGE,
  STILL_WORKING
} from 'src/components/ModalLoader/types';

import Colors from 'src/styles/Colors';
import { useEvents, useEventsDetails } from 'src/v2/Hooks/CollectionHooks';
import { PopoverStyles } from './styles';

export const EventPopOver = props => {
  const { event } = props;
  // const { openEventFormDrawer } = useEventsDetails();
  const {
    getEvent,
    downloadEventPacket,
    openModalLoader,
    updateModalLoaderMessage,
    closeModalLoader,
    openCreateEventForm
  } = useEvents();

  const {
    paymentStatus: { status: paymentStatus }
  } = event;

  const handleEditClick = el => {
    el.stopPropagation();
    getEvent({ eventId: event.id });
    openCreateEventForm();
  };

  const handleDownloadEventPacketClick = () => {
    downloadEventPacket({ eventId: event.id, updateModalLoaderMessage, closeModalLoader });
    openModalLoader({
      showProgressBar: true,
      modalLoaderMessage: DOWNLOADING_EVENT_PACKET_MESSAGE(event.title),
      modalLoaderHeader: DOWNLOADING_EVENT_PACKET_HEADER
    });
    const update = () =>
      updateModalLoaderMessage({
        showProgressBar: true,
        modalLoaderMessage: STILL_WORKING
      });
    setTimeout(() => update(), 3500);
  };

  return (
    <Portal>
      <PopoverContent w="550px">
        <PopoverArrow />
        <PopoverStyles>
          <PopoverStyles.Header>{event.title}</PopoverStyles.Header>
          <PopoverStyles.SubHeader>{moment(event.start).format('LLLL')}</PopoverStyles.SubHeader>
          <Flex justifyContent="space-between" alignItems="center" w="100%">
            <Button variant="secondary" onClick={handleDownloadEventPacketClick}>
              <HStack align="center" justifyContent="space-between">
                <Download color="var(--colors-primary)" />{' '}
                <Box ml="15px">Download Event Packet</Box>
              </HStack>
            </Button>
            <Button variant="outline" onClick={handleEditClick}>
              <HStack align="center" justifyContent="space-between">
                <Edit color="var(--colors-primary)" />
                <Box ml="15px">Edit Event Details</Box>
              </HStack>
            </Button>
          </Flex>
        </PopoverStyles>
      </PopoverContent>
    </Portal>
  );
};
