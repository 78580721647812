/* eslint-disable no-plusplus */
/* eslint-disable no-fallthrough */
/* eslint-disable no-self-assign */
import { createSelector } from 'reselect';
import moment from 'moment';
import _ from 'lodash';
import { sortChecklistItems } from './sortChecklistItems';
// import { appTutorials } from './tutorials';

const getGuests = state => state.guestReducer.guests;
const getStaff = state => state.getStaffReducer.staff;
const getSearchTerm = state => state.guestListReducer.searchTerm;
const getSortField = state => state.guestListReducer.sortField;
const getSortValue = state => state.guestListReducer.sortValue;
const getRsvpFilter = state => state.guestListReducer.rsvpFilter;
const getMealsFilter = state => state.guestListReducer.filterMeals;
const getTableFilter = state => state.guestListReducer.tableFilter;
const getGroupFilter = state => state.guestListReducer.groupFilter;
const getMemories = state => state.memoriesReducer.memories;
const getMemoriesSearchTerm = state => state.memoriesReducer.searchTerm;
const getBudgetPlannerItems = state => state.budgetPlannerReducer.items;
const getBudgetPlannerSearchTerm = state => state.budgetPlannerReducer.searchTerm;
const getStatusFilter = state => state.budgetPlannerReducer.statusFilter;
const getChecklistItems = state => state.eventsReducer.event || {};
const getChecklistSearchTerm = state => state.checklistReducer.searchTerm;
const getChecklistSortField = state => state.checklistReducer.sortField;
const getChecklistSortOrder = state => state.checklistReducer.sortOrder;
const getChecklistCompletedFilter = state => state.checklistReducer.completedFilter;
const getAllVendors = state => state.vendorsReducer.vendors;
const getVendorItem = state => state.vendorsReducer.vendorItem;
const getFilteredVendors = state => state.vendorsReducer.filteredVendors;
const getEventVendors = state => state.eventVendorsReducer.eventVendors;
const getVendorCategoryOrder = state => state.vendorsListReducer.order;
const getEventVendorsSearchTerm = state => state.vendorsListReducer.searchTerm;
const getVendorsSearchTerm = state => state.vendorsReducer.searchTerm;
const getVenueData = state => state.venuesReducer.venue;
const getCurrentTutorialIndex = state => state.venuesReducer.currentTutorialIndex;
const getHostDetails = state => state.venuesReducer.userDetails;
const getLayouts = state => state.layoutsReducer.layouts;
const getLayoutSearchTerm = state => state.layoutsReducer.searchTerm;
const getDocumentLibraries = state => state.documentLibraryReducer.documentLibraryFiles;

export const getFillteredGuests = createSelector(
  [
    getGuests,
    getSearchTerm,
    getSortField,
    getSortValue,
    getRsvpFilter,
    getMealsFilter,
    getTableFilter,
    getGroupFilter
  ],
  (guests, searchTerm, sortField, sortValue, rsvpFilter, filterMeals, tableFilter, groupFilter) => {
    let filteredGuests = [];
    filteredGuests = guests.filter(
      guest => guest.name && guest.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
    );
    switch (sortField) {
      case 'name':
        if (sortValue === 'asc') {
          filteredGuests = filteredGuests.sort((a, b) => {
            const nameA = a.name && a.name.toLowerCase(); // ignore upper and lowercase
            const nameB = b.name && b.name.toLowerCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          });
        } else {
          filteredGuests = filteredGuests.sort((a, b) => {
            const nameA = a.name && a.name.toLowerCase(); // ignore upper and lowercase
            const nameB = b.name && b.name.toLowerCase(); // ignore upper and lowercase
            if (nameB < nameA) {
              return -1;
            }
            if (nameB > nameA) {
              return 1;
            }
            // names must be equal
            return 0;
          });
        }
      case 'rsvp':
        if (rsvpFilter === 'all') {
          filteredGuests = filteredGuests;
        } else {
          filteredGuests = filteredGuests.filter(guest => guest.rsvp === rsvpFilter);
        }
      case 'table':
        if (tableFilter) {
          filteredGuests = filteredGuests.filter(
            guest => parseInt(guest.table, 10) === parseInt(tableFilter, 10)
          );
        } else {
          filteredGuests = filteredGuests;
        }
      case 'group':
        if (groupFilter && groupFilter.length) {
          filteredGuests = filteredGuests.filter(
            guest => groupFilter.indexOf(guest.groupId) !== -1
          );
        } else {
          filteredGuests = filteredGuests;
        }
      case 'meals':
        if (filterMeals === 'all') {
          filteredGuests = filteredGuests;
        } else if (filterMeals.toLowerCase() === 'none') {
          filteredGuests = filteredGuests.filter(guest => {
            return (
              guest.menuOption &&
              (guest.menuOption.mealType === 'None' || !guest.menuOption.mealType)
            );
          });
        } else {
          filteredGuests = filteredGuests.filter(guest => {
            return (
              guest.menuOption &&
              guest.menuOption.mealType &&
              guest.menuOption.mealType.toLowerCase() === filterMeals.toLowerCase()
            );
          });
        }
      default:
        filteredGuests = filteredGuests;
    }

    return filteredGuests;
  }
);

export const getFilteredStaff = createSelector([getStaff, getSearchTerm], (staff, searchTerm) => {
  const filteredStaff =
    staff &&
    staff.filter(
      s =>
        s.firstname.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
        s.lastname.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
        s.email.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
    );
  return filteredStaff;
});

export const getFilteredLayout = createSelector(
  [getLayouts, getLayoutSearchTerm],
  (layouts, searchTerm = '') => {
    const filteredLayouts =
      layouts &&
      layouts.filter(
        s => s.name.toLowerCase().indexOf(searchTerm && searchTerm.toLowerCase()) !== -1
      );
    return filteredLayouts;
  }
);

export const getFilteredMemories = createSelector(
  [getMemories, getMemoriesSearchTerm],
  (memories, searchTerm) => {
    const filteredMemories =
      memories &&
      memories.filter(
        mem =>
          (mem.uploader.firstname &&
            mem.uploader.firstname.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) ||
          (mem.uploader.lastname &&
            mem.uploader.lastname.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)
      );
    return filteredMemories;
  }
);

export const getGuestStats = createSelector([getGuests], guests =>
  guests.reduce(
    (accumulator, currentValue) => {
      const { rsvp, party, table } = currentValue;
      const accumulated = { ...accumulator };
      accumulated[rsvp] += Number(party);
      accumulated.total += Number(party);
      if (table) {
        accumulated.assigned += Number(party);
      }
      return accumulated;
    },
    { yes: 0, no: 0, maybe: 0, awaiting: 0, total: 0, assigned: 0 }
  )
);

export const getFilteredBudgetPlannerItems = createSelector(
  [getBudgetPlannerItems, getStatusFilter, getBudgetPlannerSearchTerm],
  (items, statusFilter, searchTerm) => {
    // Check if payment status is pending
    const checkIfPending = installments => {
      const notPaidInstallments = Object.values(installments)
        .filter(installment => installment.installmentStatus === 'notPaid')
        .map(installment => installment.installmentDueDate);
      // Find minimum unpaid installment
      const minimumDate = _.min(notPaidInstallments);
      return moment(minimumDate).isAfter();
    };

    // Check for payment status
    const getPillStatus = installments => {
      if (Object.keys(installments).length) {
        const notPaidInstallments = Object.values(installments).filter(
          installment => installment.installmentStatus === 'notPaid'
        );
        if (!notPaidInstallments.length) {
          return 'Paid';
        }
        if (checkIfPending(installments)) {
          return 'Pending';
        }
      }
      return 'Overdue';
    };

    return items
      .map(item => {
        return { ...item, status: getPillStatus(item.installments) };
      })
      .filter(item => {
        if (statusFilter.toLowerCase() === 'all') {
          if (searchTerm) {
            return (
              item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
              item.category.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            );
          }
          return item;
        }
        if (searchTerm && !statusFilter) {
          return (
            item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
            item.category.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          );
        }
        if (statusFilter && !searchTerm) {
          return item.status.toLowerCase() === statusFilter.toLowerCase();
        }
        if (statusFilter && searchTerm) {
          return (
            (item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
              item.category.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) &&
            item.status.toLowerCase() === statusFilter.toLowerCase()
          );
        }
        return item;
      });
  }
);

export const getBudgetPlannerStats = createSelector([getBudgetPlannerItems], items => {
  // Determine the next due item i.e. the earliest not paid installment date
  const nextDueItem = items
    .reduce((allInstallments, { installments, name }) => {
      const modifiedInstallments = Object.values(installments).map(installment => {
        return { ...installment, name };
      });
      return [...allInstallments, ...modifiedInstallments];
    }, [])
    .filter(installment => installment.installmentStatus === 'notPaid')
    .sort((a, b) => moment.utc(a.installmentDueDate) - moment.utc(b.installmentDueDate))[0];

  const stats = items.reduce(
    (acc, value) => {
      const { totalAmount } = value;
      acc.totalAmount += totalAmount;
      // Loop through the current items installments to get paid total and next due date
      Object.values(value.installments).forEach(installment => {
        const { installmentStatus, installmentAmount } = installment;
        if (installmentStatus === 'paid') {
          acc.paid += installmentAmount;
        }
      });
      return acc;
    },
    { totalAmount: 0, paid: 0 }
  );

  return { ...stats, nextDueItem };
});

export const getFilteredChecklistItems = createSelector(
  [
    getChecklistItems,
    getChecklistSearchTerm,
    getChecklistSortField,
    getChecklistSortOrder,
    getChecklistCompletedFilter
  ],
  (event, searchTerm, sortField, sortOrder, completedFilter) => {
    const filteredItems =
      (event &&
        event.checklistItems &&
        Object.entries(event.checklistItems).reduce((acc, [key, value]) => {
          if (value?.name?.toLowerCase().indexOf(searchTerm?.toLowerCase()) !== -1) {
            if (completedFilter === 'completed' && value.completed) {
              acc[key] = value;
            }
            if (completedFilter === 'uncompleted' && !value.completed) {
              acc[key] = value;
            }
            if (completedFilter === 'all') {
              acc[key] = value;
            }
          }
          return acc;
        }, {})) ||
      {};

    return sortChecklistItems({ sortField, sortOrder, filteredItems, completedFilter });
  }
);

export const getChecklistStats = createSelector([getChecklistItems], event => {
  const completed =
    event &&
    event.checklistItems &&
    Object.values(event.checklistItems)
      .flat()
      .filter(item => item.completed).length;
  const total = event && event.checklistItems && Object.values(event.checklistItems).flat().length;
  const percentageCompleted = total ? (completed / total) * 100 : 0;
  return { completed, total, percentageCompleted };
});

export const filterVendors = createSelector(
  [getAllVendors, getVendorItem, getFilteredVendors, getVendorsSearchTerm],
  (vendors, vendorItem, filteredVendors, searchTerm) => {
    let filteredVendor = [];
    if (searchTerm.length) {
      filteredVendor =
        vendors &&
        vendors.filter(
          vendor =>
            vendor.category &&
            vendor.category.categoryName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
        );
      return filteredVendor;
    }
    if (!vendorItem && (filteredVendors === 'all' || !filteredVendors)) {
      filteredVendor = vendors;
    }
    if (!filteredVendors && vendorItem) {
      filteredVendor = vendors.filter(vendor => vendor.vendors.id === vendorItem);
    }
    if (filteredVendors && filteredVendors !== 'all' && !vendorItem) {
      filteredVendor = vendors.filter(
        vendor => vendor.category.categoryName.toLowerCase() === filteredVendors.toLowerCase()
      );
    }

    const sortedVendors =
      filteredVendor &&
      filteredVendor.sort((a, b) => {
        const nameA = a.name && a.name.toLowerCase();
        const nameB = b.name && b.name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });

    return sortedVendors;
  }
);

export const sortEventVendors = createSelector(
  [getEventVendors, getVendorCategoryOrder, getEventVendorsSearchTerm],
  (eventVendors, order, searchTerm) => {
    let vendors = [];
    if (searchTerm.length) {
      vendors =
        eventVendors &&
        eventVendors.filter(
          eVendor =>
            eVendor.vendorName &&
            (eVendor.vendorName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
              eVendor.vendorCategory.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)
        );
      return vendors;
    }
    if (order === 'asc') {
      vendors =
        eventVendors &&
        eventVendors.sort((a, b) => {
          const nameA = a.vendorCategory && a.vendorCategory.toLowerCase();
          const nameB = b.vendorCategory && b.vendorCategory.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
    } else {
      vendors =
        eventVendors &&
        eventVendors.sort((a, b) => {
          const nameA = a.vendorCategory && a.vendorCategory.toLowerCase();
          const nameB = b.vendorCategory && b.vendorCategory.toLowerCase();
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
    }
    return vendors;
  }
);

export const getFilteredDocumentLibraries = createSelector([getDocumentLibraries], documents => {
  const newDocuments = documents.map(doc => {
    return {
      ...doc
    };
  });

  return newDocuments;
});
// export const tutorials = createSelector(
//   [getVenueData, getCurrentTutorialIndex, getHostDetails],
//   (venue, tutorialIndex, hostDetails) => {
//     return appTutorials(venue, tutorialIndex, hostDetails);
//   }
// );
