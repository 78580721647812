import { Flex } from '@chakra-ui/react';
import { BiLibrary, BiFile } from 'react-icons/bi';
import { MiniCardButton } from 'src/v2/UIComponents/Cards/MiniCard/MiniCardButton';

export const StepOne = ({ setDocumentUploadType, documentUploadType }) => {
  return (
    <Flex gap={8} flexDir="column">
      <MiniCardButton
        title="From the Document Library"
        subTitle={'Add a document from the library'}
        icon={BiLibrary}
        selected={documentUploadType === 'library'}
        handleClick={() => setDocumentUploadType('library')}
        isLoading={false}
      />
      <MiniCardButton
        title="From your computer"
        subTitle={'Upload a document'}
        icon={BiFile}
        selected={documentUploadType === 'upload'}
        handleClick={() => setDocumentUploadType('upload')}
        isLoading={undefined}
      />
    </Flex>
  );
};
