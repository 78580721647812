import { takeLatest, call, put } from 'redux-saga/effects';
import { getRoomsSuccess, getRoomsFailure } from './operations';
import { getRoomsAction } from './actions';
import { GET_ROOMS_REQUEST } from './types';

function* workerGetRooms({ token }) {
  try {
    const response = yield call(getRoomsAction, { token });
    yield put(getRoomsSuccess({ rooms: response.data.rooms }));
  } catch (error) {
    yield put(getRoomsFailure(error.response.data.message));
  }
}

export function* watchGetRooms() {
  yield takeLatest(GET_ROOMS_REQUEST, workerGetRooms);
}
