import { Portal, PopoverContent, PopoverArrow, Flex, Icon, Box } from '@chakra-ui/react';
import { Text } from 'src/v2/UIComponents/Primitives';
import { TextPairing } from 'src/v2/UIComponents';
import { ListCard } from 'src/v2/UIComponents/Primitives/Card/FullCard/FullCard';
import { Calendar } from 'src/v2/UIComponents/Primitives/Icons';

const MiniCard = ({ icon, title, isLoading, subTitle, height }) => {
  return (
    <Box display="flex" paddingX="18px" paddingY="12px" height="165px">
      <Flex gap={'18px'} width="100%">
        <Flex alignItems="center" color="var(--colors-primary)">
          <Icon as={icon} w={5} h={5} />
        </Flex>
        <Flex flexDir="column" width="100%" justifyContent="space-between">
          <Text fontWeight={700} fontSize="15px" lineHeight="20px" mb="4px">
            {title}
          </Text>
          <Text fontWeight={400} fontSize="14px" lineHeight="24px">
            {subTitle}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export const AppointmentPopover = props => {
  const { appointment } = props;
  // const { openEventFormDrawer } = useEventsDetails();

  const startTime = new Date(appointment.start).toLocaleTimeString().replace(/(.*)\D\d+/, '$1');
  const list = [
    {
      title: 'Organizer',
      value: `${appointment.organizerInfo?.firstname} ${appointment.organizerInfo?.lastname}`
    },
    {
      title: 'Start Time',
      value: startTime
    },
    {
      title: 'Related Event or Lead',
      value: appointment.eventTitle
    },
    {
      title: 'Appointment Notes',
      value: appointment.notes || 'No Appointment Notes'
    }
  ];

  const PopoverBody = () => {
    return (
      <ListCard
        titleIcon={<Calendar color="var(--colors-primary)" />}
        title={`Appointment Details`}
        list={list}
      />
    );
  };

  return (
    <Portal>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody />
      </PopoverContent>
    </Portal>
  );
};
