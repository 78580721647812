import { Box } from '@chakra-ui/react';
import { Text } from 'src/v2/UIComponents/Primitives';

export const ContentTitle = ({ title }) => (
  <Box w="100%" marginTop={3}>
    <Text color={'var(--colors-primary)'} variant="contentTitle">
      {title}
    </Text>
  </Box>
);
