import React from 'react';
import PropTypes from 'prop-types';

const Checklist = ({ sideBarIcon, ...props }) => {
  const sideBarIconColor = sideBarIcon ? 'var(--colors-sideBarText)' : 'var(--colors-primary)';
  return (
    <svg width={props.size} height={props.size} {...props} viewBox="0 0 24 24">
      <g fill={props.color ? props.color : sideBarIconColor}>
        <path d="M21.798 6.235v16.76c0 .443-.36.803-.804.803H3.24a.804.804 0 0 1-.804-.804V1.173c0-.444.36-.804.804-.804l13.332.003a.537.537 0 0 1 .145-.003l.094.007a.71.71 0 0 1 .103.028h.054l.1.068c.06.039.113.086.165.145l4.394 5.136a.769.769 0 0 1 .155.355v.034a.331.331 0 0 0-.041-.113l.041.15v.05l.004-.037.012.043zm-4.372-.803h1.814l-1.814-2.12v2.12zM4.051 22.19h16.132V7.039h-3.56a.804.804 0 0 1-.804-.804V1.944H4.051V22.19z" />
        <path d="M19.438 8.185c0 .444-.36.804-.803.804H10.32a.804.804 0 0 1 0-1.607h8.315c.444 0 .803.36.803.803zM8.142 6.636a.4.4 0 0 1 .132-.064.804.804 0 0 1 .818 1.372l-2 1.697a.801.801 0 0 1-1.066-.074l-.81-.854A.804.804 0 0 1 6.36 7.587l.314.357 1.467-1.308zm0 5.25a.4.4 0 0 1 .132-.064.804.804 0 0 1 .818 1.372l-2 1.697a.801.801 0 0 1-1.066-.074l-.81-.854a.804.804 0 0 1 1.145-1.126l.314.357 1.467-1.308zm0 5.25a.4.4 0 0 1 .132-.064.804.804 0 0 1 .818 1.372l-2 1.697a.801.801 0 0 1-1.066-.074l-.81-.854a.804.804 0 0 1 1.145-1.126l.314.357 1.467-1.308zm11.296-3.554c0 .444-.36.803-.803.803H10.32a.804.804 0 0 1 0-1.607h8.315c.444 0 .803.36.803.804zm0 5.398c0 .444-.36.804-.803.804H10.32a.804.804 0 1 1 0-1.607h8.315c.444 0 .803.36.803.804z" />
      </g>
    </svg>
  );
};

Checklist.defaultProps = {
  size: 24
};

Checklist.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  sideBarIcon: PropTypes.bool
};

export default Checklist;
