import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export function updateHostTutorial({ token, id, data }) {
  return axios({
    method: 'put',
    url: `${apiUri}/users/${id}`,
    headers: { Authorization: token },
    data
  });
}

export function getHostTutorialDetails({ token, id }) {
  return axios({
    method: 'get',
    url: `${apiUri}/users/tutorials/${id}`,
    headers: { Authorization: token }
  });
}

// export function getTutorials({ token, id }) {
//   return axios({
//     method: 'get',
//     url: `${apiUri}/users/tutorials/${id}`,
//     headers: { Authorization: token }
//   });
// }
