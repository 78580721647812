export const GET_STAFF_REQUEST = 'GET_STAFF_REQUEST';
export const GET_STAFF_SUCCESS = 'GET_STAFF_SUCCESS';
export const GET_STAFF_FAILURE = 'GET_STAFF_FAILURE';

export const UPDATE_STAFF_REQUEST = 'UPDATE_STAFF_REQUEST';
export const UPDATE_STAFF_SUCCESS = 'UPDATE_STAFF_SUCCESS';
export const UPDATE_STAFF_FAILURE = 'UPDATE_STAFF_FAILURE';

export const DELETE_STAFF_REQUEST = 'DELETE_STAFF_REQUEST';
export const DELETE_STAFF_SUCCESS = 'DELETE_STAFF_SUCCESS';
export const DELETE_STAFF_FAILURE = 'DELETE_STAFF_FAILURE';

export const GET_STAFF_EVENTS_REQUEST = 'GET_STAFF_EVENTS_REQUEST';
export const GET_STAFF_EVENTS_SUCCESS = 'GET_STAFF_EVENTS_SUCCESS';
export const GET_STAFF_EVENTS_FAILURE = 'GET_STAFF_EVENTS_FAILURE';

export const OPEN_ADD_STAFF_MODAL = 'OPEN_ADD_STAFF_MODAL';
export const CLOSE_ADD_STAFF_MODAL = 'CLOSE_ADD_STAFF_MODAL';

export const ADD_STAFF_REQUEST = 'ADD_STAFF_REQUEST';
export const ADD_STAFF_SUCCESS = 'ADD_STAFF_REQUEST_SUCCESS';
export const ADD_STAFF_FAILURE = 'ADD_STAFF_REQUEST_FAILURE';
