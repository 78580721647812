import { useEffect, useState } from 'react';
import { Drawer, DrawerContent, Flex, Icon, Circle } from '@chakra-ui/react';
import { useEvents, useLeads, useUser, useVenue } from 'src/v2/Hooks/CollectionHooks';
import { CardSteps, CardsSectionInfo, Tabs } from 'src/v2/UIComponents';
import { Activities, EventType } from 'src/v2/UIComponents/Primitives/Icons';
import { DisplayInfo } from 'src/v2/Pages/Leads/DisplayInfo';
import { getInitials } from 'src/utils/Helpers';
import { useConfirm, useDrawers } from 'src/v2/Hooks/HelperHooks';
import { EventTasksTab } from 'src/v2/Drawers/LeadsDetails/EventTasks';
import { DuplicatesTab } from 'src/v2/Drawers/LeadsDetails/LeadDuplicates';
import { BsArrowsAngleContract, BsArrowsAngleExpand } from 'react-icons/bs';
import { LeadMessages } from 'src/v2/Drawers/LeadsDetails/LeadMessages';
import moment from 'moment';
import Colors from 'src/styles/Colors';
import { ConfirmFields } from 'src/Types';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { Notes } from './Notes';
import { Documents } from './Documents';
import { ContractDetails } from './ContractDetails';
import { SALESPERSON_LABEL } from '../LeadsFormDrawer/constants';
import { ActivitiesTab } from './Activities';
import { EventManagementDrawer } from '../EventManagementDrawer/EventManagementDrawer';

export const LeadsDetails = () => {
  // TODO: #548 Disable Tabs and buttons when leads details is loading

  const [expanded, setExpanded] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const { userId } = useUser();
  const { isLeadsDetailsOpen, closeLeadsDetails, defaultTabIndex } = useLeads();
  const { fetchingEvent, activeEvent: lead, updateEvent: updateLead } = useEvents();
  const disableActions = fetchingEvent;
  const { leadStatusOptions, staffUsers } = useVenue();
  const { openEventManagementDrawer } = useDrawers();
  const hasDuplicates = lead?.possibleDuplicates?.length && lead?.possibleDuplicates?.length > 0;
  const staff =
    staffUsers &&
    staffUsers
      .filter(user => user.role === 'staff')
      .map(user => ({
        value: user.id,
        label: `${user.firstname} ${user.lastname}`,
        photo: user.photo
      }));
  const { salesperson, eventType, type } = lead || {};

  useEffect(() => {
    !isLeadsDetailsOpen && setTabIndex(0);
    if (isLeadsDetailsOpen) setTabIndex(defaultTabIndex);
  }, [isLeadsDetailsOpen]);

  const { openConfirm: openSalesPersonConfirm } = useConfirm({
    acceptAction: () => {},
    confirmHookId: 'salespersonConfirm'
  });

  const fields: ConfirmFields = [
    {
      fieldType: 'select',
      label: SALESPERSON_LABEL,
      name: 'salesperson',
      options: staff
      // placeholder: SALESPERSON_PLACEHOLDER
    }
  ];

  const clientInitials =
    (lead?.salesperson &&
      getInitials(lead?.salespersonData?.firstname, lead?.salespersonData?.lastname)) ||
    '';

  useEffect(() => {
    if (!salesperson && !fetchingEvent && lead && Object.keys(lead).length && type === 'lead') {
      openSalesPersonConfirm({
        title: 'Assign a Salesperson to this lead',
        confirmId: 'salespersonConfirm',
        confirmType: 'formModal',
        toUpdate: 'lead',
        fields,
        defaultValues: { salesperson: userId }
      });
    }
    return () => {};
  }, [salesperson, lead, fetchingEvent]);

  const stage = leadStatusOptions?.find(item => item.value === lead?.leadInfo?.status);
  const stepOptions = leadStatusOptions
    ?.filter(st => st.value !== 'lost')
    .map(stg => {
      const stageNumber = stg?.order;
      if (stage) {
        const isSelected = stageNumber <= stage?.order;
        return {
          order: stg.order,
          title: stg.displayName,
          value: stg.value,
          isSelected
        };
      }
      return {
        title: (fetchingEvent && '---') || 'Invalid Stage',
        value: 'invalid',
        isSelected: false,
        isDisabled: true
      };
    });
  const lastActivityDate = lead?.updated || lead?.created;

  const miniCardsTitle = [
    // TODO: #565 LeadDetails Minicards: Update "Stage" Minicard to be event type

    {
      title: 'Event Type',
      value: eventType || 'Pending',
      icon: <EventType />
    },
    {
      title: 'Last Activity Date',
      value: moment(lastActivityDate).format('L'),
      icon: <Activities />
    },
    {
      title: 'Salesperson',
      value: lead?.salespersonName || 'Not assigned',
      icon: (
        <Circle size="40px" bg="#E8E8E8" color="#A0A4A8">
          {clientInitials}
        </Circle>
      )
    }
  ];

  const handleTabChange = e => {
    setTabIndex(e);
  };

  let tabHeaders = [
    // { title: 'Lead Details' },
    { title: 'Overview' },
    { title: 'Notes' },
    { title: 'Activities' },
    { title: 'Documents' },
    { title: 'Tasks' }
    // { title: 'Duplicates' },
    // { title: 'Messages' }
    // { title: 'Products & Services', isDisabled: true, tooltip: ContactVenueX }
    // feat/calendar-appointments
    // { title: 'Appointments' }
    // { title: 'Additional Details' }
  ];

  if (!hasDuplicates)
    tabHeaders = tabHeaders.filter(tab => tab.title !== 'Duplicates' && tab.title !== 'Messages');

  const tabPanels = [
    // { component: <LeadOverview /> },
    {
      component: <ContractDetails handleTabChange={handleTabChange} fetchingEvent={fetchingEvent} />
    },
    {
      component: <Notes />
    },

    // TODO: #536 Create Activites Tab
    { component: <ActivitiesTab /> },
    { component: <Documents /> },
    { component: <EventTasksTab /> },
    // { component: <DuplicatesTab /> },
    // { component: <LeadMessages /> },
    // feat/calendar-appointments
    // { component: <AppointmentsTab isLead /> },
    {
      // TODO: #545 Build a table component that displays custom fields in lead details
      // component: <CustomFieldsTabsCore event={lead} />
    }
    // {
    //   component: <EventProductsTab />
    // }
  ];

  const stepClick = opt => {
    if (opt === 'eventBooked') return openEventManagementDrawer('book-event', 'edit', 0);
    const { id } = lead;
    const updateData = {
      leadInfo: { status: opt }
    };
    return updateLead({ eventId: id, data: updateData, closeAction: () => {} });
  };

  const controls = useAnimation();
  const handleExpand = async () => {
    if (!expanded) controls.start('expanded');
    if (expanded) controls.start('collapsed');
    setExpanded(!expanded);
  };

  const iconExpandedprops = {
    zIndex: 101
  };

  const iconStandardProps = {
    color: Colors.black11,
    cursor: 'pointer',
    onClick: handleExpand
  };

  const rightActionsTabs = [
    {
      component: (
        <Flex alignItems="center" width="25px" justifyContent="center">
          <Icon
            as={(!expanded && BsArrowsAngleExpand) || BsArrowsAngleContract}
            {...iconStandardProps}
            {...(expanded && iconExpandedprops)}
          />
        </Flex>
      )
    }
  ];

  return (
    <Drawer
      isOpen={isLeadsDetailsOpen}
      placement="right"
      onClose={closeLeadsDetails}
      size="full"
      closeOnEsc={true}
      trapFocus={false}
      variant="eventDetails"
    >
      <DrawerContent>
        <EventManagementDrawer />
        <Flex height="100%">
          <Flex
            flexDir="column"
            paddingTop="30px"
            paddingX="50px"
            paddingBottom="18px"
            flex={1}
            bg={'grayFA'}
            w="100%"
            minWidth={'1px'}
          >
            <Flex width="100%">
              <CardSteps options={stepOptions} handleClick={stepClick} />
            </Flex>
            <CardsSectionInfo
              cardsInfo={miniCardsTitle}
              mb="35px"
              mt="27px"
              isLoading={fetchingEvent}
            />
            <AnimatePresence>
              {(expanded && (
                <motion.div
                  style={{
                    position: 'absolute',
                    backgroundColor: '#fafafa',
                    zIndex: 100,
                    height: '100vh',
                    padding: '24px',
                    left: 0,
                    width: '100%'
                  }}
                  transition={{ duration: 0.2 }}
                  initial={{
                    bottom: 0,
                    transform: 'scale(0.75)',
                    transformOrigin: 'bottom left'
                  }}
                  animate={{ top: 0, transform: 'scale(1)' }}
                  exit={{
                    bottom: 0,
                    transform: 'scale(0.9)',
                    transformOrigin: 'bottom left',
                    transition: {
                      duration: 0.1,
                      opacity: 0
                    }
                  }}
                >
                  <Tabs
                    tabHeaders={tabHeaders}
                    tabPanels={tabPanels}
                    customRender
                    index={tabIndex}
                    onTabsChange={handleTabChange}
                    noPadding
                    rightActions={rightActionsTabs}
                    disableTabs={undefined}
                    bgColor={undefined}
                  />
                </motion.div>
              )) || <motion.div />}
            </AnimatePresence>
            {/* // TODO: #561 Expand arrows positioning */}
            {!expanded && (
              <div
                style={{
                  position: 'relative',
                  height: '100%',
                  minHeight: '1px',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Tabs
                  tabHeaders={tabHeaders}
                  tabPanels={tabPanels}
                  customRender
                  index={tabIndex}
                  onTabsChange={handleTabChange}
                  disableTabs={disableActions}
                  rightActions={rightActionsTabs}
                  bgColor={undefined}
                />
              </div>
            )}
          </Flex>
          <Flex minWidth={'1px'}>
            <DisplayInfo isLoading={fetchingEvent} />
          </Flex>
        </Flex>
      </DrawerContent>
    </Drawer>
  );
};
