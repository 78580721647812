import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Grid,
  GridItem,
  Icon,
  Text
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AiOutlineDownload } from 'react-icons/ai';
import Colors from 'src/styles/Colors';
import Close from 'src/styles/Icons/Close';
import { getDateDisplay } from 'src/utils/getDateDisplay';
import { useDocuments, useUser } from 'src/v2/Hooks/CollectionHooks';
import { useDrawers } from 'src/v2/Hooks/HelperHooks';
import {
  FormDrawerHeaderStyles,
  FormDrawerStyles,
  TitleStyles
} from 'src/v2/UIComponents/Drawers/FormDrawer/styles';
import { scrollBarProps } from 'src/v2/UIComponents/ScrollBar/scrollBarProps';
import { TextAreaWithLabel } from 'src/v2/UIComponents/FormComponents';
import { Button, IconButton, Tooltip } from 'src/v2/UIComponents/Primitives';
import { DocumentComment, DocumentVersion } from 'venuex-types';

export const DocumentDetailsDrawer = () => {
  const [comments, setComments] = useState<Array<DocumentComment>>([]);

  const {
    documentDetailsDrawerOpen,
    documentToView,
    closeDocumentDetailsDrawer,
    documentComments
  } = useDrawers();

  const { commentDocument, downloadDocument } = useDocuments();
  const { venueId } = useUser();
  useEffect(() => {
    setComments(documentComments);
  }, [documentComments, documentToView]);
  const methods = useForm({});
  if (!documentToView) return <div />;
  const { versions, eventId, documentId, label: documentLabel } = documentToView;

  const currentVersion: DocumentVersion = {
    ...documentToView
  };

  // const documentDate = documentCreated && getDateDisplay(new Date(documentCreated))?.longDate;

  const title = `${documentLabel} - Details`;

  const { reset } = methods;

  const onSubmit = data => {
    commentDocument({ eventId, documentId, content: data.comment });
    reset();
  };

  const handleDownload = ({ version, i }) => {
    const { filename, url } = version;
    const { documentId: docId } = documentToView;
    const fileType = filename.split('.').pop();
    const downloadName = `v${i}  ${documentLabel}.${fileType}`;
    downloadDocument({ eventId, docId, filename: downloadName, url, venueId });
  };

  const renderVersions = () =>
    versions?.map((version, i) => {
      const versionNumber = versions.length - i;

      const { description, userName, label: versionLabel } = version;
      const longDate = version.created && getDateDisplay(new Date(version.created))?.longDate;

      return (
        <AccordionItem key={i}>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left" fontSize="14px">
              {`Version ${versionNumber} - ${longDate}`}
            </Box>
            <Box display="flex">
              <IconButton
                aria-label="test"
                variant="ghost"
                icon={<Icon as={AiOutlineDownload} w={4} h={4} />}
                tooltipLabel="Download"
                onClick={() => handleDownload({ version, i: versionNumber })}
              />
            </Box>
            <Tooltip label="See Details" shouldWrapChildren>
              <AccordionIcon />
            </Tooltip>
          </AccordionButton>
          <AccordionPanel pb={4} display="flex" justifyContent={'space-between'}>
            <Box>
              <Box>
                <Text variant="contentSubTitle" as={'span'}>
                  Name -
                </Text>{' '}
                <Text fontSize="14px" as="span">
                  {(versionLabel && versionLabel) || ''}
                </Text>
              </Box>
              <Box>
                <Text variant={'contentSubTitle'} as="span">
                  Uploaded By -
                </Text>{' '}
                <Text fontSize="14px" as="span">
                  {userName}
                </Text>
              </Box>
              <Box>
                <Text variant="contentSubTitle" as={'span'}>
                  Description -
                </Text>{' '}
                <Text fontSize="14px" as="span">
                  {(description && description) || 'No Description'}
                </Text>
              </Box>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      );
    });

  const renderComments = () =>
    comments?.map((comment, i) => {
      const { shortDate, time } = (comment.created &&
        getDateDisplay(new Date(comment.created))) || {
        shortDate: false,
        time: false
      };
      const { content, userName } = comment;
      return (
        <Box as="span" flex="1" textAlign="left" key={`${comment}-${i}`}>
          <Box display="flex" fontSize="x-small" justifyContent={'space-between'}>
            <Box>{userName} </Box>
            <Box>
              {shortDate} {time}
            </Box>
          </Box>
          <Text variant="cardLabel" paddingLeft="16px" fontSize="14px">
            {content}
          </Text>

          <Divider mt="6px" />
        </Box>
      );
    });

  const commentInputProps = {
    label: 'Leave a comment',
    name: 'comment'
  };

  const CommentsSection = (
    <Box flex="1 1 auto" minH="0" display="flex" flexDirection="column">
      <Text variant={'contentTitle'}>Comments</Text>
      <Box
        display="flex"
        flexDirection="column"
        gap="12px"
        overflowY="hidden"
        minH="0px"
        border={`1px solid ${Colors.border}`}
        borderRadius="4px"
        marginTop="8px"
        paddingY={2}
      >
        <Box overflowY="scroll" sx={scrollBarProps} paddingRight="6px" paddingLeft="12px">
          {(comments?.length && renderComments()) || (
            <Text variant={'contentSubTitle'}>No Comments</Text>
          )}
        </Box>
      </Box>
    </Box>
  );

  const currentVersionRawDate = currentVersion.updated || currentVersion.created;
  const currentVersionLongDate =
    currentVersionRawDate && getDateDisplay(new Date(currentVersionRawDate))?.longDate;

  const CurrentVersion = (
    <AccordionItem>
      <AccordionButton>
        <Box as="span" flex="1" textAlign="left" fontSize="14px" color={Colors.green03 || ''}>
          {`Current Version - ${currentVersionLongDate}`}
        </Box>
        <Box display="flex">
          <IconButton
            aria-label="test"
            variant="ghost"
            icon={<Icon as={AiOutlineDownload} w={4} h={4} />}
            tooltipLabel="Download"
            onClick={() => handleDownload({ version: currentVersion, i: 1 })}
          />
        </Box>
        <Tooltip label="See Details" shouldWrapChildren>
          <AccordionIcon />
        </Tooltip>
      </AccordionButton>
      <AccordionPanel pb={4} display="flex" justifyContent={'space-between'}>
        <Box>
          <Box>
            <Text variant="contentSubTitle" as={'span'}>
              Name -
            </Text>{' '}
            <Text fontSize="14px" as="span">
              {(documentLabel && documentLabel) || ''}
            </Text>
          </Box>
          <Box>
            <Text variant={'contentSubTitle'} as="span">
              Uploaded By -
            </Text>{' '}
            <Text fontSize="14px" as="span">
              {currentVersion.userName}
            </Text>
          </Box>
          <Box>
            <Text variant="contentSubTitle" as={'span'}>
              Description -
            </Text>{' '}
            <Text fontSize="14px" as="span">
              {currentVersion.description || 'No Description'}
            </Text>
          </Box>
        </Box>
      </AccordionPanel>
    </AccordionItem>
  );
  const VersionSection = (
    <Box flex="1 1 auto" minH="0" display="flex" flexDirection="column">
      <Text variant={'contentTitle'}> Versions</Text>
      <Box
        display="flex"
        flexDirection="column"
        gap="12px"
        minH="0px"
        border={`1px solid ${Colors.border}`}
        borderRadius="4px"
        marginTop="8px"
        overflowY="hidden"
        paddingY={2}
      >
        <Box overflowY="scroll" sx={scrollBarProps} paddingRight="6px" paddingLeft="12px">
          <Accordion allowToggle>
            {CurrentVersion}
            {renderVersions()}
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
  const CommentInputSection = (
    <Box display="flex" flexDir={'column'}>
      <TextAreaWithLabel {...commentInputProps} />
      <Button type="submit" marginTop="18px" alignSelf={'end'}>
        Save Comment
      </Button>
    </Box>
  );
  const HeaderSection = (
    <DrawerHeader {...FormDrawerHeaderStyles} display="flex" justifyContent="space-between">
      <Text {...TitleStyles}>{title}</Text>
      <Center onClick={closeDocumentDetailsDrawer} cursor="pointer">
        <Close size="13" color={Colors.gray18} />
      </Center>
    </DrawerHeader>
  );
  return (
    <Drawer
      {...FormDrawerStyles}
      isOpen={documentDetailsDrawerOpen}
      onClose={closeDocumentDetailsDrawer}
      returnFocusOnClose={false}
      autoFocus={false}
      variant={'darkOverlay'}
    >
      <FormProvider {...methods}>
        <DrawerOverlay>
          <DrawerContent>
            {title && (HeaderSection || <div />)}
            <DrawerBody paddingX="40px" flex="1 1 auto">
              <Grid gridTemplateRows={'1fr auto'} minH="0" height="100%" gap="12px">
                <GridItem minH="0">
                  <Grid gridTemplateRows={'2fr 3fr'} minH="0" height="100%" gap="12px">
                    {VersionSection}
                    {CommentsSection}
                  </Grid>
                </GridItem>
                <GridItem>
                  <Box minH="1px">
                    <form onSubmit={methods?.handleSubmit(onSubmit)}>{CommentInputSection}</form>
                  </Box>{' '}
                </GridItem>
              </Grid>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </FormProvider>
    </Drawer>
  );
};
