import React from 'react';
import PropTypes from 'prop-types';

const Rings = props => (
  <svg
    width={props.width || props.size}
    height={props.height || props.size}
    {...props}
    viewBox="0 0 54 68"
    style={{ display: 'inline' }}
  >
    <path
      d="M39.65 29.261c-2.126-7.103-8.08-12.51-15.414-13.994l8.079-6.68L29.126 0H11.374L8.185 8.588l8.079 6.679C7.016 17.069 0 25.339 0 35.092c0 8.906 5.953 16.857 14.457 19.296 2.55 8.375 10.204 14.418 19.346 14.418C44.965 68.806 54 59.795 54 48.663c0-9.012-5.846-16.857-14.35-19.402zm6.165 19.402c0 6.573-5.421 11.98-12.012 11.98-4.252 0-8.079-2.226-10.205-5.619 8.504-1.484 15.095-8.164 16.477-16.539a11.876 11.876 0 0 1 5.74 10.178zM13.819 45.27c-3.508-2.12-5.634-5.937-5.634-10.178 0-6.573 5.421-11.98 12.012-11.98 4.252 0 8.079 2.227 10.205 5.62-8.504 1.483-15.095 8.163-16.583 16.538zM20.94 3.287l1.17 3.286h-3.934l1.17-3.286h1.594zm4.996 6.467l-5.74 4.665-5.74-4.665h11.48zm2.126-3.18h-2.657l-1.17-3.287h2.551l1.276 3.286zM16.157 3.286l-1.169 3.286h-2.657L13.5 3.287h2.657zM3.19 35.092c0-9.33 7.654-16.963 17.008-16.963s17.008 7.634 17.008 16.963c0 8.588-6.485 15.797-14.988 16.857-.213-.636-.32-1.166-.426-1.802 7.548-.848 13.5-7.315 13.5-15.055 0-1.908-.319-3.71-1.063-5.407 0-.106-.106-.212-.106-.318-2.232-5.513-7.76-9.435-14.031-9.435-8.398 0-15.201 6.785-15.201 15.16 0 5.831 3.295 11.132 8.504 13.57 0 .743 0 1.38.106 2.121-6.165-2.65-10.311-8.8-10.311-15.69zM21.898 46.86c.744-5.194 4.89-9.33 10.098-10.071-.744 5.195-4.783 9.33-10.098 10.071zm11.905 18.766c-9.354 0-17.008-7.634-17.008-16.963 0-8.694 6.485-15.903 14.988-16.857.213.636.32 1.166.426 1.802-7.654.848-13.607 7.21-13.607 15.055 0 8.375 6.804 15.16 15.201 15.16 8.398 0 15.2-6.785 15.2-15.16 0-5.831-3.294-11.132-8.503-13.677 0-.742 0-1.378-.106-2.12 6.271 2.544 10.523 8.694 10.523 15.69-.106 9.436-7.76 17.07-17.114 17.07z"
      fillRule="nonzero"
      fill={props.color ? props.color : 'var(--colors-primary)'}
    />
  </svg>
);

Rings.propTypes = {
  size: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  theme: PropTypes.object
};

Rings.defaultProps = {
  size: 24
};

export default Rings;
