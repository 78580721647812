const getQueryParams = url => {
  const newUrl = url.replace('?', '').split('&');
  const urlObj = {};
  newUrl.map(uri => {
    const uriKeyValues = uri.split('=');
    // eslint-disable-next-line prefer-destructuring
    urlObj[uriKeyValues[0]] = uriKeyValues[1];

    return uri;
  });
  return urlObj;
};

const titleCase = str => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

const capitalizeFirstLetter = str => {
  return str && str.charAt(0).toUpperCase() + str.substring(1);
};

const dataURItoBlob = dataURI => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }
  // separate out the mime component
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];
  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
};

const setTableNumber = (tables, tableNumber = 1) => {
  const numbers = tables.map(table => table.index);
  // const number = numbers.includes(tableNumber)
  // ? setTableNumber(tables, tableNumber + 1)
  // : tableNumber;

  return numbers.includes(tableNumber) ? setTableNumber(tables, tableNumber + 1) : tableNumber;
};

const setShapeNumber = (shapes, shapeNumber = 1) => {
  const numbers = shapes.map(shape => shape.number);
  const number = numbers.includes(shapeNumber)
    ? setShapeNumber(shapes, shapeNumber + 1)
    : shapeNumber;

  return number;
};
const getInitials = (firstName, lastName) => {
  const firsLetter = firstName?.substring(0, 1) || '';
  const secondLetter = lastName?.substring(0, 1) || '';

  return firsLetter + secondLetter;
};

exports.titleCase = titleCase;
exports.dataURItoBlob = dataURItoBlob;
exports.getQueryParams = getQueryParams;
exports.capitalizeFirstLetter = capitalizeFirstLetter;
exports.setTableNumber = setTableNumber;
exports.getInitials = getInitials;
exports.setTableNumber = setTableNumber;
exports.setShapeNumber = setShapeNumber;
