/* eslint-disable react/prop-types */

import React, { useReducer, createContext, useContext } from 'react';
import _orderBy from 'lodash/orderBy';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _isArray from 'lodash/isArray';
import { SortDirectionType } from 'react-virtualized';
import { dateFilterHelper } from './helpers';
import { getFilteredData } from './getFilteredData';
import { FilterDataParams, TableAction, TableContextType, TableState } from './Types';

const defaultTableContextValue = {
  tableData: [],
  rowCount: 0,
  sortBy: undefined,
  sortDirection: undefined,
  sortData: (srtBy: any, srtDirection: any) => {},
  getData: <T,>(data: T[], tableId: string, preserveFilters?: boolean) => {},
  resetTable: (tableId: string) => {},
  filterData: (filters: FilterDataParams) => {},
  allData: [],
  clearFilter: (filterKey: string) => {},
  removeFilters: () => {},
  // searchData: () => {},
  filters: [],
  clearContextData: () => {},
  contextTableId: ''
};

const TableContext = createContext<TableContextType<any>>(defaultTableContextValue);

export const ACTIONS = {
  FILTER_DATA: 'FILTER_DATA',
  CLEAR_FILTER: 'CLEAR_FILTER',
  SORT_DATA: 'SORT_DATA',
  GET_DATA: 'GET_DATA',
  SEARCH_DATA: 'SEARCH_DATA',
  CLEAR_CONTEXT_DATA: 'CLEAR_CONTEXT_DATA',
  RESET_TABLE: 'RESET_TABLE',
  REMOVE_FILTERS: 'REMOVE_FILTERS'
} as const;

const reducer = <T,>(state: TableState<T>, action: TableAction<T>): TableState<T> => {
  switch (action.type) {
    case ACTIONS.FILTER_DATA:
      return {
        ...state,
        tableData: action.payload.tableData || [],
        rowCount: action.payload.rowCount || 0,
        filters: action.payload.filters
      };
    case ACTIONS.RESET_TABLE:
      return {
        ...state,
        tableId: action.payload.tableId,
        tableData: [],
        allData: [],
        rowCount: 0,
        sortBy: undefined,
        sortDirection: undefined,
        filters: [],
        contextTableId: action.payload.tableId
      };
    case ACTIONS.CLEAR_FILTER:
      return {
        ...state,
        tableData: action.payload.tableData || [],
        rowCount: action.payload.rowCount || 0,
        filters: action.payload.filters
      };
    case ACTIONS.SORT_DATA:
      return {
        ...state,
        tableData: action.payload.tableData || [],
        rowCount: action.payload.rowCount || 0,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection
      };
    case ACTIONS.GET_DATA: {
      return {
        ...state,
        tableData: action.payload.tableData || [],
        allData: action.payload.allData || [],
        rowCount: action.payload.rowCount || 0,
        filters: action.payload.filters || state.filters,
        contextTableId: action.payload.tableId
      };
    }
    case ACTIONS.SEARCH_DATA:
      return {
        ...state,
        tableData: action.payload.tableData || [],
        rowCount: action.payload.rowCount || 0
      };
    case ACTIONS.CLEAR_CONTEXT_DATA:
      return {
        ...state,
        tableData: [],
        allData: [],
        rowCount: 0,
        sortBy: undefined,
        sortDirection: undefined,
        filters: []
      };

    case ACTIONS.REMOVE_FILTERS:
      return {
        ...state,
        tableData: state.allData,
        filters: [],
        rowCount: state?.allData?.length || 0
      };
    default:
      return state;
  }
};

const initialState: TableState<Record<string, any>> = {
  tableData: [],
  allData: [],
  rowCount: 0,
  sortBy: undefined,
  sortDirection: undefined,
  filters: [],
  tableId: ''
};

const TableProvider = <T extends Record<string, any>>({
  children
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { tableData, rowCount, sortBy, sortDirection, allData, filters, contextTableId } = state;

  const removeFilters = () => {
    dispatch({
      type: ACTIONS.REMOVE_FILTERS
    });
  };

  const sortData = (srtBy: string, srtDirection?: SortDirectionType) => {
    const sortedData = _orderBy(tableData, srtBy, srtDirection?.toLowerCase() as 'asc' | 'desc');
    dispatch({
      type: ACTIONS.SORT_DATA,
      payload: {
        tableData: sortedData,
        rowCount: sortedData.length || 0,
        sortBy: srtBy,
        sortDirection: srtDirection
      }
    });
  };
  const filterData = ({ filterBy, filterKey, exclude, filterId, filterType }: FilterDataParams) => {
    const { filteredData, updatedFilters } = getFilteredData({
      filterBy,
      filterKey,
      exclude,
      filterId,
      filterType,
      allData: state.allData,
      filters
    });
    dispatch({
      type: ACTIONS.FILTER_DATA,
      payload: {
        tableData: filteredData,
        rowCount: filteredData.length,
        filters: updatedFilters
      }
    });
  };
  const clearFilter = filterKey => {
    const newFilters = _filter(filters, row => row.filterKey !== filterKey) || [];

    if (!newFilters.length) {
      // Reset tableData to allData when there are no filters left
      dispatch({
        type: ACTIONS.CLEAR_FILTER,
        payload: {
          tableData: allData,
          rowCount: allData.length,
          filters: newFilters || []
        }
      });
    } else {
      const filteredData = _filter(allData, (row: T) => {
        const filterValidation = newFilters.map(filter => {
          const { filterKey: aFilterKey, filterType: fltrType } = filter;
          const { filterBy } = filter;

          if (_isArray(row[aFilterKey])) {
            // Ensure filterBy is treated as an array
            const filterByArray = Array.isArray(filterBy) ? filterBy : [filterBy];
            return filterByArray.some(i => _includes(row[aFilterKey], i));
          }

          if (fltrType === 'dateRange') {
            return dateFilterHelper(filterBy, row[filterKey]);
          }

          return _includes(filterBy, row[filterKey]);
        });

        return filterValidation.every(val => val === true);
      });

      dispatch({
        type: ACTIONS.CLEAR_FILTER,
        payload: {
          tableData: filteredData,
          rowCount: filteredData.length || 0,
          filters: newFilters || []
        }
      });
    }
  };
  const getData = (data: T[], tableId: string, preserveFilters = false) => {
    if (preserveFilters && filters && filters.length > 0) {
      // Apply existing filters one by one to the new data
      let filteredData = data;
      let updatedFilters = [...filters];

      filters.forEach(filter => {
        const result = getFilteredData({
          filterBy: filter.filterBy,
          filterKey: filter.filterKey,
          filterType: filter.filterType,
          allData: filteredData,
          filters: updatedFilters
        });
        filteredData = result.filteredData as T[];
        updatedFilters = result.updatedFilters;
      });
      dispatch({
        type: ACTIONS.GET_DATA,
        payload: {
          tableId,
          tableData: filteredData,
          allData: data,
          rowCount: filteredData.length,
          filters: updatedFilters
        }
      });
    } else {
      dispatch({
        type: ACTIONS.GET_DATA,
        payload: {
          tableId,
          tableData: data || [],
          allData: data,
          rowCount: data?.length || 0,
          filters: []
        }
      });
    }
  };

  const resetTable = (tableId: string) => {
    dispatch({
      type: ACTIONS.RESET_TABLE,
      payload: {
        tableId,
        tableData: []
      }
    });
  };

  const clearContextData = () => {
    dispatch({
      type: ACTIONS.CLEAR_CONTEXT_DATA
    });
  };

  return (
    <TableContext.Provider
      value={{
        tableData,
        rowCount,
        sortBy,
        sortDirection,
        sortData,
        getData,
        resetTable,
        filterData,
        allData,
        clearFilter,
        removeFilters,
        // searchData,
        filters,
        clearContextData,
        contextTableId
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

const useTableContext = <T,>() => useContext<TableContextType<T>>(TableContext);
export { TableProvider, useTableContext };
