import {
  GET_LAYOUTSREDUCER_REQUEST,
  GET_LAYOUTSREDUCER_FAILURE,
  GET_LAYOUTSREDUCER_SUCCESS,
  ADD_LAYOUT_REQUEST,
  ADD_LAYOUT_FAILURE,
  ADD_LAYOUT_SUCCESS,
  UPDATE_LAYOUT_REQUEST,
  UPDATE_LAYOUT_FAILURE,
  UPDATE_LAYOUT_SUCCESS,
  DELETE_LAYOUT_REQUEST,
  DELETE_LAYOUT_FAILURE,
  DELETE_LAYOUT_SUCCESS,
  UPLOAD_LAYOUT_REQUEST,
  UPLOAD_LAYOUT_FAILURE,
  UPLOAD_LAYOUT_SUCCESS,
  SET_LAYOUT_FOR_EDIT,
  CLEAR_LAYOUT_FOR_EDIT,
  SET_ROOM_SELECTED,
  CLEAR_SELECTED_ROOM,
  GET_LAYOUT_BY_ID_REQUEST,
  GET_LAYOUT_BY_ID_SUCCESS,
  GET_LAYOUT_BY_ID_FAILURE,
  GET_LAYOUT_OPTIONS,
  DUPLICATE_LAYOUT_SUCCESS,
  SET_SEARCH_TERM
} from './types';

const initialState = {
  isFetching: false,
  layouts: [],
  error: null,
  uploadedUrl: null,
  layoutSelected: null,
  layoutUpdated: null,
  roomSelected: null,
  lastUpdateDate: null,
  searchTerm: ''
};

export const layoutsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LAYOUTSREDUCER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case GET_LAYOUTSREDUCER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case GET_LAYOUTSREDUCER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        layouts: action.layouts
      };
    case ADD_LAYOUT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case ADD_LAYOUT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        uploadedUrl: null
      };
    case ADD_LAYOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        uploadedUrl: null
      };
    case UPDATE_LAYOUT_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case UPDATE_LAYOUT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case UPDATE_LAYOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        layoutSelected: action.layout,
        lastUpdateDate: new Date()
      };
    case UPLOAD_LAYOUT_REQUEST:
      return {
        ...state,
        isFetching: true,
        uploadedUrl: null
      };
    case UPLOAD_LAYOUT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case UPLOAD_LAYOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        uploadedUrl: action.url
      };
    case DELETE_LAYOUT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case DELETE_LAYOUT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case DELETE_LAYOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null
      };
    case SET_LAYOUT_FOR_EDIT:
      return {
        ...state,
        layoutSelected: action.layout
      };
    case CLEAR_LAYOUT_FOR_EDIT:
      return {
        ...state,
        layoutSelected: null,
        lastUpdateDate: null
      };
    case SET_ROOM_SELECTED:
      return {
        ...state,
        roomSelected: action.room
      };
    case CLEAR_SELECTED_ROOM:
      return {
        ...state,
        roomSelected: null
      };
    case GET_LAYOUT_BY_ID_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
        layoutSelected: null
      };
    case GET_LAYOUT_BY_ID_SUCCESS:
      return {
        ...state,
        layoutSelected: action.layout,
        isFetching: false,
        error: null
      };
    case GET_LAYOUT_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        layoutSelected: null
      };
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm
      };

    case GET_LAYOUT_OPTIONS.REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case GET_LAYOUT_OPTIONS.SUCCESS:
      return {
        isFetching: false,
        layouts: action.layouts
      };
    case GET_LAYOUT_OPTIONS.FAILURE:
      return {
        isFetching: false
      };

    default:
      return state;
  }
};
