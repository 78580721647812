import styled from 'styled-components';
import Colors from '../../styles/Colors';
import HexToRGB from '../../styles/HexToRGB';

const AlertStyles = styled.div`
  color: ${props =>
    props.color || (props.type === 'error' && Colors.redC0) || `var(--colors-primary)`};
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 2px 8px 0 rgba(${HexToRGB(Colors.black, true)}, 0.3);
  font-size: 18px;
  font-weight: 600;
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
  line-height: 50px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 99999;
  display: flex;
  flex-wrap: wrap;
  padding: 2px 50px;
  align-items: center;
  justify-content: center;
`;

export default AlertStyles;
