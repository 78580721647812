import { InputGroup, InputRightElement } from '@chakra-ui/react';
import React, { FC, forwardRef } from 'react';
import { StyledInput, InputStyles } from './styles';
import { FormFieldProps } from './types';

const RootFormField: FC<FormFieldProps> = forwardRef<HTMLInputElement, FormFieldProps>(
  (
    { startElement, name, step, fieldValue, type = 'text', placeholder, endElement, ...rest },
    ref
  ) => {
    return (
      <InputGroup>
        <StyledInput
          id={name}
          ref={ref}
          name={name}
          type={type}
          placeholder={placeholder}
          step={step}
          endElement={endElement}
          {...InputStyles}
          {...rest}
        />
        {endElement && <InputRightElement> {endElement} </InputRightElement>}
      </InputGroup>
    );
  }
);
RootFormField.displayName = 'RootFormField';

const checkProps = (prevProps, nextProps) => {
  if (prevProps.name === 'start') console.log(prevProps);
  return prevProps.fieldValue === nextProps.fieldValue;
};

export const FormField = React.memo(RootFormField, checkProps);
