import { useState, useEffect, ReactNode } from 'react';
import PropTypes from 'prop-types';
import Notifications from 'src/v2/Notifications';
import { ModalLoader } from 'src/components/ModalLoader';
import { useLoader, useSideBar } from 'src/v2/Hooks/HelperHooks';
import { ConfirmModal } from 'src/v2/UIComponents/Alert';
import { Toaster } from 'react-hot-toast';
import OverlayLoader from 'src/styles/OverlayLoader';
import { useUser } from 'src/v2/Hooks/CollectionHooks';
import LogRocket from 'logrocket';
import App from './App/index';
import { AppBody } from './AppBody';
import { ToolbarProps } from '../Toolbar';

export interface AppContainerProps {
  children: ReactNode;
  toolbarProps?: ToolbarProps;
  isCalendar?: boolean;
  showBorder?: boolean;
  showLoader?: boolean;
  withPadding?: boolean;
}

const AppContainer = ({
  children,
  toolbarProps,
  isCalendar,
  showBorder = true,
  showLoader = false,
  withPadding,
  ...props
}: AppContainerProps) => {
  const { email, firstname, lastname, userId, role, venueId } = useUser();
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    LogRocket.identify(userId, {
      name: `${firstname} ${lastname}`,
      email: email || 'not found',
      role: role || 'no role',
      venueId
    });
  }, [email, firstname, role, venueId, userId, lastname]);

  const { sidebarHidden, hideSidebar } = useSideBar();
  const { modalLoaderState, overlayLoaderOpen } = useLoader();

  const showOverlayLoader = overlayLoaderOpen || showLoader;

  return (
    <App
      show={show}
      hideSidebar={sidebarHidden}
      setHideSidebar={hideSidebar}
      isCalendar={isCalendar}
    >
      <Notifications />
      <AppBody
        toolbarProps={toolbarProps}
        isCalendar={isCalendar}
        showBorder={showBorder}
        withPadding={withPadding}
      >
        {children}
      </AppBody>
      <ModalLoader isOpen={modalLoaderState.isOpen} modalLoaderState={modalLoaderState} />
      {/* TODO: fix general glass loader */}
      {/* {glassLoaderVisible && <FullGlassLoader />} */}
      <ConfirmModal />
      {showOverlayLoader && <OverlayLoader />}
      <Toaster toastOptions={{ style: { color: '#181818' } }} />
    </App>
  );
};

AppContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setHideSidebar: PropTypes.func,
  modalLoaderState: PropTypes.object
};

export default AppContainer;
