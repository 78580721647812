import {
  OPEN_DOCUMENT_LIBRARY_UPLOAD,
  CLOSE_DOCUMENT_LIBRARY_UPLOAD,
  DOCUMENT_LIBRARY_UPLOAD_REQUEST,
  DOCUMENT_LIBRARY_UPLOAD_SUCCESS,
  DOCUMENT_LIBRARY_UPLOAD_FAILURE,
  DOCUMENT_LIBRARY_UPLOAD_SUCCESS_MESSAGE,
  DOCUMENT_LIBRARY_HIDE_NOTIFICATIONS,
  DOCUMENT_LIBRARY_REQUEST,
  DOCUMENT_LIBRARY_FAILURE,
  DOCUMENT_LIBRARY_SUCCESS,
  DELETE_DOCUMENT_FROM_LIBRARY_REQUEST,
  DELETE_DOCUMENT_FROM_LIBRARY_FAILURE,
  DELETE_DOCUMENT_FROM_LIBRARY_SUCCESS,
  GET_DOCUMENT_LIBRARY_REQUEST,
  GET_DOCUMENT_LIBRARY_SUCCESS,
  GET_DOCUMENT_LIBRARY_FAILURE,
  CLEAR_DOCUMENT_LIBRARY_SELECTED,
  DOCUMENT_LIBRARY_UPDATE_REQUEST,
  DOCUMENT_LIBRARY_UPDATE_SUCCESS,
  DOCUMENT_LIBRARY_UPDATE_FAILURE,
  DOWNLOAD_DOCUMENT_LIBRARY_REQUEST
} from './types';

export const openDocLibraryUpload = () => ({
  type: OPEN_DOCUMENT_LIBRARY_UPLOAD
});

export const closeDocLibraryUpload = () => ({
  type: CLOSE_DOCUMENT_LIBRARY_UPLOAD
});

export type DocLibraryUploadRequest = (input: { formData: object }) => void;
export const docLibraryUploadRequest: DocLibraryUploadRequest = ({ formData }) => ({
  type: DOCUMENT_LIBRARY_UPLOAD_REQUEST,
  formData
});

export const docLibraryUploadSuccess = () => ({
  type: DOCUMENT_LIBRARY_UPLOAD_SUCCESS,
  successMessage: DOCUMENT_LIBRARY_UPLOAD_SUCCESS_MESSAGE
});

export const docLibraryUploadFailure = error => ({
  type: DOCUMENT_LIBRARY_UPLOAD_FAILURE,
  errorMessage: error
});

export const hideDocLibraryNotification = () => ({
  type: DOCUMENT_LIBRARY_HIDE_NOTIFICATIONS
});

export const getDocumentLibraryRequest = () => ({
  type: DOCUMENT_LIBRARY_REQUEST
});

export const getDocumentLibrarySuccess = ({ documentLibrary }) => ({
  type: DOCUMENT_LIBRARY_SUCCESS,
  documentLibrary
});

export const getDocumentLibraryFailure = error => ({
  type: DOCUMENT_LIBRARY_FAILURE,
  error
});

export const deleteDocumentFromLibraryRequest = ({ documentId }) => ({
  type: DELETE_DOCUMENT_FROM_LIBRARY_REQUEST,
  documentId
});

export const deleteDocumentFromLibrarySuccess = () => ({
  type: DELETE_DOCUMENT_FROM_LIBRARY_SUCCESS
});

export const deleteDocumentFromLibraryFailure = () => ({
  type: DELETE_DOCUMENT_FROM_LIBRARY_FAILURE
});

export const getDocumentLibraryByIdRequest = ({ documentId }) => ({
  type: GET_DOCUMENT_LIBRARY_REQUEST,
  documentId
});

export const getDocumentLibraryByIdSuccess = ({ document }) => ({
  type: GET_DOCUMENT_LIBRARY_SUCCESS,
  document
});

export const getDocumentLibraryByIdFailure = ({ error }) => ({
  type: GET_DOCUMENT_LIBRARY_FAILURE,
  error
});

export const clearDocumentLibrarySelected = () => ({
  type: CLEAR_DOCUMENT_LIBRARY_SELECTED
});

export const docLibraryUpdateRequest = ({ formData, documentId }) => ({
  type: DOCUMENT_LIBRARY_UPDATE_REQUEST,
  formData,
  documentId
});

export const docLibraryUpdateSuccess = ({ document }) => ({
  type: DOCUMENT_LIBRARY_UPDATE_SUCCESS,
  document
});

export const docLibraryUpdateFailure = error => ({
  type: DOCUMENT_LIBRARY_UPDATE_FAILURE,
  error
});

export const downloadDocumentLibraryRequest = ({ documentId, filename, fileExtension }) => ({
  type: DOWNLOAD_DOCUMENT_LIBRARY_REQUEST,
  documentId,
  filename,
  fileExtension
});
