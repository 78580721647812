import { useState } from 'react';
import moment from 'moment';
import { Flex, Text, Icon, Box } from '@chakra-ui/react';
import { MdEmail } from 'react-icons/md';
import { FiSend } from 'react-icons/fi';
import { Button } from 'src/v2/UIComponents/Primitives/Button';
import { useEvents } from 'src/v2/Hooks/CollectionHooks';
import {
  DOWNLOADING_EVENT_PACKET_HEADER,
  DOWNLOADING_EVENT_PACKET_MESSAGE,
  STILL_WORKING
} from 'src/components/ModalLoader/types';
import { Calendar } from 'src/v2/UIComponents/Primitives/Icons';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Tooltip,
  Skeleton
} from 'src/v2/UIComponents/Primitives';
import styled from 'styled-components';
import { TextPairing } from 'src/v2/Drawers/EventDetails/Overview/components/TextPairing';
import { useConfirm } from 'src/v2/Hooks/HelperHooks';
import { useDrawers } from '../../../../../Hooks/HelperHooks/DrawerHooks/useDrawers';

const getTimeZoneAbbreviation = () => {
  const date = new Date();
  const dateString = date.toLocaleDateString(undefined, { timeZoneName: 'short' });

  // Extract the time zone abbreviation from the formatted string
  const matches = dateString.match(/\b([A-Z]+)\b/g);
  return matches ? matches[matches.length - 1] : 'UTC';
};

const CardWrapper = styled(Card)`
  height: 100%;
  @media (max-width: 1440px) {
    height: 100%;
  }
`;

export const DetailsCard = ({ fetchingEvent }) => {
  const [emailToReSend, setEmailToReSend] = useState('');
  const { activeEvent: event } = useEvents();
  const { formValues, start, end, roomData, users } = event;
  const {
    downloadEventPacket,
    updateModalLoaderMessage,
    closeModalLoader,
    openModalLoader,
    resendWelcomeEmail
  } = useEvents();
  const { openEventManagementDrawer } = useDrawers();
  const startTime = start && moment(start).format('LT');
  const endTime = end && moment(end).format('LT');

  const handleResendEmail = () => {
    resendWelcomeEmail(emailToReSend);
    setEmailToReSend('');
  };

  const { openConfirm } = useConfirm({
    acceptAction: handleResendEmail,
    confirmHookId: 'resendEmail'
  });
  const handleResendEmailClick = email => {
    setEmailToReSend(email);
    openConfirm({
      title: 'Re-send Email',
      description: 'Are you sure you want to resend this email?',
      confirmId: 'resendEmail'
    });
  };

  const handleEditClick = () => openEventManagementDrawer('event', 'edit', 1);

  const handleDownloadEventPacketClick = () => {
    downloadEventPacket({
      eventId: event.id,
      updateModalLoaderMessage,
      closeModalLoader
    });
    openModalLoader({
      showProgressBar: true,
      modalLoaderMessage: DOWNLOADING_EVENT_PACKET_MESSAGE(event.title),
      modalLoaderHeader: DOWNLOADING_EVENT_PACKET_HEADER
    });
    const update = () =>
      updateModalLoaderMessage({
        showProgressBar: true,
        modalLoaderMessage: STILL_WORKING
      });
    setTimeout(() => update(), 3500);
  };

  return (
    <CardWrapper>
      <CardHeader
        iconTitle={<Calendar />}
        title="Event Details"
        btnLabel="Edit"
        btnAction={handleEditClick}
        rightActions={undefined}
        btnIcon={undefined}
      />
      <CardBody>
        <Box mb="12px">
          <TextPairing
            fetchingData={fetchingEvent}
            title="Client Name"
            subtitle={event.clientName}
            missingMessage={undefined}
            titleColor={undefined}
          />
        </Box>
        <Box mb="12px">
          <TextPairing
            fetchingData={fetchingEvent}
            title="Event Name"
            subtitle={event.title}
            missingMessage={undefined}
            titleColor={undefined}
          />
        </Box>
        <Box mb="12px">
          <TextPairing
            fetchingData={fetchingEvent}
            title="Room"
            subtitle={roomData?.name}
            missingMessage={undefined}
            titleColor={undefined}
          />
        </Box>
        <Box mb="12px">
          {/* <TextPairing
            fetchingData={fetchingEvent}
            title="Status"
            subtitle={event?.leadInfo?.}
            missingMessage={undefined}
            titleColor={undefined}
          /> */}
        </Box>
        <Box mb="12px">
          <TextPairing
            fetchingData={fetchingEvent}
            title="Guest Count"
            subtitle={event?.minimumGuests}
            missingMessage={undefined}
            titleColor={undefined}
          />
        </Box>
        <Box mb="12px">
          <TextPairing
            fetchingData={fetchingEvent}
            title="Start Time & End Time"
            subtitle={`${startTime} ${getTimeZoneAbbreviation()}  - ${endTime} ${getTimeZoneAbbreviation()}`}
            missingMessage={undefined}
            titleColor={undefined}
          />
        </Box>
        <Flex flexDir="column">
          <Text
            fontFamily="Lato"
            fontWeight={700}
            fontSize="14px"
            lineHeight="16px"
            color="#A0A4A8"
            mb="6px"
          >
            Users
          </Text>
          {fetchingEvent && (
            <Flex>
              <Skeleton width="160px" />
            </Flex>
          )}
          {!fetchingEvent &&
            users?.map((user, key) => {
              const userName = `${user?.firstname} ${user?.lastname}`;
              const email = user?.email;
              return (
                <Box key={key} mb="20px">
                  <Text
                    fontFamily="Lato"
                    fontWeight={400}
                    fontSize="14px"
                    lineHeight="12px"
                    mb="6px"
                  >
                    {userName}
                  </Text>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Flex>
                      <Icon as={MdEmail} w={5} h={5} color="var(--colors-primary)" />
                      <Text
                        key={key}
                        fontFamily="Lato"
                        fontWeight={400}
                        fontSize="14px"
                        lineHeight="20px"
                        ml="13px"
                      >
                        {email}
                      </Text>
                    </Flex>
                    <Tooltip
                      label="Resend Welcome Email"
                      aria-label="Resend Welcome Email"
                      placement="left"
                    >
                      <Box display="inline">
                        <Icon
                          as={FiSend}
                          w={5}
                          h={5}
                          color="var(--colors-primary)"
                          cursor="pointer"
                          onClick={() => handleResendEmailClick(email)}
                        />
                      </Box>
                    </Tooltip>
                  </Flex>
                </Box>
              );
            })}
        </Flex>
      </CardBody>
      <CardFooter>
        <Button
          variant="primary"
          size="md"
          width="274px"
          onClick={handleDownloadEventPacketClick}
          isLoading={fetchingEvent}
        >
          Download Event Packet
        </Button>
      </CardFooter>
    </CardWrapper>
  );
};
