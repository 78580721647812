import { Flex, Icon, Divider, Grid, GridItem } from '@chakra-ui/react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import Colors from 'src/styles/Colors';
import { Card, Text } from '../Primitives';

const Step = ({ title, icon, isSelected }) => (
  <Flex role="group" flexDir="column" alignItems="center">
    {isSelected ? (
      <Icon
        as={BsCheckCircleFill}
        w={6}
        h={6}
        color="var(--colors-primary)"
        _groupHover={{
          color: 'var(--colors-primary-light)'
        }}
      />
    ) : (
      <Icon
        as={RiCheckboxBlankCircleFill}
        w={6}
        h={6}
        color={Colors.grayED}
        _groupHover={{
          color: 'var(--colors-primary-light)'
        }}
      />
    )}

    <Text
      _groupHover={{
        color: 'var(--colors-primary-light)'
      }}
      mt="10px"
      fontWeight={700}
      fontSize="14px"
      lineHeight="17px"
      color={isSelected ? '#000000)' : '#C5C5C5'}
      textAlign="center"
    >
      {title}
    </Text>
  </Flex>
);

export const CardSteps = ({ options, handleClick }) => {
  const totalOptions = options?.length;
  const columnCount = totalOptions * 2 - 1;
  return (
    <Card paddingY="20px" paddingX="40px" height="160px" width="100%">
      <Text fontWeight={700} fontSize="14px" lineHeight="20px" mb="20px">
        Lead Stage
      </Text>
      <Grid templateColumns={`repeat(${columnCount}, 1fr)`} flex={1}>
        {options
          ?.sort((a, b) => a.order - b.order)
          .map((option, key) => {
            const nexOption = options[key + 1];
            const isNextOptionSelected = nexOption && nexOption.isSelected;

            return (
              <>
                <GridItem h="68px" cursor="pointer" onClick={() => handleClick(option.value)}>
                  <Step title={option.title} isSelected={option.isSelected} />
                </GridItem>
                {!(key === totalOptions - 1) && (
                  <GridItem>
                    <Divider
                      borderColor={isNextOptionSelected ? 'var(--colors-primary)' : '#C5C5C5'}
                      borderWidth="4px"
                      mt="7px"
                      opacity={1}
                    />
                  </GridItem>
                )}
              </>
            );
          })}
      </Grid>
    </Card>
  );
};
