import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export function reset({ search }) {
  return axios({
    method: 'get',
    url: `${apiUri}/auth/reset/${search.token}/${search.uid}`
  });
}

export function resetPassword({ token, uid, venueId, eventId, password, confirm }) {
  return axios({
    method: 'post',
    url: `${apiUri}/auth/reset/password`,
    data: { token, uid, venueId, eventId, password, confirm }
  });
}
