import firebase from '../../firebase';

const storageRef = firebase.storage().ref();

export const uploadFile = (file, cloudPath) => {
  const ref = storageRef.child(cloudPath);
  const uploadTask = ref.put(file);

  return new Promise((resolve, reject) => {
    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      error => {
        reject(new Error(error.message));
      },
      () => {
        const { metadata } = uploadTask.snapshot;
        resolve({ success: true, fullPath: metadata.fullPath, bucket: metadata.bucket });
      }
    );
  });
};
