import { takeLatest, put, call } from 'redux-saga/effects';
import { VENUEVALUES_REQUEST, VENUEVALUES_FAILURE, VENUEVALUES_SUCCESS } from './types';

import { getStyles } from './actions';

function* workerVenueValues({ venueId }) {
  try {
    const response = yield call(getStyles, { venueId });

    const { venue } = response.data;

    yield put({
      type: VENUEVALUES_SUCCESS,
      venue
    });
  } catch (error) {
    yield put({ type: VENUEVALUES_FAILURE, ...error.response });
  }
}

// EXPORTS
export function* watcherVenueValues() {
  yield takeLatest(VENUEVALUES_REQUEST, workerVenueValues);
}
