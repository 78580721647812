import React from 'react';
import { Table } from 'src/v2/Table';
import { TableProvider } from 'src/v2/context/Tables';

export const CustomFieldsTabPageTable = ({ data, groupKey, rowClickAction }) => {
  const columns = [
    {
      label: 'Label',
      size: 'lg',
      align: 'left',
      dataKey: 'name'
    },
    {
      label: 'Description',
      size: 'lg',
      align: 'left',
      dataKey: 'fieldDescription'
    },
    {
      label: 'Placeholder',
      size: 'lg',
      align: 'left',
      dataKey: 'placeholder'
    },
    {
      label: 'Type',
      size: 'lg',
      align: 'left',
      dataKey: 'fieldType'
    },
    {
      label: 'Required Type',
      size: 'lg',
      align: 'left',
      cellDataGetter: ({ rowData }) => ({ fieldRequired: rowData.fieldRequired }),
      cellRenderer: ({ cellData }) => {
        const requiredLabel =
          (cellData.fieldRequired === 'hostRequired' && 'Host Required') ||
          (cellData.fieldRequired === 'onCreate' && 'On Event Creation') ||
          (cellData.fieldRequired === 'notRequired' && 'Not Required') ||
          (cellData.fieldRequired === 'venueRequired' && 'Venue Required');

        return requiredLabel;
      }
    },
    {
      label: 'Group',
      size: 'lg',
      align: 'left',
      dataKey: 'fieldGroup'
    },
    {
      label: 'Due Date',
      size: 'lg',
      align: 'left',
      cellDataGetter: ({ rowData }) => ({ dueDate: rowData.fieldDueDate }),
      cellRenderer: ({ cellData }) => `${cellData.dueDate} days`
    },
    {
      label: 'Host Visible',
      size: 'lg',
      align: 'left',
      cellDataGetter: ({ rowData }) => ({ hostVisibility: rowData.hostVisibility }),
      cellRenderer: ({ cellData }) => (cellData.hostVisibility ? 'Yes' : 'No')
    },
    {
      label: 'Host Required',
      size: 'lg',
      align: 'left',
      cellDataGetter: ({ rowData }) => ({ hostRequired: rowData.hostRequired }),
      cellRenderer: ({ cellData }) => (cellData.hostRequired ? 'Yes' : 'No')
    },
    {
      label: 'Host Can Edit',
      size: 'lg',
      align: 'left',
      cellDataGetter: ({ rowData }) => ({ hostEdit: rowData.hostEdit }),
      cellRenderer: ({ cellData }) => (cellData.hostEdit ? 'Yes' : 'No')
    },
    {
      label: 'Default Event Types',
      size: 'lg',
      align: 'left',
      dataKey: ''
    }
  ];

  const handleRowClickAction = e => {
    rowClickAction && rowClickAction(e.rowData);
  };

  return (
    <TableProvider>
      <Table
        columns={columns}
        data={data}
        disableHeight
        key={groupKey}
        rowClickAction={handleRowClickAction}
      />
    </TableProvider>
  );
};
