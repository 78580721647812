import { useVenue } from 'src/v2/Hooks/CollectionHooks';
import { SelectInputWithLabel } from 'src/v2/UIComponents/FormComponents';
import { EVENT_TASK_FORM_CONSTANTS } from '../constants';

const { EVENT_TASK_ASSIGNED_TO } = EVENT_TASK_FORM_CONSTANTS;

export const EventTaskAssignedTo = () => {
  const { staffUsers } = useVenue();

  const options =
    staffUsers &&
    staffUsers.map(user => {
      return { value: user.id, label: `${user.firstname} ${user.lastname}`, photo: user.photo };
    });

  const inputProps = {
    label: EVENT_TASK_ASSIGNED_TO.LABEL,
    name: 'assignedTo',
    options,
    placeholder: EVENT_TASK_ASSIGNED_TO.PLACEHOLDER,
    isClearable: true
  };

  return <SelectInputWithLabel {...inputProps} />;
};
