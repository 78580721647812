import {
  OPEN_CHANGE_PASSWORD,
  CLOSE_CHANGE_PASSWORD,
  RESET_PASSWORD_USER_REQUEST,
  RESET_PASSWORD_USER_FAILED,
  RESET_PASSWORD_USER_SUCCESS
} from './types';

export const openChangePassword = () => ({
  type: OPEN_CHANGE_PASSWORD
});

export const closeChangePassword = () => ({
  type: CLOSE_CHANGE_PASSWORD
});

export const resetUserPassword = ({ token, data }) => ({
  type: RESET_PASSWORD_USER_REQUEST,
  token,
  data
});

export const resetPasswordFailed = resetPassError => ({
  type: RESET_PASSWORD_USER_FAILED,
  resetPassError
});

export const resetPasswordSuccess = resetPassSuccess => ({
  type: RESET_PASSWORD_USER_SUCCESS,
  resetPassSuccess
});
