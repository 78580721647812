import { VStack } from '@chakra-ui/react';
import React from 'react';
import { scrollBarProps } from '../../ScrollBar/scrollBarProps';

export const FormInputsWrapper = ({ children, handleSubmit = null }) => {
  return (
    <VStack
      onSubmit={handleSubmit}
      spacing={5}
      marginBottom="16px"
      height="100%"
      overflowY={'scroll'}
      w="100%"
      align="stretch"
      my="5px"
      minHeight={1}
      flex={1}
      sx={scrollBarProps}
    >
      {children}
    </VStack>
  );
};
