import styled from 'styled-components';
import Colors from '../../styles/Colors';

const ProfileDropdownStyles = styled.div``;

ProfileDropdownStyles.ModalAvatar = styled.div`
  text-align: center;
  display: flex;
  flex: 0.65;
  justify-content: center;
  align-items: center;
  opacity: ${props => (props.hover ? 0.6 : 1)};
  :hover {
    opacity: 0.6;
  }

  img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 2px solid ${Colors.grayAA};
    cursor: pointer;
  }

  button {
    position: absolute;
  }

  .initials {
    display: flex;
    background-color: var(--colors-primary);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    color: ${Colors.white};
    font-size: 40px;
    margin: auto;

    span {
      position: relative;
      margin: 2em auto;
    }
  }
`;

ProfileDropdownStyles.Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.15;
  font-family: Lora;
  font-size: 30px;
  text-align: center;
`;

export default ProfileDropdownStyles;
