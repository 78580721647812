import { Card, CardBody, Button } from 'src/v2/UIComponents/Primitives';
import { Flex, Box } from '@chakra-ui/react';
import Search from 'src/components/Search';
import { AddPlus } from 'src/v2/UIComponents/Primitives/Icons';
import EventDocumentsTable from 'src/v2/Tables/EventDocumentsTable/Table';
import { useEvents } from 'src/v2/Hooks/CollectionHooks';
import { EmptyState } from 'src/v2/UIComponents/EmptyState';
import { useDrawers } from 'src/v2/Hooks/HelperHooks';
import { EventDocumentDrawer } from '../../EventDocumentDrawer';

export const Documents = () => {
  const { openEventDocumentDrawer } = useDrawers();
  const { activeEvent: lead, activeEventId: eventId } = useEvents();
  const { documents } = lead || {};

  const showDocuments = documents?.length > 0;

  return (
    <>
      <EventDocumentDrawer eventId={eventId} />
      <Flex justifyContent="space-between">
        {/* <Box>
          {showDocuments && (
            <Search
              width="350px"
              placeholder="Search documents"
              handleChange={() => {}}
              value={''}
            />
          )}
        </Box> */}
        <div />
        <Button
          variant="primary"
          leftIcon={<AddPlus color="white" w={4} h={4} />}
          width="140px"
          disableAnimation
          onClick={openEventDocumentDrawer}
        >
          Add Document
        </Button>
      </Flex>
      {showDocuments ? (
        <Card flex={1}>
          <CardBody paddingTop={0} paddingX={0}>
            <EventDocumentsTable />
          </CardBody>
        </Card>
      ) : (
        <Flex flexDirection={'column'} minH="1px" gap={'24px'} height="100%">
          <EmptyState title="No Documents Uploaded Yet" subTitle="Upload a document to this lead" />
        </Flex>
      )}
    </>
  );
};
