import {
  GET_LAYOUTSREDUCER_REQUEST,
  GET_LAYOUTSREDUCER_FAILURE,
  GET_LAYOUTSREDUCER_SUCCESS,
  ADD_LAYOUT_REQUEST,
  ADD_LAYOUT_FAILURE,
  ADD_LAYOUT_SUCCESS,
  UPDATE_LAYOUT_REQUEST,
  UPDATE_LAYOUT_FAILURE,
  UPDATE_LAYOUT_SUCCESS,
  DELETE_LAYOUT_REQUEST,
  DELETE_LAYOUT_FAILURE,
  DELETE_LAYOUT_SUCCESS,
  UPLOAD_LAYOUT_REQUEST,
  UPLOAD_LAYOUT_FAILURE,
  UPLOAD_LAYOUT_SUCCESS,
  SET_LAYOUT_FOR_EDIT,
  CLEAR_LAYOUT_FOR_EDIT,
  SET_ROOM_SELECTED,
  CLEAR_SELECTED_ROOM,
  GET_LAYOUT_BY_ID_REQUEST,
  GET_LAYOUT_BY_ID_SUCCESS,
  GET_LAYOUT_BY_ID_FAILURE,
  GET_LAYOUT_OPTIONS,
  DUPLICATE_LAYOUT_REQUEST,
  DUPLICATE_LAYOUT_SUCCESS,
  DUPLICATE_LAYOUT_FAILURE,
  SET_SEARCH_TERM
} from './types';

export const getLayoutsRequest = ({ venueId }) => ({
  type: GET_LAYOUTSREDUCER_REQUEST,
  venueId
});

export const getLayoutsFailure = ({ error }) => ({
  type: GET_LAYOUTSREDUCER_FAILURE,
  error
});

export const getLayoutsSuccess = ({ layouts }) => ({
  type: GET_LAYOUTSREDUCER_SUCCESS,
  layouts
});

export const addLayoutRequest = ({ layout }) => ({
  type: ADD_LAYOUT_REQUEST,
  layout
});

export const addLayoutFailure = ({ error }) => ({
  type: ADD_LAYOUT_FAILURE,
  error
});

export const addLayoutSuccess = ({ layout }) => ({
  type: ADD_LAYOUT_SUCCESS,
  layout
});

export const updateLayoutRequest = ({ layoutId, layout }) => ({
  type: UPDATE_LAYOUT_REQUEST,
  layoutId,
  layout
});

export const updateLayoutFailure = ({ error }) => ({
  type: UPDATE_LAYOUT_FAILURE,
  error
});

export const updateLayoutSuccess = ({ layout }) => ({
  type: UPDATE_LAYOUT_SUCCESS,
  layout
});

export const uploadLayoutRequest = ({ formData }) => ({
  type: UPLOAD_LAYOUT_REQUEST,
  formData
});

export const uploadLayoutFailure = ({ error }) => ({
  type: UPLOAD_LAYOUT_FAILURE,
  error
});

export const uploadLayoutSuccess = ({ url }) => ({
  type: UPLOAD_LAYOUT_SUCCESS,
  url
});

export const deleteLayoutRequest = ({ layoutId }) => ({
  type: DELETE_LAYOUT_REQUEST,
  layoutId
});

export const deleteLayoutFailure = ({ error }) => ({
  type: DELETE_LAYOUT_FAILURE,
  error
});

export const deleteLayoutSuccess = () => ({
  type: DELETE_LAYOUT_SUCCESS
});

export const setLayoutForEdit = layout => ({
  type: SET_LAYOUT_FOR_EDIT,
  layout
});

export const clearLayoutForEdit = () => ({
  type: CLEAR_LAYOUT_FOR_EDIT
});

export const setSelectedRoom = room => ({
  type: SET_ROOM_SELECTED,
  room
});

export const clearSelectedRoom = () => ({
  type: CLEAR_SELECTED_ROOM
});

export const getLayoutByIdRequest = ({ layoutId }) => ({
  type: GET_LAYOUT_BY_ID_REQUEST,
  layoutId
});

export const getLayoutByIdSuccess = ({ layout }) => ({
  type: GET_LAYOUT_BY_ID_SUCCESS,
  layout
});

export const getLayoutByIdError = ({ error }) => ({
  type: GET_LAYOUT_BY_ID_FAILURE,
  error
});

export const getLayoutOptionsOperations = {
  request: roomId => ({ type: GET_LAYOUT_OPTIONS.REQUEST, roomId }),
  success: ({ layouts }) => ({ type: GET_LAYOUT_OPTIONS.SUCCESS, layouts }),
  failure: () => ({ type: GET_LAYOUT_OPTIONS.FAILURE })
};

export const duplicateLayoutRequest = ({ layoutId }) => ({
  type: DUPLICATE_LAYOUT_REQUEST,
  layoutId
});

export const duplicateLayoutSuccess = ({ layout }) => ({
  type: DUPLICATE_LAYOUT_SUCCESS,
  layout
});

export const duplicateLayoutError = ({ error }) => ({
  type: DUPLICATE_LAYOUT_FAILURE,
  error
});

export const setSearchTerm = ({ searchTerm }) => ({
  type: SET_SEARCH_TERM,
  searchTerm
});
