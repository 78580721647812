const EVENT_DOCUMENT_NAME = {
  REQUIRED: 'Document Name is Required',
  LABEL: 'Document Name',
  PLACEHOLDER: 'Document Name'
};

const EVENT_DOCUMENT_DESCRIPTION = {
  REQUIRED: 'Document Description is Required',
  LABEL: 'Document Description',
  PLACEHOLDER: 'Document Description'
};

export const EVENT_DOCUMENT_CONSTANTS = {
  EVENT_DOCUMENT_NAME,
  EVENT_DOCUMENT_DESCRIPTION
};
