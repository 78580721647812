import { DateFieldWithLabel } from 'src/v2/UIComponents/FormComponents';
import { EVENT_DATE_LABEL, EVENT_DATE_PLACEHOLDER, EVENT_DATE_REQUIRED } from './constants';

export const EventDateInput = ({ name = 'start', bookEvent }) => {
  const eventDateProps = {
    isRequired: bookEvent,
    name,
    label: 'Event Start',
    placeholder: EVENT_DATE_PLACEHOLDER,
    rules: { required: bookEvent && EVENT_DATE_REQUIRED }
  };

  return <DateFieldWithLabel {...eventDateProps} />;
};
