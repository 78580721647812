import {
  LOGIN_ERRORS_SET,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  REFRESH_REQUEST,
  VENUEID_REMOVE,
  EVENTID_REMOVE,
  POLICY_ACCEPT_REQUEST,
  POLICY_REJECT,
  POLICY_REQUIRE,
  POLICY_ACCEPT_TRIGGER,
  GET_EMAIL_THREADS
} from './types';

export const doSetErrors = error => ({
  type: LOGIN_ERRORS_SET,
  error
});

export const doLogin = ({ email, password, venueId }) => ({
  type: LOGIN_REQUEST,
  email,
  password,
  venueId
});

export const doVenueIdRemove = () => ({
  type: VENUEID_REMOVE
});

export const doEventIdRemove = () => ({
  type: EVENTID_REMOVE
});

export const doLogout = ({ token }) => ({ type: LOGOUT_REQUEST, token });

export const doRefresh = ({ token, refresh }) => ({ type: REFRESH_REQUEST, token, refresh });

export const doPolicyAcceptTrigger = () => ({
  type: POLICY_ACCEPT_TRIGGER
});

export const doPolicyAccept = ({ token }) => ({ type: POLICY_ACCEPT_REQUEST, token });

export const doPolicyReject = () => ({
  type: POLICY_REJECT
});

export const doPolicyRequire = () => ({
  type: POLICY_REQUIRE
});

export const emailThreadOperations = {
  request: ({ token, userId, offset, limit }) => ({
    type: GET_EMAIL_THREADS.REQUEST,
    token,
    userId,
    offset,
    limit
  }),
  success: ({ threadOptions }) => ({
    type: GET_EMAIL_THREADS.SUCCESS,
    threadOptions
  }),
  failure: () => ({
    type: GET_EMAIL_THREADS.FAILURE
  })
};
