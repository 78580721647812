import React from 'react';
import PropTypes from 'prop-types';
import ChangePasswordStyles from './styles';
import Input from '../../styles/Input';

const ChangePasswordBody = ({
  oldPassword,
  newPassword,
  confirmPassword,
  handleInputChange,
  resetErrors
}) => {
  return (
    <ChangePasswordStyles.Body>
      <ChangePasswordStyles.BodyRow>
        <ChangePasswordStyles.BodyKey marginRight="40px">
          Old Password:
        </ChangePasswordStyles.BodyKey>
        <Input value={oldPassword} name="oldPassword" onChange={handleInputChange} />
      </ChangePasswordStyles.BodyRow>
      <ChangePasswordStyles.BodyRow>
        <ChangePasswordStyles.BodyKey marginRight="40px">
          New Password:
        </ChangePasswordStyles.BodyKey>
        <Input value={newPassword} name="newPassword" onChange={handleInputChange} />
      </ChangePasswordStyles.BodyRow>
      <ChangePasswordStyles.BodyRow>
        <ChangePasswordStyles.BodyKey marginRight="40px">
          Confirm Password:
        </ChangePasswordStyles.BodyKey>
        <Input value={confirmPassword} name="confirmPassword" onChange={handleInputChange} />
      </ChangePasswordStyles.BodyRow>
      <ChangePasswordStyles.Error>
        {resetErrors && (resetErrors.message ? resetErrors.message : resetErrors)}
      </ChangePasswordStyles.Error>
    </ChangePasswordStyles.Body>
  );
};

ChangePasswordBody.propTypes = {
  oldPassword: PropTypes.string,
  newPassword: PropTypes.string,
  confirmPassword: PropTypes.string,
  setOldPassword: PropTypes.func,
  setNewPassword: PropTypes.func,
  setConfirmPassword: PropTypes.func,
  handleInputChange: PropTypes.func,
  resetErrors: PropTypes.string
};

export default ChangePasswordBody;
