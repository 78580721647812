import { createGlobalStyle } from 'styled-components';
import Colors from './Colors';
import hexToRgbA from './HexToRgba';

const GlobalStyles = createGlobalStyle`
  :root {
    ${props => {
      const colors = (props.styles && props.styles.colors) || {};
      const opacity = (colors && colors.opacity) || {};
      const {
        primary = Colors.grayCC,
        sideBar = Colors.grayCC,
        sideBarSelected = Colors.grayCC,
        sideBarText = Colors.grayCC,
        headerRow = Colors.grayCC,
        tableColor = Colors.grayCC,
        hostNotif = Colors.grayCC,
        modalHeader = Colors.grayCC,
        bgLoginSection = Colors.white,
        bgLoginCard = Colors.white,
        sideBarHiddenIcon,
        sideBarHiddenSelectedIcon
      } = colors;
      const {
        sideBarOpacity = 1,
        sideBarSelectedOpacity = 1,
        headerRowOpacity = 1,
        tableColorOpacity = 1,
        hostNotifOpacity = 1,
        modalHeaderOpacity = 1,
        sideBarHiddenIconOpacity = 1,
        sideBarHiddenSelectedIconOpacity = 1
      } = opacity;
      return `
      --colors-primary: ${primary};
      --colors-sideBar: ${hexToRgbA(sideBar, sideBarOpacity)};
      --colors-sideBar-selected:  ${hexToRgbA(sideBarSelected, sideBarSelectedOpacity)};
      --colors-sideBar-hover: ${hexToRgbA(sideBarSelected, '.25')};
      --colors-sideBarText: ${sideBarText};
      --colors-headerRow: ${hexToRgbA(headerRow, headerRowOpacity)};
      --colors-tableColor: ${hexToRgbA(tableColor, tableColorOpacity)};
      --colors-hostNotif: ${hexToRgbA(hostNotif, hostNotifOpacity)};
      --colors-modalHeader: ${hexToRgbA(modalHeader, modalHeaderOpacity)};
      --colors-bgLoginSection: ${bgLoginSection};
      --colors-bgLoginCard: ${bgLoginCard};
      --colors-sideBarHiddenIcon: ${hexToRgbA(
        (sideBarHiddenIcon && sideBarHiddenIcon) || sideBarText,
        sideBarHiddenIconOpacity
      )};
      --colors-sideBarHiddenIcon-selected: ${hexToRgbA(
        (sideBarHiddenSelectedIcon && sideBarHiddenSelectedIcon) || primary,
        sideBarHiddenSelectedIconOpacity
      )};
      `;
    }}
    --logoSize: ${props => (props.styles && props.styles.logoSize) || 'auto 80%'};
  
    ${'' /* Primary variables, should be deprecated */}
    --colors-primary-light: ${props =>
      (props.styles && props.styles.colors && hexToRgbA(props.styles.colors.primary, 0.6)) ||
      `${Colors.beigeC0}66`}; 
    --colors-primary-lighter-opaque: ${props =>
      (props.styles && props.styles.colors && props.styles.colors.headerRow) ||
      `${Colors.beigeC0}33`};
    --colors-primary-lighter: ${props =>
      (props.styles && props.styles.colors && hexToRgbA(props.styles.colors.primary, 0.4)) ||
      `${Colors.beigeC0}44`};
    --colors-primary-lightest: ${props =>
      (props.styles && props.styles.colors && hexToRgbA(props.styles.colors.primary, 0.1)) ||
      `${Colors.beigeC0}18`}
        
  }


 

  html {
    width: 100%;
    height: 100%;
  }

  body {
    font-family: 'Lato', sans-serif;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    textarea, select, input, button { outline: none; }
  }

  #root {
    width: 100%;
    height: 100%;
  }

  h1, h2, h3, h4, h5, h6, p, ul, ol {
    margin-top: 0;
  }

  button {
    cursor: pointer;
    focus {outline:0;}
  }

  a {
    transition: all 250ms ease-in-out 0s;
    text-decoration: none;
    color: ${Colors.gray7D};

    &:hover {
      color: ${Colors.black};
      text-decoration: none;
    }
  }

  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .width-100 {
    width: 100%;
  }

  .new-alert-container div:first-child {
    display: none;
  }
`;

export default GlobalStyles;
