import React from 'react';
import PropTypes from 'prop-types';
import Ripples from 'react-ripples';
import ReactTooltip from 'react-tooltip';

import ButtonStyles from './styles';
import Loader from '../Icons/Loader';
import Colors from '../Colors';

const Button = ({
  icon,
  iconRight = false,
  label,
  type,
  action,
  loading,
  color,
  disabled = false,
  bgColor,
  disableShadow = false,
  disableOutline = false,
  padding,
  noBorder,
  height,
  primary,
  width,
  smallIcon,
  disableRipple,
  toolTipText,
  className = '',
  animate,
  animateSVG
}) => {
  const tooltipId = Math.random()
    .toString(36)
    .substring(2);

  return (
    <Ripples
      color={(disableRipple && 'none') || (bgColor && `var(--colors-primary-lighter)`) || '#ffffff'}
      during={1500}
    >
      <ButtonStyles
        hasIcon={icon}
        iconRight={iconRight}
        type={type}
        disabled={disabled || loading}
        loading={loading || false}
        onClick={action}
        bgColor={bgColor}
        color={color}
        disableShadow={disableShadow}
        padding={padding}
        noBorder={noBorder}
        height={height}
        disableOutline={disableOutline}
        width={width}
        primary={primary}
        smallIcon={smallIcon}
        data-for={tooltipId}
        data-tip={toolTipText}
        data-place="bottom"
        data-effect="solid"
        className={className}
        animate={animate}
        animateSVG={animateSVG}
      >
        {!loading ? icon && icon : <Loader size={22} color={color || Colors.white} />}
        {label && <span>{label}</span>}
      </ButtonStyles>
      <ReactTooltip id={tooltipId} />
    </Ripples>
  );
};

Button.propTypes = {
  icon: PropTypes.element || null,
  iconRight: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  action: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  noBorder: PropTypes.bool,
  bgColor: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  toolTipText: PropTypes.string,
  padding: PropTypes.string,
  disableShadow: PropTypes.bool,
  height: PropTypes.string,
  disableOutline: PropTypes.bool,
  smallIcon: PropTypes.bool,
  disableRipple: PropTypes.bool,
  primary: PropTypes.string,
  className: PropTypes.string,
  animate: PropTypes.bool
};

export default Button;
