import {
  OPEN_STAFF_DETAILS,
  OPEN_STAFF_DETAILS_SUCCESS,
  OPEN_STAFF_DETAILS_FAILURE,
  CLOSE_STAFF_DETAILS
} from './types';

export const openStaffDetails = ({ token, venueId, staffId }) => ({
  type: OPEN_STAFF_DETAILS,
  token,
  venueId,
  staffId
});

export const openStaffDetailsSuccess = ({ user }) => ({
  type: OPEN_STAFF_DETAILS_SUCCESS,
  user
});

export const openStaffDetailsFailure = ({ error }) => ({
  type: OPEN_STAFF_DETAILS_FAILURE,
  error
});

export const closeStaffDetails = () => ({
  type: CLOSE_STAFF_DETAILS
});
