export const CREATE_BUDGET_ITEM_CATEGORY = 'CREATE_BUDGET_ITEM_CATEGORY';
export const CREATE_BUDGET_ITEM_CATEGORY_SUCCESS = 'CREATE_BUDGET_ITEM_CATEGORY_SUCCESS';
export const CREATE_BUDGET_ITEM_CATEGORY_FAILURE = 'CREATE_BUDGET_ITEM_CATEGORY_FAILURE';

export const EDIT_BUDGET_ITEM_CATEGORY = 'EDIT_BUDGET_ITEM_CATEGORY';
export const EDIT_BUDGET_ITEM_CATEGORY_SUCCESS = 'EDIT_BUDGET_ITEM_CATEGORY_SUCCESS';
export const EDIT_BUDGET_ITEM_CATEGORY_FAILURE = 'EDIT_BUDGET_ITEM_CATEGORY_FAILURE';

export const FETCH_BUDGET_ITEM_CATEGORIES = 'FETCH_BUDGET_ITEM_CATEGORIES';
export const FETCH_BUDGET_ITEM_CATEGORIES_SUCCESS = 'FETCH_BUDGET_ITEM_CATEGORIES_SUCCESS';
export const FETCH_BUDGET_ITEM_CATEGORIES_FAILURE = 'FETCH_BUDGET_ITEM_CATEGORIES_FAILURE';

export const FETCH_BUDGET_ITEMS = 'FETCH_BUDGET_ITEMS';
export const FETCH_BUDGET_ITEMS_SUCCESS = 'FETCH_BUDGET_ITEMS_SUCCESS';
export const FETCH_BUDGET_ITEMS_FAILURE = 'FETCH_BUDGET_ITEMS_FAILURE';

export const CREATE_BUDGET_ITEM = 'CREATE_BUDGET_ITEM';
export const CREATE_BUDGET_ITEM_SUCCESS = 'CREATE_BUDGET_ITEM_SUCCESS';
export const CREATE_BUDGET_ITEM_FAILURE = 'CREATE_BUDGET_ITEM_FAILURE';

export const UPDATE_BUDGET_ITEM = 'UPDATE_BUDGET_ITEM';
export const UPDATE_BUDGET_ITEM_SUCCESS = 'UPDATE_BUDGET_ITEM_SUCCESS';
export const UPDATE_BUDGET_ITEM_FAILURE = 'UPDATE_BUDGET_ITEM_FAILURE';

export const DELETE_BUDGET_ITEM = 'DELETE_BUDGET_ITEM';
export const DELETE_BUDGET_ITEM_SUCCESS = 'DELETE_BUDGET_ITEM_SUCCESS';
export const DELETE_BUDGET_ITEM_FAILURE = 'DELETE_BUDGET_ITEM_FAILURE';

export const BATCH_DELETE_BUDGET_ITEMS = 'BATCH_DELETE_BUDGET_ITEMS';
export const BATCH_DELETE_BUDGET_ITEMS_SUCCESS = 'BATCH_DELETE_BUDGET_ITEMS_SUCCESS';
export const BATCH_DELETE_BUDGET_ITEMS_FAILURE = 'BATCH_DELETE_BUDGET_ITEMS_FAILURE';

export const DELETE_BUDGET_ITEM_CATEGORY = 'DELETE_BUDGET_ITEM_CATEGORY';
export const DELETE_BUDGET_ITEM_CATEGORY_SUCCESS = 'DELETE_BUDGET_ITEM_CATEGORY_SUCCESS';
export const DELETE_BUDGET_ITEM_CATEGORY_FAILURE = 'DELETE_BUDGET_ITEM_CATEGORY_FAILURE';

export const SET_STATUS_FILTER = 'SET_STATUS_FILTER';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
