import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Icon } from '@chakra-ui/react';
import { AiOutlineForm, AiOutlineSetting } from 'react-icons/ai';
import { BiLineChart } from 'react-icons/bi';
import { BsListUl, BsFillFunnelFill, BsFunnel } from 'react-icons/bs';
import { MdOutlineFileDownload } from 'react-icons/md';
import { useSideBar } from 'src/v2/Hooks/HelperHooks';
import { useVenue, useEvents, useUser, useLeads } from 'src/v2/Hooks/CollectionHooks';

import { Bill, FloorPlan } from 'src/v2/UIComponents/Primitives/Icons';
import SidebarStyles, { SidebarWrapper } from './styles';
import IconHome from '../../styles/Icons/Home';
import IconChair from '../../styles/Icons/ChairIcon';
import IconCalendar from '../../styles/Icons/CalendarBlank';
import IconManageStaff from '../../styles/Icons/ManageStaffIcon';
import IconMoney from '../../styles/Icons/MoneyIcon';
import IconChecklist from '../../styles/Icons/Checklist';
import IconPhotos from '../../styles/Icons/Photos';
import IconStore from '../../styles/Icons/Store';
import IconCaretRight from '../../styles/Icons/CaretRight';
import IconCaretLeft from '../../styles/Icons/CaretLeft';
import IconNotes from '../../styles/Icons/Notes';
import IconLibrary from '../../styles/Icons/Library';

const Sidebar = ({ name }) => {
  const location = useLocation();
  const { activeEvent: event } = useEvents();
  const { permissions, viewPermissions, role, admin } = useUser();
  const { logo, smallLogo, featureFlags: venueFeatureFlags } = useVenue();
  const { sidebarHidden: hideSidebar, hideSidebar: setHideSidebar } = useSideBar();
  const { isLeadsDetailsOpen, closeLeadsDetails } = useLeads();

  return (
    <SidebarWrapper hideSidebar={hideSidebar} isAdmin={admin}>
      <SidebarStyles>
        <SidebarStyles.Header>
          <SidebarStyles.Img src={(hideSidebar && smallLogo && smallLogo) || logo} alt={name} />
        </SidebarStyles.Header>
        <SidebarStyles.Body>
          <SidebarStyles.Nav>
            {(admin || role === 'host') && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('dashboard') > -1}
              >
                <Link to="/dashboard" data-for="sidebar-tooltips" data-tip="Dashboard">
                  <IconHome sideBarIcon />
                  {!hideSidebar && <span>Dashboard</span>}
                </Link>
              </SidebarStyles.NavLink>
            )}
            {/* {(admin || role === 'host') && venueFeatureFlags?.customFields && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('hostforms') > -1}
              >
                <Link to="/hostforms" data-for="sidebar-tooltips" data-tip="Questionnaire">
                  <Icon as={AiOutlineForm} w={7} h={7} />
                  {!hideSidebar && <span>Questionnaire</span>}
                </Link>
              </SidebarStyles.NavLink>
            )} */}
            {admin && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('venues') > -1}
              >
                <Link to="/venues" data-for="sidebar-tooltips" data-tip="Venues">
                  <IconHome sideBarIcon />
                  {!hideSidebar && <span>Venues</span>}
                </Link>
              </SidebarStyles.NavLink>
            )}
            {(admin || role === 'host') && venueFeatureFlags && venueFeatureFlags.checkList && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('checklist') > -1}
              >
                <Link to="/checklist" data-for="sidebar-tooltips" data-tip="Checklist">
                  <IconChecklist sideBarIcon />
                  {!hideSidebar && <span>Checklist</span>}
                </Link>
              </SidebarStyles.NavLink>
            )}
            {(admin || (role === 'staff' && permissions.events.read)) && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('events') > -1}
              >
                <Link to="/events" data-for="sidebar-tooltips" data-tip="Events Calendar">
                  <IconCalendar sideBarIcon />
                  {!hideSidebar && <span>Events Calendar</span>}
                </Link>
              </SidebarStyles.NavLink>
            )}
            {(admin || (role === 'staff' && permissions.events.read)) && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('leads') > -1}
              >
                <Link
                  to="/leads"
                  data-for="sidebar-tooltips"
                  data-tip="Leads"
                  onClick={closeLeadsDetails}
                >
                  <Icon width={8} height={8} as={BsFunnel} />
                  {!hideSidebar && <span>Leads</span>}
                </Link>
              </SidebarStyles.NavLink>
            )}
            {/* {(admin || role === 'staff') && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('payments') > -1}
              >
                <Link to="/payments" data-for="sidebar-tooltips" data-tip="Payments">
                  <Bill />
                  {!hideSidebar && <span>Payments</span>}
                </Link>
              </SidebarStyles.NavLink>
            )} */}
            {/* {(admin || role === 'host') && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('hostPayments') > -1}
              >
                <Link to="/hostPayments" data-for="sidebar-tooltips" data-tip="Payments">
                  <Bill />
                  {!hideSidebar && <span>Payments</span>}
                </Link>
              </SidebarStyles.NavLink>
            )} */}
            {(admin || (role === 'staff' && permissions.events.read)) && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('layouts') > -1}
              >
                <Link to="/layouts" data-for="sidebar-tooltips" data-tip="Floor Plans">
                  <FloorPlan />
                  {!hideSidebar && <span>Floor Plans</span>}
                </Link>
              </SidebarStyles.NavLink>
            )}
            {(admin || role === 'staff') && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('documentLibrary') > -1}
              >
                <Link to="/documentLibrary" data-for="sidebar-tooltips" data-tip="Document Library">
                  <IconLibrary sideBarIcon />
                  {!hideSidebar && <span>Document Library</span>}
                </Link>
              </SidebarStyles.NavLink>
            )}
            {/* {(admin || role === 'staff') && venueFeatureFlags?.customFields && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('customFields') > -1}
              >
                <Link to="/customFields" data-for="sidebar-tooltips" data-tip="Custom Fields">
                  <Icon as={AiOutlineForm} w={6} h={6} />
                  {!hideSidebar && <span>Custom Fields</span>}
                </Link>
              </SidebarStyles.NavLink>
            )} */}
            {/* {(admin || role === 'staff') && venueFeatureFlags?.venueSettings && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('manageproducts') > -1}
              >
                <Link
                  to="/manageproducts"
                  data-for="sidebar-tooltips"
                  data-tip="Products & Services"
                >
                  <Icon as={BsListUl} w={6} h={6} />
                  {!hideSidebar && <span>Products & Services</span>}
                </Link>
              </SidebarStyles.NavLink>
            )} */}
            {(admin ||
              (role === 'staff' &&
                permissions.users.read &&
                viewPermissions &&
                viewPermissions.manageStaff)) && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('managestaff') > -1}
              >
                <Link to="/managestaff" data-for="sidebar-tooltips" data-tip="Staff Access">
                  <IconManageStaff sideBarIcon />
                  {!hideSidebar && <span>Staff Access</span>}
                </Link>
              </SidebarStyles.NavLink>
            )}
            {(admin || role === 'host') && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('guestlist') > -1}
              >
                <Link to="/guestlist" data-for="sidebar-tooltips" data-tip="Guest List">
                  <IconManageStaff sideBarIcon />
                  {!hideSidebar && <span>Guest List</span>}
                </Link>
              </SidebarStyles.NavLink>
            )}
            {(admin || role === 'host') && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('seatingchart') > -1}
              >
                <Link to="/seatingchart" data-for="sidebar-tooltips" data-tip="Seating Chart">
                  <IconChair sideBarIcon />
                  {!hideSidebar && <span>Seating Chart</span>}
                </Link>
              </SidebarStyles.NavLink>
            )}
            {(admin || role === 'host') && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('documents') > -1}
              >
                <Link to="/documents" data-for="sidebar-tooltips" data-tip="Documents">
                  <IconNotes sideBarIcon />
                  {!hideSidebar && <span>Documents</span>}
                </Link>
              </SidebarStyles.NavLink>
            )}
            {/* TODO: if this service is not needed, maybe remove all the implementation */}
            {/* {(admin || role === 'host') && (
              <SidebarStyles.NavLink
                selected={location.pathname.indexOf('budgetplanner') > -1}
                hideSidebar={hideSidebar}
              >
                <Link to="/budgetplanner" data-for="sidebar-tooltips" data-tip="Budget Planner">
                  <IconMoney sideBarIcon />
                  {!hideSidebar && <span>Budget Planner</span>}
                </Link>
              </SidebarStyles.NavLink>
            )} */}
            {(admin || role === 'host') && venueFeatureFlags && venueFeatureFlags.vendorManagement && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('vendors') > -1}
              >
                <Link to="/vendors" data-for="sidebar-tooltips" data-tip="Vendors">
                  <IconStore sideBarIcon />
                  {!hideSidebar && <span>Vendors</span>}
                </Link>
              </SidebarStyles.NavLink>
            )}
            {/* {(admin ||
              (role === 'staff' &&
                permissions.billing.read &&
                viewPermissions &&
                viewPermissions.viewBilling)) && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('billing') > -1}
              >
                <Link to="/billing" data-for="sidebar-tooltips" data-tip="Summary">
                  <IconChecklist sideBarIcon />
                  {!hideSidebar && <span>Summary</span>}
                </Link>
              </SidebarStyles.NavLink>
            )} */}
            {(admin || role === 'staff') && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('analytics') > -1}
              >
                <Link to="/analytics" data-for="sidebar-tooltips" data-tip="Analytics">
                  <Icon as={BiLineChart} w={6} h={6} />
                  {!hideSidebar && <span>Analytics</span>}
                </Link>
              </SidebarStyles.NavLink>
            )}
            {(admin || role === 'host') && event && event.eventCode && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('memories') > -1}
              >
                <Link to="/memories" data-for="sidebar-tooltips" data-tip="Memories">
                  <IconPhotos sideBarIcon />
                  {!hideSidebar && <span>Memories</span>}
                </Link>
              </SidebarStyles.NavLink>
            )}{' '}
            {/* {(admin || role === 'staff') && venueFeatureFlags?.venueSettings && (
              <SidebarStyles.NavLink
                hideSidebar={hideSidebar}
                selected={location.pathname.indexOf('venueSettings') > -1}
              >
                <Link to="/venueSettings" data-for="sidebar-tooltips" data-tip="Venue Settings">
                  <Icon as={AiOutlineSetting} w={6} h={6} />
                  {!hideSidebar && <span>Venue Settings</span>}
                </Link>
              </SidebarStyles.NavLink>
            )} */}
          </SidebarStyles.Nav>
        </SidebarStyles.Body>
        {/* <SidebarStyles.CollapseButton onClick={() => setHideSidebar(!hideSidebar)}>
          {(hideSidebar && <IconCaretRight size={16} />) || <IconCaretLeft size={16} />}
        </SidebarStyles.CollapseButton> */}
      </SidebarStyles>
    </SidebarWrapper>
  );
};

Sidebar.propTypes = {
  location: PropTypes.object,
  event: PropTypes.object,
  viewPermissions: PropTypes.object,
  permissions: PropTypes.object,
  role: PropTypes.string,
  admin: PropTypes.bool,
  logo: PropTypes.string,
  name: PropTypes.string,
  venueFeatureFlags: PropTypes.object,
  hideSidebar: PropTypes.bool
};

export default Sidebar;
