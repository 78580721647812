import {
  OPEN_EVENT_MODAL,
  CLOSE_EVENT_MODAL,
  GET_ROOM_DETAILS_REQUEST,
  GET_ROOM_DETAILS_FAILURE,
  GET_ROOM_DETAILS_SUCCESS,
  GET_LAYOUT_DETAILS_REQUEST,
  GET_LAYOUT_DETAILS_FAILURE,
  GET_LAYOUT_DETAILS_SUCCESS,
  GET_HOSTS_SUCCESS,
  GET_HOSTS_FAILURE,
  GET_HOSTS_REQUEST,
  SET_PAYMENTSTATUS_REQUEST,
  SET_PAYMENTSTATUS_SUCCESS,
  SET_PAYMENTSTATUS_FAILURE,
  SEND_VENUETOHOSTEMAIL_REQUEST,
  SEND_VENUETOHOSTEMAIL_SUCCESS,
  SEND_VENUETOHOSTEMAIL_FAILURE,
  GENERATE_SEATING_CHART_PDF_REQUEST,
  REQUEST_EVENT_PACKET,
  REQUEST_EVENT_PACKET_FAILURE,
  OPEN_EVENT_MODAL_SUCCESS,
  OPEN_EVENT_MODAL_REQUEST,
  OPEN_EVENT_MODAL_FAILURE,
  SET_ACTIVE_ITEM,
  GET_EVENT_DETAIL_REQUEST,
  GET_EVENT_DETAIL_SUCCESS,
  GET_EVENT_DETAIL_FAILURE,
  OPEN_EVENT_DETAILS_DRAWER,
  CLOSE_EVENT_DETAILS_DRAWER,
  OPEN_EVENT_FORM_DRAWER,
  CLOSE_EVENT_FORM_DRAWER
} from './types';

export const openEventModalRequest = () => ({
  type: OPEN_EVENT_MODAL_REQUEST
});

export const openEventModalSuccess = ({ event }) => ({
  type: OPEN_EVENT_MODAL_SUCCESS,
  event
});

export const openEventModalFailure = ({ error }) => ({
  type: OPEN_EVENT_MODAL_FAILURE,
  error
});

export const openEventModal = event => ({
  type: OPEN_EVENT_MODAL,
  event
});

export const closeEventModal = () => ({
  type: CLOSE_EVENT_MODAL
});

export const getRoomDetails = ({ token, roomId }) => ({
  type: GET_ROOM_DETAILS_REQUEST,
  token,
  roomId
});

export const getRoomDetailsFailure = getRoomErrors => ({
  type: GET_ROOM_DETAILS_FAILURE,
  getRoomErrors
});

export const getRoomDetailsSuccess = room => ({
  type: GET_ROOM_DETAILS_SUCCESS,
  room
});

export const getLayoutsDetails = ({ token, roomId }) => ({
  type: GET_LAYOUT_DETAILS_REQUEST,
  token,
  roomId
});

export const getLayoutsDetailsFailure = layoutErrors => ({
  type: GET_LAYOUT_DETAILS_FAILURE,
  layoutErrors
});

export const getLayoutsDetailsSuccess = layouts => ({
  type: GET_LAYOUT_DETAILS_SUCCESS,
  layouts
});

export const getHostsRequest = ({ venueId, token, eventId, role }) => ({
  type: GET_HOSTS_REQUEST,
  venueId,
  token,
  eventId,
  role
});

export const getHostsSuccess = ({ users }) => {
  return {
    type: GET_HOSTS_SUCCESS,
    users
  };
};

export const getHostsFailure = error => ({
  type: GET_HOSTS_FAILURE,
  error
});

export const setPaymentStatusRequest = ({ status, token, eventId }) => ({
  type: SET_PAYMENTSTATUS_REQUEST,
  status,
  token,
  eventId
});

export const setPaymentStatusSuccess = ({ status }) => ({
  type: SET_PAYMENTSTATUS_SUCCESS,
  status
});

export const setPaymentStatusFailure = ({ error }) => ({
  type: SET_PAYMENTSTATUS_FAILURE,
  error
});

export const sendVenueToHostEmailRequest = ({ token, eventId, subject, message }) => ({
  type: SEND_VENUETOHOSTEMAIL_REQUEST,
  token,
  eventId,
  subject,
  message
});

export const sendVenueToHostEmailSuccess = () => ({
  type: SEND_VENUETOHOSTEMAIL_SUCCESS
});

export const sendVenueToHostEmailFailure = ({ error }) => ({
  type: SEND_VENUETOHOSTEMAIL_FAILURE,
  error
});

export const setActiveItem = ({ activeItem }) => ({
  type: SET_ACTIVE_ITEM,
  activeItem
});

export const doGeneratePdfFile = ({
  token,
  eventId,
  venueId,
  layoutId,
  canvas,
  setDownloadingDoc,
  layoutType
}) => ({
  type: GENERATE_SEATING_CHART_PDF_REQUEST,
  token,
  eventId,
  venueId,
  layoutId,
  canvas,
  setDownloadingDoc,
  layoutType
});

export const doRequestEventPacket = ({
  token = '',
  eventId = '',
  venueId = '',
  layoutId = '',
  canvas = {},
  updateModalLoaderMessage,
  closeModalLoader,
  layoutType = ''
}) => ({
  type: REQUEST_EVENT_PACKET,
  token,
  eventId,
  venueId,
  layoutId,
  canvas,
  updateModalLoaderMessage,
  closeModalLoader,
  layoutType
});

export const requestEventPacketFailure = () => ({
  REQUEST_EVENT_PACKET_FAILURE
});

export const getEventDetailRequest = () => ({
  type: GET_EVENT_DETAIL_REQUEST
});

export const getEventDetailSuccess = event => ({
  type: GET_EVENT_DETAIL_SUCCESS,
  event
});

export const getEventDetailFailure = error => ({
  type: GET_EVENT_DETAIL_FAILURE,
  error
});

export const openEventDetailsDrawer = ({ activeItem = 'overview' } = {}) => ({
  type: OPEN_EVENT_DETAILS_DRAWER,
  activeItem
});

export const closeEventDetailsDrawer = () => ({
  type: CLOSE_EVENT_DETAILS_DRAWER
});

export const openEventFormDrawer = () => ({
  type: OPEN_EVENT_FORM_DRAWER
});

export const closeEventFormDrawer = () => ({
  type: CLOSE_EVENT_FORM_DRAWER
});
