import { useState } from 'react';
import { Badge, Wrap, WrapItem } from '@chakra-ui/react';
import _isArray from 'lodash/isArray';
import Colors from 'src/styles/Colors';

export const ColumnBadge = props => {
  const { cellData } = props;
  const value = cellData?.value;
  const [showMore, setShowMore] = useState(false);

  if (_isArray(value)) {
    return (
      <Wrap>
        {value.map((type, key) => (
          <WrapItem key={key}>
            <Badge
              p="6px"
              backgroundColor={Colors.grayED7}
              variant="subtle"
              borderRadius="sm"
              cursor="default"
            >
              {type}
            </Badge>
          </WrapItem>
        ))}
      </Wrap>
    );
  }

  return null;
};
