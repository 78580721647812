import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export function getMemoriesApi({ token, type = null }) {
  let url = `${apiUri}/mainfeed?`;

  if (type) {
    url = `${url}&type=${type}`;
  }

  return axios({
    method: 'get',
    url,
    headers: { Authorization: token, app_version: '2.0.0' }
  });
}

export function deleteMemoryApi({ token, feedId }) {
  return axios({
    method: 'delete',
    url: `${apiUri}/mainfeed/${feedId}`,
    headers: { Authorization: token, app_version: '2.0.0' }
  });
}

export function batchDeleteApi({ token, feedIds }) {
  return axios({
    method: 'post',
    url: `${apiUri}/mainfeed/batchDelete`,
    headers: { Authorization: token, app_version: '2.0.0' },
    data: { feedIds }
  });
}

export function createMemoryApi({ token, formData, fullPath, bucket }) {
  return axios({
    method: 'post',
    url: `${apiUri}/mainfeed`,
    headers: { Authorization: token, app_version: '2.0.0' },
    data: {
      ...formData,
      path: fullPath,
      bucket
    }
  });
}

export function editMemoryApi({ token, feedId, caption }) {
  return axios({
    method: 'put',
    url: `${apiUri}/mainfeed/${feedId}`,
    headers: { Authorization: token, app_version: '2.0.0' },
    data: { caption }
  });
}

export function editMobileEventPhotoApi({
  token,
  eventId,
  eventMobilePhoto,
  dayOfMessage,
  eventCode
}) {
  return axios({
    method: 'put',
    url: `${apiUri}/events/${eventId}`,
    headers: { Authorization: token },
    data: { eventMobilePhoto, dayOfMessage, eventCode }
  });
}

export function getEventModeratorsApi({ token }) {
  return axios({
    method: 'get',
    url: `${apiUri}/guestuser/moderator`,
    headers: { Authorization: token, app_version: '2.0.0' }
  });
}

export function addModeratorApi({ token, email, firstname, lastname }) {
  return axios({
    method: 'post',
    url: `${apiUri}/guestuser/moderator`,
    headers: { Authorization: token, app_version: '2.0.0' },
    data: { email, firstname, lastname }
  });
}

export function deleteModeratorApi({ token, id }) {
  return axios({
    method: 'delete',
    url: `${apiUri}/guestuser/moderator/${id}`,
    headers: { Authorization: token, app_version: '2.0.0' }
  });
}
