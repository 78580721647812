import React from 'react';
import PropTypes from 'prop-types';

import CloseButtonPresentation from './styles';
import Close from '../../styles/Icons/Close';
import Colors from '../../styles/Colors';

const CloseButton = ({ disabled, onClick, size }) => {
  return (
    <div>
      <CloseButtonPresentation disabled={disabled} onClick={onClick}>
        <Close color={`${Colors.gray7D}`} size={size || '16px'} />
      </CloseButtonPresentation>
    </div>
  );
};

CloseButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string
};

export default CloseButton;
