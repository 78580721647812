import {
  CREATE_BUDGET_ITEM,
  CREATE_BUDGET_ITEM_SUCCESS,
  CREATE_BUDGET_ITEM_FAILURE,
  CREATE_BUDGET_ITEM_CATEGORY,
  CREATE_BUDGET_ITEM_CATEGORY_SUCCESS,
  CREATE_BUDGET_ITEM_CATEGORY_FAILURE,
  FETCH_BUDGET_ITEM_CATEGORIES,
  FETCH_BUDGET_ITEM_CATEGORIES_SUCCESS,
  FETCH_BUDGET_ITEM_CATEGORIES_FAILURE,
  FETCH_BUDGET_ITEMS,
  FETCH_BUDGET_ITEMS_SUCCESS,
  FETCH_BUDGET_ITEMS_FAILURE,
  UPDATE_BUDGET_ITEM,
  UPDATE_BUDGET_ITEM_SUCCESS,
  UPDATE_BUDGET_ITEM_FAILURE,
  DELETE_BUDGET_ITEM,
  DELETE_BUDGET_ITEM_SUCCESS,
  DELETE_BUDGET_ITEM_FAILURE,
  EDIT_BUDGET_ITEM_CATEGORY,
  EDIT_BUDGET_ITEM_CATEGORY_SUCCESS,
  EDIT_BUDGET_ITEM_CATEGORY_FAILURE,
  DELETE_BUDGET_ITEM_CATEGORY,
  DELETE_BUDGET_ITEM_CATEGORY_SUCCESS,
  DELETE_BUDGET_ITEM_CATEGORY_FAILURE,
  BATCH_DELETE_BUDGET_ITEMS,
  BATCH_DELETE_BUDGET_ITEMS_SUCCESS,
  BATCH_DELETE_BUDGET_ITEMS_FAILURE,
  SET_STATUS_FILTER,
  SET_SEARCH_TERM
} from './types';

const initialState = {
  status: '',
  fetching: '',
  item: null,
  error: '',
  items: [],
  category: '',
  categories: [],
  updatedItem: null,
  statusFilter: '',
  searchTerm: ''
};

export const budgetPlannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BUDGET_ITEM_CATEGORY:
      return {
        ...state,
        error: '',
        status: 'creatingBudgetPlannerCategory'
      };
    case CREATE_BUDGET_ITEM_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.category,
        status: 'creatingBudgetPlannerCategorySuccess'
      };
    case CREATE_BUDGET_ITEM_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error && action.error.error,
        status: 'creatingBudgetPlannerCategoryFailure'
      };
    case CREATE_BUDGET_ITEM:
      return {
        ...state,
        error: '',
        status: 'creatingBudgetPlannerItem'
      };
    case CREATE_BUDGET_ITEM_SUCCESS:
      return {
        ...state,
        item: action.item,
        status: 'creatingBudgetPlannerItemSuccess'
      };
    case CREATE_BUDGET_ITEM_FAILURE:
      return {
        ...state,
        item: '',
        error: action.error && action.error.error,
        status: 'creatingBudgetPlannerItemFailure'
      };
    case UPDATE_BUDGET_ITEM:
      return {
        ...state,
        error: '',
        status: 'updatingBudgetPlannerItem'
      };
    case UPDATE_BUDGET_ITEM_SUCCESS:
      return {
        ...state,
        updatedItem: action.item,
        status: 'updatingBudgetPlannerItemSuccess'
      };
    case UPDATE_BUDGET_ITEM_FAILURE:
      return {
        ...state,
        item: '',
        error: action.error && action.error.error,
        status: 'updatingBudgetPlannerItemFailure'
      };
    case EDIT_BUDGET_ITEM_CATEGORY:
      return {
        ...state,
        status: 'updatingBudgetPlannerCategory'
      };
    case EDIT_BUDGET_ITEM_CATEGORY_SUCCESS:
      return {
        ...state,
        status: 'updatingBudgetPlannerCategorySuccess'
      };
    case EDIT_BUDGET_ITEM_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error && action.error.error,
        status: 'updatingBudgetPlannerCategoryFailure'
      };
    case DELETE_BUDGET_ITEM_CATEGORY:
      return {
        ...state,
        status: 'deletingBudgetPlannerCategory'
      };
    case DELETE_BUDGET_ITEM_CATEGORY_SUCCESS:
      return {
        ...state,
        status: 'deletingBudgetPlannerCategorySuccess'
      };
    case DELETE_BUDGET_ITEM_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error && action.error.error,
        status: 'deletingBudgetPlannerCategoryFailure'
      };
    case DELETE_BUDGET_ITEM:
      return {
        ...state,
        error: '',
        status: 'deletingBudgetPlannerItem'
      };
    case DELETE_BUDGET_ITEM_SUCCESS:
      return {
        ...state,
        status: 'deletingBudgetPlannerItemSuccess'
      };
    case DELETE_BUDGET_ITEM_FAILURE:
      return {
        ...state,
        error: action.error && action.error.error,
        status: 'deletingBudgetPlannerItemFailure'
      };
    case BATCH_DELETE_BUDGET_ITEMS:
      return {
        ...state,
        error: '',
        status: 'batchDeletingBudgetPlannerItems'
      };
    case BATCH_DELETE_BUDGET_ITEMS_SUCCESS:
      return {
        ...state,
        status: 'batchDeletingBudgetPlannerItemsSuccess'
      };
    case BATCH_DELETE_BUDGET_ITEMS_FAILURE:
      return {
        ...state,
        error: action.error && action.error.error,
        status: 'batchDeletingBudgetPlannerItemsFailure'
      };
    case FETCH_BUDGET_ITEM_CATEGORIES:
      return {
        ...state,
        error: '',
        fetching: 'fetchingBudgetPlannerCategories'
      };
    case FETCH_BUDGET_ITEM_CATEGORIES_SUCCESS:
      return {
        ...state,
        status: '',
        categories: action.categories,
        fetching: 'fetchingBudgetPlannerCategoriesSuccess'
      };
    case FETCH_BUDGET_ITEM_CATEGORIES_FAILURE:
      return {
        ...state,
        status: '',
        error: action.error && action.error.error,
        fetching: 'fetchingBudgetPlannerCategoriesFailure'
      };
    case FETCH_BUDGET_ITEMS:
      return {
        ...state,
        error: '',
        fetching: 'fetchingBudgetPlannerItems'
      };
    case FETCH_BUDGET_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.items,
        fetching: 'fetchingBudgetPlannerItemsSuccess'
      };
    case FETCH_BUDGET_ITEMS_FAILURE:
      return {
        ...state,
        error: action.error && action.error.error,
        fetching: 'fetchingBudgetPlannerItemsFailure'
      };
    case SET_STATUS_FILTER:
      return {
        ...state,
        statusFilter: action.statusFilter
      };
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm
      };
    default:
      return state;
  }
};
