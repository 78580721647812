import React from 'react';
import { Normalize } from 'styled-normalize';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { TableProvider } from 'src/v2/context/Tables';
import 'react-virtualized/styles.css';

import ChangePasswordModal from './containers/ChangePasswordModal';
import DynamicValuesContainer from './containers/DynamicValuesContainer';
import Routes from './routes';

import store, { persistor } from './store';
import ErrorBoundary from './components/ErrorBoundary';
import 'react-datepicker/dist/react-datepicker.css';
import 'src/containers/AddEventModal/calendar.scss';

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ErrorBoundary>
        <DynamicValuesContainer>
          <React.Fragment>
            <Normalize />
            <ChangePasswordModal />
            <TableProvider>
              <Routes />
            </TableProvider>
          </React.Fragment>
        </DynamicValuesContainer>
      </ErrorBoundary>
    </PersistGate>
  </Provider>
);

export default App;
