import styled from 'styled-components';
import Colors from '../../styles/Colors';
import HexToRGB from '../../styles/HexToRGB';
import Screens from '../../styles/Screens';

const DropdownStyles = styled.div`
  position: relative;
  justify-content: ${props => props.justifyContent || ''};
`;

DropdownStyles.Content = styled.div`
  position: absolute;
  background-color: ${props => props.bgColor || Colors.white};
  box-shadow: 0 0 10px 0 rgba(${HexToRGB(Colors.black, true)}, 0.2);
  border-radius: 2px;
  top: ${props => props.top || 'auto'};
  bottom: ${props => props.bottom || 'auto'};
  right: ${props => props.right || 'auto'};
  left: ${props => props.left || 'auto'};
  z-index: 100;
  width: calc(100% - 20px);
  padding: 10px;
  display: ${props => (props.show ? 'block' : 'none')};

  @media (min-width: ${Screens.small}) {
    width: ${props => (props.width ? props.width : '190px')};
  }
`;

DropdownStyles.ContentHeader = styled.div`
  line-height: 34px;
  font-family: 'Lora', serif;
  font-size: 16px;
  color: ${props => props.colors || Colors.gray18};
  text-transform: capitalize;
  display: block;
  text-align: center;
  padding-bottom: 6px;
  border-bottom: 1px solid ${Colors.grayD8};
`;

DropdownStyles.ContentLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  height: ${props => props.height || ''};
  max-height: ${props => props.maxHeight || ''};
  overflow-y: scroll;
  width: 100%;
`;

DropdownStyles.ContentLinksWrapper = styled.div`
  height: 150px;
  overflow-y: scroll;
`;

DropdownStyles.Option = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  text-transform: capitalize;

  span {
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    color: #222222;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 50px;
    transition: all 250ms ease-in-out 0s;
    padding-left: ${props => (props.icon ? '35px' : '10px')};
    overflow: hidden;
    text-overflow: elipsis;
    padding-right: 10px;
    position: relative;
    white-space: nowrap;

    svg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 5px;
      margin: auto 0;
      path {
        fill: #222222;
      }
    }
  }
`;

DropdownStyles.Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px -10px -10px -10px;
`;

DropdownStyles.Input = styled.div`
  display: flex;
  position: relative;
`;

DropdownStyles.InputClear = styled.div`
  display: flex;
  position: absolute;
  top: 8px;
  right: 3px;
`;

DropdownStyles.InputButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  button {
    width: 100%;

    &:focus {
      outline: none;
      background-color: ${props =>
        props.color ? `${props.color}33` : `var(--colors-primary-lighter)`};
      color: #7d7d7d;
    }
  }
`;

DropdownStyles.ContentLink = styled.li`
  text-align: left;
  text-transform: capitalize;
  span {
    color: ${props => props.color || `var(--colors-primary)`};
    cursor: pointer;
    display: flex;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-weight: bold;
    transition: all 250ms ease-in-out 0s;
    overflow: hidden;
    text-overflow: elipsis;
    padding-left: ${props => (props.icon ? '35px' : '10px')};
    padding-right: 10px;
    position: relative;
    white-space: nowrap;
    &:hover {
      color: ${props => props.colorHover || `var(--colors-primary)`};
      background-color: ${props => props.bgColorHover || Colors.grayFA};
      svg {
        path {
          fill: ${props => props.colorHover || Colors.grayB0};
        }
      }
    }
    svg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 5px;
      margin: auto 0;
    }
  }
`;

DropdownStyles.ContentItem = styled.li`
  text-align: left;
  text-transform: capitalize;
  span {
    color: ${Colors.gray7D};
    cursor: pointer;
    display: block;
    height: 50px;
    line-height: 50px;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    transition: all 250ms ease-in-out 0s;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: ${props => (props.icon ? '35px' : '10px')};
    padding-left: 10px;
    position: relative;
    white-space: nowrap;
    &:hover {
      background-color: ${Colors.grayEE};
    }
    svg {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 5px;
      margin: auto 0 auto 20px;
      opacity: 0.3;
      transition: opacity 250ms ease-in-out 0.1s;
      &:hover {
        opacity: 1;
      }
    }
  }
`;

DropdownStyles.ContentItemTwoLine = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 50px;
`;

DropdownStyles.ContentItemTwoLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

DropdownStyles.ContentItemTwoLineMain = styled.div`
  color: ${Colors.black};
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  white-space: nowrap;
`;

DropdownStyles.ContentItemTwoLineSub = styled.div`
  color: ${Colors.gray7D};
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  white-space: nowrap;
`;

DropdownStyles.ActionableItem = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  line-height: 50px;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 250ms ease-in-out 0s;
  font-size: 11px;
  font-weight: bold;
  color: var(--colors-primary);
  &:hover {
    background-color: ${Colors.grayEE};
  }
  svg {
    margin-right: 5px;
  }
`;

DropdownStyles.SelectBtn = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: ${props => props.justifyContent || ''};
`;

export default DropdownStyles;
