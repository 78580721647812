import {
  OPEN_INVOICE_SUMMARY_DRAWER,
  CLOSE_INVOICE_SUMMARY_DRAWER,
  OPEN_MANUAL_PAYMENT_MODAL,
  CLOSE_MANUAL_PAYMENT_MODAL,
  OPEN_CREATE_INVOICE_MODAL,
  CLOSE_CREATE_INVOICE_MODAL,
  CLOSE_PAYMENT_SCHEDULE_MODAL,
  OPEN_PAYMENT_SCHEDULE_MODAL,
  OPEN_MAKE_PAYMENT_MODAL,
  CLOSE_MAKE_PAYMENT_MODAL
} from './types';
import { upcomingPayments, invoices } from './mock';

const initialState = {
  isInvoiceSummaryOpen: false,
  upcomingPayments,
  invoices,
  isManualPaymentOpen: false,
  isCreateInvoiceModalOpen: false,
  isPaymentScheduleModalOpen: false,
  isMakePaymentModalOpen: false,
  fetchingPayments: false
};

export const paymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_INVOICE_SUMMARY_DRAWER:
      return {
        ...state,
        isInvoiceSummaryOpen: true
      };
    case CLOSE_INVOICE_SUMMARY_DRAWER:
      return {
        ...state,
        isInvoiceSummaryOpen: false
      };
    case OPEN_MANUAL_PAYMENT_MODAL:
      return {
        ...state,
        isManualPaymentOpen: true
      };
    case CLOSE_MANUAL_PAYMENT_MODAL:
      return {
        ...state,
        isManualPaymentOpen: false
      };
    case OPEN_CREATE_INVOICE_MODAL:
      return {
        ...state,
        isCreateInvoiceModalOpen: true
      };
    case CLOSE_CREATE_INVOICE_MODAL:
      return {
        ...state,
        isCreateInvoiceModalOpen: false
      };
    case OPEN_PAYMENT_SCHEDULE_MODAL:
      return {
        ...state,
        isPaymentScheduleModalOpen: true
      };
    case CLOSE_PAYMENT_SCHEDULE_MODAL:
      return {
        ...state,
        isPaymentScheduleModalOpen: false
      };
    case OPEN_MAKE_PAYMENT_MODAL:
      return {
        ...state,
        isMakePaymentModalOpen: true
      };
    case CLOSE_MAKE_PAYMENT_MODAL:
      return {
        ...state,
        isMakePaymentModalOpen: false
      };
    default:
      return state;
  }
};
