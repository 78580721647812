import { takeLatest, put, call, select } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  AUTH_RESET,
  REFRESH_FAILURE,
  REFRESH_REQUEST,
  REFRESH_SUCCESS,
  POLICY_ACCEPT_SUCCESS,
  POLICY_ACCEPT_REQUEST,
  GET_EMAIL_THREADS
} from './types';

import { getEmailThreads, login, logout, policyAccept, refreshAuth } from './actions';

function* workerLogin({ email, password, venueId, eventId }) {
  try {
    const response = yield call(login, { email, password, venueId, eventId });

    const { token, refresh, expires } = response.data;

    const {
      claims,
      admin,
      role,
      uid,
      firstname,
      lastname,
      viewPermissions,
      email: userEmail,
      photo,
      venueId: jwtVenueId,
      eventId: jwtEventId,
      policyPrivacy,
      policyTerms,
      venuexAdmin
    } = jwtDecode(token);

    yield put({
      type: LOGIN_SUCCESS,
      token,
      refresh,
      expires,
      claims,
      admin,
      userEmail,
      role,
      uid,
      firstname,
      lastname,
      viewPermissions,
      photo,
      venueId: jwtVenueId,
      eventId: jwtEventId,
      policyPrivacy,
      policyTerms,
      venuexAdmin
    });
  } catch (error) {
    yield put({ type: LOGIN_FAILURE, ...error.response.data });
  }
}

function* workerLogout({ token }) {
  try {
    yield call(logout, { token });
    yield put({ type: AUTH_RESET });
  } catch (error) {
    yield put({ type: LOGOUT_FAILURE, ...error.response.data });
  }
}

function* workerRefresh(data) {
  const tToken = data.token;
  const tRefresh = data.refresh;

  try {
    const response = yield call(refreshAuth, { token: tToken, refresh: tRefresh });
    const { token, refresh, expires } = response.data;

    const {
      claims,
      admin,
      role,
      uid,
      firstname,
      lastname,
      photo,
      viewPermissions,
      venueId,
      eventId,
      policyPrivacy,
      policyTerms
    } = jwtDecode(token);

    yield put({
      type: REFRESH_SUCCESS,
      token,
      refresh,
      expires,
      claims,
      admin,
      role,
      uid,
      firstname,
      lastname,
      viewPermissions,
      photo,
      venueId,
      eventId,
      policyPrivacy,
      policyTerms
    });
  } catch (error) {
    yield put({ type: REFRESH_FAILURE, ...error.response.data });
  }
}

function* workerPolicyAccept({ token }) {
  try {
    yield call(policyAccept, { token });
    yield put({ type: POLICY_ACCEPT_SUCCESS, timestamp: Date.now() });
  } catch (error) {
    // no need to "reject" anything, there should be in place some notification for network call failures
  }
}

function* workerListEmailThreads({ userId, offset, limit }) {
  try {
    const getToken = state => state.loginReducer.token;
    const token = yield select(getToken);
    const response = yield call(getEmailThreads, { token, userId, offset, limit });

    const { threadOptions } = response.data;

    yield put({
      type: GET_EMAIL_THREADS.SUCCESS,
      threadOptions
    });
  } catch (error) {
    yield put({ type: GET_EMAIL_THREADS.failure });
  }
}

export function* watcherLogin() {
  yield takeLatest(LOGIN_REQUEST, workerLogin);
}

export function* watcherLogout() {
  yield takeLatest(LOGOUT_REQUEST, workerLogout);
}

export function* watcherRefresh() {
  yield takeLatest(REFRESH_REQUEST, workerRefresh);
}

export function* watcherPolicyAccept() {
  yield takeLatest(POLICY_ACCEPT_REQUEST, workerPolicyAccept);
}

export function* watcherGetEmailThreads() {
  yield takeLatest(GET_EMAIL_THREADS.REQUEST, workerListEmailThreads);
}
