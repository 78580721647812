import { takeLatest, put, call, all } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import {
  FETCH_GUESTS_REQUEST,
  CHANGE_USER_TABLE_REQUEST,
  GET_LAYOUT_REQUEST,
  CHANGE_LAYOUT_NAME_REQUEST,
  RESET_ALL_TABLES_REQUEST,
  FILTER_GUESTS_REQUEST,
  SUBMIT_SEATING_CHART_REQUEST,
  NEW_SEATING_CHART_REQUEST,
  UPDATE_LAYOUT_REQUEST
} from './types';
import {
  fetchGuestsSuccess,
  fetchGuestsFailure,
  changeUserTableSuccess,
  changeUserTableFailure,
  changeGroupTableSuccess,
  getLayoutError,
  getLayoutSuccess,
  updateLayoutNameSuccess,
  updateLayoutNameFailure,
  resetAllTablesFailure,
  resetAllTablesSuccess,
  filterGuestsSuccess,
  filterGuestsFailure,
  submitChartFailure,
  submitChartSuccess,
  submitNewChartFailure,
  submitNewChartSuccess,
  resetAlert,
  updateLayoutSuccess,
  updateLayoutFailure
} from './operations';
import {
  fetchGuests,
  changeUserTable,
  getLayout,
  updateLayoutName,
  resetAllTables,
  submitSeatingChartAction,
  submitNewSeatingChartAction,
  updateLayoutAction
} from './actions';
import { closeConfirmModal } from '../ConfirmModal/operations';
import { doGetGuests } from '../GuestContainer/operations';
import { getEventRequest } from '../EventsContainer/operations';

function* workerFetchGuestsRequest({ token }) {
  try {
    const response = yield call(fetchGuests, { token });
    yield put(fetchGuestsSuccess({ guests: response.data.users }));
  } catch (error) {
    yield put(fetchGuestsFailure({ error: error.response.data }));
  }
}

function* workerFilterGuestsRequest({ token, venueId, eventId, rsvp, table }) {
  try {
    const response = yield call(fetchGuests, { token, venueId, eventId, rsvp, table });
    yield put(filterGuestsSuccess({ guests: response.data.users, filterSuccess: !!table }));
  } catch (error) {
    yield put(filterGuestsFailure({ error: error.response.data }));
  }
}

function* workerChangeUserTable({ token, userId, table, group }) {
  try {
    if (Array.isArray(userId)) {
      yield all(userId.map(user => call(changeUserTable, { token, userId: user, table })));
      if (group) {
        yield put(changeGroupTableSuccess({ group, table }));
      }
    } else {
      const response = yield call(changeUserTable, { token, userId, table });
      yield put(changeUserTableSuccess({ user: response.data.guest }));
    }
    toast.success(`Party moved to table ${table}`);
  } catch (error) {
    yield put(
      changeUserTableFailure({ error: (error && error.response && error.response.data) || error })
    );
    toast.error(`Failed to move party to table ${table}`);
  }
}

function* workerGetLayout({ token, layoutId }) {
  try {
    const response = yield call(getLayout, { token, layoutId });
    const { layout } = response.data;
    yield put(getLayoutSuccess({ layout }));
  } catch (error) {
    yield put(getLayoutError({ error: error.response.data }));
  }
}

function* workerChangeLayoutName({ token, eventId, table }) {
  try {
    const response = yield call(updateLayoutName, { token, eventId, table });
    const event = response.data;
    yield put(updateLayoutNameSuccess(event));
  } catch (error) {
    yield put(updateLayoutNameFailure({ error: error.response.data }));
  }
}

function* workerResetAllTables({ token, eventId }) {
  try {
    const response = yield call(resetAllTables, { token, eventId });
    yield put(resetAllTablesSuccess({ success: response.data.success }));
    if (response.data.success) {
      yield put(closeConfirmModal());
      yield put(doGetGuests({ token, eventId, orderBy: 'name', order: 'asc' }));
    }
  } catch (error) {
    yield put(resetAllTablesFailure({ error: error.response.data }));
  }
}

function* workerSubmitSeatingChart({ token, chartData }) {
  try {
    const response = yield call(submitSeatingChartAction, { token, chartData });
    yield put(submitChartSuccess({ seatingChartData: response }));
    yield put(closeConfirmModal());
  } catch (error) {
    yield put(submitChartFailure({ error: error.response.data }));
  }
}

function* workerSubmitNewSeatingChart({ token, chartData }) {
  try {
    const response = yield call(submitNewSeatingChartAction, { token, chartData });
    yield put(submitNewChartSuccess({ data: response }));
    yield put(closeConfirmModal());
  } catch (error) {
    yield put(submitNewChartFailure({ error: error.response.data }));
  }
}

function* workerUpdateLayout({ token, eventId, layoutId }) {
  try {
    const response = yield call(updateLayoutAction, { token, eventId, layoutId });
    if (response.data.success) {
      yield put(updateLayoutSuccess({ token, layoutId }));
      yield put(getEventRequest({ token, eventId }));
    } else yield put(updateLayoutFailure());
  } catch (error) {
    yield put(updateLayoutFailure());
  }
}

export function* watchFetchGuests() {
  yield takeLatest(FETCH_GUESTS_REQUEST, workerFetchGuestsRequest);
}

export function* watchFilterGuests() {
  yield takeLatest(FILTER_GUESTS_REQUEST, workerFilterGuestsRequest);
}

export function* watchChangeUserTable() {
  yield takeLatest(CHANGE_USER_TABLE_REQUEST, workerChangeUserTable);
}

export function* watchChangeLayoutName() {
  yield takeLatest(CHANGE_LAYOUT_NAME_REQUEST, workerChangeLayoutName);
}

export function* watchGetLayout() {
  yield takeLatest(GET_LAYOUT_REQUEST, workerGetLayout);
}

export function* watchResetAllTables() {
  yield takeLatest(RESET_ALL_TABLES_REQUEST, workerResetAllTables);
}

export function* watchSubmitSeatingChart() {
  yield takeLatest(SUBMIT_SEATING_CHART_REQUEST, workerSubmitSeatingChart);
}

export function* watchSubmitNewSeatingChart() {
  yield takeLatest(NEW_SEATING_CHART_REQUEST, workerSubmitNewSeatingChart);
}

export function* watchUpdateLayout() {
  yield takeLatest(UPDATE_LAYOUT_REQUEST, workerUpdateLayout);
}
