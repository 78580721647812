export const CREATE_CUSTOM_FIELD_REQUEST = 'CREATE_CUSTOM_FIELD_REQUEST';
export const CREATE_CUSTOM_FIELD_SUCCESS = 'CREATE_CUSTOM_FIELD_SUCCESS';
export const CREATE_CUSTOM_FIELD_FAILURE = 'CREATE_CUSTOM_FIELD_FAILURE';

export const LIST_CUSTOM_FIELD_REQUEST = 'LIST_CUSTOM_FIELD_REQUEST';
export const LIST_CUSTOM_FIELD_SUCCESS = 'LIST_CUSTOM_FIELD_SUCCESS';
export const LIST_CUSTOM_FIELD_FAILURE = 'LIST_CUSTOM_FIELD_FAILURE';

export const UPDATE_CUSTOM_FIELD_REQUEST = 'UPDATE_CUSTOM_FIELD_REQUEST';
export const UPDATE_CUSTOM_FIELD_SUCCESS = 'UPDATE_CUSTOM_FIELD_SUCCESS';
export const UPDATE_CUSTOM_FIELD_FAILURE = 'UPDATE_CUSTOM_FIELD_FAILURE';

export const DELETE_CUSTOM_FIELD_REQUEST = 'DELETE_CUSTOM_FIELD_REQUEST';
export const DELETE_CUSTOM_FIELD_SUCCESS = 'DELETE_CUSTOM_FIELD_SUCCESS';
export const DELETE_CUSTOM_FIELD_FAILURE = 'DELETE_CUSTOM_FIELD_FAILURE';

export const OPEN_CUSTOM_FIELDS_DRAWER = 'OPEN_CUSTOM_FIELDS_DRAWER';
export const CLOSE_CUSTOM_FIELDS_DRAWER = 'CLOSE_CUSTOM_FIELDS_DRAWER';
