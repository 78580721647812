import { useSelector } from 'react-redux';
import { useActions } from 'src/hooks/useActions';
import {
  addCustomFieldRequest,
  getCustomFieldsRequest,
  updateCustomFieldRequest
} from 'src/v2/Collections/customFields/operations';
import { createSelector } from 'reselect';

const completedItems = createSelector(
  [
    state => state.eventsReducer.event?.fieldInputsByGroup,
    state => state.customFieldsReducer?.fieldCategoriesArray
  ],
  (inputsGroup, categories) => {
    // console.log('categories', categories);
    let totalItems = 0;

    categories?.map(caregoryName => {});
    const categoryObjects = inputsGroup?.map(category => Object.values(category));

    categoryObjects?.map(category => {
      const categoryGroupsArray = Object.values(category);

      categoryGroupsArray.map(categoryGroup => {
        const groupsArray = Object.values(categoryGroup);

        const totalFields = groupsArray.map(group => {
          totalItems += group.length;
        });
      });
    });

    // inputsGroup?.map(input => console.log(input));

    return totalItems;
  }
);

export const useCustomFields = () => {
  const {
    customFields,
    fieldCategoryOptions,
    fieldGroupOptions,
    fieldCategoriesArray,
    fieldGroupsArray,
    fieldsByCategory,
    fieldsByGroup,
    onCreateFields,
    fetchingCustomFields,
    fieldGroupObject,
    notRequiredFields,
    venueRequired,
    activeField
  } = useSelector(state => state.customFieldsReducer);
  const totalItems = useSelector(completedItems);

  const defaultValues = {
    name: '',
    label: '',
    placeholder: '',
    fieldDescription: '',
    fieldRequired: '',
    fieldCategory: '',
    defaultEventTypes: [],
    fieldType: '',
    fieldGroup: '',
    hostVisibility: true,
    hostEdit: false,
    options: []
  };

  const actions = useActions({
    addCustomFieldRequest,
    getCustomFieldsRequest,
    updateCustomFieldRequest
  });
  return {
    customFields,
    fieldCategoryOptions,
    fieldGroupOptions,
    fieldCategoriesArray,
    fieldGroupsArray,
    fieldsByCategory,
    fieldsByGroup,
    onCreateFields,
    fetchingCustomFields,
    fieldGroupObject,
    notRequiredFields,
    venueRequired,
    defaultValues,
    activeField,
    totalItems,
    ...actions
  };
};
