import { SelectInputWithLabel } from 'src/v2/UIComponents/FormComponents';
import { useVenue } from 'src/v2/Hooks/CollectionHooks';
import { EVENT_TYPE_LABEL, EVENT_TYPE_PLACEHOLDER, EVENT_TYPE_REQUIRED } from './constants';

export const EventType = ({ bookEvent }) => {
  const { eventTypeOptions } = useVenue();

  const eventTypeProps = {
    isRequired: bookEvent,
    name: 'eventType',
    placeholder: EVENT_TYPE_PLACEHOLDER,
    label: EVENT_TYPE_LABEL,
    options: eventTypeOptions,
    rules: { required: bookEvent && EVENT_TYPE_REQUIRED }
  };

  return <SelectInputWithLabel {...eventTypeProps} />;
};
