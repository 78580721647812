import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DayHeaderStyles from './styles';

const DayHeader = props => {
  let dayType = 'after';
  const day = props.day.date;
  if (moment().isSame(day, 'day')) {
    dayType = 'today';
  }
  if (moment().isAfter(day, 'day')) {
    dayType = 'before';
  }
  return <DayHeaderStyles dayType={dayType}>{props.day.label}</DayHeaderStyles>;
};

DayHeader.propTypes = {
  day: PropTypes.object
};

export default DayHeader;
