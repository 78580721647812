import {
  Flex,
  Grid,
  GridItem,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup
} from '@chakra-ui/react';
import { useVenue, useEvents } from 'src/v2/Hooks/CollectionHooks';
import { CaretDown } from 'src/v2/UIComponents/Primitives/Icons';
import { Button } from 'src/v2/UIComponents/Primitives';
import { useConfirm, useDrawers } from 'src/v2/Hooks/HelperHooks';
// import { EventDetailsCard } from './Cards/EventDetailsCard';
// import { EventFinancialsCard } from './Cards/EventFinancialsCard';
import { LeadDetailsCard } from '../Overview/Cards/LeadDetailsCard';
import { LastActivityCard } from '../Overview/Cards/LastActivityCard';
// import { validateProposalFields } from './HandleProposal/validateProposalFields';
// import { ReviewProposalBody } from './HandleProposal/ReviewProposalBody';
// import { validateContractFields } from './HandleContract/validateContractFields';
// import { ReviewContractBody } from './HandleContract/ReviewContractBody';
import { validateBookingFields } from '../BookEvent/validateBookingFields';
import { EventDetailsCard } from './Cards/EventDetailsCard';
import { LegacyPaymentsCard } from '../Overview/Cards/LegacyPaymentsCard';

// TODO: #562 Rename ContractDetails to LeadOverview and remove old LeadOverview tab references

export const ContractDetails = ({ fetchingEvent, handleTabChange }) => {
  // const { generateProposal, generateContract } = useLeads();

  const { activeEvent: lead, fetchingEvent: fetchingLead, updateEvent } = useEvents();

  const { openEventManagementDrawer, bookLeadRequest } = useDrawers();
  const disableButtons = fetchingLead;
  const { leadStatusOptions } = useVenue();

  const leadStatusLabel = leadStatusOptions?.find(status => status.value === lead?.leadInfo?.status)
    ?.displayName;

  // const { openModalLoader, updateModalLoaderMessage } = useEvents();
  // const { openConfirm: reviewProposalConfirm } = useConfirm(
  //   () => generateProposal({ eventId: lead?.id }),
  //   'reviewProposal'
  // );
  // const { openConfirm: missingProposalFieldsConfirm } = useConfirm(
  //   () => openEventManagementDrawer('lead', 'edit', 1),
  //   'missingProposalFields'
  // );

  // const { openConfirm: missingContractFieldsConfirm } = useConfirm(
  //   () => openEventManagementDrawer('lead', 'edit', 1),
  //   'missingContractFields'
  // );

  // const handleGenerateContract = () => {
  //   openModalLoader({
  //     showProgressBar: true,
  //     modalLoaderMessage: `Contract is being created for ${lead?.clientName} based on your selection.`,
  //     modalLoaderHeader: 'Creating Contract'
  //   });

  //   const update = () =>
  //     updateModalLoaderMessage({
  //       showProgressBar: true,
  //       modalLoaderMessage: 'Almost done, just a few more seconds.'
  //     });
  //   setTimeout(() => update(), 3500);
  //   generateContract({ eventId: lead?.id });
  // };

  // const { openConfirm: reviewContractConfirm } = useConfirm(
  //   () => handleGenerateContract(),
  //   'reviewContract'
  // );

  // const handleOpenProposalModal = () => {
  //   const invalidFields = validateProposalFields(lead);
  //   if (invalidFields.length) {
  //     missingProposalFieldsConfirm({
  //       title: 'Review Proposal Details ',
  //       confirmType: 'modal',
  //       height: '600px',
  //       description: <ReviewProposalBody lead={lead} missingFields />,
  //       confirmId: 'missingProposalFields',
  //       confirmButtonTitle: 'EDIT'
  //     });
  //   } else {
  //     reviewProposalConfirm({
  //       title: 'Review Proposal Details ',
  //       description: <ReviewProposalBody lead={lead} />,
  //       confirmId: 'reviewProposal'
  //     });
  //   }
  // };

  // const handleOpenContractModal = () => {
  //   // openContractModal();
  //   const invalidFields = validateContractFields(lead);
  //   if (invalidFields.length) {
  //     missingContractFieldsConfirm({
  //       title: 'Review Missing Contract Fields',
  //       confirmType: 'modal',
  //       height: '600px',
  //       description: <ReviewContractBody lead={lead} missingFields />,
  //       confirmId: 'missingContractFields',
  //       confirmButtonTitle: 'EDIT'
  //     });
  //   } else {
  //     reviewContractConfirm({
  //       title: 'Review Contract Details ',
  //       description: <ReviewContractBody lead={lead} />,
  //       confirmId: 'reviewContract',
  //       height: '600px',
  //       confirmButtonTitle: 'CREATE'
  //     });
  //   }
  // };

  type LeadStatus = string;
  const handleStatusOption = (opt: LeadStatus) => {
    if (opt === 'eventBooked') return openEventManagementDrawer('book-event', 'edit', 0);
    const { id } = lead;
    const updateData = {
      leadInfo: { status: opt }
    };
    return updateEvent({ eventId: id, data: updateData, closeAction: () => {} });
  };

  const missingBookEventFields = (lead && validateBookingFields(lead)) || true;

  const LeadStatusButton = () => {
    return (
      <Menu closeOnSelect>
        <MenuButton
          as={Button}
          variant="filterDropdown"
          rightIcon={<CaretDown />}
          width="100%"
          isDisabled={disableButtons}
          tooltip="Update Lead Status"
        >
          {`Status: ${leadStatusLabel}` || `Lead Status`}
        </MenuButton>
        <MenuList zIndex={200}>
          <MenuOptionGroup>
            {leadStatusOptions?.map(val => (
              <MenuItemOption
                key={val?.value}
                value={val.value}
                textTransform="capitalize"
                fontFamily="Lato"
                fontSize="15px"
                lineHeight="22.5px"
                onClick={e => handleStatusOption(val?.value)}
                _active={{ background: 'grayED' }}
                _focus={{ background: 'grayED' }}
                isDisabled={disableButtons}
              >
                {val?.displayName}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    );
  };

  return (
    <Flex
      flexDirection={'column'}
      height="100%"
      minH="1px"
      gap={'24px'}
      justifyContent="space-between"
    >
      <Grid templateColumns="repeat(6, 1fr)" gap={6} width="100%" minH="1px" paddingRight="12px">
        <LeadStatusButton />

        <GridItem>
          {/* <Button
            onClick={handleOpenProposalModal}
            variant="filterDropdown"
            rightIcon={<Icon as={MdOutlineFileDownload} w={5} h={5} />}
            width="100%"
            isDisabled={true}
            tooltip={ContactVenueX}
          >
            Create Proposal
          </Button> */}
        </GridItem>
        <GridItem>
          {/* <Button
            onClick={handleOpenContractModal}
            variant="filterDropdown"
            rightIcon={<Icon as={MdOutlineFileDownload} w={5} h={5} />}
            width="100%"
            isDisabled={true}
            tooltip={ContactVenueX}
          >
            Create Contract
          </Button> */}
        </GridItem>
        <GridItem />

        <Button
          onClick={() => openEventManagementDrawer('lead', 'edit', 0)}
          width="100%"
          isDisabled={disableButtons}
          variant={(!missingBookEventFields && 'outline') || undefined}
          tooltip="Edit Lead Details"
        >
          Manage Details
        </Button>

        <Button
          onClick={() => {
            bookLeadRequest();
            openEventManagementDrawer('book-event', 'edit', 0);
          }}
          width="100%"
          variant={(missingBookEventFields && 'outline') || undefined}
          tooltip={missingBookEventFields && 'Complete required fields to book this event'}
          isDisabled={disableButtons}
        >
          Book Event
        </Button>
      </Grid>

      <Grid
        templateColumns="repeat(2, 1fr)"
        templateRows="100% 100%"
        gap={4}
        minH="1px"
        height="100%"
        overflowY={'scroll'}
        // sx={{
        //   '::-webkit-scrollbar': {
        //     width: '12px'
        //   },
        //   '&:hover::-webkit-scrollbar': {
        //     width: '12px'
        //   },
        //   '&:hover::-webkit-scrollbar-track': {
        //     backgroundColor: 'rgba(221, 221, 221, 0.3)',
        //     borderRight: '3px solid #fafafa',
        //     borderLeft: '3px solid #fafafa',
        //     borderRadius: '6px'
        //   },
        //   // '&:hover ::-webkit-scrollbar-button': {
        //   //   display: 'none'
        //   // },
        //   '&:hover::-webkit-scrollbar-thumb': {
        //     backgroundColor: 'rgba(221, 221, 221, 0.8)',
        //     borderRight: '3px solid #fafafa',
        //     borderLeft: '3px solid #fafafa',
        //     borderRadius: '5.5px'
        //   }
        // }}
      >
        <GridItem>
          <LeadDetailsCard />
        </GridItem>
        <GridItem>
          <LastActivityCard handleTabChange={handleTabChange} />
        </GridItem>
        <GridItem>
          <EventDetailsCard />
        </GridItem>
        <GridItem>
          <LegacyPaymentsCard />
        </GridItem>
        {/* <GridItem>
          <EventFinancialsCard />
        </GridItem> */}
      </Grid>
    </Flex>
  );
};
