import { FC } from 'react';
import { Tooltip as ChakraTooltip, TooltipProps } from '@chakra-ui/react';

export const Tooltip: FC<TooltipProps> = ({ children, ...props }) => {
  return (
    <ChakraTooltip
      bg="black22"
      opacity={0.8}
      p="8px 21px"
      fontSize="13px"
      borderRadius="3px"
      variant="default"
      hasArrow
      // css={{ opacity: '0.9 !important' }}
      {...props}
    >
      {children}
    </ChakraTooltip>
  );
};
