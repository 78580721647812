import { OPEN_HOST_FORM_DRAWER, CLOSE_HOST_FORM_DRAWER } from 'src/v2/Reducers/HostForm/types';

export const openHostFormDrawer = ({ activeGroupFields, activeGroupName }) => ({
  type: OPEN_HOST_FORM_DRAWER,
  activeGroupFields,
  activeGroupName
});

export const closeHostFormDrawer = () => ({
  type: CLOSE_HOST_FORM_DRAWER
});
