import { useVenue } from 'src/v2/Hooks/CollectionHooks';
import { SelectInputWithLabel } from 'src/v2/UIComponents/FormComponents';
import { ACTIVITY_FORM_CONSTANTS } from '../constants';

const ActivityType = props => {
  const { ACTIVITY_TYPE } = ACTIVITY_FORM_CONSTANTS;

  const { activityTypes } = useVenue();

  const inputProps = {
    name: 'type',
    isRequired: true,
    label: ACTIVITY_TYPE.LABEL,
    placeholder: ACTIVITY_TYPE.PLACEHOLDER,
    rules: {
      required: ACTIVITY_TYPE.REQUIRED
    },
    options: activityTypes
  };
  return <SelectInputWithLabel {...inputProps} {...props} />;
};

export default ActivityType;
