import { TabPanel as ChakraTabPanel, VStack } from '@chakra-ui/react';
import Colors from 'src/styles/Colors';

export const TabPanel = ({ children, bgColor, ...props }) => (
  <ChakraTabPanel
    overflowY="scroll"
    flexDirection="column"
    h="100%"
    bg={bgColor || Colors.grayFA}
    as={VStack}
    align="stretch"
    spacing="5"
    color="black"
    {...props}
    css={{
      '&::-webkit-scrollbar': {
        width: '6px'
      },
      '&::-webkit-scrollbar-track': {
        width: '6px'
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'transparent',
        borderRadius: '6px'
      }
    }}
    _hover={{
      '&::-webkit-scrollbar-thumb': {
        background: Colors.grayCC
      }
    }}
  >
    {children}
  </ChakraTabPanel>
);
