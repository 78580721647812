import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react';
import { FieldBoxHeader } from './FieldBoxHeader';

export const FieldBox = ({ children, title, subtitle, buttonAction }) => (
  <Accordion
    allowToggle
    bg="white"
    borderRadius="8px"
    defaultIndex={[0]}
    border="1px solid #ededed"
  >
    <AccordionItem borderBottomWidth={0} borderTopWidth={0}>
      <AccordionButton
        paddingY="20px"
        paddingX="18px"
        w="100%"
        alignItems="center"
        _hover="none"
        borderRadius="8px"
      >
        <AccordionIcon w={8} h={8} color="var(--colors-primary)" mr="22px" />
        <FieldBoxHeader title={title} subtitle={subtitle} buttonAction={buttonAction} />
      </AccordionButton>
      <AccordionPanel borderTop="1px solid #ededed" padding="0px">
        {children}
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
);
