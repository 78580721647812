import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Table } from 'src/v2/Table';
import { Controls } from 'src/v2/UIComponents/Table';
import { Tag, Tooltip, IconButton } from 'src/v2/UIComponents/Primitives';
import Colors from 'src/styles/Colors';
import { useLeads } from 'src/v2/Hooks/CollectionHooks';
import { useConfirm, useDrawers } from 'src/v2/Hooks/HelperHooks';
import { ParsedLead } from 'src/containers/LeadsContainer/reducers';
import { LeadQuickEditDrawer } from 'src/v2/Drawers/LeadQuickEditDrawer';
import { MdEditNote } from 'react-icons/md';
import { useTableContext } from 'src/v2/context/Tables';
import { Filter } from 'src/v2/context/Tables/Types';
import { isEqual } from 'lodash';
import * as leadsTableUtils from './leadsTableUtils';

export const LeadsTable = ({
  leadStatusOptions,
  handleEditClick,
  handleOpenDetails,
  handleOpenLeadsForm,
  isFetchingLeads,
  showBooked = false
}) => {
  // Hooks
  const { deleteLead, isLeadsDetailsOpen, tableLeads } = useLeads();
  const { filters: contextFilters, filterData, getData } = useTableContext();
  const {
    openQuickEditLeadDrawer,
    setLeadToQuickEdit,
    quickEditLeadDrawerOpen,
    leadToQuickEdit
  } = useDrawers();
  const { openConfirm } = useConfirm({
    acceptAction: () => deleteLead({ id: leadToDelete }),
    confirmHookId: 'deleteLead'
  });

  // State
  const [leadToDelete, setLeadToDelete] = useState();
  const [renderTable, setRenderTable] = useState(true);
  const [savedFilters, setSavedFilters] = useState<Filter[]>([]);

  // Refs
  const prevFiltersRef = useRef<Filter[]>();
  const leadsRef = useRef<any>([]);

  // Memoized values
  const memoizedFilters = useMemo(() => contextFilters, [contextFilters]);
  const leads = useMemo(() => {
    return leadsTableUtils.filterLeads(tableLeads);
  }, [tableLeads]);

  // Effects
  useEffect(() => {
    // Control table rendering based on lead details visibility
    setRenderTable(!isLeadsDetailsOpen);
  }, [isLeadsDetailsOpen]);

  useEffect(() => {
    // Update saved filters when context filters change
    if (memoizedFilters && !isEqual(memoizedFilters, prevFiltersRef.current)) {
      setSavedFilters(memoizedFilters);
      prevFiltersRef.current = memoizedFilters;
    }
  }, [memoizedFilters]);

  const applyFilters = useCallback(() => {
    if (savedFilters.length > 0) {
      savedFilters.forEach(filter => {
        filterData({
          filterBy: filter.filterBy,
          filterKey: filter.filterKey,
          filterType: filter.filterType
        });
      });
    }
  }, [savedFilters, filterData]);

  useEffect(() => {
    if (savedFilters.length > 0) {
      applyFilters();
    }
  }, [leads, savedFilters]);

  useEffect(() => {
    if (leads && !isEqual(leads, leadsRef.current)) {
      leadsRef.current = leads;
      getData(leads, 'leadstable', true); // Update allData in TableProvider
    }
  }, [tableLeads, getData, applyFilters]);

  useEffect(() => {
    // Apply filters when table is rendered and there are saved filters
    if (renderTable && savedFilters.length > 0) {
      applyFilters();
    }
  }, [renderTable, savedFilters]);

  // Helper functions

  const handleDelete = ({ id }) => {
    setLeadToDelete(id);
    openConfirm({
      title: `Delete Lead`,
      description: 'Are you sure you want to delete this lead? All saved details will be lost.',
      confirmId: 'deleteLead',
      confirmType: 'cancel',
      confirmButtonTitle: 'DELETE'
    });
  };

  const quickEditAction = (e: { rowData: ParsedLead }) => {
    const cellData = e.rowData;
    openQuickEditLeadDrawer(cellData);
    if (quickEditLeadDrawerOpen) {
      setLeadToQuickEdit(cellData);
    } else {
      openQuickEditLeadDrawer(cellData);
    }
  };

  const columns = [
    {
      label: '',
      size: 'sm',
      align: 'left',
      cellRenderer: ({ rowData }: { rowData: ParsedLead }) => {
        const status = rowData?.leadInfo?.status;
        const isBooked = status === 'eventBooked';
        return (
          !isBooked && (
            <Tooltip label="Quick Edit" aria-label="Quick Edit" placement="left" shouldWrapChildren>
              <IconButton
                size="sm"
                as={MdEditNote}
                variant="link"
                aria-label="quick-edit"
                cursor={'pointer'}
                color={(leadToQuickEdit?.id === rowData.id && Colors.green2C) || ''}
                onClick={() => quickEditAction({ rowData })}
              />
            </Tooltip>
          )
        );
      }
    },
    {
      label: 'Name',
      size: 'lg',
      align: 'left',
      dataKey: 'clientName'
    },
    {
      label: 'Status',
      size: 'lg',
      align: 'left',
      dataKey: 'leadStatusNumber',
      cellDataGetter: ({ rowData }) => ({
        status: rowData?.status
      }),
      cellRenderer: ({ cellData }) => {
        const { status } = cellData;
        const { title, color } = leadsTableUtils.getLeadTagInformation(status, leadStatusOptions);
        return <Tag title={title} bg={color} />;
      }
    },
    {
      label: 'Event Date',
      size: 'lg',
      align: 'left',
      dateFormat: 'short',
      componentType: 'date',
      dataKey: 'start'
    },
    {
      label: 'Sales Person',
      size: 'lg',
      align: 'left',
      dataKey: 'salespersonName'
    },
    {
      label: 'Source',
      size: 'lg',
      align: 'left',
      dataKey: 'source',
      cellDataGetter: ({ rowData }) => {
        const sourceLabel = rowData?.leadInfo?.sourceLabel;
        return { sourceLabel };
      },
      cellRenderer: ({ cellData }) => {
        return <p>{cellData?.sourceLabel}</p>;
      }
    },
    {
      label: 'Received',
      size: 'lg',
      align: 'left',
      dataKey: 'leadDate',
      componentType: 'date'
    },
    {
      label: 'Phone',
      size: 'lg',
      align: 'left',
      dataKey: 'phone'
    },
    {
      label: 'Actions',
      size: 'lg',
      disableSort: true,
      cellDataGetter: ({ rowData }) => ({ ...rowData }),
      cellRenderer: ({ cellData }) => (
        <Controls
          editProps={false}
          deleteProps={{
            onClick: e => {
              e.stopPropagation();
              handleDelete({ id: cellData.id });
            }
          }}
          openDetailsProps={{ onClick: () => handleOpenDetails({ cellData }) }}
        />
      )
    }
  ];

  const emptyState = {
    title: 'No leads added yet.',
    subTitle: 'Add one now!',
    btnAction: handleOpenLeadsForm,
    btnLabel: 'Add New Lead'
  };

  return (
    <>
      {renderTable && (
        <Table
          columns={columns}
          data={leads}
          emptyStateProps={emptyState}
          isLoading={isFetchingLeads}
          tableId="leadstable"
        />
      )}
      <LeadQuickEditDrawer fullSize={false} showBooked={showBooked} />
    </>
  );
};
