export const EVENT_FAILURE = 'EVENT_FAILURE';
export const EVENT_SUCCESS = 'EVENT_SUCCESS';
export const EVENT_REQUEST = 'EVENT_REQUEST';
export const NOTES_FAILURE = 'NOTES_FAILURE';
export const NOTES_SUCCESS = 'NOTES_SUCCESS';
export const NOTES_REQUEST = 'NOTES_REQUEST';
export const NOTE_CREATE_FAILURE = 'NOTE_CREATE_FAILURE';
export const NOTE_CREATE_SUCCESS = 'NOTE_CREATE_SUCCESS';
export const NOTE_CREATE_REQUEST = 'NOTE_CREATE_REQUEST';
export const NOTE_DELETE_FAILURE = 'NOTE_DELETE_FAILURE';
export const NOTE_DELETE_SUCCESS = 'NOTE_DELETE_SUCCESS';
export const NOTE_DELETE_REQUEST = 'NOTE_DELETE_REQUEST';
export const EVENT_PHOTO_EDIT_REQUEST = 'EVENT_PHOTO_EDIT_REQUEST';
export const EVENT_PHOTO_EDIT_FAILURE = 'EVENT_PHOTO_EDIT_FAILURE';
export const EVENT_PHOTO_EDIT_SUCCESS = 'EVENT_PHOTO_EDIT_SUCCESS';
export const CLOUD_IMAGE_UPLOAD_REQUEST = 'CLOUD_IMAGE_UPLOAD_REQUEST';
export const CLOUD_IMAGE_UPLOAD_FAILURE = 'CLOUD_IMAGE_UPLOAD_FAILURE';
export const CLOUD_IMAGE_UPLOAD_SUCCESS = 'CLOUD_IMAGE_UPLOAD_SUCCESS';
export const IDEAS_REQUEST = 'IDEAS_REQUEST';
export const IDEAS_SUCCESS = 'IDEAS_SUCCESS';
export const IDEAS_FAILURE = 'IDEAS_FAILURE';
export const EVENT_IDEA_CREATE_REQUEST = 'EVENT_IDEA_CREATE_REQUEST';
export const EVENT_IDEA_CREATE_FAILURE = 'EVENT_IDEA_CREATE_FAILURE';
export const EVENT_IDEA_CREATE_SUCCESS = 'EVENT_IDEA_CREATE_SUCCESS';
export const EVENT_IDEA_EDIT_REQUEST = 'EVENT_IDEA_EDIT_REQUEST';
export const EVENT_IDEA_EDIT_FAILURE = 'EVENT_IDEA_EDIT_FAILURE';
export const EVENT_IDEA_EDIT_SUCCESS = 'EVENT_IDEA_EDIT_SUCCESS';
export const EVENT_IDEA_DELETE_REQUEST = 'EVENT_IDEA_DELETE_REQUEST';
export const EVENT_IDEA_DELETE_FAILURE = 'EVENT_IDEA_DELETE_FAILURE';
export const EVENT_IDEA_DELETE_SUCCESS = 'EVENT_IDEA_DELETE_SUCCESS';
export const VENUE_STAFF_REQUEST = 'VENUE_STAFF_REQUEST';
export const VENUE_STAFF_FAILURE = 'VENUE_STAFF_FAILURE';
export const VENUE_STAFF_SUCCESS = 'VENUE_STAFF_SUCCESS';
export const EVENT_CONTACTS_UPDATE_REQUEST = 'EVENT_CONTACTS_UPDATE_REQUEST';
export const EVENT_CONTACTS_UPDATE_FAILURE = 'EVENT_CONTACTS_UPDATE_FAILURE';
export const EVENT_CONTACTS_UPDATE_SUCCESS = 'EVENT_CONTACTS_UPDATE_SUCCESS';
export const NOTES_CLEAN = 'NOTES_CLEAN';
export const CLEAR_IMAGE_UPLOAD = 'CLEAR_IMAGE_UPLOAD';
export const OPEN_ADD_CONTACT_MODAL = 'OPEN_ADD_CONTACT_MODAL';
export const CLOSE_ADD_CONTACT_MODAL = 'CLOSE_ADD_CONTACT_MODAL';
export const RESET_ADDED_CONTACT = 'RESET_ADDED_CONTACT';
export const NOTE_EDIT_REQUEST = 'NOTE_EDIT_REQUEST';
export const NOTE_EDIT_FAILURE = 'NOTE_EDIT_FAILURE';
export const NOTE_EDIT_SUCCESS = 'NOTE_EDIT_SUCCESS';
export const RESET_EDITED_NOTE = 'RESET_EDITED_NOTE';
export const PROFILE_AVATAR_EDIT_REQUEST = 'PROFILE_AVATAR_EDIT_REQUEST';
export const PROFILE_AVATAR_EDIT_FAILURE = 'PROFILE_AVATAR_EDIT_FAILURE';
export const PROFILE_AVATAR_EDIT_SUCCESS = 'PROFILE_AVATAR_EDIT_SUCCESS';
