const HexToRGB = (hexInput, join = false) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const hex = hexInput.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    const rgb = [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];

    return join ? rgb.join(',') : rgb;
  }

  return null;
};

export default HexToRGB;
