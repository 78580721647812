import { Grid, GridItem } from '@chakra-ui/react';
import { useUser, useVenue } from 'src/v2/Hooks/CollectionHooks';
import { Filter } from 'src/v2/UIComponents/Toolbar/Filter';
import { Button } from 'src/v2/UIComponents/Primitives';
import Colors from 'src/styles/Colors';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTableContext } from 'src/v2/context/Tables';
import { EventTypeLabel, FilterLabel, LeadLabel } from './labels';

export const RootFilterBar = ({ expanded }) => {
  const [eventFilterOptions, setEventFilterOptions] = useState();
  const [appointmentFilterOptions, setAppointmentFilterOptions] = useState();
  const [leadStatusFilterOptions, setLeadStatusFilterOptions] = useState();

  const {
    staffUsers,
    eventTypes,
    appointmentTypes,
    fetchingFireStore,
    leadStatusOptions
  } = useVenue();
  const { filterData, clearFilter, filters } = useTableContext();

  useEffect(() => {
    if (!fetchingFireStore && eventTypes && appointmentTypes) {
      const eventTypesWithLead = [
        ...eventTypes,
        {
          value: 'lead',
          label: <LeadLabel />
        }
      ];
      const appointmentTypeFilters = appointmentTypes?.map(apptType => {
        return {
          value: apptType.id,
          label: <FilterLabel apptType={apptType} />
        };
      });
      const eventTypeFilters = eventTypesWithLead?.map(eventType => {
        if (eventType.value === 'lead') return eventType;
        return {
          ...eventType,
          label: <EventTypeLabel eventType={eventType} />
        };
      });
      const leadStatusFilters = leadStatusOptions
        .map(status => {
          return {
            ...status,
            label: status.displayName
          };
        })
        ?.sort((a, b) => a.order - b.order);
      setEventFilterOptions(eventTypeFilters);
      setAppointmentFilterOptions(appointmentTypeFilters);
      setLeadStatusFilterOptions(leadStatusFilters);
    }
  }, [fetchingFireStore, eventTypes, appointmentTypes, leadStatusOptions]);

  const { userId } = useUser();
  const [selectedMy, setSelectedMy] = useState(false);

  useEffect(() => {
    const consultantFilterObject = filters?.find(fltr => fltr.filterKey === 'attendees');
    const consultantFilterLength = consultantFilterObject?.filterBy?.length;
    if (consultantFilterLength === 1) {
      const activeFilter = consultantFilterObject?.filterBy[0];
      if (activeFilter === userId) {
        setSelectedMy(true);
      }
    }
  }, [filters, userId]);

  const staffFilters = staffUsers?.map(staff => ({
    label: `${staff?.firstname} ${staff.lastname}`,
    value: staff?.id
  }));

  // TODO: Calendar Updates: read venue event types for type options

  const showMyEvents = () => filterData({ filterBy: [userId], filterKey: 'attendees' });
  const clearMyEvents = () => clearFilter('attendees');

  const handleMyEventsClick = () => {
    if (selectedMy) {
      clearMyEvents();
      setSelectedMy(false);
    } else if (!selectedMy) {
      showMyEvents();
      setSelectedMy(true);
    }
  };

  const ready = !fetchingFireStore && eventFilterOptions && appointmentFilterOptions;

  return (
    (
      <AnimatePresence>
        {ready && expanded && (
          <motion.div
            key="filtertool"
            style={{ overflow: 'hidden' }}
            initial={{ height: '0px' }}
            animate={{ height: '100px' }}
            exit={{ height: '0px' }}
            transition={{ duration: 0.5 }}
          >
            <Grid
              templateColumns="repeat(6, 1fr)"
              gap={6}
              height={!expanded && '0px'}
              overflow={!expanded && 'hidden'}
              px="30px"
              py={expanded && '16px'}
              borderTop={expanded && `1px solid ${Colors.grayED}`}
              borderBottom={`1px solid ${Colors.grayED}`}
              backgroundColor={Colors.white}
            >
              <GridItem>
                <Button
                  variant={(selectedMy && 'primaryOutline') || 'primary'}
                  width="100%"
                  paddingX="20px"
                  onClick={handleMyEventsClick}
                >
                  {(selectedMy && 'Showing my events') || 'Show my events'}
                </Button>
              </GridItem>

              <GridItem>
                <Filter
                  title="Event Type"
                  dataKey="eventType"
                  filterList={{
                    groups: [
                      { options: eventFilterOptions, title: 'Event Type' },
                      { options: appointmentFilterOptions, title: 'Appointment Type' }
                    ]
                  }}
                  width="100%"
                />
              </GridItem>
              <GridItem>
                <Filter
                  title="Organizer"
                  dataKey="organizer"
                  filterList={staffFilters}
                  width="100%"
                />
              </GridItem>
              <GridItem>
                <Filter title="Staff" dataKey="attendees" filterList={staffFilters} width="100%" />
              </GridItem>
              <GridItem>
                <Filter
                  title="Lead Status"
                  dataKey="status"
                  filterList={leadStatusFilterOptions}
                  width="100%"
                />
              </GridItem>
              <GridItem>
                <Filter
                  title="Salesperson"
                  dataKey="salesperson"
                  filterList={staffFilters}
                  width="100%"
                />
              </GridItem>
            </Grid>
          </motion.div>
        )}
      </AnimatePresence>
    ) || <div />
  );
};

export const FilterBar = React.memo(RootFilterBar);
