import { useSelector, useDispatch } from 'react-redux';
import {
  getEvents,
  clearActiveEvent,
  deleteEvent,
  getEventRequest,
  updateChecklistItemRequest,
  openCreateEventForm,
  closeCreateEventForm,
  getEventAnalytics,
  getEventTasksRequest
} from 'src/containers/EventsContainer/operations';
import {
  addEventRequest,
  checkEventCodeRequest,
  sendEventEmailOperations,
  setLeadTypeRequest,
  updateEventRequest
} from 'src/containers/AddEventModal/operations';
import {
  doRequestEventPacket,
  openEventModalRequest,
  sendVenueToHostEmailRequest,
  setPaymentStatusRequest
} from 'src/containers/EventDetailsModal/operations';
import {
  updateModalLoaderMessage,
  closeModalLoader,
  openModalLoader
} from 'src/containers/ModalLoader/operations';
import { useActions } from 'src/hooks/useActions';
import {
  handleUpdateEventData,
  // getDefaultValues,
  parseEventFormData
} from 'src/v2/utils/parsers/events';
import { doRemind } from 'src/containers/ForgotPassword/operations';
import { RootStates, UseEventsHookReturn } from 'src/Types/GlobalTypes';
import { toast } from 'react-hot-toast';

type UseEventsHook = () => UseEventsHookReturn;

export const useEvents = () => {
  const dispatch = useDispatch();
  const eventsState = useSelector((state: RootStates) => state.eventsReducer);
  const { token, venueId = '', uid: userId } = useSelector(
    (state: RootStates) => state.loginReducer
  );
  const { isEventDetailsDrawerOpen } = useSelector(
    (state: RootStates) => state.eventDetailsModalReducer
  );

  const eventFormState = useSelector((state: RootStates) => state.addEventModalReducer);
  const {
    addingEvent,
    addEventSuccess,
    hostsCreated,
    eventDocumentsCreated,
    updating,
    updateEventSuccess,
    sendingEventEmail,
    duplicateIdUpdating
  } = eventFormState;
  const {
    event: activeEvent,
    creatingHosts,
    fetchingEvent,
    events,
    monthStart,
    monthEnd,
    isCreateEventFormOpen,
    appointments,
    threadOptions,
    fetchingEmailThreads,
    detailedEventList,
    fetchingAnalytics,
    userTasks
  } = eventsState;

  const { id: activeEventId, checkListGroups } = activeEvent || {};

  const createEvent = data => {
    const eventObject = parseEventFormData(data);
    dispatch(addEventRequest({ token, venueId, event: eventObject, monthStart, monthEnd }));
  };

  const getThisMonthsEvents = () => {
    if (!token) toast.error('You must be logged in to view this page');
    token && dispatch(getEvents({ token, monthStart, monthEnd }));
  };

  const updateEvent = ({
    data,
    eventId: eId,
    isAppointment,
    closeAction,
    isLead,
    navigate,
    getEventsWithLead
  }: {
    data: any;
    eventId: any;
    isAppointment?: boolean;
    closeAction?: () => void;
    isLead?: boolean;
    navigate?: any;
    getEventsWithLead?: boolean;
  }) => {
    const updatedEventObject = handleUpdateEventData(data);
    dispatch(
      updateEventRequest({
        token,
        event: updatedEventObject,
        id: eId,
        venueId,
        isAppointment,
        closeAction,
        isLead,
        monthStart,
        monthEnd,
        navigate,
        getEventsWithLead
      })
    );
  };

  const downloadEventPacket = ({
    eventId = '',
    updateModalLoaderMessage: modalLoaderUpdateMessage = '',
    closeModalLoader: modalLoaderClose
  }) => {
    const event = events.find(row => row.id === eventId) || { layout: '' };
    const { layout } = event;
    if (!token) throw new Error('Token is required');
    dispatch(
      doRequestEventPacket({
        token,
        eventId,
        venueId,
        layoutId: layout,
        updateModalLoaderMessage: modalLoaderUpdateMessage,
        closeModalLoader: modalLoaderClose
      })
    );
  };

  const resendWelcomeEmail = email => {
    dispatch(doRemind({ venueId, eventId: activeEventId, email }));
  };

  const setPaymentStatus = (status, eId) => {
    dispatch(setPaymentStatusRequest({ token, eventId: eId, status }));
  };

  // const defaultValues = getDefaultValues({
  //   firstname,
  //   lastname,
  //   userId,
  //   lockLayoutDays,
  //   guestMobileApp, // do we need this in default values?
  //   formValues,
  //   customFields,
  //   activeEvent
  // });

  // deleteEvent:({  id: deleteId }) => {
  //   dispatch(deleteEvent({ token, id: deleteId, consultantId: userId }))

  const actions = useActions({
    setLeadTypeRequest,
    addEvent: ({ event }) => addEventRequest({ token, venueId, event }),
    getEventTasksRequest,
    clearActiveEvent,
    setPaymentStatus,
    sendVenueToHostEmailRequest,
    getEvents,
    getEventAnalytics,
    checkEventCodeRequest,
    getEvent: ({ eventId }) => getEventRequest({ token, eventId }),
    deleteEvent: ({ id: deleteId }) =>
      deleteEvent({
        id: deleteId,
        consultantId: userId,
        monthStart,
        monthEnd
      }),
    openModalLoader,
    updateModalLoaderMessage,
    closeModalLoader,
    updateChecklistItemRequest,
    openEventModalRequest,
    openCreateEventForm,
    closeCreateEventForm,
    sendEventEmail: ({ recipient, cc, bcc, body, subject, attachments, eventId }) =>
      sendEventEmailOperations.request({ recipient, cc, bcc, body, subject, attachments, eventId })

    // requestEventPacket: () =>
    //   doRequestEventPacket({
    //     token,
    //     venueId,
    //     layoutId,
    //     canvas,
    //     updateModalLoaderMessage,
    //     closeModalLoader,
    //     layoutType
    //   })
  });

  return {
    ...actions,
    userTasks,
    activeEvent,
    activeEventId,
    addEventSuccess,
    addingEvent,
    appointments,
    createEvent,
    creatingHosts,
    eventsState,
    hostsCreated,
    updateEvent,
    // defaultValues,
    fetchingEvent,
    eventDocumentsCreated,
    checkListGroups,
    downloadEventPacket,
    resendWelcomeEmail,
    updating,
    updateEventSuccess,
    isEventDetailsDrawerOpen,
    isCreateEventFormOpen,
    monthStart,
    monthEnd,
    fetchingEmailThreads,
    sendingEventEmail,
    threadOptions,
    detailedEventList,
    fetchingAnalytics,
    getThisMonthsEvents,
    events,
    duplicateIdUpdating
  };
};
