import { useEffect, useMemo, useState } from 'react';
import { Flex, HStack, Icon } from '@chakra-ui/react';
import { BiRefresh } from 'react-icons/bi';
import AppContainer from 'src/v2/UIComponents/AppContainer';
import { Card, CardHeader, CardBody, Button } from 'src/v2/UIComponents/Primitives';
import { EventDetails } from 'src/v2/Drawers';
import { debounce } from 'lodash';
import Search from 'src/components/Search';
import { useLeads, useVenue, useRooms, useCustomFields } from 'src/v2/Hooks/CollectionHooks';
import { LeadsDetails as LeadsDetailsDrawer } from 'src/v2/Drawers/LeadsDetails';
import { TasksDropdown } from 'src/v2/UIComponents/Primitives/ButtonDropdown/TasksDropdown';
import { EventManagementDrawer } from 'src/v2/Drawers/EventManagementDrawer/EventManagementDrawer';
import Colors from 'src/styles/Colors';
import { ToolbarAction } from 'src/v2/UIComponents/Toolbar';
import { useTableContext } from 'src/v2/context/Tables';
import { useDrawers } from 'src/v2/Hooks/HelperHooks';
import { FilterBar } from './FiltersBar';
import { LeadsTable } from './LeadsTable';
import { MiniCardsSection } from './MiniCardsSection';
import { useEvents } from '../../Hooks/CollectionHooks/Events/useEvents';

const Leads = () => {
  const {
    getLeadsRequest,

    openLeadsDetails,
    closeLeadsDetails,
    leadStatusOptions,

    fetchingLeads
  } = useLeads();

  const [showBooked, setShowBooked] = useState(false);

  const { openEventManagementDrawer } = useDrawers();

  const { getEvent, getEventTasksRequest } = useEvents();

  const { filterData, filters, tableData } = useTableContext();
  const [searchTerm, setSearchTerm] = useState('');
  const { getStaff, getVenue } = useVenue();
  const { getRooms } = useRooms();
  const { getCustomFieldsRequest } = useCustomFields();
  const disableButtons = fetchingLeads;

  useEffect(() => {
    getLeadsRequest();
    getStaff();
    getRooms();
    getVenue();
    getCustomFieldsRequest();
    getEventTasksRequest();

    return () => {
      closeLeadsDetails();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!filters?.length) {
      setSearchTerm('');
    }
  }, [filters]);

  useEffect(() => {
    if (showBooked) {
      getLeadsRequest({ getEvents: true });
    }
  }, [showBooked]);

  const debouncedSearch = debounce(filterData, 600);

  const handleOnChangeSearch = value => {
    setSearchTerm(value);
    debouncedSearch({
      filterBy: value,
      filterKey: 'clientName',
      filterType: 'search'
    });
  };

  const leftActions: ToolbarAction[] = [
    {
      type: 'custom',
      component: (
        <Search
          width="350px"
          placeholder={`Search ${(filters?.length && `${tableData?.length} Filtered`) ||
            ''} Lead${(tableData?.length > 1 && 's') || ''}`}
          handleChange={e => handleOnChangeSearch(e.target.value)}
          value={searchTerm}
        />
      )
    }
  ];

  const rightActions: ToolbarAction[] = [
    {
      type: 'custom',
      component: <TasksDropdown />
    }
  ];

  const toolbarProps = { leftActions, rightActions };

  const handleEditClick = ({ cellData }) => {
    if (cellData) {
      getEvent({ eventId: cellData.id });
      openEventManagementDrawer('lead', 'edit');
    }
  };

  const handleOpenDetails = ({ cellData }) => {
    if (cellData) {
      getEvent({ eventId: cellData.id });
      openLeadsDetails();
    }
  };

  const ButtonLabel = () => {
    return (
      <HStack spacing={2}>
        <Button leftIcon={<Icon as={BiRefresh} />} variant="outline">
          Refresh Leads
        </Button>
        <Button
          variant={showBooked ? 'primary' : 'outline'}
          onClick={() => setShowBooked(!showBooked)}
        >
          {showBooked ? 'Hide' : 'Show'} Booked Events
        </Button>
      </HStack>
    );
  };
  return (
    <>
      <AppContainer toolbarProps={toolbarProps} isCalendar={undefined} withPadding={undefined}>
        <Flex flex={1} flexDir="column">
          <MiniCardsSection />
          <FilterBar
            onAddLeadClick={() => openEventManagementDrawer('lead', 'create')}
            disableButtons={disableButtons}
          />
          <Card flex={1}>
            <CardHeader
              paddingLeft="51px"
              title="Leads Overview"
              paddingY="20px"
              borderBottom={`1px solid ${Colors.grayED}`}
              iconTitle={undefined}
              rightActions={undefined}
              btnLabel={<ButtonLabel />}
              btnAction={getLeadsRequest}
              btnIcon={undefined}
            />
            <CardBody paddingTop={0} paddingX={0}>
              <LeadsTable
                showBooked={showBooked}
                handleEditClick={handleEditClick}
                handleOpenDetails={handleOpenDetails}
                leadStatusOptions={leadStatusOptions}
                handleOpenLeadsForm={() => openEventManagementDrawer('lead', 'create')}
                isFetchingLeads={fetchingLeads}
              />
            </CardBody>
          </Card>
        </Flex>
      </AppContainer>
      <LeadsDetailsDrawer />
      <EventManagementDrawer addLead />
      <EventDetails />
    </>
  );
};
export default Leads;
