import React from 'react';

const Store = props => {
  const sideBarIconColor = props.sideBarIcon
    ? 'var(--colors-sideBarText)'
    : 'var(--colors-primary)';
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 26 23">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={props.color ? props.color : sideBarIconColor} transform="translate(-1189 -119)">
          <g transform="translate(1189 119)">
            <path d="M17.448 21.493V16.02a.756.756 0 00-.757-.754H9.348a.756.756 0 00-.758.754v5.473H4.37V13.85a3.793 3.793 0 002.5-1.515 3.826 3.826 0 003.088 1.572c1.25 0 2.353-.615 3.048-1.552a3.789 3.789 0 003.047 1.552 3.79 3.79 0 003.062-1.57 3.787 3.787 0 002.526 1.516v7.64h-4.193zm-7.343 0h5.827v-4.72h-5.827v4.72zM1.642 10.848H5.97A2.295 2.295 0 013.806 12.4a2.295 2.295 0 01-2.164-1.552zM4.57 1.986a.735.735 0 01.677-.48H7.79L6.258 9.342H1.845L4.57 1.986zm16.869.02l2.726 7.335h-4.413L18.22 1.507h2.544c.299 0 .563.195.676.5zm-3.222 8.842a2.293 2.293 0 01-2.164 1.552 2.298 2.298 0 01-2.165-1.552h4.329zm-6.095 0A2.297 2.297 0 019.958 12.4a2.323 2.323 0 01-2.192-1.552h4.356zm.125-1.507H7.8l1.534-7.834h2.912V9.34zm1.516-7.834h2.913l1.533 7.834h-4.446V1.507zM22.177 12.4a2.295 2.295 0 01-2.165-1.552h4.33a2.297 2.297 0 01-2.165 1.552zm2.66 9.093h-1.68v-7.73a3.8 3.8 0 002.806-3.465.742.742 0 000-.465L22.86 1.487A2.252 2.252 0 0020.763 0H5.247c-.92 0-1.761.582-2.095 1.457L.048 9.834C.04 9.856.045 9.88.04 9.9c-.017.065-.04.126-.04.195 0 .028.008.054.009.08a3.804 3.804 0 002.846 3.596v7.722h-1.68a.755.755 0 00-.758.754c0 .416.338.753.757.753h23.663a.756.756 0 00.758-.753.756.756 0 00-.758-.754z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

Store.defaultProps = {
  size: 24
};

export default Store;
