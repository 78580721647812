import { TextFieldWithLabel } from 'src/v2/UIComponents/FormComponents';
import { EVENT_TASK_FORM_CONSTANTS } from '../constants';

export const EventTaskName = props => {
  const { EVENT_TASK_NAME } = EVENT_TASK_FORM_CONSTANTS;
  const inputProps = {
    name: 'name',
    isRequired: true,
    label: EVENT_TASK_NAME.LABEL,
    placeholder: EVENT_TASK_NAME.PLACEHOLDER,
    rules: {
      required: EVENT_TASK_NAME.REQUIRED
    }
  };
  return <TextFieldWithLabel {...inputProps} {...props} />;
};
