import { TextFieldWithLabel } from 'src/v2/UIComponents/FormComponents';

export const ProspectStreetAddress = props => {
  const billingFirstNameProps = {
    name: 'leadInfo.streetAddress',
    label: 'Address',
    placeholder: 'Client Address'
  };
  return <TextFieldWithLabel {...billingFirstNameProps} {...props} />;
};
