import { Icon } from '@chakra-ui/react';

export const EventType = props => {
  const { width = 8, height = 6, color } = props;

  return (
    <Icon width={width} height={height} viewBox="0 0 36 26" {...props}>
      <path
        d="M32.604 0.908081H3.404C1.8 0.908081 0.508 2.21208 0.508 3.81208V5.12408H0.5V22.1881C0.5 23.7801 1.8 25.0841 3.396 25.0841H32.596C34.2 25.0841 35.5 23.7841 35.5 22.1881V3.81208C35.5 2.20808 34.196 0.908081 32.604 0.908081ZM16.86 12.1961C16.86 11.9521 17.068 11.7401 17.316 11.7401H26.348C26.604 11.7401 26.804 11.9481 26.804 12.1961V13.4161C26.804 13.6601 26.604 13.8721 26.348 13.8721H17.316C17.072 13.8721 16.86 13.6641 16.86 13.4161V12.1961ZM14.896 16.9041C14.896 17.1601 14.688 17.3601 14.44 17.3601H5.708C5.464 17.3601 5.252 17.1601 5.252 16.9041V9.09208C5.252 8.83608 5.46 8.63608 5.708 8.63608H14.44C14.684 8.63608 14.896 8.83608 14.896 9.09208V16.9041ZM28.852 16.9041C28.852 17.1601 28.652 17.3601 28.396 17.3601H17.316C17.072 17.3601 16.86 17.1601 16.86 16.9041V15.6921C16.86 15.4361 17.068 15.2361 17.316 15.2361H28.396C28.652 15.2361 28.852 15.4361 28.852 15.6921V16.9041ZM30.748 10.3041C30.748 10.5601 30.548 10.7601 30.292 10.7601H17.316C17.072 10.7601 16.86 10.5601 16.86 10.3041V9.09208C16.86 8.83608 17.068 8.63608 17.316 8.63608H30.292C30.548 8.63608 30.748 8.83608 30.748 9.09208V10.3041Z"
        fill={color ?? 'currentColor'}
      />
    </Icon>
  );
};
