import JsPDF from 'jspdf';

export const createPdfFile = async (
  imageData,
  title,
  layoutName,
  orientation,
  updatePDFMessage,
  closeModal
) => {
  if (updatePDFMessage) await updatePDFMessage();
  const pdf = new JsPDF({
    orientation,
    format: 'letter'
  });
  pdf.addImage(
    imageData,
    'PNG',
    0,
    0,
    pdf.internal.pageSize.getWidth(),
    pdf.internal.pageSize.getHeight()
  );
  console.log('saving');
  pdf.save(`${title}-(${layoutName})`, { returnPromise: true }).then(() => console.log('done'));
};
