import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

// eslint-disable-next-line import/prefer-default-export
export const registerUser = ({ user, token }) => {
  return axios({
    method: 'post',
    url: `${apiUri}/users`,
    headers: { Authorization: token },
    data: user
  });
};
