import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
  GET_HOST_TUTORIALS_DETAILS_FAILURE,
  GET_HOST_TUTORIALS_DETAILS_REQUEST,
  GET_HOST_TUTORIALS_DETAILS_SUCCESS,
  UPDATE_HOST_TUTORIALS_FAILURE,
  UPDATE_HOST_TUTORIALS_REQUEST,
  UPDATE_HOST_TUTORIALS_SUCCESS
} from './types';

import { updateHostTutorial, getHostTutorialDetails } from './actions';

const getToken = state => state.loginReducer.token;
// const getVenueId = state => state.venuesReducer.venue.id;
const getUserId = state => state.loginReducer.uid;

function* workerGetHostTutorialDetails({ token, id }) {
  try {
    const response = yield call(getHostTutorialDetails, { token, id });
    const { tutorials } = response.data;
    yield put({
      type: GET_HOST_TUTORIALS_DETAILS_SUCCESS,
      tutorialsStatus: tutorials
    });
  } catch (error) {
    yield put({ type: GET_HOST_TUTORIALS_DETAILS_FAILURE, fetchingTutorials: error.response.data });
  }
}

function* workerUpdateHostTutorials({ tutorialsStatus }) {
  try {
    const userId = yield select(getUserId);
    const token = yield select(getToken);

    const response = yield call(updateHostTutorial, {
      token,
      id: userId,
      data: { tutorials: tutorialsStatus }
    });

    if (response.data && response.data.success) {
      yield put({ type: UPDATE_HOST_TUTORIALS_SUCCESS, data: response.data });
      // yield put({ type: VENUE_READ_REQUEST, token, id: venueId });
    }
  } catch (error) {
    yield put({ type: UPDATE_HOST_TUTORIALS_FAILURE, error: error.response });
  }
}

export function* watcherGetHostTutorialDetails() {
  yield takeLatest(GET_HOST_TUTORIALS_DETAILS_REQUEST, workerGetHostTutorialDetails);
}

export function* watcherUpdateHostTutorials() {
  yield takeLatest(UPDATE_HOST_TUTORIALS_REQUEST, workerUpdateHostTutorials);
}
