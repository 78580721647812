import { DateFieldWithLabel } from 'src/v2/UIComponents/FormComponents';
import { EXPECTED_CLOSE_DATE_LABEL } from '../../constants';

export const ExpectedCloseDate = () => {
  const ExpectedCloseDateProps = {
    name: 'leadInfo.expectedCloseDate',
    label: EXPECTED_CLOSE_DATE_LABEL,
    placeholder: EXPECTED_CLOSE_DATE_LABEL,
    showTimeSelect: false
  };

  return <DateFieldWithLabel {...ExpectedCloseDateProps} />;
};
