import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import {
  GET_GUESTS_REQUEST,
  GET_GUESTS_SUCCESS,
  GET_GUESTS_FAILURE,
  ADD_GUEST_REQUEST,
  ADD_GUEST_SUCCESS,
  ADD_GUEST_FAILURE,
  EDIT_GUEST_REQUEST,
  EDIT_GUEST_SUCCESS,
  EDIT_GUEST_FAILURE,
  DELETE_GUEST_REQUEST,
  DELETE_GUEST_SUCCESS,
  DELETE_GUEST_FAILURE,
  RESET_ADDDED_GUEST
} from './types';

const initialState = {
  guests: [],
  addedGuest: null,
  editedGuest: null,
  fetching: false,
  fetchingGuests: false,
  error: null,
  addGuestLoading: false,
  addGuestError: null,
  editGuestLoading: false,
  editGuestError: null,
  deleteGuestLoading: false,
  deleteGuestSuccess: null,
  deleteGuestError: null
};

const persistConfig = {
  key: 'guest',
  storage,
  blacklist: [
    'token',
    'refresh',
    'expires',
    'role',
    'claims',
    'admin',
    'fetching',
    'fetchingGuests',
    'addGuestLoading',
    'deleteGuestLoading'
  ]
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GUESTS_REQUEST:
      return {
        ...state,
        fetchingGuests: true,
        fetching: true,
        error: null,
        editGuestError: null,
        editedGuest: null,
        deleteGuestError: null,
        deleteGuestSuccess: null,
        addGuestError: null
      };
    case GET_GUESTS_SUCCESS:
      return {
        ...state,
        fetchingGuests: false,
        fetching: false,
        editedGroup: null,
        editedGuest: null,
        deleteGuestSuccess: null,
        deleteGroupSuccess: null,
        deleteGroupError: null,
        deleteGuestError: null,
        guests: action.guests
      };
    case GET_GUESTS_FAILURE:
      return {
        ...state,
        fetchingGuests: false,
        fetching: false,
        editedGuest: null,
        deleteGuestError: null,
        error: action.error
      };
    case ADD_GUEST_REQUEST:
      return {
        ...state,
        addGuestLoading: true,
        addedGuest: null,
        addGuestError: null
      };
    case ADD_GUEST_SUCCESS:
      return {
        ...state,
        addGuestLoading: false,
        addedGuest: action.guest,
        guests: [...state.guests, action.guest],
        addGuestError: null
      };
    case ADD_GUEST_FAILURE:
      return {
        ...state,
        addGuestLoading: false,
        addedGuest: null,
        addGuestError: action.error
      };
    case EDIT_GUEST_REQUEST:
      return {
        ...state,
        editGuestLoading: true,
        editedGuest: null,
        editGuestError: null
      };
    case EDIT_GUEST_FAILURE:
      return {
        ...state,
        editGuestLoading: false,
        editedGuest: null,
        editGuestError: action.error
      };
    case EDIT_GUEST_SUCCESS:
      return {
        ...state,
        editGuestLoading: false,
        editedGuest: action.guest,
        guests: state.guests.map(guest => {
          if (guest.id === action.guest.id) {
            return { ...guest, ...action.guest };
          }
          return guest;
        }),
        editGuestError: null
      };
    case DELETE_GUEST_REQUEST:
      return {
        ...state,
        deleteGuestLoading: true,
        deleteGuestSuccess: null,
        deleteGuestError: null
      };
    case DELETE_GUEST_SUCCESS:
      return {
        ...state,
        deleteGuestLoading: false,
        batchDeleteLoading: false,
        deleteGuestSuccess: action.success,
        guests: state.guests.filter(guest => guest.id !== action.id),
        deleteGuestError: null
      };
    case DELETE_GUEST_FAILURE:
      return {
        ...state,
        deleteGuestLoading: false,
        batchDeleteLoading: false,
        deleteGuestSuccess: null,
        deleteGuestError: action.error
      };
    case RESET_ADDDED_GUEST:
      return {
        ...state,
        addedGuest: null
      };
    default:
      return state;
  }
};

const guestReducer = persistReducer(persistConfig, reducer);

export default guestReducer;
