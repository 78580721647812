import React from 'react';
import PropTypes from 'prop-types';

import SearchStyles from './styles';

import IconSearch from '../../styles/Icons/Search';
import Colors from '../../styles/Colors';

const Search = ({ height, width, type, placeholder, handleChange, value, onKeyDown, disabled }) => {
  return (
    <SearchStyles>
      <SearchStyles.Input
        value={value}
        height={height}
        width={width}
        placeholder={placeholder}
        type={type}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        disabled={disabled}
      />
      <SearchStyles.Icon height={height}>
        <IconSearch color={Colors.grayB0} size={'16px'} />
      </SearchStyles.Icon>
    </SearchStyles>
  );
};

Search.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  handleChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool
};

export default Search;
