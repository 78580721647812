import { Flex } from '@chakra-ui/react';
import moment from 'moment';
import { useEvents, useLeads } from 'src/v2/Hooks/CollectionHooks';
import { ListCard } from 'src/v2/UIComponents/Primitives/Card/FullCard/FullCard';
import { Activities, AddPlus } from 'src/v2/UIComponents/Primitives/Icons';
import { Button } from 'src/v2/UIComponents/Primitives';
import { useDrawers } from 'src/v2/Hooks/HelperHooks';
import { ActivityFormDrawer } from 'src/v2/Drawers/ActivityFormDrawer';
import { EventActivityObject } from 'venuex-types';

export const LastActivityCard = ({ handleTabChange }) => {
  // TODO: #542 Empty state for LastActivityCard
  const { openActivityDrawer } = useDrawers();

  const { activeEvent: lead, fetchingEvent } = useEvents();
  const { activities: unfilteredActivities } = lead || {};

  const activities = unfilteredActivities?.filter((i): i is EventActivityObject => {
    return typeof i !== 'undefined' && !i.archive;
  });
  const lastNote = activities?.[0];
  // const { description, created, createdByData, activityTypeLabel } = lastNote;

  const createdByData = lastNote?.createdByData;
  const description = lastNote?.description;
  const created = lastNote?.created;
  const activityTypeLabel = lastNote?.activityTypeLabel;

  const { firstname, lastname } = createdByData || {
    firstname: '',
    lastname: ''
  };
  const createdByName = firstname && lastname && `${firstname} ${lastname}`;
  const list = [
    {
      title: 'Type',
      value: activityTypeLabel
    },
    {
      title: 'Description',
      value: description
    },
    {
      title: 'Logged By',
      value: createdByName && `${createdByName} on ${moment(created).format('LLL')}`
    }
  ];

  const footer = () => (
    <Flex justifyContent="center" mt="50px">
      <Button
        leftIcon={<AddPlus w={5} h={5} />}
        onClick={() => openActivityDrawer({})}
        isDisabled={fetchingEvent}
      >
        Log New Activity
      </Button>
    </Flex>
  );

  return (
    <>
      <ListCard
        titleIcon={<Activities color="var(--colors-primary)" />}
        title={`Last Activity ${(activityTypeLabel && `(${activityTypeLabel})`) || ''}`}
        list={list}
        headerBtnLabel="View All"
        headerBtnAction={() => handleTabChange(2)}
        fetching={fetchingEvent}
        headerBtnIsDisabled={fetchingEvent}
        footer={footer}
        headerBtnIcon={undefined}
      />
      <ActivityFormDrawer />
    </>
  );
};
