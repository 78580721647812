/* eslint-disable react/prop-types */
// removed

import { UsersInput } from 'src/v2/Drawers/EventFormDrawer/Inputs';

import { FormInputsWrapper } from 'src/v2/UIComponents/FormComponents';

import { ContentTitle } from './ContentTitle';

export const ClientDetailsTab = ({ bookEvent }) => {
  return (
    <FormInputsWrapper>
      <ContentTitle title="Portal Users" />
      <UsersInput bookEvent={bookEvent} />
    </FormInputsWrapper>
  );
};
