import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
  autoFillDocumentRequest,
  clearDocument,
  closeUploadDocumentVersionDrawer,
  commentDocumentRequest,
  deleteDocumentRequest,
  documentUploadOpen,
  documentUploadRequest,
  downloadDocumentOperations,
  getDocumentsRequest,
  openUploadDocumentVersionDrawer,
  selectDocument,
  updateDocumentRequest
} from 'src/containers/DocumentsContainer/operations';
import { useActions } from 'src/hooks/useActions';
import { RootStates, UseDocumentsHookReturn } from 'src/Types/GlobalTypes';
import { AddEventDocumentFormFieldProps } from 'venuex-types';

type useDocumentsHook = () => UseDocumentsHookReturn;

export const useDocuments: useDocumentsHook = () => {
  const dispatch = useDispatch();
  const { event } = useSelector((state: RootStates) => state.eventsReducer);
  const {
    isUploadDocumentVersionDrawerOpen,
    documentSelected,
    isFetching: isFetchingDocuments,
    documents
  } = useSelector((state: RootStates) => state.documentsReducer);

  // TODO: validate if this function is used
  // const handleUpsertDocument = document => {
  //   const eventId = event.id;
  //   if (document.documentId) {
  //     const { documentId, ...clean } = document;
  //     dispatch(updateDocumentRequest({ eventId, documentId, document: clean }));
  //     return;
  //   }
  //   if (!document.documentId) {
  //     toast('Error');
  //   }
  //   dispatch(addDocumentRequest({ eventId, document }));
  // };

  const handleUpdateVersionDocument = ({
    formData
  }: {
    formData: AddEventDocumentFormFieldProps;
  }) => {
    const eventId = event?.id;
    const { documentId } = documentSelected;

    if (documentId && eventId) {
      dispatch(updateDocumentRequest({ eventId, documentId, document: formData }));
    } else {
      toast.error('Error updating document version 2');
    }
  };

  // TODO: #664 useDocuments Hook: Review operations and fix

  const actions = useActions({
    documentUploadOpen,
    documentUploadRequest,
    closeUploadDocumentVersionDrawer,
    openUploadDocumentVersionDrawer,
    selectDocument,
    clearDocument,
    getDocumentsRequest,
    commentDocument: commentDocumentRequest,
    deleteDocument: deleteDocumentRequest,
    autoFillDocument: autoFillDocumentRequest,
    downloadDocument: ({ eventId, docId, filename, url }) =>
      downloadDocumentOperations.request({ eventId, docId, filename, url })
  });

  return {
    ...actions,
    // handleUpsertDocument,
    handleUpdateVersionDocument,
    isUploadDocumentVersionDrawerOpen,
    isFetchingDocuments,
    documents
  };
};
