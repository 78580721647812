import React from 'react';
import PropTypes from 'prop-types';
import VenuesStyles from './styles';
import Table from '../Table';

const Venues = props => (
  <VenuesStyles>
    <VenuesStyles.Body>
      <Table
        loading={props.fetching}
        columns={['id', 'name']}
        data={props.data}
        actions={[
          {
            label: 'Copy Embed',
            onClick: e => {
              const { id } = JSON.parse(e.currentTarget.getAttribute('data'));
              const copyInput = document.createElement('input');
              copyInput.setAttribute('type', 'text');
              copyInput.setAttribute('style', 'display: block; width: 1px; height: 1px;');
              copyInput.value = `<iframe src="${window.location.origin}?venueId=${id}" style="position: absolute; top: 0; bottom: 0; right: 0; left: 0; width: 100%; height: 100%;"></iframe>`;

              document.body.append(copyInput);
              copyInput.select();
              document.execCommand('copy');
              alert('Copied!');
            }
          }
        ]}
        footer={{
          label: 'Load More'
        }}
      />
    </VenuesStyles.Body>
  </VenuesStyles>
);

Venues.propTypes = {
  fetching: PropTypes.bool,
  token: PropTypes.string,
  data: PropTypes.array,
  doLoadMoreVenues: PropTypes.func
};

export default Venues;
