import React, { FC } from 'react';
import { FormControl, HStack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormField, FormLabel, FormErrorMessage } from '../../Primitives';
import { TextFieldProps } from './types';
import { findErrorByName } from '../FormUtils';

export const TextFieldWithLabel: FC<TextFieldProps> = ({
  isRequired,
  label,
  name,
  customLabel,
  placeholder,
  rules,
  fieldArray,
  errorKey,
  startElement,
  labelIcon,
  ...rest
}) => {
  const {
    formState: { errors },
    register,
    control
  } = useFormContext();
  const fieldValue = useWatch({ name, control });

  const errorMessage = findErrorByName(errors, name);
  const isInvalid = !!errorMessage;
  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <HStack marginBottom="0px" justify="space-between" align="flex-end">
        {customLabel || <FormLabel label={label} labelIcon={labelIcon} />}
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </HStack>

      <FormField
        startElement={startElement}
        placeholder={placeholder}
        type="text"
        fieldValue={fieldValue}
        {...register(name, rules)}
        {...rest}
      />
    </FormControl>
  );
};
