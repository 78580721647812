import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export function getStaff({ token, venueId }) {
  return axios({
    method: 'get',
    url: `${apiUri}/venues/${venueId}/users?role=staff`,
    headers: { Authorization: token }
  });
}

export function updateStaff({ token, staffId, viewPermissions }) {
  return axios({
    method: 'put',
    url: `${apiUri}/users/${staffId}`,
    headers: { Authorization: token },
    data: viewPermissions
  });
}

export function deleteStaff({ token, staffId, consultantId }) {
  return axios({
    method: 'delete',
    url: `${apiUri}/users/${staffId}`,
    headers: { Authorization: token },
    data: { consultant: consultantId }
  });
}

export function getStaffEvents({ token, consultantId }) {
  return axios({
    method: 'get',
    url: `${apiUri}/events?consultant=${consultantId}`,
    headers: { Authorization: token }
  });
}

export function addStaff({ token, staff, venueId }) {
  return axios({
    method: 'post',
    url: `${apiUri}/venues/${venueId}/users`,
    headers: { Authorization: token },
    data: {
      ...staff,
      role: 'staff',
      venueId
    }
  });
}
