// action types
import {
  VENUEVALUES_RESET,
  VENUEVALUES_REQUEST,
  VENUEVALUES_FAILURE,
  VENUEVALUES_SUCCESS
} from './types';

// reducer with initial state
const initialState = {
  fetching: true,
  error: null,
  venue: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case VENUEVALUES_RESET:
      return {
        ...state,
        fetching: false,
        error: null,
        venue: {}
      };
    case VENUEVALUES_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null
      };
    case VENUEVALUES_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };
    case VENUEVALUES_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        venue: action.venue
      };
    default:
      return state;
  }
};

export default reducer;
