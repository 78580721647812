import { useSelector } from 'react-redux';
import {
  showGlassLoader,
  hideGlassLoader,
  setGlassLoaderMessage,
  openModalLoader,
  closeModalLoader,
  updateModalLoaderMessage
} from 'src/containers/ModalLoader/operations';
import { useActions } from 'src/hooks/useActions';
import { openOverlayLoader, closeOverlayLoader } from 'src/containers/OverlayLoader/operations';
import { ModalLoaderReducer, RootStates } from 'src/Types';

export interface UseLoaderHookReturn extends ModalLoaderReducer {
  modalLoaderState: ModalLoaderReducer;
  glassLoaderVisible: ModalLoaderReducer['glassLoaderVisible'];
  hideGlassLoader: () => void;
  showGlassLoader: () => void;
  overlayLoaderOpen: boolean;
  message: string;
}
export const useLoader = (): UseLoaderHookReturn => {
  const modalLoaderState = useSelector((state: RootStates) => state.modalLoaderReducer);
  const { overlayLoaderOpen } = useSelector((state: RootStates) => state.overlayLoaderReducer);

  const actions = useActions({
    showGlassLoader,
    hideGlassLoader,
    setGlassLoaderMessage,
    openModalLoader,
    closeModalLoader,
    updateModalLoaderMessage,
    openOverlayLoader,
    closeOverlayLoader
  });

  return {
    modalLoaderState,
    overlayLoaderOpen,
    ...modalLoaderState,
    ...actions
  };
};
