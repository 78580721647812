import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from 'react-modal';
import './index.css';

const ModalFooter = styled.div`
  padding: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-top: solid 1px #d8d8d8;
  text-align: center;
`;

const ModalLoader = props => {
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.40)',
      zIndex: props.zIndex || 10
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      maxHeight: '90vh',
      boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
      overflow: 'hidden',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: '600px',
      height: '200px',
      borderRadius: '2em',
      padding: '13px 0px',
      boxSizing: 'border-box'
    }
  };

  return <Modal style={customStyles} {...props} ariaHideApp={false} closeTimeoutMS={2000} />;
};

ModalLoader.propTypes = {
  noPadding: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  zIndex: PropTypes.number
};

ModalLoader.Footer = ModalFooter;

export default ModalLoader;
