import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

// converted to lazy
import Calendar from 'src/v2/Pages/Calendar';
import Leads from 'src/v2/Pages/Leads';
import VenuesContainer from './containers/VenuesContainer';
import LoginContainer from './containers/LoginContainer';
import ForgotPassword from './containers/ForgotPassword';
import NotFound from './containers/NotFound';
import TitleComponent from './components/TitleComponent';

const SeatingChartContainer = React.lazy(() => import('./containers/SeatingChartContainer'));
const LayoutsContainer = React.lazy(() => import('./containers/LayoutsContainer'));
const HostChecklist = React.lazy(() => import('./v2/Pages/HostPortal/HostChecklist/Checklist'));
const Analytics = React.lazy(() => import('./v2/Pages/Analytics'));
const ManageStaffContainer = React.lazy(() => import('./containers/ManageStaffContainer'));
const GuestListContainer = React.lazy(() => import('./containers/GuestListContainer'));
const BillingContainer = React.lazy(() => import('./containers/BillingContainer'));
const BudgetPlannerContainer = React.lazy(() => import('./containers/BudgetPlannerContainer'));
const MemoriesContainer = React.lazy(() => import('./containers/MemoriesContainer'));
const VendorsContainer = React.lazy(() => import('./containers/VendorsContainer'));
const VendorListContainer = React.lazy(() => import('./containers/VendorListContainer'));
// const CustomFields = React.lazy(() => import('./v2/Pages/CustomFields'));
const DocumentLibrary = React.lazy(() => import('./v2/Pages/DocumentLibrary'));
const VenueSettings = React.lazy(() => import('./v2/Pages/VenueSettings'));
const ManageProducts = React.lazy(() => import('./v2/Pages/ManageProducts'));
const HostDashboard = React.lazy(() => import('./v2/Pages/HostPortal/HostDashboard/HostDashboard'));
const HostDocuments = React.lazy(() => import('./v2/Pages/HostPortal/HostDocuments/HostDocuments'));
// const CustomFields = React.lazy(() => import('./v2/Pages/CustomFields/CustomFieldsPage'));
// const HostQuestionnaire = React.lazy(() =>
//   import('./v2/Pages/HostPortal/HostQuestionnaire/HostQuestionnairePage')
// );

// import ResetPassword from './containers/ResetPassword';

const HostRoute = ({ component: Component, role, admin, token, ...rest }) => (
  <Route
    {...rest}
    render={(props: any) => {
      if (token) {
        return admin || role === 'host' ? (
          <React.Fragment>
            <Suspense>
              <TitleComponent venueData={props.venueData} />
              <Component {...props} />
            </Suspense>
          </React.Fragment>
        ) : (
          <Redirect push to={role === 'host' ? '/dashboard' : '/events'} />
        );
      }
      return <Redirect push to="/login" />;
    }}
  />
);

const StaffRoute = ({ component: Component, claims, role, admin, permission, token, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (token) {
          return (admin || role === 'staff') &&
            (permission ? claims.permissions[permission].read : true) ? (
            <React.Fragment>
              <Suspense>
                <TitleComponent venueData={props.venueData} />

                <Component {...props} />
              </Suspense>
            </React.Fragment>
          ) : (
            <Redirect push to={role === 'host' ? '/dashboard' : '/events'} />
          );
        }
        return <Redirect push to="/login" />;
      }}
    />
  );
};

const AdminRoute = ({ component: Component, admin, role, token, ...rest }) => (
  <Route
    {...rest}
    render={(props: any) => {
      if (token) {
        return admin ? (
          <React.Fragment>
            <Suspense>
              <TitleComponent venueData={props.venueData} />
              <Component {...props} />
            </Suspense>
          </React.Fragment>
        ) : (
          <Redirect push to={role === 'host' ? '/dashboard' : '/events'} />
        );
      }
      return <Redirect push to="/login" />;
    }}
  />
);

const Routes = props => (
  <HashRouter>
    <Switch>
      <Route
        path="/"
        exact
        render={() => {
          return (
            <React.Fragment>
              <TitleComponent venueData={props.venueData} />
              <Redirect push to={props.role === 'host' ? '/dashboard' : '/events'} />
            </React.Fragment>
          );
        }}
      />

      {/* AUTH SECTIONS */}
      <Route
        path="/login"
        exact
        render={() => (
          <React.Fragment>
            <TitleComponent venueData={props.venueData} />
            <LoginContainer {...props} />
          </React.Fragment>
        )}
      />
      <Route
        path="/forgotpassword"
        exact
        render={() => (
          <React.Fragment>
            <TitleComponent venueData={props.venueData} />
            <ForgotPassword {...props} />
          </React.Fragment>
        )}
      />
      {/* <Route
        path={`/auth/reset/:token=:s`}
        exact
        render={() => (
          <React.Fragment>
            <TitleComponent venueData={props.venueData} />
            <ResetPassword {...props} />
          </React.Fragment>
        )}
      /> */}
      <HostRoute path="/dashboard" exact component={HostDashboard} {...props} />
      <HostRoute path="/guestlist" exact component={GuestListContainer} {...props} />
      <HostRoute path="/seatingchart" exact component={SeatingChartContainer} {...props} />

      <HostRoute path="/memories" exact component={MemoriesContainer} {...props} />
      <HostRoute path="/budgetplanner" exact component={BudgetPlannerContainer} {...props} />
      <HostRoute path="/checklist" exact component={HostChecklist} {...props} />
      <HostRoute path="/vendors" exact component={VendorsContainer} {...props} />
      <HostRoute path="/vendorlist" exact component={VendorListContainer} {...props} />
      {/* <HostRoute path="/hostPayments" exact component={Payments} {...props} /> */}
      <HostRoute path="/documents" exact component={HostDocuments} {...props} />
      <AdminRoute path="/venues" exact component={VenuesContainer} {...props} />
      <StaffRoute path="/events" exact component={Calendar} {...props} />
      <StaffRoute path="/documentLibrary" exact component={DocumentLibrary} {...props} />
      <StaffRoute path="/calendar" exact component={Calendar} {...props} />
      <StaffRoute path="/leads" exact component={Leads} {...props} />
      {/* <StaffRoute path="/payments" exact component={Payments} {...props} /> */}
      <StaffRoute path="/analytics" exact component={Analytics} {...props} />
      <StaffRoute
        path="/managestaff"
        exact
        component={ManageStaffContainer}
        {...props}
        permission="users"
      />
      <StaffRoute
        path="/billing"
        exact
        component={BillingContainer}
        {...props}
        permission="billing"
      />

      {/* match permissions for layouts with that of events to not add more perms now (events has none, just is staff), same here */}
      <StaffRoute
        path="/layouts"
        exact
        component={routeProps => <LayoutsContainer {...routeProps} {...props} />}
        {...props}
      />

      <StaffRoute path="/manageproducts" exact component={ManageProducts} {...props} />
      <StaffRoute path="/venueSettings" exact component={VenueSettings} {...props} />
      {/* NOT FOUND */}
      <Route path="*" component={NotFound} />
    </Switch>
  </HashRouter>
);

Routes.propTypes = {
  claims: PropTypes.object,
  venueData: PropTypes.object,
  role: PropTypes.string,
  admin: PropTypes.bool,
  token: PropTypes.string
};

AdminRoute.propTypes = {
  component: PropTypes.elementType,
  venueData: PropTypes.object,
  role: PropTypes.string,
  admin: PropTypes.bool,
  token: PropTypes.string
};

StaffRoute.propTypes = {
  component: PropTypes.elementType,
  venueData: PropTypes.object,
  role: PropTypes.string,
  admin: PropTypes.bool,
  token: PropTypes.string,
  claims: PropTypes.object,
  permission: PropTypes.string
};

HostRoute.propTypes = {
  component: PropTypes.elementType,
  venueData: PropTypes.object,
  role: PropTypes.string,
  admin: PropTypes.bool,
  token: PropTypes.string
};

const mapStateToProps = state => ({
  claims: state.loginReducer.claims,
  role: state.loginReducer.role,
  admin: state.loginReducer.admin,
  token: state.loginReducer.token,
  venueData: state.venueValuesReducer.venue
});

export default connect(mapStateToProps)(Routes);
