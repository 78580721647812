// action types
import {
  OPEN_MODAL_LOADER,
  CLOSE_MODAL_LOADER,
  UPDATE_MODAL_LOADER_MESSAGE,
  SHOW_GLASS_LOADER,
  HIDE_GLASS_LOADER,
  SET_GLASS_LOADER_MESSAGE,
  OPEN_CONFIRM,
  CLOSE_CONFIRM,
  CONFIRM_ACCEPT,
  CONFIRM_REJECT,
  OPEN_TOAST,
  CLOSE_TOAST
} from './types';

// reducer with initial state
const initialState = {
  confirmNoSkip: false,
  confirmModalDescription: null,
  confirmModalOpen: false,
  confirmModalTitle: null,
  confirmAccepted: false,
  confirmRejected: false,
  confirmId: '',
  glassLoaderMessage: '',
  glassLoaderVisible: false,
  isOpen: false,
  modalLoaderHeader: null,
  modalLoaderMessage: null,
  modalLoaderType: null,
  showProgressBar: false.valueOf,
  toastOpen: false,
  toastMessage: null,
  toastId: null,
  toastBody: null,
  confirmType: '',
  confirmUpdate: '',
  confirmFields: [],
  confirmButtonTitle: '',
  confirmDefaultValues: {},
  confirmModalHeight: ''
};

export const modalLoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL_LOADER:
      return {
        ...state,
        isOpen: true,
        showProgressBar: action.showProgressBar,
        modalLoaderMessage: action.modalLoaderMessage,
        modalLoaderType: action.modalLoaderType,
        modalLoaderHeader: action.modalLoaderHeader
      };
    case CLOSE_MODAL_LOADER:
      return {
        ...state,
        isOpen: false,
        showProgressBar: null,
        modalLoaderMessage: null,
        modalLoaderType: null,
        modalLoaderHeader: null
      };
    case UPDATE_MODAL_LOADER_MESSAGE:
      return {
        ...state,
        modalLoaderHeader:
          (action.modalLoaderHeader && action.modalLoaderHeader) || state.modalLoaderHeader,
        modalLoaderMessage: action.modalLoaderMessage,
        showProgressBar: action.showProgressBar
      };
    case SHOW_GLASS_LOADER:
      return {
        ...state,
        glassLoaderVisible: true,
        glassLoaderMessage: action.glassLoaderMessage
      };
    case HIDE_GLASS_LOADER:
      return {
        ...state,
        glassLoaderVisible: false,
        glassLoaderMessage: ''
      };
    case SET_GLASS_LOADER_MESSAGE:
      return {
        ...state,
        glassLoaderMessage: action.message
      };

    case OPEN_CONFIRM:
      return {
        ...state,
        confirmModalOpen: true,
        confirmNoSkip: action.noSkip,
        confirmModalTitle: action.title,
        confirmModalHeight: action.height,
        confirmModalDescription: action.description,
        confirmId: action.confirmId,
        confirmAccepted: false,
        confirmRejected: false,
        confirmUpdate: action.toUpdate,
        confirmType: action.confirmType,
        confirmFields: action.fields,
        confirmButtonTitle: action.confirmButtonTitle,
        confirmDefaultValues: action.defaultValues
      };
    case CLOSE_CONFIRM:
      return {
        ...state,
        confirmNoSkip: false,
        confirmModalOpen: false,
        confirmModalTitle: null,
        confirmModalDescription: null,
        confirmId: null,
        confirmType: '',
        confirmUpdate: '',
        confirmFields: [],
        confirmButtonTitle: '',
        confirmDefaultValues: {}
      };
    case CONFIRM_ACCEPT:
      return {
        ...state,
        confirmAccepted: true
      };
    case CONFIRM_REJECT:
      return {
        ...state,
        confirmRejected: true,
        confirmModalOpen: false,
        confirmModalTitle: null,
        confirmModalDescription: null
      };

    case OPEN_TOAST:
      return {
        ...state,
        toastMessage: action.message,
        toastOpen: true,
        toastBody: action.body
      };

    case CLOSE_TOAST:
      return {
        ...state,
        toastMessage: null,
        toastOpen: false,
        toastBody: action.body
      };

    default:
      return state;
  }
};
