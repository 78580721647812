import { useEffect } from 'react';
import moment from 'moment';
import { useEventsDetails, useEvents, useVenue } from 'src/v2/Hooks/CollectionHooks';
import { DetailsDrawer } from './DetailsDrawer';

export const EventDetails = () => {
  const {
    isEventDetailsDrawerOpen,
    activeItem,
    closeEventDetailsDrawer,
    setActiveItem
  } = useEventsDetails();
  const { clearActiveEvent, activeEvent, fetchingEvent: readingEvent } = useEvents();

  const { fetchingFireStore } = useVenue();

  const fetching = readingEvent || fetchingFireStore;

  const startDate = moment(activeEvent?.start).format('dddd, MMMM Do, YYYY');
  const startTime = moment(activeEvent?.start).format('LT');

  const eventData = {
    ...activeEvent,
    startDate,
    startTime
  };

  const handleDetailsDrawerClose = () => {
    closeEventDetailsDrawer();
    clearActiveEvent();
    setActiveItem({ activeItem: 'overview' });
  };

  useEffect(
    () => () => {
      handleDetailsDrawerClose();
    },
    []
  );

  return (
    <DetailsDrawer
      isOpen={isEventDetailsDrawerOpen}
      onCloseDrawer={handleDetailsDrawerClose}
      event={eventData}
      activeItem={activeItem}
      fetchingEvent={fetching}
    />
  );
};
