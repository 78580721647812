import React from 'react';
import Loader from '../Icons/Loader';
import OverlayStyles from './styles';

const OverlayLoader = () => {
  return (
    <OverlayStyles>
      <Loader />
    </OverlayStyles>
  );
};

export default OverlayLoader;
