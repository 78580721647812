/* eslint-disable react/prop-types */
import moment from 'moment';
import Colors from 'src/styles/Colors';
import { Icon, Flex } from '@chakra-ui/react';
import { MdAdd } from 'react-icons/md';
import { Toolbar } from 'src/v2/UIComponents/Toolbar';
// import { useTableContext } from 'src/v2/context/Tables';
import { useDrawers } from 'src/v2/Hooks/HelperHooks';
import { TasksDropdown } from 'src/v2/UIComponents/Primitives/ButtonDropdown/TasksDropdown';
import React from 'react';
import Search from './Search';

const RootCalendarToolbar = ({
  date,
  label,
  messages,
  setDate,
  setHighlight,
  setExpanded,
  expanded
}) => {
  // TODO: #989 Calendar Updates: Replace Create Event Form with Leads Form
  // const { openCreateEventForm } = useEvents();
  // const { filters } = useTableContext();
  const { openCalendarWizard } = useDrawers();
  const handleDateChange = dateAction => {
    if (dateAction === 'now') {
      setDate(new Date(moment.now()));
      setHighlight(moment().format('MM/DD/YYYY'));
    }
    if (dateAction === 'prev') {
      setDate(new Date(moment(date).subtract(1, 'months')));
      setHighlight(null);
    }
    if (dateAction === 'next') {
      setDate(new Date(moment(date).add(1, 'months')));
      setHighlight(null);
    }
  };

  const handleSelectOption = option => {
    // set date to navigate automatically
    if (option) {
      setHighlight(option.value);
      setDate(new Date(moment(option.value)));
    }
  };

  // const handleOpenCreateEventModal = () => {
  //   // openCreateEventForm();
  //   console.log('open create event form');
  // };

  const rightActions = [
    {
      icon: <Icon as={MdAdd} w={6} h={6} />,
      onClick: () => openCalendarWizard(),
      type: 'iconButton'
    },
    {
      type: 'custom',
      component: <TasksDropdown />
    }
    // {
    //   icon: <Icon as={MdOutlineNotifications} w={6} h={6} />,
    //   title: 'Overdue Payments',
    //   noItemsText: 'No overdue payments',
    //   type: 'dropdown',
    //   variant: 'roundedSecondaryOutline'
    // }
    // feat/calendar-appointments
    // {
    //   icon: <Icon as={BiFilter} w={6} h={6} />,
    //   onClick: () => setExpanded(!expanded),
    //   type: (!filters.length && 'iconButtonSecondary') || 'iconButton'
    // }
  ];

  const leftActions = [
    {
      label: messages.today,
      variant: 'grayOutline',
      size: 'md',
      width: '100px',
      color: Colors.gray7D,
      onClick: () => handleDateChange('now')
    },
    {
      type: 'custom',
      component: <Search handleSelectOption={handleSelectOption} />
    }
  ];

  return (
    <>
      <Flex flexDirection={'column'} justifyContent={{ md: 'space-between' }} width="100%">
        <Toolbar
          title={label}
          rightActions={rightActions}
          leftActions={leftActions}
          leftArrowClick={() => handleDateChange('prev')}
          rightArrowClick={() => handleDateChange('next')}
          isCalendar
        />
      </Flex>
    </>
  );
};

export const CalendarToolbar = React.memo(RootCalendarToolbar);
