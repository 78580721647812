import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import { getLayout } from 'src/containers/LayoutsContainer/actions';
import { updateModalLoaderMessage, closeModalLoader } from 'src/containers/ModalLoader/operations';
import toast from 'react-hot-toast';
import { Spinner } from '@chakra-ui/react';
import { CLOSE_MODAL_LOADER } from 'src/containers/ModalLoader/types';
import {
  GET_ROOM_DETAILS_REQUEST,
  GET_LAYOUT_DETAILS_REQUEST,
  GET_HOSTS_REQUEST,
  SET_PAYMENTSTATUS_REQUEST,
  SEND_VENUETOHOSTEMAIL_REQUEST,
  GENERATE_SEATING_CHART_PDF_REQUEST,
  GENERATE_SEATING_CHART_PDF_FAILURE,
  REQUEST_EVENT_PACKET,
  REQUEST_EVENT_PACKET_FAILURE
} from './types';

import {
  EVENT_PACKET_ERROR_HEADER,
  EVENT_PACKET_ERROR_MESSAGE
} from '../../components/ModalLoader/types';

import {
  getRoomDetailsAction,
  getLayoutsDetailsAction,
  getHostsAction,
  setPaymentStatusAction,
  sendVenueToHostEmailAction,
  generatePdfFile,
  generateTemplatePDF
} from './actions';

import { generateExcelFile, generateVendorListExcelFile } from '../GuestContainer/actions';
import { getEventAction } from '../EventsContainer/actions';

import {
  getRoomDetailsFailure,
  getRoomDetailsSuccess,
  getLayoutsDetailsFailure,
  getLayoutsDetailsSuccess,
  getHostsSuccess,
  getHostsFailure,
  setPaymentStatusSuccess,
  setPaymentStatusFailure,
  sendVenueToHostEmailSuccess,
  sendVenueToHostEmailFailure
} from './operations';

import { setPaymentStatusOnEvent } from '../EventsContainer/sagas';
import { createPdfFile } from '../../utils/generateSeatingChartPdf';
import { createExcelFile } from '../../utils/generateGuestList';

const getToken = state => state.loginReducer.token;

function* workerGetRooms({ token, roomId }) {
  try {
    const response = yield call(getRoomDetailsAction, { token, roomId });
    const { room } = response.data;
    yield put(getRoomDetailsSuccess(room));
  } catch (error) {
    yield put(getRoomDetailsFailure(error));
  }
}

// function* workerOpenEventModal({ token, eventId }) {
//   try {
//     const response = yield call(getEventAction, { token, eventId });
//     const { event } = response.data;
//     yield put(getEventSuccess(event));
//     yield put(openEventModalSuccess({ event }));
//   } catch (error) {
//     yield put(openEventModalFailure({ error: error.response && error.response.data.message }));
//   }
// }

function* workerGetLayouts({ token, roomId }) {
  try {
    const response = yield call(getLayoutsDetailsAction, { token, roomId });
    const { layouts } = response.data;
    yield put(getLayoutsDetailsSuccess(layouts));
  } catch (error) {
    yield put(getLayoutsDetailsFailure(error));
  }
}

function* workerHostUsers({ venueId, token, eventId, role }) {
  try {
    const response = yield call(getHostsAction, { venueId, token, eventId, role });
    yield put(getHostsSuccess({ users: response.data.users }));
  } catch (error) {
    yield put(getHostsFailure(error));
  }
}

function* workerSetPaymentStatus({ status, token, eventId }) {
  try {
    const response = yield call(setPaymentStatusAction, { status, token, eventId });
    const { status: responseStatus } = response && response.data;
    yield put(setPaymentStatusSuccess({ status: responseStatus }));
    yield setPaymentStatusOnEvent({ eventId, status: responseStatus });
  } catch (error) {
    yield put(setPaymentStatusFailure(error));
  }
}

function* workerSendVenueToHostEmail({ eventId, subject, message }) {
  const loadingToastId = toast.loading('Sending Email', {
    icon: <Spinner />
  });
  try {
    const token = yield select(getToken);
    const emailResponse = yield call(sendVenueToHostEmailAction, {
      token,
      eventId,
      subject,
      message
    });

    if (emailResponse) {
      toast.dismiss(loadingToastId);
      toast.success('Email Sent');
    }
    yield put(sendVenueToHostEmailSuccess());
  } catch (error) {
    toast.dismiss(loadingToastId);
    yield put(sendVenueToHostEmailFailure(error));
  }
}

function* workerGeneratePdfFile({ token, eventId, venueId, layoutId, setDownloadingDoc }) {
  try {
    const response = yield call(generatePdfFile, { token, eventId, venueId, layoutId });
    if (response) {
      const { imageData, title, layoutName, orientation } = response.data;
      createPdfFile(imageData, title, layoutName, orientation, setDownloadingDoc);
    }
  } catch (error) {
    yield put({ type: GENERATE_SEATING_CHART_PDF_FAILURE, error });
  }
}

function* workerRequestEventPacket({ eventId, venueId, layoutId, layoutType }) {
  const token = yield select(getToken);
  let layoutDataType = layoutType;
  try {
    let pdfRequest = generatePdfFile;
    if (!layoutType) {
      const layoutData = yield call(getLayout, { token, layoutId });
      console.log('layoutData', layoutData.data);
      layoutDataType = layoutData?.data?.layout?.type;
    }

    if (layoutDataType === 'template') {
      pdfRequest = generateTemplatePDF;
    }
    const { pDFresponse, excelResponse, vendorListResponse } = yield all({
      pDFresponse: call(pdfRequest, { token, eventId, venueId, layoutId }),
      excelResponse: call(generateExcelFile, { token, eventId, venueId, layoutId }),
      vendorListResponse: call(generateVendorListExcelFile, { token, eventId })
    });

    if (excelResponse && pDFresponse) {
      const { buffer, eventTitle } = excelResponse.data;
      const { buffer: vendorListBuffer, eventTitle: vEventTitle } = vendorListResponse.data;
      createExcelFile(buffer, `Guest List for ${eventTitle}`);
      createExcelFile(vendorListBuffer, `Vendor List for ${vEventTitle}`);

      const url = window.URL.createObjectURL(new Blob([pDFresponse.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Seating Chart for ${eventTitle}.pdf`);
      document.body.appendChild(link);
      link.click();
      yield put(closeModalLoader());
    }
  } catch (error) {
    yield put(
      updateModalLoaderMessage({
        modalLoaderHeader: EVENT_PACKET_ERROR_HEADER,
        showProgressBar: false,
        modalLoaderMessage: EVENT_PACKET_ERROR_MESSAGE
      })
    );
    yield new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 1500);
    });
    yield put({ type: CLOSE_MODAL_LOADER, error });
    yield put({ type: REQUEST_EVENT_PACKET_FAILURE, error });
  }
}

// EXPORTS

export function* watchGetRoomDetails() {
  yield takeLatest(GET_ROOM_DETAILS_REQUEST, workerGetRooms);
}

export function* watchGetLayoutsDetails() {
  yield takeLatest(GET_LAYOUT_DETAILS_REQUEST, workerGetLayouts);
}

export function* watchGetHosts() {
  yield takeLatest(GET_HOSTS_REQUEST, workerHostUsers);
}

export function* watchSetPaymentStatus() {
  yield takeLatest(SET_PAYMENTSTATUS_REQUEST, workerSetPaymentStatus);
}

export function* watchSendVenueToHostEmail() {
  yield takeLatest(SEND_VENUETOHOSTEMAIL_REQUEST, workerSendVenueToHostEmail);
}

export function* watcherGeneratePdfFile() {
  yield takeLatest(GENERATE_SEATING_CHART_PDF_REQUEST, workerGeneratePdfFile);
}

export function* watcherRequestEventPacket() {
  yield takeLatest(REQUEST_EVENT_PACKET, workerRequestEventPacket);
}

// export function* watcherRequestOpenEventModal() {
//   yield takeLatest(OPEN_EVENT_MODAL_REQUEST, workerOpenEventModal);
// }
