import { Button } from 'src/v2/UIComponents/Primitives';
import { Box, Text, Flex } from '@chakra-ui/react';
import { AddPlus } from 'src/v2/UIComponents/Primitives/Icons';
import { useEvents, useLeads } from 'src/v2/Hooks/CollectionHooks';
import { EmptyState } from 'src/v2/UIComponents/EmptyState';
import { EventActivityObject } from 'venuex-types';
import moment from 'moment';
import {
  scrollBarProps,
  scrollParentProps,
  scrollSiblingProps
} from 'src/v2/UIComponents/ScrollBar/scrollBarProps';
import { useConfirm, useDrawers } from 'src/v2/Hooks/HelperHooks';
import { EventTabBox } from 'src/v2/UIComponents/Primitives/Cards/EventTabBox';
import { useState } from 'react';
import { ActivityFormDrawer } from '../../ActivityFormDrawer';

export const ActivitiesTab = () => {
  const { openActivityDrawer } = useDrawers();
  const { updateEvent, activeEvent: lead } = useEvents();
  const [activityToDelete, setActivityTodelete] = useState('');
  const handleEditActivity = (activity: EventActivityObject) =>
    openActivityDrawer({ activity, editActivity: true });

  const leadActivities = lead?.activities?.filter(actvty => !actvty?.archive);
  const handleConfirmDelete = () => {
    if (activityToDelete) {
      updateEvent({
        data: {
          activity: {
            id: activityToDelete,
            archive: true
          }
        },
        eventId: lead?.id,
        isLead: true
      });
    }
    setActivityTodelete('');
  };

  const { openConfirm } = useConfirm({
    acceptAction: handleConfirmDelete,
    confirmHookId: 'deleteActivity'
  });

  const requestDelete = noteId => {
    setActivityTodelete(noteId);
    openConfirm({
      title: 'Delete Activity',
      description: 'Are you sure you want to delete this activity?',
      confirmId: 'deleteActivity',
      confirmType: 'cancel'
    });
  };

  return (
    <>
      <Box display="flex" height="100%" flexDirection={'column'} gap="18px" sx={scrollParentProps}>
        <Flex justifyContent="flex-end" sx={scrollSiblingProps}>
          <Button
            variant="primary"
            leftIcon={<AddPlus color="white" w={4} h={4} />}
            width="140px"
            onClick={() => openActivityDrawer({})}
          >
            Log Activity
          </Button>
        </Flex>
        <Flex
          flexDirection={'column'}
          minH="1px"
          gap={'24px'}
          overflowY="scroll"
          height="100%"
          sx={scrollBarProps}
        >
          {(leadActivities?.length &&
            leadActivities?.map((activity, key) => {
              const createdByData = activity?.createdByData;
              // TODO: #552 Lead Details Notes Tab: Review why notes are not showing.
              const title = `${createdByData?.firstname} ${createdByData?.lastname} logged - ${
                activity?.activityTypeLabel
              } on ${moment(activity?.created).format('LLL')}`;

              return (
                <EventTabBox
                  key={key}
                  title={title}
                  subtitle={undefined}
                  buttonAction={() => handleEditActivity(activity)}
                  secondaryAction={() => requestDelete(activity?.id)}
                  icons
                >
                  <Box padding="22px">
                    <Text variant="contentTitle" fontSize="15px">
                      {activity?.title}
                    </Text>
                    <Text variant="contentSubTitle">{activity?.description}</Text>
                  </Box>
                </EventTabBox>
              );
            })) || (
            <EmptyState
              title="No Activities Added Yet"
              subTitle="Log an activity to track client engagement"
              btnAction={undefined}
              btnLabel={undefined}
            />
          )}
        </Flex>
      </Box>
      <ActivityFormDrawer />
    </>
  );
};
