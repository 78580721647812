import styled from 'styled-components';

const InputInlineEditStyles = styled.div`
  overflow: hidden;
  input {
    float: left;
  }

  button {
    padding-left: 30px;
    border-radius: 0px;
    &:last-child {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
`;

InputInlineEditStyles.Form = styled.div`
  overflow: hidden;
  display: ${props => (props.show ? 'block' : 'none')};
`;

InputInlineEditStyles.Value = styled.div`
  background-color: ${props => props.bgColor || 'transparent'};
  display: ${props => (props.show ? 'flex' : 'none')};
  align-items: center;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
`;

InputInlineEditStyles.ValueLabel = styled.span`
  float: left;
  margin-right: 10px;
`;

export default InputInlineEditStyles;
