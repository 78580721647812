import styled from 'styled-components';
import HexToRGB from '../../styles/HexToRGB';
import Screens from '../../styles/Screens';
import Colors from '../../styles/Colors';

const ForgotPasswordStyles = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${props => (props.bgColor ? props.bgColor : `var(--colors-bgLoginSection)`)};
`;

ForgotPasswordStyles.Card = styled.div`
  width: calc(100% - 48px);
  padding: 24px;
  border-radius: 2px;
  box-shadow: 0 0 20px 0 rgba(${HexToRGB(Colors.black, true)}, 0.2);
  background-color: ${props => (props.bgColor ? props.bgColor : `var(--colors-bgLoginCard)`)};

  input {
    width: calc(100% - 20px);
    margin-bottom: 20px;
  }

  button {
    width: 100%;
  }

  @media (min-width: ${Screens.small}) {
    padding: 48px;
    width: 480px;

    button {
      width: auto;
      margin-right: 20px;
    }
  }
`;

ForgotPasswordStyles.CardHeader = styled.div`
  display: flex;
  background: ${props => (props.color ? props.color : `var(--colors-bgLoginSection)`)};
  background-image: url(${props => props.logo});
  background-position: center;
  background-size: var(--logoSize);
  background-repeat: no-repeat;
  height: 200px;
  align-items: middle;
  justify-content: center;
  margin-bottom: 20px;

  h1 {
    margin: 0;
    line-height: 200px;
  }
`;

ForgotPasswordStyles.Error = styled.div`
  transition: all 250ms ease-in-out 0s;
  color: ${Colors.redC0};
  font-size: 11px;
  font-weight: bold;
  line-height: 22px;
  height: ${props => (props.show ? '22px' : '0px')};
  margin-bottom: 20px;
`;

ForgotPasswordStyles.Success = styled.div`
  display: flex;
  flex-direction: column;
`;
ForgotPasswordStyles.SuccessHeader = styled.div`
  text-align: center;
  font-size: 26px;
  font-family: Lora;
`;
ForgotPasswordStyles.SuccessText = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  line-height: 25px;
  text-align: center;
`;
ForgotPasswordStyles.SuccessNote = styled.div`
  margin-top: 35px;
  margin-left: 10px;
  text-decoration: underline;
  font-style: italic;
  font-weight: 900;
  font-size: 14px;
`;
ForgotPasswordStyles.SuccessNoteText = styled.div`
  margin: 6px 15px;
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
`;

ForgotPasswordStyles.BackButton = styled.div`
  margin-top: 20px;
  margin-left: 36%;
`;

export default ForgotPasswordStyles;
