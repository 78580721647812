import { takeLatest, call, put } from 'redux-saga/effects';
import * as actions from './actions';
import { openFileModal } from './operations';

// action types
import {
  CHECK_CSV_FAILURE,
  CHECK_CSV_SUCCESS,
  CHECK_CSV_REQUEST,
  CANCEL_AFTER_CSV_PARSE,
  RESET
} from './types';

const { checkCSV } = actions;

// worker saga: makes the api call when watcher saga sees the action
function* workerCheckCSV({ formData, eventId, venueId }) {
  try {
    const response = yield call(checkCSV, { formData, eventId, venueId });

    const { parsedData, columns } = response.data;

    // dispatch a success action to the store with the new dog
    yield put({ type: CHECK_CSV_SUCCESS, parsedData, columns });
    yield put(openFileModal());
  } catch (error) {
    yield put({ type: CHECK_CSV_FAILURE, error: error.response.data });
  }
}

function* workerReset() {
  yield put({ type: RESET });
}

export function* watcherCheckCSV() {
  yield takeLatest(CHECK_CSV_REQUEST, workerCheckCSV);
}

export function* watcherResetCSV() {
  yield takeLatest(CANCEL_AFTER_CSV_PARSE, workerReset);
}
