import { VENUEVALUES_REQUEST } from './types';

export interface Action {
  type: string;
}

export type GetVenueValuesProps = {
  venueId: string;
};

export interface GetVenueValuesAction extends Action {
  venueId: string;
}

export type GetVenueValuesRequest = (input: GetVenueValuesProps) => GetVenueValuesAction;

export const doGetVenueValues: GetVenueValuesRequest = ({ venueId }) => ({
  type: VENUEVALUES_REQUEST,
  venueId
});
