import React from 'react';
import { Icon } from '@chakra-ui/react';

export const Download = props => {
  return (
    <Icon {...props}>
      <path
        d="M2.568 20.232h18.964v-6.036c0-.573.468-1.041 1.041-1.041.574 0 1.042.468 1.042 1.041v7.077c0 .574-.468 1.042-1.042 1.042H1.527a1.043 1.043 0 0 1-1.042-1.042v-7.077c0-.573.468-1.041 1.042-1.041.573 0 1.04.468 1.04 1.041v6.036zm12.827-9a1.016 1.016 0 0 1 1.462 0 1.016 1.016 0 0 1-.001 1.463l-4.076 4.04-.014.012a1.093 1.093 0 0 1-1.446-.013l-4.077-4.04c-.2-.2-.31-.46-.31-.73 0-.27.11-.53.296-.717.377-.43 1.06-.43 1.475-.016l2.305 2.275V2.922c0-.573.468-1.04 1.041-1.04s1.041.467 1.041 1.04v10.584l2.304-2.273z"
        fill="currentColor"
      />
    </Icon>
  );
};
