import React, { FC, ReactNode } from 'react';
import { Text as ChakraText, TextProps } from '@chakra-ui/react';

interface Props extends TextProps {
  variant?: string;
  children?: ReactNode;
  color?: string;
}

export const Text: FC<Props> = ({ variant, children, color, ...props }) => {
  return (
    <ChakraText variant={variant} color={color} {...props}>
      {children}
    </ChakraText>
  );
};
