import styled from 'styled-components';

const EllipsisStyles = styled.div`
  display: flex;
  padding: 0.75rem;
  &:hover {
    cursor: pointer;
  }
`;

EllipsisStyles.Dot = styled.div`
  display: flex;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background-color: var(--colors-primary);
  margin: 1px;
`;

export default EllipsisStyles;
