/* eslint-disable react/prop-types */
import { FC, useEffect, useState } from 'react';
import { Flex, Icon } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormDrawer } from 'src/v2/UIComponents/Drawers';
import { MdNavigateNext } from 'react-icons/md';
import { useConfirm } from 'src/v2/Hooks/HelperHooks';
import _isEmpty from 'lodash/isEmpty';
import Colors from 'src/styles/Colors';
import { WizardFormProps } from 'src/Types/GlobalTypes';

export const Wizard: FC<WizardFormProps> = ({
  steps,
  cancelAction,
  saveAction,
  defaultValues,
  isOpen,
  formId,
  disableNext,
  touchedDefaultValues
}) => {
  // eslint-disable-next-line dot-notation
  const [activeStep, setStep] = useState(0);
  const methods = useForm({ mode: 'onBlur' });
  const {
    handleSubmit,
    reset,
    trigger,
    setValue,
    formState: { isDirty, touchedFields: dirtyFields }
  } = methods;

  // const currentValues = watch();
  // console.log('current values', currentValues);

  const resetForm = () => {
    reset();
    setStep(0);
  };

  const handleClose = () => {
    setStep(0);
    reset();
    cancelAction();
  };

  useEffect(() => {
    !isOpen && resetForm();
  }, [isOpen]);

  useEffect(() => {
    setStep(0);
  }, [isOpen]);

  // useEffect(() => {
  //   if (defaultValues) {
  //     reset(defaultValues);
  //   }
  // }, [defaultValues]);

  useEffect(() => {
    if (touchedDefaultValues) {
      const valueKeys = Object.keys(touchedDefaultValues);
      valueKeys.forEach((key: string) => {
        const fieldName: string = key;
        setValue(fieldName, touchedDefaultValues[key], {
          shouldDirty: true,
          shouldTouch: true
        });
      });
    }
  }, [setValue, touchedDefaultValues, dirtyFields]);

  const { openConfirm } = useConfirm({ acceptAction: handleClose, confirmHookId: formId });

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  const forward = async () => {
    const { validateFields } = steps[activeStep];

    const result = await trigger(validateFields);

    result && setStep(activeStep + 1);
  };

  const backward = () => {
    setStep(activeStep - 1);
  };

  const renderSteps = currentStep => {
    return steps
      ?.filter(StepObject => currentStep === StepObject.step)
      .map(StepObject => {
        const StepComponent = StepObject?.component;
        return <StepComponent key="step" />;
      });
  };

  const closeDrawer = () => {
    if (!isDirty) cancelAction();
    else {
      openConfirm({
        description: 'Are you sure you want to cancel? All changes will be lost.',
        title: 'Discard Changes?',
        confirmId: formId,
        confirmType: 'cancel'
      });
    }
  };

  const footerOptions = () => {
    const canGoBack = activeStep > 0;
    const canGoForward = activeStep < Number(steps?.length) - 1;
    return {
      leftButton: {
        onClick: (canGoBack && backward) || closeDrawer,
        label: (canGoBack && 'BACK') || undefined
      },
      rightButton: {
        onClick: (canGoForward && forward) || handleSubmit(data => handleSave(data)),
        label: (canGoForward && 'NEXT') || undefined,
        rightIcon:
          (canGoForward && <Icon as={MdNavigateNext} h={5} w={5} color={`${Colors.white}`} />) ||
          undefined,
        isDisabled: disableNext
      }
    };
  };

  const getDirtyKeys = object => Object.keys(object).filter(key => object[key]);

  const handleSave = data => {
    const fieldsToSubmit = {};
    const dirtyKeys = getDirtyKeys(dirtyFields);
    dirtyKeys.forEach(dirtyFieldKey => {
      const dirtyField = dirtyFields[dirtyFieldKey];
      if (Array.isArray(dirtyField)) {
        fieldsToSubmit[dirtyFieldKey] = data[dirtyFieldKey];
      }
      if (typeof dirtyFields[dirtyFieldKey] === 'object') {
        const fieldObject = dirtyFields[dirtyFieldKey];
        Object.keys(fieldObject).forEach(property => {
          if (data[dirtyFieldKey][property] && fieldObject[property] === true) {
            fieldsToSubmit[dirtyFieldKey] = {};
            fieldsToSubmit[dirtyFieldKey][property] = data[dirtyFieldKey][property];
          }
        });
        fieldsToSubmit[dirtyFieldKey] = data[dirtyFieldKey];
      }
      if (dirtyFields[dirtyFieldKey] === true) {
        fieldsToSubmit[dirtyFieldKey] = data[dirtyFieldKey];
      }
    });
    if (!_isEmpty(fieldsToSubmit)) {
      saveAction(fieldsToSubmit);
    }
  };

  return (
    <FormDrawer
      isOpen={isOpen}
      title={steps[activeStep].title}
      showFooter
      footerOptions={footerOptions()}
      closeDrawer={closeDrawer}
      wide
      isFetching={undefined}
    >
      <FormProvider {...methods}>
        <Flex flexDir={'column'} h="100%" minH="1px">
          {renderSteps(activeStep)}
        </Flex>
      </FormProvider>
    </FormDrawer>
  );
};
