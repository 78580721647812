import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useVenue } from 'src/v2/Hooks/CollectionHooks';
import { TextFieldWithLabel } from 'src/v2/UIComponents/FormComponents';
import { EVENT_NAME_PLACEHOLDER, EVENT_NAME_LABEL } from 'src/v2/Drawers/EventFormDrawer/constants';
import { debounce } from 'lodash';

export const EventTitleInput = ({ bookEvent, mode }) => {
  const { setValue } = useFormContext();
  const { dirtyFields, touchedFields } = useFormState();
  const debouncedSetValue = useRef(debounce(setValue, 250));

  const clientNameInput = useWatch({ name: 'clientName' });
  const eventType = useWatch({ name: 'eventType' });

  const { title: dirtyName } = dirtyFields;
  const { title: touchedName } = touchedFields;
  const { eventTypeOptions } = useVenue();
  useEffect(() => {
    if (mode === 'create' && clientNameInput && eventType && (!dirtyName || !touchedName)) {
      const eventTypeObject =
        eventTypeOptions && eventTypeOptions.find(type => type.value === eventType);
      debouncedSetValue.current(
        'title',
        `${clientNameInput} - ${eventTypeObject && eventTypeObject.label}`,
        { shouldDirty: true, shouldTouch: true }
      );
    }
  }, [clientNameInput, eventType, dirtyName, mode, touchedName]);

  const eventTitleProps = {
    name: 'title',
    isRequired: bookEvent,
    label: EVENT_NAME_LABEL,
    placeholder: EVENT_NAME_PLACEHOLDER,
    rules: {
      required: bookEvent && 'Required'
    }
  };

  return <TextFieldWithLabel {...eventTitleProps} />;
};

EventTitleInput.propTypes = {
  consultants: PropTypes.object,
  users: PropTypes.array,
  errors: PropTypes.object,
  register: PropTypes.func,
  inputStyles: PropTypes.object
};
