export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';

export const GET_EVENT_ANALYTICS = {
  REQUEST: 'GET_EVENT_ANALYTICS_REQUEST',
  SUCCESS: 'GET_EVENT_ANALYTICS_SUCCESS',
  FAILURE: 'GET_EVENT_ANALYTICS_FAILURE'
};

export const GET_EVENT_REQUEST = 'GET_EVENT_REQUEST';
export const GET_EVENT_FAILURE = 'GET_EVENT_FAILURE';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const CLEAR_ACTIVE_EVENT = 'CLEAR_ACTIVE_EVENT';

export const GET_MORE_EVENTS_REQUEST = 'GET_MORE_EVENTS_REQUEST';
export const GET_MORE_EVENTS_FAILURE = 'GET_MORE_EVENTS_FAILURE';
export const GET_MORE_EVENTS_SUCCESS = 'GET_MORE_EVENTS_SUCCESS';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS';

export const SEARCH_EVENT_REQUEST = 'SEARCH_EVENT_REQUEST';

export const UPDATE_EVENT_NOTE_REQUEST = 'UPDATE_EVENT_NOTE_REQUEST';
export const UPDATE_EVENT_NOTE_SUCCESS = 'UPDATE_EVENT_NOTE_SUCCESS';
export const UPDATE_EVENT_NOTE_FAILURE = 'UPDATE_EVENT_NOTE_FAILURE';

export const UPDATE_EVENT_CHECKLIST_ITEM_REQUEST = 'UPDATE_EVENT_CHECKLIST_ITEM_REQUEST';
export const UPDATE_EVENT_CHECKLIST_ITEM_SUCCESS = 'UPDATE_EVENT_CHECKLIST_ITEM_REQUEST';
export const UPDATE_EVENT_CHECKLIST_ITEM_FAILURE = 'UPDATE_EVENT_CHECKLIST_ITEM_REQUEST';

export const OPEN_CREATE_EVENT_FORM = 'OPEN_CREATE_EVENT_FORM';
export const CLOSE_CREATE_EVENT_FORM = 'CLOSE_CREATE_EVENT_FORM';

export const GET_USER_TASKS_REQUEST = 'GET_USER_TASKS_REQUEST';
export const GET_USER_TASKS_SUCCESS = 'GET_USER_TASKS_SUCCESS';
export const GET_USER_TASKS_FAILURE = 'GET_USER_TASKS_FAILURE';
