import {
  CLOSE_CUSTOM_FIELDS_DRAWER,
  CREATE_CUSTOM_FIELD_FAILURE,
  CREATE_CUSTOM_FIELD_REQUEST,
  CREATE_CUSTOM_FIELD_SUCCESS,
  LIST_CUSTOM_FIELD_FAILURE,
  LIST_CUSTOM_FIELD_REQUEST,
  LIST_CUSTOM_FIELD_SUCCESS,
  OPEN_CUSTOM_FIELDS_DRAWER
} from './types';

const initialState = {
  creatingField: false,
  createFieldSuccess: false,
  customFields: null,
  fieldCreateError: null,
  customFieldsFetchError: null,
  customFieldDrawerOpen: false,
  activeField: null,
  onCreateFields: null,
  notRequiredFields: null,
  fetchingCustomFields: false,
  fieldGroupObject: null
};
export const customFieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        creatingField: true
      };
    case CREATE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        creatingField: false,
        createFieldSuccess: true
      };
    case CREATE_CUSTOM_FIELD_FAILURE:
      return {
        ...state,
        creatingField: false,
        fieldCreateError: action.error
      };

    case LIST_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        fetchingCustomFields: true
      };
    case LIST_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        fetchingCustomFields: false,
        customFields: action.customFields,
        fieldCategoryOptions: action.fieldCategoryOptions,
        fieldGroupOptions: action.fieldGroupOptions,
        fieldCategoriesArray: action.fieldCategoriesArray,
        fieldGroupsArray: action.fieldGroupsArray,
        fieldsByCategory: action.fieldsByCategory,
        fieldsByGroup: action.fieldsByGroup,
        onCreateFields: action.onCreateFields,
        fieldGroupObject: action.fieldGroupObject,
        notRequiredFields: action.notRequiredFields,
        venueRequired: action.venueRequired
      };
    case LIST_CUSTOM_FIELD_FAILURE:
      return {
        ...state,
        fetchingCustomFields: false,
        customFieldsFetchError: action.error
      };

    case OPEN_CUSTOM_FIELDS_DRAWER:
      return {
        ...state,
        customFieldDrawerOpen: true,
        activeField: action.activeField
      };

    case CLOSE_CUSTOM_FIELDS_DRAWER:
      return {
        ...state,
        customFieldDrawerOpen: false,
        activeField: null
      };

    default:
      return state;
  }
};
