import React from 'react';
import PropTypes from 'prop-types';
import Colors from '../Colors';

const Library = props => {
  let fillColor = 'var(--colors-primary)';
  if (props.sideBarIcon) fillColor = 'var(--colors-sideBarText)';
  if (props.hideSidebar) fillColor = Colors.white;
  if (props.color) fillColor = props.color;
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 512 512" {...props} fill={fillColor}>
      <path d="M378.526.363l-98.765 19.765V0H36.35v509.726h243.41V189.881L344.221 512l131.429-26.301L378.526.363zM142.451 478.518H67.557v-23.232h74.893v23.232zm0-54.44H67.557V31.208h74.893v392.87zm106.101 54.44h-74.893v-23.232h74.893v23.232zm.001-54.44H173.66V31.208h74.348l.545 2.726v390.144zm35.27-372.938l70.227-14.054 74.321 371.384-70.143 14.478L283.823 51.14zm80.528 402.411l70.143-14.478 4.433 22.15-70.229 14.054-4.347-21.726z" />
      <path d="M84.5 63.456h41.61v31.208H84.5zM190.617 63.456h41.61v31.208h-41.61zM306.26 79.79l39.596-7.647 5.918 30.644-39.597 7.646z" />
    </svg>
  );
};
Library.defaultProps = {
  size: 24
};

Library.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  sideBarIcon: PropTypes.bool,
  hideSidebar: PropTypes.bool
};

export default Library;
