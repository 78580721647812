import { GetStaffRequest } from 'src/containers/ManageStaffContainer/OperationTypes';
import {
  GET_STAFF_REQUEST,
  GET_STAFF_SUCCESS,
  GET_STAFF_FAILURE,
  UPDATE_STAFF_REQUEST,
  UPDATE_STAFF_SUCCESS,
  UPDATE_STAFF_FAILURE,
  DELETE_STAFF_REQUEST,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_FAILURE,
  GET_STAFF_EVENTS_REQUEST,
  GET_STAFF_EVENTS_SUCCESS,
  GET_STAFF_EVENTS_FAILURE,
  OPEN_ADD_STAFF_MODAL,
  CLOSE_ADD_STAFF_MODAL,
  ADD_STAFF_REQUEST,
  ADD_STAFF_SUCCESS,
  ADD_STAFF_FAILURE
} from './types';

export const getStaffRequest: GetStaffRequest = ({ token, venueId }) => ({
  type: GET_STAFF_REQUEST,
  token,
  venueId
});

export const getStaffSuccess = ({ staff }) => ({
  type: GET_STAFF_SUCCESS,
  staff
});

export const getStaffFailure = ({ error }) => ({
  type: GET_STAFF_FAILURE,
  error
});

export const updateStaffRequest = ({ token, staffId, viewPermissions, venueId }) => ({
  type: UPDATE_STAFF_REQUEST,
  token,
  staffId,
  viewPermissions,
  venueId
});

export const updateStaffSuccess = ({ staff }) => ({
  type: UPDATE_STAFF_SUCCESS,
  staff
});

export const updateStaffFailure = ({ error }) => ({
  type: UPDATE_STAFF_FAILURE,
  error
});

export const deleteStaffRequest = ({ token, staffId, consultantId }) => ({
  type: DELETE_STAFF_REQUEST,
  token,
  staffId,
  consultantId
});

export const deleteStaffSuccess = ({ success }) => ({
  type: DELETE_STAFF_SUCCESS,
  success
});

export const deleteStaffFailure = ({ error }) => ({
  type: DELETE_STAFF_FAILURE,
  error
});

export const getStaffEventsRequest = ({ token, consultantId }) => ({
  type: GET_STAFF_EVENTS_REQUEST,
  token,
  consultantId
});

export const getStaffEventsSuccess = ({ events }) => ({
  type: GET_STAFF_EVENTS_SUCCESS,
  events
});

export const getStaffEventsFailure = ({ error }) => ({
  type: GET_STAFF_EVENTS_FAILURE,
  error
});

export const openAddStaffModal = () => ({
  type: OPEN_ADD_STAFF_MODAL
});

export const closeAddStaffModal = () => ({
  type: CLOSE_ADD_STAFF_MODAL
});

export const addStaffRequest = ({ token, staff, venueId }) => ({
  type: ADD_STAFF_REQUEST,
  token,
  staff,
  venueId
});

export const addStaffSuccess = ({ success }) => ({
  type: ADD_STAFF_SUCCESS,
  success
});

export const addStaffFailure = ({ error }) => ({
  type: ADD_STAFF_FAILURE,
  error
});
