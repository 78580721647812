import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FC, useState } from 'react';
import Colors from 'src/styles/Colors';
import { AnimatePresenceDiv } from 'src/v2/UIComponents/Animated/AnimatePresenceDiv';
import { InlineTextField } from 'src/v2/UIComponents/FormComponents/InlineTextField/InlineTextField';
import { ComposerToFieldTypes } from '../types';

export const ComposerToField: FC<ComposerToFieldTypes> = () => {
  const [showCopy, setShowCopy] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const baseStyles = {
    borderBottom: `1px solid ${Colors.grayED7}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 12px',
    fontSize: '14px'
  };
  return (
    <>
      <Box
        borderBottom={`1px solid ${Colors.grayED7}`}
        height="50px"
        display="flex"
        justifyContent={'center'}
        alignItems="center"
        padding="0px 12px"
        fontSize="14px"
        minHeight={'50px'}
      >
        To:
        <InlineTextField placeholder={''} name="recipient.email" />
        <Box
          color="var(--colors-primary)"
          fontWeight={'semibold'}
          marginRight="13px"
          _hover={{
            fontWeight: '800',
            cursor: 'pointer'
          }}
          onClick={() => setShowCopy(!showCopy)}
        >
          cc
        </Box>
        <Box
          color="var(--colors-primary)"
          fontWeight={'semibold'}
          marginRight="13px"
          _hover={{
            fontWeight: '800',
            cursor: 'pointer'
          }}
          onClick={() => setShowBcc(!showBcc)}
        >
          bcc
        </Box>
      </Box>
      <AnimatePresenceDiv>
        {showCopy && (
          <motion.div
            key="cc"
            style={{ overflow: 'hidden', ...baseStyles }}
            initial={{ minHeight: 0 }}
            animate={{ minHeight: '50px', height: '0px' }}
            exit={{ minHeight: 0, height: '0px' }}
          >
            cc:
            <InlineTextField placeholder={''} name="cc.email" />
          </motion.div>
        )}
      </AnimatePresenceDiv>
      <AnimatePresenceDiv>
        {showBcc && (
          <motion.div
            key="bcc"
            style={{ overflow: 'hidden', ...baseStyles }}
            initial={{ minHeight: 0 }}
            animate={{ minHeight: '50px', height: '0px' }}
            exit={{ minHeight: 0, height: '0px' }}
          >
            bcc:
            <InlineTextField placeholder={''} name="bcc.email" />
          </motion.div>
        )}
      </AnimatePresenceDiv>
    </>
  );
};
