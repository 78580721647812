import axios from 'axios';
import config from '../../.env.json';

const { apiUri } = config;

export function batchDeleteGuest({ token, guestId }) {
  return axios({
    method: 'post',
    url: `${apiUri}/guests/batchDelete`,
    headers: { Authorization: token },
    data: {
      guestId
    }
  });
}

export function getGroups({ token, eventId }) {
  return axios({
    method: 'get',
    url: `${apiUri}/groups?eventId=${eventId}`,
    headers: { Authorization: token }
  });
}

export function addGroup({ token, data }) {
  return axios({
    method: 'post',
    url: `${apiUri}/groups`,
    headers: { Authorization: token },
    data
  });
}

export function getExportData({ token, venueId, eventId }) {
  return axios({
    method: 'post',
    url: `${apiUri}/users/export`,
    headers: { Authorization: token },
    data: {
      venueId,
      eventId
    }
  });
}

export function editGroup({ token, groupId, data }) {
  return axios({
    method: 'put',
    url: `${apiUri}/groups/${groupId}`,
    headers: { Authorization: token },
    data
  });
}

export function deleteGroup({ token, groupId }) {
  return axios({
    method: 'delete',
    url: `${apiUri}/groups/${groupId}`,
    headers: { Authorization: token }
  });
}

export function hostSendEmailAndUpdateEvent({ id, event, sendEmail, token, guestId }) {
  return axios({
    method: 'post',
    url: `${apiUri}/guests/sendEmail`,
    headers: { Authorization: token },
    data: {
      eventPayload: event,
      id,
      guestId,
      sendEmail
    }
  });
}

export function hostUpdateEventAction({ id, event, token }) {
  return axios({
    method: 'put',
    url: `${apiUri}/events/${id}`,
    headers: { Authorization: token },
    data: {
      ...event,
      id
    }
  });
}

export function hostSendBatchEmail({ eventId, guestIds, token, subject, message }) {
  return axios({
    method: 'post',
    url: `${apiUri}/guests/sendEmailBatch`,
    headers: { Authorization: token },
    data: {
      eventId,
      guestIds,
      subject,
      message
    }
  });
}

export const getLayout = ({ token, layoutId }) => {
  const url = `${apiUri}/layouts/${layoutId}`;
  return axios({
    method: 'get',
    url,
    headers: { Authorization: token }
  });
};
