import moment from 'moment';

export const sortChecklistItems = ({ sortField, sortOrder, filteredItems }) => {
  const sortedItems = {};
  if (sortField && sortOrder) {
    switch (sortField) {
      case 'name':
        if (sortOrder === 'asc') {
          Object.keys(filteredItems)
            .sort((a, b) => {
              const nameA = filteredItems[a].name && filteredItems[a].name.toLowerCase(); // ignore upper and lowercase
              const nameB = filteredItems[b].name && filteredItems[b].name.toLowerCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
            .forEach(key => {
              sortedItems[key] = filteredItems[key];
            });

          return sortedItems;
        }
        if (sortOrder === 'desc') {
          Object.keys(filteredItems)
            .sort((a, b) => {
              const nameA = filteredItems[a].name && filteredItems[a].name.toLowerCase(); // ignore upper and lowercase
              const nameB = filteredItems[b].name && filteredItems[b].name.toLowerCase(); // ignore upper and lowercase
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
            .forEach(key => {
              sortedItems[key] = filteredItems[key];
            });

          return sortedItems;
        }
        break;
      case 'completedBy':
        if (sortOrder === 'asc') {
          Object.keys(filteredItems)
            .sort((a, b) => {
              const nameA =
                filteredItems[a].completedBy && filteredItems[a].completedBy.toLowerCase(); // ignore upper and lowercase
              const nameB =
                filteredItems[b].completedBy && filteredItems[b].completedBy.toLowerCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
            .forEach(key => {
              sortedItems[key] = filteredItems[key];
            });

          return sortedItems;
        }
        if (sortOrder === 'desc') {
          Object.keys(filteredItems)
            .sort((a, b) => {
              const nameA =
                filteredItems[a].completedBy && filteredItems[a].completedBy.toLowerCase(); // ignore upper and lowercase
              const nameB =
                filteredItems[b].completedBy && filteredItems[b].completedBy.toLowerCase(); // ignore upper and lowercase
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
            .forEach(key => {
              sortedItems[key] = filteredItems[key];
            });

          return sortedItems;
        }
        break;
      case 'dueDate':
        if (sortOrder === 'asc') {
          Object.keys(filteredItems)
            .sort((a, b) => {
              const dueDateA = filteredItems[a].dueDate;
              const dueDateB = filteredItems[b].dueDate;
              if (moment(dueDateA).isBefore(dueDateB, 'date')) {
                return -1;
              }
              if (moment(dueDateA).isAfter(dueDateB, 'date')) {
                return 1;
              }
              // names must be equal
              return 0;
            })
            .forEach(key => {
              sortedItems[key] = filteredItems[key];
            });

          return sortedItems;
        }
        if (sortOrder === 'desc') {
          Object.keys(filteredItems)
            .sort((a, b) => {
              const dueDateA = filteredItems[a].dueDate;
              const dueDateB = filteredItems[b].dueDate;
              if (moment(dueDateA).isAfter(dueDateB, 'date')) {
                return -1;
              }
              if (moment(dueDateA).isBefore(dueDateB, 'date')) {
                return 1;
              }
              // names must be equal
              return 0;
            })
            .forEach(key => {
              sortedItems[key] = filteredItems[key];
            });

          return sortedItems;
        }
        break;
      case 'completedDate':
        if (sortOrder === 'asc') {
          Object.keys(filteredItems)
            .sort((a, b) => {
              if (!filteredItems[a].completedDate) return 1;
              if (!filteredItems[b].completedDate) return -1;

              const completedOnA = filteredItems[a].completedDate;
              const completedOnB = filteredItems[b].completedDate;
              if (moment(completedOnA).isBefore(completedOnB, 'date')) {
                return -1;
              }
              if (moment(completedOnA).isAfter(completedOnB, 'date')) {
                return 1;
              }
              // names must be equal
              return 0;
            })
            .forEach(key => {
              sortedItems[key] = filteredItems[key];
            });

          return sortedItems;
        }
        if (sortOrder === 'desc') {
          Object.keys(filteredItems)
            .sort((a, b) => {
              if (!filteredItems[a].completedDate) return 1;
              if (!filteredItems[b].completedDate) return -1;
              const completedOnA = filteredItems[a].completedDate;
              const completedOnB = filteredItems[b].completedDate;
              if (moment(completedOnA).isAfter(completedOnB, 'date')) {
                return -1;
              }
              if (moment(completedOnA).isBefore(completedOnB, 'date')) {
                return 1;
              }
              // names must be equal
              return 0;
            })
            .forEach(key => {
              sortedItems[key] = filteredItems[key];
            });

          return sortedItems;
        }
        break;
      default:
        Object.keys(filteredItems)
          .sort((a, b) => {
            const nameA = filteredItems[a].name && filteredItems[a].name.toLowerCase(); // ignore upper and lowercase
            const nameB = filteredItems[b].name && filteredItems[b].name.toLowerCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
          .forEach(key => {
            sortedItems[key] = filteredItems[key];
          });

        return sortedItems;
    }
  }
  Object.keys(filteredItems)
    .sort((a, b) => {
      const dueDateA = filteredItems[a].dueDate;
      const dueDateB = filteredItems[b].dueDate;
      if (moment(dueDateA).isAfter(dueDateB, 'date')) {
        return 1;
      }
      if (moment(dueDateA).isBefore(dueDateB, 'date')) {
        return -1;
      }
      // names must be equal
      return 0;
    })
    .forEach(key => {
      sortedItems[key] = filteredItems[key];
    });

  return sortedItems;
};
